import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { RRule } from 'rrule';

import { addDtStartAndTimezoneToRRule } from '../../../helper/rrule/add-dtstart-and-timezone-to-rrule';
import { getEventEndTimeFor } from '../../../helper/rrule/get-event-end-time-for';
import { getRecurrences } from '../../../helper/rrule/recurrences';
import { validateRRuleStartEndConsistent } from '../../../helper/rrule/validate-rrule-start-end-consistent';
import { validateAndGetRRuleFromStr } from '../../../helper/rrule/validation';
import { DBDocObject } from '../../generic/db-doc/db-doc-object';
import { DBDocSchema } from '../../generic/db-doc/db-doc-schema';
import { EventData } from '../event-data/event-data';

import { EventConfigConstructorDoGenerate, EventConfigConstructorDontGenerate } from './event-config-constructor';
import { EventConfigSchema } from './event-config-schema';

export class EventConfig extends DBDocObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected start!: moment.Moment;

  protected end!: moment.Moment;

  protected rrule!: string;

  protected generate!: boolean;

  protected type?: string;

  protected color?: string;

  protected assignedUserId?: string;

  protected assignedBackupUserId?: string;

  protected displayName!: string;

  protected description!: string;

  protected extendScheduleNumDays!: number;

  /////////////////////////////////////////////////////////////////////////////
  // Private variables
  /////////////////////////////////////////////////////////////////////////////

  private _startRRule: RRule | undefined;

  private _endRRule: RRule | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: EventConfigConstructorDoGenerate | EventConfigConstructorDontGenerate) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): EventConfig {
    return new EventConfig(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(EventConfig.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new EventConfigSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getStart(timezone: string): moment.Moment {
    return cloneDeep(this.start).tz(timezone);
  }

  public getEnd(timezone: string): moment.Moment {
    return cloneDeep(this.end).tz(timezone);
  }

  public getRRule(): string {
    return cloneDeep(this.rrule);
  }

  public getGenerate(): boolean {
    return cloneDeep(this.generate);
  }

  public getType(): string | undefined {
    return cloneDeep(this.type);
  }

  public getColor(): string | undefined {
    return cloneDeep(this.color);
  }

  public getAssignedUserId(): string | undefined {
    return cloneDeep(this.assignedUserId);
  }

  public getAssignedBackupUserId(): string | undefined {
    return cloneDeep(this.assignedBackupUserId);
  }

  public getDisplayName(): string {
    return cloneDeep(this.displayName);
  }

  public getDescription(): string {
    return cloneDeep(this.description);
  }

  public getExtendScheduleNumDays() {
    return cloneDeep(this.extendScheduleNumDays);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Private convenience methods
  /////////////////////////////////////////////////////////////////////////////

  public getStartRRule(timezone: string): RRule {
    if (this._startRRule === undefined) {
      this._startRRule = addDtStartAndTimezoneToRRule(this.getRRule(), this.getStart(timezone), timezone);
    }
    validateAndGetRRuleFromStr(this._startRRule.toString(), true);
    return this._startRRule;
  }

  public getEndRRule(timezone: string): RRule {
    if (this._endRRule === undefined) {
      this._endRRule = addDtStartAndTimezoneToRRule(this.getRRule(), this.getEnd(timezone), timezone);
    }
    validateAndGetRRuleFromStr(this._endRRule.toString(), true);
    return this._endRRule;
  }

  /**
   * Return the array of events of the given type whose start
   * and end follow the given rrule (recurrence rule), and
   * where the startTime is in the half-open range
   * (eventStartMin, eventStartMax].
   *
   * @param startRRule
   * @param endRRule
   * @param type
   * @param eventStartMin
   * @param eventStartMax
   */
  public generateEventsWithStartBetween(
    eventStartMin: moment.Moment,
    eventStartMax: moment.Moment,
    timezone: string,
  ): EventData[] {
    // Check 1: No Invalid Dates
    if (
      [this.getStart(timezone), this.getEnd(timezone), eventStartMin, eventStartMax]
        .map((z) => z.isValid())
        .includes(false)
    ) {
      throw new Error('generateEventsWithStartBetween: User Error, invalid date.');
    }

    // Check 2: Generate flag true
    if (this.getGenerate() !== true) {
      return [];
    }

    // Step 1: Get start times
    const startTimes = getRecurrences(this.getStartRRule(timezone), eventStartMin, eventStartMax);

    // Step 2: Get end times
    const endTimes: moment.Moment[] = [];
    for (const startTime of startTimes) {
      const endTime = getEventEndTimeFor({
        thisEventStartTime: startTime,
        templateRRuleStr: this.getRRule(),
        templateEventStartTime: this.getStart(timezone),
        templateEventEndTime: this.getEnd(timezone),
        eventTimezone: timezone,
      });
      endTimes.push(endTime);
    }

    // Step 3: Make Events
    const events: EventData[] = [];
    for (let i = 0; i < startTimes.length; i++) {
      const event = new EventData({
        id: DBDocSchema.GenericDefaults.id,
        start: startTimes[i],
        end: endTimes[i],
        assignedUserId: this.getAssignedUserId(),
        assignedBackupUserId: this.getAssignedBackupUserId(),
        color: this.getColor(),
        type: this.getType()!,
        eventConfigId: this.getId(),
      });
      events.push(event);
    }
    return events;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Extend Schedule
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Return the list of events which need to be added in order to extend the given
   * EventConfig by the specified number of days.
   * @param endOfLastEvent
   * @param numDaysToAdd
   * @param timezone Local timezone of the org
   * @param nowOverride For unit testing, this parameter you to simulate running this function at a different time.
   */
  public extendSchedule(
    endOfLastEvent: moment.Moment | undefined,
    numDaysToAdd: number,
    timezone: string,
    nowOverride: Date | undefined = undefined,
  ): EventData[] {
    /**
     * If no event of this type has been defined in the past then create events starting at the beginning of
     * today. Else, the next event can start after the last one ended.
     */
    const startOfToday = moment.tz(nowOverride, timezone).startOf('day');
    const minNewEventStart = endOfLastEvent || startOfToday;

    // The number of days to add to insure that the calendar is always filled numDaysToAdd days into the future.
    // note that tomorrow.diff(today, 'days') > 0, and yesterday.diff(today, 'days') < 0
    const numRemainingDaysToAdd = numDaysToAdd - minNewEventStart.diff(cloneDeep(startOfToday), 'days');

    if (numRemainingDaysToAdd <= 0) {
      return [];
    }

    const maxNewEventStart = cloneDeep(minNewEventStart).add(numRemainingDaysToAdd, 'days').subtract(1, 'second');
    // Step 3: Create Events
    const newEvents = this.generateEventsWithStartBetween(minNewEventStart, maxNewEventStart, timezone);
    return newEvents;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Sanity Check
  /////////////////////////////////////////////////////////////////////////////

  public sanityCheck(): void {
    validateRRuleStartEndConsistent(this.getRRule(), cloneDeep(this.start), cloneDeep(this.end));
  }
}
