<!-- Exec IVR Command -->
<form *ngIf="!loading" [formGroup]="form">
  <!-- Number of times to loop -->
  <mat-form-field class="full-width">
    <input class="full-width" formControlName="numRetriesAllowed" matInput placeholder="Number of Retries on IVR" />
    <mat-error *ngIf="numRetriesAllowed.errors?.min && numRetriesAllowed.dirty">
      Min is {{ numRetriesAllowed.errors?.min.min }}
    </mat-error>
    <mat-error *ngIf="numRetriesAllowed.errors?.max && numRetriesAllowed.dirty">
      Max is {{ numRetriesAllowed.errors?.max.max }}
    </mat-error>
  </mat-form-field>

  <!-- IVR Select -->
  <app-obj-select
    [label]="'IVR'"
    [orderedItems]="ivrsArray"
    [(kvPair)]="selectedIVRKVPair"
    (kvPairChange)="updateObjAndEmit()"
  ></app-obj-select>
</form>

<!-- Is Valid Indicator -->
<app-obj-is-valid *ngIf="!loading" [isValidObj]="isValidObjScoper"></app-obj-is-valid>
