<form *ngIf="!loading" [formGroup]="form">
  <!-- Language -->
  <mat-form-field class="full-width">
    <mat-label>Language</mat-label>
    <mat-select formControlName="language" value="obj.languageDefaults.getId()">
      <mat-option *ngFor="let language of possibleLanguages" [value]="language.getShortCode()">{{
        language.getDisplayName()
      }}</mat-option>
    </mat-select>
  </mat-form-field>

  <!-- SSML -->

  Docs:
  <ul>
    <li><a href="https://cloud.google.com/text-to-speech/docs/ssml">Google Text-To-Speech SSML Reference</a></li>
    <li><a href="https://cloud.google.com/text-to-speech">Google Text-To-Speech SSML Live Demo</a></li>
    <li>
      <a href="https://docs.aws.amazon.com/polly/latest/dg/supportedtags.html"
        >Amazon Polly Text-To-Speech SSML Reference</a
      >
    </li>
  </ul>

  <mat-form-field class="full-width">
    <textarea class="full-width" formControlName="ssml" matInput placeholder="SSML"></textarea>
    <span *ngIf="ssml?.invalid && ssml?.touched"> SSML Invalid </span>
  </mat-form-field>

  <!-- Audio URL -->
  <mat-form-field class="full-width">
    <textarea class="full-width" formControlName="audioURL" matInput placeholder="Audio URL"></textarea>
    <span *ngIf="audioURL?.invalid && audioURL?.touched"> AudioURL Invalid </span>
  </mat-form-field>

  <!-- Audio File -->
  <p-fileUpload
    #filePicker
    accept="audio/wav"
    customUpload="true"
    maxFileSize="100000000"
    mode="advanced"
    [disabled]="isSSMLFormOptionDisabled('fileUpload')"
    [files]="file !== undefined ? [file] : []"
    [showCancelButton]="false"
    [showUploadButton]="false"
    (onSelect)="onSelectFile($event)"
  >
  </p-fileUpload>
</form>
