import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isNil, orderBy } from 'lodash';
import moment from 'moment-timezone';
import { map } from 'rxjs/operators';

import {
  AllDataUser,
  computeAllEntityStats,
  EntityStats,
  EntityStatsDisplayWindow,
  EntityStatsSchema,
  EventType,
  HoursOverTime,
  OrgData,
} from '@pwp-common';

import { KVPair } from '../../../../common/objects/kvpair';
import { NgChanges } from '../../../../common/objects/ng-changes';
import { EventsService } from '../../../../services/event/events/events.service';
import { EntityStatsDisplayWindowChange } from '../../generic/entity-stats-display-window-change';

@UntilDestroy()
@Component({
  selector: 'app-event-hours-table',
  templateUrl: './event-hours-table.component.html',
  styleUrls: ['./event-hours-table.component.css'],
})
export class EventHoursTableComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Input / Output
  /////////////////////////////////////////////////////////////////////////////////////////////

  @Input() orgData: OrgData;

  @Input() entityStatsMap: Map<string, EntityStats>;

  @Input() allDataUserMap: Map<string, AllDataUser>;

  @Input() windowObj: EntityStatsDisplayWindowChange;

  @Input() eventTypes: EventType[] = [];

  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  customEntityStatsMap: Map<string, EntityStats>;

  inProgress = false;

  hoursOverTime: HoursOverTime[] = [];

  parents: string[] = [];

  labels: string[] = [];

  values: number[] = [];

  selectedEventTypes: KVPair<EventType>[] = [];

  loading = false;

  cols = ['displayName', 'email', 'totalHours'];

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private translocoService: TranslocoService,
    private eventsService: EventsService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: NgChanges<EventHoursTableComponent>) {
    if (!isNil(changes.eventTypes)) {
      this.selectedEventTypes = this.eventTypes.map((value, ind) => new KVPair({ id: ind, value }));
    }
    this.getData();
  }

  ///////////////////////////////////////////////////////////////////////
  // UI Callbacks
  ///////////////////////////////////////////////////////////////////////

  changeEventTypes(eventTypes: KVPair<EventType>[]) {
    this.updateUIWithEntityStats();
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Data
  ///////////////////////////////////////////////////////////////////////

  public getData() {
    console.log('EventHoursTableComponent.getData: Starting', this.windowObj);

    // Loading will be set to false by updateUIWithEntityStats
    this.loading = true;

    /**
     * Case 1: Non-Custom Window Type
     *
     * - Data already obtained. Just refresh UI.
     */
    if (this.windowObj?.type !== EntityStatsDisplayWindow.custom) {
      this.updateUIWithEntityStats();
      console.log('EventHoursTableComponent.getData: Completed', { windowObj: this.windowObj });
      return;
    }

    /**
     * Case 2: Custom Window Type
     *
     * - Get data, and refresh UI.
     */
    const selectedEventTypes = this.selectedEventTypes.map((z) => z.value.getInternalName());
    void this.eventsService
      .getEventsWithStartOrEndInRange(this.windowObj.windowStart, this.windowObj.windowEnd, true, ...selectedEventTypes)
      .pipe(
        map((events) => {
          this.customEntityStatsMap = computeAllEntityStats(
            [],
            events,
            this.allDataUserMap,
            this.orgData.getTimezone(),
          );
        }),
        map(() => {
          this.updateUIWithEntityStats();
          this.changeDetectorRef.detectChanges();
          console.log('EventHoursTableComponent.getData: Completed', { windowObj: this.windowObj });
        }),
      )
      .toPromise();
  }

  ///////////////////////////////////////////////////////////////////////
  // Update UI
  ///////////////////////////////////////////////////////////////////////
  private getActiveEntityStatsMap(): Map<string, EntityStats> {
    if (this.windowObj?.type === EntityStatsDisplayWindow.custom) {
      return this.customEntityStatsMap;
    }
    return this.entityStatsMap;
  }

  public updateUIWithEntityStats() {
    console.log('EventHoursTableComponent.updateUIWithEntityStats: Starting');
    if (isNil(this.windowObj)) {
      console.log('EventHoursTableComponent.updateUIWithEntityStats: Window obj is nil. Returning');
      return;
    }
    this.loading = true;
    const hoursOverTime = [];
    const selectedEventTypesArray = this.selectedEventTypes.map((z) => z.value);

    const months: Set<string> = new Set();
    for (const entityStats of this.getActiveEntityStatsMap().values()) {
      const hoursForOneEntity = new HoursOverTime(
        entityStats as EntityStats,
        this.windowObj.type,
        this.allDataUserMap,
        selectedEventTypesArray,
      );
      hoursOverTime.push(hoursForOneEntity);
      hoursForOneEntity.monthStrs.forEach((z) => months.add(z));
    }

    this.updateCols(months);
    this.hoursOverTime = orderBy(hoursOverTime, (z) => z.displayName, ['asc']);
    this.loading = false;
    console.log('EventHoursTableComponent.updateUIWithEntityStats: Completed');
  }

  /**
   * Update the list of columns with the given set of months.
   *
   * @param months
   */
  private updateCols(months: Set<string>) {
    const sortedMonths = orderBy(
      Array.from(months),
      (month) => moment(month, EntityStatsSchema.Constants.monthFormat).valueOf(),
      ['desc'],
    );

    const cols = ['displayName', 'email', 'totalHours'];

    for (const monthStr of sortedMonths) {
      // Hours
      const numHoursKey = HoursOverTime.getKeyName(HoursOverTime.NUM_HOURS_POSTFIX, monthStr);
      cols.push(numHoursKey);
      const translationKeyHours = `event-hours-table.${numHoursKey}`;
      this.translocoService.setTranslationKey(translationKeyHours, `Hours: ${monthStr}`, 'en');
      this.translocoService.setTranslationKey(translationKeyHours, `Horas: ${monthStr}`, 'es');

      // Events
      const numEventsKey = HoursOverTime.getKeyName(HoursOverTime.NUM_EVENTS_POSTFIX, monthStr);
      cols.push(numEventsKey);
      const translationKeyEvents = `event-hours-table.${numEventsKey}`;
      this.translocoService.setTranslationKey(translationKeyEvents, `Shifts: ${monthStr}`, 'en');
      this.translocoService.setTranslationKey(translationKeyEvents, `Turnos: ${monthStr}`, 'es');
    }

    this.cols = cols;
  }
}
