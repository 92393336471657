import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import moment from 'moment-timezone';

import {
  DATETIME_LOCAL_CONTROL_STR_FORMAT,
  getFieldControlValueOrDefault,
} from '../../../../common/objects/form-helper';
import { DatetimeValidator } from '../../../../common/validators/datetime-validator/datetime-validator';
import { ComponentWithEmittingForm } from '../../../generic/abstract-classes/component-with-emitting-form';
import { EventRepeatInfo } from '../../generic/event-repeat-info';

@Component({
  selector: 'app-event-repeat',
  templateUrl: './event-repeat.component.html',
  styleUrls: ['./event-repeat.component.css'],
})
export class EventRepeatComponent extends ComponentWithEmittingForm<EventRepeatInfo> implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Close Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  public getObjFromForm(): EventRepeatInfo {
    const untilStr = getFieldControlValueOrDefault(this.until, undefined);
    const until = moment(untilStr, DATETIME_LOCAL_CONTROL_STR_FORMAT);

    return new EventRepeatInfo(this.obj.rrule, until);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected defineForm() {
    // Init Form
    const formConfig = {} as any;

    formConfig.until = [
      this.obj.until.format(DATETIME_LOCAL_CONTROL_STR_FORMAT),
      [Validators.required, DatetimeValidator.stepSizeSeconds(900)],
    ];

    this.form = this.formBuilder.group(formConfig);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Form Field Getters
  /////////////////////////////////////////////////////////////////////////////////////////////

  get until(): AbstractControl | null {
    return this.form.get('until');
  }
}
