import { FieldChangeTypes, isFieldChange } from '../../generic/serialization/field-change';
import { AllDataUser } from '../../user/all-data-user/all-data-user';
import { EventDataSchema } from '../event-data/event-data-schema';

/**
 * This interface lists all event properties that can be updated in
 * bulk. This object is not a class because it needs to be instantiated
 * inside an angular template.
 */
export interface EventPropertiesBulkUpdate {
  assignedUserId?: string | FieldChangeTypes;
  assignedBackupUserId?: string | FieldChangeTypes;
  color?: string | FieldChangeTypes;
}

/**
 * Validate the requested bulk update. Throw if invalid.
 */
export const eventPropertiesBulkUpdateIsValid = (
  update: EventPropertiesBulkUpdate,
  allDataUserMap: Map<string, AllDataUser>,
): void => {
  if (update.assignedUserId !== undefined) {
    validateUserId(update.assignedUserId, allDataUserMap);
  }

  if (update.assignedBackupUserId !== undefined) {
    validateUserId(update.assignedBackupUserId, allDataUserMap);
  }

  if (update.color !== undefined) {
    validateColor(update.color);
  }
};

/**
 * Throw an error if the userId is an invalid input to EventPropertiesBulkUpdate.
 * @param userId
 * @param allDataUserMap
 */
const validateUserId = (userId: string, allDataUserMap: Map<string, AllDataUser>): void => {
  if (!isFieldChange(userId) && !allDataUserMap.has(userId)) {
    throw new Error(
      `User Error: EventPropertiesBulkUpdate.validateUserId: attempted to use value='${userId}' as a userId`,
    );
  }
};

/**
 * Throw an error if the color is invalid.
 * @param color
 * @param allDataUserMap
 */
const validateColor = (color: string): void => {
  if (
    !isFieldChange(color) &&
    !Array.from([EventDataSchema.Colors.reserved, EventDataSchema.Colors.default]).includes(color)
  ) {
    throw new Error(`User Error: EventPropertiesBulkUpdate.validateColor: attempted to assign value=${color} as color`);
  }
};
