import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { isNil } from 'lodash';
import { SharedModule } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { MessagesModule } from 'primeng/messages';
import { map, startWith } from 'rxjs';

import {
  AvailableTimesByWeekMap,
  AvailableTimesType,
} from '../../../common/objects/time-selection/available-times-by-week';
import { getAvailableTimesByDayOfWeek } from '../../../common/objects/time-selection/get-available-times-by-day-of-week';
import { makeTimeSelectionFromAvailableTimesByDayOfWeek } from '../../../common/objects/time-selection/make-time-selection-from-available-times-by-day-of-week';
import { normalizeAvailableTimesByWeekMap } from '../../../common/objects/time-selection/normalize-available-times-by-week-map';
import { TimeRangeStrValidator } from '../../../common/validators/time-range-str-validator';
import { ComponentWithEmittingForm } from '../../generic/abstract-classes/component-with-emitting-form';

import { EditAvailableHoursByDowInput } from './edit-available-hours-by-dow-input';

@Component({
  selector: 'app-edit-available-hours-by-dow',
  standalone: true,
  imports: [
    DropdownModule,
    InputMaskModule,
    FormsModule,
    ReactiveFormsModule,
    MessagesModule,
    SharedModule,
    CommonModule,
    TranslocoModule,
  ],
  templateUrl: './edit-available-hours-by-dow.component.html',
  styleUrls: ['./edit-available-hours-by-dow.component.css'],
})
export class EditAvailableHoursByDowComponent
  extends ComponentWithEmittingForm<EditAvailableHoursByDowInput>
  implements OnInit
{
  private readonly formBuilder = inject(NonNullableFormBuilder);
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Input/Output
  /////////////////////////////////////////////////////////////////////////////////////////////

  @Input()
  description = '';

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////

  timeRangeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]-(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

  isoWeekdays = [1, 2, 3, 4, 5, 6, 7];

  public readonly form = this.createForm();

  public readonly hasUnavailableTime$ = this.form.valueChanges.pipe(
    startWith(this.form.value),
    map(() =>
      this.isoWeekdays.some((isoWeekday) => this.isSometimesAvailable(isoWeekday) || this.isNeverAvailable(isoWeekday)),
    ),
  );

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  private createForm(): FormGroup {
    const formGroup = this.formBuilder.group({});
    const availableTimes = getAvailableTimesByDayOfWeek(this.obj?.timeSelection);

    this.isoWeekdays.forEach((isoWeekday) => {
      const availableTimesForWeekday = availableTimes.get(isoWeekday)!;

      formGroup.addControl(
        this.timeRangeControlName(isoWeekday),
        this.formBuilder.control(availableTimesForWeekday.timeRange),
      );
      formGroup.addControl(
        this.timeRangeTypeControlName(isoWeekday),
        this.formBuilder.control(availableTimesForWeekday.type),
      );
      formGroup.addValidators([
        TimeRangeStrValidator.timeRange(
          this.timeRangeTypeControlName(isoWeekday),
          this.timeRangeControlName(isoWeekday),
        ),
      ]);
    });

    return formGroup;
  }

  protected defineForm() {
    const availableTimes = getAvailableTimesByDayOfWeek(this.obj?.timeSelection);

    const newValues = this.isoWeekdays.reduce((acc, isoWeekday) => {
      const availableTimesForWeekday = availableTimes.get(isoWeekday)!;

      return {
        ...acc,
        [this.timeRangeControlName(isoWeekday)]: availableTimesForWeekday.timeRange,
        [this.timeRangeTypeControlName(isoWeekday)]: availableTimesForWeekday.type,
      };
    }, {});

    this.form.patchValue(newValues);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Get Obj From Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    // Make the Map from Form
    let availableTimes: AvailableTimesByWeekMap = new Map();

    for (const isoWeekday of this.isoWeekdays) {
      const timeRangeType = this.form?.get(this.timeRangeTypeControlName(isoWeekday))?.value;
      const timeRange: string = this.form?.get(this.timeRangeControlName(isoWeekday))?.value;
      availableTimes.set(isoWeekday, { type: timeRangeType, timeRange });
    }
    availableTimes = normalizeAvailableTimesByWeekMap(availableTimes);

    const timeSelection = makeTimeSelectionFromAvailableTimesByDayOfWeek(availableTimes, this.obj.timezone);
    return {
      timezone: this.obj.timezone,
      timeSelection,
    };
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Get Obj From Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  private isNeverAvailable(isoWeekday: number): boolean {
    return this.form?.get(this.timeRangeTypeControlName(isoWeekday))?.value === AvailableTimesType.neverAvailable;
  }

  isSometimesAvailable(isoWeekday: number): boolean {
    return this.form?.get(this.timeRangeTypeControlName(isoWeekday))?.value === AvailableTimesType.sometimesAvailable;
  }

  isInvalidTimeRange(isoWeekday: number): boolean {
    return this.form?.get(this.timeRangeControlName(isoWeekday))?.invalid;
  }

  timeRangeControlName(isoWeekday: number) {
    return `timeRange${isoWeekday}`;
  }

  timeRangeTypeControlName(isoWeekday: number) {
    return `timeRangeType${isoWeekday}`;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid
  /////////////////////////////////////////////////////////////////////////////////////////////

  public isValidObj(): boolean {
    if (isNil(this.obj?.timezone)) {
      return false;
    }

    return super.isValidObj();
  }
}
