import { cloneDeep, isNil } from 'lodash';

import { defaultReservationSkipOffersIfEditorOutput } from '../../default-reservation-skip-offers-if-editor-output';
import { ReservationSkipOffersIfEditorOutput } from '../../reservation-skip-offers-if-editor-output';
import {
  ReservationOfferSkipIfForm,
  ReservationOfferSkipIfNumCallsInProgressOptions,
} from '../reservation-offer-skip-if-form';

/**
 * For a given formType return a sensible value of AllDataCommunicationWorkflowEditorTargetMode.
 */
export const formToReservationSkipOffersIfEditorOutput = (
  formType: ReservationOfferSkipIfForm,
): ReservationSkipOffersIfEditorOutput => {
  if (isNil(formType)) {
    return cloneDeep(defaultReservationSkipOffersIfEditorOutput);
  }

  const result: ReservationSkipOffersIfEditorOutput = {
    skipIfUnavailable: false,
    skipIfNumCallsInProgressGE: 1,
  };

  if (formType.skipIfUnavailable) {
    result.skipIfUnavailable = true;
  }

  if (formType.skipIfNumCallsInProgressGE === ReservationOfferSkipIfNumCallsInProgressOptions.neverSkip) {
    result.skipIfNumCallsInProgressGE = undefined;
  }

  if (typeof formType.skipIfNumCallsInProgressGE === 'number') {
    result.skipIfNumCallsInProgressGE = formType.skipIfNumCallsInProgressGE;
  }

  return result;
};
