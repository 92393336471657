import { cloneDeep } from 'lodash';

import { DBDocObject } from '../generic/db-doc/db-doc-object';
import { DBDocSchema } from '../generic/db-doc/db-doc-schema';
import { Displayable } from '../generic/db-doc/displayable';

import { EmailTemplateConfigConstructor } from './email-template-config-constructor';
import { EmailTemplateConfigSchema } from './email-template-config-schema';
import { EmailTemplateType } from './email-template-type';

export class EmailTemplateConfig extends DBDocObject implements Displayable {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected replyTo!: string;

  protected templateType!: EmailTemplateType;

  protected sgTemplateId!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: EmailTemplateConfigConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): EmailTemplateConfig {
    return new EmailTemplateConfig(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(EmailTemplateConfig.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new EmailTemplateConfigSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getReplyTo(): string {
    return cloneDeep(this.replyTo);
  }

  public getTemplateType(): EmailTemplateType {
    return cloneDeep(this.templateType);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Displayable
  /////////////////////////////////////////////////////////////////////////////

  public getDisplayName() {
    return this.getTemplateType();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////
}
