import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

import { quickExit } from '../../../../common/conversation/quick-exit/quick-exit';

import { ConversationDialogEndedInterface } from './conversation-dialog-ended-interface';

@Component({
  selector: 'app-conversation-ended-dialog',
  templateUrl: './conversation-ended-dialog.component.html',
  styleUrls: ['./conversation-ended-dialog.component.css'],
})
export class ConversationEndedDialogComponent implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  timeoutSecondsRemaining = 5;

  timer: any;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    public ref: MatDialogRef<ConversationEndedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConversationDialogEndedInterface,
  ) {}

  ngOnInit(): void {
    this.startTimer();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Timer
  /////////////////////////////////////////////////////////////////////////////////////////////

  private startTimer() {
    this.timeoutSecondsRemaining = 5;
    this.timer = setInterval(() => {
      this.timeoutSecondsRemaining--;

      if (this.timeoutSecondsRemaining <= 0) {
        this.redirectNow();
      }
    }, 1000);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Buttons
  /////////////////////////////////////////////////////////////////////////////////////////////

  public redirectNow(): void {
    clearInterval(this.timer);
    quickExit(this.data?.redirectURL);
  }

  cancel() {
    clearInterval(this.timer);
    this.ref.close();
  }
}
