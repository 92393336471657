import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ReleaseVersionService } from './services/version/release-version.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private readonly releaseVersionService = inject(ReleaseVersionService);

  public ngOnInit(): void {
    this.startVersionService();
  }

  private startVersionService(): void {
    // Get the latest version now, and start a periodic refresh
    this.releaseVersionService.refreshVersion().pipe(untilDestroyed(this)).subscribe();
    this.releaseVersionService.loadLatestVersionPeriodically();
  }
}
