import { isNil } from 'lodash';

import { VoiceResponseCommandName } from '@pwp-common';

import { ServiceCommandEditorOutput } from '../../../editor-output/service-command-editor-output';
import { ServiceOperationEditorStepHeader } from '../service-operation-editor-step-header';

const commandConfigs = {
  [VoiceResponseCommandName.dialCallList]: {
    materialIconName: 'phone',
    title: 'titleDialCallList',
  },
  [VoiceResponseCommandName.enforceServiceLimit]: {
    materialIconName: 'self_improvement',
    title: 'titleEnforceServiceLimit',
  },
  [VoiceResponseCommandName.flagServiceRequest]: {
    materialIconName: 'flag',
    title: 'titleFlagServiceRequest',
  },
  [VoiceResponseCommandName.forward]: {
    materialIconName: 'phone_forwarded',
    title: 'titleForward',
  },
  [VoiceResponseCommandName.hangup]: {
    materialIconName: 'call_end',
    title: 'titleHangup',
  },
  [VoiceResponseCommandName.playOrSay]: {
    materialIconName: 'music_note',
    title: 'titlePlayOrSay',
  },
  [VoiceResponseCommandName.takeVoicemail]: {
    materialIconName: 'voicemail',
    title: 'titleTakeVoicemail',
  },
};

export const makeServiceOperationEditorStepHeaders = (
  editorResults: ServiceCommandEditorOutput[],
): ServiceOperationEditorStepHeader[] => {
  const commands = (editorResults ?? []).filter((z) => !isNil(z));

  return commands.map((command) => commandConfigs[command.commandName]);
};
