<ng-container *transloco="let t; read: 'service-command-editor-take-voicemail'">
  <p-messages class="service-command-editor-take-voicemail__message" [severity]="'info'">
    <ng-template pTemplate>{{ t('takeVoicemailDescription') }}</ng-template>
  </p-messages>
  <label class="service-command-editor-take-voicemail__form-field">
    <span>{{ t('voicemailBox') }}</span>
    <app-voicemail-autocomplete
      [formControl]="form.controls.voicemailConfigId"
      [placeholder]="t('voicemailBox')"
    ></app-voicemail-autocomplete>
  </label>
</ng-container>
