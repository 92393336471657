import { inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { flatten, orderBy } from 'lodash';
import { combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { EventData, EventDataSchema, EventOverlapData, getOverlappingCurrentOrFutureEvent } from '@pwp-common';

import { DbDocumentService } from '../../generic/db-document-service';
import { DBOrderBy, DBQuery } from '../../generic/interfaces';
import { observableTakeOne } from '../../generic/take-one';
import { OrgDataService } from '../../orgs/org-data/org-data.service';
import { AuthService } from '../../user/auth/auth.service';
import { EventConfigService } from '../event-config/event-config.service';

import { getEventsQuery } from './helper/get-events-query';

@Injectable({
  providedIn: 'root',
})
export class EventsService extends DbDocumentService<EventData> {
  private readonly eventConfigService = inject(EventConfigService);

  private readonly orgDataService = inject(OrgDataService);
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(db: AngularFirestore, authService: AuthService) {
    super(db, authService, EventData);
  }

  /////////////////////////////////////////////////////////////////////
  // Events with start in range
  /////////////////////////////////////////////////////////////////////

  public getEventsWithStartInRange(
    start: moment.Moment,
    end: moment.Moment,
    type?: string | string[],
    takeOne = true,
  ): Observable<EventData[]> {
    // Log inputs
    console.log(
      `getEventsWithStartInRange:` +
        `\n\tStart:\t${start}` +
        `\n\tTo:\t\t${end}` +
        `\n\ttakeOne:${takeOne}` +
        `\n\ttype:\t${type}`,
    );

    // Make the query
    const query: DBQuery[] = [
      { fieldPath: EventDataSchema.start, opStr: '>=', value: start.toDate() },
      { fieldPath: EventDataSchema.start, opStr: '<=', value: end.toDate() },
      ...getEventsQuery(type),
    ];

    const dbOrderBy: DBOrderBy = { fieldPath: EventDataSchema.start, directionStr: 'asc' };

    // Return
    return this.getDocsArray({ query, orderBy: dbOrderBy, takeOne });
  }

  public getEventsWithStartOrEndInRange(
    start: moment.Moment,
    end: moment.Moment,
    takeOne = true,
    ...eventTypes: string[]
  ): Observable<EventData[]> {
    console.log(
      `getEventsWithStartOrEndInRange:` +
        `\n\tStart:\t${start}` +
        `\n\tTo:\t\t${end}` +
        `\n\ttakeOne:${takeOne}` +
        `\n\ttype:\t${eventTypes.join(',')}`,
    );

    if (eventTypes.length === 0) {
      console.log('getEventsWithStartOrEndInRange: No events specified, returning empty list.');
      return of([]);
    }

    const withStartInRangeObservables: Observable<EventData[]>[] = [];
    for (const eventType of eventTypes) {
      withStartInRangeObservables.push(this.getEventsWithStartInRange(start, end, eventType, takeOne));
    }

    const prevEventObservable = this.getEventsProperlyContainingTimestamp(start, takeOne, eventTypes);
    const observable = combineLatest([prevEventObservable, ...withStartInRangeObservables]).pipe(
      map((z) => {
        const events = orderBy(flatten(z), [(event) => event.getStart().valueOf()], ['asc']);
        console.log(events);
        return events;
      }),
    );
    return observableTakeOne(observable, takeOne);
  }

  private getEventsProperlyContainingTimestamp(
    timestamp: moment.Moment,
    takeOne: boolean,
    eventTypes: string[],
  ): Observable<EventData[]> {
    const observables: Observable<EventData[]>[] = [];
    for (const eventType of eventTypes) {
      observables.push(this.getEventOfOneTypeProperlyContainingTimestamp(timestamp, eventType, takeOne));
    }
    const observable = combineLatest(observables).pipe(map((z) => flatten(z)));
    return observableTakeOne(observable, takeOne);
  }

  /**
   * Since events don't overlap, there is at most one that contains this timestamp. We exclude events that
   * start or end on the given timestamp, because such events are included in the associated query for
   * getEventsWithStartInRange
   *
   * This private method returns an array with 0 or 1 element. We do this because this method is private and
   * getAllEventsProperlyContainingTimestamp returns an array.
   *
   * @param timestamp
   * @param type
   * @param takeOne
   */
  private getEventOfOneTypeProperlyContainingTimestamp(
    timestamp: moment.Moment,
    eventType: string,
    takeOne = true,
  ): Observable<EventData[]> {
    const query: DBQuery[] = [
      { fieldPath: EventDataSchema.start, opStr: '<', value: timestamp.toDate() },
      { fieldPath: EventDataSchema.type, opStr: '==', value: eventType },
    ];

    const dbOrderBy: DBOrderBy = { fieldPath: EventDataSchema.start, directionStr: 'desc' };

    return this.getDocsArray(query, dbOrderBy, 1, takeOne).pipe(
      map((event) => {
        const result = event.filter((z) => z.getIntervalMs().contains(timestamp));
        return result;
      }),
    );
  }

  public async getEventOverlapData(eventData: EventData): Promise<EventOverlapData | null> {
    const [orgData, existingEvents, eventConfigs] = await Promise.all([
      firstValueFrom(this.orgDataService.getOrgData()),
      firstValueFrom(
        this.getEventsWithStartOrEndInRange(eventData.getStart(), eventData.getEnd(), true, eventData.getType()),
      ),
      firstValueFrom(this.eventConfigService.getAllEventConfigsOfType(eventData.getType())),
    ]);

    return getOverlappingCurrentOrFutureEvent({
      eventData,
      orgData,
      eventConfigs,
      existingEvents,
    });
  }
}
