import { isNil } from 'lodash';

import { defaultReservationSkipOffersIfEditorOutput } from '../../default-reservation-skip-offers-if-editor-output';
import { ReservationSkipOffersIfEditorOutput } from '../../reservation-skip-offers-if-editor-output';
import { formToReservationSkipOffersIfEditorOutput } from '../form-to-reservation-skip-offers-if-editor-output/form-to-reservation-skip-offers-if-editor-output';
import {
  ReservationOfferSkipIfForm,
  ReservationOfferSkipIfNumCallsInProgressOptions,
} from '../reservation-offer-skip-if-form';

/**
 * For a given editorOutput return a sensible value of AllDataCommunicationWorkflowEditorTargetMode.
 */
export const reservationSkipOffersIfEditorOutputToForm = (
  editorOutput: ReservationSkipOffersIfEditorOutput,
): ReservationOfferSkipIfForm => {
  if (isNil(editorOutput)) {
    return formToReservationSkipOffersIfEditorOutput(defaultReservationSkipOffersIfEditorOutput);
  }

  const result: ReservationOfferSkipIfForm = {
    skipIfUnavailable: false,
    skipIfNumCallsInProgressGE: 1,
  };

  if (editorOutput.skipIfUnavailable) {
    result.skipIfUnavailable = true;
  }

  if (isNil(editorOutput.skipIfNumCallsInProgressGE)) {
    result.skipIfNumCallsInProgressGE = ReservationOfferSkipIfNumCallsInProgressOptions.neverSkip;
  } else {
    result.skipIfNumCallsInProgressGE = editorOutput.skipIfNumCallsInProgressGE;
  }

  return result;
};
