import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { isNil } from 'lodash';
import { firstValueFrom } from 'rxjs';

import { BaseLayoutTitleResolver } from '../../../../layouts/base-layout/typings/base-layout-title-resolver';
import { OrgDataService } from '../../../../services/orgs/org-data/org-data.service';

export const resolveModifyScheduleTitle: BaseLayoutTitleResolver = async (activatedRoute: ActivatedRouteSnapshot) => {
  const translocoService = inject(TranslocoService);
  const { eventType, eventId } = activatedRoute.params;

  if (isNil(eventType)) {
    return translocoService.selectTranslate('modify-schedule.title');
  }

  const orgData = await firstValueFrom(inject(OrgDataService).getOrgData());

  const eventTypeName = orgData
    .getEventTypes()
    .find((type) => type.getInternalName() === eventType)
    ?.getDisplayName();

  const type = isNil(eventId) ? 'create' : 'edit';

  return translocoService.selectTranslate(`upsert-event.title.${type}`, { eventTypeName });
};
