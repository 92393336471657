import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { isNil } from 'lodash';

import { SchemaFieldConstants, SSMLAudio, SSMLAudioSchema, SupportedLanguages } from '@pwp-common';

import { getFieldValuesFromFormInComponent } from '../../../../common/objects/form-helper';
import { KVPair } from '../../../../common/objects/kvpair';
import { ObjEditor } from '../../../generic/abstract-classes/obj-editor';
import { FileSSMLAudioPair } from '../generic/file-ssml-audio-pair';

@Component({
  selector: 'app-file-ssml-audio-pair-editor',
  templateUrl: './file-ssml-audio-pair-editor.component.html',
  styleUrls: ['./file-ssml-audio-pair-editor.component.css'],
})
export class FileSsmlAudioPairEditorComponent extends ObjEditor<KVPair<FileSSMLAudioPair>> implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  possibleLanguages = SupportedLanguages.all;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  file: File;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(_changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parameters = getFieldValuesFromFormInComponent(
      undefined,
      [SSMLAudioSchema.ssml, SSMLAudioSchema.audioURL],
      SSMLAudioSchema.Defaults,
      this,
    );

    const languageDefaults = SupportedLanguages.getDefaults(this.language.value);
    const ssmlAudio = new SSMLAudio(parameters);
    const value = new FileSSMLAudioPair(languageDefaults, ssmlAudio, this.file);
    return new KVPair({ id: this.obj.id, value });
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: KVPair<FileSSMLAudioPair>) {
    // Init Audio Form
    const formConfig = {} as any;
    formConfig[SSMLAudioSchema.ssml] = [
      obj.value?.ssmlAudio.getSSML(),
      [Validators.maxLength(SchemaFieldConstants.longStringMaxLength)],
    ];
    formConfig[SSMLAudioSchema.audioURL] = [
      obj.value?.ssmlAudio?.getAudioURL(),
      [Validators.maxLength(SchemaFieldConstants.longStringMaxLength)],
    ];
    formConfig.language = [obj.value?.languageDefaults.getShortCode(), [Validators.required]];
    this.form = this.formBuilder.group(formConfig);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  get language(): AbstractControl | null {
    return this.form.get('language');
  }

  get ssml(): AbstractControl | null {
    return this.form.get(SSMLAudioSchema.ssml);
  }

  get audioURL(): AbstractControl | null {
    return this.form.get(SSMLAudioSchema.audioURL);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Disable Option
  ///////////////////////////////////////////////////////////////////////////////////////////

  isSSMLFormOptionDisabled(element: 'audioURL' | 'ssml' | 'fileUpload') {
    const parameters = getFieldValuesFromFormInComponent(
      undefined,
      [SSMLAudioSchema.audioURL, SSMLAudioSchema.ssml],
      SSMLAudioSchema.Defaults,
      this,
    );

    const languageEqualsSelectedKey = this.language.value === this.obj?.value?.languageDefaults.getShortCode();
    const audioURLIsDefault = parameters[SSMLAudioSchema.audioURL] === SSMLAudioSchema.Defaults.audioURL;
    const ssmlIsDefault = parameters[SSMLAudioSchema.ssml] === SSMLAudioSchema.Defaults.ssml;

    let isDisabled: boolean;
    switch (element) {
      case 'fileUpload': {
        isDisabled = !audioURLIsDefault || !ssmlIsDefault || !languageEqualsSelectedKey;
        break;
      }
    }

    return isDisabled;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // File Picker Callback
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Save state indicating that a file was selected. We save the
   * file as a state variable, and upload it later.
   *
   * @param event Event indicating selected file.
   */
  onSelectFile(event: { originalEvent: any; files: FileList }) {
    const selectedFiles = Array.from(event.files);
    this.file = selectedFiles[0];
    this.updateObjAndEmit();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObjScoper = (): boolean => this.isValidObj();

  isValidObj() {
    if (!super.isValidObj()) {
      return false;
    }

    // The SSML object can't be empty.
    const parameters = getFieldValuesFromFormInComponent(
      undefined,
      [SSMLAudioSchema.audioURL, SSMLAudioSchema.ssml],
      SSMLAudioSchema.Defaults,
      this,
    );

    const fileIsDefault = isNil(this.file);
    const audioURLIsDefault = parameters[SSMLAudioSchema.audioURL] === SSMLAudioSchema.Defaults.audioURL;
    const ssmlIsDefault = parameters[SSMLAudioSchema.ssml] === SSMLAudioSchema.Defaults.ssml;

    if (fileIsDefault && audioURLIsDefault && ssmlIsDefault) {
      return false;
    }
    return true;
  }
}
