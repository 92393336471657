import Joi from 'joi';

import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { CommunicationsRequestCreateAsyncServiceRequestSchema } from '../../requests/communications-request-create-async-service-request/communications-request-create-async-service-request-schema';
import { CommunicationsRequestCreateOutboundCallSessionSchema } from '../../requests/communications-request-create-outbound-call-session/communications-request-create-outbound-call-session-schema';
import { CommunicationsRequestDialAsyncServiceRequestSchema } from '../../requests/communications-request-dial-async-service-request/communications-request-dial-async-service-request-schema';
import { CommunicationsRequestDialConferenceSchema } from '../../requests/communications-request-dial-conference/communications-request-dial-conference-schema';
import { CommunicationsRequestDialPhoneSchema } from '../../requests/communications-request-dial-phone/communications-request-dial-phone-schema';
import { CommunicationsRequestGetCallLogPIISchema } from '../../requests/communications-request-get-call-log-pii/communications-request-get-call-log-pii-schema';
import { CommunicationsRequestGetConversationLogPIISchema } from '../../requests/communications-request-get-conversation-log-pii/communications-request-get-conversation-log-pii-schema';
import { CommunicationsRequestGetVoiceDeviceTokenSchema } from '../../requests/communications-request-get-voice-device-token/communications-request-get-voice-device-token-schema';
import { CommunicationsRequestHandleAsyncServiceRequestSchema } from '../../requests/communications-request-handle-async-service-request/communications-request-handle-async-service-request-schema';
import { CommunicationsRequestOnTaskRouterConfigurationUpdatedSchema } from '../../requests/communications-request-on-task-router-configuration-updated/communications-request-on-task-router-configuration-updated-schema';

export class AnyCommunicationsRequestSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The type of the entity
  public static readonly type = 'type';

  private static readonly SCHEMA_ID = 'anyCommunicationsRequest';

  private static computedSchema = Joi.alternatives()
    .try(
      ...[
        new CommunicationsRequestCreateAsyncServiceRequestSchema().getJoiSchema(),
        new CommunicationsRequestCreateOutboundCallSessionSchema().getJoiSchema(),
        new CommunicationsRequestDialAsyncServiceRequestSchema().getJoiSchema(),
        new CommunicationsRequestDialConferenceSchema().getJoiSchema(),
        new CommunicationsRequestDialPhoneSchema().getJoiSchema(),
        new CommunicationsRequestGetCallLogPIISchema().getJoiSchema(),
        new CommunicationsRequestGetConversationLogPIISchema().getJoiSchema(),
        new CommunicationsRequestGetVoiceDeviceTokenSchema().getJoiSchema(),
        new CommunicationsRequestHandleAsyncServiceRequestSchema().getJoiSchema(),
        new CommunicationsRequestOnTaskRouterConfigurationUpdatedSchema().getJoiSchema(),
      ],
    )
    .id(AnyCommunicationsRequestSchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static GenericDefaults = class {
    // The name of the command
    public static readonly type = undefined;
  };

  /**
   * Always serialize the command name
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  public static getSchemaId(): string {
    return `#${AnyCommunicationsRequestSchema.SCHEMA_ID}`;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } | import('joi').AnySchema {
    return AnyCommunicationsRequestSchema.computedSchema;
  }
}
