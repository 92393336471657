import { isNil } from 'lodash';

import {
  CallEntrypoint,
  getTimeSelectionFromCallEntrypoint,
  Hangup,
  SerializableObjectWithNoParams,
  VRCIfTime,
} from '@pwp-common';

import { AvailableHoursMode } from '../available-hours-mode';
import { PhoneLineEditorOutput } from '../phone-line-editor-output';

export const createPhoneLineEditorOutputFromCallEntrypoint = (
  callEntrypoint: CallEntrypoint,
): PhoneLineEditorOutput => {
  const availableHours = getTimeSelectionFromCallEntrypoint(callEntrypoint)?.getComplement() ?? null;

  const existingIfTime = callEntrypoint.getCommands().find((command) => command instanceof VRCIfTime) as
    | VRCIfTime
    | undefined;
  const existingCommands = existingIfTime?.getCommands() ?? [];
  const closedHoursCommands = existingCommands.reduce((acc, command) => {
    if (command instanceof Hangup) {
      return acc;
    }

    return [...acc, (command as unknown as SerializableObjectWithNoParams).serialize()];
  }, []);

  return {
    callEntrypoint,
    closedHoursCommands,
    availableHours,
    availableHoursMode: isNil(availableHours) ? AvailableHoursMode.alwaysAvailable : AvailableHoursMode.custom,
  };
};
