import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatestWith } from 'rxjs';
import { map } from 'rxjs/operators';

import { PTableCol } from '../../../../../common/p-table/p-table-col';
import { EventRequestConfigService } from '../../../../../services/event/event-requests/event-request-config.service';
import { OrgDataService } from '../../../../../services/orgs/org-data/org-data.service';
import { SettingsEditorTableBase } from '../../../../generic/settings/abstract-classes/settings-editor-table-base';
import { EventRequestConfigTableRow } from '../event-request-config-table-row/event-request-config-table-row';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-event-request-config-table',
  templateUrl: './event-request-config-table.component.html',
  styleUrls: ['./event-request-config-table.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class EventRequestConfigTableComponent
  extends SettingsEditorTableBase<EventRequestConfigTableRow>
  implements OnInit
{
  ///////////////////////////////////////////////////////////////////////
  // Constants
  ///////////////////////////////////////////////////////////////////////

  cols: PTableCol[] = [
    { field: 'eventType', header: 'colEventType' },
    { field: 'rolePrimary', header: 'colPrimary' },
    { field: 'roleBackup', header: 'colBackup' },
  ];

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  constructor(
    private orgDataService: OrgDataService,
    private eventRequestConfigService: EventRequestConfigService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Data
  ///////////////////////////////////////////////////////////////////////
  getData() {
    this.loading = true;
    this.tableRows = this.eventRequestConfigService.getDocsArray().pipe(
      untilDestroyed(this),
      combineLatestWith(this.orgDataService.getOrgData()),
      map(([eventRequestConfig, orgData]) => {
        const orderedEventTypes = orgData.getEventTypes();
        const tableRows = orderedEventTypes.map((eventType) => {
          const eventRequestConfigData = eventRequestConfig.find((event) => event.getId() === eventType.getId());
          return new EventRequestConfigTableRow({ eventType, eventRequestConfigData });
        });
        this.loading = false;
        this.changeDetectorRef.detectChanges();
        return tableRows;
      }),
    );
  }
}
