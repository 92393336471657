import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';

import {
  ConversationMessageTemplate,
  ConversationMessageTemplateType,
  DBDocSchema,
  SupportedLanguages,
  TemplateText,
} from '@pwp-common';

import { getFieldControlValueOrDefault } from '../../../../common/objects/form-helper';
import { ConversationMessageTemplateService } from '../../../../services/conversation/conversation-message-template/conversation-message-template.service';
import { ComponentWithForm } from '../../../generic/abstract-classes/component-with-form';
import { makeAuditEntry } from '../../../generic/settings/common/audit-entry/helpers/make-audit-entry';
import { AuditEntry } from '../../../generic/settings/common/audit-entry/interfaces';

@Component({
  selector: 'app-distinguished-conversation-message-template-editor',
  templateUrl: './distinguished-conversation-message-template-editor.component.html',
  styleUrls: ['./distinguished-conversation-message-template-editor.component.css'],
})
export class DistinguishedConversationMessageTemplateEditorComponent extends ComponentWithForm implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Inputs
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  @Input()
  cardTitle: string;

  @Input()
  tempateType: ConversationMessageTemplateType;

  @Input()
  textBoxLabel: string;

  @Input()
  textBoxPlaceholder: string;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  auditEntry: AuditEntry;

  controlNameTextBox = 'textBox';

  uploadInProgress = false;

  conversationMessageTemplateObj: ConversationMessageTemplate;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private conversationMessageTemplateService: ConversationMessageTemplateService,
    private formBuilder: UntypedFormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Get Data
  /////////////////////////////////////////////////////////////////////////////////////////////

  refreshData = () => this.getDataPromise();

  getDataPromise() {
    return this.conversationMessageTemplateService
      .getTemplateOfType(this.tempateType)
      .toPromise()
      .then((result) => {
        this.conversationMessageTemplateObj = result;
        this.auditEntry = makeAuditEntry(result);
      });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  resetForm = () => this.defineForm();

  public defineForm() {
    // Init Form
    const formConfig = {} as any;
    formConfig[this.controlNameTextBox] = [
      this.conversationMessageTemplateObj?.getBodyInLang(SupportedLanguages.en) || '',
      [Validators.maxLength(1600)],
    ];

    this.form = this.formBuilder.group(formConfig);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Get Obj From Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm(): ConversationMessageTemplate {
    const textBoxValue = getFieldControlValueOrDefault(this.textBox, '');

    const templateText = new TemplateText({ text: new Map([[SupportedLanguages.en.getShortCode(), textBoxValue]]) });

    const obj = new ConversationMessageTemplate({
      id: this.conversationMessageTemplateObj?.getId() || DBDocSchema.GenericDefaults.id,
      displayName: '',
      description: '',
      type: this.tempateType,
      body: templateText,
    });
    return obj;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Form Field Getters
  /////////////////////////////////////////////////////////////////////////////////////////////

  get textBox(): AbstractControl | null {
    return this.form.get(this.controlNameTextBox);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Buttons
  /////////////////////////////////////////////////////////////////////////////////////////////

  doUpload = () => this.upload();

  async upload() {
    const obj = this.getObjFromForm();
    await this.conversationMessageTemplateService.upload({ obj }).toPromise();
  }
}
