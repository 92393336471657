<ng-container *transloco="let t; read: 'phone-line-editor'">
  <app-settings
    [auditEntry]="callEntrypoint | auditEntry"
    [doUpload]="save"
    [editorTemplate]="editor"
    [isValid]="isValid"
    [refreshData]="refreshData"
    [resetForm]="resetForm"
  >
    <ng-template #editor>
      <p-selectButton
        class="p-fluid phone-line-editor__mode-switch"
        [formControl]="form.controls.availableHoursMode"
        [options]="[
          { label: 'Open 24/7', value: 'alwaysAvailable' },
          { label: 'Limited hours', value: 'custom' },
        ]"
      >
      </p-selectButton>
      <p-messages
        *ngIf="form.controls.availableHoursMode.value === 'alwaysAvailable'"
        class="phone-line-editor__message"
        [severity]="'info'"
      >
        <ng-template pTemplate>
          <span>
            {{ t('alwaysAvailableDescription') }}
          </span>
        </ng-template>
      </p-messages>
      <div class="phone-line-editor__form">
        <ng-container *ngIf="form.controls.availableHoursMode.value === 'custom'">
          <app-edit-available-hours-by-dow
            #availableHoursByDow
            [description]="t('availableHoursDescription')"
            [obj]="editHoursInput$ | async"
            (objChange)="availableHours$.next($event.timeSelection)"
          ></app-edit-available-hours-by-dow>
          <app-accordion-wizard-steps *ngIf="availableHoursByDow.hasUnavailableTime$ | async">
            <app-accordion-wizard-step [label]="t('closedHoursConfigTitle')">
              <app-service-operation-editor [formControl]="form.controls.closedHoursConfig">
              </app-service-operation-editor>
            </app-accordion-wizard-step>
          </app-accordion-wizard-steps>
        </ng-container>
      </div>
    </ng-template>
  </app-settings>
</ng-container>
