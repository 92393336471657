import { cloneDeep, isEqual } from 'lodash';

import { DBDocObject } from '../../generic/db-doc/db-doc-object';
import { DBDocSchema } from '../../generic/db-doc/db-doc-schema';

import { RolesConstructor } from './roles-constructor';
import { RolesSchema } from './roles-schema';
import { isPossibleRole, simplifyRoles } from './simplify-roles';
import { PossibleRole } from './types';

export class Roles extends DBDocObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected roles!: PossibleRole[];

  protected orgNote!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: RolesConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): Roles {
    return new Roles(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new RolesSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getUserId(): string {
    return cloneDeep(this.getId());
  }

  public getRoles(): PossibleRole[] {
    return cloneDeep(this.roles);
  }

  public getOrgNote(): string {
    return cloneDeep(this.orgNote);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  public setRoles(roles: PossibleRole[]): Roles {
    this.roles = simplifyRoles(roles);
    return this;
  }

  public setOrgNote(orgNote: string): Roles {
    this.orgNote = orgNote;
    return this;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////

  public canAccessConversation(): boolean {
    if (this.isOrgAdmin() || this._hasRole(RolesSchema.Roles.conversation)) {
      return true;
    }
    return false;
  }

  public isOrgAdmin(): boolean {
    if (this._hasRole(RolesSchema.Roles.orgAdmin)) {
      return true;
    }
    return false;
  }

  public canAccessSupport(): boolean {
    if (this.isOrgAdmin() && this._hasRole(RolesSchema.Roles.support)) {
      return true;
    }
    return false;
  }

  public canAccessVoicemail(): boolean {
    if (this.isOrgAdmin() || this._hasRole(RolesSchema.Roles.voicemail)) {
      return true;
    }
    return false;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Has Role
  /////////////////////////////////////////////////////////////////////////////

  public _hasRole(role: PossibleRole): boolean {
    isPossibleRole(role);

    return this.roles.includes(role);
  }

  public hasRoles(roles: PossibleRole[]): boolean {
    for (const role of roles) {
      switch (role) {
        case 'conversation': {
          if (!this.canAccessConversation()) {
            return false;
          }
          break;
        }
        case 'orgAdmin': {
          if (!this.isOrgAdmin()) {
            return false;
          }
          break;
        }
        case 'support': {
          if (!this.canAccessSupport()) {
            return false;
          }
          break;
        }
        case 'voicemail': {
          if (!this.canAccessVoicemail()) {
            return false;
          }
          break;
        }
        default: {
          return false;
        }
      }
    }
    return true;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(Roles.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Sanity Check
  /////////////////////////////////////////////////////////////////////////////

  sanityCheck() {
    if (!isEqual(this.roles, simplifyRoles(this.roles))) {
      console.error({
        roles: this.roles,
        simplifiedRoles: simplifyRoles(this.roles),
      });
      throw new Error('User Error: Roles are not simplified');
    }
  }
}
