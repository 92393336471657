import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { cloneDeep, isNil } from 'lodash';
import moment from 'moment-timezone';

import {
  ConversationConfig,
  ConversationConfigSchema,
  ConversationRequestConfigure,
  ConversationRequestConfigureSchema,
  IdentityConfigConversation,
  IdentityConfigConversationCaptureTarget,
  OrgData,
} from '@pwp-common';

import { getFieldControlValueOrDefault, getFieldValuesFromFormInComponent } from '../../../common/objects/form-helper';
import { URLValidator } from '../../../common/validators/url-validator/url-validator';
import { ConversationConfigService } from '../../../services/conversation/conversation-config/conversation-config.service';
import { ConversationEndpointService } from '../../../services/conversation/conversation-endpoint/conversation-endpoint.service';
import { OrgDataService } from '../../../services/orgs/org-data/org-data.service';
import { EditAvailableHoursByDowInput } from '../../core/edit-available-hours-by-dow/edit-available-hours-by-dow-input';
import { makeAuditEntry } from '../../generic/settings/common/audit-entry/helpers/make-audit-entry';
import { AuditEntry } from '../../generic/settings/common/audit-entry/interfaces';

@Component({
  selector: 'app-conversation-config-editor',
  templateUrl: './conversation-config-editor.component.html',
  styleUrls: ['./conversation-config-editor.component.css'],
})
export class ConversationConfigEditorComponent implements OnInit {
  private readonly captureIdentityToVendorEnabledFieldName = 'captureIdentityToVendorEnabled';

  private conversationConfig: ConversationConfig;

  private orgData: OrgData;

  public readonly form = new UntypedFormGroup({
    inactiveAfterDuration: new UntypedFormControl(),
    closedAfterDuration: new UntypedFormControl(),
    pIIRetentionDuration: new UntypedFormControl(),
    redirectURL: new UntypedFormControl('', URLValidator.url()),
    waitingRoomConfigEnabled: new UntypedFormControl(),
    captureIdentityToVendorEnabled: new UntypedFormControl(),
    communicationWorkflowId: new UntypedFormControl(),
  });

  public loading = true;

  public auditEntry: AuditEntry;

  public availableTimesEditorInput: EditAvailableHoursByDowInput;

  ////////////////////////////////////////////////////////////////////////////
  // Constructor
  ////////////////////////////////////////////////////////////////////////////
  constructor(
    private conversationConfigService: ConversationConfigService,
    private conversationEndpointService: ConversationEndpointService,
    private orgDataService: OrgDataService,
  ) {}

  ////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////////

  public async ngOnInit(): Promise<void> {
    this.refreshData();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  public updateUI = () => {
    if (isNil(this.orgData) || isNil(this.conversationConfig)) {
      return;
    }
    this.loading = true;
    this.availableTimesEditorInput = {
      timezone: this.orgData.getTimezone(),
      timeSelection: this.conversationConfig.getAvailableTimes(),
    };

    const formValues = {
      [ConversationRequestConfigureSchema.inactiveAfterDuration]: moment
        .duration(this.conversationConfig.getInactiveAfterDuration())
        .asMinutes(),
      [ConversationRequestConfigureSchema.closedAfterDuration]: moment
        .duration(this.conversationConfig.getClosedAfterDuration())
        .asMinutes(),
      [ConversationRequestConfigureSchema.pIIRetentionDuration]: moment
        .duration(this.conversationConfig.getPIIRetentionDuration())
        .asHours(),
      [ConversationRequestConfigureSchema.redirectURL]:
        this.conversationConfig.getRedirectURL() ?? ConversationRequestConfigureSchema.redirectURL,
      [ConversationRequestConfigureSchema.waitingRoomConfigEnabled]:
        this.conversationConfig.getWaitingRoomConfig().getEnabled() ?? false,
      [ConversationRequestConfigureSchema.communicationWorkflowId]:
        this.conversationConfig.getCommunicationWorkflowId(),
      [this.captureIdentityToVendorEnabledFieldName]:
        this.conversationConfig.getIdentityConfig().getCaptureTarget() ===
        IdentityConfigConversationCaptureTarget.vendor,
    };

    this.form.patchValue(formValues);
    this.auditEntry = makeAuditEntry(this.conversationConfig);
    this.loading = false;
  };

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Get Data
  /////////////////////////////////////////////////////////////////////////////////////////////

  public refreshData = async () => {
    this.loading = true;
    return Promise.all([
      this.orgDataService.getOrgData().toPromise(),
      this.conversationConfigService.getUniqueDoc({ query: [] }).toPromise(),
    ]).then((result) => {
      this.orgData = result[0];
      this.conversationConfig = result[1];
      this.updateUI();
    });
  };

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Do Upload
  /////////////////////////////////////////////////////////////////////////////////////////////

  public upload = async () => {
    const defaults = {} as any;
    defaults[ConversationRequestConfigureSchema.waitingRoomConfigEnabled] = false;
    defaults[ConversationRequestConfigureSchema.redirectURL] = ConversationRequestConfigureSchema.Defaults.redirectURL;

    const parameters = getFieldValuesFromFormInComponent(
      undefined,
      [
        ConversationRequestConfigureSchema.waitingRoomConfigEnabled,
        ConversationRequestConfigureSchema.redirectURL,
        ConversationRequestConfigureSchema.communicationWorkflowId,
      ],
      defaults,
      this.form.controls,
    );

    const captureIdentityToVendorEnabled = getFieldControlValueOrDefault(this.captureIdentityToVendorEnabled, false);

    if (parameters[ConversationRequestConfigureSchema.redirectURL] === '') {
      parameters[ConversationRequestConfigureSchema.redirectURL] =
        ConversationRequestConfigureSchema.Defaults.redirectURL;
    }

    let identityConfig = cloneDeep(ConversationConfigSchema.Defaults.identityConfig);
    if (captureIdentityToVendorEnabled === true) {
      identityConfig = new IdentityConfigConversation({
        captureTarget: IdentityConfigConversationCaptureTarget.vendor,
      });
    }

    const inactiveAfterDuration = moment
      .duration(this.form.value[ConversationConfigSchema.inactiveAfterDuration], 'minutes')
      .toISOString();
    const closedAfterDuration = moment
      .duration(this.form.value[ConversationConfigSchema.closedAfterDuration], 'minutes')
      .toISOString();
    const pIIRetentionDuration = moment
      .duration(this.form.value[ConversationConfigSchema.pIIRetentionDuration], 'hours')
      .toISOString();

    const uploadObj = new ConversationRequestConfigure({
      ...parameters,
      type: 'configure',
      inactiveAfterDuration,
      closedAfterDuration,
      pIIRetentionDuration,
      availableTimes: this.availableTimesEditorInput.timeSelection,
      conversationConfigId: this.conversationConfig.getId(),
      identityConfig,
    });

    await this.conversationEndpointService.configure(uploadObj);
  };

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid
  /////////////////////////////////////////////////////////////////////////////////////////////

  public isValid = (): boolean => this.form.valid;

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////
  public get inactiveAfterDuration() {
    return this.form.get(ConversationRequestConfigureSchema.inactiveAfterDuration) as UntypedFormControl;
  }

  public get closedAfterDuration() {
    return this.form.get(ConversationRequestConfigureSchema.closedAfterDuration) as UntypedFormControl;
  }

  public get pIIRetentionDuration() {
    return this.form.get(ConversationRequestConfigureSchema.pIIRetentionDuration) as UntypedFormControl;
  }

  public get redirectURL() {
    return this.form.get(ConversationRequestConfigureSchema.redirectURL) as UntypedFormControl;
  }

  public get waitingRoomConfigEnabled() {
    return this.form.get(ConversationRequestConfigureSchema.waitingRoomConfigEnabled) as UntypedFormControl;
  }

  public get captureIdentityToVendorEnabled() {
    return this.form.get(this.captureIdentityToVendorEnabledFieldName) as UntypedFormControl;
  }

  public get communicationWorkflowId() {
    return this.form.get('communicationWorkflowId') as UntypedFormControl;
  }
}
