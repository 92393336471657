import { ChangeDetectionStrategy, Component, OnChanges, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';

import { CallListService } from '../../../../../services/call/call-list/call-list.service';
import { SettingsRowEditorBase } from '../../../../generic/settings/abstract-classes/settings-row-editor-base';
import { makeAuditEntry } from '../../../../generic/settings/common/audit-entry/helpers/make-audit-entry';
import { CallListEditorOutput } from '../../editor/editor-output/call-list-editor-output';
import { getUpdatedCallList } from '../../editor/get-updated-call-list/get-updated-call-list';
import { CallListTableRow } from '../call-list-table-row/call-list-table-row';

@Component({
  selector: 'app-call-list-table-editor-expansion',
  templateUrl: './call-list-table-editor-expansion.component.html',
  styleUrls: ['./call-list-table-editor-expansion.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class CallListTableEditorExpansionComponent
  extends SettingsRowEditorBase<CallListEditorOutput, CallListTableRow>
  implements OnChanges, OnInit
{
  ///////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    messageService: MessageService,
    private callListService: CallListService,
  ) {
    super(messageService);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Audit Entry
  ///////////////////////////////////////////////////////////////////////////////////////////
  updateAuditEntry() {
    this.auditEntry = makeAuditEntry(this.row.data.callList);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Upload
  ///////////////////////////////////////////////////////////////////////////////////////////
  protected async uploadEditorOutput(
    editorOutput: CallListEditorOutput,
  ): Promise<{ updateSucceeded: boolean; objId: string }> {
    const requestedUpdate = getUpdatedCallList({ originalCallList: this.row.data.callList, editorOutput });
    const updateSucceeded = await this.callListService.uploadIfNotEditingStaleObject(requestedUpdate);
    return { objId: requestedUpdate.getId(), updateSucceeded };
  }
}
