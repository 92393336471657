<ng-container *transloco="let t; read: 'change-my-primary-email'">
  <!-- Toast notifications configuration -->
  <p-toast position="bottom-center"></p-toast>

  <form *ngIf="!loading" [formGroup]="form">
    <h3>{{ t('title') }}</h3>
    <!-- Current Email -->
    <div class="form-row">
      <div class="col">
        <h6>{{ t('currentEmailAddress') }}: {{ (angularFireAuth.user | async)?.email }}</h6>
      </div>
    </div>
    <!-- New Email Address -->
    <div class="form-row">
      <div class="col">
        <mat-form-field class="full-width">
          <input
            class="full-width"
            formControlName="newEmailAddress"
            matInput
            placeholder="{{ t('newEmailAddress') }}"
          />
          <div *ngIf="newEmailAddress.invalid && newEmailAddress.dirty">
            <mat-error *ngIf="newEmailAddress.errors?.required">
              {{ t('errorRequired') }}
            </mat-error>
            <mat-error *ngIf="newEmailAddress.errors?.email">
              {{ t('errorInvalidEmail') }}
            </mat-error>
          </div>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="full-width">
          <input
            appMustMatchErrorStateMatcher
            class="full-width"
            formControlName="confirmNewEmailAddress"
            matInput
            placeholder="{{ t('confirmNewEmailAddress') }}"
          />
          <div *ngIf="confirmNewEmailAddress.invalid && confirmNewEmailAddress.dirty">
            <mat-error *ngIf="confirmNewEmailAddress.errors?.required">
              {{ t('errorRequired') }}
            </mat-error>
            <mat-error *ngIf="confirmNewEmailAddress.errors?.email">
              {{ t('errorInvalidEmail') }}
            </mat-error>
          </div>
          <mat-error *ngIf="form.errors?.mustMatch">
            {{ t('errorMustMatch') }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <!-- Password -->
    <div class="form-row">
      <div class="col">
        <mat-form-field class="full-width">
          <input
            class="full-width"
            formControlName="password"
            matInput
            type="password"
            placeholder="{{ t('password') }}"
          />
          <mat-error *ngIf="password.invalid && password.dirty">
            {{ t('errorRequired') }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
  <p-button
    [disabled]="!form?.valid || emailChangeInProgress"
    label="{{ t('saveButton') }}"
    [loading]="emailChangeInProgress"
    (onClick)="confirmAndChange()"
  ></p-button>
</ng-container>
