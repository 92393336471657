import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';

import { DBDocObject } from '../../generic/db-doc/db-doc-object';
import { ConversationSummary } from '../conversation-summary/conversation-summary';

import { ConversationLogConstructor } from './conversation-log-constructor';
import { ConversationLogSchema } from './conversation-log-schema';
import { ConversationState, ConversationStateType } from './conversation-log-state';

export class ConversationLog extends DBDocObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected chatServiceSid!: string | undefined;

  protected conversationConfigId!: string;

  protected conversationSid!: string;

  protected messagingServiceSid!: string | undefined;

  protected state!: ConversationStateType;

  protected summary!: ConversationSummary | undefined;

  protected closeTime!: moment.Moment | undefined;

  protected firstUserMessageTime!: moment.Moment | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ConversationLogConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ConversationLog {
    return new ConversationLog(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(ConversationLog.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): ConversationLogSchema {
    return new ConversationLogSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getChatServiceSid(): string | undefined {
    return cloneDeep(this.chatServiceSid);
  }

  public getConversationConfigId(): string {
    return cloneDeep(this.conversationConfigId);
  }

  public getConversationSid(): string {
    return cloneDeep(this.conversationSid);
  }

  public getMessagingServiceSid(): string | undefined {
    return cloneDeep(this.messagingServiceSid);
  }

  public getState(): ConversationStateType {
    return cloneDeep(this.state);
  }

  public getSummary(): ConversationSummary | undefined {
    return cloneDeep(this.summary);
  }

  public getCloseTime(): moment.Moment | undefined {
    return cloneDeep(this.closeTime);
  }

  public getFirstUserMessageTime(): moment.Moment | undefined {
    return cloneDeep(this.firstUserMessageTime);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  public setFirstUserMessageTime(firstUserMessageTime: moment.Moment | undefined): ConversationLog {
    this.firstUserMessageTime = firstUserMessageTime;
    return this;
  }

  public setSummary(summary: ConversationSummary): ConversationLog {
    this.summary = summary;
    return this;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Was Answered
  /////////////////////////////////////////////////////////////////////////////

  public wasAnswered(): boolean {
    switch (this.getState()) {
      case ConversationState.serviceRequested: {
        return false;
      }
      case ConversationState.serviceDeliveryInProgress: {
        return true;
      }
      default: {
        const firstAnsweredBy = this.summary?.getFirstAnsweredBy();
        if (firstAnsweredBy === undefined) {
          return false;
        }
        return true;
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Hold Duration
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Return the number of milliseconds that the user was waiting
   * for an advocate.. If the user has not started holding, then return 0.
   *
   * If the user is currently holding, then return the number
   * of milliseconds they have been on hold.
   */
  public getHoldDurationMS(): number {
    // Defensive code which determines value of timestamps that should never be undefined in the
    // cases they are used below.
    const valueOfServiceRequestTime = this.createTime?.valueOf() || 0;
    const valueOfServiceDeliveryStartTime = this.firstUserMessageTime?.valueOf() || 0;
    const valueOfCloseTime = this.closeTime?.valueOf() || 0;

    switch (this.getState()) {
      case ConversationState.serviceRequested: {
        // Client currently on hold
        return moment.now().valueOf() - valueOfServiceRequestTime;
      }
      case ConversationState.serviceDeliveryInProgress: {
        // Client completed entire hold duration, but summary
        return valueOfServiceDeliveryStartTime - valueOfServiceRequestTime;
      }
      default: {
        // Client completed entire hold duration
        // Case: No service was delivered. This happens if the client was on hold for the entire duration of their call.
        if (this.wasAnswered()) {
          // Case: Service delivery was attempted. Namely that, the some userId sent a message to the chat.
          return valueOfServiceDeliveryStartTime - valueOfServiceRequestTime;
        }
        return valueOfCloseTime - valueOfServiceRequestTime;
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Talk Duration
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Return the duration in milliseconds that the call was connected. This excludes
   * time that the client was on hold.
   *
   * If the client is currently on hold, return 0.
   * If the client is currently talking, return the duration so far.
   */
  public getTalkDurationMS(): number {
    const valueOfServiceDeliveryStartTime = this.firstUserMessageTime?.valueOf() || 0;
    const valueOfCloseTime = this.closeTime?.valueOf() || 0;

    switch (this.getState()) {
      case ConversationState.serviceRequested: {
        // Conversation has not started yet.
        return 0;
      }
      case ConversationState.serviceDeliveryInProgress: {
        // Case: User currently talking
        return moment().valueOf() - valueOfServiceDeliveryStartTime;
      }
      default: {
        if (!this.wasAnswered()) {
          return 0;
        }
        return valueOfCloseTime - valueOfServiceDeliveryStartTime;
      }
    }
  }
}
