<ng-container *transloco="let t; read: 'inbox-callback-detail-caller-details'">
  <ng-template #spinner>
    <app-progress-spinner></app-progress-spinner>
  </ng-template>

  <p-card *ngIf="data$ | async as data; else spinner" [header]="t('title')">
    <ng-template pTemplate="content">
      <div class="flex flex-column gap-3 align-items-baseline">
        <div *ngIf="data.e164Phone === undefined && data.notes.length === 0">
          {{ t('emptyMessage') }}
        </div>

        <div *ngIf="data.e164Phone !== undefined">
          <!-- Phone Number -->
          <label class="flex flex-column">
            {{ t('e164PhoneLabel') }}
            <input pInputText type="text" [disabled]="true" value="{{ data.e164Phone }}" />
          </label>
        </div>

        <div *ngFor="let note of data.notes" class="w-full">
          <label class="flex flex-column">
            <span class="flex w-auto gap-1 align-items-center">
              {{ t('noteLabel') }}
              <!-- Note: aria-description can and should be removed once PrimeNG is updated to v15 -->
              <i
                *ngIf="!note.isRedacted"
                class="pi pi-info-circle text-xs"
                tooltipStyleClass="max-w-25rem"
                [attr.aria-description]="t('noteCreationInfo', note)"
                [pTooltip]="t('noteCreationInfo', note)"
              ></i>
            </span>

            <textarea
              pInputTextarea
              [disabled]="true"
              [value]="note.isRedacted ? t('noteRedactedValue') : note.value"
            ></textarea>
          </label>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <ng-container *ngIf="data.dataRetentionEndFormatted">{{
        t('dialogFooter', { dataRetentionEnd: data.dataRetentionEndFormatted })
      }}</ng-container>
    </ng-template>
  </p-card>
</ng-container>
