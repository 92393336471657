import { DBDocSchema } from '../../generic/db-doc/db-doc-schema';
import { SchemaField } from '../../generic/serialization/schema-field';

export class UsageSchema extends DBDocSchema {
  public static readonly windowStart = 'windowStart';

  public static readonly windowEnd = 'windowEnd';

  public static readonly asOf = 'asOf';

  public static readonly category = 'category';

  public static readonly usage = 'usage';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'usage';
  };

  static Defaults = class {
    public static readonly windowStart = undefined;

    public static readonly windowEnd = undefined;

    public static readonly asOf = undefined;

    public static readonly category = undefined;

    public static readonly usage = 0;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Default Values
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([UsageSchema.usage]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[UsageSchema.windowStart] = SchemaField.timestamp(UsageSchema.Defaults.windowStart);
    parameters[UsageSchema.windowEnd] = SchemaField.timestamp(UsageSchema.Defaults.windowEnd);
    parameters[UsageSchema.asOf] = SchemaField.timestamp(UsageSchema.Defaults.asOf);
    parameters[UsageSchema.category] = SchemaField.string(UsageSchema.Defaults.category);
    parameters[UsageSchema.usage] = SchemaField.number(UsageSchema.Defaults.usage);
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${UsageSchema.Constants.collection}`;
  }
}
