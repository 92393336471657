import { cloneDeep, isNil } from 'lodash';

import { SerializableObjectSchema } from '../../../../../generic/serialization/serializable-object-schema';
import { DBMatchRule } from '../../generic/db-match-rule/db-match-rule';
import { DBMatchRuleSchema } from '../../generic/db-match-rule/db-match-rule-schema';
import { DBMatchRuleName } from '../../generic/db-match-rule-name';

import { DBMatchRuleE164PhoneConstructor } from './db-match-rule-e164-phone-constructor';
import { DBMatchRuleE164PhoneSchema } from './db-match-rule-e164-phone-schema';

export class DBMatchRuleE164Phone extends DBMatchRule {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected e164Phones!: string[];

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: DBMatchRuleE164PhoneConstructor) {
    (parameters as any)[DBMatchRuleSchema.type] = DBMatchRuleName.e164Phone;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): DBMatchRuleE164Phone {
    return new DBMatchRuleE164Phone(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(DBMatchRuleE164Phone.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new DBMatchRuleE164PhoneSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Make QueryId
  /////////////////////////////////////////////////////////////////////////////

  public static makeQueryId(e164Phone: string): string {
    if (isNil(e164Phone)) {
      throw new Error('DBMatchRuleE164Phone.makeQueryId: User Error, cannot match nil');
    }
    return `${DBMatchRuleName.e164Phone}_${e164Phone}`;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Query IDs
  /////////////////////////////////////////////////////////////////////////////

  public getQueryIds(): string[] {
    return [...this.getE164Phones().map((z) => DBMatchRuleE164Phone.makeQueryId(z))];
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getE164Phones(): string[] {
    return cloneDeep(this.e164Phones);
  }
}
