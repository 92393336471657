import { cloneDeep, isNil } from 'lodash';

import { AllDataServiceException } from '@pwp-common';

import { makeServiceOperationEditorOutput } from '../../../../service-operation-editor/editor-output/make-service-operation-editor-output/make-service-operation-editor-output';
import { defaultServiceExceptionEditorOutput } from '../../service-exception-editor/default-value';
import { ServiceExceptionEditorOutput } from '../service-exception-editor-output';

export const makeServiceExceptionEditorOutput = (
  input: AllDataServiceException | ServiceExceptionEditorOutput,
): ServiceExceptionEditorOutput => {
  if (isNil(input)) {
    return cloneDeep(defaultServiceExceptionEditorOutput);
  }

  if (input instanceof AllDataServiceException) {
    const result: ServiceExceptionEditorOutput = {
      id: input.serviceException.getId(),
      displayName: input.serviceException.getDisplayName(),
      description: input.serviceException.getDescription(),
      e164PhoneMatchers: input.serviceException.getMatcher().getMatchedE164Phones(),
      onCreateServiceCommands: { commands: [] },
    };

    // Parse onCreateServiceCommands
    result.onCreateServiceCommands = makeServiceOperationEditorOutput(
      input.inboundCallSessionAction.parameters.onCreate,
    );

    return result;
  }

  return input;
};
