import { cloneDeep } from 'lodash';

import { SerializableObject } from '../../../../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../../../../generic/serialization/serializable-object-schema';
import { ReservationSkipOffersIfRuleType } from '../reservation-skip-offers-if-rule-name';

export abstract class ReservationSkipOffersIfRule extends SerializableObject {
  protected type!: ReservationSkipOffersIfRuleType;

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    throw new Error('Error: Cannot get schema for abstract DBMatchRule');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getType(): ReservationSkipOffersIfRuleType {
    return cloneDeep(this.type);
  }
}
