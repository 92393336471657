import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogService } from '@ngneat/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MessageService } from 'primeng/api';
import { take } from 'rxjs/operators';

import { mustMatch } from '../../../../common/validators/must-match/must-match';
import { ComponentWithForm } from '../../../generic/abstract-classes/component-with-form';

@UntilDestroy()
@Component({
  selector: 'app-change-my-primary-email',
  templateUrl: './change-my-primary-email.component.html',
  styleUrls: ['./change-my-primary-email.component.css'],
  providers: [MessageService],
})
export class ChangeMyPrimaryEmailComponent extends ComponentWithForm implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  emailChangeInProgress = false;

  controlNameNewEmailAddress = 'newEmailAddress';

  controlNameConfirmNewEmailAddress = 'confirmNewEmailAddress';

  controlNamePassword = 'password';

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<ChangeMyPrimaryEmailComponent>,
    private formBuilder: UntypedFormBuilder,
    public angularFireAuth: AngularFireAuth,
    private messageService: MessageService,
    private translocoService: TranslocoService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected defineForm() {
    // Init Form
    const formConfig = {} as any;
    formConfig[this.controlNameNewEmailAddress] = ['', [Validators.required, Validators.email]];
    formConfig[this.controlNameConfirmNewEmailAddress] = ['', [Validators.required, Validators.email]];
    formConfig[this.controlNamePassword] = ['', [Validators.required]];

    this.form = this.formBuilder.group(formConfig, {
      validator: mustMatch(this.controlNameNewEmailAddress, this.controlNameConfirmNewEmailAddress),
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Form Field Getters
  /////////////////////////////////////////////////////////////////////////////////////////////

  get newEmailAddress(): AbstractControl | null {
    return this.form.get(this.controlNameNewEmailAddress);
  }

  get confirmNewEmailAddress(): AbstractControl | null {
    return this.form.get(this.controlNameConfirmNewEmailAddress);
  }

  get password(): AbstractControl | null {
    return this.form.get(this.controlNamePassword);
  }

  /////////////////////////////////////////////////////////////////////////////////
  // Upload Action
  /////////////////////////////////////////////////////////////////////////////////

  confirmAndChange() {
    const title = this.translocoService.translate('change-my-primary-email.confirmChangeTitle');
    const body = this.translocoService.translate('change-my-primary-email.confirmChangeBody');

    this.dialogService
      .confirm({
        title,
        body,
      })
      .afterClosed$.subscribe(async (confirmed) => {
        if (!confirmed) {
          return;
        }
        try {
          await this.changeEmail();
        } catch (error) {
          this.failureMessage(error);
        }
      });
  }

  async changeEmail() {
    this.emailChangeInProgress = true;

    const currentUser = await this.angularFireAuth.user.pipe(take(1)).toPromise();
    try {
      await this.angularFireAuth.signInWithEmailAndPassword(currentUser.email, this.password.value);
    } catch (error) {
      console.error(error);
      this.failureMessage(error);
      this.emailChangeInProgress = false;
      return;
    }

    try {
      await currentUser.verifyBeforeUpdateEmail(this.newEmailAddress.value);
      this.successMessage();
    } catch (error) {
      console.error(error);
      this.failureMessage(error);
    }
    this.emailChangeInProgress = false;
    this.changeDetectorRef.detectChanges();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Notifications
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  private successMessage() {
    const successTitle = this.translocoService.translate('change-my-primary-email.successTitle');
    const successBody = this.translocoService.translate('change-my-primary-email.successBody');

    this.dialogService
      .success({
        title: successTitle,
        body: successBody,
      })
      .afterClosed$.subscribe(() => {
        this.dialogRef.close();
      });
  }

  private failureMessage(error: any) {
    const message = this.translocoService.translate('change-my-primary-email.toastError');

    console.error(error);
    this.messageService.add({
      severity: 'error',
      summary: message,
      detail: JSON.stringify(error),
    });
  }
}
