<ng-container *transloco="let t; read: 'all-data-user-editor'">
  <!-- Toast notifications configuration -->
  <p-toast position="bottom-center"></p-toast>

  <mat-card>
    <!-- Header -->
    <mat-card-header>
      <legend>{{ t('editProfile') }}: {{ obj.userData.getDisplayName() }}</legend>
    </mat-card-header>

    <!-- Content -->
    <mat-card-content>
      <form [formGroup]="form">
        <!-------------------------------------------------------------->
        <!-- User Data -->
        <!-------------------------------------------------------------->

        <!-- User Id -->
        <p>{{ t('userId') }}: {{ obj.userData.getId() }}</p>
        <p>{{ t('email') }}: {{ obj.userData.getNotificationEmail() }}</p>
        <br />

        <!-- First Name -->
        <mat-form-field class="full-width">
          <input class="full-width" formControlName="firstName" matInput placeholder="{{ t('firstName') }}" />
          <mat-error *ngIf="firstName.invalid && firstName.dirty">
            {{ t('required') }}
          </mat-error>
        </mat-form-field>

        <br />
        <!-- Last Initial -->
        <mat-form-field class="full-width">
          <input class="full-width" formControlName="lastInitial" matInput placeholder="{{ t('lastInitial') }}" />
          <mat-error *ngIf="lastInitial.invalid && lastInitial.dirty">
            {{ t('required') }}
          </mat-error>
        </mat-form-field>
        <br />
        <!-------------------------------------------------------------->
        <!-- User Private Data -->
        <!-------------------------------------------------------------->

        <!-- Phone Number -->
        <mat-form-field>
          <span matPrefix>+1 &nbsp;</span>
          <input formControlName="e164Phone" matInput type="text" placeholder="{{ t('e164Phone') }}" />

          <mat-error *ngIf="e164Phone.errors?.min || e164Phone.errors?.max">
            {{ t('phoneDigitsWrong') }}
          </mat-error>
          <mat-error *ngIf="e164Phone.errors?.required">
            {{ t('required') }}
          </mat-error>
          <mat-error *ngIf="e164Phone.errors?.usLocalPhone">
            {{ t('invalidPhone') }}
          </mat-error>
        </mat-form-field>

        <!-------------------------------------------------------------->
        <!-- Roles -->
        <!-------------------------------------------------------------->

        <br />
        <!-- Roles -->
        <p-dropdown
          appendTo="body"
          class="inputfield"
          formControlName="roles"
          ariaLabel="{{ t('roles') }}"
          [options]="[
            {
              label: t('support'),
              description: t('supportDescription'),
              value: ['support', 'orgAdmin'],
              disabled: !loggedInUserIsPwpAdmin,
            },
            { label: t('orgAdmin'), description: t('orgAdminDescription'), value: ['orgAdmin'] },
            {
              label: t('conversationPlusVoicemail'),
              description: t('conversationPlusVoicemailDescription'),
              value: ['conversation', 'voicemail'],
            },
            { label: t('voicemail'), description: t('voicemailDescription'), value: ['voicemail'] },
            { label: t('conversation'), description: t('conversationDescription'), value: ['conversation'] },
            { label: t('noRole'), description: t('noRoleDescription'), value: [] },
          ]"
        >
          <ng-template pTemplate="item" let-item>
            <div>
              <b>{{ item.label }}</b>
              <p>{{ item.description }}</p>
            </div>
          </ng-template>
        </p-dropdown>

        <br />
        <!-- OrgNote -->
        <mat-form-field appearance="standard">
          <mat-label>{{ t('orgNote') }}</mat-label>
          <textarea formControlName="orgNote" matInput></textarea>
          <mat-error *ngIf="orgNote.invalid && orgNote.dirty">
            {{ t('error') }}
          </mat-error>
        </mat-form-field>
      </form>
    </mat-card-content>

    <!-- Actions -->
    <mat-card-actions>
      <button
        class="p-button-danger p-button-text"
        pButton
        [disabled]="deleteDialogOpened || deleteProcessing || loggedInUserIsOrgAdmin !== true"
        label="{{ t('delete') }}"
        [loading]="deleteProcessing"
        (click)="confirmAndDelete()"
      ></button>
      <button
        class="p-button-text"
        pButton
        [disabled]="uploadProcessing"
        label="{{ t('upload') }}"
        [loading]="uploadProcessing"
        (click)="upload()"
      ></button>
      <button
        class="p-button-text"
        pButton
        label="{{ t('changePassword') }}"
        (click)="openChangePasswordDialog()"
      ></button>
    </mat-card-actions>
  </mat-card>
</ng-container>
