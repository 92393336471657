import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { cloneDeep, isNil } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import {
  AllDataServiceException,
  DBCreateDoc,
  DBDeleteDoc,
  DBMatcher,
  DBMatchRuleE164Phone,
  DBMatchRuleName,
  DBTransaction,
  DBTransactionType,
  DBUploadExistingDoc,
  getServiceExceptionDbDataIds,
  isNilOrDefaultDocId,
  makeAllDataServiceExceptionMap,
  ServiceException,
  ServiceExceptionActionInboundCallSession,
  ServiceExceptionSchema,
  ServiceLimit,
  ServiceLimitEnforcementStrategyRRule,
  ServiceLimitServiceUsage,
  ServiceLimitServiceUsageThreshold,
  ServiceOperationData,
} from '@pwp-common';

import { SERVICE_LIMIT_SERVICE_USAGE_THRESHOLD_HIGH } from '../../../../../../../common/src/objects/communication/service-limit/service-limit-service-usage-threshold/spec/data';
import { ServiceExceptionEditorOutput } from '../../../components/core/service-exception/editor/editor-output/service-exception-editor-output';
import { observableTakeOne } from '../../generic/take-one';
import { ServiceExceptionService } from '../service-exception/service-exception.service';
import { ServiceLimitService } from '../service-limit/service-limit.service';
import { ServiceOperationService } from '../service-operation/service-operation.service';

import { AllDataServiceExceptionTransaction } from './types';

///////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Service
///////////////////////////////////////////////////////////////////////////////////////////////////////////////

@Injectable({
  providedIn: 'root',
})
export class AllDataServiceExceptionService {
  constructor(
    private db: AngularFirestore,
    private serviceOperationService: ServiceOperationService,
    private serviceExceptionService: ServiceExceptionService,
    private serviceLimitService: ServiceLimitService,
  ) {}

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Get All Docs
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Get a map specifying all data for each service exception.
   */
  public getDocs(takeOne = true): Observable<Map<string, AllDataServiceException>> {
    const observable = forkJoin([this.serviceExceptionService.getDocsArray(), this.serviceLimitService.getDocs()]).pipe(
      map((values): Map<string, AllDataServiceException> => {
        const serviceOperationData: ServiceOperationData = { serviceLimitMap: values[1] };
        return makeAllDataServiceExceptionMap(values[0], serviceOperationData);
      }),
      finalize(() => console.log('AllDataServiceExceptionService.getDocs: Complete')),
    );
    return observableTakeOne(observable, takeOne);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Upload transaction
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public async update(
    current: ServiceException | undefined,
    editorResult: ServiceExceptionEditorOutput,
  ): Promise<string | undefined> {
    const transaction = this.getUpdatedServiceException(current, editorResult);

    await this.serviceLimitService.runDBTransaction(transaction.serviceLimitTransaction);
    await this.serviceExceptionService.runDBTransaction(transaction.serviceExceptionTransaction);

    return transaction.serviceExceptionId;
  }

  private getUpdatedServiceException(
    current: ServiceException | undefined,
    editorResult: ServiceExceptionEditorOutput,
  ): AllDataServiceExceptionTransaction {
    const serviceException: ServiceException = cloneDeep(current) ?? ServiceException.deserialize({});

    /**
     * Return the original if no changes were made.
     */
    if (isNil(editorResult)) {
      return {
        serviceExceptionId: undefined,
        serviceExceptionTransaction: new DBTransaction<ServiceException>(DBTransactionType.update, []),
        serviceLimitTransaction: new DBTransaction<ServiceException>(DBTransactionType.update, []),
      };
    }

    /**
     * Create updated service exception
     */
    serviceException.setField(ServiceExceptionSchema.id, editorResult.id);
    serviceException.setField(ServiceExceptionSchema.displayName, editorResult.displayName);
    serviceException.setField(ServiceExceptionSchema.description, editorResult.description);

    // Matchers
    const e164PhoneMatchers = new DBMatchRuleE164Phone({
      type: DBMatchRuleName.e164Phone,
      e164Phones: editorResult.e164PhoneMatchers,
    });
    serviceException.setField(
      ServiceExceptionSchema.matcher,
      new DBMatcher({ queryIds: [], rules: [e164PhoneMatchers] }),
    );

    // Inbound Call Session Action
    const serviceOperation = this.serviceOperationService.getServiceOperation(editorResult.onCreateServiceCommands);
    const inboundCallSessionAction = new ServiceExceptionActionInboundCallSession({
      onCreate: serviceOperation.serviceOperation,
    });
    serviceException.setField(ServiceExceptionSchema.inboundCallSessionAction, inboundCallSessionAction);

    /**
     * Make transaction to update service exception
     */
    let serviceExceptionId = editorResult.id;
    let serviceExceptionTransaction: DBTransaction<ServiceException>;
    if (isNilOrDefaultDocId(editorResult.id)) {
      serviceExceptionId = this.db.createId();
      serviceException.setId(serviceExceptionId);
      serviceExceptionTransaction = new DBTransaction(DBTransactionType.update, [new DBCreateDoc(serviceException)]);
    } else {
      serviceExceptionTransaction = new DBTransaction(DBTransactionType.update, [
        new DBUploadExistingDoc(serviceException),
      ]);
    }

    /**
     * Determine if any service limits need to be deleted
     */
    const serviceLimitTransaction = cloneDeep(serviceOperation.serviceLimitTransaction);
    const dbDataIdsToDelete = getServiceExceptionDbDataIds(current).difference(
      getServiceExceptionDbDataIds(serviceException),
    );
    for (const obsoleteServiceLimitId of Array.from(dbDataIdsToDelete.params.serviceLimits).sort()) {
      // In order to create a DBDeleteDoc we have to create a new ServiceLimit with the correct id.
      const tmpServiceLimit = new ServiceLimit({
        id: obsoleteServiceLimitId,
        displayName: '',
        description: '',
        enforcementStrategy: new ServiceLimitEnforcementStrategyRRule({
          enforce: true,
          type: 'rrule',
          threshold: ServiceLimitServiceUsageThreshold.deserialize(SERVICE_LIMIT_SERVICE_USAGE_THRESHOLD_HIGH),
          windowResetRRule: 'DTSTART:20210904T153000Z\nRRULE:INTERVAL=1;FREQ=DAILY;WKST=MO',
        }),
        usage: ServiceLimitServiceUsage.deserialize({}),
      });
      serviceLimitTransaction.actions.push(new DBDeleteDoc(tmpServiceLimit));
    }

    /**
     * Return
     */
    return { serviceExceptionId, serviceExceptionTransaction, serviceLimitTransaction };
  }
}
