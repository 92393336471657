import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import {
  CommunicationWidgetCreateAsyncServiceRequest,
  CommunicationWidgetCreateAsyncServiceRequestSchema,
  CommunicationWidgetName,
  CommunicationWidgetSchema,
  DBDocSchema,
  ServiceOperation,
  VoiceResponseCommand,
} from '@pwp-common';

import { getFieldValuesFromFormInComponent } from '../../../common/objects/form-helper';
import { KVPair } from '../../../common/objects/kvpair';
import { CommunicationWidgetCreateAsyncServiceRequestService } from '../../../services/communication/communication-widget/communication-widget-create-async-service-request/communication-widget-create-async-service-request.service';
import { DBQuery } from '../../../services/generic/interfaces';
import { ConfigDocSelectAndEdit } from '../../generic/abstract-classes/config-doc-select-and-edit';

@Component({
  selector: 'app-communication-widget-create-async-service-request-select-and-edit',
  templateUrl: './communication-widget-create-async-service-request-select-and-edit.component.html',
  styleUrls: ['./communication-widget-create-async-service-request-select-and-edit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunicationWidgetCreateAsyncServiceRequestSelectAndEditComponent
  extends ConfigDocSelectAndEdit<CommunicationWidgetCreateAsyncServiceRequest>
  implements OnInit, OnChanges
{
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  form: UntypedFormGroup = new UntypedFormGroup({});

  ////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////
  // Sub Components
  ////////////////////////////////////////////////////////////////////////
  public onCompleteCommands: VoiceResponseCommand[];

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  constructor(
    // @ts-ignore
    private _dataService: CommunicationWidgetCreateAsyncServiceRequestService,
    private formBuilder: UntypedFormBuilder,
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    super(CommunicationWidgetCreateAsyncServiceRequest, _dataService, _changeDetectorRef);
  }

  itemsQuery() {
    const query: DBQuery[] = [
      {
        fieldPath: CommunicationWidgetSchema.type,
        opStr: '==',
        value: CommunicationWidgetName.createAsyncServiceRequest,
      },
    ];
    return query;
  }

  kvPairBuilder = (): KVPair<CommunicationWidgetCreateAsyncServiceRequest> =>
    new KVPair({
      id: DBDocSchema.GenericDefaults.id,
      value: new CommunicationWidgetCreateAsyncServiceRequest({
        id: DBDocSchema.GenericDefaults.id,
        type: CommunicationWidgetName.createAsyncServiceRequest,
        communicationWorkflowId: CommunicationWidgetCreateAsyncServiceRequestSchema.Defaults.communicationWorkflowId,
        description: CommunicationWidgetCreateAsyncServiceRequestSchema.Defaults.description,
        displayName: CommunicationWidgetCreateAsyncServiceRequestSchema.Defaults.displayName,
        onComplete: new ServiceOperation({ commands: [] }),
      }),
    });

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parameters = getFieldValuesFromFormInComponent(
      this.selectedKVPair.value.getId(),
      [
        CommunicationWidgetCreateAsyncServiceRequestSchema.displayName,
        CommunicationWidgetCreateAsyncServiceRequestSchema.description,
        CommunicationWidgetCreateAsyncServiceRequestSchema.communicationWorkflowId,
      ],
      CommunicationWidgetCreateAsyncServiceRequestSchema.Defaults,
      this,
    );

    const onCompleteServiceOperation = new ServiceOperation({
      commands: this.onCompleteCommands || [],
    });
    parameters[CommunicationWidgetCreateAsyncServiceRequestSchema.onComplete] = onCompleteServiceOperation;

    const obj = new CommunicationWidgetCreateAsyncServiceRequest(parameters);
    return obj;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: CommunicationWidgetCreateAsyncServiceRequest) {
    // Init Form
    const formConfig = {} as any;
    formConfig[CommunicationWidgetCreateAsyncServiceRequestSchema.displayName] = [
      obj.getDisplayName(),
      [Validators.required, Validators.maxLength(500)],
    ];
    formConfig[CommunicationWidgetCreateAsyncServiceRequestSchema.description] = [
      obj.getDescription(),
      [Validators.required, Validators.maxLength(500)],
    ];
    formConfig[CommunicationWidgetCreateAsyncServiceRequestSchema.communicationWorkflowId] = [
      obj.getCommunicationWorkflowId(),
    ];

    this.form = this.formBuilder.group(formConfig);

    // Init other state
    this.onCompleteCommands = obj.getOnComplete().getCommands();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  get displayName() {
    return this.form.get(CommunicationWidgetCreateAsyncServiceRequestSchema.displayName) as UntypedFormControl;
  }

  get description() {
    return this.form.get(CommunicationWidgetCreateAsyncServiceRequestSchema.description) as UntypedFormControl;
  }

  get communicationWorkflowId() {
    return this.form.get(
      CommunicationWidgetCreateAsyncServiceRequestSchema.communicationWorkflowId,
    ) as UntypedFormControl;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObjScoper = (): boolean => this.isValidObj();

  isValidObj(): boolean {
    // This checks that the form is valid.
    if (!super.isValidObj()) {
      return false;
    }

    return true;
  }
}
