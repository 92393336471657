<ng-container *transloco="let t; read: 'rrule-editor'">
  <form *ngIf="orgData !== undefined" [formGroup]="form">
    <!-- Start At (dTStart)  -->
    <div class="grid formgrid p-fluid">
      <div *ngIf="!disableStartEditing" class="field col-12">
        <span class="p-float-label">
          <input
            id="dtStart"
            pInputText
            type="datetime-local"
            ariaLabel="{{ t('dtStartLabel', { rruleTimezone }) }}"
            [formControl]="dtStart"
            [ngClass]="{ 'ng-invalid': dtStart?.invalid }"
            placeholder="{{ t('dtStartLabel', { rruleTimezone }) }}"
          />
          <label for="dtStart">{{ t('dtStartLabel', { rruleTimezone }) }}</label>
          <p *ngIf="dtStart.hasError('isBefore')" class="p-error">{{ t('dtStartErrorIsBefore') }}</p>
          <p *ngIf="dtStart.hasError('required')" class="p-error">{{ t('dtStartErrorRequired') }}</p>
        </span>
      </div>
      <!-- and Repeat Every (interval + frequency, wkst (if weekly), and byweekday (if weekly))  -->
      <div class="field col-12 md:col-6 mt-3">
        <!-- Interval -->
        <span class="p-float-label">
          <p-inputNumber
            id="interval"
            mode="decimal"
            ariaLabel="{{ t('intervalLabel') }}"
            [formControl]="interval"
            [min]="1"
            [ngClass]="{ 'ng-invalid': interval?.invalid }"
            [showButtons]="true"
          >
          </p-inputNumber>
          <label for="interval">{{ t('intervalLabel') }}</label>
          <p *ngIf="interval.hasError('required')" class="p-error">{{ t('intervalErrorRequired') }}</p>
          <p *ngIf="interval.hasError('min')" class="p-error">{{ t('intervalErrorMin') }}</p>
        </span>
      </div>
      <div class="field col-12 md:col-6 mt-3">
        <!-- Frequency -->
        <p-dropdown ariaLabel="{{ t('frequencyLabel') }}" [formControl]="frequency" [options]="possibleFrequencies">
          <ng-template pTemplate="item" let-frequency>
            <div>{{ t(frequency.label) }}</div>
          </ng-template>
          <ng-template pTemplate="selectedItem" let-frequency>
            <div>{{ t(frequency.label) }}</div>
          </ng-template>
        </p-dropdown>
      </div>

      <!-- By Weekday -->
      <div class="field col-12 mt-3" [hidden]="frequency?.value !== weekly">
        <p>{{ t('byWeekdayLabel') }}</p>
        <p-selectButton id="byWeekday" [formControl]="byWeekday" [multiple]="true" [options]="possibleValuesByWeekday">
          <ng-template let-item>
            {{ t(item.label) }}
          </ng-template>
        </p-selectButton>
      </div>

      <!-- Week Start  -->
      <div class="field col-12 mt-3" [hidden]="frequency?.value !== weekly">
        <p-dropdown ariaLabel="{{ t('weekStartLabel') }}" [formControl]="weekStart" [options]="possibleValuesWeekStart">
          <ng-template pTemplate="item" let-weekStartValue>
            <div>{{ t(weekStartValue.label) }}</div>
          </ng-template>
          <ng-template pTemplate="selectedItem" data-testid="weekStartSelectedValue" let-weekStartValue>
            <div>{{ t(weekStartValue.label) }}</div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </form>
  <app-progress-spinner *ngIf="orgData === undefined"></app-progress-spinner>
</ng-container>
