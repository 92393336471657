import { makePTableCols } from '../../../../../common/p-table/make-p-table-cols';
import { createAnsweredCallsRow } from '../answered-calls-row/answered-calls-row';
import { createAverageHoldTimeForAnsweredCallsRow } from '../average-hold-time-for-answered-calls-row/average-hold-time-for-answered-calls-row';
import { createAverageHoldTimeForCallersThatHungUpEarlyRow } from '../average-hold-time-for-callers-that-hung-up-early-row/average-hold-time-for-callers-that-hung-up-early-row';
import { createAverageTalkDurationRow } from '../average-talk-duration-row/average-talk-duration-row';
import { AdminReportEventStatsReportRowInput, AdminReportUserCallStatsRowInput, ReportRow } from '../interfaces';
import { createMissedCallsRow } from '../missed-calls-row/missed-calls-row';
import { createNobodyAvailableRow } from '../nobody-available-row/nobody-available-row';
import { createNumCallsRow } from '../num-calls-row/num-calls-row';
import { createUserMostAtRiskOfBurnoutRow } from '../user-most-at-risk-of-burnout-row/user-most-at-risk-of-burnout-row';
import { createUserWhoAnsweredMostCallsRow } from '../user-who-answered-most-calls-row/user-who-answered-most-calls-row';
import { createUserWhoHasTheMostTalkMinutesRow } from '../user-who-has-the-most-talk-minutes-row/user-who-has-the-most-talk-minutes-row';
import { createUserWhoTookTheMostEventHoursRow } from '../user-who-took-the-most-event-hours-row/user-who-took-the-most-event-hours-row';
import { createUserWhoTookTheMostEventsRow } from '../user-who-took-the-most-events-row/user-who-took-the-most-events-row';

import { MakeTableDataInput, MakeTableDataOutput } from './interfaces';

const createCallDataRows = ({
  entityStats,
  usersStatsData,
  callList,
}: AdminReportUserCallStatsRowInput): ReportRow[] => [
  createNumCallsRow({ entityStats, callList }),
  createAnsweredCallsRow({ entityStats, callList }),
  createMissedCallsRow({ entityStats, callList }),
  createNobodyAvailableRow({ entityStats, callList }),
  createAverageTalkDurationRow({ entityStats, callList }),
  createAverageHoldTimeForAnsweredCallsRow({ entityStats, callList }),
  createAverageHoldTimeForCallersThatHungUpEarlyRow({ entityStats, callList }),
  createUserWhoAnsweredMostCallsRow({ entityStats, usersStatsData, callList }),
  createUserWhoHasTheMostTalkMinutesRow({ entityStats, usersStatsData, callList }),
];

const createUserDataRows = ({
  entityStats,
  usersStatsData,
  eventType,
}: AdminReportEventStatsReportRowInput): ReportRow[] => [
  createUserWhoTookTheMostEventsRow({ entityStats, usersStatsData, eventType }),
  createUserWhoTookTheMostEventHoursRow({ entityStats, usersStatsData, eventType }),
];

export const makeTableData = ({
  entityStats,
  usersStatsData,
  callLists,
  eventTypes,
}: MakeTableDataInput): MakeTableDataOutput => {
  const cols = makePTableCols({
    translationScope: 'admin-reports',
    columns: ['type', 'question', 'filter', 'total', ...entityStats.getSortedMonths()],
  });

  const rows: ReportRow[] = [
    ...createCallDataRows({ entityStats, usersStatsData }),
    ...createUserDataRows({ entityStats, usersStatsData }),
    createUserMostAtRiskOfBurnoutRow({ entityStats, usersStatsData }),
    ...callLists.flatMap((callList) => createCallDataRows({ entityStats, usersStatsData, callList })),
    ...eventTypes.flatMap((eventType) => createUserDataRows({ entityStats, usersStatsData, eventType })),
  ];

  return { rows, cols };
};
