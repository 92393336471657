import { translate } from '@ngneat/transloco';
import moment from 'moment-timezone';

import { CommunicationSessionType, getDisplayName } from '@pwp-common';

import { createSurveyModel } from '../../../../../../components/form/utils/create-survey-model/create-survey-model';
import { createDisplayableCommunication } from '../create-displayable-communication/create-displayable-communication';

import { CreateFormSubmissionRowInput, FormSubmissionRow } from './interfaces';

export const createFormSubmissionRows = ({
  formSubmissions,
  callListMap,
  callLogs,
  conversationLogs,
  formVersions,
  forms,
  allUserDataMap,
  orgData,
}: CreateFormSubmissionRowInput): FormSubmissionRow[] =>
  formSubmissions.map((formSubmission) => {
    const communicationLog =
      formSubmission.getSessionId().getCommunicationSessionType() === CommunicationSessionType.inboundConversation
        ? conversationLogs.get(formSubmission.getSessionId().getConversationLogId())
        : callLogs.get(formSubmission.getSessionId().getInboundCallSessionId());

    const logDisplay = createDisplayableCommunication({ communicationLog, allUserDataMap, callListMap, orgData });
    const formVersion = formVersions.get(formSubmission.getFormVersionId());
    const form = forms.get(formVersion.getFormId());

    const surveyModel = createSurveyModel(formVersion.getFormSchema());
    surveyModel.data = JSON.parse(formSubmission.getValue());
    surveyModel.mode = 'display';

    return {
      id: formSubmission.getId(),
      form: form.getDisplayName(),
      submissionTime: formSubmission.getCreateTime().tz(orgData.getTimezone()),
      submittedBy: getDisplayName(allUserDataMap, formSubmission.getCreatedByUserId()),
      contactChannel: translate(`communication-logs-table.${logDisplay.communicationType}`),
      contactType: logDisplay.communicationTypeDetail,
      contactAnsweredBy: logDisplay.answeredBy,
      contactTime: moment.tz(logDisplay.timestamp, orgData.getTimezone()),
      surveyModel,
    };
  });
