import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { isNil } from '@ngneat/transloco';
import Joi from 'joi';
import moment from 'moment-timezone';

import { SchemaField } from '@pwp-common';

import { getTimestampFromDateTimeLocalFieldControl } from '../../objects/form-helper';

export class DatetimeValidator {
  private static isoDurationSchema = Joi.object({
    timeDuration: SchemaField.isoDuration(undefined).required(),
  });

  /**
   *
   * @param value
   * @param timezone Datetime controls return timestamp as strings without timezone information. Interpret in the given timezone.
   * Use local timezone if undefined
   * @returns
   */
  public static isValid(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isValid = !isNil(control.value) ?? moment(control.value).isValid();
      return !isValid ? { isValid: { value: control.value } } : null;
    };
  }

  /**
   *
   * @param value
   * @param timezone Datetime controls return timestamp as strings without timezone information. Interpret in the given timezone.
   * Use local timezone if undefined
   * @returns
   */
  public static isAfter(value: moment.Moment, timezone: string | undefined): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const parsedValue = getTimestampFromDateTimeLocalFieldControl(control, timezone, undefined);

      if (isNil(parsedValue)) {
        return null;
      }
      const valid = parsedValue?.isAfter(value);

      return !valid ? { isAfter: { value: control.value } } : null;
    };
  }

  /**
   *
   * @param value
   * @param timezone Datetime controls return timestamp as strings without timezone information. Interpret in the given timezone.
   * Use local timezone if undefined
   * @returns
   */
  public static isBefore(value: moment.Moment, timezone: string | undefined): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const parsedValue = getTimestampFromDateTimeLocalFieldControl(control, timezone, undefined);
      if (isNil(parsedValue)) {
        return null;
      }
      const valid = parsedValue.isBefore(value);
      return valid ? null : { isBefore: { value: control.value } };
    };
  }

  /**
   * Validate that
   *
   * @param stepSize
   * @returns
   */
  public static stepSizeSeconds(stepSize: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (3600 % stepSize !== 0) {
        throw new Error('DatetimeValidator.stepSizeSeconds: Invalid step size');
      }

      const ts = moment(control.value);
      const totalSeconds = ts.minutes() * 60 + ts.seconds();

      const valid = totalSeconds % stepSize === 0;

      return !valid ? { stepSizeSeconds: { value: control.value } } : null;
    };
  }

  ////////////////////////////////////////////////////////////////////////
  // Validate against control
  ////////////////////////////////////////////////////////////////////////

  /**
   * @deprecated Use isBeforeControl instead
   * @param controlName
   * @param matchingControlName
   * @param timezone Datetime controls return timestamp as strings without timezone information. Interpret timestamps
   * in the given timezone.
   * Use local timezone if undefined
   * @returns
   */
  public static isAfterControl(controlName: string, matchingControlName: string, timezone?: string): any {
    return (formGroup: UntypedFormGroup): void => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      const parsedcontrolValue = getTimestampFromDateTimeLocalFieldControl(control, timezone, undefined);
      const errors = DatetimeValidator.isAfter(parsedcontrolValue, timezone)(matchingControl);
      if (!isNil(errors)) {
        control.setErrors(errors);
      } else {
        control.setErrors(null);
      }
    };
  }

  ////////////////////////////////////////////////////////////////////////
  // Duration
  ////////////////////////////////////////////////////////////////////////

  /**
   *
   * @param value
   * @param timezone Datetime controls return timestamp as strings without timezone information. Interpret in the given timezone.
   * Use local timezone if undefined
   * @returns
   */
  public static isIsoDuration(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const validation = DatetimeValidator.isoDurationSchema.validate({ timeDuration: control.value });
      let valid = false;
      if (
        isNil(validation.error) &&
        isNil(validation.errors) &&
        isNil(validation.warning) &&
        validation.value.timeDuration === control.value
      ) {
        valid = true;
      }
      return !valid ? { isIsoDuration: { value: control.value } } : null;
    };
  }
}
