import Joi from 'joi';

import { SchemaField } from '../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';

/**
 * Schema:
 * https://datatracker.ietf.org/doc/html/rfc8225#section-5.2.1
 */
export class ShakenPayloadOrigSchema extends SerializableObjectSchema {
  public static readonly tn = 'tn';

  public static readonly uri = 'uri';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly tn = undefined;

    public static readonly uri = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getJoiSchema(): import('joi').AnySchema {
    const schema = Joi.object(this.getSchemaDefinition()).or(ShakenPayloadOrigSchema.tn, ShakenPayloadOrigSchema.uri);
    return schema;
  }

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[ShakenPayloadOrigSchema.tn] = SchemaField.string(undefined).optional();
    parameters[ShakenPayloadOrigSchema.uri] = SchemaField.uri(undefined).optional();
    return parameters;
  }
}
