import { cloneDeep } from 'lodash';

import { SerializableObjectSchema } from '../../generic/serialization/serializable-object-schema';
import { VoiceResponseCommandName } from '../any-voice-response-command/voice-response-command-name';
import { VoiceResponseCommand } from '../generic/voice-response-command';
import { VoiceResponseCommandSchema } from '../generic/voice-response-command-schema';

import { SetContextConstructor } from './set-context-constructor';
import { SetContextSchema } from './set-context-schema';

export class SetContext extends VoiceResponseCommand {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  private language!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: SetContextConstructor) {
    // This hack ensures that deserializing a VoiceCommand is equal to instanting it directly.
    // The difference is trivial, and only required for intuitive unit testing.
    (parameters as any)[VoiceResponseCommandSchema.commandName] = VoiceResponseCommandName.setContext;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): SetContext {
    return new SetContext(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(SetContext.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new SetContextSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getLanguage(): string {
    return cloneDeep(this.language);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////
}
