import { Component, Inject } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

import { EqualsValidator } from '../../../common/validators/equals-validator';
import { ComponentWithForm } from '../abstract-classes/component-with-form';

import { ConfirmWithInputInterface } from './confirm-with-input-interface';

@Component({
  selector: 'app-confirm-with-input',
  templateUrl: './confirm-with-input.component.html',
  styleUrls: ['./confirm-with-input.component.css'],
})
export class ConfirmWithInputComponent extends ComponentWithForm {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  controlNameConfirmedStr = 'confirmationString';

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    public ref: MatDialogRef<ConfirmWithInputComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmWithInputInterface,
    private formBuilder: UntypedFormBuilder,
  ) {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected defineForm() {
    // Init Form
    const formConfig = {} as any;
    formConfig[this.controlNameConfirmedStr] = [
      '',
      [Validators.required, EqualsValidator.equalsValidator(this.data.requiredConfirmationString)],
    ];

    this.form = this.formBuilder.group(formConfig);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Form Field Getters
  /////////////////////////////////////////////////////////////////////////////////////////////

  get confirmationString(): AbstractControl | null {
    return this.form.get(this.controlNameConfirmedStr);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Buttons
  /////////////////////////////////////////////////////////////////////////////////////////////

  confirm() {
    this.ref.close(true);
  }

  cancel() {
    this.ref.close(false);
  }
}
