import { cloneDeep } from 'lodash';

import { SerializableObjectSchema } from '../../../../../../generic/serialization/serializable-object-schema';
import { ReservationSkipOffersIfRule } from '../../generic/reservation-skip-offers-if-rule/reservation-skip-offers-if-rule';
import { ReservationSkipOffersIfRuleSchema } from '../../generic/reservation-skip-offers-if-rule/reservation-skip-offers-if-rule-schema';
import { ReservationSkipOffersIfRuleName } from '../../generic/reservation-skip-offers-if-rule-name';

import { ReservationSkipOffersIfRuleAvailabilityConstructor } from './reservation-skip-offers-if-rule-availability-constructor';
import { ReservationSkipOffersIfRuleAvailabilitySchema } from './reservation-skip-offers-if-rule-availability-schema';

export class ReservationSkipOffersIfRuleAvailability extends ReservationSkipOffersIfRule {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected skipIfUnavailable!: boolean;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ReservationSkipOffersIfRuleAvailabilityConstructor) {
    (parameters as any)[ReservationSkipOffersIfRuleSchema.type] = ReservationSkipOffersIfRuleName.availability;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(
    validationResult: import('joi').ValidationResult,
  ): ReservationSkipOffersIfRuleAvailability {
    return new ReservationSkipOffersIfRuleAvailability(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ReservationSkipOffersIfRuleAvailability.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ReservationSkipOffersIfRuleAvailabilitySchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getSkipIfUnavailable() {
    return cloneDeep(this.skipIfUnavailable);
  }
}
