import { DBDocSchema } from '../db-doc/db-doc-schema';
import { SchemaField } from '../serialization/schema-field';

export abstract class GenericDisplayableSchema extends DBDocSchema {
  public static readonly description = 'description'; // Description

  public static readonly displayName = 'displayName'; // Display Name

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly description = 'missing-description';

    public static readonly displayName = 'missing-displayName';
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[GenericDisplayableSchema.description] = SchemaField.string(
      GenericDisplayableSchema.Defaults.description,
    );
    parameters[GenericDisplayableSchema.displayName] = SchemaField.string(
      GenericDisplayableSchema.Defaults.displayName,
    );
    /* eslint-enable */

    return parameters;
  }
}
