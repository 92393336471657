<ng-container *transloco="let t; read: 'event-repeat'">
  <app-rrule-generator [(obj)]="obj.rrule" (objChange)="updateObjAndEmit()"></app-rrule-generator>

  <form *ngIf="!loading" [formGroup]="form">
    <!-- Ends:  -->
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>{{ t('until') }}</mat-label>
      <input formControlName="until" matInput type="datetime-local" [step]="900" />
      <mat-error *ngIf="until?.invalid && until?.touched">
        {{ t('untilError') }}
      </mat-error>
    </mat-form-field>
  </form>
</ng-container>
