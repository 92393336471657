import { cloneDeep } from 'lodash';

import { SerializableObject } from '../../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../../generic/serialization/serializable-object-schema';

import { ServiceExceptionExecutionConstructor } from './service-exception-execution-constructor';
import { ServiceExceptionExecutionSchema } from './service-exception-execution-schema';

export class ServiceExceptionExecution extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected actionExecuted!: boolean;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ServiceExceptionExecutionConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ServiceExceptionExecution {
    const obj = new ServiceExceptionExecution(super._deserialize(validationResult));
    return obj;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(ServiceExceptionExecution.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ServiceExceptionExecutionSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getActionExecuted() {
    return cloneDeep(this.actionExecuted);
  }
}
