import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import RRule from 'rrule';

import { validateAndGetRRuleFromStr } from './validation';

/**
 * Create a new rrule where the timezone is replaced as specified.
 *
 * @param rrule
 * @param newTimezone
 * @returns
 */
export const replaceTimezoneInRRule = (rruleStr: string, newTimezone: string): RRule => {
  const rrule = validateAndGetRRuleFromStr(rruleStr, true);
  const rruleOptions = cloneDeep(rrule!.origOptions);
  if (!moment.tz.names().includes(newTimezone!)) {
    console.error({ newTimezone, rruleStr, origOptions: rrule?.origOptions });
    throw new Error('replaceTimezoneInRRule: Invalid timezone!');
  }
  rruleOptions.tzid = newTimezone;
  const rruleWithTimezone = new RRule(rruleOptions);
  return rruleWithTimezone;
};
