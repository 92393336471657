import moment from 'moment-timezone';

/**
 * The RRule library requires dates in the global timezone to be
 * reinterpreted as UTC date strings. This function does that
 * reinterpretation.
 *
 * https://github.com/jakubroztocil/rrule
 * @param dateWithTimezone
 */
export const reInterpretMomentToUTC = (dateWithTimezone: moment.Moment): moment.Moment =>
  moment.utc(dateWithTimezone.format('YYYY-MM-DDTHH:mm:ss.SSSS'), 'YYYY-MM-DDTHH:mm:ss.SSSS');
