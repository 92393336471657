import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Provider } from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { cloneDeep, isNil } from 'lodash';

import { CallListSchema } from '@pwp-common';

import { FormGroupControlValueAccessor } from '../../../../generic/abstract-classes/form-group-control-value-accessor';
import { CallListEditorFormType } from '../editor-output/call-list-editor-form-type';
import { CallListEditorOutput } from '../editor-output/call-list-editor-output';
import { defaultCallListEditorOutput } from '../editor-output/default-call-list-editor-output';

const VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CallListEditorComponent),
  multi: true,
};

const VALIDATOR: Provider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => CallListEditorComponent),
  multi: true,
};

@UntilDestroy()
@Component({
  selector: 'app-call-list-editor',
  templateUrl: './call-list-editor.component.html',
  styleUrls: ['./call-list-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VALUE_ACCESSOR, VALIDATOR],
})
export class CallListEditorComponent extends FormGroupControlValueAccessor<CallListEditorOutput, CallListEditorOutput> {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  baseObj: CallListEditorOutput | undefined = undefined;

  ////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////
  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form Controls
  ///////////////////////////////////////////////////////////////////////////////////////////
  get displayName() {
    return this.form.get(CallListSchema.displayName) as UntypedFormControl;
  }

  get description() {
    return this.form.get(CallListSchema.description) as UntypedFormControl;
  }

  get calleeEntities() {
    return this.form.get(CallListSchema.calleeEntities) as UntypedFormControl;
  }

  get offerConfig() {
    return this.form.get(CallListSchema.offerConfig) as UntypedFormControl;
  }

  get ringTime() {
    return this.offerConfig.get('ringTime') as UntypedFormControl;
  }

  get callerId() {
    return this.offerConfig.get('callerId') as UntypedFormControl;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineForm() {
    const formConfig = {} as any;
    formConfig[CallListSchema.displayName] = new UntypedFormControl(undefined, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50),
    ]);
    formConfig[CallListSchema.description] = new UntypedFormControl(undefined, [Validators.maxLength(100)]);
    formConfig[CallListSchema.calleeEntities] = new UntypedFormControl([], [Validators.maxLength(30)]);
    formConfig[CallListSchema.offerConfig] = new UntypedFormGroup({
      ringTime: new UntypedFormControl(undefined),
      callerId: new UntypedFormControl(undefined),
    });
    this.form = new UntypedFormGroup(formConfig);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  parseValueChange(value: CallListEditorOutput): CallListEditorOutput {
    const result = cloneDeep(this.baseObj ?? defaultCallListEditorOutput);
    result.description = this.description.value || result.description;
    result.displayName = this.displayName.value || result.displayName;
    result.calleeEntities = this.calleeEntities.value || result.calleeEntities;
    result.offerConfig.callerId = this.callerId.value || result.offerConfig.callerId;
    result.offerConfig.ringTime = this.ringTime.value || result.offerConfig.ringTime;
    return result;
  }

  writeValue(value: CallListEditorOutput | undefined | null) {
    /**
     * Preserve any properties of the input, if it is specified (such as the id)
     */
    if (isNil(value)) {
      return;
    }
    this.baseObj = cloneDeep(value ?? defaultCallListEditorOutput);
    const formValue: CallListEditorFormType = {
      description: this.baseObj?.description,
      displayName: this.baseObj?.displayName,
      calleeEntities: this.baseObj.calleeEntities,
      offerConfig: this.baseObj?.offerConfig,
    };
    this.form.patchValue(formValue, { emitEvent: true });
    this.changeDetectorRef.detectChanges();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  public makeValidationErrors(): ValidationErrors {
    return {
      'call-list-editor': this.form.value,
    };
  }
}
