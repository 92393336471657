import type { AnySchema } from 'joi';

import { CommunicationSessionId } from '../../../../../objects/core/communication-session-id/communication-session-id';
import { FormBuilderVendor } from '../../../../../objects/form/form-builder-vendor';
import { SchemaField } from '../../../../../objects/generic/serialization/schema-field';
import { jsonStringSchema } from '../../../../../objects/generic/serialization/schema-field/json-string-schema/json-string-schema';
import { FormRequestSchema } from '../../generic/form-request/form-request-schema';

export class FormRequestCreateSubmissionSchema extends FormRequestSchema {
  public static readonly formVersionId = 'formVersionId';

  public static readonly sessionId = 'sessionId';

  public static readonly submission = 'submission';

  public static readonly submissionId = 'submissionId';

  public static readonly vendor = 'vendor';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly Defaults = class extends FormRequestSchema.Defaults {
    public static readonly formVersionId = undefined;

    public static readonly sessionId = undefined;

    public static readonly submission = undefined;

    public static readonly submissionId = undefined;

    public static readonly vendor = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: AnySchema } {
    const parameters = super.getSchemaDefinition();

    parameters[FormRequestCreateSubmissionSchema.formVersionId] = SchemaField.autoGeneratedDocId(
      FormRequestCreateSubmissionSchema.Defaults.vendor,
    );
    parameters[FormRequestCreateSubmissionSchema.sessionId] = SchemaField.obj(
      CommunicationSessionId,
      FormRequestCreateSubmissionSchema.Defaults.vendor,
    );
    parameters[FormRequestCreateSubmissionSchema.submission] = jsonStringSchema(
      FormRequestCreateSubmissionSchema.Defaults.vendor,
    );
    parameters[FormRequestCreateSubmissionSchema.submissionId] = SchemaField.autoGeneratedDocId(
      FormRequestCreateSubmissionSchema.Defaults.vendor,
    );
    parameters[FormRequestCreateSubmissionSchema.vendor] = SchemaField.string(
      FormRequestCreateSubmissionSchema.Defaults.vendor,
    ).valid(...Object.values(FormBuilderVendor));

    return parameters;
  }
}
