import { cloneDeep, intersection } from 'lodash';

import { SupportedLanguages } from '../../voice-response-command/vrc-audio-metadata/supported-languages';
import { RenderedTemplate } from '../rendered-template/rendered-template';
import moment from 'moment-timezone';
import Mustache from 'mustache';
import { isNilOrDefault } from '../../generic/serialization/is-nil-or-default';

import {
  rruleHasCount,
  rruleHasDtstart,
  rruleHasTzid,
  rruleIsMinutely,
  rruleIsSecondly,
} from '../../../helper/rrule/fields';
import { rruleIsValid } from '../../../helper/rrule/validation';
import { getRecurrences } from '../../../helper/rrule/recurrences';
import { replaceTimezoneInRRule } from '../../../helper/rrule/timezone';
import { CalleeEntity } from '../../call/callee-entity/callee-entity';
import { DBDocSchema } from '../../generic/db-doc/db-doc-schema';
import { GenericDisplayable } from '../../generic/displayable/generic-displayable';
import { LanguageDefaults } from '../../voice-response-command/vrc-audio-metadata/language-defaults';
import { TemplateText } from '../template-text/template-text';

import { MessageDeliveryChannel } from './channel';
import { MESSAGE_TEMPLATE_SEND_WINDOW_ERR_MINUTES, SCHEDULED_MESSAGE_TEMPLATES } from './constants';
import { MessageTemplateConstructor } from './message-template-constructor';
import { getValidKeyForContextType } from './message-template-context/get-keywords-for-context';
import {
  MessageTemplateContextObj,
  MessageTemplateContextObjKeys,
} from './message-template-context/message-template-context-obj';
import { MessageTemplateContextType } from './message-template-context/message-template-context-type';
import { MessageTemplateSchema } from './message-template-schema';

export class MessageTemplate extends GenericDisplayable {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected context!: MessageTemplateContextType;

  protected eventTypes!: string[];

  protected subject!: TemplateText;

  protected body!: TemplateText;

  protected assignedUsers!: CalleeEntity[];

  protected subscribedUserIds!: string[];

  protected cc!: string[];

  protected bcc!: string[];

  protected replyTo!: string;

  protected sendRRule!: string;

  protected sendEnabled!: boolean;

  protected messageDeliveryChannel!: MessageDeliveryChannel;

  protected callLists!: string[];

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: MessageTemplateConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): MessageTemplate {
    return new MessageTemplate(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(MessageTemplate.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new MessageTemplateSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getContext(): MessageTemplateContextType {
    return cloneDeep(this.context);
  }

  public getEventTypes(): string[] {
    return cloneDeep(this.eventTypes);
  }

  public getSubject(): TemplateText {
    return cloneDeep(this.subject);
  }

  public getBody(): TemplateText {
    return cloneDeep(this.body);
  }

  public getAssignedUsers(): CalleeEntity[] {
    return cloneDeep(this.assignedUsers);
  }

  public getSubscribedUserIds(): string[] {
    return cloneDeep(this.subscribedUserIds);
  }

  public getCC(): string[] {
    return cloneDeep(this.cc);
  }

  public getBCC(): string[] {
    return cloneDeep(this.bcc);
  }

  public getReplyTo(): string {
    return cloneDeep(this.replyTo);
  }

  public getSendRRule(): string {
    return cloneDeep(this.sendRRule);
  }

  public getSendEnabled(): boolean {
    return cloneDeep(this.sendEnabled);
  }

  public getMessageDeliveryChannel(): MessageDeliveryChannel {
    return cloneDeep(this.messageDeliveryChannel);
  }

  public getCallLists() {
    return cloneDeep(this.callLists);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Render
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Determine the list of users that should receive a message at the given time,
   * and according to the given context. Return this as a list that can be sent
   * via a Service Provider.
   *
   * @param toBeSentAt The time which will be used to determine if messages are sent
   * @param contextByUserId Context to be used for each userId's
   * @returns
   */
  public getMessages(
    timezone: string,
    language: LanguageDefaults,
    contextByUserId: Map<string, MessageTemplateContextObj>,
    toBeSentAt = moment(),
    requestedToUserIds?: string[],
  ): RenderedTemplate[] {
    const renderedTemplates: RenderedTemplate[] = [];
    const toUserIds = this.getToUsers(requestedToUserIds);
    for (const userId of toUserIds) {
      const contextObj = contextByUserId.get(userId) || {};
      const renderedTemplate = this.renderMessageFor(userId, contextObj, timezone, language, toBeSentAt);
      if (renderedTemplate !== undefined) {
        renderedTemplates.push(renderedTemplate);
      }
    }
    return renderedTemplates;
  }

  private getToUsers(requestedToUserIds?: string[]): string[] {
    const requestedOrDefault = requestedToUserIds || this.getSubscribedUserIds();
    switch (this.context) {
      case MessageTemplateContextType.callListCallAnswered: {
        return requestedOrDefault;
      }
      case MessageTemplateContextType.conversationServiceRequest: {
        return requestedOrDefault;
      }
      case MessageTemplateContextType.shiftsToday: {
        return intersection(this.getSubscribedUserIds(), requestedOrDefault);
      }
      case MessageTemplateContextType.shiftsTomorrow: {
        return intersection(this.getSubscribedUserIds(), requestedOrDefault);
      }
      default: {
        console.error({ context: this.context });
        throw new Error(
          `MessageTemplate.checkCanSendContextSpecificLogic: Unable to interpret context: ${this.context}`,
        );
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Render Helper Methods
  /////////////////////////////////////////////////////////////////////////////

  /**
   *
   * It's possible that messages will only be sent to a subset of the subscribed users
   * (due to blockout times), or to nobody due to context. For examples, a daily alert
   * could be configured to be sent at 9am in a particular timezone, and no other time.
   *
   * This function determines which users should get messages, and returns information
   * that can be used to deliver those messages across the required channels.

   * @param userId
   * @param contextObj Used to render the template
   * @param toBeSentAt For unit testing, one can specify the time which will be used to
   * determine if messages are sent
   */
  public renderMessageFor(
    userId: string,
    contextObj: MessageTemplateContextObj,
    timezone: string,
    language: LanguageDefaults,
    toBeSentAt: moment.Moment,
  ): RenderedTemplate | undefined {
    if (!this.canSend(timezone, toBeSentAt)) {
      return undefined;
    }
    if (!this.hasNontrivialContext(contextObj)) {
      return undefined;
    }

    const subjectStr = this.renderTemplateInLang(this.subject, language, contextObj);
    const bodyStr = this.renderTemplateInLang(this.body, language, contextObj);

    return new RenderedTemplate({
      channel: this.getMessageDeliveryChannel(),
      subject: subjectStr,
      body: bodyStr,
      targetUserId: cloneDeep(userId),
      cc: this.getCC(),
      bcc: this.getBCC(),
      replyTo: this.getReplyTo(),
    });
  }

  private renderTemplateInLang(
    templateText: TemplateText,
    language: LanguageDefaults,
    contextObj: MessageTemplateContextObj,
  ): string {
    const localizedTemplate =
      templateText.getText().get(language.getShortCode()) ||
      `Missing Template for language ${language.getDisplayName()}`;
    return Mustache.render(localizedTemplate, contextObj);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Can Send
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Determine if this message template can be sent at the specified time.
   * @param timezone
   * @param sendTime
   */
  public canSend(timezone: string, toBeSentAt?: moment.Moment): boolean {
    try {
      this.sanityCheck();
    } catch (error) {
      return false;
    }

    if (!this.sendEnabled) {
      return false;
    }

    if (!this.isInSendWindow(timezone, toBeSentAt || moment())) {
      return false;
    }

    if (!this.checkCanSendContextSpecificLogic()) {
      return false;
    }
    return true;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Can Send Helper: Send Window
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Return true if this message can be sent at the specified time.
   * @param timestamp
   */
  private isInSendWindow(timezone: string, timestamp: moment.Moment): boolean {
    if (!this.doesSendTimeDependOnRRule()) {
      return true;
    }

    const rruleWithTimezone = replaceTimezoneInRRule(this.sendRRule, timezone);

    const canSendTimes = getRecurrences(
      rruleWithTimezone,
      cloneDeep(timestamp).subtract(MESSAGE_TEMPLATE_SEND_WINDOW_ERR_MINUTES, 'minute'),
      cloneDeep(timestamp).add(MESSAGE_TEMPLATE_SEND_WINDOW_ERR_MINUTES, 'minute'),
    );

    return canSendTimes.length > 0;
  }

  private doesSendTimeDependOnRRule(): boolean {
    return SCHEDULED_MESSAGE_TEMPLATES.includes(this.context);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Can Send Helper: Send Window
  /////////////////////////////////////////////////////////////////////////////

  public checkCanSendContextSpecificLogic(): boolean {
    switch (this.context) {
      case MessageTemplateContextType.callListCallAnswered: {
        return true;
      }
      case MessageTemplateContextType.conversationServiceRequest: {
        return true;
      }
      case MessageTemplateContextType.shiftsToday: {
        return this.subscribedUserIds.length > 0;
      }
      case MessageTemplateContextType.shiftsTomorrow: {
        return this.subscribedUserIds.length > 0;
      }
      default: {
        console.error({ context: this.context });
        throw new Error(
          `MessageTemplate.checkCanSendContextSpecificLogic: Unable to interpret context: ${this.context}`,
        );
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Nontrivial Context
  /////////////////////////////////////////////////////////////////////////////

  private hasNontrivialContext(contextObj: MessageTemplateContextObj): boolean {
    switch (this.context) {
      case MessageTemplateContextType.callListCallAnswered: {
        return true;
      }
      case MessageTemplateContextType.conversationServiceRequest: {
        return true;
      }
      case MessageTemplateContextType.shiftsToday: {
        return contextObj[MessageTemplateContextObjKeys.shiftsToday]?.length > 0;
      }
      case MessageTemplateContextType.shiftsTomorrow: {
        return contextObj[MessageTemplateContextObjKeys.shiftsTomorrow]?.length > 0;
      }
      default: {
        throw new Error(`MessageTemplate.hasNontrivialContext: Unable to interpret context: ${this.context}`);
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Sanity Check
  /////////////////////////////////////////////////////////////////////////////

  public sanityCheck(): void {
    for (const templateText of [this.body, this.subject]) {
      this.validateTemplateText(templateText);
    }

    // This function will throw if the RRule is scheduled and the RRule is invalid.
    this.validateRRule();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Sanity Check Helper Methods
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This method
   * @param templateText
   * @returns
   */
  private validateTemplateText(templateText: TemplateText): void {
    const possibleVars = getValidKeyForContextType(this.context);
    for (const langShortCode of templateText.getText().keys()) {
      // Validate
      templateText.sanityCheck();

      const language = SupportedLanguages.getDefaults(langShortCode);
      const usedVars = templateText.getUsedVars(language);

      // Check the vars used, and validate they are allowed
      for (const usedVar of usedVars) {
        if (!possibleVars.has(usedVar)) {
          console.log('MessageTemplate.validateTemplateText: Invalid variable used', {
            usedVars,
            possibleVars,
            templateText,
          });
          throw new Error('MessageTemplate.validateTemplateText: Invalid variable used');
        }
      }
    }
  }

  /**
   * Validate that this RRule meets all required specifications to be a
   * scheduled message.
   *
   * This method assumes that the context is scheduled.
   * @returns
   */
  private validateRRule(): void {
    this.validateRRuleAndContextTypeInSync();
    if (!this.doesSendTimeDependOnRRule()) {
      return;
    }

    // Require Valid
    rruleIsValid(this.sendRRule, true);

    // Require Dtstart
    if (!rruleHasDtstart(this.sendRRule, true)) {
      console.log(this.sendRRule);
      throw new Error('MessageTemplate.validRRuleForScheduledMessage: !rruleHasDtstart');
    }

    // Forbid tzid
    if (rruleHasTzid(this.sendRRule, true)) {
      console.log(this.sendRRule);
      throw new Error('MessageTemplate.validRRuleForScheduledMessage: rruleHasTzid');
    }

    // Forbid secondly
    if (rruleIsSecondly(this.sendRRule, true)) {
      console.log(this.sendRRule);
      throw new Error('MessageTemplate.validRRuleForScheduledMessage: rruleIsSecondly');
    }

    // Forbid Minutely
    if (rruleIsMinutely(this.sendRRule, true)) {
      console.log(this.sendRRule);
      throw new Error('MessageTemplate.validRRuleForScheduledMessage: rruleIsMinutely');
    }

    // Forbid Count
    if (rruleHasCount(this.sendRRule, true)) {
      console.log(this.sendRRule);
      throw new Error('MessageTemplate.validRRuleForScheduledMessage: rruleHasCount');
    }
  }

  private validateRRuleAndContextTypeInSync(): void {
    const rruleIsNilOrDefault = isNilOrDefault(
      this.sendRRule,
      MessageTemplateSchema.sendRRule,
      MessageTemplate.getSchema(),
    );
    const sendTimeDependsOnRRule = this.doesSendTimeDependOnRRule();
    if ((sendTimeDependsOnRRule && rruleIsNilOrDefault) || (!sendTimeDependsOnRRule && !rruleIsNilOrDefault)) {
      console.error({ sendTimeDependsOnRRule, rruleIsNilOrDefault });
      throw new Error('MessageTemplate.validateRRule: sendTimeDependsOnRRule && rruleIsNilOrDefault are incompatible');
    }
  }
}
