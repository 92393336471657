import { cloneDeep } from 'lodash';

import { CommunicationSessionId } from '../../../../../objects/core/communication-session-id/communication-session-id';
import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { PossibleAdminRole } from '../../../../../objects/user/admin-roles/types';
import { PossibleRole } from '../../../../../objects/user/roles/types';
import { CommunicationsRequestName } from '../../../communications-request-name';
import { CommunicationsRequest } from '../../generic/communications-request/communications-request';
import { CommunicationsRequestSchema } from '../../generic/communications-request/communications-request-schema';

import { CommunicationsRequestDialConferenceConstructor } from './communications-request-dial-conference-constructor';
import { CommunicationsRequestDialConferenceSchema } from './communications-request-dial-conference-schema';

export class CommunicationsRequestDialConference extends CommunicationsRequest {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected dialUserId!: string;

  protected callerIdObjId!: string;

  protected sessionId!: CommunicationSessionId;

  protected conferenceFriendlyName!: string | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationsRequestDialConferenceConstructor) {
    (parameters as any)[CommunicationsRequestSchema.type] = CommunicationsRequestName.dialConference;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationsRequestDialConference {
    return new CommunicationsRequestDialConference(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationsRequestDialConference.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationsRequestDialConferenceSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Roles
  /////////////////////////////////////////////////////////////////////////////

  public getRequiredRoles(): PossibleRole[] {
    return ['voicemail'];
  }

  public getRequiredAdminRoles(): PossibleAdminRole[] | undefined {
    return undefined;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getDialUserId() {
    return cloneDeep(this.dialUserId);
  }

  public getCallerIdObjId() {
    return cloneDeep(this.callerIdObjId);
  }

  public getSessionId() {
    return cloneDeep(this.sessionId);
  }

  public getConferenceFriendlyName() {
    return cloneDeep(this.conferenceFriendlyName);
  }
}
