import Joi from 'joi';

import { SerializableObjectSchema } from '../../../../generic/serialization/serializable-object-schema';
import { ServiceableAddressE164PhoneSchema } from '../../serviceable-address-type/serviceable-address-e164-phone/serviceable-address-e164-phone-schema';
import { ServiceableAddressSchema } from '../serviceable-address/serviceable-address-schema';

export class AnyServiceableAddressSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The type of the entity
  public static readonly type = 'type';

  private static readonly SCHEMA_ID = 'anyServiceableAddress';

  private static computedSchema = Joi.alternatives()
    .try(...[new ServiceableAddressE164PhoneSchema().getJoiSchema()])
    .id(AnyServiceableAddressSchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static GenericDefaults = class {
    // The name of the command
    public static readonly type = undefined;
  };

  /**
   * Always serialize the command name
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  public static getSchemaId(): string {
    return `#${AnyServiceableAddressSchema.SCHEMA_ID}`;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } | import('joi').AnySchema {
    return AnyServiceableAddressSchema.computedSchema;
  }

  public getCollection(orgId: string) {
    return new ServiceableAddressSchema().getCollection(orgId);
  }

  public getCollectionName() {
    return new ServiceableAddressSchema().getCollectionName();
  }
}
