import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { PossibleAdminRole } from '../../../../../objects/user/admin-roles/types';
import { PossibleRole } from '../../../../../objects/user/roles/types';
import { CommunicationsRequestName } from '../../../communications-request-name';
import { CommunicationsRequest } from '../../generic/communications-request/communications-request';
import { CommunicationsRequestSchema } from '../../generic/communications-request/communications-request-schema';

import { CommunicationsRequestOnTaskRouterConfigurationUpdatedConstructor } from './communications-request-on-task-router-configuration-updated-constructor';
import { CommunicationsRequestOnTaskRouterConfigurationUpdatedSchema } from './communications-request-on-task-router-configuration-updated-schema';

export class CommunicationsRequestOnTaskRouterConfigurationUpdated extends CommunicationsRequest {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationsRequestOnTaskRouterConfigurationUpdatedConstructor) {
    (parameters as any)[CommunicationsRequestSchema.type] = CommunicationsRequestName.onTaskRouterConfigurationUpdated;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(
    validationResult: import('joi').ValidationResult,
  ): CommunicationsRequestOnTaskRouterConfigurationUpdated {
    return new CommunicationsRequestOnTaskRouterConfigurationUpdated(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationsRequestOnTaskRouterConfigurationUpdated.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationsRequestOnTaskRouterConfigurationUpdatedSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Roles
  /////////////////////////////////////////////////////////////////////////////

  public getRequiredRoles(): PossibleRole[] {
    return ['orgAdmin'];
  }

  public getRequiredAdminRoles(): PossibleAdminRole[] | undefined {
    return undefined;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
}
