import type { settings as SurveySettings } from 'survey-core';

let initialized = false;

export const configureSurveyJS = (settings: typeof SurveySettings) => {
  if (initialized) {
    return;
  }

  initialized = true;
  settings.storeUtcDates = true;
};
