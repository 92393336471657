import { groupBy } from 'lodash';

import { AllDataUser } from '../../user/all-data-user/all-data-user';
import { EventData } from '../event-data/event-data';
import { EventRequest } from '../event-request/event-request';

import { AllDataEvent } from './all-data-event';

export const makeAllDataEventMap = (
  events: EventData[],
  allDataUserMap: Map<string, AllDataUser>,
  eventRequests: EventRequest[],
): Map<string, AllDataEvent> => {
  const result = new Map<string, AllDataEvent>();

  // Shard events by id
  const eventsById = new Map<string, EventData>();
  for (const event of events) {
    eventsById.set(event.getId(), event);
  }

  // Shard requests by associated event
  const requestsByEvent = groupBy(eventRequests, (obj) => obj.getEventId());

  // Make AllDataEvent for each eventId
  for (const [eventId, requests] of Object.entries(requestsByEvent)) {
    // Collect all requests into a map
    const eventRequestsForOneEventMap: Map<string, EventRequest> = new Map();
    for (const request of requests) {
      eventRequestsForOneEventMap.set(request.getId(), request);
    }

    // Make AllDataEvent
    const eventData = eventsById.get(eventId);
    const allDataEvent = new AllDataEvent(eventData, allDataUserMap, eventRequestsForOneEventMap);
    eventsById.delete(eventId);
    result.set(eventId, allDataEvent);
  }

  // Add events that have no associted request
  for (const [eventId, event] of eventsById.entries()) {
    const allDataEvent = new AllDataEvent(event, allDataUserMap, new Map());
    result.set(eventId, allDataEvent);
  }

  return result;
};
