import { cloneDeep, isNil } from 'lodash';

import { CallList, CallListSchema, isNilOrDefaultDocId } from '@pwp-common';

import { editorOutputToCalleeEntities } from '../../../workers-autocomplete/editor-output/callee-entities/editor-output-to-callee-entities/editor-output-to-callee-entities';
import { CallListEditorOutput } from '../editor-output/call-list-editor-output';
import { editorOutputToOfferConfig } from '../editor-output/editor-output-to-offer-config/editor-output-to-offer-config';

export const getUpdatedCallList = (params: {
  originalCallList: CallList;
  editorOutput: CallListEditorOutput;
}): CallList => {
  if (params.originalCallList.getId() !== params.editorOutput.id) {
    console.error(params);
    throw new Error('getUpdatedCallList: call list id mismatch');
  }

  if (isNilOrDefaultDocId(params.editorOutput.id)) {
    console.error(params);
    throw new Error('getUpdatedCallList: call list id is missing');
  }
  const updatedObj = cloneDeep(params.originalCallList);
  updatedObj.setField(CallListSchema.displayName, params.editorOutput.displayName);
  updatedObj.setField(CallListSchema.description, params.editorOutput.description);
  updatedObj.setField(CallListSchema.calleeEntities, editorOutputToCalleeEntities(params.editorOutput.calleeEntities));
  updatedObj.setField(
    CallListSchema.offerConfig,
    editorOutputToOfferConfig({
      callListOfferConfigCallerIdStrategy: params.editorOutput.offerConfig.callerId,
      callListOfferConfigMachineDetection: params.editorOutput.offerConfig.ringTime,
    }),
  );

  /**
   * Set the 'dialTimeoutSeconds' field if the value of
   * editorOutput?.offerConfig?.ringTime?.ringTimeoutSeconds
   * is defined.
   */
  if (!isNil(params.editorOutput?.offerConfig?.ringTime?.ringTimeoutSeconds)) {
    updatedObj.setField(CallListSchema.dialTimeoutSeconds, params.editorOutput.offerConfig.ringTime.ringTimeoutSeconds);
  }

  updatedObj.sanityCheck();

  return updatedObj;
};
