import { cloneDeep } from 'lodash';

import { SerializableObject } from '../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../generic/serialization/serializable-object-schema';
import { TemplateText } from '../../text/template-text/template-text';

import { ConversationWaitingRoomConfigConstructor } from './conversation-waiting-room-config-constructor';
import { ConversationWaitingRoomConfigSchema } from './conversation-waiting-room-config-schema';

export class ConversationWaitingRoomConfig extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected enabled!: boolean;

  protected waitingRoomMessage!: TemplateText;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ConversationWaitingRoomConfigConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ConversationWaitingRoomConfig {
    return new ConversationWaitingRoomConfig(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ConversationWaitingRoomConfig.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ConversationWaitingRoomConfigSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getEnabled(): boolean {
    return cloneDeep(this.enabled);
  }

  public getWaitingRoomMessage(): TemplateText {
    return cloneDeep(this.waitingRoomMessage);
  }
}
