import moment from 'moment-timezone';

import { EntityStats } from '@pwp-common';

import { createReportRow } from '../create-report-row/create-report-row';
import { createValuesForEntityStats } from '../create-values-for-entity-stats/create-values-for-entity-stats';
import { formatUsersWithDuration } from '../formatting/format-users-with-duration';
import { getUsersWithHighestCount } from '../get-users-with-highest-count/get-users-with-highest-count';
import { AdminReportEventStatsReportRowInput, ReportRow } from '../interfaces';

export const createUserWhoTookTheMostEventHoursRow = ({
  entityStats,
  usersStatsData,
  eventType,
}: AdminReportEventStatsReportRowInput): ReportRow =>
  createReportRow(
    'userWhoTookTheMostEventHours',
    createValuesForEntityStats(entityStats, (_, period) => {
      const getUserEventHours = (userStats: EntityStats) =>
        userStats.getChunkByPeriod(period)?.getHours(eventType) ?? 0;

      const { users, count } = getUsersWithHighestCount(usersStatsData, getUserEventHours);

      return formatUsersWithDuration({ users, count: moment.duration({ hours: count }).asMilliseconds() });
    }),
    { eventType },
  );
