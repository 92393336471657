import { SchemaField } from '../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';
import { ShakenHeader } from '../shaken-header/shaken-header';
import { ShakenPayload } from '../shaken-payload/shaken-payload';

export class ShakenPassportSchema extends SerializableObjectSchema {
  public static readonly header = 'header';

  public static readonly payload = 'payload';

  public static readonly signature = 'signature';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly header = undefined;

    public static readonly payload = undefined;

    public static readonly signature = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[ShakenPassportSchema.header] = SchemaField.obj(
      ShakenHeader,
      ShakenPassportSchema.Defaults.header,
    ).required();
    parameters[ShakenPassportSchema.payload] = SchemaField.obj(
      ShakenPayload,
      ShakenPassportSchema.Defaults.payload,
    ).required();
    parameters[ShakenPassportSchema.signature] = SchemaField.string(ShakenPassportSchema.Defaults.signature).required();
    /* eslint-enable */

    return parameters;
  }
}
