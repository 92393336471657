import { Injectable } from '@angular/core';
import { loadingFor } from '@ngneat/loadoff';
import { cloneDeep, isNil } from 'lodash';
import moment from 'moment-timezone';
import { combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';

import { AllDataUser, AsyncServiceRequestE164Phone, CallList, OrgData, VoicemailMetadata } from '@pwp-common';

import { AsyncServiceRequestService } from '../../../../services/async-service-request/async-service-request.service';
import { CallListService } from '../../../../services/call/call-list/call-list.service';
import { CallLogService } from '../../../../services/call/call-log/call-log.service';
import { OrgDataService } from '../../../../services/orgs/org-data/org-data.service';
import { AllDataUserService } from '../../../../services/user/all-data-user/all-data-user.service';
import { AuthService } from '../../../../services/user/auth/auth.service';
import { RolesService } from '../../../../services/user/roles/roles.service';
import { VoicemailMetadataService } from '../../../../services/voicemail/voicemail-metadata/voicemail-metadata.service';
import { CallbackRowConstructor } from '../../row/callback-row/callback-row-constructor';
import { InboxRow } from '../../row/inbox-row/inbox-row';
import { VoicemailRowConstructor } from '../../row/voicemail-row/voicemail-row-constructor';

import { makeInboxRows } from './make-inbox-rows/make-inbox-rows';

@Injectable({
  providedIn: 'root',
})
export class InboxService {
  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////
  public $allDataUser: Observable<Map<string, AllDataUser>>;

  public $callLists: Observable<Map<string, CallList>>;

  public $orgData: Observable<OrgData>;

  public $isAdmin: Observable<boolean>;

  public $loggedInUserId: Observable<string>;

  private updatedVoicemails = new Subject<string>();

  private updatedAsyncServiceRequests = new Subject<string>();

  loaderBase = loadingFor('allUserDataMap', 'callListMap', 'isAdmin', 'orgData', 'loggedInUserId');

  loader = loadingFor('voicemailTableRows', 'asyncServiceRequestRows');

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  constructor(
    private authService: AuthService,
    private allDataUserService: AllDataUserService,
    private callListService: CallListService,
    private voicemailMetadataService: VoicemailMetadataService,
    private callLogsService: CallLogService,
    private orgDataService: OrgDataService,
    private rolesService: RolesService,
    private asyncServiceRequestService: AsyncServiceRequestService,
  ) {
    this.$loggedInUserId = this.authService.getUserId();
    this.$allDataUser = this.allDataUserService
      .getDocs(false)
      .pipe(this.loaderBase.allUserDataMap.track(), shareReplay(1));
    this.$callLists = this.callListService.getDocs().pipe(this.loaderBase.callListMap.track(), shareReplay(1));
    this.$orgData = this.orgDataService.getOrgData().pipe(this.loaderBase.orgData.track(), shareReplay(1));
    this.$isAdmin = this.rolesService.getRoles().pipe(
      map((z) => z.isOrgAdmin()),
      this.loaderBase.isAdmin.track(),
      shareReplay(1),
    );
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Inbox Rows
  ///////////////////////////////////////////////////////////////////////
  public getInboxRows(params: { start: moment.Moment; end: moment.Moment }): Observable<InboxRow[]> {
    const logMetadata = { ...params };
    console.log('InboxService.getInboxRows: Starting', logMetadata);
    if (isNil(params.start) || isNil(params.end) || !params.start.isValid() || !params.end.isValid()) {
      console.warn('InboxService.getInboxRows: Exiting because of invalid input', logMetadata);
      return;
    }

    const start = cloneDeep(params.start).startOf('day');
    const end = cloneDeep(params.end).endOf('day');

    const $voicemails = this.getVoicemails({ start, end });
    const $callbacks = this.getAsyncServiceRequests({ start, end });

    return combineLatest([$voicemails, $callbacks]).pipe(map((value) => makeInboxRows(value[0], value[1])));
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Voicemail
  ///////////////////////////////////////////////////////////////////////
  public markVoicemailAsUpdated(docId: string): void {
    this.updatedVoicemails.next(docId);
  }

  private getVoicemail(docId: string): Observable<VoicemailRowConstructor | undefined> {
    let voicemailMetadata: VoicemailMetadata;
    const observable = this.voicemailMetadataService.getDoc(docId).pipe(
      // Step 1: Get associated call logs
      switchMap((value) => {
        voicemailMetadata = value;
        return forkJoin([
          this.callLogsService.getDoc(voicemailMetadata.getSessionId()),
          this.$allDataUser,
          this.$callLists,
          this.$orgData,
        ]);
      }),

      map((value) => {
        const callLog = value[0];
        const allUserDataMap = value[1];
        const callLists = value[2];
        const orgData = value[3];

        return { voicemailMetadata, allUserDataMap, callLists, callLog, orgData };
      }),
    );

    return observable;
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Async Service Requests
  ///////////////////////////////////////////////////////////////////////
  public markAsyncServiceRequestUpdated(docId: string): void {
    this.updatedAsyncServiceRequests.next(docId);
  }

  private getAsyncServiceRequest(docId: string): Observable<CallbackRowConstructor | undefined> {
    let asyncServiceRequestE164Phone: AsyncServiceRequestE164Phone;
    const observable = this.asyncServiceRequestService.getDoc(docId).pipe(
      // Step 1: Get associated call logs
      switchMap((value) => {
        asyncServiceRequestE164Phone = value as AsyncServiceRequestE164Phone;
        return forkJoin([
          this.callLogsService.getDoc(asyncServiceRequestE164Phone.getSessionId().getInboundCallSessionId()),
          this.$allDataUser,
          this.$callLists,
          this.$orgData,
        ]);
      }),
      map((value) => {
        const callLog = value[0];
        const allUserDataMap = value[1];
        const callLists = value[2];
        const orgData = value[3];

        return { asyncServiceRequest: asyncServiceRequestE164Phone, allUserDataMap, callLists, callLog, orgData };
      }),
    );

    return observable;
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Voicemails
  ///////////////////////////////////////////////////////////////////////
  private getVoicemails(params: { start: moment.Moment; end: moment.Moment }): Observable<VoicemailRowConstructor[]> {
    const logMetadata = { ...params };
    console.log('InboxService.getVoicemails: Starting', logMetadata);
    if (isNil(params.start) || isNil(params.end) || !params.start.isValid() || !params.end.isValid()) {
      console.warn('InboxService.getVoicemails: Exiting because of invalid input', logMetadata);
      return;
    }

    const start = cloneDeep(params.start).startOf('day');
    const end = cloneDeep(params.end).endOf('day');

    let voicemailMetadataArray: VoicemailMetadata[] = [];
    let rows: VoicemailRowConstructor[] = [];
    const $originalRows = this.voicemailMetadataService.getVoicemailsWithRecordingStartInRange(start, end, true).pipe(
      // Step 1: Get associated call logs
      switchMap((value) => {
        voicemailMetadataArray = value;
        return forkJoin([
          this.callLogsService.getDocsWithIds(voicemailMetadataArray.map((z) => z.getSessionId())),
          this.$allDataUser,
          this.$callLists,
          this.$orgData,
        ]);
      }),

      map((value) => {
        const callLogs = value[0];
        const allUserDataMap = value[1];
        const callLists = value[2];
        const orgData = value[3];

        rows = [];
        for (const voicemailMetadata of voicemailMetadataArray) {
          rows.push({
            voicemailMetadata,
            allUserDataMap,
            callLists,
            callLog: callLogs.get(voicemailMetadata.getSessionId()),
            orgData,
          });
        }
        return rows;
      }),
      this.loader.voicemailTableRows.track(),
    );

    const updates = new Map<string, VoicemailRowConstructor>();

    const $updateVM = this.updatedVoicemails.asObservable().pipe(
      switchMap((docId) => this.getVoicemail(docId)),
      map((voicemailRow) => {
        updates.set(voicemailRow.voicemailMetadata.getId(), voicemailRow);
      }),
      startWith(undefined),
    );
    return combineLatest([$originalRows, $updateVM]).pipe(
      map((value) => {
        const updateRows: VoicemailRowConstructor[] = [];
        for (const row of value[0]) {
          updateRows.push(updates.get(row.voicemailMetadata.getId()) ?? row);
        }
        return updateRows;
      }),
    );
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Async Service Requests
  ///////////////////////////////////////////////////////////////////////
  private getAsyncServiceRequests(params: {
    start: moment.Moment;
    end: moment.Moment;
  }): Observable<CallbackRowConstructor[]> {
    const logMetadata = { ...params };
    console.log('InboxService.getAsyncServiceRequests: Starting', logMetadata);
    if (isNil(params.start) || isNil(params.end) || !params.start.isValid() || !params.end.isValid()) {
      console.warn('InboxService.getAsyncServiceRequests: Exiting because of invalid input', logMetadata);
      return;
    }

    const start = cloneDeep(params.start).startOf('day');
    const end = cloneDeep(params.end).endOf('day');

    let asyncServiceRequests: AsyncServiceRequestE164Phone[] = [];
    let rows: CallbackRowConstructor[] = [];
    const $originalRows = this.asyncServiceRequestService.getAsyncServiceRequestsCreatedInRange(start, end, true).pipe(
      // Step 1: Get associated call logs
      switchMap((value) => {
        asyncServiceRequests = value as AsyncServiceRequestE164Phone[];
        return forkJoin([
          this.callLogsService.getDocsWithIds(
            asyncServiceRequests.map((z) => z.getSessionId().getInboundCallSessionId()),
          ),
          this.$allDataUser,
          this.$callLists,
          this.$orgData,
        ]);
      }),

      map((value) => {
        const callLogs = value[0];
        const allUserDataMap = value[1];
        const callLists = value[2];
        const orgData = value[3];

        rows = [];
        for (const asyncServiceRequest of asyncServiceRequests) {
          rows.push({
            asyncServiceRequest,
            allUserDataMap,
            callLists,
            callLog: callLogs.get(asyncServiceRequest.getSessionId().getInboundCallSessionId()),
            orgData,
          });
        }
        return rows;
      }),
      this.loader.asyncServiceRequestRows.track(),
    );

    const updates = new Map<string, CallbackRowConstructor>();

    const $updateVM = this.updatedAsyncServiceRequests.asObservable().pipe(
      switchMap((docId) => this.getAsyncServiceRequest(docId)),
      map((rowConstructor) => {
        updates.set(rowConstructor.asyncServiceRequest.getId(), rowConstructor);
      }),
      startWith(undefined),
    );
    return combineLatest([$originalRows, $updateVM]).pipe(
      map((value) => {
        const updateRows: CallbackRowConstructor[] = [];
        for (const rowConstructor of value[0]) {
          updateRows.push(updates.get(rowConstructor.asyncServiceRequest.getId()) ?? rowConstructor);
        }
        return updateRows;
      }),
    );
  }
}
