import type { AnySchema } from 'joi';
import Joi from 'joi';

import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { AutomationResponseCreateAutomationSchema } from '../../responses/automation-response-create-automation/automation-response-create-automation-schema';

export class AnyAutomationResponseSchema extends SerializableObjectSchema {
  private static readonly SCHEMA_ID = 'anyAutomationResponse';

  private static computedSchema = Joi.alternatives()
    .try(
      new AutomationResponseCreateAutomationSchema().getJoiSchema(),
      new AutomationResponseCreateAutomationSchema().getJoiSchema(),
    )
    .id(AnyAutomationResponseSchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly type = 'type';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly GenericDefaults = class {
    public static readonly type = undefined;
  };

  public static getSchemaId(): string {
    return `#${AnyAutomationResponseSchema.SCHEMA_ID}`;
  }

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: AnySchema } | AnySchema {
    return AnyAutomationResponseSchema.computedSchema;
  }
}
