import type { AnySchema } from 'joi';

import { SchemaField } from '../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../generic/serialization/serializable-object-schema';

export class CommunicationSessionIdSchema extends SerializableObjectSchema {
  public static readonly conversationLogId = 'conversationLogId';

  public static readonly inboundCallSessionId = 'inboundCallSessionId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly conversationLogId = undefined;

    public static readonly inboundCallSessionId = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: AnySchema } {
    const parameters = {} as any;

    parameters[CommunicationSessionIdSchema.inboundCallSessionId] = SchemaField.autoGeneratedDocId(
      CommunicationSessionIdSchema.Defaults.inboundCallSessionId,
    ).optional();
    parameters[CommunicationSessionIdSchema.conversationLogId] = SchemaField.autoGeneratedDocId(
      CommunicationSessionIdSchema.Defaults.conversationLogId,
    ).optional();

    return parameters;
  }
}
