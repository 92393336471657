import { cloneDeep } from 'lodash';

import { DBDocSchema } from '../../../../generic/db-doc/db-doc-schema';
import { CommunicationWidget } from '../../generic/communication-widget/communication-widget';
import { CommunicationWidgetSchema } from '../../generic/communication-widget/communication-widget-schema';
import { CommunicationWidgetName } from '../../generic/communication-widget-name';

import { CommunicationWidgetEnforceServiceLimitConstructor } from './communication-widget-enforce-service-limit-constructor';
import { CommunicationWidgetEnforceServiceLimitSchema } from './communication-widget-enforce-service-limit-schema';

export class CommunicationWidgetEnforceServiceLimit extends CommunicationWidget {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected serviceLimitExceededAudioId!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationWidgetEnforceServiceLimitConstructor) {
    (parameters as any)[CommunicationWidgetSchema.type] = CommunicationWidgetName.enforceServiceLimit;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(
    validationResult: import('joi').ValidationResult,
  ): CommunicationWidgetEnforceServiceLimit {
    return new CommunicationWidgetEnforceServiceLimit(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationWidgetEnforceServiceLimit.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new CommunicationWidgetEnforceServiceLimitSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getServiceLimitExceededAudioId(): string {
    return cloneDeep(this.serviceLimitExceededAudioId);
  }
}
