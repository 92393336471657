import { cloneDeep, isNil } from 'lodash';

import { CallList } from '../../call/call-list/call-list/call-list';
import { CallLog } from '../../call/call-log/call-log';
import { CallLogSchema } from '../../call/call-log/call-log-schema';
import { AssignedUserType } from '../../event/event-data/enums';
import { EventData } from '../../event/event-data/event-data';
import { EventDataSchema } from '../../event/event-data/event-data-schema';
import { SerializableObject } from '../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../generic/serialization/serializable-object-schema';
import { EventType } from '../../org/event-type/event-type';
import { AssignedUserStatTypes } from '../assigned-user-type-stats/assigned-user-stats-types';
import { CallStats } from '../call-stats/call-stats';
import { EventStats } from '../event-stats/event-stats';

import { EntityStatsChunkConstructor } from './entity-stats-chunk-constructor';
import { EntityStatsChunkSchema } from './entity-stats-chunk-schema';

/**
 * All Entity stats associated to a chunk of data.
 */
export class EntityStatsChunk extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected calls!: CallStats;

  protected events!: EventStats;

  protected eventsByType!: Map<string, EventStats>;

  protected callList!: Map<string, CallStats>;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: EntityStatsChunkConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): EntityStatsChunk {
    return new EntityStatsChunk(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(EntityStatsChunk.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new EntityStatsChunkSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getCalls(): CallStats;
  public getCalls(callList: CallList): CallStats | undefined;
  public getCalls(callList?: CallList): CallStats | undefined {
    if (isNil(callList)) {
      return cloneDeep(this.calls);
    }

    return this.callList.get(callList.getId());
  }

  public getEvents(): EventStats {
    return cloneDeep(this.events);
  }

  public getEventsByType(): Map<string, EventStats> {
    return cloneDeep(this.eventsByType);
  }

  public getCallList(): Map<string, CallStats> {
    return this.callList;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Other Properties
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Indicate if this object is the trivial one.
   */
  public isEmpty(): boolean {
    if (!this.calls.hasOnlyDefaultProperties()) {
      return false;
    }

    for (const stats of this.callList.values()) {
      if (!stats.hasOnlyDefaultProperties()) {
        return false;
      }
    }

    for (const stats of this.eventsByType.values()) {
      if (!stats.hasOnlyDefaultProperties()) {
        return false;
      }
    }

    return true;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Number of Hours and Events
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getHours(eventType?: EventType, assignedUserStatType?: AssignedUserStatTypes): number {
    let eventStats: EventStats | undefined = this.getEvents();
    if (eventType !== undefined) {
      eventStats = this.getEventsByType().get(eventType.getInternalName());
    }

    const result =
      eventStats
        ?.getAssignedUserTypeStats()
        .get(assignedUserStatType || AssignedUserStatTypes.any)!
        .getDurationHours() || 0;
    return result;
  }

  public getNumEvents(eventType?: EventType, assignedUserStatType?: AssignedUserStatTypes): number {
    let eventStats: EventStats | undefined = this.getEvents();
    if (eventType !== undefined) {
      eventStats = this.getEventsByType().get(eventType.getInternalName());
    }

    const result =
      eventStats
        ?.getAssignedUserTypeStats()
        .get(assignedUserStatType || AssignedUserStatTypes.any)!
        .getNum() || 0;
    return result;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Update this obj
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Update this object to include the given call
   * @param callLog callLog to update the object with
   */
  public updateWithCall(callLog: CallLog): EntityStatsChunk {
    this.calls.updateWith(callLog);

    const callListId = callLog.getCallListId();
    if (callListId !== CallLogSchema.Defaults.callListId) {
      if (!this.callList.has(callListId)) {
        this.callList.set(callListId, CallStats.deserialize({}));
      }

      const callStats = this.callList.get(callListId)!;
      callStats.updateWith(callLog);
      this.callList.set(callListId, callStats);
    }

    return this;
  }

  /**
   * Update this object to include the given event
   * @param event eventData to update the object with
   */
  public updateWithEvent(event: EventData, assignedUserType: AssignedUserType): EntityStatsChunk {
    this.events.updateWith(event, assignedUserType);
    const eventType = event.getType();
    if (eventType === EventDataSchema.Defaults.type) {
      return this;
    }

    if (!this.eventsByType.has(eventType)) {
      this.eventsByType.set(eventType, EventStats.deserialize({}) as EventStats);
    }

    this.eventsByType.get(eventType)!.updateWith(event, assignedUserType);

    return this;
  }
}
