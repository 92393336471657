import { SerializableObject } from '../../../../../../../generic/serialization/serializable-object';
import { CommunicationSessionEventLogDialAsyncServiceRequestDelivery } from '../../type/communication-session-event-log-dial-async-service-request-delivery/communication-session-event-log-dial-async-service-request-delivery';
import { CommunicationSessionEventLogDialConference } from '../../type/communication-session-event-log-dial-conference/communication-session-event-log-dial-conference';
import { CommunicationSessionEventLogDialReservationOffer } from '../../type/communication-session-event-log-dial-reservation-offer/communication-session-event-log-dial-reservation-offer';
import { CommunicationSessionEventLogDialPhone } from '../communication-session-event-log-dial-phone/communication-session-event-log-dial-phone';
import { CommunicationSessionEventLogDialPhoneCallType } from '../communication-session-event-log-dial-phone-call-type';

import { AnyCommunicationSessionEventLogDialPhoneSchema } from './any-communication-session-event-log-dial-phone-schema';

export abstract class AnyCommunicationSessionEventLogDialPhone extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  public static _deserialize(validationResult: import('joi').ValidationResult): CommunicationSessionEventLogDialPhone {
    const typeString = validationResult.value[AnyCommunicationSessionEventLogDialPhoneSchema.callType];
    const callType =
      CommunicationSessionEventLogDialPhoneCallType[
        typeString as keyof typeof CommunicationSessionEventLogDialPhoneCallType
      ];
    if (callType === undefined) {
      throw new Error(
        `AnyCommunicationSessionEventLog._deserialize: User Error: Cannot deserialize obj with type=${typeString}`,
      );
    }

    switch (callType) {
      case CommunicationSessionEventLogDialPhoneCallType.asyncServiceRequestDelivery: {
        return new CommunicationSessionEventLogDialAsyncServiceRequestDelivery(validationResult.value);
      }
      case CommunicationSessionEventLogDialPhoneCallType.dialConference: {
        return new CommunicationSessionEventLogDialConference(validationResult.value);
      }
      case CommunicationSessionEventLogDialPhoneCallType.reservationOffer: {
        return new CommunicationSessionEventLogDialReservationOffer(validationResult.value);
      }
      default: {
        throw new Error(`AnyCommunicationSessionEventLog._deserialize Unknown type: ${callType}`);
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema() {
    return new AnyCommunicationSessionEventLogDialPhoneSchema();
  }
}
