import { cloneDeep } from 'lodash';

import { SerializableObjectSchema } from '../../generic/serialization/serializable-object-schema';
import { VoiceResponseCommandName } from '../any-voice-response-command/voice-response-command-name';
import { VoiceResponseCommand } from '../generic/voice-response-command';
import { VoiceResponseCommandSchema } from '../generic/voice-response-command-schema';

import { FlagServiceRequestConstructor } from './flag-service-request-constructor';
import { FlagServiceRequestSchema } from './flag-service-request-schema';

export class FlagServiceRequest extends VoiceResponseCommand {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  deny!: boolean;
  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: FlagServiceRequestConstructor) {
    // This hack ensures that deserializing a VoiceCommand is equal to instanting it directly.
    // The difference is trivial, and only required for intuitive unit testing.
    (parameters as any)[VoiceResponseCommandSchema.commandName] = VoiceResponseCommandName.flagServiceRequest;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): FlagServiceRequest {
    return new FlagServiceRequest(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(FlagServiceRequest.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new FlagServiceRequestSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getDeny(): boolean {
    return cloneDeep(this.deny);
  }
  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////
}
