import type { ValidationResult } from 'joi';

import { AnySerializableObject } from '../../../../../objects/generic/serialization/any-serializable-object/any-serializable-object';
import { ObjectBuilder } from '../../../../../objects/generic/serialization/interfaces';
import { AutomationRequestName } from '../../../automation-request-name';
import { AutomationResponseCreateAutomation } from '../../responses/automation-response-create-automation/automation-response-create-automation';

import { AnyAutomationResponseSchema } from './any-automation-response-schema';

export abstract class AnyAutomationResponse extends AnySerializableObject {
  protected static getObjectBuilder(validationResult: ValidationResult): ObjectBuilder {
    const typeString = validationResult.value[AnyAutomationResponseSchema.type];
    const type = AutomationRequestName[typeString as keyof typeof AutomationRequestName];

    if (type === undefined) {
      throw new Error(`AnyAutomationResponse._deserialize: User Error: Cannot deserialize obj with type=${typeString}`);
    }

    switch (type) {
      case AutomationRequestName.createAutomation:
        return AutomationResponseCreateAutomation;
      default:
        throw new Error(`Automation._deserialize Unknown type: ${type}`);
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): AnyAutomationResponseSchema {
    return new AnyAutomationResponseSchema();
  }
}
