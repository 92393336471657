<ng-template #objEditor>
  <form *ngIf="!loading" [formGroup]="form">
    <div class="form-row">
      <div class="col">
        <!-- Display Name -->
        <mat-form-field class="full-width">
          <textarea class="full-width" formControlName="displayName" matInput placeholder="Display Name"></textarea>
          <span *ngIf="displayName.invalid && displayName.touched"> Display Name Invalid </span>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Description -->
        <mat-form-field class="full-width">
          <textarea class="full-width" formControlName="description" matInput placeholder="Description"></textarea>
          <span *ngIf="description.invalid && description.touched"> Description Invalid </span>
        </mat-form-field>
      </div>
    </div>

    Docs:
    <ul>
      <li>
        <a href="https://www.twilio.com/docs/voice/twiml/dial#timeout">Dial Timeout Seconds</a> is the number of seconds
        that Twilio will wait for the dialed party to answer the call. Twilio will always add about 5 seconds to
        whatever you set below (the minimum below is also incidently 5 seconds). Eg, if you set 10 then twilio will wait
        almost 15 seconds before hanging up.
      </li>
    </ul>

    <div class="form-row">
      <div class="col">
        <!-- Capacity -->
        <mat-form-field class="full-width">
          <input class="full-width" formControlName="capacity" matInput placeholder="Capacity" type="number" />
          <span *ngIf="capacity.errors?.min && capacity.touched">
            Capacity too low: {{ capacity.errors.min.actual }}
          </span>
          <span *ngIf="capacity.errors?.max && capacity.touched">
            Please contact support to increase your capacity: {{ capacity.errors.max.actual }}
          </span>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Dial Timeout -->
        <mat-form-field class="full-width">
          <input
            class="full-width"
            formControlName="dialTimeoutSeconds"
            matInput
            placeholder="Dial Timeout (Seconds)"
            type="number"
          />
          <span *ngIf="dialTimeoutSeconds.errors?.min && dialTimeoutSeconds.touched">
            Dial Timeout too low: {{ dialTimeoutSeconds.errors.min.actual }}
          </span>
          <span *ngIf="dialTimeoutSeconds.errors?.max && dialTimeoutSeconds.touched">
            Please contact support to increase your dial timeout: {{ dialTimeoutSeconds.errors.max.actual }}
          </span>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Confirm Join IVR -->
        <app-obj-select
          [label]="'Confirm Join IVR'"
          [orderedItems]="ivrsArray"
          [(kvPair)]="selectedConfirmJoinIVRKVPair"
        ></app-obj-select>
      </div>
      <div class="col">
        <!-- PII Retention Duration -->
        <mat-form-field class="full-width">
          <input
            class="full-width"
            formControlName="pIIRetentionDuration"
            matInput
            placeholder="PII Retention Duration"
          />
          <mat-error *ngIf="pIIRetentionDuration.errors && pIIRetentionDuration.dirty">
            Invalid PII Retention Duration
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>

  <mat-accordion *ngIf="!loading">
    <!-- This container holds all CalleeEntityEditorComponents -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Callee Entitites </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <app-callee-entity-select-and-edit
        [eventTypes]="eventTypes"
        [userDataArray]="userDataArray"
        [(objList)]="calleeEntities"
      >
      </app-callee-entity-select-and-edit>
    </mat-expansion-panel>

    <!-- This container holds onNoUserLeftToDialModIncCallCommands -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          No User Left To Dial Commands Mod Inc Call (Commands to Modify Incoming Call)</mat-panel-title
        >
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <app-vrc-list-editor [(objList)]="onNoUserLeftToDialModIncCallCommands"></app-vrc-list-editor>
    </mat-expansion-panel>

    <!-- This container holds Callee Hangup On Star Commands -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Caller Hangup On Star Commands </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <app-vrc-list-editor [(objList)]="callerHangupOnStarCommands"></app-vrc-list-editor>
    </mat-expansion-panel>

    <!-- This container holds Callee Hangup On Star Commands -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Callee Hangup On Star Commands </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <app-vrc-list-editor [(objList)]="calleeHangupOnStarCommands"></app-vrc-list-editor>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>

<app-obj-select-and-edit
  [doDelete]="doDelete"
  [doRefresh]="doRefresh"
  [doUpload]="doUpload"
  [editorTemplate]="objEditor"
  [label]="'Call List'"
  [modifiedSelectedItem]="getUpdatedObj()"
  [objIsValid]="isValidObjScoper"
  [orderedItems]="itemsArray | async"
  [selectedKVPair]="selectedKVPair"
  (duplicateClick)="duplicateClick()"
  (newClick)="newClick()"
  (selectedKVPairChange)="onSelectedKVPairChange($event)"
>
</app-obj-select-and-edit>
