import type { AnySchema } from 'joi';

import { TimeSelection } from '../../core/time-selection/time-selection/time-selection';
import { SchemaField } from '../../generic/serialization/schema-field';
import { AnyVoiceResponseCommand } from '../any-voice-response-command/any-voice-response-command';
import { VoiceResponseCommandSchema } from '../generic/voice-response-command-schema';

export class VRCIfTimeSchema extends VoiceResponseCommandSchema {
  public static readonly timeSelection = 'timeSelection';

  public static readonly commands = 'commands';

  public static readonly Defaults = class extends VoiceResponseCommandSchema.GenericDefaults {
    public static readonly timeSelection = undefined;

    public static readonly commands = undefined;
  };

  public getSchemaDefinition(): { [key: string]: AnySchema } {
    const parameters = super.getSchemaDefinition() as { [key: string]: AnySchema };

    parameters[VRCIfTimeSchema.timeSelection] = SchemaField.obj(
      TimeSelection,
      VRCIfTimeSchema.Defaults.timeSelection,
    ).required();
    parameters[VRCIfTimeSchema.commands] = SchemaField.arrayOfType(
      SchemaField.obj(AnyVoiceResponseCommand, undefined),
      VRCIfTimeSchema.Defaults.commands,
    ).required();

    return parameters;
  }
}
