// Fixed
export { CommunicationWorkflowTargetTimeoutFixedConstructor } from './communication-workflow-target-timeouts/communication-workflow-target-timeout-fixed/communication-workflow-target-timeout-fixed-constructor';
export { CommunicationWorkflowTargetTimeoutFixed } from './communication-workflow-target-timeouts/communication-workflow-target-timeout-fixed/communication-workflow-target-timeout-fixed';
export { CommunicationWorkflowTargetTimeoutFixedSchema } from './communication-workflow-target-timeouts/communication-workflow-target-timeout-fixed/communication-workflow-target-timeout-fixed-schema';

// Generic
export { CommunicationWorkflowTargetTimeout } from './generic/communication-workflow-target-timeout/communication-workflow-target-timeout';
export {
  CommunicationWorkflowTargetTimeoutName,
  CommunicationWorkflowTargetTimeoutNameType,
} from './generic/communication-workflow-target-timeout-name';

////////////////////////////////////////////////////////////////////////////////////////////////
// Warning On Import
////////////////////////////////////////////////////////////////////////////////////////////////

console.warn(
  '--------- pwp-common:objects/communication/communication-workflow/communication-workflow-target/communication-workflow-target-timeouts/index.ts: Imported ---------',
);
