import { Directive, inject, TemplateRef } from '@angular/core';

import { DataTableCellContext } from './interface';

@Directive({
  selector: '[appDataTableCell]',
  standalone: true,
})
export class DataTableCellDirective {
  public readonly templateRef: TemplateRef<DataTableCellContext> = inject(TemplateRef);

  public static ngTemplateContextGuard(
    directive: DataTableCellDirective,
    context: unknown,
  ): context is DataTableCellContext {
    return true;
  }
}
