import { SchemaField } from '../../../../generic/serialization/schema-field';
import { ServiceableAddressSchema } from '../../generic/serviceable-address/serviceable-address-schema';

export class ServiceableAddressE164PhoneSchema extends ServiceableAddressSchema {
  public static readonly e164Phone = 'e164Phone';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;

    public static readonly e164Phone = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[ServiceableAddressE164PhoneSchema.e164Phone] = SchemaField.e164Phone(
      ServiceableAddressE164PhoneSchema.Defaults.e164Phone,
    ).optional();
    /* eslint-enable */

    return parameters;
  }
}
