import Joi from 'joi';

import { SerializableObjectSchema } from '../../../../../../../generic/serialization/serializable-object-schema';
import { CommunicationSessionEventLogSendMessageNotificationSchema } from '../../type/communication-session-event-log-send-message-notification/communication-session-event-log-send-message-notification-schema';
import { CommunicationSessionEventLogSendReservationOfferSchema } from '../../type/communication-session-event-log-send-reservation-offer/communication-session-event-log-send-reservation-offer-schema';

export class AnyCommunicationSessionEventLogSendMessageSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The type of the entity
  public static readonly messageType = 'messageType';

  private static readonly SCHEMA_ID = 'anyCommunicationSessionEventLogSendMessage';

  private static computedSchema = Joi.alternatives()
    .try(
      new CommunicationSessionEventLogSendReservationOfferSchema().getJoiSchema(),
      new CommunicationSessionEventLogSendMessageNotificationSchema().getJoiSchema(),
    )
    .id(AnyCommunicationSessionEventLogSendMessageSchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static GenericDefaults = class {
    // The name of the command
    public static readonly type = undefined;
  };

  /**
   * Always serialize the command name
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  public static getSchemaId(): string {
    return `#${AnyCommunicationSessionEventLogSendMessageSchema.SCHEMA_ID}`;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } | import('joi').AnySchema {
    return AnyCommunicationSessionEventLogSendMessageSchema.computedSchema;
  }
}
