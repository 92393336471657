import { ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { isNil } from 'lodash';
import moment from 'moment-timezone';
import { Observable } from 'rxjs';

import { EventConfig, EventConfigSchema, EventData, EventType, UserData } from '@pwp-common';

import {
  DATETIME_LOCAL_CONTROL_STR_FORMAT,
  getFieldValuesFromFormInComponent,
  getTimestampFromDateTimeLocalFieldControl,
} from '../../../common/objects/form-helper';
import { KVPair } from '../../../common/objects/kvpair';
import { userDataDisplayFormat } from '../../../common/objects/user-data-display-format';
import { DatetimeValidator } from '../../../common/validators/datetime-validator/datetime-validator';
import { EventConfigService } from '../../../services/event/event-config/event-config.service';
import { UserDataService } from '../../../services/user/user-data/user-data.service';
import { ConfigDocSelectAndEdit } from '../../generic/abstract-classes/config-doc-select-and-edit';

@Component({
  selector: 'app-event-config-select-and-edit',
  templateUrl: './event-config-select-and-edit.component.html',
  styleUrls: ['./event-config-select-and-edit.component.css'],
})
export class EventConfigSelectAndEditComponent
  extends ConfigDocSelectAndEdit<EventConfig>
  implements OnInit, OnChanges
{
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  form: UntypedFormGroup = new UntypedFormGroup({});

  userDataArray: Observable<UserData[]>;

  eventTypes: EventType[] = [];

  selectedEventTypeKVPair: KVPair<EventType>;

  selectedAssignedUser: KVPair<UserData> | undefined;

  selectedAssignedBackupUser: KVPair<UserData> | undefined;

  sevenDayEvents: EventData[];

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  userDataDisplayFormat = userDataDisplayFormat;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private userDataService: UserDataService,
    private formBuilder: UntypedFormBuilder,
    // @ts-ignore
    private _dataService: EventConfigService,
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    super(EventConfig, _dataService, _changeDetectorRef);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.orgData?.previousValue?.getId() !== changes.orgData?.currentValue?.getId() &&
      this.orgData !== undefined
    ) {
      this.eventTypes = this.orgData.getEventTypes();
    }
    super.ngOnChanges(changes);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Get Data
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getData() {
    super.getData();
    this.userDataArray = this.userDataService.getDocsArray();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parameters = getFieldValuesFromFormInComponent(
      this.selectedKVPair?.value.getId(),
      [
        EventConfigSchema.displayName,
        EventConfigSchema.description,
        EventConfigSchema.rrule,
        EventConfigSchema.color,
        EventConfigSchema.generate,
        EventConfigSchema.extendScheduleNumDays,
      ],
      EventConfigSchema.Defaults,
      this,
    );

    /* eslint-disable */
    parameters[EventConfigSchema.start] = getTimestampFromDateTimeLocalFieldControl(
      this.start,
      this.orgData.getTimezone(),
      undefined,
    )
      .set('seconds', 0)
      .set('milliseconds', 0);
    parameters[EventConfigSchema.end] = getTimestampFromDateTimeLocalFieldControl(
      this.end,
      this.orgData.getTimezone(),
      undefined,
    )
      .set('seconds', 0)
      .set('milliseconds', 0);
    if (this.selectedEventTypeKVPair?.value === undefined) {
      return undefined;
    }

    parameters[EventConfigSchema.type] =
      this.selectedEventTypeKVPair.value?.getInternalName() || EventConfigSchema.Defaults.type;
    parameters[EventConfigSchema.assignedUserId] =
      this.selectedAssignedUser?.value?.getId() || EventConfigSchema.Defaults.assignedUserId;
    parameters[EventConfigSchema.assignedBackupUserId] =
      this.selectedAssignedBackupUser?.value?.getId() || EventConfigSchema.Defaults.assignedBackupUserId;
    /* eslint-enable */

    return new EventConfig(parameters);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: EventConfig) {
    // Init Form
    const formConfig = {} as any;
    formConfig[EventConfigSchema.displayName] = [
      obj.getDisplayName(),
      [Validators.required, Validators.maxLength(500)],
    ];
    formConfig[EventConfigSchema.description] = [
      obj.getDescription(),
      [Validators.required, Validators.maxLength(500)],
    ];
    formConfig[EventConfigSchema.start] = [
      obj.getStart(this.orgData.getTimezone()).format(DATETIME_LOCAL_CONTROL_STR_FORMAT),
      [Validators.required],
    ];
    formConfig[EventConfigSchema.end] = [
      obj.getEnd(this.orgData.getTimezone()).format(DATETIME_LOCAL_CONTROL_STR_FORMAT),
      [Validators.required],
    ];
    formConfig[EventConfigSchema.rrule] = [obj.getRRule(), [Validators.required, Validators.maxLength(500)]];
    formConfig[EventConfigSchema.generate] = [obj.getGenerate()];
    formConfig[EventConfigSchema.color] = [obj.getColor(), [Validators.maxLength(500)]];
    formConfig[EventConfigSchema.extendScheduleNumDays] = [obj.getExtendScheduleNumDays()];

    this.selectedEventTypeKVPair = new KVPair({ id: obj.getType() });

    if (obj.getAssignedUserId() !== EventConfigSchema.Defaults.assignedUserId) {
      this.selectedAssignedUser = new KVPair({ id: obj.getAssignedUserId() });
    }
    if (obj.getAssignedBackupUserId() !== EventConfigSchema.Defaults.assignedBackupUserId) {
      this.selectedAssignedUser = new KVPair({ id: obj.getAssignedBackupUserId() });
    }

    this.form = this.formBuilder.group(formConfig, {
      validator: DatetimeValidator.isAfterControl(
        EventConfigSchema.start,
        EventConfigSchema.end,
        this.orgData.getTimezone(),
      ),
    });
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  get displayName(): AbstractControl | null {
    return this.form.get(EventConfigSchema.displayName);
  }

  get description(): AbstractControl | null {
    return this.form.get(EventConfigSchema.description);
  }

  get start(): AbstractControl | null {
    return this.form.get(EventConfigSchema.start);
  }

  get end(): AbstractControl | null {
    return this.form.get(EventConfigSchema.end);
  }

  get rrule(): AbstractControl | null {
    return this.form.get(EventConfigSchema.rrule);
  }

  get generate(): AbstractControl | null {
    return this.form.get(EventConfigSchema.generate);
  }

  get color(): AbstractControl | null {
    return this.form.get(EventConfigSchema.color);
  }

  get extendScheduleNumDays(): AbstractControl | null {
    return this.form.get(EventConfigSchema.extendScheduleNumDays);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Events to generate
  ///////////////////////////////////////////////////////////////////////////////////////////

  public getEventsInSevenDays(): EventData[] {
    this.sevenDayEvents = [];
    try {
      this.sevenDayEvents =
        this.getUpdatedObj()?.generateEventsWithStartBetween(
          moment(),
          moment().add(7, 'days'),
          this.orgData.getTimezone(),
        ) || [];
    } catch (error) {
      return [];
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObjScoper = (): boolean => this.isValidObj();

  isValidObj(): boolean {
    // This checks that the form is valid.
    if (!super.isValidObj()) {
      return false;
    }

    if (isNil(this.selectedEventTypeKVPair?.value)) {
      return false;
    }

    return true;
  }
}
