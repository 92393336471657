import { cloneDeep, isEqual, isNil } from 'lodash';

import {
  CommunicationTaskQueueWorkerList,
  CommunicationTaskQueueWorkerListEvent,
  CommunicationTaskQueueWorkerListName,
  CommunicationTaskQueueWorkerListUser,
} from '@pwp-common';

import { WorkersAutocompleteEditorOutput } from '../../workers-autocomplete-editor-output';

export const editorOutputToTaskQueueWorkerLists = (
  options: WorkersAutocompleteEditorOutput,
): CommunicationTaskQueueWorkerList[] => {
  let result: CommunicationTaskQueueWorkerList[] = [];

  let current: CommunicationTaskQueueWorkerList;
  for (const option of options) {
    let newWorkerList: CommunicationTaskQueueWorkerList;
    switch (option.type) {
      case 'deletedUser': {
        continue;
      }
      case 'deletedEvent': {
        continue;
      }
      case 'user': {
        newWorkerList = new CommunicationTaskQueueWorkerListUser({
          type: CommunicationTaskQueueWorkerListName.userList,
          userIds: [option.userId],
        });
        break;
      }
      case 'event': {
        newWorkerList = new CommunicationTaskQueueWorkerListEvent({
          type: CommunicationTaskQueueWorkerListName.event,
          eventTypes: [option.eventTypeId],
          assignedUserTypes: [option.assignedUserType],
        });
        break;
      }
      default: {
        console.error(options);
        throw new Error('editorOutputToTaskQueueWorkerLists: Not implemented!');
      }
    }
    const accResult = accumulateWorkerLists({ historical: result, current, new: newWorkerList });
    result = accResult.historical;
    current = accResult.current;
  }

  if (!isNil(current)) {
    result = [...result, current];
  }

  return result;
};

// eslint-disable-next-line max-len
const accumulateWorkerLists = (params: {
  historical: CommunicationTaskQueueWorkerList[];
  current: CommunicationTaskQueueWorkerList | undefined;
  new: CommunicationTaskQueueWorkerList;
}): { historical: CommunicationTaskQueueWorkerList[]; current: CommunicationTaskQueueWorkerList } => {
  if (isNil(params.current)) {
    return {
      historical: cloneDeep(params.historical),
      current: cloneDeep(params.new),
    };
  }

  if (params.current.getType() !== params.new.getType()) {
    return {
      historical: [...cloneDeep(params.historical), cloneDeep(params.current)],
      current: cloneDeep(params.new),
    };
  }

  switch (params.current.getType()) {
    case CommunicationTaskQueueWorkerListName.userList: {
      const typedCurrent = params.current as CommunicationTaskQueueWorkerListUser;
      const typedNew = params.new as CommunicationTaskQueueWorkerListUser;
      const updated = new CommunicationTaskQueueWorkerListUser({
        type: CommunicationTaskQueueWorkerListName.userList,
        userIds: [...typedCurrent.getUserIds(), ...typedNew.getUserIds()],
      });

      return {
        historical: cloneDeep(params.historical),
        current: updated,
      };
    }
    case CommunicationTaskQueueWorkerListName.event: {
      const typedCurrent = params.current as CommunicationTaskQueueWorkerListEvent;
      const typedNew = params.new as CommunicationTaskQueueWorkerListEvent;

      // Same Event Types
      if (isEqual(typedCurrent.getEventTypes(), typedNew.getEventTypes())) {
        const updated = new CommunicationTaskQueueWorkerListEvent({
          type: CommunicationTaskQueueWorkerListName.event,
          eventTypes: typedCurrent.getEventTypes(),
          assignedUserTypes: [...typedCurrent.getAssignedUserTypes(), ...typedNew.getAssignedUserTypes()],
        });
        return {
          historical: cloneDeep(params.historical),
          current: updated,
        };
      }

      // Same Assigned User Types
      if (isEqual(typedCurrent.getAssignedUserTypes(), typedNew.getAssignedUserTypes())) {
        const updated = new CommunicationTaskQueueWorkerListEvent({
          type: CommunicationTaskQueueWorkerListName.event,
          eventTypes: [...typedCurrent.getEventTypes(), ...typedNew.getEventTypes()],
          assignedUserTypes: typedCurrent.getAssignedUserTypes(),
        });
        return {
          historical: cloneDeep(params.historical),
          current: updated,
        };
      }

      // Do not try to compress the representation in any other case
      return {
        historical: [...cloneDeep(params.historical), cloneDeep(params.current)],
        current: cloneDeep(params.new),
      };
    }
    default: {
      console.error(params);
      throw new Error(`accumulateWorkerLists: Unknown type ${params.current.getType()}`);
    }
  }
};
