import { Model } from 'survey-core';

import { surveyJSTheme } from './survey-js-theme';

export const createSurveyModel = (schema: string): Model => {
  const survey = new Model(schema);

  survey.applyTheme(surveyJSTheme);

  return survey;
};
