import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isNil } from 'lodash';

import { SerializableObject, SerializableObjectBuilder } from '@pwp-common';

export class ObjValidator {
  public static isSerializable<T extends SerializableObject>(deserializer: SerializableObjectBuilder<T>): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const { value } = control;
      if (isNil(value)) {
        return null;
      }

      try {
        const serializedObj = value.serialize();
        const deserializedObj: T = deserializer.deserialize(serializedObj);
        deserializedObj.sanityCheck();
      } catch (error) {
        return { objValidatorIsSerializable: value };
      }

      return null;
    };
  }

  public static isDeserializable<T extends SerializableObject>(
    deserializer: SerializableObjectBuilder<T>,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const { value } = control;
      if (isNil(value)) {
        return null;
      }

      try {
        deserializer.deserialize(value);
      } catch (error) {
        return { objValidatorIsDeserializable: value };
      }

      return null;
    };
  }
}
