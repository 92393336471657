import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import firebase from 'firebase/compat/app';
import { cloneDeep, isNil } from 'lodash';
import { firstValueFrom, map } from 'rxjs';

import { UserData } from '@pwp-common';

import { PasswordValidator } from '../../common/validators/password-validator';
import { FirebaseAuthAction } from '../../modules/auth/enums/firebase-auth-action';
import { AuthService } from '../../services/user/auth/auth.service';
import { RolesService } from '../../services/user/roles/roles.service';
import { UserDataService } from '../../services/user/user-data/user-data.service';

import { LoginAction } from './login-action';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private readonly authService = inject(AuthService);
  ////////////////////////////////////////////////////////////////////////////////
  // Variables
  ////////////////////////////////////////////////////////////////////////////////

  lang: string;

  // Form vars
  form: UntypedFormGroup;

  type: LoginAction = 'login';

  loading = false;

  serverMessage: string;

  // Vars used when type='password_reset'
  actionCode: string;

  emailFromPasswordResetLink: string;

  strongPasswordValidators = [
    Validators.required,
    Validators.minLength(10),
    PasswordValidator.containsDigitValidator(),
    PasswordValidator.containsSymbolValidator(),
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private angularFireAuth: AngularFireAuth,
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private rolesService: RolesService,
    private router: Router,
    private translocoService: TranslocoService,
    private userDataService: UserDataService,
  ) {}

  ////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////////////

  public ngOnInit(): void {
    this.initForm();
    this.changeType('login');
    this.languageListener();

    this.angularFireAuth.authState
      .pipe(untilDestroyed(this))
      .subscribe((user) => this.firebaseAuthChangeListener(user));
    this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe((data) => this.parseURL(data));
  }

  public ngOnDestroy(): void {
    this.changeDetectorRef.detach();
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Parse URL
  ////////////////////////////////////////////////////////////////////////////////

  private async parseURL(params: Params) {
    console.log('LoginComponent.parseURL: Starting.');

    if (isNil(params) || isNil(params.mode)) {
      console.log('LoginComponent.parseURL: Nothing to do.');
      return;
    }

    this.actionCode = this.activatedRoute.snapshot.queryParams.oobCode;

    // Change the form to a password reset form
    switch (params.mode) {
      case FirebaseAuthAction.forgotPassword: {
        this.changeType('request_password_reset');
        break;
      }
      case FirebaseAuthAction.verifyAndChangeEmail: {
        try {
          await this.angularFireAuth.applyActionCode(this.actionCode);
          this.serverMessage = this.translocoService.translate('login.email_updated');
          await this.router.navigate(['/auth/login']);
        } catch (error) {
          this.serverMessage = JSON.stringify(error);
        }
        this.changeDetectorRef.detectChanges();
        break;
      }
      case FirebaseAuthAction.resetPassword: {
        this.changeType('password_reset');
        try {
          this.emailFromPasswordResetLink = await this.angularFireAuth.verifyPasswordResetCode(this.actionCode);
        } catch (error) {
          this.serverMessage = JSON.stringify(error);
        }
        this.changeDetectorRef.detectChanges();
        break;
      }
      default: {
        console.log('LoginComponent.parseURL: Got unknown params. Doing nothing.', params);
      }
    }
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Language
  ////////////////////////////////////////////////////////////////////////////////

  private languageListener() {
    this.translocoService.langChanges$
      .pipe(
        map((lang: string, _) => {
          console.log(`Language Changed: ${lang}`);
          this.lang = lang;
          this.changeDetectorRef.detectChanges();
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Auth Listener
  ////////////////////////////////////////////////////////////////////////////////

  private firebaseAuthChangeListener = async (user: firebase.User) => {
    console.log('Firebase Auth Change Listener responded');
    if (user) {
      console.log('firebaseAuthChangeListener: User authenticated.');
      await this.redirectLoggedInUserAsNecessary();
    } else {
      console.log('firebaseAuthChangeListener: User not authenticated. No action to take.');
    }
  };

  private async redirectLoggedInUserAsNecessary(): Promise<void> {
    try {
      const isAuthorized = await firstValueFrom(this.userDataService.isAuthorizedAccount());

      if (!isAuthorized) {
        throw new Error(
          'redirectLoggedInUserAsNecessary:New user/Not authorized. Please ask an admin to create an account for you.',
        );
      }

      console.log('redirectLoggedInUserAsNecessary: Authorized user logging in.');

      const userData = await firstValueFrom(this.userDataService.getUserData());
      await this.syncAuthEmailAndDbEmail(userData);

      if (userData.hasIncompleteProfile()) {
        console.log('redirectLoggedInUserAsNecessary: Incomplete Profile. Navigating to user-profile.');
        await this.router.navigate(['/user-profile']);
        return;
      }

      console.log('redirectLoggedInUserAsNecessary: Profile Complete: Continuing to events');

      const userRoles = await firstValueFrom(this.rolesService.getRoles());
      const redirectPath = userRoles.isOrgAdmin() ? '/modify-schedule' : '/events-calendar';

      await this.router.navigate([redirectPath]);
    } catch (e) {
      console.error('redirectLoggedInUserAsNecessary: There is a problem with your account.', e);
      await this.authService.logout();
    } finally {
      console.log('redirectLoggedInUserAsNecessary: Complete');
    }
  }

  syncAuthEmailAndDbEmail(userData: UserData): Promise<void> {
    const _userData = cloneDeep(userData);
    return this.angularFireAuth.currentUser.then((authUser) => {
      if (_userData.getNotificationEmail() === authUser.email) {
        console.log('syncAuthEmailAndDbEmail: Emails are in sync');
        return;
      }
      _userData.setNotificationEmail(authUser.email);
      return this.userDataService.upload({ obj: _userData }).toPromise();
    });
  }
  ////////////////////////////////////////////////////////////////////////////////
  // Form
  ////////////////////////////////////////////////////////////////////////////////

  private initForm() {
    this.form = this.formBuilder.group({
      email: ['', []],
      acceptTOSPrivacyPolicy: [false, ''],
      password: [''],
      passwordConfirm: [''],
    });
  }

  get isLogin() {
    return this.type === 'login';
  }

  get isRequestPasswordReset() {
    return this.type === 'request_password_reset';
  }

  get isPasswordReset() {
    return this.type === 'password_reset';
  }

  get email() {
    return this.form.get('email');
  }

  get password() {
    return this.form.get('password');
  }

  get acceptTOSPrivacyPolicy() {
    return this.form.get('acceptTOSPrivacyPolicy');
  }

  get passwordDoesMatch() {
    if (this.type !== 'password_reset') {
      return true;
    }
    return this.password.value === this.passwordConfirm.value;
  }

  get passwordConfirm() {
    return this.form.get('passwordConfirm');
  }

  changeType(val: LoginAction) {
    this.type = val;

    switch (val) {
      case 'login': {
        this.form.controls.email.setValidators([Validators.required, Validators.email]);
        this.form.controls.password.setValidators([Validators.required]);
        this.form.controls.acceptTOSPrivacyPolicy.setValidators([Validators.requiredTrue]);
        break;
      }
      case 'password_reset': {
        this.form.controls.email.setValidators([]);
        this.form.controls.acceptTOSPrivacyPolicy.setValidators([]);
        this.form.controls.password.setValidators(this.strongPasswordValidators);
        this.form.controls.passwordConfirm.setValidators(this.strongPasswordValidators);
        break;
      }
    }
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Submit Form
  ////////////////////////////////////////////////////////////////////////////////

  async onSubmit() {
    this.loading = true;
    const email = this.email.value;
    const password = this.password.value;

    try {
      switch (this.type) {
        case 'login': {
          await this.angularFireAuth.signInWithEmailAndPassword(email, password);
          break;
        }
        case 'request_password_reset': {
          await this.angularFireAuth.sendPasswordResetEmail(email);
          this.serverMessage = 'Check your email';
          break;
        }
        case 'password_reset': {
          if (!this.passwordDoesMatch) {
            this.serverMessage = 'Passwords Do Not Match';
            break;
          }
          await this.angularFireAuth.confirmPasswordReset(this.actionCode, this.password.value);
          this.serverMessage = 'Ok';
          await this.angularFireAuth.signInWithEmailAndPassword(this.emailFromPasswordResetLink, password);
          break;
        }
      }
    } catch (err) {
      this.serverMessage = JSON.stringify(err);
    }

    this.changeDetectorRef.detectChanges();
    this.loading = false;
  }
}
