import Joi from 'joi';

import { GenericDisplayableSchema } from '../../../generic/displayable/generic-displayable-schema';
import { SchemaField } from '../../../generic/serialization/schema-field';
import { CommunicationWorkflowDefaultFilter } from '../communication-workflow-default-filter/communication-workflow-default-filter';
import { CommunicationWorkflowFilter } from '../communication-workflow-filter/communication-workflow-filter';

export class CommunicationWorkflowSchema extends GenericDisplayableSchema {
  public static readonly defaultFilter = 'defaultFilter';

  public static readonly filters = 'filters';

  public static readonly sid = 'sid';

  public static readonly taskReservationTimeoutMS = 'taskReservationTimeoutMS';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'communicationWorkflows';

    public static readonly taskReservationTimeoutMSMax = 86400000;
  };

  static Defaults = class {
    public static readonly description = 'missing-description';

    public static readonly displayName = 'missing-displayName';

    public static readonly defaultFilter = undefined;

    public static readonly filters = undefined;

    public static readonly sid = undefined;

    public static taskReservationTimeoutMS = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // prettier-ignore
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationWorkflowSchema.defaultFilter] = SchemaField.obj(CommunicationWorkflowDefaultFilter, CommunicationWorkflowSchema.Defaults.defaultFilter).optional();
    parameters[CommunicationWorkflowSchema.filters] = SchemaField.shortObjArray(CommunicationWorkflowFilter, CommunicationWorkflowSchema.Defaults.filters).required();
    parameters[CommunicationWorkflowSchema.sid] = SchemaField.string(CommunicationWorkflowSchema.Defaults.sid).optional();
    parameters[CommunicationWorkflowSchema.taskReservationTimeoutMS] = (SchemaField.number(CommunicationWorkflowSchema.Defaults.taskReservationTimeoutMS).required() as Joi.NumberSchema)
        .min(0)
        .max(CommunicationWorkflowSchema.Constants.taskReservationTimeoutMSMax)
    ;
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${CommunicationWorkflowSchema.Constants.collection}`;
  }
}
