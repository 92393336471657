import { cloneDeep } from 'lodash';

import { DBDocSchema } from '../../../../generic/db-doc/db-doc-schema';
import { GenericDisplayable } from '../../../../generic/displayable/generic-displayable';
import { CommunicationWidgetName } from '../communication-widget-name';

export abstract class CommunicationWidget extends GenericDisplayable {
  protected type!: CommunicationWidgetName;

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    throw new Error('Error: Cannot get schema for abstract CommunicationWidget');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getType(): CommunicationWidgetName {
    return cloneDeep(this.type);
  }
}
