<ng-container *transloco="let t; read: 'change-other-user-password'">

  <legend>
    {{ t('title') }}
  </legend>

  <p-messages severity="info">
    <ng-template pTemplate>
      <p class="wrap">{{ t('instructions') }}</p>
    </ng-template>
  </p-messages>

  <form class="change-other-user-password__form">
    <fieldset class="change-other-user-password__form-fields">
      <label *ngrxLet="form.controls.newPassword as newPasswordControl" class="change-other-user-password__form-field">
        <span class="change-other-user-password__form-field-label">{{ t('newPasswordField.label') }}</span>
        <p-password
          styleClass="p-password--full-width"
          [formControl]="newPasswordControl"
          [placeholder]="t('newPasswordField.placeholder')"
          [toggleMask]="true"
        ></p-password>
        <app-form-field-help [control]="newPasswordControl">
          <ng-container *ngIf="newPasswordControl.errors as newPasswordErrors" form-field-help-errors>
            <div *ngIf="newPasswordErrors.minlength as minLength">
              {{ t('newPasswordField.minLengthError', { minLength: minLength.requiredLength }) }}
            </div>
            <div *ngIf="newPasswordErrors.missingDigit">
              {{ t('newPasswordField.missingDigitError') }}
            </div>
            <div *ngIf="newPasswordErrors.missingSymbol">
              {{ t('newPasswordField.missingSymbolError') }}
            </div>
          </ng-container>
        </app-form-field-help>
      </label>
      <label
        *ngrxLet="form.controls.newPasswordConfirmation as newPasswordConfirmationControl"
        class="change-other-user-password__form-field"
      >
        <span class="change-other-user-password__form-field-label">{{ t('newPasswordConfirmationField.label') }}</span>
        <p-password
          styleClass="p-password--full-width"
          [feedback]="false"
          [formControl]="newPasswordConfirmationControl"
          [class.ng-invalid]="newPasswordConfirmationControl.touched && form.invalid"
          [placeholder]="t('newPasswordConfirmationField.placeholder')"
          [toggleMask]="true"
        ></p-password>
        <app-form-field-help [control]="form">
          <ng-container
            *ngIf="newPasswordConfirmationControl.touched && form.errors as formErrors"
            form-field-help-errors
          >
            <div *ngIf="formErrors.mustMatch">
              {{ t('newPasswordConfirmationField.mustMatchError') }}
            </div>
          </ng-container>
        </app-form-field-help>
      </label>

      <p-messages class="p-messages--no-margin" [closable]="false"></p-messages>
    </fieldset>

    <p-button
      *ngrxLet="loading$ as loading"
      [attr.aria-busy]="loading"
      [disabled]="form.invalid || loading"
      [label]="t('resetPasswordButtonLabel')"
      [loading]="loading"
      (onClick)="changePassword()"
    ></p-button>
  </form>

</ng-container>
