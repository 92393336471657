import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import firebase from 'firebase/compat/app';
import parsePhoneNumber from 'libphonenumber-js';
import { cloneDeep, isNil } from 'lodash';
import moment from 'moment-timezone';
import { lastValueFrom } from 'rxjs';

import { OrgData, UserData, UserDataSchema, UserPrivateData, UserPrivateDataSchema } from '@pwp-common';

import {
  DATETIME_LOCAL_CONTROL_STR_FORMAT,
  getTimestampFromDateTimeLocalFieldControl,
} from '../../../../common/objects/form-helper';
import { DatetimeValidator } from '../../../../common/validators/datetime-validator/datetime-validator';
import { PhoneValidator } from '../../../../common/validators/phone-validator/phone-validator';
import { UploadDictionary } from '../../../../services/generic/interfaces';
import { OrgDataService } from '../../../../services/orgs/org-data/org-data.service';
import { UserDataService } from '../../../../services/user/user-data/user-data.service';
import { UserPrivateDataService } from '../../../../services/user/user-private-data/user-private-data.service';
import { makeAuditEntry } from '../../../generic/settings/common/audit-entry/helpers/make-audit-entry';
import { AuditEntry } from '../../../generic/settings/common/audit-entry/interfaces';
import { ChangeMyPrimaryEmailComponent } from '../change-my-primary-email/change-my-primary-email.component';

@Component({
  selector: 'app-my-user-profile-editor',
  templateUrl: './my-user-profile-editor.component.html',
  styleUrls: ['./my-user-profile-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyUserProfileEditorComponent implements OnInit {
  ////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////

  formNameUserData = 'userData';

  formNameUserPrivateData = 'userPrivate';

  formControlNameAvailability = 'availability';

  formControlNameUnavailableUntil = 'unavailableUntil';

  possibleValuesAvailability: { label: string; value: boolean }[] = [
    { label: 'optionAvailable', value: true },
    { label: 'optionUnavailable', value: false },
  ];
  ////////////////////////////////////////////////////////////////////////////
  // Variables
  ////////////////////////////////////////////////////////////////////////////

  loading = true;

  form: UntypedFormGroup;

  auditEntry: AuditEntry;

  orgData: OrgData;

  userData: UserData;

  userPrivate: UserPrivateData;

  timezone: string;

  userBadgeSeverity = 'success';

  ////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////////
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private userDataService: UserDataService,
    private userPrivateDataService: UserPrivateDataService,
    private orgDataService: OrgDataService,
  ) {}

  ngOnInit(): void {
    void this.getDataPromise();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  doUpdateUI = () => {
    this.updateUI();
  };

  updateUI() {
    if (isNil(this.orgData)) {
      return;
    }
    this.loading = true;

    /**
     * Determine how to fill out the form
     */
    const unavailableUntil = (
      this.userPrivate.isAwayFromPhone() ? this.userPrivate.getAwayFromPhoneEndTime() : moment().add(1, 'hour')
    )
      .tz(this.orgData.getTimezone())
      .format(DATETIME_LOCAL_CONTROL_STR_FORMAT);
    const availability = !this.userPrivate?.isAwayFromPhone();
    const notificationEmail = this.userData.getNotificationEmail() ?? UserDataSchema.Defaults.notificationEmail;
    const firstName = this.userData?.getFirstName() ?? UserDataSchema.Defaults.firstName;
    const lastInitial = this.userData?.getLastInitial() ?? UserDataSchema.Defaults.lastInitial;
    const e164Phone = this.userPrivate?.getUSLocalPhone() ?? UserPrivateDataSchema.Defaults.e164Phone;

    /**
     * Create the form. We create it here because the datetime
     * validators depend on timezone.
     */
    this.form = new UntypedFormGroup({
      availability: new UntypedFormControl(availability, []),
      unavailableUntil: new UntypedFormControl(unavailableUntil, [
        DatetimeValidator.isBefore(moment().add(24, 'h'), this.timezone),
        DatetimeValidator.isAfter(moment(), this.timezone),
      ]),
      userData: new UntypedFormGroup({
        notificationEmail: new UntypedFormControl({ value: notificationEmail, disabled: true }),
        firstName: new UntypedFormControl(firstName, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
        ]),
        lastInitial: new UntypedFormControl(lastInitial, [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100),
        ]),
      }),
      userPrivate: new UntypedFormGroup({
        e164Phone: new UntypedFormControl(e164Phone, [Validators.required, PhoneValidator.isValidPhone()]),
      }),
    });

    /**
     * Set remaining variables & exit
     */
    this.auditEntry = makeAuditEntry(this.userPrivate);
    this.userBadgeSeverity = this.userPrivate?.isAwayFromPhone() ? 'secondary' : 'success';
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Get Data
  /////////////////////////////////////////////////////////////////////////////////////////////

  refreshData = () => this.getDataPromise();

  async getDataPromise() {
    this.loading = true;
    return Promise.all([
      lastValueFrom(this.orgDataService.getOrgData()),
      lastValueFrom(this.userDataService.getUserData()),
      lastValueFrom(this.userPrivateDataService.getUserPrivateData()),
    ]).then((result) => {
      this.orgData = result[0];
      this.userData = result[1];
      this.userPrivate = result[2];
      this.timezone = this.orgData.getTimezone();
      this.updateUI();
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Do Upload
  /////////////////////////////////////////////////////////////////////////////////////////////

  doUpload = () => this.upload();

  async upload() {
    const updatedUserData = cloneDeep(this.userData);
    updatedUserData.setField(
      UserDataSchema.firstName,
      this.form.value[this.formNameUserData][UserDataSchema.firstName],
    );
    updatedUserData.setField(
      UserDataSchema.lastInitial,
      this.form.value[this.formNameUserData][UserDataSchema.lastInitial],
    );

    const updatedUserPrivateData = cloneDeep(this.userPrivate);
    const localNumberDigitsOnly = parsePhoneNumber(this.e164Phone.value, 'US').number.slice(2);
    updatedUserPrivateData.setField(UserPrivateDataSchema.e164Phone, localNumberDigitsOnly);

    /**
     * Determine the value of awayFromPhoneStartTime
     */
    const fieldOverrides: UploadDictionary = {};
    fieldOverrides[UserPrivateDataSchema.awayFromPhoneStartTime] = firebase.firestore.FieldValue.delete();
    if (this.availability?.value === false) {
      const unavailableUntil = getTimestampFromDateTimeLocalFieldControl(
        this.unavailableUntil,
        this.orgData.getTimezone(),
        moment(),
      )!;
      fieldOverrides[UserPrivateDataSchema.awayFromPhoneStartTime] = unavailableUntil.subtract(24, 'hours').toDate();
    }

    await lastValueFrom(this.userDataService.upload({ obj: updatedUserData }));
    await lastValueFrom(this.userPrivateDataService.upload({ obj: updatedUserPrivateData, overrides: fieldOverrides }));
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid
  /////////////////////////////////////////////////////////////////////////////////////////////

  getIsValid = (): boolean => this.isValid();

  isValid() {
    return this.form.valid;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////
  get firstName() {
    return this.form.get([this.formNameUserData, UserDataSchema.firstName]) as UntypedFormControl;
  }

  get lastInitial() {
    return this.form.get([this.formNameUserData, UserDataSchema.lastInitial]) as UntypedFormControl;
  }

  get notificationEmail() {
    return this.form.get([this.formNameUserData, UserDataSchema.notificationEmail]) as UntypedFormControl;
  }

  get e164Phone() {
    return this.form.get([this.formNameUserPrivateData, UserPrivateDataSchema.e164Phone]) as UntypedFormControl;
  }

  get availability() {
    return this.form.get(this.formControlNameAvailability) as UntypedFormControl;
  }

  get unavailableUntil() {
    return this.form.get(this.formControlNameUnavailableUntil) as UntypedFormControl;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Change Email
  ///////////////////////////////////////////////////////////////////////////////////////////
  openChangeEmailDialog() {
    this.dialog.open(ChangeMyPrimaryEmailComponent, {
      hasBackdrop: true,
      minWidth: '50%',
    });
  }
}
