import { cloneDeep } from 'lodash';

import { SerializableObject } from '../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';

import { OutboundCallSessionParticipantConstructor } from './outbound-call-session-participant-constructor';
import { OutboundCallSessionParticipantSchema } from './outbound-call-session-participant-schema';
import { OutboundCallSessionParticipantTypeType } from './outbound-call-session-participant-type';

export class OutboundCallSessionParticipant extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected type!: OutboundCallSessionParticipantTypeType;

  protected participantId!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: OutboundCallSessionParticipantConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): OutboundCallSessionParticipant {
    return new OutboundCallSessionParticipant(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(OutboundCallSessionParticipant.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new OutboundCallSessionParticipantSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getType(): OutboundCallSessionParticipantTypeType {
    return cloneDeep(this.type);
  }

  public getParticipantId(): string {
    return cloneDeep(this.participantId);
  }
}
