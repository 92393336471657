import { cloneDeep } from 'lodash';

import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';
import { IdentityConfig } from '../identity-config/identity-config';

import { IdentityConfigConversationCaptureTarget } from './identity-config-conversation-capture-target';
import { IdentityConfigConversationConstructor } from './identity-config-conversation-constructor';
import { IdentityConfigConversationSchema } from './identity-config-conversation-schema';

export class IdentityConfigConversation extends IdentityConfig<IdentityConfigConversationCaptureTarget> {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected captureTarget!: IdentityConfigConversationCaptureTarget | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: IdentityConfigConversationConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): IdentityConfigConversation {
    return new IdentityConfigConversation(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(IdentityConfigConversation.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new IdentityConfigConversationSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getCaptureTarget() {
    return cloneDeep(this.captureTarget);
  }
}
