<ng-container *transloco="let t; read: 'accordion-wizard-step-content-footer'">
  <!-- Continue Step -->
  <button
    *ngIf="stepIndex < numSteps - 1"
    class="flex-grow-1 p-button-lg"
    pButton
    type="button"
    [disabled]="hasError || continueLoadingIndicator"
    label="{{ t('continueButtonLabel') }}"
    [loading]="continueLoadingIndicator"
    (click)="continueClick()"
  ></button>

  <!--  Last Step-->
  <button
    *ngIf="stepIndex >= numSteps - 1"
    class="flex-grow-1 p-button-lg"
    pButton
    type="button"
    [disabled]="hasError || continueLoadingIndicator"
    label="{{ t('finalContinueButtonLabel') }}"
    [loading]="continueLoadingIndicator"
    (click)="continueClick()"
  ></button>
</ng-container>
