import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

import { displayTime, DisplayTimeOptions } from '@pwp-common';

@Pipe({
  name: 'displayTimestamp',
  standalone: true,
  pure: true,
})
export class DisplayTimestampPipe implements PipeTransform {
  public transform(timestamp: moment.Moment, options?: DisplayTimeOptions): string {
    return displayTime(timestamp, timestamp.tz(), options);
  }
}
