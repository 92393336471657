import { AsyncServiceRequest } from '../../generic/async-service-request/async-service-request';
import { AsyncServiceRequestSchema } from '../../generic/async-service-request/async-service-request-schema';
import { AsyncServiceRequestName } from '../../generic/async-service-request-name';

import { AsyncServiceRequestE164PhoneConstructor } from './async-service-request-e164-phone-constructor';
import { AsyncServiceRequestE164PhoneSchema } from './async-service-request-e164-phone-schema';

export class AsyncServiceRequestE164Phone extends AsyncServiceRequest {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: AsyncServiceRequestE164PhoneConstructor) {
    (parameters as any)[AsyncServiceRequestSchema.type] = AsyncServiceRequestName.e164Phone;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): AsyncServiceRequestE164Phone {
    return new AsyncServiceRequestE164Phone(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(AsyncServiceRequestE164Phone.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): AsyncServiceRequestE164PhoneSchema {
    return new AsyncServiceRequestE164PhoneSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
}
