import { isNil } from 'lodash';

import { ServiceLimit } from '../../../communication/service-limit/service-limit/service-limit';
import { VoiceResponseCommandName } from '../../any-voice-response-command/voice-response-command-name';
import { EnforceServiceLimit } from '../../enforce-service-limit/enforce-service-limit';
import { ServiceOperation } from '../service-operation/service-operation';
import { ServiceOperationData } from '../service-operation-data/service-operation-data';

export class AllDataServiceOperation {
  public data: ServiceOperationData = {
    serviceLimitMap: new Map<string, ServiceLimit>(),
  };

  constructor(
    public serviceOperation: ServiceOperation,
    initialData?: ServiceOperationData,
  ) {
    if (isNil(serviceOperation)) {
      console.error('AllDataServiceOperation: Cannot initialize class with missing ServiceOperation', {
        serviceOperation,
        initialData,
      });
      throw new Error('AllDataServiceOperation: Cannot initialize class with missing ServiceOperation');
    }

    this.parse(serviceOperation, initialData);
  }

  private parse(serviceOperation: ServiceOperation, data?: ServiceOperationData) {
    for (const command of serviceOperation.getCommands()) {
      switch (command.getCommandName()) {
        case VoiceResponseCommandName.flagServiceRequest: {
          break;
        }
        case VoiceResponseCommandName.enforceServiceLimit: {
          const enforceServiceLimitCommand = command as EnforceServiceLimit;
          const serviceLimitId = enforceServiceLimitCommand.getServiceLimitId();
          const serviceLimit = data?.serviceLimitMap?.get(serviceLimitId);
          if (isNil(serviceLimit)) {
            console.error('AllDataServiceOperation: Cannot initialize class with missing service limit', {
              serviceOperation,
              data,
              serviceLimitAction: enforceServiceLimitCommand,
              serviceLimit,
            });
            throw new Error('AllDataServiceOperation: Cannot initialize class with missing service limit');
          }
          this.data.serviceLimitMap?.set(serviceLimitId, serviceLimit);
          break;
        }
      }
    }
  }
}
