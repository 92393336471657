import { GenericDisplayableSchema } from '../../../generic/displayable/generic-displayable-schema';
import { SchemaField } from '../../../generic/serialization/schema-field';
import { SSMLAudio } from '../ssml-audio/ssml-audio';

/**
 * Schema we use to track audio files in database.
 */
export class VRCAudioMetadataSchema extends GenericDisplayableSchema {
  // Default Audio to use
  public static readonly audio = 'audio';

  // Default Audio to use
  public static readonly audioNoSpeechRecognition = 'audioNoSpeechRecognition';

  public static readonly wasProcessedByServer = 'wasProcessedByServer';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'vrcAudioMetadata';
  };

  static Defaults = class {
    public static readonly displayName = 'missing-displayName';

    public static readonly description = 'missing-description';

    public static readonly audio = new Map<string, SSMLAudio>();

    public static readonly audioNoSpeechRecognition = new Map<string, SSMLAudio>();

    public static readonly wasProcessedByServer = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[VRCAudioMetadataSchema.audio] = SchemaField.mapOfObj(SSMLAudio, VRCAudioMetadataSchema.Defaults.audio);
    parameters[VRCAudioMetadataSchema.audioNoSpeechRecognition] = SchemaField.mapOfObj(
      SSMLAudio,
      VRCAudioMetadataSchema.Defaults.audioNoSpeechRecognition,
    );
    parameters[VRCAudioMetadataSchema.wasProcessedByServer] = SchemaField.boolean(
      VRCAudioMetadataSchema.Defaults.wasProcessedByServer,
    );
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${VRCAudioMetadataSchema.Constants.collection}`;
  }
}
