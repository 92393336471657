import { cloneDeep } from 'lodash';

import { SerializableObject } from '../../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../../generic/serialization/serializable-object-schema';

import { CallListExtensionConfigConstructor } from './call-list-extension-config-constructor';
import { CallListExtensionConfigSchema } from './call-list-extension-config-schema';

export class CallListExtensionConfig extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected extension!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CallListExtensionConfigConstructor) {
    super(parameters);
  }
  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is protected, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CallListExtensionConfig {
    return new CallListExtensionConfig(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(CallListExtensionConfig.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CallListExtensionConfigSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getExtension() {
    return cloneDeep(this.extension);
  }
}
