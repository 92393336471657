import type { AnySchema } from 'joi';
import Joi from 'joi';

import { SerializableObjectSchema } from '../../../../generic/serialization/serializable-object-schema';
import { AutomationActionSendEmailSchema } from '../../type/automation-action-send-email/automation-action-send-email-schema';

export class AnyAutomationActionSchema extends SerializableObjectSchema {
  private static readonly SCHEMA_ID = 'anyAutomationAction';

  private static readonly computedSchema = Joi.alternatives()
    .try(new AutomationActionSendEmailSchema().getJoiSchema())
    .id(this.SCHEMA_ID);

  public static readonly type = 'type';
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: AnySchema } | AnySchema {
    return AnyAutomationActionSchema.computedSchema;
  }
}
