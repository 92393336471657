import { cloneDeep, isNil } from 'lodash';
import moment from 'moment-timezone';

import { getRRuleDtstart, rruleHasDtstart } from '../../../../../../helper/rrule/fields';
import { getOccuranceAfter } from '../../../../../../helper/rrule/recurrences';
import { validateAndGetRRuleFromStr } from '../../../../../../helper/rrule/validation';
import { SerializableObjectSchema } from '../../../../../generic/serialization/serializable-object-schema';
import { ServiceLimitServiceUsage } from '../../../service-limit-service-usage/service-limit-service-usage';
import { ServiceLimitEnforcementStrategy } from '../../generic/service-limit-enforcement-strategy/service-limit-enforcement-strategy';
import { ServiceLimitEnforcementStrategySchema } from '../../generic/service-limit-enforcement-strategy/service-limit-enforcement-strategy-schema';
import { ServiceLimitEnforcementStrategyName } from '../../generic/service-limit-enforcement-strategy-name';

import { ServiceLimitEnforcementStrategyRRuleConstructor } from './service-limit-enforcement-strategy-rrule-constructor';
import { ServiceLimitEnforcementStrategyRRuleSchema } from './service-limit-enforcement-strategy-rrule-schema';

export class ServiceLimitEnforcementStrategyRRule extends ServiceLimitEnforcementStrategy {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected windowResetRRule!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ServiceLimitEnforcementStrategyRRuleConstructor) {
    (parameters as any)[ServiceLimitEnforcementStrategySchema.type] = ServiceLimitEnforcementStrategyName.rrule;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(
    validationResult: import('joi').ValidationResult,
  ): ServiceLimitEnforcementStrategyRRule {
    return new ServiceLimitEnforcementStrategyRRule(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ServiceLimitEnforcementStrategyRRule.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ServiceLimitEnforcementStrategyRRuleSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getWindowResetRRule(): string {
    return cloneDeep(this.windowResetRRule);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Sanity Check
  /////////////////////////////////////////////////////////////////////////////

  public sanityCheck() {
    super.sanityCheck();
    validateAndGetRRuleFromStr(this.windowResetRRule, true);
    if (!rruleHasDtstart(this.windowResetRRule)) {
      console.error(this.windowResetRRule);
      throw new Error('ServiceLimitEnforcementStrategyRRule.sanityCheck: RRule does not have a DTSTART.');
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Does Permit Service
  /////////////////////////////////////////////////////////////////////////////

  public doesPermitService(usage: ServiceLimitServiceUsage): boolean {
    const genericValue = this.doesPermitServiceByGenericProps(usage);
    if (!isNil(genericValue)) {
      return genericValue;
    }

    const nextUsageWindowReset = this.getNextUsageWindowReset(usage.getWindowStartTime());
    if (isNil(nextUsageWindowReset)) {
      console.error(
        'doesPermitService: Permitting service because we encountered an error in determining the next usage window reset time.',
      );
      return true;
    }
    if (nextUsageWindowReset.isAfter(moment())) {
      return usage.getServiceDeliveredNum() < this.threshold.getServiceDeliveredNum();
    }
    return this.threshold.getServiceDeliveredNum() > 0;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Reset
  /////////////////////////////////////////////////////////////////////////////

  public onBeforeLogSessionComplete(usage: ServiceLimitServiceUsage): void {
    // Handle common cases
    super.onBeforeLogSessionComplete(usage);

    const nextUsageWindowReset = this.getNextUsageWindowReset(usage.getWindowStartTime());
    if (isNil(nextUsageWindowReset)) {
      console.error(
        'resetServiceUsageWindowIfNecessary: Doing nothing because there is no next usage window reset time.',
      );
      return;
    }

    if (nextUsageWindowReset.isAfter(moment())) {
      /**
       * Do nothing because the usage window does not need to be reset.
       */
      return;
    }
    usage.reset();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Next Usage Window Reset Time
  /////////////////////////////////////////////////////////////////////////////

  private getNextUsageWindowReset(afterTimestamp: moment.Moment | undefined): moment.Moment | undefined {
    // Do nothing if RRule is invalid
    const rrule = validateAndGetRRuleFromStr(this.windowResetRRule, false);
    if (isNil(rrule)) {
      console.error('getNextUsageWindowReset: Doing nothing because RRule is invalid.');
      return undefined;
    }

    const timestamp = afterTimestamp ?? getRRuleDtstart(this.windowResetRRule);
    if (isNil(timestamp)) {
      console.error(
        'getNextUsageWindowReset: Error. Doing nothing because RRule does not define a DTStart and no window start is specified',
        {
          rruleStr: this.windowResetRRule,
        },
      );
      return undefined;
    }
    const nextResetTimestamp = getOccuranceAfter(timestamp, rrule, false);
    return nextResetTimestamp;
  }
}
