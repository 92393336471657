import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { firstValueFrom } from 'rxjs';

import { MessageTemplate, MessageTemplateSchema } from '@pwp-common';

import { UpdateData } from '../../../common/firestore/update-data/update-data';
import { getSetOptionsFromMode } from '../../generic/batch/set-options-for-mode';
import { DbDocumentService } from '../../generic/db-document-service';
import { AuthService } from '../../user/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class MessageTemplateService extends DbDocumentService<MessageTemplate> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(db: AngularFirestore, authService: AuthService) {
    super(db, authService, MessageTemplate);
  }

  private async getUpdateData(
    userId: string,
    shouldSubscribe: boolean,
  ): Promise<UpdateData<typeof MessageTemplateSchema>> {
    const updateValue: UpdateData<typeof MessageTemplateSchema> = {
      [MessageTemplateSchema.subscribedUserIds]: shouldSubscribe
        ? firebase.firestore.FieldValue.arrayUnion(userId)
        : firebase.firestore.FieldValue.arrayRemove(userId),
    };

    return firstValueFrom(this.addGenericFields(updateValue, 'update'));
  }

  public async updateUserSubscriptions(subscriptions: Record<string, boolean>): Promise<void> {
    const userId = await firstValueFrom(this.getInjectedAuthService().getUserId());
    const batch = firebase.firestore().batch();

    const batchUpdates = Object.entries(subscriptions).map(async ([messageTemplateId, shouldSubscribe]) => {
      const doc = await firstValueFrom(this.docRef(messageTemplateId));
      const updateData = await this.getUpdateData(userId, shouldSubscribe);

      batch.set(doc.ref, updateData, getSetOptionsFromMode('update'));
    });

    await Promise.all(batchUpdates);

    await batch.commit();
  }
}
