import Joi from 'joi';

import { SchemaField } from '../../../../../../generic/serialization/schema-field';
import { CommunicationWorkflowTargetTimeoutSchema } from '../../generic/communication-workflow-target-timeout/communication-workflow-target-timeout-schema';

export class CommunicationWorkflowTargetTimeoutFixedSchema extends CommunicationWorkflowTargetTimeoutSchema {
  public static readonly timeoutMS = 'timeoutMS';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;

    public static readonly timeoutMS = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationWorkflowTargetTimeoutFixedSchema.timeoutMS] = (
      SchemaField.number(
        CommunicationWorkflowTargetTimeoutFixedSchema.Defaults.timeoutMS,
      ).required() as Joi.NumberSchema
    ).min(1000);
    /* eslint-enable */

    return parameters;
  }
}
