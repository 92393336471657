import { cloneDeep } from 'lodash';

import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { CommunicationsRequestName } from '../../../communications-request-name';
import { CommunicationsResponse } from '../../generic/communications-response/communications-response';
import { CommunicationsResponseSchema } from '../../generic/communications-response/communications-response-schema';

import { CommunicationsResponseCreateOutboundCallError } from './communications-response-create-outbound-call-error';
import { CommunicationsResponseCreateOutboundCallSessionConstructor } from './communications-response-create-outbound-call-session-constructor';
import { CommunicationsResponseCreateOutboundCallSessionSchema } from './communications-response-create-outbound-call-session-schema';

export class CommunicationsResponseCreateOutboundCallSession extends CommunicationsResponse<CommunicationsResponseCreateOutboundCallError> {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected outboundCallSessionId!: string;

  protected outboundCallSessionEventId!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationsResponseCreateOutboundCallSessionConstructor) {
    (parameters as any)[CommunicationsResponseSchema.type] = CommunicationsRequestName.createOutboundCallSession;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(
    validationResult: import('joi').ValidationResult,
  ): CommunicationsResponseCreateOutboundCallSession {
    return new CommunicationsResponseCreateOutboundCallSession(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationsResponseCreateOutboundCallSession.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationsResponseCreateOutboundCallSessionSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getOutboundCallSessionId(): string {
    return cloneDeep(this.outboundCallSessionId);
  }

  public getOutboundCallSessionEventId(): string {
    return cloneDeep(this.outboundCallSessionEventId);
  }
}
