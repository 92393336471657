// Email Template Config
export { ReleaseVersion } from './release-version';
export { ReleaseVersionSchema } from './release-version-schema';
export { ReleaseVersionConstructor } from './release-version-constructor';

////////////////////////////////////////////////////////////////////////////////////////////////
// Warning On Import
////////////////////////////////////////////////////////////////////////////////////////////////

console.warn('--------- pwp-common:objects/version/index.ts: Imported ---------');
