<div *transloco="let unscopedT">
  <ng-container *ngIf="rowData[col.field] as cell">
    <ng-container *ngIf="cell | isRowCell; else valueCell">
      <mat-icon *ngIf="cell.icon as icon">{{ icon }}</mat-icon>

      <app-status-badge *ngIf="cell.statusBadgeColor as color; else cellContent" [color]="color">
        {{ unscopedT(cell.translationScope + '.' + cell.translationKey, cell.translationObj) }}
      </app-status-badge>

      <ng-template #cellContent>
        <span class="wordWrap" [class]="cell.styleClass">{{
          unscopedT(cell.translationScope + '.' + cell.translationKey, cell.translationObj)
        }}</span>
      </ng-template>
    </ng-container>

    <ng-template #valueCell>
      <ng-container *ngIf="cell | isMoment; else rawCell">
        {{ cell | displayTimestamp }}
      </ng-container>
    </ng-template>

    <ng-template #rawCell>
      {{ cell }}
    </ng-template>
  </ng-container>
</div>
