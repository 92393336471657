import { isNil } from 'lodash';

import {
  AllDataServiceException,
  DBMatchRuleE164Phone,
  DBMatchRuleName,
  displayTime,
  EnforceServiceLimit,
  FlagServiceRequest,
  isNilOrDefault,
  OrgData,
  ServiceExceptionSchema,
  VoiceResponseCommandName,
} from '@pwp-common';

import { RowCell } from '../../../../../common/p-table/row-cell';
import { DataTableRow } from '../../../../generic/data-table/common/data-table-row';
import { StatusBadgeColor } from '../../../../shared/status-badge/status-badge-color';

const SERVICE_EXCEPTION_SCHEMA = new ServiceExceptionSchema();

export class ServiceExceptionsTableRow implements DataTableRow {
  //////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  //////////////////////////////////////////////////////////////////////////////////////////

  id: string;

  description: RowCell<string>;

  displayName: RowCell<string>;

  rules: RowCell<string>;

  ttl: RowCell<Date>;

  action: RowCell<string>;

  numServiceRequests: RowCell<number>;

  numServiceDeliveries: RowCell<number>;

  //////////////////////////////////////////////////////////////////////////////////////////
  // Constructor
  //////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    public allDataServiceException: AllDataServiceException,
    public orgData: OrgData,
  ) {
    this.id = allDataServiceException.serviceException.getId();

    this.setDescription(allDataServiceException);
    this.setDisplayName(allDataServiceException);
    this.setRules(allDataServiceException);
    this.setTTL(allDataServiceException, orgData);
    this.setAction(allDataServiceException);
    this.setNumServiceRequests(allDataServiceException);
    this.setNumServiceDeliveries(allDataServiceException);
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Description
  //////////////////////////////////////////////////////////////////////////////////////////

  private setDescription(allDataServiceException: AllDataServiceException) {
    const description = allDataServiceException?.serviceException.getDescription();
    if (isNilOrDefault(description, ServiceExceptionSchema.description, SERVICE_EXCEPTION_SCHEMA)) {
      this.description = new RowCell<string>({
        translationScope: 'service-exceptions-table',
        translationKey: 'cellDescriptionMissing',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    this.description = new RowCell<string>({
      translationScope: 'service-exceptions-table',
      translationKey: 'cellDescription',
      translationObj: {
        description,
      },
      sortValue: description,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Display Name
  //////////////////////////////////////////////////////////////////////////////////////////

  private setDisplayName(allDataServiceException: AllDataServiceException) {
    const displayName = allDataServiceException?.serviceException.getDisplayName();
    if (isNilOrDefault(displayName, ServiceExceptionSchema.displayName, SERVICE_EXCEPTION_SCHEMA)) {
      this.displayName = new RowCell<string>({
        translationScope: 'service-exceptions-table',
        translationKey: 'cellDisplayNameMissing',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    this.displayName = new RowCell<string>({
      translationScope: 'service-exceptions-table',
      translationKey: 'cellDisplayName',
      translationObj: {
        displayName,
      },
      sortValue: displayName,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Matchers
  //////////////////////////////////////////////////////////////////////////////////////////

  private setRules(allDataServiceException: AllDataServiceException) {
    const rules = allDataServiceException?.serviceException.getMatcher()?.getRules();
    if (isNil(rules) || rules.length === 0) {
      this.rules = new RowCell<string>({
        translationScope: 'service-exceptions-table',
        translationKey: 'cellRulesMissing',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    let ruleStrings = [];
    for (const rule of rules) {
      switch (rule.getType()) {
        case DBMatchRuleName.e164Phone: {
          ruleStrings = [...ruleStrings, ...(rule as DBMatchRuleE164Phone).getE164Phones()];
          break;
        }
        default: {
          console.error(rule);
          console.error('ServiceExceptionRow.setRules: Not Implemented Yet');
        }
      }
    }
    this.rules = new RowCell<string>({
      translationScope: 'service-exceptions-table',
      translationKey: 'cellRules',
      translationObj: {
        rulesString: ruleStrings.join(','),
      },
      sortValue: ruleStrings.join(','),
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // TTL
  //////////////////////////////////////////////////////////////////////////////////////////

  private setTTL(allDataServiceException: AllDataServiceException, orgData: OrgData) {
    const expireAt = allDataServiceException?.serviceException.getTTL()?.getExpireAt();
    if (isNil(expireAt)) {
      this.ttl = new RowCell<Date>({
        translationScope: 'service-exceptions-table',
        translationKey: 'cellTTLMissing',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    const timestamp = displayTime(expireAt, orgData.getTimezone(), { timeOnly: false });
    this.ttl = new RowCell<Date>({
      translationScope: 'service-exceptions-table',
      translationKey: 'cellTTL',
      translationObj: {
        timestamp,
      },
      sortValue: expireAt.toDate(),
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Action
  //////////////////////////////////////////////////////////////////////////////////////////

  private setAction(allDataServiceException: AllDataServiceException) {
    const onCreateCommands = allDataServiceException?.inboundCallSessionAction.parameters.onCreate;

    for (const command of onCreateCommands.serviceOperation.getCommands()) {
      switch (command.getCommandName()) {
        case VoiceResponseCommandName.dialCallList: {
          const translationKey = 'cellActionDialCallList';
          this.action = new RowCell<string>({
            translationScope: 'service-exceptions-table',
            translationKey,
            translationObj: {},
            sortValue: command.getCommandName(),
            statusBadgeColor: StatusBadgeColor.red,
          });
          return;
        }
        case VoiceResponseCommandName.enforceServiceLimit: {
          const translationKey = 'cellActionEnforceServiceLimit';
          this.action = new RowCell<string>({
            translationScope: 'service-exceptions-table',
            translationKey,
            translationObj: {},
            sortValue: command.getCommandName(),
            statusBadgeColor: StatusBadgeColor.blue,
          });
          return;
        }
        case VoiceResponseCommandName.flagServiceRequest: {
          const flagServiceRequestCommand = command as FlagServiceRequest;
          const translationKey = flagServiceRequestCommand.getDeny()
            ? 'cellActionFlagServiceRequestAndDeny'
            : 'cellActionFlagServiceRequestAndNoDeny';
          this.action = new RowCell<string>({
            translationScope: 'service-exceptions-table',
            translationKey,
            translationObj: {},
            sortValue: command.getCommandName(),
            statusBadgeColor: StatusBadgeColor.yellow,
          });
          return;
        }
        case VoiceResponseCommandName.playOrSay: {
          const translationKey = 'cellActionPlayOrSay';
          this.action = new RowCell<string>({
            translationScope: 'service-exceptions-table',
            translationKey,
            translationObj: {},
            sortValue: command.getCommandName(),
            statusBadgeColor: StatusBadgeColor.grey,
          });
          return;
        }
      }
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Num Service Requests
  //////////////////////////////////////////////////////////////////////////////////////////
  private setNumServiceRequests(allDataServiceException: AllDataServiceException) {
    const usage = this.getUsage(allDataServiceException);
    const numServiceRequests = usage?.getInboundCallServiceRequestedNum() + usage?.getOutboundCallServiceRequestedNum();
    this.numServiceRequests = new RowCell<number>({
      translationScope: 'service-exceptions-table',
      translationKey: 'cellNumServiceRequestsNotSet',
      translationObj: {},
      sortValue: undefined,
    });

    if (!isNil(usage)) {
      this.numServiceRequests = new RowCell<number>({
        translationScope: 'service-exceptions-table',
        translationKey: 'cellNumServiceRequests',
        translationObj: { num: numServiceRequests },
        sortValue: numServiceRequests,
      });
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Num Service Requests
  //////////////////////////////////////////////////////////////////////////////////////////
  private setNumServiceDeliveries(allDataServiceException: AllDataServiceException) {
    const usage = this.getUsage(allDataServiceException);
    const numServiceDeliveries =
      usage?.getInboundCallServiceDeliveredNum() + usage?.getOutboundCallServiceDeliveredNum();
    this.numServiceDeliveries = new RowCell<number>({
      translationScope: 'service-exceptions-table',
      translationKey: 'cellNumServiceDeliveriesNotSet',
      translationObj: {},
      sortValue: undefined,
    });

    if (!isNil(usage)) {
      this.numServiceDeliveries = new RowCell<number>({
        translationScope: 'service-exceptions-table',
        translationKey: 'cellNumServiceDeliveries',
        translationObj: { num: numServiceDeliveries },
        sortValue: numServiceDeliveries,
      });
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Private
  //////////////////////////////////////////////////////////////////////////////////////////

  private getUsage(allDataServiceException: AllDataServiceException) {
    const onCreateCommands = allDataServiceException?.inboundCallSessionAction.parameters.onCreate;

    for (const command of onCreateCommands.serviceOperation.getCommands()) {
      switch (command.getCommandName()) {
        case VoiceResponseCommandName.enforceServiceLimit: {
          const typedCommand = command as EnforceServiceLimit;
          const usage = allDataServiceException.inboundCallSessionAction.parameters.onCreate.data.serviceLimitMap
            .get(typedCommand.getServiceLimitId())
            .getUsage();

          return usage;
        }
      }
    }
    return undefined;
  }
}
