import { cloneDeep, isNil, values } from 'lodash';
import moment from 'moment-timezone';

import { DBDocObject } from '../../../generic/db-doc/db-doc-object';
import { DBDocSchema } from '../../../generic/db-doc/db-doc-schema';
import { getNumBillableMinutes } from '../../helper/get-num-billable-minutes/get-num-billable-minutes';
import { wasAnsweredByMachine } from '../../helper/was-answered-by-machine/was-answered-by-machine/was-answered-by-machine';
import { IVRResponse } from '../../ivr/ivr-response/ivr-response';

import { GenericDialedCallLogConstructor } from './any-dialed-call-log-constructor';
import { DialedCallStatus } from './dialed-call-status';
import { OperatorCallStatus, operatorCallStatusIsComplete } from './operator-call-status';

export abstract class GenericDialedCallLog extends DBDocObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Timestamps
   */
  initiatedTimestamp!: moment.Moment;

  ringingTimestamp!: moment.Moment;

  answeredTimestamp!: moment.Moment;

  completedTimestamp!: moment.Moment;

  /**
   * Operator Tracking
   */
  errorCode!: number | undefined;

  operatorDurationMS!: number | undefined;

  operatorPriceUnit!: string | undefined;

  operatorBasePricePM: string | undefined;

  operatorCountryCode: string | undefined;

  /**
   * IVR
   */
  responses!: IVRResponse[];

  /**
   * Other
   */
  callerIdObjId!: string | undefined;

  sipResponseCode!: number | undefined;

  operatorCallStatus: OperatorCallStatus | undefined;

  callSid: string | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: GenericDialedCallLogConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  static getSchema(): DBDocSchema {
    throw new Error('GenericDialedCallLog.getSchema: UserError: This function should never be called.');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Timestamps
   */
  public getInitiatedTimestamp(): moment.Moment | undefined {
    return cloneDeep(this.initiatedTimestamp);
  }

  public getRingingTimestamp(): moment.Moment | undefined {
    return cloneDeep(this.ringingTimestamp);
  }

  public getAnsweredTimestamp(): moment.Moment | undefined {
    return cloneDeep(this.answeredTimestamp);
  }

  public getCompletedTimestamp(): moment.Moment | undefined {
    return cloneDeep(this.completedTimestamp);
  }

  /**
   * Operator Tracking
   */

  /**
   * If the operator thew an error when trying to dial this call, the
   * associated error code will be available here.
   */
  public getErrorCode(): number | undefined {
    return cloneDeep(this.errorCode);
  }

  public getOperatorDurationMS(): number | undefined {
    return cloneDeep(this.operatorDurationMS);
  }

  public getOperatorPriceUnit(): string | undefined {
    return cloneDeep(this.operatorPriceUnit);
  }

  public getOperatorBasePricePM(): string | undefined {
    return cloneDeep(this.operatorBasePricePM);
  }

  public getOperatorCountryCode(): string | undefined {
    return cloneDeep(this.operatorCountryCode);
  }

  /**
   * IVR
   */
  public getResponses(): IVRResponse[] {
    return cloneDeep(this.responses);
  }

  /**
   * Other
   */
  public getCallerIdObjId(): string | undefined {
    return cloneDeep(this.callerIdObjId);
  }

  /**
   * This value is unset if either the call is in progress, or
   * if the loaded data is historic, and sipResponseCode was
   * never saved.
   */
  public getSIPResponseCode(): number | undefined {
    return cloneDeep(this.sipResponseCode);
  }

  /**
   * Determine the current OperatorCallStatus based on a
   * number of heuristics.
   */
  public getOperatorCallStatus(): OperatorCallStatus {
    if (operatorCallStatusIsComplete(this.operatorCallStatus as any)) {
      /**
       * For completed calls, if the operatorCallStatus is set, then it
       * is accurate.
       */
      return cloneDeep(this.operatorCallStatus!);
    }

    if (!isNil(this.completedTimestamp)) {
      /**
       * If OperatorCallStatus is missing and completedTimestamp is set
       * (historic data) then return 'completed', because that is the
       * most common result.
       */
      return OperatorCallStatus.completed;
    }

    /**
     * In-progress calls do not currently set operatorCallStatus in
     * realtime to avoid correctly handling a race condition. However,
     * they do set the timestamps, which are enough to determine
     * via stored timestamps the status as below.
     */
    if (!isNil(this.answeredTimestamp)) {
      return OperatorCallStatus.inProgress;
    }
    if (!isNil(this.ringingTimestamp)) {
      return OperatorCallStatus.ringing;
    }
    if (!isNil(this.initiatedTimestamp)) {
      return OperatorCallStatus.initiated;
    }

    /**
     * The OperatorCallStatus is unset during short windows
     * between when this object is created and
     * when the status callback finishes writing to DB. Return 'initiated'
     * in that case, since that is the most common result.
     */
    return OperatorCallStatus.initiated;
  }

  /**
   * This value is unset:
   * 1. If the loaded data is historic, and this data was never recorded.
   * 2. There is no associated call, e.g., no call was dialed.
   */
  public getCallSid(): string | undefined {
    return cloneDeep(this.callSid);
  }
  /////////////////////////////////////////////////////////////////////////////
  // Did Dial
  /////////////////////////////////////////////////////////////////////////////

  public abstract didDial(): boolean;

  /////////////////////////////////////////////////////////////////////////////
  // In Progress
  /////////////////////////////////////////////////////////////////////////////

  public isInProgress(): boolean {
    return this.getCompletedTimestamp() === undefined;
  }

  public wasAnsweredByHumanOrMachine(): boolean {
    return this.getAnsweredTimestamp() !== undefined;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Did disconnect before answer
  /////////////////////////////////////////////////////////////////////////////

  public didHangupBeforeAnswered(): boolean {
    if (!this.didDial()) {
      return false;
    }

    if (this.getCompletedTimestamp() !== undefined && this.getAnsweredTimestamp() === undefined) {
      return true;
    }

    return false;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Ring Duration
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Return the number of milliseconds that the call was ringing, typically this is
   * the time before it is answered by an initial voice prompt.
   */
  public getRingDurationMS(): number {
    if (!this.didDial()) {
      return 0;
    }

    if (isNil(this.ringingTimestamp)) {
      // Calls to some automated systems will be answered, but will have no ringing
      // timestamp
      return 0;
    }

    let ringStart = moment(0);
    let ringEnd = moment();
    if (this.isInProgress()) {
      // In progress
      if (!this.wasAnsweredByHumanOrMachine()) {
        // Case: Not answered yet
        ringStart = this.getRingingTimestamp() || ringStart;
        ringEnd = moment() || ringEnd;
        return ringEnd.diff(ringStart);
      }
      // Case: Answered
      ringStart = this.getRingingTimestamp() || ringStart;
      ringEnd = this.getAnsweredTimestamp() || ringEnd;
      return ringEnd.diff(ringStart);
    }
    // Completed
    if (this.didHangupBeforeAnswered()) {
      // Case: Hung up before answered
      ringStart = this.getRingingTimestamp() || ringStart;
      ringEnd = this.getCompletedTimestamp() || ringEnd;
      return ringEnd.diff(ringStart);
    }
    // Case: Answered
    ringStart = this.getRingingTimestamp() || ringStart;
    ringEnd = this.getAnsweredTimestamp() || ringEnd;
    return ringEnd.diff(ringStart);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Talk Duration
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Return the duration in milliseconds that this call was connected to a client.
   */
  public getTalkDurationMS(): number {
    if (!this.wasAnsweredByHumanOrMachine()) {
      return 0;
    }

    const endTime = this.getCompletedTimestamp() || moment();
    return endTime?.diff(this.getAnsweredTimestamp());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Call Status
  /////////////////////////////////////////////////////////////////////////////

  public getDialedCallStatus(): DialedCallStatus {
    const operatorCallStatus = this.getOperatorCallStatus();

    if (operatorCallStatus === OperatorCallStatus.completed) {
      return this.guessRefinedCompletedStatus();
    }

    const dialedCallStatuses: string[] = values(DialedCallStatus);
    if (dialedCallStatuses.includes(operatorCallStatus as string)) {
      return DialedCallStatus[operatorCallStatus as keyof typeof DialedCallStatus];
    }

    console.error('GenericDialedCallLog.getDialedCallStatus: This case has not been implemented', {
      operatorCallStatus,
    });
    return DialedCallStatus.completed;
  }

  /**
   * Given an
   * @private
   */
  private guessRefinedCompletedStatus():
    | DialedCallStatus.completedAnsweredByMachine
    | DialedCallStatus.completedAnsweredLive
    | DialedCallStatus.completedReceptionIssueAnsweredByMachine
    | DialedCallStatus.completed {
    // Determine if the call was machine answered
    const machineAnswered = wasAnsweredByMachine(this.responses);
    if (isNil(machineAnswered)) {
      // Just return completed, since we don't know.
      return DialedCallStatus.completed;
    }

    /**
     * If the phone never rang and was answered by a machine,
     * then flag it as a possible reception issue.
     */

    // Ringing timestamp is inconsistently set/missing for some automated systems, but answered appears to be there
    const answeredButNeverRung = isNil(this.ringingTimestamp) && !isNil(this.answeredTimestamp);
    const answeredImmediately =
      !isNil(this.answeredTimestamp) &&
      !isNil(this.ringingTimestamp) &&
      this.answeredTimestamp.isSame(this.ringingTimestamp);

    if (machineAnswered && (answeredButNeverRung || answeredImmediately)) {
      return DialedCallStatus.completedReceptionIssueAnsweredByMachine;
    }

    if (machineAnswered) {
      return DialedCallStatus.completedAnsweredByMachine;
    }
    return DialedCallStatus.completedAnsweredLive;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Billable Minutes
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Determine the number of billable minutes.
   */
  public getNumBillableMinutes(): number {
    return getNumBillableMinutes({ isInProgress: this.isInProgress(), operatorDurationMS: this.operatorDurationMS });
  }
}
