import { values } from 'lodash';

import { SchemaField } from '../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';

import { OutboundCallSessionParticipantType } from './outbound-call-session-participant-type';

export class OutboundCallSessionParticipantSchema extends SerializableObjectSchema {
  public static readonly type = 'type';

  public static readonly participantId = 'participantId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;

    public static readonly participantId = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[OutboundCallSessionParticipantSchema.type] = SchemaField.string(
      OutboundCallSessionParticipantSchema.Defaults.type,
    )
      .allow(...values(OutboundCallSessionParticipantType))
      .required();
    parameters[OutboundCallSessionParticipantSchema.participantId] = SchemaField.string(
      OutboundCallSessionParticipantSchema.Defaults.participantId,
    ).optional();
    /* eslint-enable */

    return parameters;
  }
}
