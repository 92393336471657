import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LetModule } from '@ngrx/component';
import { isNil } from 'lodash';
import moment from 'moment-timezone';
import { Table } from 'primeng/table';
import {
  BehaviorSubject,
  combineLatest,
  defer,
  filter,
  firstValueFrom,
  from,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { SurveyModule } from 'survey-angular-ui';

import { getFormSubmissionReportInfo } from '@pwp-common';

import { downloadCSV } from '../../../../common/files/download-csv/download-csv';
import { makePTableCols } from '../../../../common/p-table/make-p-table-cols';
import { DateRangeSelectOutput } from '../../../../components/core/date-range-select/common/date-range-select-output';
import { DateRangeSelectModule } from '../../../../components/core/date-range-select/date-range-select.module';
import { FormSubmissionService } from '../../../../components/form/services/form-submission/form-submission.service';
import { FormVersionService } from '../../../../components/form/services/form-version.service';
import { FormService } from '../../../../components/form/services/form.service';
import { DataTableModule } from '../../../../components/generic/data-table/data-table.module';
import { CallListService } from '../../../../services/call/call-list/call-list.service';
import { CallLogService } from '../../../../services/call/call-log/call-log.service';
import { ConversationLogService } from '../../../../services/conversation/conversation-log/conversation-log.service';
import { OrgDataService } from '../../../../services/orgs/org-data/org-data.service';
import { UserDataService } from '../../../../services/user/user-data/user-data.service';
import { StylesService } from '../../../../ui/styles/styles.service';

import { createFormSubmissionCSV } from './helpers/create-form-submission-csv/create-form-submission-csv';
import { createFormSubmissionRows } from './helpers/create-form-submission-row/create-form-submission-row';
import { FormSubmissionRow } from './helpers/create-form-submission-row/interfaces';

@Component({
  selector: 'app-form-submission-reports',
  standalone: true,
  imports: [
    CommonModule,
    DataTableModule,
    DateRangeSelectModule,
    FormsModule,
    LetModule,
    ReactiveFormsModule,
    SurveyModule,
  ],
  templateUrl: './form-submission-reports.component.html',
  styleUrls: ['./form-submission-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSubmissionReportsComponent {
  private readonly callListService = inject(CallListService);

  private readonly callLogService = inject(CallLogService);

  private readonly conversationLogService = inject(ConversationLogService);

  private readonly formSubmissionService = inject(FormSubmissionService);

  private readonly formVersionService = inject(FormVersionService);

  private readonly formService = inject(FormService);

  private readonly orgDataService = inject(OrgDataService);

  private readonly stylesService = inject(StylesService);

  private readonly userDataService = inject(UserDataService);

  private readonly allUserDataMap$ = this.userDataService.getDocs();

  private readonly orgData$ = this.orgDataService.getOrgData();

  private readonly selectedDateRange$ = defer(() =>
    this.dateRange.valueChanges.pipe(
      startWith(this.dateRange.value),
      filter((range) => !isNil(range)),
    ),
  );

  public readonly dateRange = new FormControl<DateRangeSelectOutput>({
    start: moment().subtract(1, 'day').startOf('day').toDate(),
    end: moment().endOf('day').toDate(),
  });

  public readonly cols = makePTableCols({
    columns: [
      'form',
      'submissionTime',
      'submittedBy',
      'contactChannel',
      'contactType',
      'contactTime',
      'contactAnsweredBy',
    ],
    translationScope: 'form-submission-reports',
  });

  public readonly loading$ = new BehaviorSubject(false);

  public readonly stylesLoaded$ = from(this.stylesService.lazyLoadGlobalStyle('survey-creator')).pipe(map(() => true));

  public readonly tableRows$ = this.selectedDateRange$.pipe(
    tap(() => this.loading$.next(true)),
    switchMap((range) => this.getRows(range)),
    tap(() => this.loading$.next(false)),
  );

  // @Todo: use CommunicationService.getCommunicationDisplay when data like `allUserDataMap`, `callListMap` and etc can be memoized
  // Using it right now will cause multiple requests to the same data
  private async getRows(range: DateRangeSelectOutput): Promise<FormSubmissionRow[]> {
    const [formSubmissions, allUserDataMap, callListMap, orgData] = await firstValueFrom(
      combineLatest([
        this.formSubmissionService.getFormSubmissionsIn(range),
        this.allUserDataMap$,
        this.callListService.getDocs(),
        this.orgData$,
      ]),
    );
    const { formVersionIds, conversationLogIds, callLogIds } = getFormSubmissionReportInfo(formSubmissions);

    const { formVersions, conversationLogs, callLogs } = await firstValueFrom(
      combineLatest({
        formVersions: this.formVersionService.getDocsWithIds(Array.from(formVersionIds)),
        conversationLogs: this.conversationLogService.getDocsWithIds(Array.from(conversationLogIds)),
        callLogs: this.callLogService.getDocsWithIds(Array.from(callLogIds)),
      }),
    );

    const forms = await firstValueFrom(
      this.formService.getDocsWithIds(Array.from(formVersions.values()).map((formVersion) => formVersion.getFormId())),
    );

    return createFormSubmissionRows({
      formSubmissions,
      callListMap,
      callLogs,
      conversationLogs,
      formVersions,
      forms,
      allUserDataMap,
      orgData,
    });
  }

  public downloadReport = (table: Table): void => {
    const selectedDateRange = this.dateRange.value;
    const start = moment(selectedDateRange.start).format('YYYY-MM-DD');
    const end = moment(selectedDateRange.end).format('YYYY-MM-DD');
    const filename = `form-submissions-${start}-${end}.csv`;

    const data = createFormSubmissionCSV({
      tableColumns: table.columns,
      data: table.value,
    });

    downloadCSV({ data, filename });
  };
}
