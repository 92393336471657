import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { EventRequestName } from '../../../event-request-name';
import { EventRequestResponse } from '../../generic/event-response/event-request-response';
import { EventRequestResponseSchema } from '../../generic/event-response/event-request-response-schema';

import { EventRequestTransactionResponseConstructor } from './event-request-transaction-response-constructor';
import { EventRequestTransactionResponseError } from './event-request-transaction-response-error';
import { EventRequestTransactionResponseSchema } from './event-request-transaction-response-schema';

export class EventRequestTransactionResponse extends EventRequestResponse<EventRequestTransactionResponseError> {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: EventRequestTransactionResponseConstructor) {
    (parameters as any)[EventRequestResponseSchema.type] = EventRequestName.eventRequestTransaction;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): EventRequestTransactionResponse {
    return new EventRequestTransactionResponse(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(EventRequestTransactionResponse.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new EventRequestTransactionResponseSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
}
