import { cloneDeep, isNil } from 'lodash';
import moment from 'moment-timezone';

import { GLOBAL_TIMEZONE } from './constants';

////////////////////////////////////////////////////////////////////////////////////////
// Constants
////////////////////////////////////////////////////////////////////////////////////////

const TIME_FORMAT = 'HH:mm';

////////////////////////////////////////////////////////////////////////////////////////
// Functions
////////////////////////////////////////////////////////////////////////////////////////

/**
 * Input is a pair of moments. Check that the second occurs after
 * the first, and return a pair of strings defining the time range.
 *
 * @param timeRange Possibly empty pair of times occuring today. The second
 * timestamp occurs after the first when they are converted to both occur
 * today.
 */
export const parseMomentToTimeRange = (timeRange: moment.Moment[]): string[] => {
  ////////////////////////////////////////////
  // Sanity Checking
  ////////////////////////////////////////////

  if (isNil(timeRange) || timeRange.length === 0) {
    console.debug('parseMomentToTimeRange: Empty range. Returning.');
    return [];
  }

  if (timeRange.length !== 2) {
    console.error('parseMomentToTimeRange' + `Bad input: timeRange = ${timeRange}`);
    throw new Error('Error in parseMomentToTimeRange');
  }

  ////////////////////////////////////////////
  // Parse
  ////////////////////////////////////////////

  const start = timeRange[0].format(TIME_FORMAT);
  const end = timeRange[1].format(TIME_FORMAT);

  ////////////////////////////////////////////
  // Check this is sensible time range
  ////////////////////////////////////////////

  const result = cloneDeep([start, end]);
  if (!isValidTimeRange(result)) {
    throw new Error('parseMomentToTimeRange: Bad time range!');
  }

  return result;
};

/**
 * Input is a pair of strings of the form
 * ['HH:mm', 'HH:mm']
 *
 * This defines a time range, today in the globally configured
 * timezone. Return that time range.
 */
export const parseTimeRangeToMoment = (timeRange: string[]): moment.Moment[] => {
  ////////////////////////////////////////////
  // Sanity Checking
  ////////////////////////////////////////////

  if (isNil(timeRange) || timeRange.length === 0) {
    return [];
  }

  if (timeRange.length !== 2) {
    console.error('parseTimeRangeToMoment' + `Bad input: timeRange = ${timeRange}`);
    throw new Error('Error in parseTimeRangeToMoment');
  }

  ////////////////////////////////////////////
  // Parse the time strings.
  ////////////////////////////////////////////

  // Use moment.utc simply as a way to parse string.
  const start = moment.utc(timeRange[0], TIME_FORMAT);
  const end = moment.utc(timeRange[1], TIME_FORMAT);

  ////////////////////////////////////////////
  // Transpose start/end to today
  ////////////////////////////////////////////

  const startTransposed = moment.tz(new Date(), GLOBAL_TIMEZONE);
  startTransposed.set('hour', start.hour());
  startTransposed.set('minute', start.minute());

  // Start with copy of startTransposed so milliseconds are correct.
  const endTransposed = cloneDeep(startTransposed);
  endTransposed.set('hour', end.hour());
  endTransposed.set('minute', end.minute());

  return [startTransposed, endTransposed];
};

/**
 * A time range is valid if it is empty, or start < end.
 * @param timeRange Array of strings defining a time range.
 */
export const isValidTimeRange = (timeRange: string[]): boolean => {
  const momentRange = parseTimeRangeToMoment(timeRange);

  // Empty range is valid
  if (isNil(timeRange) || momentRange.length === 0) {
    return true;
  }

  // start < end
  if (momentRange[0].valueOf() < momentRange[1].valueOf()) {
    return true;
  }

  return false;
};

/**
 * Transpose the given time range to today. For example, if the input is an
 * array specifying the blockout times, then we return the blockout times
 * for today. The time range is specified in global config.
 */

/**
 * Transpose the given time range to today. For example, if the input is an
 * array specifying the blockout times, then we return the blockout times
 * for today. The time range is specified in global config.

 * @param timeRange see parseTimeRangeToMoment for details.
 */
export const transposeTimeRangeToToday = (timeRange: string[]): moment.Moment[] => {
  if (!isValidTimeRange(timeRange)) {
    throw new Error('Error, invalid time range.');
  }

  const dailyRange = parseTimeRangeToMoment(timeRange);

  // Return if dailyRange is empty.
  if (dailyRange.length !== 2) {
    return [];
  }

  const startTime = moment.tz(new Date(), GLOBAL_TIMEZONE);
  startTime.set('hour', dailyRange[0].hour());
  startTime.set('minute', dailyRange[0].minute());

  const endTime = moment.tz(new Date(), GLOBAL_TIMEZONE);
  endTime.set('hour', dailyRange[1].hour());
  endTime.set('minute', dailyRange[1].minute());

  return [startTime, endTime];
};

/**
 * Determine if the given time point occurs between the
 * specified start and end (not inclusive on either side).
 *
 * @param range A time range.
 * @param time Check if this time occurs inside range
 */
export const timeRangeContains = (range: { start: moment.Moment; end: moment.Moment }, time: moment.Moment) => {
  const timeUnixSeconds = time.unix();
  const start = range.start.unix();
  const end = range.end.unix();

  if (timeUnixSeconds < start || timeUnixSeconds > end) {
    return false;
  }
  return true;
};
