import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { isNil } from 'lodash';

import { EnforceBlockedCaller, EnforceBlockedCallerSchema, VoiceResponseCommand } from '@pwp-common';

import { KVPair } from '../../../../common/objects/kvpair';
import { VRCEditor } from '../../../generic/abstract-classes/vrc-editor';

@Component({
  selector: 'app-vrc-editor-enforce-blocked-caller',
  templateUrl: './vrc-editor-enforce-blocked-caller.component.html',
  styleUrls: ['./vrc-editor-enforce-blocked-caller.component.css'],
})
export class VrcEditorEnforceBlockedCallerComponent extends VRCEditor<EnforceBlockedCaller> implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  commandsToExecuteOnEnforce: VoiceResponseCommand[];

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    super(_changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parameters = {} as any;
    parameters[EnforceBlockedCallerSchema.commands] =
      this.commandsToExecuteOnEnforce || EnforceBlockedCallerSchema.Defaults.commands;
    const value = new EnforceBlockedCaller(parameters);
    return new KVPair({ id: this.obj.id, value });
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: KVPair<EnforceBlockedCaller>) {
    // Init other state
    this.commandsToExecuteOnEnforce = obj.value!.getCommands();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObjScoper = (): boolean => this.isValidObj();

  isValidObj() {
    if (!super.isValidObj()) {
      return false;
    }

    if (isNil(this.commandsToExecuteOnEnforce) || this.commandsToExecuteOnEnforce.length === 0) {
      return false;
    }

    return true;
  }
}
