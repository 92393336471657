<ng-container *transloco="let t; read: 'repeat-schedule-dialog'">
  <form *ngIf="!loading" [formGroup]="form">
    <mat-form-field appearance="fill">
      <mat-label>{{ t('label') }}</mat-label>
      <input formControlName="endDate" matInput [matDatepicker]="picker" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <button cdkFocusInitial mat-button [disabled]="form.invalid" [mat-dialog-close]="getEndDate()">
      {{ t('okButton') }}
    </button>
  </form>
</ng-container>
