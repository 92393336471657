import { isNil } from 'lodash';
import moment from 'moment-timezone';

import { UnsentMessage } from '../../conversation-client/message-state/unsent-message';
import { getMessageParticipantDisplayId } from '../../participant/helper/get-message-participant-display-id';

import { DisplayableMessage } from './displayable-message';
import { CreateDisplayableMessageInput } from './interfaces';

export const getDisplayableUnsentMessage = async ({
  message,
  indexId,
}: CreateDisplayableMessageInput<UnsentMessage>): Promise<DisplayableMessage> => ({
  _id: message.id,
  indexId,
  content: message.body,
  senderId: await getMessageParticipantDisplayId(message),
  date: moment(message.requestTime).format('MMMM D, YYYY'),
  timestamp: moment(message.requestTime).format('h:mm A'),
  serverTimestamp: message.requestTime,
  saved: !isNil(message.index),
  failure: message.failure,
});
