import { SchemaField } from '../../../objects/generic/serialization/schema-field';
import { RequestSchema } from '../../generic/request/request-schema';

export class DeleteUserRequestSchema extends RequestSchema {
  public static readonly userId = 'userId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class extends RequestSchema.GenericDefaults {
    public static readonly userId = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[DeleteUserRequestSchema.userId] = SchemaField.email(DeleteUserRequestSchema.Defaults.userId);
    /* eslint-enable */

    return parameters;
  }
}
