<ng-container *transloco="let t; read: 'inbox-handled-by-button'">
  <p-toast position="bottom-center"></p-toast>

  <app-progress-spinner *ngIf="loading"></app-progress-spinner>
  <p-button
    *ngIf="!loading"
    styleClass="p-button-outlined"
    [disabled]="!canToggleHandledBySelf"
    label="{{ t(toggleButtonCell.translationKey, toggleButtonCell.translationObj) }}"
    [loading]="toggleHandleInProgress"
    (click)="toggleHandledBySelfClick()"
  >
  </p-button>
</ng-container>
