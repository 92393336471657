import type { ValidationResult } from 'joi';

import { CommunicationSessionId } from '../../core/communication-session-id/communication-session-id';
import { DBDocObject } from '../../generic/db-doc/db-doc-object';
import { FormBuilderVendor } from '../form-builder-vendor';

import { FormSubmissionConstructor } from './form-submission-constructor';
import { FormSubmissionSchema } from './form-submission-schema';

export class FormSubmission extends DBDocObject {
  public formVersionId!: string;

  public sessionId!: CommunicationSessionId;

  public vendor!: FormBuilderVendor;

  public value!: string;

  constructor(params: FormSubmissionConstructor) {
    super(params);
  }

  protected static _deserialize(validationResult: ValidationResult): FormSubmission {
    return new FormSubmission(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(FormSubmission.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): FormSubmissionSchema {
    return new FormSubmissionSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getFormVersionId(): string {
    return this.formVersionId;
  }

  public getSessionId(): CommunicationSessionId {
    return this.sessionId;
  }

  public getVendor(): FormBuilderVendor {
    return this.vendor;
  }

  public getValue(): string {
    return this.value;
  }
}
