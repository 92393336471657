import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { isNil } from 'lodash';
import { SharedModule } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { SelectButtonModule } from 'primeng/selectbutton';
import { BehaviorSubject, combineLatest, firstValueFrom, map, switchMap } from 'rxjs';

import { CallEntrypoint, TimeSelection } from '@pwp-common';

import { EditAvailableHoursByDowComponent } from '../../../../../../components/core/edit-available-hours-by-dow/edit-available-hours-by-dow.component';
import { ServiceOperationEditorModule } from '../../../../../../components/core/service-operation-editor/service-operation-editor.module';
import { AccordionWizardModule } from '../../../../../../components/generic/accordion-wizard/accordion-wizard.module';
import { AuditEntryPipe } from '../../../../../../components/generic/settings/common/audit-entry/audit-entry.pipe';
import { SettingsModule } from '../../../../../../components/generic/settings/settings.module';
import { CallEntrypointsService } from '../../../../../../services/call/call-entrypoints/call-entrypoints.service';
import { OrgDataService } from '../../../../../../services/orgs/org-data/org-data.service';

import { AvailableHoursMode } from './editor-output/available-hours-mode';
import { createCallEntrypointFromPhoneLineEditorOutput } from './editor-output/create-call-entrypoint-from-phone-line-editor-output/create-call-entrypoint-from-phone-line-editor-output';
import { createPhoneLineEditorOutputFromCallEntrypoint } from './editor-output/create-phone-line-editor-output/create-phone-line-editor-output-from-call-entrypoint';

@Component({
  selector: 'app-phone-line-editor',
  standalone: true,
  imports: [
    AccordionWizardModule,
    AuditEntryPipe,
    CommonModule,
    EditAvailableHoursByDowComponent,
    FormsModule,
    MessagesModule,
    ReactiveFormsModule,
    SelectButtonModule,
    SettingsModule,
    ServiceOperationEditorModule,
    SharedModule,
    TranslocoModule,
  ],
  templateUrl: './phone-line-editor.component.html',
  styleUrls: ['./phone-line-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneLineEditorComponent implements OnInit {
  private readonly callEntrypointService = inject(CallEntrypointsService);

  private readonly fb = inject(NonNullableFormBuilder);

  private readonly orgDataService = inject(OrgDataService);

  private readonly reset$ = new BehaviorSubject(null);

  @Input() public callEntrypoint: CallEntrypoint;

  public readonly form = this.fb.group({
    availableHoursMode: this.fb.control(AvailableHoursMode.alwaysAvailable),
    closedHoursConfig: this.fb.control({ commands: [] }),
  });

  public readonly availableHours$ = new BehaviorSubject<TimeSelection | null>(null);

  public readonly editHoursInput$ = this.reset$.pipe(
    switchMap(() => combineLatest([this.orgDataService.getOrgData(), this.availableHours$])),
    map(([orgData, availableHours]) => ({
      timeSelection: availableHours,
      timezone: orgData.getTimezone(),
    })),
  );

  @Output() public readonly saved = new EventEmitter<void>();

  private setCallEntrypointValues(): void {
    const { closedHoursCommands, availableHoursMode, availableHours } = createPhoneLineEditorOutputFromCallEntrypoint(
      this.callEntrypoint,
    );

    this.form.setValue({
      availableHoursMode,
      closedHoursConfig: { commands: closedHoursCommands },
    });
    this.availableHours$.next(availableHours);
  }

  public ngOnInit(): void {
    this.setCallEntrypointValues();
  }

  public isValid = () => {
    if (this.form.invalid) {
      return false;
    }

    const { availableHoursMode, closedHoursConfig } = this.form.value;

    if (isNil(availableHoursMode === AvailableHoursMode.alwaysAvailable)) {
      return true;
    }

    return closedHoursConfig.commands.length > 0;
  };

  public save = async (): Promise<void> => {
    const { closedHoursConfig, availableHoursMode } = this.form.value;

    const updatedCallEntrypoint = createCallEntrypointFromPhoneLineEditorOutput({
      availableHours: this.availableHours$.value,
      callEntrypoint: this.callEntrypoint,
      closedHoursCommands: closedHoursConfig.commands,
      availableHoursMode,
    });

    await firstValueFrom(
      this.callEntrypointService.upload({
        obj: updatedCallEntrypoint,
        mode: 'update',
        generateId: false,
      }),
    );
  };

  public resetForm = (): void => {
    this.reset$.next(null);
    this.form.reset({}, { emitEvent: false });
    this.setCallEntrypointValues();
  };

  public refreshData = async (): Promise<void> => this.saved.emit();
}
