import { EntityStats } from '../entity-stats/entity-stats';

import { EntityStatsDisplay } from './entity-stats-display';
import { EntityStatsDisplayWindow } from './entity-stats-display-window/enum';

export class CallStatsOverTime extends EntityStatsDisplay {
  // Raw Counts
  public iNum = new Map<string, number>();

  public iNumServiceRequested = new Map<string, number>();

  public iNumServiceNotRequested = new Map<string, number>();

  public iNumUnexpectedlyMissed = new Map<string, number>();

  public iNumAnswered = new Map<string, number>();

  public iNumNoCapacity = new Map<string, number>();

  public iNumEnforceBlockedCaller = new Map<string, number>();

  public iNumUsersAnswered = new Map<string, number>();

  public iNumBillableMinutes = new Map<string, number>();

  public avgHoldTimeSeconds = new Map<string, number>();

  public avgTalkTimeMinutes = new Map<string, number>();

  /**
   * Determine how basic stats (eg, answered percentage etc) vary over time.
   * @param entityStats
   * @param callLists
   * @param window
   */
  constructor(entityStats: EntityStats, window: EntityStatsDisplayWindow) {
    super(entityStats, window);

    // Set Number of Calls
    this.setStats(entityStats);
  }

  private setStats(entityStats: EntityStats) {
    for (const [month, entityStatsChunk] of entityStats.getByMonth()) {
      if (!this.isMonthContainedInWindow(month)) {
        continue;
      }
      // Set raw values
      const callStats = entityStatsChunk.getCalls();
      this.iNum.set(month, callStats.getINum());
      this.iNumServiceRequested.set(month, callStats.getINumServiceRequested());
      this.iNumServiceNotRequested.set(month, callStats.getINumServiceNotRequested());
      this.iNumUnexpectedlyMissed.set(month, callStats.getINumUnexpectedlyMissed());
      this.iNumAnswered.set(month, callStats.getINumAnswered());
      this.iNumNoCapacity.set(month, callStats.getINumNoCapacity());
      this.iNumEnforceBlockedCaller.set(month, callStats.getINumEnforceBlockedCaller());
      this.iNumUsersAnswered.set(month, callStats.getINumUsersAnswered());
      this.iNumBillableMinutes.set(month, callStats.getINumBillableMinutes());

      this.avgHoldTimeSeconds.set(month, Math.round(callStats.avgHoldTimeMS() / 1000.0));
      this.avgTalkTimeMinutes.set(month, Math.round(callStats.avgTalkTimeMS() / (60 * 1000.0)));
    }
  }
}
