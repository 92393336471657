import { isEmpty, isNil } from 'lodash';

import { AllDataEvent, displayTimeRangeShort, OrgData } from '@pwp-common';

import { RowCell } from '../../../../common/p-table/row-cell';
import { DataTableRow } from '../../../generic/data-table/common/data-table-row';
import { StatusBadgeColor } from '../../../shared/status-badge/status-badge-color';

export class EventRequestTableRow implements DataTableRow {
  //////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  //////////////////////////////////////////////////////////////////////////////////////////

  public id: string;

  public allDataEvent: AllDataEvent;

  public assignedPrimaryUserId: string;

  public assignedBackupUserId: string;

  public eventType: string;

  public details: RowCell<Date>;

  public type: RowCell<string>;

  public currentPrimary: RowCell<string>;

  public currentBackup: RowCell<string>;
  //////////////////////////////////////////////////////////////////////////////////////////
  // Constructor
  //////////////////////////////////////////////////////////////////////////////////////////

  constructor(params: { eventData: AllDataEvent; orgData: OrgData }) {
    const { eventData, orgData } = params;
    this.allDataEvent = eventData;
    this.id = eventData.getEventId();
    this.setDetails(eventData, orgData);
    this.setEventType(eventData, orgData);
    this.setUserDisplayNameWithEmail(eventData);
    this.setCurrentBackup(eventData);
  }

  private setDetails(allDataEvent: AllDataEvent, orgData: OrgData) {
    const eventData = allDataEvent.getEventData();

    if (isNil(eventData)) {
      this.details = new RowCell<Date>({
        translationScope: 'event-request-table-row',
        translationKey: 'cellDataMissing',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    const sortValue = eventData?.getStart()?.toDate();
    this.details = new RowCell<Date>({
      translationScope: 'event-request-table-row',
      translationKey: 'cellDetails',
      translationObj: {
        details: displayTimeRangeShort(eventData.getStart(), eventData.getEnd(), orgData.getTimezone()),
      },
      sortValue,
    });
  }

  private setEventType(allDataEvent: AllDataEvent, orgData: OrgData) {
    const eventData = allDataEvent.getEventData();
    const eventType = eventData?.getType();
    this.eventType = eventType;

    if (isEmpty(eventType)) {
      this.type = new RowCell<string>({
        translationScope: 'event-request-table-row',
        translationKey: 'cellDataMissing',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    const eventTypeDisplayName = orgData.getEventTypeWithInternalName(eventType).getDisplayName();
    this.type = new RowCell<string>({
      translationScope: 'event-request-table-row',
      translationKey: 'cellType',
      translationObj: {
        eventTypeDisplayName,
      },
      sortValue: eventTypeDisplayName,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // User Display Name With Email
  //////////////////////////////////////////////////////////////////////////////////////////
  private setUserDisplayNameWithEmail(allDataEvent: AllDataEvent) {
    const allDataUserMap = allDataEvent.getAllDataUserMap();
    const eventData = allDataEvent.getEventData();
    this.assignedPrimaryUserId = eventData.getAssignedUserId();
    const currentPrimaryUser = allDataUserMap?.get(this.assignedPrimaryUserId);

    if (isNil(this.assignedPrimaryUserId)) {
      this.currentPrimary = new RowCell<string>({
        translationScope: 'event-request-table-row',
        translationKey: 'cellDataMissing',
        translationObj: {},
        sortValue: undefined,
        styleClass: undefined,
      });
      return;
    }
    const currentPrimary = currentPrimaryUser?.userData?.getDisplayNameWithEmail();
    if (isNil(currentPrimary)) {
      this.currentPrimary = new RowCell<string>({
        translationScope: 'event-request-table-row',
        translationKey: 'cellDataMissing',
        translationObj: {},
        sortValue: undefined,
        statusBadgeColor: StatusBadgeColor.yellow,
      });
      return;
    }
    this.currentPrimary = new RowCell<string>({
      translationScope: 'event-request-table-row',
      translationKey: 'cellUserCurrentPrimary',
      translationObj: {
        currentPrimary,
      },
      sortValue: currentPrimary,
    });
  }

  private setCurrentBackup(allDataEvent: AllDataEvent) {
    const allDataUserMap = allDataEvent.getAllDataUserMap();
    const eventData = allDataEvent.getEventData();
    this.assignedBackupUserId = eventData.getAssignedBackupUserId();
    const currentBackupUser = allDataUserMap.get(this.assignedBackupUserId);
    const currentBackup = currentBackupUser?.userData?.getDisplayNameWithEmail();

    if (isNil(currentBackup)) {
      this.currentBackup = new RowCell<string>({
        translationScope: 'event-request-table-row',
        translationKey: 'cellDataMissing',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    this.currentBackup = new RowCell<string>({
      translationScope: 'event-request-table-row',
      translationKey: 'cellUserCurrentBackup',
      translationObj: {
        currentBackup,
      },
      sortValue: '',
    });
  }
}
