import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';

import { DBDocSchema } from '../../../../../../../generic/db-doc/db-doc-schema';
import { CommunicationSessionEventLog } from '../../../../generic/communication-session-event-log/communication-session-event-log';
import { CommunicationSessionEventLogSchema } from '../../../../generic/communication-session-event-log/communication-session-event-log-schema';
import { CommunicationSessionEventLogName } from '../../../../generic/communication-session-event-log-name';
import { CommunicationSessionEventLogConferenceType } from '../communication-session-event-log-conference-type';

import { CommunicationSessionEventLogConferenceConstructor } from './communication-session-event-log-conference-constructor';

export abstract class CommunicationSessionEventLogConference extends CommunicationSessionEventLog {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected conferenceEventType!: CommunicationSessionEventLogConferenceType;

  protected conferenceSid!: string;

  protected eventTimestamp!: moment.Moment;

  protected friendlyName!: string;

  protected sequenceNumber!: number;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  protected constructor(parameters: CommunicationSessionEventLogConferenceConstructor) {
    (parameters as any)[CommunicationSessionEventLogSchema.type] = CommunicationSessionEventLogName.conference;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    throw new Error('Error: Cannot get schema for abstract CommunicationSessionEventLogConference');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getConferenceEventType() {
    return cloneDeep(this.conferenceEventType);
  }

  public getConferenceSid() {
    return cloneDeep(this.conferenceSid);
  }

  public getEventTimestamp() {
    return cloneDeep(this.eventTimestamp);
  }

  /**
   * The conference friendly name, provided to
   * Twilio
   */
  public getFriendlyName() {
    return cloneDeep(this.friendlyName);
  }

  public getSequenceNumber() {
    return cloneDeep(this.sequenceNumber);
  }
}
