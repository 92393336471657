import { isEqual, isNil } from 'lodash';

import { Displayable } from '@pwp-common';

export type KeyType = string | number | boolean;

export interface KVPairConstructor<T> {
  id?: KeyType;
  value?: T;
}
export class KVPair<T> {
  public id?: KeyType;

  public value?: T;

  constructor(parameters: KVPairConstructor<T>) {
    if (parameters === undefined) {
      return;
    }
    this.id = parameters.id;
    this.value = parameters.value;
  }

  public getId() {
    return this.id;
  }
}

/**
 * Return true if the given KVPair has all properties
 * either missing or set to undefined.
 */
export const isEmptyKVPair = <T>(kvPair: KVPair<T>): boolean =>
  isNil(kvPair) || (kvPair?.id === undefined && kvPair?.value === undefined);

export const makeKVPairs = <T>(array: T[]): KVPair<T>[] =>
  array.map((value: T, index: number) => new KVPair({ id: index, value }));

/**
 * Return true if the given KVPair has both the key and value
 * set. Else return false.
 */
export const isComplete = <T>(kvPair: KVPair<T>): boolean => !isNil(kvPair?.id) && !isNil(kvPair?.value);

export const kvPairEqual = (o1: any, o2: any): boolean => {
  if (o1?.id !== o2?.id) {
    return false;
  }
  return isEqual(o1?.value?.getId(), o2?.value?.getId());
};

export const getCompleteKVPair = <T extends Displayable>(partialKVPair: KVPair<T>, items?: Array<T>): KVPair<T> => {
  if (isEmptyKVPair(partialKVPair)) {
    throw new Error('getCompleteKVPair: User error. partialKVPair is empty.');
  }
  if (isComplete(partialKVPair)) {
    return partialKVPair;
  }

  if (isNil(items)) {
    throw new Error('getCompleteKVPair: User error. Cannot get complete KVPair if given no data.');
  }

  const { id } = partialKVPair;
  const { value } = partialKVPair;

  // First try to see if there is an object with this index
  if (!isNil(id) && !isNil(items)) {
    if (typeof id === 'number') {
      return new KVPair({ id, value: items[id as number] });
    }
  }

  if (!isNil(items)) {
    for (let i = 0; i < items.length; i++) {
      if (!isNil(value) && isEqual(items[i]?.getId(), value.getId())) {
        return new KVPair({ id: i, value });
      }

      if (!isNil(id) && isEqual(items[i].getId(), id)) {
        return new KVPair({ id: i, value: items[i] });
      }
    }
  }
  console.error({ partialKVPair, items });
  throw new Error('getCompleteKVPair: User error. Cannot complete the given KV pair.');
};

export const getCompleteKVPairs = <T extends Displayable>(items?: Array<T>): KVPair<T>[] => {
  if (isNil(items)) {
    return [];
  }

  return items.map((value, index) => new KVPair({ id: index, value }));
};
