import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, inject, ViewChild } from '@angular/core';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LetModule } from '@ngrx/component';
import { minutesToSeconds } from 'date-fns';
import { isNil } from 'lodash';
import moment from 'moment-timezone';
import { MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import {
  combineLatest,
  debounceTime,
  defer,
  filter,
  firstValueFrom,
  map,
  share,
  switchMap,
  withLatestFrom,
} from 'rxjs';

import {
  AllDataEvent,
  AllDataEventDisplay,
  DBDocSchema,
  EventData,
  EventDataSchema,
  EventOverlapData,
  OrgData,
} from '@pwp-common';

import { eventTitle } from '../../../../common/event/calendar-event/make-calendar-event/make-calendar-event';
import { controlValues } from '../../../../common/form/control-values';
import { DatetimeValidator } from '../../../../common/validators/datetime-validator/datetime-validator';
import { isBeforeControl } from '../../../../common/validators/datetime-validator/is-before-control/is-before-control';
import { EventsCalendarComponent } from '../../../../components/event/events-calendar/events-calendar.component';
import { FormFieldHelpComponent } from '../../../../components/form/form-field-help/form-field-help.component';
import { AccordionWizardModule } from '../../../../components/generic/accordion-wizard/accordion-wizard.module';
import { SettingsModule } from '../../../../components/generic/settings/settings.module';
import { BASE_LAYOUT_TOAST_KEY } from '../../../../layouts/base-layout/constants/base-layout-toast-key';
import { EventsService } from '../../../../services/event/events/events.service';
import { OrgDataService } from '../../../../services/orgs/org-data/org-data.service';
import { themeColor } from '../../../../ui/styles/helpers/theme-color/theme-color';

import { RecurrenceMode } from './recurrence-mode';

@UntilDestroy()
@Component({
  selector: 'app-upsert-event',
  standalone: true,
  imports: [
    AccordionWizardModule,
    CommonModule,
    DropdownModule,
    EventsCalendarComponent,
    FormFieldHelpComponent,
    FormsModule,
    InputTextModule,
    LetModule,
    MatIconModule,
    MessagesModule,
    ReactiveFormsModule,
    SettingsModule,
    TranslocoModule,
  ],
  templateUrl: './upsert-event.component.html',
  styleUrls: ['./upsert-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpsertEventComponent implements AfterViewInit {
  private readonly activatedRouteSnapshot = inject(ActivatedRoute).snapshot;

  private readonly eventDataService = inject(EventsService);

  private readonly fb = inject(NonNullableFormBuilder);

  private readonly messagesService = inject(MessageService);

  private readonly orgDataService = inject(OrgDataService);

  private readonly router = inject(Router);

  private readonly translocoService = inject(TranslocoService);

  private readonly queryParams = this.activatedRouteSnapshot.queryParams;

  private readonly orgData$ = this.orgDataService.getOrgData();

  private readonly eventData$ = defer(() =>
    combineLatest([this.orgData$, controlValues(this.formGroup)]).pipe(
      filter(() => this.formGroup.valid),
      debounceTime(500),
      map(
        ([orgData]) =>
          new EventData({
            type: this.eventType,
            start: moment.tz(this.formGroup.get(['date', 'start']).value, orgData.getTimezone()),
            end: moment.tz(this.formGroup.get(['date', 'end']).value, orgData.getTimezone()),
            eventConfigId: EventDataSchema.Defaults.eventConfigId,
            id: DBDocSchema.GenericDefaults.id,
          }),
      ),
    ),
  );

  public readonly eventType = this.activatedRouteSnapshot.params.eventType;

  public readonly recurrenceOptions = [{ icon: 'event', value: RecurrenceMode.once, translationKey: 'once' }];

  public readonly stepSize = minutesToSeconds(15);

  public readonly formGroup = this.fb.group({
    date: this.fb.group(
      {
        start: [this.queryParams.startDate, [Validators.required, DatetimeValidator.stepSizeSeconds(this.stepSize)]],
        end: [this.queryParams.endDate, [Validators.required, DatetimeValidator.stepSizeSeconds(this.stepSize)]],
      },
      { validators: [isBeforeControl('start', 'end')] },
    ),
    recurrenceMode: [null as RecurrenceMode | null, Validators.required],
  });

  public readonly overlap$ = this.eventData$.pipe(
    switchMap((eventData) => this.eventDataService.getEventOverlapData(eventData)),
    share(),
  );

  public readonly isValid$ = combineLatest([this.overlap$, controlValues(this.formGroup)]).pipe(
    map(([overlap]) => isNil(overlap) && this.formGroup.valid),
  );

  @ViewChild(EventsCalendarComponent) public readonly eventsCalendarComponent!: EventsCalendarComponent;

  private updateOverlapMessage(overlap: EventOverlapData | null): void {
    this.messagesService.clear();

    if (!isNil(overlap)) {
      this.messagesService.add({
        severity: 'warn',
        detail: this.translocoService.translate(`upsert-event.overlappingEvent.${overlap.type}Message`),
      });
    }
  }

  private updateEventPreview(eventData: EventData, overlap: EventOverlapData | null, orgData: OrgData): void {
    const eventPreviewId = 'new-event-preview';
    const fullCalendar = this.eventsCalendarComponent.calendarComponent.getApi();

    fullCalendar.getEventById(eventPreviewId)?.remove();
    fullCalendar.addEvent({
      start: eventData.getStart().toDate(),
      end: eventData.getEnd().toDate(),
      id: eventPreviewId,
      title: eventTitle(new AllDataEventDisplay(new AllDataEvent(eventData, new Map(), new Map()), orgData)),
      backgroundColor: isNil(overlap) ? themeColor('primary') : themeColor('error'),
      color: themeColor('white'),
      resourceId: eventData.getType(),
    });
  }

  private updateCalendarPosition(overlap: EventOverlapData | null): void {
    this.eventsCalendarComponent.calendarComponent
      .getApi()
      .gotoDate(overlap?.start ?? this.formGroup.get(['date', 'start']).value);
  }

  public ngAfterViewInit(): void {
    this.overlap$
      .pipe(withLatestFrom(this.eventData$, this.orgData$), untilDestroyed(this))
      .subscribe(([overlap, eventData, orgData]) => {
        this.updateOverlapMessage(overlap);
        this.updateEventPreview(eventData, overlap, orgData);
        this.updateCalendarPosition(overlap);
      });
  }

  public resetForm = (): void => {
    this.formGroup.reset({});
  };

  public refreshData = async (): Promise<void> => {
    await this.router.navigate(['/modify-schedule']);
  };

  public save = async (): Promise<void> => {
    const eventData = await firstValueFrom(this.eventData$);

    await firstValueFrom(
      this.eventDataService.upload({
        obj: eventData,
        generateId: true,
        mode: 'create',
      }),
    );

    this.messagesService.add({
      severity: 'success',
      summary: this.translocoService.translate('upsert-event.createEventSuccess.title'),
      detail: this.translocoService.translate('upsert-event.createEventSuccess.message'),
      key: BASE_LAYOUT_TOAST_KEY,
    });
  };
}
