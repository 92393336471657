import { SchemaField } from '../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';

export class IVRResponseSchema extends SerializableObjectSchema {
  // Speech result detected from Twilio
  public static readonly speech = 'speech';

  // Digits detected or sent
  public static readonly digits = 'digits';

  // ID of IVR in progress
  public static readonly ivrId = 'ivrId';

  // Timestamp of event
  public static readonly timestamp = 'timestamp';

  // direction
  public static readonly direction = 'direction';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly speech = undefined;

    public static readonly digits = undefined;

    public static readonly ivrId = undefined;

    public static readonly timestamp = undefined;

    public static readonly direction = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[IVRResponseSchema.speech] = SchemaField.string(IVRResponseSchema.Defaults.speech);
    parameters[IVRResponseSchema.digits] = SchemaField.string(IVRResponseSchema.Defaults.digits);
    parameters[IVRResponseSchema.ivrId] = SchemaField.string(IVRResponseSchema.Defaults.ivrId);
    parameters[IVRResponseSchema.timestamp] = SchemaField.timestamp(IVRResponseSchema.Defaults.timestamp);
    parameters[IVRResponseSchema.direction] = SchemaField.string(IVRResponseSchema.Defaults.direction);
    /* eslint-enable */

    return parameters;
  }
}
