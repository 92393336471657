import { isNil } from 'lodash';

import { AsyncServiceRequest, AsyncServiceRequestStatus } from '@pwp-common';

import { InboxRow } from '../inbox-row/inbox-row';
import { InboxRowStatus } from '../inbox-row/inbox-row-status';
import { InboxRowType } from '../inbox-row/inbox-row-type';
import { VoicemailRow } from '../voicemail-row/voicemail-row';

import { CallbackRowConstructor } from './callback-row-constructor';

export class CallbackRow extends InboxRow {
  voicemailRow: VoicemailRow | undefined;

  //////////////////////////////////////////////////////////////////////////////////////////
  // Constructor
  //////////////////////////////////////////////////////////////////////////////////////////
  constructor(public callbackRowParams: CallbackRowConstructor) {
    super({
      id: callbackRowParams.asyncServiceRequest.getId(),
      timestamp: callbackRowParams.asyncServiceRequest.getCreateTime(),
      inboxRowType: InboxRowType.callbackRequest,
      handledByUserId: callbackRowParams.asyncServiceRequest.getAssignedTo(),
      status: getStatus(callbackRowParams.asyncServiceRequest),
      callLists: callbackRowParams.callLists,
      callLog: callbackRowParams.callLog,
      allUserDataMap: callbackRowParams.allUserDataMap,
      orgData: callbackRowParams.orgData,
    });
    if (!isNil(callbackRowParams.voicemail)) {
      this.voicemailRow = new VoicemailRow(callbackRowParams.voicemail);
    }
  }
}

const getStatus = (asyncServiceRequest: AsyncServiceRequest): InboxRowStatus => {
  if (isNil(asyncServiceRequest)) {
    return InboxRowStatus.closed;
  }
  switch (asyncServiceRequest.getStatus()) {
    case AsyncServiceRequestStatus.closed: {
      return InboxRowStatus.closed;
    }
    case AsyncServiceRequestStatus.open: {
      return InboxRowStatus.open;
    }
  }
};
