<ng-container *transloco="let t; read: 'upsert-event'">
  <p-messages> </p-messages>
  <app-settings [doUpload]="save" [refreshData]="refreshData" [resetForm]="resetForm" [valid]="isValid$ | async">
    <app-accordion-wizard-steps [formGroup]="formGroup">
      <app-accordion-wizard-step
        *ngrxLet="formGroup.controls.date as date"
        [formGroup]="date"
        [hasError]="date.invalid"
        [label]="t('startAndEnd.label')"
      >
        <label *ngrxLet="date.controls.start as start" class="upsert-event__form-field">
          <span>{{ t('startAndEnd.startFieldLabel') }}</span>
          <input formControlName="start" pInputText type="datetime-local" [step]="stepSize" />

          <app-form-field-help [control]="start">
            <ng-container form-field-help-errors>
              <div *ngIf="start.errors?.required">{{ t('startAndEnd.errorRequired') }}</div>
              <div *ngIf="start.errors?.stepSizeSeconds">{{ t('startAndEnd.errorStepSizeSeconds') }}</div>
            </ng-container>
          </app-form-field-help>
        </label>

        <label *ngrxLet="date.controls.end as end" class="upsert-event__form-field">
          {{ t('startAndEnd.endFieldLabel') }}
          <input
            formControlName="end"
            pInputText
            type="datetime-local"
            [ngClass]="{
              'ng-dirty ng-invalid': date.dirty && date.errors?.isBeforeControl,
            }"
            [step]="stepSize"
          />

          <app-form-field-help [control]="date">
            <ng-container form-field-help-errors>
              <div *ngIf="end.errors?.required">{{ t('startAndEnd.errorRequired') }}</div>
              <div *ngIf="end.errors?.stepSizeSeconds">{{ t('startAndEnd.errorStepSizeSeconds') }}</div>
              <div *ngIf="date.errors?.isBeforeControl">{{ t('startAndEnd.errorIsBeforeControl') }}</div>
            </ng-container>
          </app-form-field-help>
        </label>
      </app-accordion-wizard-step>
      <app-accordion-wizard-step
        [hasError]="formGroup.controls.recurrenceMode.invalid"
        [label]="t('recurrenceMode.label')"
      >
        <p-dropdown
          class="p-fluid"
          formControlName="recurrenceMode"
          [autoDisplayFirst]="false"
          [options]="recurrenceOptions"
          [placeholder]="t('recurrenceMode.placeholder')"
        >
          <ng-template let-item pTemplate="selectedItem">
            <span>{{ t('recurrenceMode.' + item.translationKey) }}</span>
          </ng-template>
          <ng-template let-item pTemplate="item">
            <div class="upsert-event__option">
              <mat-icon>{{ item.icon }}</mat-icon>
              <span>{{ t('recurrenceMode.' + item.translationKey) }}</span>
            </div>
          </ng-template>
        </p-dropdown>
      </app-accordion-wizard-step>
    </app-accordion-wizard-steps>
  </app-settings>

  <app-events-calendar
    [editable]="false"
    [eventTypeSelectMode]="'none'"
    [selectedEventType]="eventType"
    [streamingData]="false"
  >
  </app-events-calendar>
</ng-container>
