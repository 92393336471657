import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { ConversationRequestName } from '../../../conversation-request-name';
import { ConversationResponse } from '../../generic/conversation-response/conversation-response';
import { ConversationResponseSchema } from '../../generic/conversation-response/conversation-response-schema';

import { ConversationResponseCloseAndDeleteConstructor } from './conversation-response-close-and-delete-constructor';
import { ConversationResponseCloseAndDeleteError } from './conversation-response-close-and-delete-error';
import { ConversationResponseCloseAndDeleteSchema } from './conversation-response-close-and-delete-schema';

export class ConversationResponseCloseAndDelete extends ConversationResponse<ConversationResponseCloseAndDeleteError> {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ConversationResponseCloseAndDeleteConstructor) {
    (parameters as any)[ConversationResponseSchema.type] = ConversationRequestName.closeAndDelete;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ConversationResponseCloseAndDelete {
    return new ConversationResponseCloseAndDelete(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ConversationResponseCloseAndDelete.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ConversationResponseCloseAndDeleteSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
}
