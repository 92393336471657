import type { ValidationResult } from 'joi';
import { isNil } from 'lodash';

import { ObjectBuilder, SerializableObjectType } from '../interfaces';
import { SerializableObject } from '../serializable-object';

export abstract class AnySerializableObject extends SerializableObject {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected static getObjectBuilder(validationResult: ValidationResult): ObjectBuilder {
    throw new Error('AnySerializableObject.getObjectBuilder: Must be implemented by subclass');
  }

  protected static _deserialize(
    validationResult: ValidationResult,
    ObjectBuilder: typeof SerializableObject = this,
  ): Record<string, unknown> {
    if (!isNil(validationResult.error ?? validationResult.errors)) {
      throw new Error(
        'AnySerializableObject._deserialize: User Error. Function called with error in validation result.',
      );
    }

    return ObjectBuilder.getSchema()
      .getSchemaKeys(true)
      .reduce((acc, field) => ({ ...acc, [field]: validationResult.value[field] }), {});
  }

  protected static createObject(validationResult: ValidationResult): SerializableObject {
    const ObjectBuilder = this.getObjectBuilder(validationResult);

    return new ObjectBuilder(this._deserialize(validationResult, ObjectBuilder as SerializableObjectType));
  }
}
