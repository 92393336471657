<plotly-plot
  [config]="{
    displaylogo: false,
    modeBarButtonsToRemove: [
      'zoom2d',
      'pan2d',
      'select2d',
      'lasso2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
      'resetScale2d',
      'toggleSpikelines',
      'hoverClosestCartesian',
      'hoverCompareCartesian',
    ],
  }"
  [data]="[
    {
      x: values,
      y: labels,
      type: 'funnel',
      hoverinfo: 'y+x+percent previous+percent initial',
    },
  ]"
  [layout]="{
    xaxis: {
      automargin: true,
      autorange: 'reversed',
    },
    yaxis: {
      automargin: true,
      autorange: 'reversed',
    },
  }"
  [style]="{ position: 'relative', width: '100%', height: '100vh' }"
  [useResizeHandler]="true"
>
</plotly-plot>
