import { cloneDeep, isNil } from 'lodash';

import { DBDocSchema } from '../../generic/db-doc/db-doc-schema';
import { SupportedLanguages } from '../../voice-response-command/vrc-audio-metadata/supported-languages';
import { normalizeSpeechResult } from '../ivr/helper/normalize-speech-result';
import { IVRResponse } from '../ivr/ivr-response/ivr-response';
import { IVRResponseDirection } from '../ivr/ivr-response/ivr-response-direction';

import { ConfirmJoinIVRResponseStatus } from './confirm-join-ivr-response-status';
import { DialStatus } from './dial-status';
import { DialedCallLogConstructor } from './dialed-call-log-constructor';
import { DialedCallLogSchema } from './dialed-call-log-schema';
import { GenericDialedCallLog } from './generic/any-dialed-call-log';
import { OperatorCallStatus } from './generic/operator-call-status';
import { UserStatus } from './user-status';

export class DialedCallLog extends GenericDialedCallLog {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected userId!: string;

  protected userStatus!: UserStatus;

  protected dialStatus!: DialStatus | undefined;

  protected connectedToClient!: boolean;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: DialedCallLogConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): DialedCallLog {
    return new DialedCallLog(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(DialedCallLog.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new DialedCallLogSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  /**
   * UserId of user this call was directed to
   */
  public getUserId(): string {
    return cloneDeep(this.userId);
  }

  /**
   * Status of the user.
   */
  public getUserStatus(): UserStatus {
    return cloneDeep(this.userStatus);
  }

  /**
   * Status of the dial. Specifies the type of dial error, if any. For example,
   * invalidOrMissingPhone, and incomingCallNotActive.
   */
  public getDialStatus(): DialStatus | undefined {
    return cloneDeep(this.dialStatus);
  }

  public getConnectedToClient(): boolean {
    return cloneDeep(this.connectedToClient);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Did Dial
  /////////////////////////////////////////////////////////////////////////////

  public didDial(): boolean {
    if (this.initiatedTimestamp !== undefined && this.operatorCallStatus !== OperatorCallStatus.failed) {
      return true;
    }
    return false;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Confirm Join Status
  /////////////////////////////////////////////////////////////////////////////

  public confirmJoinStatus(): { status: ConfirmJoinIVRResponseStatus; response?: IVRResponse } {
    if (isNil(this.responses) || this.responses.length === 0) {
      return { status: ConfirmJoinIVRResponseStatus.noResponse };
    }

    for (let i = this.responses.length - 1; i >= 0; i--) {
      const response = this.responses[i];
      if (response.getDirection() !== IVRResponseDirection.received) {
        continue;
      }
      const digits = response.getDigits() ?? '';
      const speech = normalizeSpeechResult(response.getSpeech() ?? '', SupportedLanguages.en);

      if (digits.length > 0) {
        if (digits === '1') {
          return { status: ConfirmJoinIVRResponseStatus.acceptedWithDigits, response };
        }
        return { status: ConfirmJoinIVRResponseStatus.invalidDigits, response };
      }

      if (speech.length > 0) {
        if (['accept', 'aceptar'].includes(speech)) {
          return { status: ConfirmJoinIVRResponseStatus.acceptedWithSpeech, response };
        }
        return { status: ConfirmJoinIVRResponseStatus.invalidSpeech, response };
      }
    }

    return { status: ConfirmJoinIVRResponseStatus.noResponse };
  }
}
