import Joi from 'joi';

import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { ConversationResponseCloseAndDeleteSchema } from '../../responses/conversation-response-close-and-delete/conversation-response-close-and-delete-schema';
import { ConversationResponseConfigureSchema } from '../../responses/conversation-response-configure/conversation-response-configure-schema';
import { ConversationResponseGetJwtSchema } from '../../responses/conversation-response-get-jwt/conversation-response-get-jwt-schema';
import { ConversationResponseTakeSchema } from '../../responses/conversation-response-take/conversation-response-take-schema';

export class AnyConversationResponseSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The type of the entity
  public static readonly type = 'type';

  private static readonly SCHEMA_ID = 'anyConversationResponse';

  private static computedSchema = Joi.alternatives()
    .try(
      ...[
        new ConversationResponseCloseAndDeleteSchema().getJoiSchema(),
        new ConversationResponseConfigureSchema().getJoiSchema(),
        new ConversationResponseGetJwtSchema().getJoiSchema(),
        new ConversationResponseTakeSchema().getJoiSchema(),
      ],
    )
    .id(AnyConversationResponseSchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static GenericDefaults = class {
    // The name of the command
    public static readonly type = undefined;
  };

  /**
   * Always serialize the command name
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  public static getSchemaId(): string {
    return `#${AnyConversationResponseSchema.SCHEMA_ID}`;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } | import('joi').AnySchema {
    return AnyConversationResponseSchema.computedSchema;
  }
}
