import type { AnySchema } from 'joi';

import { SchemaField } from '../../../../../objects/generic/serialization/schema-field';
import { UserRequestSchema } from '../../generic/user-request/user-request-schema';

export class UserRequestUpdateUserSchema extends UserRequestSchema {
  public static readonly password = 'password';

  public static readonly userId = 'userId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static Defaults = class extends UserRequestSchema.Defaults {
    public static readonly password = undefined;

    public static readonly userId = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: AnySchema } {
    const parameters = super.getSchemaDefinition();

    parameters[UserRequestUpdateUserSchema.password] = SchemaField.string(
      UserRequestUpdateUserSchema.Defaults.password,
    ).required();

    parameters[UserRequestUpdateUserSchema.userId] = SchemaField.userId(
      UserRequestUpdateUserSchema.Defaults.userId,
    ).required();

    return parameters;
  }
}
