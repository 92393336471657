import { cloneDeep } from 'lodash';

import { DBUploadExistingDoc } from '../../../db/transaction/actions/db-upload-existing-doc';
import { EventData } from '../event-data/event-data';

import { EventDataTransactionDisplay } from './event-data-transaction-display';

export function selectEventsWeeklyInFuture(
  transactions: EventDataTransactionDisplay[],
  allEvents: EventData[],
): EventData[] {
  const futureAssignments: EventData[] = [];

  console.log(allEvents);
  for (const futureEvent of allEvents) {
    for (const givenTransaction of transactions) {
      const givenEvent = (givenTransaction.actions[0] as DBUploadExistingDoc<EventData>).obj;

      if (futureEvent.isShiftedByWeek(givenEvent)) {
        const updatedFutureEvent = cloneDeep(futureEvent);
        futureAssignments.push(updatedFutureEvent);
        break;
      }
    }
  }

  console.log('Returning future assignments');
  console.log(futureAssignments);
  return futureAssignments;
}
