import { cloneDeep } from 'lodash';

import { DBDocSchema } from '../../../generic/db-doc/db-doc-schema';
import { GenericDisplayable } from '../../../generic/displayable/generic-displayable';
import { LanguageDefaults } from '../language-defaults';
import { SSMLAudio } from '../ssml-audio/ssml-audio';

import { VRCAudioMetadataSchema } from './vrc-audio-metadata-schema';
import { VRCAudioMetadataConstructor } from './vrc-audio-metadata-schema-constructor';

/**
 * This command holds metadata associated to voice response commands.
 */
export class VRCAudioMetadata extends GenericDisplayable {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected audio!: Map<string, SSMLAudio>;

  protected audioNoSpeechRecognition!: Map<string, SSMLAudio>;

  protected wasProcessedByServer!: boolean | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: VRCAudioMetadataConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): VRCAudioMetadata {
    return new VRCAudioMetadata(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(VRCAudioMetadata.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new VRCAudioMetadataSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getAudio(): Map<string, SSMLAudio> {
    return cloneDeep(this.audio);
  }

  public getAudioNoSpeechRecognition(): Map<string, SSMLAudio> {
    return cloneDeep(this.audioNoSpeechRecognition);
  }

  public getWasProcessedByServer(): boolean | undefined {
    return cloneDeep(this.wasProcessedByServer);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////

  public setAudioSSML(langCode: LanguageDefaults, ssmlAudio: SSMLAudio) {
    this.audio.set(langCode.getShortCode(), ssmlAudio);
    return this;
  }

  public setWasProcessedByServer(wasProcessedByServer: boolean) {
    this.wasProcessedByServer = wasProcessedByServer;
    return this;
  }
}
