import { values } from 'lodash';

import { SchemaField } from '../../../../../objects/generic/serialization/schema-field';
import { ConversationResponseSchema } from '../../generic/conversation-response/conversation-response-schema';

import { ConversationResponseGetJwtError } from './conversation-response-get-jwt-error';

export class ConversationResponseGetJwtSchema extends ConversationResponseSchema {
  public static readonly jwt = 'jwt';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;

    public static readonly error = undefined;

    public static readonly jwt = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[ConversationResponseGetJwtSchema.jwt] = SchemaField.jwt(ConversationResponseGetJwtSchema.Defaults.jwt);
    parameters[ConversationResponseGetJwtSchema.error] = SchemaField.string(
      ConversationResponseGetJwtSchema.Defaults.error,
    )
      .optional()
      .valid(...values(ConversationResponseGetJwtError));
    /* eslint-enable */

    return parameters;
  }
}
