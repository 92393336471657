import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { isNil } from 'lodash';

import { NgChanges } from '../../../../../../common/objects/ng-changes';
import { CommunicationsService } from '../../../../../../services/call/communications/communications.service';
import { VoicemailRow } from '../../../../row/voicemail-row/voicemail-row';
import { InboxDialPhoneConfirmResult } from '../../generic/inbox-dial-phone-confirm/inbox-dial-phone-confirm-result';

@Component({
  selector: 'app-inbox-voicemail-detail-dial-phone-button',
  templateUrl: './inbox-voicemail-detail-dial-phone-button.component.html',
  styleUrls: ['./inbox-voicemail-detail-dial-phone-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboxVoicemailDetailDialPhoneButtonComponent implements OnInit, OnChanges {
  ///////////////////////////////////////////////////////////////////////
  // Input
  ///////////////////////////////////////////////////////////////////////
  @Input() row: VoicemailRow;

  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////
  canDial = true;

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private communicationsService: CommunicationsService,
  ) {}

  ngOnInit(): void {
    this.refreshUI();
  }

  ngOnChanges(changes: NgChanges<InboxVoicemailDetailDialPhoneButtonComponent>) {
    this.refreshUI();
  }

  ///////////////////////////////////////////////////////////////////////
  // Dial Phone
  ///////////////////////////////////////////////////////////////////////

  doDial = async (result: InboxDialPhoneConfirmResult) => {
    await this.doDialHandler(result);
  };

  async doDialHandler(result: InboxDialPhoneConfirmResult) {
    await this.communicationsService.dialByPhone({
      e164Phone: result.e164Phone,
      callerIdObjId: result.callerIdObjId,
    });
  }

  ///////////////////////////////////////////////////////////////////////
  // Refresh UI
  ///////////////////////////////////////////////////////////////////////

  refreshUI() {
    if (isNil(this.row)) {
      this.canDial = false;
    }

    this.changeDetectorRef.markForCheck();
  }
}
