import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SharedModule } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';

import { ForwardSchema } from '@pwp-common';

import { provideValidator } from '../../../../common/form/provide-validator';
import { provideValueAccessor } from '../../../../common/form/provide-value-accessor';
import { TranslocoRootModule } from '../../../../transloco/transloco-root.module';
import { FormGroupControlValueAccessor } from '../../../generic/abstract-classes/form-group-control-value-accessor';
import { PhoneEditorModule } from '../../phone-editor/phone-editor.module';
import { ForwardEditorOutput } from '../editor-output/forward-editor-output';

@UntilDestroy()
@Component({
  selector: 'app-service-command-editor-forward',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MessagesModule,
    PhoneEditorModule,
    ReactiveFormsModule,
    SharedModule,
    TranslocoRootModule,
  ],
  templateUrl: './service-command-editor-forward.component.html',
  styleUrls: ['./service-command-editor-forward.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    provideValueAccessor(ServiceCommandEditorForwardComponent),
    provideValidator(ServiceCommandEditorForwardComponent),
  ],
})
export class ServiceCommandEditorForwardComponent
  extends FormGroupControlValueAccessor<ForwardEditorOutput, ForwardEditorOutput>
  implements Validator
{
  public defineForm(): void {
    this.form = new FormGroup({
      [ForwardSchema.commandName]: new FormControl(ForwardSchema.Defaults.commandName, [Validators.required]),
      [ForwardSchema.to]: new FormControl(ForwardSchema.Defaults.to, [Validators.required]),
    });
  }

  public parseValueChange(value: ForwardEditorOutput): ForwardEditorOutput {
    return value;
  }

  public validate(): ValidationErrors | null {
    if (this.form.valid) {
      return null;
    }

    return { invalidForwardCommand: true };
  }
}
