import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';

import { AudioPlayerComponent } from './audio-player/audio-player.component';

@NgModule({
  declarations: [AudioPlayerComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule, MatSliderModule],
  exports: [AudioPlayerComponent],
})
export class AudioPlayerModule {}
