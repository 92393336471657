import { SchemaField } from '../../../generic/serialization/schema-field';
import { GenericDialedCallLogSchema } from '../../dialed-call-log/generic/any-dialed-call-log-schema';
import { OutboundCallSessionParticipant } from '../outbound-call-session-participant/outbound-call-session-participant';

export class OutboundCallSessionSchema extends GenericDialedCallLogSchema {
  public static readonly conferenceSid = 'conferenceSid';

  public static readonly participant = 'participant';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'outboundCallSessions';
  };

  static Defaults = class {
    ///////////////////////////////////////////////////////
    // Generic Properties, Copy/Pasted Here
    ///////////////////////////////////////////////////////
    /**
     * Timestamps
     */
    public static readonly initiatedTimestamp = undefined;

    public static readonly ringingTimestamp = undefined;

    public static readonly answeredTimestamp = undefined;

    public static readonly completedTimestamp = undefined;

    /**
     * Operator Tracking
     */
    public static readonly errorCode = undefined;

    public static readonly operatorDurationMS = undefined;

    public static readonly operatorPriceUnit = undefined;

    public static readonly operatorBasePricePM = undefined;

    public static readonly operatorCountryCode = undefined;

    /**
     * IVR
     */
    public static readonly responses = [];

    /**
     * Other
     */
    public static readonly callerIdObjId = undefined;

    public static readonly sipResponseCode = undefined;

    public static readonly operatorCallStatus = undefined;

    public static readonly callSid = undefined;

    ///////////////////////////////////////////////////////
    // OutboundCallSession Properties
    ///////////////////////////////////////////////////////

    public static readonly conferenceSid = undefined;

    public static readonly participant = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[OutboundCallSessionSchema.conferenceSid] = SchemaField.string(
      OutboundCallSessionSchema.Defaults.conferenceSid,
    ).optional();
    parameters[OutboundCallSessionSchema.participant] = SchemaField.obj(
      OutboundCallSessionParticipant,
      OutboundCallSessionSchema.Defaults.participant,
    ).required();
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${OutboundCallSessionSchema.Constants.collection}`;
  }
}
