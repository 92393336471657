import { cloneDeep } from 'lodash';

import { SerializableObject } from '../../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../../generic/serialization/serializable-object-schema';
import { ServiceExceptionExecution } from '../service-exception-execution/service-exception-execution';

import { ServiceExceptionExecutionsConstructor } from './service-exception-executions-constructor';
import { ServiceExceptionExecutionsSchema } from './service-exception-executions-schema';

export class ServiceExceptionExecutions extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected executions!: Map<string, ServiceExceptionExecution>;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ServiceExceptionExecutionsConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ServiceExceptionExecutions {
    const obj = new ServiceExceptionExecutions(super._deserialize(validationResult));
    return obj;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(ServiceExceptionExecutions.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ServiceExceptionExecutionsSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getExecutions() {
    return cloneDeep(this.executions);
  }
}
