import { isNil } from 'lodash';

import { ANONYMOUS_PHONES_SORTED } from '../constants';

const ANONYMOUS_STRING_PHONES = new Set(['anonymous']);
const ANONYMOUS_PHONES = new Set(ANONYMOUS_PHONES_SORTED);
/**
 * Return true if the given from field specifies an anonymous
 * phone. Else return false.
 * @param req: Request object for current call
 */
export const isAnonymousPhone = (fromField: string | null | undefined): boolean => {
  if (isNil(fromField)) {
    return true;
  }
  /**
   * Check when e164Phone: 'Anonymous'
   */
  if (ANONYMOUS_STRING_PHONES.has(fromField.trim().toLowerCase())) {
    return true;
  }
  const digitsOnly = fromField.replace(/[^\d]/g, '');
  return ANONYMOUS_PHONES.has(digitsOnly);
};
