<!-- Play Or Say Command -->
<form *ngIf="!loading" [formGroup]="form">
  <!-- Number of times to loop -->
  <mat-form-field class="full-width">
    <input class="full-width" formControlName="loop" matInput placeholder="Loop" />
    <mat-error *ngIf="loop.errors?.min && loop.dirty"> Min is {{ loop.errors?.min.min }} </mat-error>
    <mat-error *ngIf="loop.errors?.max && loop.dirty"> Max is {{ loop.errors?.max.max }} </mat-error>
  </mat-form-field>

  <!-- Audio Select -->
  <app-obj-select
    [label]="'Audio Select'"
    [orderedItems]="vrcAudioMetadataArray"
    [(kvPair)]="selectedVrcAudioMetadataKVPair"
    (kvPairChange)="updateObjAndEmit()"
  ></app-obj-select>
</form>

<!-- Is Valid Indicator -->
<app-obj-is-valid *ngIf="!loading" [isValidObj]="isValidObjScoper"></app-obj-is-valid>
