import type { ValidationResult } from 'joi';

import { AnySerializableObject } from '../../../../../objects/generic/serialization/any-serializable-object/any-serializable-object';
import { ObjectBuilder } from '../../../../../objects/generic/serialization/interfaces';
import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { FormRequestName } from '../../../form-request-name';
import { FormResponseCreateSubmission } from '../../responses/form-response-create-submission/form-response-create-submission';
import { FormResponseCreateVersion } from '../../responses/form-response-create-version/form-response-create-version';

import { AnyFormResponseSchema } from './any-form-response-schema';

export abstract class AnyFormResponse extends AnySerializableObject {
  protected static getObjectBuilder(validationResult: ValidationResult): ObjectBuilder {
    const typeString = validationResult.value[AnyFormResponseSchema.type];
    const type = FormRequestName[typeString as keyof typeof FormRequestName];

    if (type === undefined) {
      throw new Error(`AnyFormResponseUser._deserialize: User Error: Cannot deserialize obj with type=${typeString}`);
    }

    switch (type) {
      case FormRequestName.createSubmission:
        return FormResponseCreateSubmission;
      case FormRequestName.createVersion:
        return FormResponseCreateVersion;
      default:
        throw new Error(`AnyFormResponse._deserialize Unknown type: ${type}`);
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new AnyFormResponseSchema();
  }
}
