import { CommunicationLogParticipant } from '../../communication/communication-log-participant/communication-log-participant';
import { DBDocSchema } from '../../generic/db-doc/db-doc-schema';
import { SchemaField } from '../../generic/serialization/schema-field';

export class SentMessageSchema extends DBDocSchema {
  public static readonly errorCode = 'errorCode';

  public static readonly from = 'from';

  public static readonly messageSid = 'messageSid';

  public static readonly numMedia = 'numMedia';

  public static readonly numSegments = 'numSegments';

  public static readonly operatorTimestamps = 'operatorTimestamps';

  public static readonly participant = 'participant';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly errorCode = undefined;

    public static readonly from = undefined;

    public static readonly messageSid = undefined;

    public static readonly numMedia = undefined;

    public static readonly numSegments = undefined;

    public static readonly operatorTimestamps = new Map();

    public static readonly participant = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */

    parameters[SentMessageSchema.errorCode] = SchemaField.number(SentMessageSchema.Defaults.errorCode);
    parameters[SentMessageSchema.from] = SchemaField.string(SentMessageSchema.Defaults.from);
    parameters[SentMessageSchema.messageSid] = SchemaField.string(SentMessageSchema.Defaults.messageSid);
    parameters[SentMessageSchema.numMedia] = SchemaField.number(SentMessageSchema.Defaults.numMedia);
    parameters[SentMessageSchema.numSegments] = SchemaField.number(SentMessageSchema.Defaults.numSegments);
    parameters[SentMessageSchema.operatorTimestamps] = SchemaField.mapOfType(
      SchemaField.timestamp(undefined),
      SentMessageSchema.Defaults.operatorTimestamps,
    );
    parameters[SentMessageSchema.participant] = SchemaField.obj(
      CommunicationLogParticipant,
      SentMessageSchema.Defaults.participant,
    );
    /* eslint-enable */

    return parameters;
  }

  getCollection(orgId: string, ...otherFields: string[]): string {
    throw new Error('This method should never be called.');
  }
}
