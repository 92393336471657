import { DBMatcher } from '../../../../core/db-matcher/db-matcher/db-matcher';
import { TTL } from '../../../../core/ttl/ttl';
import { GenericDisplayableSchema } from '../../../../generic/displayable/generic-displayable-schema';
import { SchemaField } from '../../../../generic/serialization/schema-field';
import { ServiceExceptionActionInboundCallSession } from '../service-exception-action/service-exception-action-inbound-call-session/service-exception-action-inbound-call-session';

export class ServiceExceptionSchema extends GenericDisplayableSchema {
  public static readonly ttl = 'ttl';

  public static readonly inboundCallSessionAction = 'inboundCallSessionAction';

  public static readonly matcher = 'matcher';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'serviceExceptions';
  };

  static Defaults = class {
    public static readonly description = 'missing-description';

    public static readonly displayName = 'missing-displayName';

    public static readonly queryIdentifiers = [];

    public static readonly ttl = undefined;

    public static readonly inboundCallSessionAction = undefined;

    public static readonly matcher = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[ServiceExceptionSchema.ttl] = SchemaField.obj(TTL, ServiceExceptionSchema.Defaults.ttl).optional();
    parameters[ServiceExceptionSchema.inboundCallSessionAction] = SchemaField.obj(
      ServiceExceptionActionInboundCallSession,
      ServiceExceptionSchema.Defaults.inboundCallSessionAction,
    );
    parameters[ServiceExceptionSchema.matcher] = SchemaField.obj(DBMatcher, ServiceExceptionSchema.Defaults.matcher);
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${ServiceExceptionSchema.Constants.collection}`;
  }
}
