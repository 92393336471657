import type { AnySchema } from 'joi';

import { GenericDisplayableSchema } from '../../generic/displayable/generic-displayable-schema';
import { SchemaField } from '../../generic/serialization/schema-field';

export class FormSchema extends GenericDisplayableSchema {
  public static readonly activeVersionId = 'activeVersionId';

  public static readonly Constants = class {
    public static readonly collection = 'forms';
  };

  public static readonly Defaults = class extends GenericDisplayableSchema.Defaults {
    public static readonly activeVersionId = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: AnySchema } {
    const parameters = super.getSchemaDefinition();

    parameters[FormSchema.activeVersionId] = SchemaField.autoGeneratedDocId(FormSchema.Defaults.activeVersionId);

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${FormSchema.Constants.collection}`;
  }
}
