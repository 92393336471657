import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Provider } from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { cloneDeep, isNil } from 'lodash';

import { FormGroupControlValueAccessor } from '../../../../generic/abstract-classes/form-group-control-value-accessor';
import {
  answerQuicklyRingTimeEditorOutput,
  defaultOfferConfigRingTimeEditorOutput,
} from '../editor-output/default-offer-config-ring-time-editor-output';
import { OfferConfigRingTimeEditorOutput } from '../editor-output/interfaces';
import { PresetValues } from '../editor-output/preset-values';

const VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => OfferConfigRingTimeEditorComponent),
  multi: true,
};
const VALIDATOR: Provider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => OfferConfigRingTimeEditorComponent),
  multi: true,
};

@UntilDestroy()
@Component({
  selector: 'app-offer-config-ring-time-editor',
  templateUrl: './offer-config-ring-time-editor.component.html',
  styleUrls: ['./offer-config-ring-time-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VALUE_ACCESSOR, VALIDATOR],
})
export class OfferConfigRingTimeEditorComponent extends FormGroupControlValueAccessor<
  OfferConfigRingTimeEditorOutput,
  OfferConfigRingTimeEditorOutput
> {
  possiblePresetValues: { label: string; value: string }[] = [
    { label: 'answerQuicklyButtonLabel', value: PresetValues.answerQuickly },
    { label: 'customButtonLabel', value: PresetValues.custom },
  ];

  baseObj: OfferConfigRingTimeEditorOutput | undefined = undefined;

  presetValues = PresetValues;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////
  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Controls
  /////////////////////////////////////////////////////////////////////////////////////////////
  get preset() {
    return this.form.get('preset') as UntypedFormControl;
  }

  get ringTimeoutSeconds() {
    return this.form.get('ringTimeoutSeconds') as UntypedFormControl;
  }

  get hangUpOnVoiceMail() {
    return this.form.get('hangUpOnVoiceMail') as UntypedFormControl;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////
  defineForm() {
    const formConfig = {} as any;
    formConfig.preset = new UntypedFormControl(undefined, []);
    formConfig.ringTimeoutSeconds = new UntypedFormControl(undefined, []);
    formConfig.hangUpOnVoiceMail = new UntypedFormControl(undefined, []);
    this.form = new UntypedFormGroup(formConfig);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////
  parseValueChange(value: any): any {
    return value;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Write value
  /////////////////////////////////////////////////////////////////////////////////////////////
  writeValue(value: any) {
    if (isNil(value)) {
      return;
    }
    this.baseObj = cloneDeep(value ?? defaultOfferConfigRingTimeEditorOutput);
    const formValue = {
      ringTimeoutSeconds: this.baseObj?.ringTimeoutSeconds,
      hangUpOnVoiceMail: this.baseObj?.hangUpOnVoiceMail,
      preset: this.baseObj.preset,
    };
    this.form.patchValue(formValue, { emitEvent: true });
    this.changeDetectorRef.detectChanges();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Custom methods
  /////////////////////////////////////////////////////////////////////////////////////////////
  handlePresetChange(value: PresetValues) {
    if (value === PresetValues.answerQuickly) {
      this.form.patchValue(answerQuicklyRingTimeEditorOutput);
    } else {
      this.form.patchValue({
        ...(this.baseObj ?? defaultOfferConfigRingTimeEditorOutput),
        /**
         * To handle the corner case of baseObj = answerQuicklyRingTimeEditorOutput
         * we force the preset to be custom.
         */
        preset: PresetValues.custom,
      });
    }
  }

  handleTimeoutChange(res: number) {
    if (
      res === answerQuicklyRingTimeEditorOutput.ringTimeoutSeconds &&
      this.hangUpOnVoiceMail.value === answerQuicklyRingTimeEditorOutput.hangUpOnVoiceMail
    ) {
      this.form.patchValue({ preset: PresetValues.answerQuickly });
    } else {
      this.form.patchValue({ preset: PresetValues.custom });
    }
  }

  handleHangUpChange(checkboxValue: boolean) {
    if (
      checkboxValue === answerQuicklyRingTimeEditorOutput.hangUpOnVoiceMail &&
      this.ringTimeoutSeconds.value === answerQuicklyRingTimeEditorOutput.ringTimeoutSeconds
    ) {
      this.form.patchValue({ preset: PresetValues.answerQuickly });
    } else {
      const presetValue =
        this.ringTimeoutSeconds.value === answerQuicklyRingTimeEditorOutput.ringTimeoutSeconds
          ? PresetValues.answerQuickly
          : PresetValues.custom;
      this.form.patchValue({ preset: presetValue });
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////
  public makeValidationErrors(): ValidationErrors {
    return {
      'offer-config-ring-time-editor': this.form.value,
    };
  }
}
