import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { Observable } from 'rxjs';

import { VoicemailConfig } from '@pwp-common';

import { provideValidator } from '../../../common/form/provide-validator';
import { provideValueAccessor } from '../../../common/form/provide-value-accessor';
import { setControlEnabled } from '../../../common/form/set-control-enabled';
import { VoicemailConfigService } from '../../../services/voicemail/voicemail-config/voicemail-config.service';
import { ObjAutocompleteFormControlBase } from '../../generic/abstract-classes/obj-autocomplete-form-control-base';

@Component({
  selector: 'app-voicemail-autocomplete',
  standalone: true,
  imports: [AutoCompleteModule, CommonModule, FormsModule, MatIconModule, ReactiveFormsModule, TranslocoModule],
  providers: [provideValueAccessor(VoicemailAutocompleteComponent), provideValidator(VoicemailAutocompleteComponent)],
  templateUrl: './voicemail-autocomplete.component.html',
  styleUrls: ['./voicemail-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VoicemailAutocompleteComponent extends ObjAutocompleteFormControlBase<VoicemailConfig> {
  private static id = 0;

  private readonly voicemailConfigService = inject(VoicemailConfigService);

  public readonly defaultId = `app-voicemail-autocomplete-${(VoicemailAutocompleteComponent.id += 1)}`;

  @Input() public inputId?: string;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Set All Options
  /////////////////////////////////////////////////////////////////////////////////////////////

  public defineAllOptions(): Observable<VoicemailConfig[]> {
    return this.voicemailConfigService.getDocsArray();
  }

  // @Note: when running control.enable, the value is removed from the control. Reason is still unclear.
  // Component will likely get rewritten
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled !== this.form.disabled) {
      setControlEnabled(this.form, !isDisabled);
    }

    this.changeDetectorRef.detectChanges();
  }
}
