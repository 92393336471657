import { cloneDeep } from 'lodash';

import { SerializableObject } from '../../../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../../../generic/serialization/serializable-object-schema';
import { ServiceOperation } from '../../../../../voice-response-command/service-operation/service-operation/service-operation';

export abstract class ServiceExceptionAction extends SerializableObject {
  protected onCreate!: ServiceOperation;
  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    throw new Error('Error: Cannot get schema for abstract ServiceExceptionAction');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getOnCreate(): ServiceOperation {
    return cloneDeep(this.onCreate);
  }
}
