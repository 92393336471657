import type { AnySchema } from 'joi';

import { SchemaField } from '../../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../../generic/serialization/serializable-object-schema';
import { AutomationActionName } from '../../automation-action-name';

export class AutomationActionSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly type = 'type';

  public static readonly Defaults = class {
    public static readonly type = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: AnySchema } {
    const parameters: { [key: string]: AnySchema } = {};

    parameters[AutomationActionSchema.type] = SchemaField.string(AutomationActionSchema.Defaults.type)
      .required()
      .valid(...Object.values(AutomationActionName));

    return parameters;
  }
}
