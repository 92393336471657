<ng-container *transloco="let t; read: 'target-mode-editor'">
  <form [formGroup]="form">
    <div class="formgrid grid p-fluid">
      <!-- Select Mode -->
      <div class="field col-12 mt-3">
        <span class="p-float-label">
          <p-dropdown
            id="mode"
            optionLabel="value"
            optionValue="value"
            attr.aria-label="{{ t('modeLabel') }}"
            [autoDisplayFirst]="false"
            [formControl]="mode"
            [ngClass]="{ 'ng-invalid': mode?.invalid }"
            [options]="modes"
          >
            <ng-template pTemplate="selectedItem" let-item>
              <div class="row w-full">
                <div class="col">
                  {{ t(item.value) }}
                </div>
              </div>
            </ng-template>

            <ng-template pTemplate="item" let-item>
              <div class="row w-full">
                <div class="col">
                  {{ t(item.value) }}
                </div>
              </div>
            </ng-template>
          </p-dropdown>
          <label for="mode">{{ t('modeLabel') }}</label>
        </span>
        <p *ngIf="mode.hasError('required')" class="p-error">{{ t('modeErrorRequired') }}</p>
      </div>

      <!-- Max Reserved Workers -->
      <div *ngIf="!maxReservedWorkers.disabled" class="field col-12 mt-3">
        <span class="p-float-label">
          <p-inputNumber
            id="maxReservedWorkers"
            mode="decimal"
            ariaLabel="{{ t('maxReservedWorkersLabel') }}"
            [formControl]="maxReservedWorkers"
            [ngClass]="{ 'ng-invalid': maxReservedWorkers?.invalid }"
            [showButtons]="true"
            suffix=" {{ t('maxReservedWorkersSuffix') }}"
          >
          </p-inputNumber>
          <label for="maxReservedWorkers">{{ t('maxReservedWorkersLabel') }}</label>
          <p *ngIf="maxReservedWorkers.hasError('required')" class="p-error">
            {{ t('maxReservedWorkersErrorRequired') }}
          </p>
          <p *ngIf="maxReservedWorkers.hasError('min')" class="p-error">
            {{ t('maxReservedWorkersErrorMin', { maxReservedWorkers: maxReservedWorkers.value }) }}
          </p>
          <p *ngIf="maxReservedWorkers.hasError('max')" class="p-error">
            {{ t('maxReservedWorkersErrorMax', { maxReservedWorkers: maxReservedWorkers.value }) }}
          </p>
        </span>
        <p class="wrap" id="maxReservedWorkers-help">
          {{ t('maxReservedWorkersHelp', { maxReservedWorkers: maxReservedWorkers.value }) }}
        </p>
      </div>
    </div>
  </form>
</ng-container>
