import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { isNil } from '@ngneat/transloco';

import {
  AnyUserResponse,
  UserRequest,
  UserRequestChangeOrgId,
  UserRequestCreateUser,
  UserRequestCreateUserConstructor,
  UserResponse,
} from '@pwp-common';

import { UserRequestUpdateUser } from '../../../../../../../common/src/callable-functions/user/request/requests/user-request-update-user/user-request-update-user';
import { UserRequestUpdateUserConstructor } from '../../../../../../../common/src/callable-functions/user/request/requests/user-request-update-user/user-request-update-user-constructor';
import { CallableFunctionService } from '../../generic/callable-function-service/callable-function-service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserEndpointService extends CallableFunctionService<UserRequest, UserResponse<unknown>> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(
    fns: AngularFireFunctions,
    private authService: AuthService,
  ) {
    super(AnyUserResponse, fns);
  }
  ///////////////////////////////////////////////////////////////////////
  // Function Name
  ///////////////////////////////////////////////////////////////////////

  public getFunctionName() {
    return 'user';
  }

  ///////////////////////////////////////////////////////////////////////
  // Change Org Id
  ///////////////////////////////////////////////////////////////////////

  public async changeOrgIdOfLoggedInUser(newOrgId: string) {
    const currentOrgId = await this.authService.getOrgId().toPromise();
    if (isNil(newOrgId) || currentOrgId === newOrgId) {
      console.log('changeOrgIdLoggedInUser: Nothing to do.', { currentOrgId, newOrgId });
      return;
    }

    const request = new UserRequestChangeOrgId({ requestedNewOrgId: newOrgId });
    const response = await this.makeRequestOLD(request).toPromise();
    console.log(response);

    return response;
  }

  ///////////////////////////////////////////////////////////////////////
  // Change Org Id
  ///////////////////////////////////////////////////////////////////////

  public async createUser(params: UserRequestCreateUserConstructor) {
    const request = new UserRequestCreateUser(params);

    return this.makeRequest(request);
  }

  ///////////////////////////////////////////////////////////////////////
  // Update User
  ///////////////////////////////////////////////////////////////////////

  public async updateUser(params: UserRequestUpdateUserConstructor) {
    const request = new UserRequestUpdateUser(params);

    return this.makeRequest(request);
  }
}
