/* eslint-disable max-classes-per-file */
import { CalleeEntity } from '../../call/callee-entity/callee-entity';
import { timeSelectionAlwaysInclude } from '../../core/time-selection/constants';
import { TimeSelection } from '../../core/time-selection/time-selection/time-selection';
import { GenericDisplayableSchema } from '../../generic/displayable/generic-displayable-schema';
import { SchemaField } from '../../generic/serialization/schema-field';
import { IdentityConfigConversation } from '../../identity/identity-config/identity-config-conversation/identity-config-conversation';
import { AnyVoiceResponseCommand } from '../../voice-response-command/any-voice-response-command/any-voice-response-command';
import { conversationWaitingRoomConfigDefault } from '../conversation-waiting-room-config/constants';
import { ConversationWaitingRoomConfig } from '../conversation-waiting-room-config/conversation-waiting-room-config';

import { defaultIdentityConfigConversation } from './defaults';

export class ConversationConfigSchema extends GenericDisplayableSchema {
  public static readonly chatServiceSid = 'chatServiceSid';

  public static readonly onCannotProvideService = 'onCannotProvideService'; // Commands to execute if we cannot provide service.

  public static readonly serviceProviders = 'serviceProviders'; // List of objects specifying who will provide service

  public static readonly roleSidAnonymousParticipant = 'roleSidAnonymousParticipant';

  public static readonly roleSidInternalParticipant = 'roleSidInternalParticipant';

  public static readonly communicationWorkflowId = 'communicationWorkflowId';

  public static readonly inactiveAfterDuration = 'inactiveAfterDuration';

  public static readonly closedAfterDuration = 'closedAfterDuration';

  public static readonly availableTimes = 'availableTimes';

  public static readonly pIIRetentionDuration = 'pIIRetentionDuration';

  public static readonly redirectURL = 'redirectURL';

  public static readonly waitingRoomConfig = 'waitingRoomConfig';

  public static readonly identityConfig = 'identityConfig';

  public static readonly phones = 'phones';

  public static readonly messagingServiceSid = 'messagingServiceSid';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly Constants = class {
    public static readonly collection = 'conversationConfig';
  };

  public static readonly Defaults = class extends GenericDisplayableSchema.Defaults {
    public static readonly chatServiceSid = undefined;

    public static readonly onCannotProvideService = [];

    public static readonly serviceProviders = [];

    public static readonly roleSidAnonymousParticipant = undefined;

    public static readonly roleSidInternalParticipant = undefined;

    public static readonly communicationWorkflowId = undefined;

    public static readonly inactiveAfterDuration = undefined;

    public static readonly closedAfterDuration = undefined;

    public static readonly availableTimes = timeSelectionAlwaysInclude;

    public static readonly pIIRetentionDuration = 'PT0D';

    public static readonly redirectURL = undefined;

    public static readonly waitingRoomConfig = ConversationWaitingRoomConfig.deserialize(
      conversationWaitingRoomConfigDefault,
    );

    public static readonly identityConfig = defaultIdentityConfigConversation;

    public static readonly phones = undefined;

    public static readonly messagingServiceSid = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Default Values
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([
      ConversationConfigSchema.identityConfig,
      ConversationConfigSchema.availableTimes,
      ConversationConfigSchema.pIIRetentionDuration,
      ConversationConfigSchema.serviceProviders,
    ]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    parameters[ConversationConfigSchema.chatServiceSid] = SchemaField.string(
      ConversationConfigSchema.Defaults.chatServiceSid,
    );
    parameters[ConversationConfigSchema.onCannotProvideService] = SchemaField.shortObjArray(
      AnyVoiceResponseCommand,
      ConversationConfigSchema.Defaults.onCannotProvideService,
    );

    parameters[ConversationConfigSchema.serviceProviders] = SchemaField.shortObjArray(
      CalleeEntity,
      ConversationConfigSchema.Defaults.serviceProviders,
    );
    parameters[ConversationConfigSchema.roleSidAnonymousParticipant] = SchemaField.string(
      ConversationConfigSchema.Defaults.roleSidAnonymousParticipant,
    ).required();
    parameters[ConversationConfigSchema.roleSidInternalParticipant] = SchemaField.string(
      ConversationConfigSchema.Defaults.roleSidInternalParticipant,
    ).required();

    parameters[ConversationConfigSchema.communicationWorkflowId] = SchemaField.autoGeneratedDocId(
      ConversationConfigSchema.Defaults.communicationWorkflowId,
    ).optional();
    parameters[ConversationConfigSchema.inactiveAfterDuration] = SchemaField.isoDuration(
      ConversationConfigSchema.Defaults.inactiveAfterDuration,
    );
    parameters[ConversationConfigSchema.closedAfterDuration] = SchemaField.isoDuration(
      ConversationConfigSchema.Defaults.closedAfterDuration,
    );
    parameters[ConversationConfigSchema.availableTimes] = SchemaField.obj(
      TimeSelection,
      ConversationConfigSchema.Defaults.availableTimes,
    );
    parameters[ConversationConfigSchema.pIIRetentionDuration] = SchemaField.isoDuration(
      ConversationConfigSchema.Defaults.pIIRetentionDuration,
    );

    parameters[ConversationConfigSchema.redirectURL] = SchemaField.uri(ConversationConfigSchema.Defaults.redirectURL);
    parameters[ConversationConfigSchema.waitingRoomConfig] = SchemaField.obj(
      ConversationWaitingRoomConfig,
      ConversationConfigSchema.Defaults.waitingRoomConfig,
    );
    parameters[ConversationConfigSchema.identityConfig] = SchemaField.obj(
      IdentityConfigConversation,
      ConversationConfigSchema.Defaults.identityConfig,
    );
    parameters[ConversationConfigSchema.phones] = SchemaField.arrayOfType(
      SchemaField.string(undefined),
      ConversationConfigSchema.Defaults.phones,
    ).optional();
    parameters[ConversationConfigSchema.messagingServiceSid] = SchemaField.string(
      ConversationConfigSchema.Defaults.messagingServiceSid,
    ).optional();

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${ConversationConfigSchema.Constants.collection}`;
  }
}
