import { cloneDeep } from 'lodash';

import { CommunicationTaskQueue, CommunicationTaskQueueSchema } from '@pwp-common';

import { editorOutputToTaskQueueWorkerLists } from '../../../../../../workers-autocomplete/editor-output/task-queue/editor-output-to-task-queue-worker-lists/editor-output-to-task-queue-worker-lists';
import { reservationOfferConfigEditorOutputToDb } from '../../../reservation-offer-config/db/reservation-offer-config-editor-output-to-db/reservation-offer-config-editor-output-to-db';
import { TargetEditorOutput } from '../../target-editor-output';

import { defaultCommunicationTaskQueue } from './default-communication-task-queue';

export const targetEditorOutputToDb = (params: {
  originalTaskQueue: CommunicationTaskQueue | undefined;
  targetEditorOutput: TargetEditorOutput;
}): CommunicationTaskQueue => {
  const taskQueue = params.originalTaskQueue ?? cloneDeep(defaultCommunicationTaskQueue);

  // Reservation Config
  const reservationConfig = reservationOfferConfigEditorOutputToDb({
    channelConfig: params.targetEditorOutput.channelConfig,
    offers: params.targetEditorOutput.offers,
    skipOffersIf: params.targetEditorOutput.skipOffersIf,
  });

  // Task Queue
  taskQueue.setId(params.targetEditorOutput.communicationTaskQueueId);
  taskQueue.setField(
    CommunicationTaskQueueSchema.workers,
    editorOutputToTaskQueueWorkerLists(params.targetEditorOutput.workers),
  );
  taskQueue.setField(
    CommunicationTaskQueueSchema.maxReservedWorkers,
    params.targetEditorOutput.mode.maxReservedWorkers,
  );
  taskQueue.setField(CommunicationTaskQueueSchema.reservationOfferConfig, reservationConfig);

  return taskQueue;
};
