import { values } from 'lodash';

import { SchemaField } from '../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../generic/serialization/serializable-object-schema';
import { ConversationUserIdentityType } from '../conversation-participant-summary/conversation-participant-id-type';

export class ConversationUserAttributesSchema extends SerializableObjectSchema {
  public static readonly identityType = 'identityType';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly identityType = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[ConversationUserAttributesSchema.identityType] = SchemaField.string(
      ConversationUserAttributesSchema.Defaults.identityType,
    )
      .valid(...values(ConversationUserIdentityType))
      .required();
    /* eslint-enable */

    return parameters;
  }
}
