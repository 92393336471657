import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';

import { SerializableObject } from '../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';
import { TimeSelectionItem } from '../time-selection-item/time-selection-item';

import { TimeSelectionConstructor } from './time-selection-constructor';
import { TimeSelectionSchema } from './time-selection-schema';

export class TimeSelection extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected items!: TimeSelectionItem[];

  protected includeByDefault!: boolean;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: TimeSelectionConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): TimeSelection {
    return new TimeSelection(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(TimeSelection.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new TimeSelectionSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Return a new TimeSelection that is the set-theoretic complement of this one.
   */
  public getComplement(): TimeSelection {
    const items = this.getItems().map((item) => item.getComplement());
    return new TimeSelection({
      items,
      includeByDefault: !this.getIncludeByDefault(),
    });
  }

  public getItems(): TimeSelectionItem[] {
    return cloneDeep(this.items);
  }

  public getIncludeByDefault(): boolean {
    return cloneDeep(this.includeByDefault);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Enabled At Time
  /////////////////////////////////////////////////////////////////////////////

  public doesInclude(timestamp: moment.Moment) {
    let include = this.getIncludeByDefault();
    for (const item of this.items) {
      include = item.doesInclude(timestamp, include);
    }

    return include;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Is Always
  /////////////////////////////////////////////////////////////////////////////

  public willAlways(include: boolean) {
    if (this.items.length !== 0) {
      return false;
    }

    return this.includeByDefault === include;
  }
}
