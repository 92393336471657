import { cloneDeep } from 'lodash';

import { DBDocObject } from '../db-doc/db-doc-object';
import { DBDocSchema } from '../db-doc/db-doc-schema';

import { GenericDisplayableConstructor } from './generic-displayable-constructor';

export abstract class GenericDisplayable extends DBDocObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected description!: string;

  protected displayName!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: GenericDisplayableConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  static getSchema(): DBDocSchema {
    throw new Error('GenericDialedCallLog.getSchema: UserError: This function should never be called.');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getDisplayName(): string {
    return cloneDeep(this.displayName);
  }

  public getDescription(): string {
    return cloneDeep(this.description);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  public setDescription(description: string): this {
    this.description = description;
    return this;
  }

  public setDisplayName(displayName: string): this {
    this.displayName = displayName;
    return this;
  }
}
