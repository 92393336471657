import { SchemaField } from '../../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../../generic/serialization/serializable-object-schema';
import { CallListOfferChannelConfigPhoneCall } from '../call-list-offer-channel-config-phone-call/call-list-offer-channel-config-phone-call';
import { CallListOfferChannelConfigPhoneCallSchema } from '../call-list-offer-channel-config-phone-call/call-list-offer-channel-config-phone-call-schema';

export class CallListOfferConfigSchema extends SerializableObjectSchema {
  public static readonly offerChannelConfigPhoneCall = 'offerChannelConfigPhoneCall';

  static Defaults = class {
    public static readonly offerChannelConfigPhoneCall = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[CallListOfferConfigSchema.offerChannelConfigPhoneCall] = SchemaField.obj(
      CallListOfferChannelConfigPhoneCall,
      CallListOfferChannelConfigPhoneCallSchema.Defaults.callerIdStrategy,
    ).required();
    /* eslint-enable */

    return parameters;
  }
}
