<!-- Required to configure toast notifications -->
<p-toast position="bottom-center"></p-toast>

<p-confirmDialog icon="pi pi-exclamation-triangle"></p-confirmDialog>

<mat-card>
  <!-- Header -->
  <mat-card-header>
    <mat-card-title>
      <legend>Select and Edit {{ label }}</legend>
    </mat-card-title>
  </mat-card-header>

  <!-- Content -->
  <mat-card-content>
    <div class="container">
      <div class="row">
        <div class="col">
          <app-obj-select
            [kvPair]="selectedKVPair"
            [label]="label"
            [orderedItems]="orderedItems"
            (kvPairChange)="_selectedKVPairChange($event)"
          ></app-obj-select>
        </div>
      </div>
    </div>

    <div [hidden]="selectedKVPair === undefined">
      <ng-container
        [ngTemplateOutlet]="editorTemplate"
        [ngTemplateOutletContext]="{ $implicit: selectedKVPair, item: selectedKVPair?.value, id: selectedKVPair?.id }"
      >
      </ng-container>
    </div>

    <!-- Is Valid Indicator -->
    <app-obj-is-valid *ngIf="selectedKVPair !== undefined" [isValidObj]="objIsValid"></app-obj-is-valid>

    <!-- Debug View -->
    <app-obj-debug-view
      *ngIf="modifiedSelectedItem !== undefined"
      class="content"
      [item]="modifiedSelectedItem"
    ></app-obj-debug-view>
  </mat-card-content>

  <!-- Actions -->
  <mat-card-actions>
    <button
      *ngIf="doUpload !== undefined"
      class="p-button-text"
      label="Upload"
      pButton
      [disabled]="uploadInProcess || selectedKVPair === undefined || !objIsValid()"
      [loading]="uploadInProcess"
      (click)="_uploadClick()"
    ></button>
    <button
      *ngIf="duplicateClick.observers.length > 0"
      class="p-button-text"
      label="Duplicate"
      pButton
      [disabled]="selectedKVPair === undefined"
      (click)="_duplicateClick()"
    ></button>
    <button class="p-button-text p-button-secondary" label="New" pButton (click)="_newClick()"></button>
    <button
      *ngIf="doDelete !== undefined"
      class="p-button-text p-button-danger"
      label="Delete"
      pButton
      [disabled]="deleteInProgress || selectedKVPair === undefined"
      [loading]="deleteInProgress"
      (click)="_deleteClick()"
    ></button>
    <button
      *ngIf="doRefresh !== undefined"
      class="p-button-text"
      label="Refresh"
      pButton
      [disabled]="refreshInProgress"
      [loading]="refreshInProgress"
      (click)="_refreshClick()"
    ></button>
  </mat-card-actions>
</mat-card>
