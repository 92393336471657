import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import {
  EventRequest,
  EventRequestName,
  EventRequestTransaction,
  EventRequestTransactionItem,
  EventRequestTransactionItemType,
  EventRequestTransactionResponse,
} from '@pwp-common';

import { CallableFunctionService } from '../../generic/callable-function-service/callable-function-service';

@Injectable({
  providedIn: 'root',
})
export class EventTransactionService extends CallableFunctionService<
  EventRequestTransaction,
  EventRequestTransactionResponse
> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(
    fns: AngularFireFunctions,
    private db: AngularFirestore,
  ) {
    super(EventRequestTransactionResponse, fns);
  }

  ///////////////////////////////////////////////////////////////////////
  // Function Name
  ///////////////////////////////////////////////////////////////////////

  public getFunctionName() {
    return 'eventRequest';
  }

  public async makeEventRequestTransaction(requests: EventRequest[]) {
    const itemsArray: EventRequestTransactionItem[] = requests.map(
      (item) =>
        new EventRequestTransactionItem({
          type: EventRequestTransactionItemType.assignment,
          eventId: item.getEventId(),
          requested: item.getRequestedSlot(),
          eventRequestId: this.db.createId(),
        }),
    );

    const requestEventRequestTransaction: EventRequestTransaction = new EventRequestTransaction({
      type: EventRequestName.eventRequestTransaction,
      items: itemsArray,
    });
    return (await this.makeRequest(requestEventRequestTransaction)) as EventRequestTransactionResponse;
  }
}
