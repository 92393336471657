<ng-container *transloco="let t; read: 'automation-table-editor'">
  <app-data-table
    [actionsTemplate]="actions"
    [cols]="cols"
    [expansionRow]="expansionRow"
    [selectedCols]="cols"
    [tableRows]="tableRows$"
    [translationScope]="'automation-table-editor'"
  >
    <ng-template #actions>
      <button
        icon="pi pi-plus"
        pButton
        styleClass="p-button-outlined"
        [label]="t('createAutomationButton.label')"
        (click)="openNewAutomationDialog()"
      ></button>
    </ng-template>
    <ng-template #expansionRow let-rowData>
      <app-automation-editor
        [automation]="rowData.automation"
        [mode]="'update'"
        (saved)="updateWithSuccess()"
      ></app-automation-editor>
    </ng-template>
  </app-data-table>
</ng-container>
