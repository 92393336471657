<plotly-plot
  [config]="{
    displaylogo: false,
    modeBarButtonsToRemove: ['hoverClosestPie'],
  }"
  [data]="[
    {
      values: labeledData?.getData() || [],
      labels: labeledData?.getLabels() || [],
      type: 'pie',
    },
  ]"
  [layout]="{
    showlegend: true,
    legend: { orientation: 'h' },
  }"
  [style]="{ position: 'relative', width: '100%', height: '100vh' }"
  [useResizeHandler]="true"
>
</plotly-plot>
