import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ControlValueAccessor, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { RadioButtonModule } from 'primeng/radiobutton';

import { AssignedUserType, canRequestSlot, EventRequestConfig } from '@pwp-common';

import { provideValueAccessor } from '../../../../common/form/provide-value-accessor';
import { NgChanges } from '../../../../common/objects/ng-changes';
import { EventRequestTableRow } from '../event-request-table-row/event-request-table-row';

// @Watch https://github.com/primefaces/primeng/issues/9733
// PrimeNG radio button can't be disabled with reactive forms since v15

@Component({
  selector: 'app-requested-slot-select',
  standalone: true,
  imports: [CommonModule, FormsModule, RadioButtonModule, ReactiveFormsModule, TranslocoModule],
  providers: [provideValueAccessor(RequestedSlotSelectComponent)],
  templateUrl: './requested-slot-select.component.html',
  styleUrls: ['./requested-slot-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestedSlotSelectComponent implements OnChanges, ControlValueAccessor {
  private onChange: (value: AssignedUserType) => void;

  private onTouched: () => void;

  public selectedSlot: AssignedUserType;

  @Input() public eventRequestConfig: EventRequestConfig;

  @Input() public rowData: EventRequestTableRow;

  public requestableSlots: Record<AssignedUserType, boolean> = {
    backup: false,
    primary: false,
  };

  public readonly assignedUserType = AssignedUserType;

  public ngOnChanges(changes: NgChanges<RequestedSlotSelectComponent>): void {
    if (changes.eventRequestConfig || changes.rowData) {
      const commonCanRequestSlotParams = {
        eventData: this.rowData.allDataEvent.getEventData(),
        eventRequestConfig: this.eventRequestConfig,
      };

      this.requestableSlots = {
        primary: canRequestSlot({ ...commonCanRequestSlotParams, slot: AssignedUserType.primary }),
        backup: canRequestSlot({ ...commonCanRequestSlotParams, slot: AssignedUserType.backup }),
      };
    }
  }

  public writeValue(obj: AssignedUserType): void {
    this.selectedSlot = obj;
  }

  public registerOnChange(fn: (value: AssignedUserType) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public reflectSlotChange(slot: AssignedUserType): void {
    this.onTouched();
    this.onChange(slot);
  }
}
