import { cloneDeep } from 'lodash';
import { Mixin } from 'ts-mixer';

import { DBDocSchema } from '../../../../../../../generic/db-doc/db-doc-schema';
import { SentMessage } from '../../../../../../../message/sent-message/sent-message';
import { CommunicationSessionEventLog } from '../../../../generic/communication-session-event-log/communication-session-event-log';
import { CommunicationSessionEventLogSchema } from '../../../../generic/communication-session-event-log/communication-session-event-log-schema';
import { CommunicationSessionEventLogName } from '../../../../generic/communication-session-event-log-name';
import { CommunicationSessionEventLogSendMessageType } from '../communication-session-event-log-send-message-type';

import { CommunicationSessionEventLogSendMessageConstructor } from './communication-session-event-log-send-message-constructor';

export abstract class CommunicationSessionEventLogSendMessage extends Mixin(CommunicationSessionEventLog, SentMessage) {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected messageType!: CommunicationSessionEventLogSendMessageType;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationSessionEventLogSendMessageConstructor) {
    (parameters as any)[CommunicationSessionEventLogSchema.type] = CommunicationSessionEventLogName.sendMessage;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    throw new Error('Error: Cannot get schema for abstract CommunicationSessionEventLogDialPhone');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Tracking Info
   */
  public getMessageType() {
    return cloneDeep(this.messageType);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Did Dial
  /////////////////////////////////////////////////////////////////////////////

  public didDial(): boolean {
    return true;
  }
}
