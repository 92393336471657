import { isNil, upperFirst } from 'lodash';

import {
  CommunicationSessionEventLogSendMessage,
  displayTime,
  OperatorMessageStatus,
  UserData,
  UserStatus,
} from '@pwp-common';

import { RowCell } from '../../../../common/p-table/row-cell';
import { StatusBadgeColor } from '../../../shared/status-badge/status-badge-color';

import { CommunicationSessionEventBase } from './communication-session-event-base';

export class CommunicationSessionSendMessageLogBase<
  T extends CommunicationSessionEventLogSendMessage,
> extends CommunicationSessionEventBase<T> {
  //////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  //////////////////////////////////////////////////////////////////////////////////////////

  messageSentFrom: RowCell<string>;

  messageSentTimestamp: RowCell<Date>;

  messageDeliveredTimestamp: RowCell<Date>;

  operatorMessageStatus: RowCell<string>;

  //////////////////////////////////////////////////////////////////////////////////////////
  // Constructor
  //////////////////////////////////////////////////////////////////////////////////////////

  constructor({
    event,
    allDataUser,
    timezone,
    userId,
    userStatus,
  }: {
    event: T;
    allDataUser: Map<string, UserData>;
    timezone: string;
    userId?: string;
    userStatus?: UserStatus;
  }) {
    super({
      channel: 'text',
      sid: event.getMessageSid(),
      doc: event,
      allDataUser,
      timezone,
      userId,
      userStatus,
    });
    this.setMessageSentFrom(event);
    this.setMessageSentTimestamp(event, timezone);
    this.setMessageDeliveredTimestamp(event, timezone);
    this.setOperatorMessageStatus(event);
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Origination Reason
  //////////////////////////////////////////////////////////////////////////////////////////
  protected setOriginationReason() {
    this.originationReason = new RowCell<string>({
      translationScope: 'communication-session-event-row',
      translationKey: 'cellOriginationReasonSentMessage',
      translationObj: { ...this.userDisplayNameWithEmail?.translationObj },
      sortValue: 'cellOriginationReasonSentMessage',
      styleClass: undefined,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Message Sent From
  //////////////////////////////////////////////////////////////////////////////////////////
  protected setMessageSentFrom(event: T) {
    const from = event.getFrom();
    this.messageSentFrom = new RowCell<string>({
      translationScope: 'communication-session-event-row',
      translationKey: 'cellMessageSentFrom',
      translationObj: { from },
      sortValue: from,
      styleClass: undefined,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Operator Timestamps
  //////////////////////////////////////////////////////////////////////////////////////////
  private setMessageSentTimestamp(event: T, timezone: string) {
    const messageSentTimestamp = event.getOperatorTimestamps().get(OperatorMessageStatus.sent);
    if (isNil(messageSentTimestamp)) {
      this.messageSentTimestamp = new RowCell<Date>({
        translationScope: 'communication-session-event-row',
        translationKey: 'cellMessageSentTimestampMissing',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    this.messageSentTimestamp = new RowCell<Date>({
      translationScope: 'communication-session-event-row',
      translationKey: 'cellMessageSentTimestamp',
      translationObj: {
        timestamp: displayTime(messageSentTimestamp, timezone, { timeOnly: true, showSeconds: true }),
      },
      sortValue: messageSentTimestamp.toDate(),
    });
  }

  private setMessageDeliveredTimestamp(event: T, timezone: string) {
    const messageDeliveredTimestamp = event.getOperatorTimestamps().get(OperatorMessageStatus.delivered);
    if (isNil(messageDeliveredTimestamp)) {
      this.messageDeliveredTimestamp = new RowCell<Date>({
        translationScope: 'communication-session-event-row',
        translationKey: 'cellMessageDeliveredTimestampMissing',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    this.messageDeliveredTimestamp = new RowCell<Date>({
      translationScope: 'communication-session-event-row',
      translationKey: 'cellMessageDeliveredTimestamp',
      translationObj: {
        timestamp: displayTime(messageDeliveredTimestamp, timezone, { timeOnly: true, showSeconds: true }),
      },
      sortValue: messageDeliveredTimestamp.toDate(),
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Summary
  //////////////////////////////////////////////////////////////////////////////////////////

  private getSummaryError(errorCode: number): RowCell<string> {
    /**
     * List of all possible error codes here:
     * https://support.twilio.com/hc/en-us/articles/223181868-Troubleshooting-Undelivered-Twilio-SMS-Messages
     */
    const translationKey = `cellSummaryError${errorCode}`;
    return new RowCell<string>({
      translationScope: 'communication-session-event-row',
      translationKey,
      translationObj: {
        errorCode: errorCode.toString(),
      },
      sortValue: translationKey,
      statusBadgeColor: StatusBadgeColor.yellow,
    });
  }

  protected setSummary(event: T, userStatus?: UserStatus) {
    /**
     * Case: Handle all messaging errors.
     *
     * https://support.twilio.com/hc/en-us/articles/223181868-Troubleshooting-Undelivered-Twilio-SMS-Messages
     */
    if (!isNil(event.getErrorCode())) {
      this.summary = this.getSummaryError(event.getErrorCode());
      return;
    }

    //////////////////////////////////////////////////////////////////////
    // Summary based on Operator Status
    //////////////////////////////////////////////////////////////////////

    const eventStatus = event.getOperatorMessageStatus();
    const operatorMessageStatusCSS = this.getOperatorMessageStatusCSS(event);

    const translationKey = `cellSummaryOperatorMessageStatus${upperFirst(eventStatus)}`;
    this.summary = new RowCell<string>({
      translationScope: 'communication-session-event-row',
      translationKey,
      sortValue: translationKey,
      statusBadgeColor: operatorMessageStatusCSS,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Operator Message Status
  //////////////////////////////////////////////////////////////////////////////////////////

  private getOperatorMessageStatusCSS(event: T): StatusBadgeColor | undefined {
    const operatorMessageStatus = event.getOperatorMessageStatus();
    if ([OperatorMessageStatus.sent, OperatorMessageStatus.delivered].includes(operatorMessageStatus)) {
      return StatusBadgeColor.green;
    }
    if ([OperatorMessageStatus.failed, OperatorMessageStatus.undelivered].includes(operatorMessageStatus)) {
      return StatusBadgeColor.yellow;
    }
    return undefined;
  }

  protected setOperatorMessageStatus(event: T) {
    const operatorMessageStatus = event.getOperatorMessageStatus();

    const translationKey = `cellOperatorMessageStatus${upperFirst(operatorMessageStatus)}`;
    this.operatorMessageStatus = new RowCell<string>({
      translationScope: 'communication-session-event-row',
      translationKey,
      sortValue: translationKey,
    });

    this.operatorMessageStatus.statusBadgeColor = this.getOperatorMessageStatusCSS(event);
  }
}
