import type { ValidationResult } from 'joi';
import { cloneDeep } from 'lodash';

import { PossibleAdminRole } from '../../../../../objects/user/admin-roles/types';
import { PossibleRole } from '../../../../../objects/user/roles/types';
import { UserRequestName } from '../../../user-request-name';
import { UserRequest } from '../../generic/user-request/user-request';
import { UserRequestSchema } from '../../generic/user-request/user-request-schema';

import { UserRequestUpdateUserConstructor } from './user-request-update-user-constructor';
import { UserRequestUpdateUserSchema } from './user-request-update-user-schema';

export class UserRequestUpdateUser extends UserRequest {
  protected password!: string;

  protected userId!: string;

  constructor(parameters: UserRequestUpdateUserConstructor) {
    super({
      ...parameters,
      [UserRequestSchema.type]: UserRequestName.updateUser,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  protected static _deserialize(validationResult: ValidationResult): UserRequestUpdateUser {
    return new UserRequestUpdateUser(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): UserRequestUpdateUserSchema {
    return new UserRequestUpdateUserSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(UserRequestUpdateUser.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Roles
  /////////////////////////////////////////////////////////////////////////////

  public getRequiredRoles(): PossibleRole[] {
    return ['orgAdmin'];
  }

  public getRequiredAdminRoles(): PossibleAdminRole[] | undefined {
    return undefined;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getPassword() {
    return cloneDeep(this.password);
  }

  public getUserId() {
    return cloneDeep(this.userId);
  }
}
