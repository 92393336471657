<ng-template #doneTemplate>
  <ng-container *transloco="let t; read: 'conversations-page'">
    <!-- Feature Off -->
    <app-conversations-feature-not-enabled
      *ngIf="(conversationConfig | async) === undefined"
    ></app-conversations-feature-not-enabled>

    <!-- Feature On -->
    <p-tabView *ngIf="(conversationConfig | async) !== undefined">
      <p-tabPanel header="{{ t('conversations') }}">
        <app-conversation-logs-query-editor></app-conversation-logs-query-editor>
      </p-tabPanel>
      <p-tabPanel *appIfRole="'orgAdmin'" header="{{ t('settings') }}">
        <app-conversation-settings></app-conversation-settings>
      </p-tabPanel>
    </p-tabView>
  </ng-container>
</ng-template>

<app-loading [doneTemplate]="doneTemplate" [loader]="loader"></app-loading>
