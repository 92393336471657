import { cloneDeep } from 'lodash';

import { Displayable } from '../../generic/db-doc/displayable';
import { SerializableObject } from '../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../generic/serialization/serializable-object-schema';

import { EventTypeConstructor } from './event-type-constructor';
import { EventTypeSchema } from './event-type-schema';

export class EventType extends SerializableObject implements Displayable {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  private internalName!: string;

  private displayName!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: EventTypeConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): EventType {
    return new EventType(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(EventType.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new EventTypeSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  /**
   * The internal name is a unique identifier of the event.
   */
  public getId(): string {
    return cloneDeep(this.internalName);
  }

  public getInternalName(): string {
    return cloneDeep(this.internalName);
  }

  public getDisplayName(): string {
    return cloneDeep(this.displayName);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////
}
