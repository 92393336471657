<ng-container *transloco="let t; read: 'workers-autocomplete'">
  <span class="p-float-label">
    <p-autoComplete
      appendTo="body"
      id="workersAutocomplete"
      [dropdown]="true"
      dropdownAriaLabel="{{ t('autocompleteDropdownLabel') }}"
      [formControl]="control"
      [multiple]="true"
      [ngClass]="{ 'ng-invalid': control?.invalid }"
      [suggestions]="suggestedOptions$ | async"
      [unique]="unique"
      (completeMethod)="search($event)"
    >
      <ng-template pTemplate="item" let-value>
        <div class="row">
          <div class="col flex justify-content-start align-items-end flex-wrap">
            <i *ngIf="value.type === 'user'" class="pi pi-user"></i>
            <i *ngIf="value.type === 'event'" class="pi pi-calendar"></i>
          </div>
          <div class="col flex justify-content-end flex-wrap">
            {{ value.displayName }}
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="selectedItem" let-value>
        <div class="row">
          <div class="col flex justify-content-start align-items-end flex-wrap">
            <i *ngIf="value.type === 'user'" class="pi pi-user"></i>
            <i *ngIf="value.type === 'event'" class="pi pi-calendar"></i>
            <i *ngIf="value.type === 'deletedUser'" class="p-error pi pi-flag-fill"></i>
            <i *ngIf="value.type === 'deletedEvent'" class="p-error pi pi-flag-fill"></i>
          </div>
          <div class="col flex justify-content-end flex-wrap">
            {{ value.displayName }}
          </div>
        </div>
      </ng-template>
    </p-autoComplete>
    <label for="workersAutocomplete">{{ label }}</label>
    <p *ngIf="control.hasError('noDeletedWorkers')" class="p-error">{{ t('controlErrorNoDeletedWorkers') }}</p>
  </span>
</ng-container>
