import { cloneDeep } from 'lodash';

import { MessageDeliveryChannel } from '../message-template/channel';

import { SMS_COMPLIANCE_FOOTER } from './constants';

/**
 * This class defines a message that will be submitted to a service provider (Twilio or SendGrid)
 * to be immediately to be sent to a particular user.
 *
 * Note that messages are sent to a user (and we don't support sending messages
 * to a specific email/phone if that info isn't tied to a particular user).
 */
export class RenderedTemplate {
  private channel: MessageDeliveryChannel;

  private subject: string;

  private body: string;

  private targetUserId: string;

  private cc: string[];

  private bcc: string[];

  private replyTo: string | undefined;

  constructor({
    channel,
    subject,
    body,
    targetUserId,
    cc,
    bcc,
    replyTo,
  }: {
    channel: MessageDeliveryChannel;
    subject: string;
    body: string;
    targetUserId: string;
    cc: string[];
    bcc: string[];
    replyTo: string | undefined;
  }) {
    this.channel = channel;
    this.subject = subject;
    this.body = body;
    this.targetUserId = targetUserId;
    this.cc = cc;
    this.bcc = bcc;
    this.replyTo = replyTo;
  }

  //////////////////////////////////////////////////////////////////
  // Getters
  //////////////////////////////////////////////////////////////////

  public getChannel(): MessageDeliveryChannel {
    return cloneDeep(this.channel);
  }

  public getSubject(): string {
    return cloneDeep(this.subject);
  }

  /**
   * This method is private to prevent accidental use
   * another method adds a footer at the bottom of SMS messages
   * to ensure compliance.
   * @returns
   */
  private getBody(): string {
    return cloneDeep(this.body);
  }

  public getTargetUserId(): string {
    return cloneDeep(this.targetUserId);
  }

  public getCC(): string[] {
    return cloneDeep(this.cc);
  }

  public getBCC(): string[] {
    return cloneDeep(this.bcc);
  }

  public getReplyTo(): string | undefined {
    return cloneDeep(this.replyTo);
  }

  //////////////////////////////////////////////////////////////////
  // Compliance
  //////////////////////////////////////////////////////////////////

  public getCompliantBody(): string {
    const body = this.getBody();
    switch (this.channel) {
      case MessageDeliveryChannel.email: {
        return body;
      }
      case MessageDeliveryChannel.sms: {
        return `${body}\n${SMS_COMPLIANCE_FOOTER}`;
      }
    }
  }
}
