import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import moment from 'moment-timezone';

export const isBeforeControl =
  (controlName1: string, controlName2: string): ValidatorFn =>
  (formGroup: FormGroup) => {
    const control1 = formGroup.get(controlName1) as FormControl<Date>;
    const control2 = formGroup.get(controlName2) as FormControl<Date>;

    return moment(control1.value).isBefore(control2.value) ? null : { isBeforeControl: controlName1 };
  };
