import type { ValidationResult } from 'joi';

import { AnySerializableObject } from '../../../../generic/serialization/any-serializable-object/any-serializable-object';
import { ObjectBuilder } from '../../../../generic/serialization/interfaces';
import { AutomationName } from '../../automation-name';
import { AutomationOnCommunicationComplete } from '../../type/automation-on-communication-complete/automation-on-communication-complete';

import { AnyAutomationSchema } from './any-automation-schema';

export abstract class AnyAutomation extends AnySerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  protected static getObjectBuilder(validationResult: ValidationResult): ObjectBuilder {
    const typeString = validationResult.value[AnyAutomationSchema.type];
    const type = AutomationName[typeString as keyof typeof AutomationName];

    if (type === undefined) {
      throw new Error(`AnyAutomation._deserialize: User Error: Cannot deserialize obj with type=${typeString}`);
    }

    switch (type) {
      case AutomationName.onCommunicationComplete:
        return AutomationOnCommunicationComplete;
      default:
        throw new Error(`AnyAutomation._deserialize Unknown type: ${type}`);
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): AnyAutomationSchema {
    return new AnyAutomationSchema();
  }
}
