import { CommunicationSessionEventLogConferenceSchema } from '../../generic/communication-session-event-log-conference/communication-session-event-log-conference-schema';

export class CommunicationSessionEventLogConferenceStartSchema extends CommunicationSessionEventLogConferenceSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    ///////////////////////////////////////////////////////
    // Generic InboundCallEvent Properties, Copy/Pasted Here
    ///////////////////////////////////////////////////////

    public static readonly type = undefined;

    public static readonly actor = undefined;

    public static readonly timestamp = undefined;

    ///////////////////////////////////////////////////////
    // CommunicationSessionEventLogConference Properties
    ///////////////////////////////////////////////////////

    public static readonly conferenceEventType = undefined;

    public static readonly conferenceSid = undefined;

    public static readonly eventTimestamp = undefined;

    public static readonly friendlyName = undefined;

    public static readonly sequenceNumber = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    /* eslint-enable */

    return parameters;
  }
}
