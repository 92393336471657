import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { StatusBadgeColor } from './status-badge-color';

@Component({
  selector: 'app-status-badge',
  standalone: true,
  imports: [],
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': '"status-badge--" + color',
  },
})
export class StatusBadgeComponent {
  @Input() public color: StatusBadgeColor;
}
