import Joi from 'joi';

import { SerializableObjectSchema } from '../../generic/serialization/serializable-object-schema';
import { AddBlockedCallerSchema } from '../add-blocked-caller/add-blocked-caller-schema';
import { CreateAsyncServiceRequestSchema } from '../create-async-service-request/create-async-service-request-schema';
import { DialCallListSchema } from '../dial-call-list/dial-call-list-schema';
import { EnforceBlockedCallerSchema } from '../enforce-blocked-caller/enforce-blocked-caller-schema';
import { EnforceServiceExceptionsSchema } from '../enforce-service-exceptions/enforce-service-exceptions-schema';
import { EnforceServiceLimitSchema } from '../enforce-service-limit/enforce-service-limit-schema';
import { ExecEntrypointSchema } from '../exec-entrypoint/exec-entrypoint-schema';
import { ExecIVRSchema } from '../exec-ivr/exec-ivr-schema';
import { FlagServiceRequestSchema } from '../flag-service-request/flag-service-request-schema';
import { ForwardSchema } from '../forward/forward-schema';
import { HangupSchema } from '../hangup/hangup-schema';
import { JoinConferenceSchema } from '../join-conference/join-conference-schema';
import { PlaySchema } from '../play/play-schema';
import { PlayOrSaySchema } from '../play-or-say/play-or-say-schema';
import { SaySchema } from '../say/say-schema';
import { SendMessageTemplateSchema } from '../send-message-template/send-message-template-schema';
import { SetContextSchema } from '../set-context/set-context-schema';
import { SwitchSchema } from '../switch/switch/switch-schema';
import { TakeVoicemailSchema } from '../take-voicemail/take-voicemail-schema';
import { VRCIfTimeSchema } from '../voice-response-command-if-time/voice-response-command-if-time-schema';

export class AnyVoiceResponseCommandSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The name of the command
  public static readonly commandName = 'commandName';

  private static readonly SCHEMA_ID = 'anyVoiceResponseCommand';

  public static computedSchema: import('joi').AnySchema;

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static GenericDefaults = class {
    // The name of the command
    public static readonly commandName = 'missing-commandName';
  };

  /**
   * Always serialize the command name
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set(['commandName']);
  }

  public static getSchemaId(): string {
    return `#${AnyVoiceResponseCommandSchema.SCHEMA_ID}`;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } | import('joi').AnySchema {
    if (AnyVoiceResponseCommandSchema.computedSchema !== undefined) {
      return AnyVoiceResponseCommandSchema.computedSchema;
    }

    const commandSchemas = [
      new AddBlockedCallerSchema().getJoiSchema(),
      new CreateAsyncServiceRequestSchema().getJoiSchema(),
      new DialCallListSchema().getJoiSchema(),
      new EnforceBlockedCallerSchema().getJoiSchemaWithoutShared(),
      new EnforceServiceLimitSchema().getJoiSchema(),
      new EnforceServiceExceptionsSchema().getJoiSchema(),
      new ExecEntrypointSchema().getJoiSchema(),
      new ExecIVRSchema().getJoiSchema(),
      new ForwardSchema().getJoiSchema(),
      new FlagServiceRequestSchema().getJoiSchema(),
      new HangupSchema().getJoiSchema(),
      new VRCIfTimeSchema().getJoiSchema(),
      new JoinConferenceSchema().getJoiSchema(),
      new PlaySchema().getJoiSchema(),
      new PlayOrSaySchema().getJoiSchema(),
      new SendMessageTemplateSchema().getJoiSchema(),
      new SetContextSchema().getJoiSchema(),
      new SaySchema().getJoiSchema(),
      new SwitchSchema().getJoiSchemaWithoutShared(),
      new TakeVoicemailSchema().getJoiSchema(),
    ];
    AnyVoiceResponseCommandSchema.computedSchema = Joi.alternatives()
      .try(...commandSchemas)
      .id(AnyVoiceResponseCommandSchema.SCHEMA_ID);
    return AnyVoiceResponseCommandSchema.computedSchema;
  }
}
