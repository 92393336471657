/**
 * These statuses correspond 1:1 with the CallStatus field in Twilio's CallStatusCallback. They have been converted
 * from british to American English and to camelCase to be consistent with the rest of the codebase.
 *
 * These values are serialized to the database.
 * Possible call statuses are documented here:
 * https://support.twilio.com/hc/en-us/articles/223132547-What-are-the-Possible-Call-Statuses-and-What-do-They-Mean-
 */
export enum OperatorCallStatus {
  // This isn't in the docs, but that's an error
  initiated = 'initiated',
  // queued	The call is ready and waiting in line before going out.
  queued = 'queued',
  // ringing	The call is currently ringing.
  ringing = 'ringing',
  // in-progress	The call was answered and is actively in progress.
  inProgress = 'inProgress',
  // completed	The call was answered and has ended normally.
  completed = 'completed',
  // busy	The caller received a busy signal.
  busy = 'busy',
  // failed	The call could not be completed as dialed, most likely because the phone number was non-existent.
  failed = 'failed',
  // no-answer	The call ended without being answered.
  noAnswer = 'noAnswer',
  // canceled	The call was canceled via the REST API while queued or ringing.
  canceled = 'canceled',
}

const CURRENT_CALL_STATUSES = new Set([
  OperatorCallStatus.initiated,
  OperatorCallStatus.queued,
  OperatorCallStatus.ringing,
  OperatorCallStatus.inProgress,
]);
const COMPLETED_CALL_STATUSES = new Set([
  OperatorCallStatus.completed,
  OperatorCallStatus.failed,
  OperatorCallStatus.noAnswer,
  OperatorCallStatus.canceled,
  OperatorCallStatus.busy,
]);

export const operatorCallStatusIsComplete = (status: OperatorCallStatus): boolean =>
  COMPLETED_CALL_STATUSES.has(status);

export const operatorCallStatusIsCurrent = (status: OperatorCallStatus): boolean => CURRENT_CALL_STATUSES.has(status);
