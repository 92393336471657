import { Conversation, Message } from '@twilio/conversations';
import { isNil, sortedIndexBy } from 'lodash';
import pRetry from 'p-retry';
import { BehaviorSubject } from 'rxjs';
import { v4 } from 'uuid';

import { isUnsentMessage } from '../../../message/helper/is-unsent-message';
import { UnsentMessage } from '../unsent-message';

import { ConversationMessageState } from './interfaces';

export class MessageState {
  ///////////////////////////////////////////////////////////////////////////
  // Private Variables
  ///////////////////////////////////////////////////////////////////////////

  private conversationMessagesStates: ConversationMessageState[] = [];

  private receivedIndexIds: Set<string> = new Set();

  private unsentMessages: { [unsentMessageId: string]: UnsentMessage } = {};

  private thisParticipantSid: string;

  private lastReadMessageIndex: { [participantId: string]: number } = {};

  private lastSeenByEveryoneIndex = 0;

  private updateLastReadIndexPromises: { [updateId: string]: Promise<void> } = {};

  ///////////////////////////////////////////////////////////////////////////
  // Public Variables
  ///////////////////////////////////////////////////////////////////////////

  public $messagesLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public readonly conversationMessagesStates$: BehaviorSubject<ConversationMessageState[]> = new BehaviorSubject([]);

  ///////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////////

  constructor(
    private readonly conversation: Conversation,
    private retries: number = 10,
  ) {
    conversation.onWithReplay('participantUpdated', ({ participant, updateReasons }) => {
      if (!updateReasons.includes('lastReadMessageIndex')) {
        return;
      }
      this.updateReadHorizon(participant.sid, participant.lastReadMessageIndex);
      this.emitMessages();
    });

    this.maintainMessageState();

    conversation.on('messageUpdated', ({ message, updateReasons }) => {
      /**
       * SMS Delivery Receipts Not Implemented
       *
       * Delivery receipts for SMS participants are basically the same as saved receipts,
       * which we already have. For SMS participants, delivery receipts have the disadvantage
       * that messages will still come up as "delivered" if [the end device is in airplane
       * mode or out of receiption because they these receipts only indicate that the message
       * was received and accepted by the terminating operator, and not the end-user. We don't
       * know of a clear use case for having this data, and since showing this data could be
       * misleading we're going to wait on implementation until we see a clear advantage of
       * showing this information, and know how to show it in a useful way.
       */
      if (!updateReasons.includes('deliveryReceipt')) {
      }
    });
  }

  ///////////////////////////////////////////////////////////////////////////
  // Get Historical Messages
  ///////////////////////////////////////////////////////////////////////////

  private async getHistoricalMessages(): Promise<void> {
    const logMetadata = { sid: this.conversation.sid } as any;
    const messages: Message[] = [];
    logMetadata.messages = messages;

    let messagePage = await this.conversation.getMessages(30, undefined, 'forward');
    logMetadata.messages = messages;
    messages.push(...messagePage.items);

    while (messagePage.hasNextPage) {
      messagePage = await messagePage.nextPage();
      messages.push(...messagePage.items);
      logMetadata.messages = messages;
    }

    this.receive(messages);
    this.$messagesLoaded.next(true);
  }

  ///////////////////////////////////////////////////////////////////////////
  // Receive
  ///////////////////////////////////////////////////////////////////////////

  private maintainMessageState() {
    this.conversation.onWithReplay('messageAdded', (message) => {
      this.receive([message]);
    });

    // Get Historical Messages
    this.getHistoricalMessages().catch((error) => {
      console.error('MessageState.maintainMessageState: Unknown error getting historical messages');
      console.error(error);
      this.$messagesLoaded.next(false);
    });
  }

  private receive(newMessages: Message[]) {
    for (const newMessage of newMessages) {
      this.updateReadHorizon(this.thisParticipantSid, newMessage.index);

      // In order for messages to be read they have to be read by everyone that has sent a message
      this.updateReadHorizon(newMessage.participantSid, -1);
      const indexId = this.getIndexId(newMessage);
      const conversationMessageState = { message: newMessage, indexId };
      const insertionIndex = sortedIndexBy(
        this.conversationMessagesStates,
        conversationMessageState,
        (z) => z.message.index,
      );
      if (indexId !== undefined) {
        this.receivedIndexIds.add(indexId);
      }

      this.conversationMessagesStates.splice(insertionIndex, 0, conversationMessageState);
    }
    this.emitMessages();
  }

  ///////////////////////////////////////////////////////////////////////////
  // Setters
  ///////////////////////////////////////////////////////////////////////////

  public setThisParticipantSid(sid: string | undefined): MessageState {
    this.thisParticipantSid = sid;
    this.updateReadHorizon(sid, Math.max(...this.conversationMessagesStates.map((z) => z.message.index)));
    this.emitMessages();
    return this;
  }

  ///////////////////////////////////////////////////////////////////////////
  // Send
  ///////////////////////////////////////////////////////////////////////////

  public async sendAssumingJoined(body: string): Promise<void> {
    const logMetadata = { body } as any;
    const unsentMessage = this.conversation.prepareMessage().setBody(body).build();
    const unsentMessageId = v4();
    const participant = await this.conversation.getParticipantBySid(this.thisParticipantSid);

    const run = async (attemptNum: number) => {
      const index = await unsentMessage.send();
      logMetadata.index = index;
      logMetadata.attemptNum = attemptNum;
      console.log('MessageState.send', 'Message sent with index', logMetadata);
    };

    this.unsentMessages[unsentMessageId] = {
      id: unsentMessageId,
      participant,
      requestTime: new Date(),
      body,
      promise: undefined as any,
    };

    this.unsentMessages[unsentMessageId].promise = pRetry(run, {
      retries: this.retries,
      onFailedAttempt: (error) => {
        logMetadata.error = error;
        if (error.retriesLeft === 0) {
          this.messageSendFailed(unsentMessageId);
          console.warn('MessageState.send', 'Error sending message. No retries left.', logMetadata);
          return;
        }
        console.warn('MessageState.send', 'Error sending message, retrying ...', logMetadata);
      },
    }).catch((error) => {
      console.log(
        'MessageState.send',
        'Retries exhausted. Fatal error sending message. Nothing to do.',
        logMetadata,
        error,
      );
    });

    this.emitMessages();
  }

  private messageSendFailed(unsentMessageId: string) {
    this.unsentMessages[unsentMessageId].failure = true;
    this.emitMessages();
  }

  ///////////////////////////////////////////////////////////////////////////
  // Read Horizon
  ///////////////////////////////////////////////////////////////////////////

  private updateReadHorizon(participantSid: string, lastReadIndex: number) {
    if (isNil(participantSid)) {
      // Ignore this. Null is the participantId for system messages &
      // undefined is the default value of this.thisParticipantId.
      return;
    }

    if (
      !isNil(this.lastReadMessageIndex[this.thisParticipantSid]) &&
      lastReadIndex > this.lastReadMessageIndex[this.thisParticipantSid]
    ) {
      if (this.conversation.state.current !== 'closed') {
        const updatePromiseId = v4();
        this.updateLastReadIndexPromises[updatePromiseId] = this.conversation
          .advanceLastReadMessageIndex(lastReadIndex)
          .then((_) => {
            delete this.updateLastReadIndexPromises[updatePromiseId];
          })
          .catch((error) => {
            console.warn(
              'MessageState.updateThisParticipantReadHorizon: Ignoring error updating last read message index',
              error,
            );
          });
      }
    }

    if (participantSid === this.thisParticipantSid) {
      if (
        isNil(this.lastReadMessageIndex[participantSid]) ||
        this.lastReadMessageIndex[participantSid] < lastReadIndex
      ) {
        this.lastReadMessageIndex[participantSid] = lastReadIndex;
      }
    } else {
      this.lastReadMessageIndex[participantSid] = Math.max(
        this.lastReadMessageIndex[participantSid] ?? -1,
        lastReadIndex,
      );
    }

    // This user has seen all messages they have received, so ignore them in lastSeenByEveryoneIndex.
    const allExceptThisParticipant = Object.entries(this.lastReadMessageIndex).filter(
      (z) => z[0] !== this.thisParticipantSid,
    );
    if (allExceptThisParticipant.length !== 0) {
      this.lastSeenByEveryoneIndex = Math.min(
        ...Object.entries(this.lastReadMessageIndex)
          .filter((z) => z[0] !== this.thisParticipantSid)
          .map((z) => z[1]),
      );
    }
  }

  public wasSeenByEveryone(message: Message): boolean {
    return message.index <= this.lastSeenByEveryoneIndex;
  }

  ///////////////////////////////////////////////////////////////////////////
  // Display
  ///////////////////////////////////////////////////////////////////////////

  private getIndexId(message: Message) {
    if (message.participantSid !== this.thisParticipantSid) {
      return undefined;
    }

    for (const [unsentId, unsentMessage] of Object.entries(this.unsentMessages)) {
      if (unsentMessage.body === message.body) {
        delete this.unsentMessages[unsentId];
        return unsentId;
      }
    }
    return undefined;
  }

  private emitMessages(): void {
    const result = [...this.conversationMessagesStates];

    Object.entries(this.unsentMessages).forEach(([key, unsentMessage]) => {
      // Delete any unsent messages that have been successfully sent
      if (unsentMessage.index !== undefined || this.receivedIndexIds.has(key)) {
        delete this.unsentMessages[key];
        return;
      }

      const unsentMessageState: ConversationMessageState = { indexId: unsentMessage.id, message: unsentMessage };
      const getMessageCreateTime = ({ message }: ConversationMessageState) =>
        isUnsentMessage(message) ? message.requestTime.valueOf() : message.dateCreated.valueOf();

      const insertionIndex = sortedIndexBy(result, unsentMessageState, getMessageCreateTime);

      result.splice(insertionIndex, 0, unsentMessageState);
    });

    this.conversationMessagesStates$.next(result);
  }

  ///////////////////////////////////////////////////////////////////////////
  // Shutdown
  ///////////////////////////////////////////////////////////////////////////

  public shutdown(): void {
    this.conversationMessagesStates = [];
    this.receivedIndexIds = new Set();
    this.unsentMessages = {};
    this.thisParticipantSid = '';
    this.lastReadMessageIndex = {};
    this.lastSeenByEveryoneIndex = 0;
    this.updateLastReadIndexPromises = {};

    this.conversationMessagesStates$.complete();
    this.$messagesLoaded.complete();
  }
}
