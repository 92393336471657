<ng-container *transloco="let t; read: 'phone-line-table-editor'">
  <app-data-table
    [cols]="cols"
    [expansionRow]="expansionRow"
    [selectedCols]="cols"
    [tableRows]="tableRows$"
    [translationScope]="'phone-line-table-editor'"
  >
    <ng-template #expansionRow let-rowData>
      <app-phone-line-editor
        [callEntrypoint]="rowData.callEntrypoint"
        (saved)="updateWithSuccess()"
      ></app-phone-line-editor>
    </ng-template>
  </app-data-table>
</ng-container>
