import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { Observable } from 'rxjs';

import { makePTableCols } from '../../../common/p-table/make-p-table-cols';
import { PTableCol } from '../../../common/p-table/p-table-col';
import { DataTableRow } from '../../generic/data-table/common/data-table-row';
import { DataTableModule } from '../../generic/data-table/data-table.module';

@Component({
  selector: 'app-communication-logs-session-hunt',
  standalone: true,
  imports: [CommonModule, DataTableModule, TranslocoModule],
  templateUrl: './communication-logs-session-hunt.component.html',
  styleUrls: ['./communication-logs-session-hunt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunicationLogsSessionHuntComponent {
  public readonly cols = makePTableCols({
    translationScope: 'communication-logs-session-hunt',
    columns: [
      'messageSentFrom',
      'userDisplayNameWithEmail',
      'createDate',
      'originationReason',
      'initiatedTimestamp',
      'ringingTimestamp',
      'answeredTimestamp',
      'completedTimestamp',
      'userStatus',
      'dialStatus',
      'ringDuration',
      'talkDuration',
      'messageSentTimestamp',
      'messageDeliveredTimestamp',
      'connectedToClient',
      'sid',
      'summary',
      'operatorMessageStatus',
    ],
  });

  public readonly selectedCols: PTableCol[] = this.cols.filter((col) =>
    ['createDate', 'originationReason', 'userStatus', 'talkDuration', 'completedTimestamp', 'summary'].includes(
      col.field,
    ),
  );

  @Input() public tableRows$: Observable<DataTableRow[]>;

  @Input() public tableTitle: string;
}
