import { cloneDeep } from 'lodash';

import { CommunicationSessionEventLogDialPhone } from '../../generic/communication-session-event-log-dial-phone/communication-session-event-log-dial-phone';
import { CommunicationSessionEventLogDialPhoneSchema } from '../../generic/communication-session-event-log-dial-phone/communication-session-event-log-dial-phone-schema';
import { CommunicationSessionEventLogDialPhoneCallType } from '../../generic/communication-session-event-log-dial-phone-call-type';

import { CommunicationSessionEventLogDialAsyncServiceRequestDeliveryConstructor } from './communication-session-event-log-dial-async-service-request-delivery-constructor';
import { CommunicationSessionEventLogDialAsyncServiceRequestDeliverySchema } from './communication-session-event-log-dial-async-service-request-delivery-schema';

export class CommunicationSessionEventLogDialAsyncServiceRequestDelivery extends CommunicationSessionEventLogDialPhone {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected asyncServiceRequestId!: string | undefined;

  protected requestedByUserId!: string | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationSessionEventLogDialAsyncServiceRequestDeliveryConstructor) {
    (parameters as any)[CommunicationSessionEventLogDialPhoneSchema.callType] =
      CommunicationSessionEventLogDialPhoneCallType.asyncServiceRequestDelivery;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(
    validationResult: import('joi').ValidationResult,
  ): CommunicationSessionEventLogDialAsyncServiceRequestDelivery {
    return new CommunicationSessionEventLogDialAsyncServiceRequestDelivery(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationSessionEventLogDialAsyncServiceRequestDelivery.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): CommunicationSessionEventLogDialAsyncServiceRequestDeliverySchema {
    return new CommunicationSessionEventLogDialAsyncServiceRequestDeliverySchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This value should always be defined. It is marked as optional because
   * the log is written to the DB in parts, and this field may be missing depending
   * on which part of the log is written first.
   */
  public getAsyncServiceRequestId() {
    return cloneDeep(this.asyncServiceRequestId);
  }

  public getRequestedByUserId() {
    return cloneDeep(this.requestedByUserId);
  }
}
