import { SchemaField } from '../../../../../generic/serialization/schema-field';
import { DBMatchRuleSchema } from '../../generic/db-match-rule/db-match-rule-schema';

export class DBMatchRuleAnonymousPhoneSchema extends DBMatchRuleSchema {
  public static readonly anonymousPhonesDigitsOnly = 'anonymousPhonesDigitsOnly';

  public static readonly receivedAtE164Phones = 'receivedAtE164Phones';

  public static readonly forwardedFromE164Phones = 'forwardedFromE164Phones';

  public static readonly x5us = 'x5us';

  public static readonly origIds = 'origIds';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;

    public static readonly anonymousPhonesDigitsOnly = undefined;

    public static readonly receivedAtE164Phones = [];

    public static readonly forwardedFromE164Phones = [];

    public static readonly x5us = [];

    public static readonly origIds = [];
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([
      DBMatchRuleAnonymousPhoneSchema.receivedAtE164Phones,
      DBMatchRuleAnonymousPhoneSchema.forwardedFromE164Phones,
      DBMatchRuleAnonymousPhoneSchema.x5us,
      DBMatchRuleAnonymousPhoneSchema.origIds,
    ]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[DBMatchRuleAnonymousPhoneSchema.anonymousPhonesDigitsOnly] = SchemaField.shortStringArray(
      DBMatchRuleAnonymousPhoneSchema.Defaults.anonymousPhonesDigitsOnly,
    );
    parameters[DBMatchRuleAnonymousPhoneSchema.receivedAtE164Phones] = SchemaField.shortStringArray(
      DBMatchRuleAnonymousPhoneSchema.Defaults.receivedAtE164Phones,
    );
    parameters[DBMatchRuleAnonymousPhoneSchema.forwardedFromE164Phones] = SchemaField.shortStringArray(
      DBMatchRuleAnonymousPhoneSchema.Defaults.forwardedFromE164Phones,
    );
    parameters[DBMatchRuleAnonymousPhoneSchema.x5us] = SchemaField.shortStringArray(
      DBMatchRuleAnonymousPhoneSchema.Defaults.x5us,
    );
    parameters[DBMatchRuleAnonymousPhoneSchema.origIds] = SchemaField.shortStringArray(
      DBMatchRuleAnonymousPhoneSchema.Defaults.origIds,
    );
    /* eslint-enable */

    return parameters;
  }
}
