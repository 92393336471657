import { cloneDeep } from 'lodash';

import { SerializableObject } from '../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';
import { ShakenPayloadDest } from '../shaken-payload-dest/shaken-payload-dest';
import { ShakenPayloadOrig } from '../shaken-payload-orig/shaken-payload-orig';

import { ShakenPayloadConstructor } from './shaken-payload-constructor';
import { ShakenPayloadSchema } from './shaken-payload-schema';

export class ShakenPayload extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected attest!: 'A' | 'B' | 'C';

  protected dest!: ShakenPayloadDest;

  protected iat!: number;

  protected orig!: ShakenPayloadOrig;

  protected origid!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ShakenPayloadConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ShakenPayload {
    return new ShakenPayload(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ShakenPayload.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ShakenPayloadSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getAttest(): 'A' | 'B' | 'C' {
    return cloneDeep(this.attest);
  }

  public getDest(): ShakenPayloadDest {
    return cloneDeep(this.dest);
  }

  public getIAT(): number {
    return cloneDeep(this.iat);
  }

  public getOrig(): ShakenPayloadOrig {
    return cloneDeep(this.orig);
  }

  public getOrigId(): string {
    return cloneDeep(this.origid);
  }
}
