import { values } from 'lodash';

import { CommunicationSessionId } from '../../../core/communication-session-id/communication-session-id';
import { DBDocSchema } from '../../../generic/db-doc/db-doc-schema';
import { SchemaField } from '../../../generic/serialization/schema-field';
import { AsyncServiceRequestName } from '../async-service-request-name';
import { AsyncServiceRequestStatus } from '../async-service-request-status';

export class AsyncServiceRequestSchema extends DBDocSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The name of the command
  public static readonly type = 'type';

  public static readonly assignedTo = 'assignedTo';

  public static readonly communicationWidgetId = 'communicationWidgetId';

  public static readonly status = 'status';

  public static readonly serviceableAddressId = 'serviceableAddressId';

  public static readonly sessionId = 'sessionId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'asyncServiceRequests';
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////
  static Defaults = class {
    public static readonly type = undefined;

    public static readonly assignedTo = undefined;

    public static readonly communicationWidgetId = undefined;

    public static readonly status = undefined;

    public static readonly serviceableAddressId = undefined;

    public static readonly sessionId = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // prettier-ignore
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[AsyncServiceRequestSchema.type] = SchemaField.string(AsyncServiceRequestSchema.Defaults.type).required().valid(...values(AsyncServiceRequestName));
    parameters[AsyncServiceRequestSchema.assignedTo] = SchemaField.string(AsyncServiceRequestSchema.Defaults.assignedTo).optional();
    parameters[AsyncServiceRequestSchema.communicationWidgetId] = SchemaField.autoGeneratedDocId(AsyncServiceRequestSchema.Defaults.communicationWidgetId).optional();
    parameters[AsyncServiceRequestSchema.sessionId] = SchemaField.obj(CommunicationSessionId, AsyncServiceRequestSchema.Defaults.sessionId).required();
    parameters[AsyncServiceRequestSchema.serviceableAddressId] = SchemaField.autoGeneratedDocId(AsyncServiceRequestSchema.Defaults.serviceableAddressId).required();
    parameters[AsyncServiceRequestSchema.status] = SchemaField.string(AsyncServiceRequestSchema.status, AsyncServiceRequestSchema.Defaults.status).required().valid(...values(AsyncServiceRequestStatus));
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////
  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${AsyncServiceRequestSchema.Constants.collection}`;
  }
}
