import { cloneDeep } from 'lodash';

import { AssignedUserType } from '../../../../../event/event-data/enums';
import { SerializableObjectSchema } from '../../../../../generic/serialization/serializable-object-schema';
import { CommunicationTaskQueueWorkerList } from '../../generic/communication-task-queue-worker-list/communication-task-queue-worker-list';
import { CommunicationTaskQueueWorkerListSchema } from '../../generic/communication-task-queue-worker-list/communication-task-queue-worker-list-schema';
import { CommunicationTaskQueueWorkerListName } from '../../generic/communication-task-queue-worker-list-name';

import { CommunicationTaskQueueWorkerListEventConstructor } from './communication-task-queue-worker-list-event-constructor';
import { CommunicationTaskQueueWorkerListEventSchema } from './communication-task-queue-worker-list-event-schema';

export class CommunicationTaskQueueWorkerListEvent extends CommunicationTaskQueueWorkerList {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected assignedUserTypes!: AssignedUserType[];

  protected eventTypes!: string[];

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationTaskQueueWorkerListEventConstructor) {
    (parameters as any)[CommunicationTaskQueueWorkerListSchema.type] = CommunicationTaskQueueWorkerListName.event;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(
    validationResult: import('joi').ValidationResult,
  ): CommunicationTaskQueueWorkerListEvent {
    return new CommunicationTaskQueueWorkerListEvent(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationTaskQueueWorkerListEvent.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationTaskQueueWorkerListEventSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Query IDs
  /////////////////////////////////////////////////////////////////////////////

  public getQueryIds(): string[] {
    return [];
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getAssignedUserTypes(): AssignedUserType[] {
    return cloneDeep(this.assignedUserTypes);
  }

  public getEventTypes(): string[] {
    return cloneDeep(this.eventTypes);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Num Worker Slots
  /////////////////////////////////////////////////////////////////////////////

  public getNumWorkerSlots(): number {
    const numEvents = this.eventTypes?.length ?? 0;
    const numAssignedUserTypes = this.assignedUserTypes?.length ?? 0;

    return numEvents * numAssignedUserTypes;
  }
}
