import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { translate } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { camelCase, isNil } from 'lodash';
import { MessageService } from 'primeng/api';
import { map } from 'rxjs/operators';

import { userDataDisplayFormat } from '../../../../../../common/objects/user-data-display-format';
import { RowCell } from '../../../../../../common/p-table/row-cell';
import { AuthService } from '../../../../../../services/user/auth/auth.service';
import { InboxRow } from '../../../../row/inbox-row/inbox-row';
import { getInboxRowHandleStatus } from '../../../../row/inbox-row-handle-status/get-inbox-row-handle-status/get-inbox-row-handle-status';
import { InboxRowHandleStatus } from '../../../../row/inbox-row-handle-status/inbox-row-handle-status';

@UntilDestroy()
@Component({
  selector: 'app-inbox-handled-by-button',
  templateUrl: './inbox-handled-by-button.component.html',
  styleUrls: ['./inbox-handled-by-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class InboxHandledByButtonComponent implements OnInit, OnChanges {
  ///////////////////////////////////////////////////////////////////////
  // Input
  ///////////////////////////////////////////////////////////////////////
  @Input() row: InboxRow;

  @Input() editorTemplate: TemplateRef<any>;

  @Input() toggleHandledBySelf: () => Promise<void>;

  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  loading = true;

  handleStatus = InboxRowHandleStatus.notHandled;

  canToggleHandledBySelf = false;

  loggedInUserId: string;

  toggleButtonCell: RowCell<string>;

  toggleHandleInProgress = false;
  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!isNil(this.loggedInUserId)) {
      this.refreshUI();
    }
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Data
  ///////////////////////////////////////////////////////////////////////
  getData() {
    this.loading = true;
    this.changeDetectorRef.detectChanges();
    this.authService
      .getUserId()
      .pipe(
        map((loggedInUserId) => {
          this.loggedInUserId = loggedInUserId;
          this.refreshUI();
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  ///////////////////////////////////////////////////////////////////////
  // Update UI
  ///////////////////////////////////////////////////////////////////////
  refreshUI() {
    const { handledByUserId } = this.row.inboxRowParams;
    const allUserDataMap = this.row?.inboxRowParams.allUserDataMap ?? new Map();
    const displayName = userDataDisplayFormat(allUserDataMap.get(handledByUserId ?? 'missing')?.userData);

    this.handleStatus = getInboxRowHandleStatus({
      loggedInUserId: this.loggedInUserId,
      handledByUserId,
      allDataUserMap: allUserDataMap,
    });

    this.toggleButtonCell = new RowCell<string>({
      translationScope: 'inbox-handled-by-button',
      translationKey: camelCase(`toggleButton ${this.handleStatus}`),
      translationObj: {
        displayName,
      },
      sortValue: '',
    });
    this.canToggleHandledBySelf = isNil(handledByUserId) || handledByUserId === this.loggedInUserId;
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  ///////////////////////////////////////////////////////////////////////
  // Toggle Handled
  ///////////////////////////////////////////////////////////////////////

  async toggleHandledBySelfClick() {
    this.toggleHandleInProgress = true;
    this.changeDetectorRef.detectChanges();

    try {
      await this.toggleHandledBySelf();
    } catch (error) {
      this.showUnknownError();
    }
    this.toggleHandleInProgress = false;
    this.changeDetectorRef.detectChanges();
  }

  ///////////////////////////////////////////////////////////////////////
  // Message
  ///////////////////////////////////////////////////////////////////////

  private showUnknownError() {
    const title = translate(`pwp-api.errorTimeoutTitle`);
    const body = translate(`pwp-api.errorTimeoutBody`);

    this.messageService.add({
      severity: 'warn',
      summary: title,
      detail: body,
    });
    this.changeDetectorRef.detectChanges();
  }
}
