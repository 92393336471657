import { DBUploadExistingDoc } from '../../../db/transaction/actions/db-upload-existing-doc';
import { DBTransaction } from '../../../db/transaction/db-transaction';
import { DBTransactionType } from '../../../db/transaction/db-transaction-type';
import { OrgData } from '../../org/org-data/org-data';
import { AllDataUser } from '../../user/all-data-user/all-data-user';
import { EventData } from '../event-data/event-data';

import { EventDataTransactionDisplay } from './event-data-transaction-display';

export const makeSimpleUploadEventDataTransactionDisplay = (
  allDataUserMap: Map<string, AllDataUser>,
  orgData: OrgData,
  eventData: EventData,
): EventDataTransactionDisplay => {
  console.log('E2E debug: makeSimpleUploadEventDataTransactionDisplay.id', eventData?.getId());
  console.log('E2E debug: makeSimpleUploadEventDataTransactionDisplay.start', eventData?.getStart());
  console.log('E2E debug: makeSimpleUploadEventDataTransactionDisplay.end', eventData?.getEnd());
  console.log('E2E debug: makeSimpleUploadEventDataTransactionDisplay.assignedUserId', eventData?.getAssignedUserId());
  console.log(
    'E2E debug: makeSimpleUploadEventDataTransactionDisplay.assignedBackupUserId',
    eventData?.getAssignedBackupUserId(),
  );
  console.log('E2E debug: makeSimpleUploadEventDataTransactionDisplay.color', eventData?.getColor());
  console.log('E2E debug: makeSimpleUploadEventDataTransactionDisplay.type', eventData?.getType());
  console.log('E2E debug: makeSimpleUploadEventDataTransactionDisplay.allDataUserMap.size', allDataUserMap?.size);
  console.log(
    'E2E debug: makeSimpleUploadEventDataTransactionDisplay / assignedUser',
    allDataUserMap?.get(eventData?.getAssignedUserId() as string),
  );
  console.log(
    'E2E debug: makeSimpleUploadEventDataTransactionDisplay / assignedBackupUser',
    allDataUserMap?.get(eventData?.getAssignedBackupUserId() as string),
  );

  return new EventDataTransactionDisplay(
    allDataUserMap,
    orgData,
    DBTransactionType.update,
    [new DBUploadExistingDoc(eventData)],
    eventData.getId(),
    eventData.getStart(),
    eventData.getEnd(),
    eventData.getAssignedUserId(),
    eventData.getAssignedBackupUserId(),
    eventData.getColor(),
    eventData.getType(),
  );
};

export const makeSplitEventDataTransactionDisplay = (
  allDataUserMap: Map<string, AllDataUser>,
  orgData: OrgData,
  transaction: DBTransaction<EventData>,
): EventDataTransactionDisplay => {
  const firstEventData = (transaction.actions[0] as DBUploadExistingDoc<EventData>).obj;
  const secondEventData = (transaction.actions[1] as DBUploadExistingDoc<EventData>).obj;

  return new EventDataTransactionDisplay(
    allDataUserMap,
    orgData,
    DBTransactionType.split,
    transaction.actions,
    firstEventData.getId(),
    firstEventData.getStart(),
    secondEventData.getEnd(),
    firstEventData.getAssignedUserId(),
    firstEventData.getAssignedBackupUserId(),
    firstEventData.getColor(),
    firstEventData.getType(),
  );
};
