<ng-container *transloco="let t; read: 'reservation-offer-channel-config'">
  <form [formGroup]="form">
    <div class="grid-nogutter">
      <div class="col-12">
        <div class="grid-nogutter p-fluid">
          <!-- Origination -->
          <div class="field mb-4 col-12">
            <span class="p-float-label">
              <app-vrc-audio-select
                id="vrcAudioMetadataId"
                [formControl]="vrcAudioMetadataId"
                [placeholder]="t('vrcAudioMetadataIdPlaceholder')"
                [required]="true"
              ></app-vrc-audio-select>
              <p *ngIf="vrcAudioMetadataId.hasError('required')" class="p-error">
                {{ t('vrcAudioMetadataIdErrorRequired') }}
              </p>
              <p class="wrap" id="vrcAudioMetadataId-help">
                {{ t('vrcAudioMetadataIdHelp', { vrcAudioMetadataId: vrcAudioMetadataId.value }) }}
              </p>
            </span>
          </div>

          <!-- Dial Timeout (Seconds) -->
          <div class="field col-12 mt-3">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon"><i class="pi pi-arrow-down"></i></span>
              <span class="p-float-label">
                <p-inputNumber
                  id="dialTimeoutSeconds"
                  mode="decimal"
                  ariaLabel="{{ t('dialTimeoutSecondsLabel') }}"
                  [formControl]="dialTimeoutSeconds"
                  [ngClass]="{ 'ng-invalid': dialTimeoutSeconds?.invalid }"
                  [showButtons]="true"
                  suffix=" {{ t('dialTimeoutSecondsSuffix') }}"
                >
                </p-inputNumber>
                <label for="dialTimeoutSeconds">{{ t('dialTimeoutSecondsLabel') }}</label>
              </span>
            </div>

            <p *ngIf="dialTimeoutSeconds.hasError('required')" class="p-error">
              {{ t('dialTimeoutSecondsErrorRequired') }}
            </p>
            <p *ngIf="dialTimeoutSeconds.hasError('min')" class="p-error">
              {{ t('dialTimeoutSecondsErrorMin', { dialTimeoutSeconds: dialTimeoutSeconds.value }) }}
            </p>
            <p *ngIf="dialTimeoutSeconds.hasError('max')" class="p-error">
              {{ t('dialTimeoutSecondsErrorMax', { dialTimeoutSeconds: dialTimeoutSeconds.value }) }}
            </p>
            <p class="wrap" id="dialTimeoutSeconds-help">
              {{ t('dialTimeoutSecondsHelp', { dialTimeoutSeconds: dialTimeoutSeconds.value }) }}
            </p>
          </div>

          <!-- Body -->
          <div class="field mb-4 col-12">
            <span class="p-float-label w-full">
              <textarea
                class="w-full"
                id="body"
                pInputTextarea
                rows="4"
                [formControl]="body"
                [ngClass]="{ 'ng-invalid': body?.invalid }"
              ></textarea>
              <label for="body">{{ t('bodyLabel') }}</label>
              <p *ngIf="body.touched && body.hasError('required')" class="p-error">
                {{ t('bodyErrorRequired', { length: body?.value?.length ?? 0 }) }}
              </p>
              <p *ngIf="body.touched && body.hasError('minlength')" class="p-error">
                {{ t('bodyErrorMinLength', { length: body?.value?.length ?? 0 }) }}
              </p>
              <p *ngIf="body.touched && body.hasError('maxlength')" class="p-error">
                {{ t('bodyErrorMaxLength', { length: body?.value?.length ?? 0 }) }}
              </p>
              <p class="wrap" id="body-help">
                {{ t('bodyHelp', { length: body?.value?.length ?? 0 }) }}
              </p>
            </span>
          </div>

          <!-- Caller Id -->
          <div class="field mb-4 col-12">
            <span class="w-full">
              <app-caller-id-autocomplete
                id="callerIdObjId"
                [formControl]="callerIdObjId"
                [placeholder]="t('callerIdObjIdPlaceholder')"
              ></app-caller-id-autocomplete>
              <p *ngIf="callerIdObjId.touched && callerIdObjId.hasError('required')" class="p-error">
                {{ t('callerIdObjIdErrorRequired') }}
              </p>
              <p class="wrap" id="callerIdObjId-help">{{ t('callerIdObjIdHelp') }}</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-container>
