import { RouterModule, Routes } from '@angular/router';

import { CommunicationLogsTableComponent } from './components/communication-logs/communication-logs-table/communication-logs-table.component';
import { OrgDataSelectAndEditComponent } from './components/config/org-data-select-and-edit/org-data-select-and-edit.component';
import { AdminGuard } from './guards/admin/admin.guard';
import { VoicemailGuard } from './guards/voicemail/voicemail.guard';
import { authRoutes } from './modules/auth/auth.routes';
import { AuthGuard } from './modules/auth/guards/auth/auth.guard';
import { automationRoutes } from './modules/automation/automation.routes';
import { AdminAnalyticsComponent } from './pages/admin-analytics/admin-analytics.component';
import { ChatComponent } from './pages/chat/chat.component';
import { EventRequestsComponent } from './pages/event-requests/event-requests.component';
import { EventSelectorComponent } from './pages/event-selector/event-selector.component';
import { InboxComponent } from './pages/inbox/inbox.component';
import { IncomingChatComponent } from './pages/incoming-chat/incoming-chat.component';
import { UpsertEventComponent } from './pages/modify-schedule/components/upsert-event/upsert-event.component';
import { ModifyScheduleComponent } from './pages/modify-schedule/modify-schedule.component';
import { resolveModifyScheduleTitle } from './pages/modify-schedule/resolvers/resolve-modify-schedule-title/resolve-modify-schedule-title';
import { OrgSettingsComponent } from './pages/org-settings/org-settings.component';
import { ServiceExceptionsComponent } from './pages/service-exceptions/service-exceptions.component';
import { SupportComponent } from './pages/support/support.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { UsersAdminComponent } from './pages/users-admin/users-admin.component';
import { selectTranslate } from './transloco/helpers/select-translate/select-translate';

// Route Configuration
export const routes: Routes = [
  ...authRoutes,
  {
    path: 'incomingChat',
    component: IncomingChatComponent,
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadComponent: () => import('./layouts/base-layout/base-layout.component').then((m) => m.BaseLayoutComponent),
    children: [
      {
        path: 'automation',
        children: automationRoutes,
        resolve: { title: async () => selectTranslate('automation.title') },
      },
      {
        path: 'user-profile',
        component: UserProfileComponent,
        resolve: { title: async () => selectTranslate('user-profile.title') },
      },
      {
        path: 'chat',
        component: ChatComponent,
        resolve: { title: async () => selectTranslate('chat.title') },
      },
      {
        path: 'events-calendar',
        component: EventSelectorComponent,
        resolve: { title: async () => selectTranslate('events-calendar.title') },
      },
      {
        path: 'call-logs',
        component: CommunicationLogsTableComponent,
        resolve: { title: async () => selectTranslate('call-logs.title') },
      },
      {
        path: 'inbox',
        component: InboxComponent,
        canActivate: [VoicemailGuard],
        resolve: { title: async () => selectTranslate('inbox.title') },
      },
      {
        path: 'event-requests',
        component: EventRequestsComponent,
        canActivate: [AdminGuard],
        resolve: { title: async () => selectTranslate('event-requests.title') },
      },
      {
        path: 'modify-schedule',
        component: ModifyScheduleComponent,
        canActivate: [AdminGuard],
        resolve: { title: async () => selectTranslate('modify-schedule.title') },
      },
      {
        path: 'modify-schedule/:eventType',
        component: UpsertEventComponent,
        canActivate: [AdminGuard],
        data: { returnUrl: '/modify-schedule' },
        resolve: { title: resolveModifyScheduleTitle },
      },
      {
        path: 'modify-schedule/:eventType/:eventId',
        component: UpsertEventComponent,
        canActivate: [AdminGuard],
        data: { returnUrl: '/modify-schedule' },
        resolve: { title: resolveModifyScheduleTitle },
      },
      {
        path: 'users',
        component: UsersAdminComponent,
        canActivate: [AdminGuard],
        resolve: { title: async () => selectTranslate('users.title') },
      },
      {
        path: 'stats',
        component: AdminAnalyticsComponent,
        canActivate: [AdminGuard],
        resolve: { title: async () => selectTranslate('analytics.title') },
      },
      {
        path: 'support',
        component: SupportComponent,
        canActivate: [AdminGuard],
      },
      {
        path: 'serviceExceptions',
        component: ServiceExceptionsComponent,
        canActivate: [AdminGuard],
        resolve: { title: async () => selectTranslate('service-exceptions.title') },
      },
      {
        path: 'orgSettings',
        component: OrgSettingsComponent,
        canActivate: [AdminGuard],
        resolve: { title: async () => selectTranslate('org-settings-page.title') },
      },
      {
        path: 'communicationWorkflow',
        component: OrgSettingsComponent,
        canActivate: [AdminGuard],
        resolve: { title: async () => selectTranslate('org-settings-page.title') },
      },
      // PWP Admin Options
      {
        path: 'org-select-and-edit',
        component: OrgDataSelectAndEditComponent,
        canActivate: [AdminGuard],
      },
    ],
  },
];
export const routing = RouterModule.forRoot(routes, {});
