import { cloneDeep } from 'lodash';

import { CallEntrypoint, getCommandsWithUpdatedIfTime, VoiceResponseCommand, VRCIfTime } from '@pwp-common';

import { AvailableHoursMode } from '../available-hours-mode';
import { PhoneLineEditorOutput } from '../phone-line-editor-output';

export const createCallEntrypointFromPhoneLineEditorOutput = (params: PhoneLineEditorOutput): CallEntrypoint =>
  cloneDeep(params.callEntrypoint).setCommands(getUpdatedCommands(params));

const getUpdatedCommands = ({
  callEntrypoint,
  closedHoursCommands,
  availableHoursMode,
  availableHours,
}: PhoneLineEditorOutput): VoiceResponseCommand[] => {
  if (availableHoursMode === AvailableHoursMode.alwaysAvailable) {
    return callEntrypoint.getCommands().filter((command) => !(command instanceof VRCIfTime));
  }

  return getCommandsWithUpdatedIfTime({
    callEntrypoint,
    closedHoursCommands,
    timeSelection: availableHours.getComplement(),
  });
};
