import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { translate, TranslocoModule } from '@ngneat/transloco';
import { LetModule } from '@ngrx/component';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { PasswordModule } from 'primeng/password';
import { BehaviorSubject } from 'rxjs';

import { UserResponseUpdateUserError } from '@pwp-common';

import { mustMatch } from '../../../../common/validators/must-match/must-match';
import { PasswordValidator } from '../../../../common/validators/password-validator';
import { UserEndpointService } from '../../../../services/user/user-endpoint/user-endpoint.service';
import { FormFieldHelpComponent } from '../../../form/form-field-help/form-field-help.component';

import { ChangeOtherUserPasswordDialogInput } from './interfaces';

@Component({
  selector: 'app-change-other-user-password',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    InputTextareaModule,
    ReactiveFormsModule,
    ButtonModule,
    FormFieldHelpComponent,
    PasswordModule,
    MessagesModule,
    TranslocoModule,
    LetModule,
  ],
  templateUrl: './change-other-user-password.component.html',
  styleUrls: ['./change-other-user-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class ChangeOtherUserPasswordComponent {
  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  public loading = true;

  private readonly config: DynamicDialogConfig<ChangeOtherUserPasswordDialogInput> = inject(
    DynamicDialogConfig<ChangeOtherUserPasswordDialogInput>,
  );

  private readonly userEndpoint = inject(UserEndpointService);

  private readonly fb = inject(NonNullableFormBuilder);

  private readonly messageService = inject(MessageService);

  public readonly form = this.fb.group(
    {
      newPassword: this.fb.control('', [
        Validators.minLength(10),
        PasswordValidator.containsDigitValidator(),
        PasswordValidator.containsSymbolValidator(),
      ]),
      newPasswordConfirmation: this.fb.control('', [Validators.required]),
    },
    {
      validators: [mustMatch('newPassword', 'newPasswordConfirmation')],
    },
  );

  public readonly loading$ = new BehaviorSubject(false);

  private handleError() {
    this.messageService.add({
      severity: 'error',
      summary: translate(`change-other-user-password.errors.errorTitle`),
      detail: translate(`change-other-user-password.errors.${UserResponseUpdateUserError.unableToUpdateUser}`),
    });
  }

  private handleSuccess() {
    this.messageService.add({
      severity: 'success',
      summary: translate(`change-other-user-password.success.title`),
      detail: translate(`change-other-user-password.success.body`),
    });
  }

  public async changePassword(): Promise<void> {
    this.loading$.next(true);
    const { userId } = this.config.data;
    const { newPassword } = this.form.value;

    const result = await this.userEndpoint.updateUser({ userId, password: newPassword });
    this.loading$.next(false);
    if (result.getError()) {
      this.handleError();
    } else {
      this.handleSuccess();
    }
  }
}
