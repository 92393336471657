import { Injectable } from '@angular/core';
import firebase from 'firebase/compat';
import { isNil } from 'lodash';
import { firstValueFrom, Observable, of } from 'rxjs';

import {
  CommunicationWidget,
  CommunicationWidgetName,
  CommunicationWidgetSchema,
  isNilOrDefaultDocId,
} from '@pwp-common';

import { DbDocumentService } from '../../../generic/db-document-service';
import { DBOrderBy, DBQuery } from '../../../generic/interfaces';

@Injectable({
  providedIn: 'root',
})
export abstract class CommunicationWidgetService<T extends CommunicationWidget> extends DbDocumentService<T> {
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Abstract Methods
  //////////////////////////////////////////////////////////////////////////////////////////////////

  protected abstract getType(): CommunicationWidgetName;

  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Get All Docs Of Type
  //////////////////////////////////////////////////////////////////////////////////////////////////

  public getAllDocsOfType(params: {
    orderBy?: DBOrderBy;
    limit?: number;
    takeOne?: boolean;
  }): Observable<T[] | undefined> {
    const type = this.getType();
    const logMetadata = { type };
    console.log('CommunicationWidgetService.getDefaultDoc', 'Starting', logMetadata);
    if (type === undefined) {
      console.error(
        'CommunicationWidgetService.getDefaultDoc',
        'User Error: type is undefined or empty. Returning undefined',
        logMetadata,
      );
      return of(undefined);
    }

    const query: DBQuery[] = [{ fieldPath: CommunicationWidgetSchema.type, opStr: '==', value: type }];
    return this.getDocsArray(query, params.orderBy, params.limit, params.takeOne);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Get Doc Of Type
  //////////////////////////////////////////////////////////////////////////////////////////////////

  public getDefaultDoc(): Observable<T | undefined> {
    const type = this.getType();
    const logMetadata = { type };
    console.log('CommunicationWidgetService.getDefaultDoc', 'Starting', logMetadata);
    if (type === undefined) {
      console.error(
        'CommunicationWidgetService.getDefaultDoc',
        'User Error: type is undefined or empty. Returning undefined',
        logMetadata,
      );
      return of(undefined);
    }

    try {
      const query: DBQuery[] = [{ fieldPath: CommunicationWidgetSchema.type, opStr: '==', value: type }];
      return this.getUniqueDoc({ query, logErrIfNoDocExists: false });
    } catch (error) {
      console.error('CommunicationWidgetService.getDefaultDoc', 'Unknown error', logMetadata, error);
      throw error;
    } finally {
      console.log('CommunicationWidgetService.getDefaultDoc', 'Completed', logMetadata);
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Upload Doc Of Type
  //////////////////////////////////////////////////////////////////////////////////////////////////

  public async uploadDefaultDoc(obj: T): Promise<void> {
    const promise = this.db.firestore
      .runTransaction(async (transaction: firebase.firestore.Transaction) => {
        if (isNil(obj)) {
          return transaction;
        }

        // Check if an object with this type already exists
        const defaultDoc = await firstValueFrom(this.getDefaultDoc());
        const mode = isNil(defaultDoc) ? 'create' : 'update';
        let id = obj.getId();
        if (mode === 'create' && isNilOrDefaultDocId(id)) {
          id = this.createId();
        }
        const serializedObj = await firstValueFrom(this.getSerializedObjWithGenericFields(obj, mode));
        const docRef = await firstValueFrom(this.docRef(id));
        if (mode === 'create') {
          transaction.set(docRef.ref, serializedObj);
        } else {
          transaction.update(docRef.ref, serializedObj);
        }
        return transaction;
      })
      .catch((err) => {
        console.error(`DbDocumentService.uploadDefaultDoc:${this.getLoggingInfo()}: Error`, { obj });
        console.error('err');
        console.error(err);
        throw err;
      })
      .finally(() => {
        console.log(`DbDocumentService.uploadDefaultDoc:${this.getLoggingInfo()}: Complete`, { obj });
      });

    await promise;
  }
}
