<ng-container *transloco="let t; read: 'edit-event-properties-dialog'">
  <div *ngIf="!loading">
    <!-- Color -->
    <div class="form-row">
      <div class="col">
        <app-color-dropdown ngDefaultControl [(selectedColor)]="selectedColor"></app-color-dropdown>
      </div>
    </div>
    <!-- Assigned User -->
    <div class="form-row">
      <div class="col">
        <app-obj-autocomplete
          [displayFormat]="userDataDisplayFormat"
          [label]="t('assignedUser')"
          [orderedItems]="data.userDataArray"
          [specialKVPairs]="{ noChange: t('noChange'), resetDefault: t('resetDefault') }"
          [(kvPair)]="selectedAssignedUser"
        ></app-obj-autocomplete>
      </div>
    </div>
    <!-- Assigned Backup User -->
    <div class="form-row">
      <div class="col">
        <app-obj-autocomplete
          [displayFormat]="userDataDisplayFormat"
          [label]="t('assignedBackupUser')"
          [orderedItems]="data.userDataArray"
          [specialKVPairs]="{ noChange: t('noChange'), resetDefault: t('resetDefault') }"
          [(kvPair)]="selectedAssignedBackupUser"
        ></app-obj-autocomplete>
      </div>
    </div>
  </div>
  <button cdkFocusInitial mat-button [mat-dialog-close]="getObjFromForm()">{{ t('okButton') }}</button>
</ng-container>
