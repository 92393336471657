import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { isNil } from 'lodash';

import { CallStatsFunnel, EntityStats, EntityStatsDisplayWindow } from '@pwp-common';

@Component({
  selector: 'app-calls-funnel',
  templateUrl: './calls-funnel.component.html',
  styleUrls: ['./calls-funnel.component.css'],
})
export class CallsFunnelComponent implements OnInit, OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  @Input() orgStats: EntityStats;

  @Input() window: EntityStatsDisplayWindow;

  values: number[] = [];

  labels: string[] = [];

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if ('orgStats' in changes || 'window' in changes) {
      this.refreshData();
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Data
  /////////////////////////////////////////////////////////////////////////////////////////////

  private refreshData() {
    this.resetData();
    if (isNil(this.orgStats) || isNil(this.window)) {
      return;
    }

    const callStatsOverTime = new CallStatsFunnel(this.orgStats, this.window);
    this.labels = [
      'Service Requested',
      'Not Blocked',
      'Have Capacity',
      'Client Did Not Disconnect Early',
      'Client Connected',
    ];
    this.values = [
      callStatsOverTime.iNumServiceRequested,
      callStatsOverTime.iNumNotBlocked,
      callStatsOverTime.iNumUnblockedAndHaveCapacity,
      callStatsOverTime.iNumUnblockedAndHaveCapacityAndClientNotDisconnectEarly,
      callStatsOverTime.iNumClientConnectedToUser,
    ];
  }

  private resetData() {
    this.values = [];
    this.labels = [];
  }
}
