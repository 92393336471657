import { ChangeDetectorRef, Directive, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { cloneDeep, isEqual, isNil } from 'lodash';
import { Subject } from 'rxjs';

import { Displayable } from '../../../../../../../common/src';
import {
  getCompleteKVPair,
  isEmptyKVPair,
  KVPair,
  KVPairConstructor,
  kvPairEqual,
} from '../../../common/objects/kvpair';
import { NgChanges } from '../../../common/objects/ng-changes';

@Directive()
export abstract class ChooseObjForm<T extends Displayable> implements OnChanges, OnDestroy {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Inputs / Outputs
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  @Input() label = '';

  @Input() orderedItems: T[];

  @Input() kvPair: KVPair<T> | undefined | string;

  @Output() kvPairChange = new EventEmitter<KVPair<T> | undefined | string>();

  @Input() specialKVPairs: { [key: string]: string } = {};

  protected ngUnsubscribe = new Subject<void>();

  private lastEmittedValue: any;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: NgChanges<ChooseObjForm<any>>) {
    if (!isNil(this.orderedItems)) {
      if (!isNil(changes.orderedItems)) {
        this.setFormControl(this.kvPair, true);
        return;
      }

      if (!isNil(changes.kvPair)) {
        this.setFormControl(this.kvPair, true);
        return;
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.changeDetectorRef.detach();

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Compare
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public compareObjs(o1: KVPair<T> | undefined, o2: KVPair<T> | undefined): boolean {
    if (typeof o1 === 'string' || typeof o2 === 'string') {
      return isEqual(o1, o2);
    }
    return kvPairEqual(o1, o2);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Callbacks
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public selectionChanged(kvPairConstructor: string | KVPairConstructor<T>) {
    if (typeof kvPairConstructor === 'string') {
      this.kvPair = kvPairConstructor;
    } else {
      this.kvPair = isNil(kvPairConstructor) ? kvPairConstructor : new KVPair(kvPairConstructor);
    }
    this.emitObj();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Update the form control with the given value (if specified). If
   * a string is given, then update the form with the object with this
   * id instead.
   */
  protected setFormControl(partialKVPair: KVPair<T> | string, emitChange = true) {
    if (isNil(partialKVPair) || typeof partialKVPair === 'string') {
      this.getFormControl().setValue(partialKVPair);
      this.kvPair = partialKVPair;
    } else if (!isEmptyKVPair(partialKVPair)) {
      const completePair = getCompleteKVPair(partialKVPair, this.orderedItems);
      this.getFormControl().setValue(completePair);
      this.kvPair = completePair;
    } else {
      this.getFormControl().setValue(undefined);
      this.kvPair = undefined;
    }
    if (emitChange) {
      this.emitObj();
    }
  }

  protected emitObj() {
    if (isEqual(this.kvPair, this.lastEmittedValue)) {
      return;
    }

    if (isNil(this.kvPair)) {
      this.kvPairChange.emit(this.kvPair);
      this.lastEmittedValue = cloneDeep(this.kvPair);
      return;
    }

    if (typeof this.kvPair === 'string') {
      this.kvPairChange.emit(this.kvPair);
      this.lastEmittedValue = cloneDeep(this.kvPair);
      return;
    }

    // Can't get complete kvPair if ordered items is empty
    if (isNil(this.orderedItems)) {
      return;
    }

    if (isEmptyKVPair(this.kvPair)) {
      return;
    }

    const completePair = getCompleteKVPair(this.kvPair, this.orderedItems);
    this.kvPairChange.emit(completePair);
    this.lastEmittedValue = cloneDeep(this.kvPair);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Abstract Methods
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public abstract getFormControl(): UntypedFormControl;
}
