<div *ngIf="!loading">
  <form [formGroup]="form">
    <!-- Language -->
    <mat-form-field class="full-width">
      <mat-label>Language</mat-label>
      <mat-select formControlName="language" value="obj.languageDefaults.getId()">
        <mat-option *ngFor="let language of possibleLanguages" [value]="language.getShortCode()">{{
          language.getDisplayName()
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Template Text -->
    <mat-form-field class="full-width">
      <textarea
        cdkAutosizeMinRows="2"
        cdkTextareaAutosize
        formControlName="templateText"
        matInput
        placeholder="Template"
      ></textarea>
    </mat-form-field>
  </form>
</div>
