import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { translate } from '@ngneat/transloco';
import moment from 'moment-timezone';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import {
  CommunicationsRequestGetConversationLogPII,
  CommunicationsRequestName,
  CommunicationsResponseGetConversationLogPII,
  displayTime,
  formatDuration,
} from '@pwp-common';

import { CommunicationsService } from '../../../services/call/communications/communications.service';

@Component({
  selector: 'app-conversation-info-dialog',
  templateUrl: './conversation-info-dialog.component.html',
  styleUrls: ['./conversation-info-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class ConversationInfoDialogComponent implements OnInit {
  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  loading = true;

  dataRetentionDurationFormatted: string;

  dataRetentionEndFormatted: string;

  communicationsResponseGetConversationLogPII: CommunicationsResponseGetConversationLogPII;

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private changeDetectorRef: ChangeDetectorRef,
    private communicationsService: CommunicationsService,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    void this.getData();
  }

  ///////////////////////////////////////////////////////////////////////
  // Close
  ///////////////////////////////////////////////////////////////////////

  closeDialog() {
    this.ref.close();
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Data
  ///////////////////////////////////////////////////////////////////////

  async getData() {
    // Set loading state
    this.loading = true;

    const { data } = this.config;

    // Fetch data
    try {
      this.communicationsResponseGetConversationLogPII = await this.communicationsService.getConversationLogPII(
        new CommunicationsRequestGetConversationLogPII({
          type: CommunicationsRequestName.getConversationLogPII,
          conversationLogId: data.conversationLog.getId(),
        }),
      );
      if (this.communicationsResponseGetConversationLogPII?.getError() !== undefined) {
        this.showError('noData');
        return;
      }
    } catch (error) {
      console.log(error);
      this.showError('timeout');
      return;
    }

    const retentionDurationMS = moment.duration(data.conversationConfig.getPIIRetentionDuration()).asMilliseconds();
    this.dataRetentionDurationFormatted = formatDuration(retentionDurationMS);
    this.dataRetentionEndFormatted = displayTime(
      data.conversationLog.getCloseTime() ?? moment().add(data.conversationConfig.getPIIRetentionDuration()),
      data.orgData.getTimezone(),
    );
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  ///////////////////////////////////////////////////////////////////////
  // Errors
  ///////////////////////////////////////////////////////////////////////

  private showError(errorKey: 'timeout' | 'noData') {
    let title = '';
    let body = '';
    switch (errorKey) {
      case 'timeout': {
        title = translate(`pwp-api.errorTimeoutTitle`);
        body = translate(`pwp-api.errorTimeoutBody`);
        break;
      }
      case 'noData': {
        title = translate(`conversation-info-dialog.errorNoDataTitle`);
        body = translate(`conversation-info-dialog.errorNoDataBody`);
        break;
      }
    }

    this.messageService.add({
      severity: 'error',
      closable: true,
      sticky: true,
      summary: title,
      detail: body,
    });

    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }
}
