import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Provider } from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { loadingFor } from '@ngneat/loadoff';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { EnforceServiceLimitSchema, isNilOrDefault, ServiceLimit } from '@pwp-common';

import { ObjValidator } from '../../../../common/validators/obj-validator/obj-validator';
import { ServiceLimitService } from '../../../../services/communication/service-limit/service-limit.service';
import { FormGroupControlValueAccessor } from '../../../generic/abstract-classes/form-group-control-value-accessor';
import { EnforceServiceLimitEditorOutput } from '../editor-output/enforce-service-limit-editor-output';

const VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ServiceCommandEditorEnforceServiceLimitComponent),
  multi: true,
};

const VALIDATOR: Provider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ServiceCommandEditorEnforceServiceLimitComponent),
  multi: true,
};

const ENFORCE_SERVICE_LIMIT_SCHEMA = new EnforceServiceLimitSchema();

@UntilDestroy()
@Component({
  selector: 'app-service-command-editor-enforce-service-limit',
  templateUrl: './service-command-editor-enforce-service-limit.component.html',
  styleUrls: ['./service-command-editor-enforce-service-limit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VALUE_ACCESSOR, VALIDATOR],
})
export class ServiceCommandEditorEnforceServiceLimitComponent extends FormGroupControlValueAccessor<
  any,
  EnforceServiceLimitEditorOutput
> {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  serviceLimitSubscription: Subscription;

  loader = loadingFor('serviceLimit');

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public serviceLimitService: ServiceLimitService,
  ) {
    super(changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineForm() {
    this.form = new UntypedFormGroup({
      commandName: new UntypedFormControl(EnforceServiceLimitSchema.Defaults.commandName, [Validators.required]),
      serviceLimit: new UntypedFormControl(undefined, [Validators.required, ObjValidator.isSerializable(ServiceLimit)]),
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Write Value
  /////////////////////////////////////////////////////////////////////////////////////////////

  public writeValue(value: any) {
    const serviceLimitId: string | undefined = isNil(value) ? value : value[EnforceServiceLimitSchema.serviceLimitId];
    if (!isNilOrDefault(serviceLimitId, EnforceServiceLimitSchema.serviceLimitId, ENFORCE_SERVICE_LIMIT_SCHEMA)) {
      this.serviceLimitSubscription?.unsubscribe();

      this.serviceLimitSubscription = this.serviceLimitService
        .getDoc(serviceLimitId)
        .pipe(
          map((serviceLimit) => {
            this.serviceLimit.setValue(serviceLimit, { emitEvent: true });
            return serviceLimit;
          }),
          this.loader.serviceLimit.track(),
          untilDestroyed(this),
        )
        .subscribe();
    }
    super.writeValue(value);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  parseValueChange(value: any): EnforceServiceLimitEditorOutput {
    const result: EnforceServiceLimitEditorOutput = { ...value };
    result[EnforceServiceLimitSchema.serviceLimitId] = (this.serviceLimit.value as ServiceLimit)?.getId();
    return result;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Controls
  /////////////////////////////////////////////////////////////////////////////////////////////

  get serviceLimit() {
    return this.form.get('serviceLimit') as UntypedFormControl;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  public makeValidationErrors(): ValidationErrors {
    return {
      'service-command-editor-enforce-service-limit': this.form.value,
    };
  }
}
