import { cloneDeep } from 'lodash';

import { MessageTemplateContextType } from './message-template-context-type';

////////////////////////////////////////////////////////////
// Object Storing Context
////////////////////////////////////////////////////////////

export type MessageTemplateContextObj = { [key: string]: any };

////////////////////////////////////////////////////////////
// All possible keys of MessageTemplateContextObj
////////////////////////////////////////////////////////////

export enum MessageTemplateContextObjKeys {
  // Core
  firstName = 'firstName',
  lastInitial = 'lastInitial',
  displayName = 'displayName',
  cc = 'cc',
  bcc = 'bcc',
  replyTo = 'replyTo',
  templateDisplayName = 'templateDisplayName',
  contextLang = 'contextLang',

  // Shift
  shiftsToday = 'shiftsToday',
  shiftsTomorrow = 'shiftsTomorrow',

  // Conversation
  conversationServiceRequest = 'conversationServiceRequest',
}

////////////////////////////////////////////////////////////
// All possible keys of MessageTemplateContextObj
////////////////////////////////////////////////////////////

const coreVars = new Set<string>([
  MessageTemplateContextObjKeys.firstName,
  MessageTemplateContextObjKeys.lastInitial,
  MessageTemplateContextObjKeys.displayName,
  MessageTemplateContextObjKeys.replyTo,
  MessageTemplateContextObjKeys.templateDisplayName,
  MessageTemplateContextObjKeys.bcc,
  MessageTemplateContextObjKeys.cc,
]);

/**
 * Return the set of valid keys for a given message template context type.
 * @param context
 * @returns
 */
export const getKeysValidForContextType = (context: MessageTemplateContextType): Set<string> => {
  const varsInContext = cloneDeep(coreVars);
  switch (context) {
    case MessageTemplateContextType.shiftsToday: {
      varsInContext.add(MessageTemplateContextObjKeys.shiftsToday);
      break;
    }
    case MessageTemplateContextType.shiftsTomorrow: {
      varsInContext.add(MessageTemplateContextObjKeys.shiftsTomorrow);
      break;
    }
    case MessageTemplateContextType.conversationServiceRequest: {
      varsInContext.add(MessageTemplateContextObjKeys.conversationServiceRequest);
      break;
    }
  }

  return varsInContext;
};
