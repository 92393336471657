import type { AnySchema } from 'joi';
import { values } from 'lodash';

import { SchemaField } from '../../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../../generic/serialization/serializable-object-schema';
import { NoteBodyType } from '../note-body-type';

export abstract class NoteBodySchema extends SerializableObjectSchema {
  public static readonly type = 'type';

  public static readonly value = 'value';

  /////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////

  public static readonly Defaults = class {
    public static readonly type = undefined;
  };

  /////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  /////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: AnySchema } {
    const parameters: { [key: string]: AnySchema } = {};

    parameters[NoteBodySchema.type] = SchemaField.string(NoteBodySchema.Defaults.type)
      .required()
      .valid(...values(NoteBodyType));

    return parameters;
  }
}
