import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { CommunicationWorkflow } from '@pwp-common';

import { CommunicationWorkflowService } from '../../../../services/communication/communication-workflow/communication-workflow.service';
import {
  getObjAutocompleteFormControlBaseProviders,
  ObjAutocompleteFormControlBase,
} from '../../../generic/abstract-classes/obj-autocomplete-form-control-base';

@Component({
  selector: 'app-communication-workflow-autocomplete',
  templateUrl: './communication-workflow-autocomplete.component.html',
  styleUrls: ['./communication-workflow-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...getObjAutocompleteFormControlBaseProviders(CommunicationWorkflowAutocompleteComponent)],
})
export class CommunicationWorkflowAutocompleteComponent
  extends ObjAutocompleteFormControlBase<CommunicationWorkflow>
  implements OnInit
{
  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public communicationWorkflowService: CommunicationWorkflowService,
  ) {
    super(changeDetectorRef);
  }

  public defineAllOptions(): Observable<CommunicationWorkflow[]> {
    return this.communicationWorkflowService.getDocsArray();
  }
}
