import type { ValidationResult } from 'joi';

import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { CommunicationsRequestName } from '../../../communications-request-name';
import { CommunicationsResponse } from '../../generic/communications-response/communications-response';
import { CommunicationsResponseSchema } from '../../generic/communications-response/communications-response-schema';

import { CommunicationsResponseGetVoiceDeviceTokenConstructor } from './communications-response-get-voice-device-token-constructor';
import { CommunicationsResponseGetVoiceDeviceTokenError } from './communications-response-get-voice-device-token-error';
import { CommunicationsResponseGetVoiceDeviceTokenSchema } from './communications-response-get-voice-device-token-schema';

export class CommunicationsResponseGetVoiceDeviceToken extends CommunicationsResponse<CommunicationsResponseGetVoiceDeviceTokenError> {
  protected token!: string;

  constructor(parameters: CommunicationsResponseGetVoiceDeviceTokenConstructor) {
    super({
      ...parameters,
      [CommunicationsResponseSchema.type]: CommunicationsRequestName.getVoiceDeviceToken,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: ValidationResult): CommunicationsResponseGetVoiceDeviceToken {
    return new CommunicationsResponseGetVoiceDeviceToken(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationsResponseGetVoiceDeviceToken.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationsResponseGetVoiceDeviceTokenSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getToken(): string {
    return this.token;
  }
}
