import type { AnySchema } from 'joi';
import Joi from 'joi';

import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { FormRequestCreateSubmissionSchema } from '../../requests/form-request-create-submission/form-request-create-submission-schema';
import { FormRequestCreateVersionSchema } from '../../requests/form-request-create-version/form-request-create-version-schema';

export class AnyFormRequestSchema extends SerializableObjectSchema {
  private static readonly SCHEMA_ID = 'anyFormRequest';

  private static computedSchema = Joi.alternatives()
    .try(new FormRequestCreateSubmissionSchema().getJoiSchema(), new FormRequestCreateVersionSchema().getJoiSchema())
    .id(AnyFormRequestSchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly type = 'type';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly GenericDefaults = class {
    public static readonly type = undefined;
  };

  public static getSchemaId(): string {
    return `#${AnyFormRequestSchema.SCHEMA_ID}`;
  }

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: AnySchema } | AnySchema {
    return AnyFormRequestSchema.computedSchema;
  }
}
