import moment from 'moment-timezone';

import { ServiceExceptionExecutions } from '../../communication/service-exception/service-exception-executions/service-exception-executions/service-exception-executions';
import { ServiceLimitExecutions } from '../../communication/service-limit/service-limit-executions/service-limit-executions/service-limit-executions';
import { DBDocSchema } from '../../generic/db-doc/db-doc-schema';
import { SchemaField } from '../../generic/serialization/schema-field';
import { IVRResponse } from '../ivr/ivr-response/ivr-response';

export class CallLogSchema extends DBDocSchema {
  // Timestamp when the call is created by the operator
  public static readonly operatorCallCreateTime = 'operatorCallCreateTime';

  // Timestamp when the call is received
  public static readonly incomingCallReceivedTime = 'incomingCallReceivedTime';

  // Timestamp when the incoming call was started by the operator; this is when ringing stops and
  // the first TwiML is executed.
  public static readonly operatorCallStartTime = 'operatorCallStartTime';

  // Timestamp when user enters conference room
  public static readonly serviceRequestTime = 'serviceRequestTime';

  // Timestamp when we first started dialing users, to find one to accept this call
  public static readonly dialerStartTime = 'dialerStartTime';

  // Timestamp when call two parties are connected.
  public static readonly serviceDeliveryStartTime = 'serviceDeliveryStartTime';

  // Timestamp when one party disconnects
  public static readonly serviceDeliveryEndTime = 'serviceDeliveryEndTime';

  // Callee Disconnect Time
  public static readonly calleeDisconnectTime = 'calleeDisconnectTime';

  // The call list is specified under the document callLists/{callListId}
  public static readonly callListId = 'callListId';

  // UserId of the user that answered the call. This field is set to unanswered if the call wasn't answered.
  public static readonly answeredBy = 'answeredBy';

  // SID of the Conference
  public static readonly conferenceSid = 'conferenceSid';

  // SID of the incoming call
  public static readonly incomingCallSid = 'incomingCallSid';

  // List of userIds to call, in order
  public static readonly userIdsToCall = 'userIdsToCall';

  // Caller, Callee, or Server
  public static readonly disconnectedBy = 'disconnectedBy';

  // Language of the client
  public static readonly language = 'language';

  // Disconnect Reason
  public static readonly disconnectReason = 'disconnectReason';

  // The reason we stop making outgoing calls related to this sessionId
  public static readonly stoppedDialingReason = 'stoppedDialingReason';

  // Indicator if there was a critical error associated to this call.
  public static readonly hasCriticalServerError = 'hasCriticalServerError';

  // Id of the data structure we used to identify the caller and block them. Missing if incoming call was not blocked
  public static readonly blockedCallerId = 'blockedCallerId';

  // Indicate if a new blocked caller was created based on this call.
  public static readonly didCreateNewBlockedCaller = 'didCreateNewBlockedCaller';

  // Indicate if the caller was anonymous
  public static readonly hasAnonymousCaller = 'hasAnonymousCaller';

  // The phone number where this call was received
  public static readonly receivedAtE164Phone = 'receivedAtE164Phone';

  // Id of the voicemail, if one was received in reference to this call
  public static readonly voicemailMetadataId = 'voicemailMetadataId';

  // Duration of the call, according to the operator
  public static readonly operatorDurationMS = 'operatorDurationMS';

  // Price unit that the call is billed in.
  public static readonly operatorPriceUnit = 'operatorPriceUnit';

  // Price unit that the call is billed in.
  public static readonly calleeCallAcceptTime = 'calleeCallAcceptTime';

  // IVR Responses received for this call
  public static readonly responses = 'responses';

  public static readonly asyncServiceRequestId = 'asyncServiceRequestId';

  public static readonly serviceExceptionExecutions = 'serviceExceptionExecutions';

  public static readonly serviceLimitExecutions = 'serviceLimitExecutions';

  public static readonly forwardedTo = 'forwardedTo';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'callLogs';
  };

  static Defaults = class {
    public static readonly operatorCallCreateTime = moment(0);

    public static readonly incomingCallReceivedTime = moment(0);

    public static readonly operatorCallStartTime = moment(0);

    public static readonly serviceRequestTime = undefined;

    public static readonly dialerStartTime = undefined;

    public static readonly serviceDeliveryStartTime = undefined;

    public static readonly serviceDeliveryEndTime = undefined;

    public static readonly calleeDisconnectTime = undefined;

    public static readonly callListId = undefined;

    public static readonly answeredBy = undefined;

    public static readonly conferenceSid = undefined;

    public static readonly incomingCallSid = 'missing-incomingCallSid';

    public static readonly userIdsToCall = [];

    public static readonly disconnectedBy = undefined;

    public static readonly language = undefined;

    public static readonly disconnectReason = undefined;

    public static readonly stoppedDialingReason = undefined;

    public static readonly hasCriticalServerError = false;

    public static readonly blockedCallerId = undefined;

    public static readonly didCreateNewBlockedCaller = false;

    public static readonly hasAnonymousCaller = undefined;

    public static readonly receivedAtE164Phone = 'missing-receivedAtE164Phone';

    public static readonly voicemailMetadataId = undefined;

    public static readonly operatorDurationMS = undefined;

    public static readonly operatorPriceUnit = undefined;

    public static readonly calleeCallAcceptTime = undefined;

    public static readonly responses = [];

    public static readonly asyncServiceRequestId = undefined;

    public static readonly serviceExceptionExecutions = undefined;

    public static readonly serviceLimitExecutions = undefined;

    public static readonly forwardedTo = undefined;
  };

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([
      // Default value is the empty list, so always serialize
      CallLogSchema.userIdsToCall,
    ]);
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CallLogSchema.operatorCallCreateTime] = SchemaField.timestamp(
      CallLogSchema.Defaults.operatorCallCreateTime,
    );
    parameters[CallLogSchema.incomingCallReceivedTime] = SchemaField.timestamp(
      CallLogSchema.Defaults.incomingCallReceivedTime,
    );
    parameters[CallLogSchema.operatorCallStartTime] = SchemaField.timestamp(
      CallLogSchema.Defaults.operatorCallStartTime,
    );
    parameters[CallLogSchema.serviceRequestTime] = SchemaField.timestamp(CallLogSchema.Defaults.serviceRequestTime);
    parameters[CallLogSchema.dialerStartTime] = SchemaField.timestamp(CallLogSchema.Defaults.dialerStartTime);
    parameters[CallLogSchema.serviceDeliveryStartTime] = SchemaField.timestamp(
      CallLogSchema.Defaults.serviceDeliveryStartTime,
    );
    parameters[CallLogSchema.serviceDeliveryEndTime] = SchemaField.timestamp(
      CallLogSchema.Defaults.serviceDeliveryEndTime,
    );
    parameters[CallLogSchema.calleeDisconnectTime] = SchemaField.timestamp(CallLogSchema.Defaults.calleeDisconnectTime);
    parameters[CallLogSchema.callListId] = SchemaField.string(CallLogSchema.Defaults.callListId);
    parameters[CallLogSchema.answeredBy] = SchemaField.string(CallLogSchema.Defaults.answeredBy);
    parameters[CallLogSchema.conferenceSid] = SchemaField.string(CallLogSchema.Defaults.conferenceSid);
    parameters[CallLogSchema.incomingCallSid] = SchemaField.string(CallLogSchema.Defaults.incomingCallSid);
    parameters[CallLogSchema.userIdsToCall] = SchemaField.shortStringArray(CallLogSchema.Defaults.userIdsToCall);
    parameters[CallLogSchema.disconnectedBy] = SchemaField.string(CallLogSchema.Defaults.disconnectedBy);
    parameters[CallLogSchema.language] = SchemaField.string(CallLogSchema.Defaults.language);
    parameters[CallLogSchema.disconnectReason] = SchemaField.string(CallLogSchema.Defaults.disconnectReason);
    parameters[CallLogSchema.stoppedDialingReason] = SchemaField.string(CallLogSchema.Defaults.stoppedDialingReason);
    parameters[CallLogSchema.hasCriticalServerError] = SchemaField.boolean(
      CallLogSchema.Defaults.hasCriticalServerError,
    );
    parameters[CallLogSchema.blockedCallerId] = SchemaField.string(CallLogSchema.Defaults.blockedCallerId);
    parameters[CallLogSchema.didCreateNewBlockedCaller] = SchemaField.boolean(
      CallLogSchema.Defaults.didCreateNewBlockedCaller,
    );
    parameters[CallLogSchema.hasAnonymousCaller] = SchemaField.boolean(CallLogSchema.Defaults.hasAnonymousCaller);
    parameters[CallLogSchema.receivedAtE164Phone] = SchemaField.string(CallLogSchema.Defaults.receivedAtE164Phone);
    parameters[CallLogSchema.voicemailMetadataId] = SchemaField.string(CallLogSchema.Defaults.voicemailMetadataId);
    parameters[CallLogSchema.operatorDurationMS] = SchemaField.number(CallLogSchema.Defaults.operatorDurationMS);
    parameters[CallLogSchema.operatorPriceUnit] = SchemaField.string(CallLogSchema.Defaults.operatorPriceUnit);
    parameters[CallLogSchema.calleeCallAcceptTime] = SchemaField.timestamp(CallLogSchema.Defaults.calleeCallAcceptTime);
    parameters[CallLogSchema.responses] = SchemaField.shortObjArray(IVRResponse, CallLogSchema.Defaults.responses);
    parameters[CallLogSchema.asyncServiceRequestId] = SchemaField.autoGeneratedDocId(
      CallLogSchema.Defaults.asyncServiceRequestId,
    ).optional();
    parameters[CallLogSchema.serviceExceptionExecutions] = SchemaField.obj(
      ServiceExceptionExecutions,
      CallLogSchema.Defaults.serviceExceptionExecutions,
    ).optional();
    parameters[CallLogSchema.serviceLimitExecutions] = SchemaField.obj(
      ServiceLimitExecutions,
      CallLogSchema.Defaults.serviceLimitExecutions,
    ).optional();
    parameters[CallLogSchema.forwardedTo] = SchemaField.string(CallLogSchema.Defaults.forwardedTo).optional();
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${CallLogSchema.Constants.collection}`;
  }
}
