import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { isNil, TranslocoModule } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BehaviorSubject, combineLatest, defer, firstValueFrom, map, shareReplay, startWith } from 'rxjs';

import { CallList, CommunicationWorkflow, ConversationConfig } from '@pwp-common';

import { provideValueAccessor } from '../../../../../../common/form/provide-value-accessor';
import { FormGroupControlValueAccessor } from '../../../../../../components/generic/abstract-classes/form-group-control-value-accessor';
import { CallListService } from '../../../../../../services/call/call-list/call-list.service';
import { CommunicationWorkflowService } from '../../../../../../services/communication/communication-workflow/communication-workflow.service';
import { ConversationConfigService } from '../../../../../../services/conversation/conversation-config/conversation-config.service';

import { createAutocompleteGroup } from './helpers/create-autocomplete-group/create-autocomplete-group';
import { AutocompleteGroup, AutocompleteItem } from './helpers/create-autocomplete-group/interfaces';

@UntilDestroy()
@Component({
  selector: 'app-automation-trigger-autocomplete',
  standalone: true,
  templateUrl: './automation-trigger-autocomplete.component.html',
  styleUrls: ['./automation-trigger-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AutoCompleteModule, CommonModule, FormsModule, MatIconModule, ReactiveFormsModule, TranslocoModule],
  providers: [provideValueAccessor(AutomationTriggerAutocompleteComponent)],
})
export class AutomationTriggerAutocompleteComponent extends FormGroupControlValueAccessor<
  AutocompleteGroup[],
  string[]
> {
  private static id = 0;

  private readonly callListService = inject(CallListService);

  private readonly communicationWorkflowService = inject(CommunicationWorkflowService);

  private readonly conversationConfigService = inject(ConversationConfigService);

  private readonly group$ = combineLatest([
    this.callListService.getDocsArray(),
    this.communicationWorkflowService.getDocsArray(),
    this.conversationConfigService.getDocsArray(),
  ]).pipe(
    map(([callLists, communicationWorkflows, conversationConfigs]) => [
      createAutocompleteGroup(callLists, CallList),
      createAutocompleteGroup(communicationWorkflows, CommunicationWorkflow),
      createAutocompleteGroup(conversationConfigs, ConversationConfig),
    ]),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private readonly query$ = new BehaviorSubject<string>('');

  private readonly selectedValues$ = defer(() =>
    this.form.valueChanges.pipe(
      startWith<AutocompleteItem[]>([]),
      map((items) => items.map((item) => item.value)),
    ),
  );

  public readonly defaultId = `app-automation-trigger-autocomplete-${(AutomationTriggerAutocompleteComponent.id += 1)}`;

  public readonly form = new FormControl<AutocompleteItem[]>([]);

  public readonly suggestions$ = combineLatest([this.group$, this.query$, this.selectedValues$]).pipe(
    map(([groups, query, selectedValues]) =>
      groups.map((group) => ({
        ...group,
        items: group.items.filter(
          (item) => !selectedValues.includes(item.value) && item.label.toLowerCase().includes(query.toLowerCase()),
        ),
      })),
    ),
  );

  @Input() public inputId?: string;

  // eslint-disable-next-line class-methods-use-this
  protected parseValueChange(items: AutocompleteItem[]): string[] {
    return items.map((item) => item.value);
  }

  public async writeValue(selectedValues: string[]): Promise<void> {
    if (isNil(selectedValues)) {
      this.form.setValue([]);

      return;
    }

    const items = await Promise.all(
      selectedValues.map(async (value) => {
        const groups = await firstValueFrom(this.group$);

        return groups.flatMap((group) => group.items).find((item) => item.value === value);
      }),
    );

    this.form.setValue(items);
  }

  public search(event: { query: string }): void {
    this.query$.next(event.query);
  }
}
