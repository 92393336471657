import { orderBy } from 'lodash';
import moment from 'moment-timezone';

import { GLOBAL_TIMEZONE } from '../../../helper/constants';
import { Interval } from '../../../helper/interval';
import { LabeledData } from '../display/labeled-data';
import { EntityStats } from '../entity-stats/entity-stats';
import { EntityStatsSchema } from '../entity-stats/entity-stats-schema';

import { getIntervalForWindow } from './entity-stats-display-window/entity-stats-display-window';
import { EntityStatsDisplayWindow } from './entity-stats-display-window/enum';

export abstract class EntityStatsDisplay {
  protected window?: EntityStatsDisplayWindow;

  protected interval?: Interval;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(entityStats: EntityStats, window?: EntityStatsDisplayWindow) {
    if (window !== undefined) {
      this.window = window;
      this.interval = getIntervalForWindow(window);
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Get Arrays
  /////////////////////////////////////////////////////////////////////////////

  public getLabeledData(mapOfValues: Map<string, number>): LabeledData {
    if (mapOfValues.size < 1) {
      return new LabeledData([], []);
    }

    // Sort alphabetically, increasing order
    let keys = orderBy(Array.from(mapOfValues.keys()), [String], ['asc']);

    // If the keys are months, then sort chronologically increasing order
    if (moment(keys[0], EntityStatsSchema.Constants.monthFormat, GLOBAL_TIMEZONE).isValid()) {
      keys = orderBy(
        keys,
        (month) => moment(month, EntityStatsSchema.Constants.monthFormat, GLOBAL_TIMEZONE).valueOf(),
        ['asc'],
      );
    }

    const values: number[] = [];
    for (const key of keys) {
      values.push(mapOfValues.get(key)!);
    }

    return new LabeledData(keys, values);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Is Month Contained In Interval
  /////////////////////////////////////////////////////////////////////////////

  public isMonthContainedInWindow(monthStr: string): boolean {
    const parsed = moment.tz(monthStr, EntityStatsSchema.Constants.monthFormat, GLOBAL_TIMEZONE);
    return this.interval!.contains(parsed);
  }
}
