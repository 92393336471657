import { isNil } from 'lodash';

import { AssignedUserType } from '@pwp-common';

import { AllRequestsForOneEventSlotSummary } from '../../../common/all-requests-for-one-event-slot-summary';

import { AllRequestsForOneEventSlotSummaryData } from './interface';

export const getSummaryData = (summary: AllRequestsForOneEventSlotSummary): AllRequestsForOneEventSlotSummaryData => {
  const eventData = summary.allDataEvent.getEventData();
  const numberOfRequests = summary.allDataEvent.getRequests().length;

  if (isNil(eventData)) {
    return {
      isEventDeleted: true,
      numberOfRequests,
    };
  }

  const slot = summary.assignedUserType ?? AssignedUserType.primary;
  const assignedUserId =
    slot === AssignedUserType.primary ? eventData.getAssignedUserId() : eventData.getAssignedBackupUserId();
  const assignedTo =
    summary.allDataEvent.getAllDataUserMap().get(assignedUserId)?.userData.getDisplayNameWithEmail() ?? null;

  return {
    assignedTo,
    isEventDeleted: false,
    numberOfRequests,
    slot,
  };
};
