import { cloneDeep, isNil } from 'lodash';
import moment from 'moment-timezone';

import { CallLogSchema } from '../../../call/call-log/call-log-schema';
import { isNilOrDefault } from '../../../generic/serialization/is-nil-or-default';
import { SerializableObject } from '../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';
import { CommunicationSessionEventLogDialPhoneCallType } from '../../communication-session-event-log/communication-session-event-log/events/communication-session-event-log-dial-phone/generic/communication-session-event-log-dial-phone-call-type';
import { ServiceLimitLogSessionCompleteData } from '../service-limit/service-limit-log-session-complete-data';

import { ServiceLimitServiceUsageConstructor } from './service-limit-service-usage-constructor';
import { ServiceLimitServiceUsageSchema } from './service-limit-service-usage-schema';

const CALL_LOG_SCHEMA = new CallLogSchema();
export class ServiceLimitServiceUsage extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected inboundCallServiceDeliveredNum!: number;

  protected inboundCallServiceRequestedNum!: number;

  protected outboundCallServiceDeliveredNum!: number;

  protected outboundCallServiceRequestedNum!: number;

  protected windowStartTime!: moment.Moment | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ServiceLimitServiceUsageConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ServiceLimitServiceUsage {
    const obj = new ServiceLimitServiceUsage(super._deserialize(validationResult));
    return obj;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(ServiceLimitServiceUsage.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ServiceLimitServiceUsageSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getInboundCallServiceDeliveredNum(): number {
    return cloneDeep(this.inboundCallServiceDeliveredNum);
  }

  public getInboundCallServiceRequestedNum(): number {
    return cloneDeep(this.inboundCallServiceRequestedNum);
  }

  public getWindowStartTime(): moment.Moment | undefined {
    return cloneDeep(this.windowStartTime);
  }

  public getOutboundCallServiceDeliveredNum(): number {
    return cloneDeep(this.outboundCallServiceDeliveredNum);
  }

  public getOutboundCallServiceRequestedNum(): number {
    return cloneDeep(this.outboundCallServiceRequestedNum);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Complex Getters
  /////////////////////////////////////////////////////////////////////////////

  public getServiceDeliveredNum(): number {
    return this.inboundCallServiceDeliveredNum + this.outboundCallServiceDeliveredNum;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Reset State
  /////////////////////////////////////////////////////////////////////////////

  public reset() {
    this.inboundCallServiceDeliveredNum = 0;
    this.inboundCallServiceRequestedNum = 0;
    this.outboundCallServiceDeliveredNum = 0;
    this.outboundCallServiceRequestedNum = 0;
    this.windowStartTime = moment();
    return this;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Increment For Inbound Call
  /////////////////////////////////////////////////////////////////////////////
  public logInboundCallSessionComplete(data: ServiceLimitLogSessionCompleteData) {
    if (!isNil(data?.callLog)) {
      switch (data.mode) {
        case 'callComplete': {
          if (!isNilOrDefault(data.callLog.getCallListId(), CallLogSchema.callListId, CALL_LOG_SCHEMA)) {
            this.inboundCallServiceRequestedNum += 1;
          }
          if (data.callLog.wasClientConnectedToUser()) {
            this.inboundCallServiceDeliveredNum += 1;
          }
          break;
        }
        case 'asyncServiceRequestCreated': {
          if (
            !isNilOrDefault(
              data.callLog.getAsyncServiceRequestId(),
              CallLogSchema.asyncServiceRequestId,
              CALL_LOG_SCHEMA,
            )
          ) {
            this.outboundCallServiceRequestedNum += 1;
          }
          break;
        }
      }
    }
    if (!isNil(data?.asyncServiceRequestDelivery)) {
      const isServiceDeliveryAttempt =
        data?.asyncServiceRequestDelivery?.getCallType() ===
        CommunicationSessionEventLogDialPhoneCallType.asyncServiceRequestDelivery;
      if (isServiceDeliveryAttempt) {
        this.outboundCallServiceDeliveredNum += 1;
      }
    }
    return this;
  }
}
