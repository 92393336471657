import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { loadingFor } from '@ngneat/loadoff';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';

import {
  CommunicationWidgetEnforceServiceLimit,
  CommunicationWidgetEnforceServiceLimitSchema,
  CommunicationWidgetName,
} from '@pwp-common';

import { CommunicationWidgetEnforceServiceLimitService } from '../../../services/communication/communication-widget/communication-widget-enforce-service-limit/communication-widget-enforce-service-limit.service';
import { makeAuditEntry } from '../../generic/settings/common/audit-entry/helpers/make-audit-entry';
import { AuditEntry } from '../../generic/settings/common/audit-entry/interfaces';

@UntilDestroy()
@Component({
  selector: 'app-org-settings-enforce-service-limit',
  templateUrl: './org-settings-enforce-service-limit.component.html',
  styleUrls: ['./org-settings-enforce-service-limit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgSettingsEnforceServiceLimitComponent implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  private dbCommunicationWidget: CommunicationWidgetEnforceServiceLimit;

  auditEntry: AuditEntry;

  loading = loadingFor('cwESL');

  form = new UntypedFormGroup({
    serviceLimitExceededAudioId: new UntypedFormControl(),
  });

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private cwESLService: CommunicationWidgetEnforceServiceLimitService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.cwESLService
      .getDefaultDoc()
      .pipe(
        this.loading.cwESL.track(),
        untilDestroyed(this),
        map((z) => {
          this.dbCommunicationWidget = z;
          this.resetUI();
        }),
      )
      .subscribe();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Upload
  ///////////////////////////////////////////////////////////////////////////////////////////

  doUpload = async () => this._doUpload();

  async _doUpload() {
    const obj = new CommunicationWidgetEnforceServiceLimit({
      id: this.dbCommunicationWidget?.getId(),
      type: CommunicationWidgetName.enforceServiceLimit,
      serviceLimitExceededAudioId: this.serviceLimitExceededAudioId?.value,
      description: CommunicationWidgetEnforceServiceLimitSchema.Defaults.description,
      displayName: CommunicationWidgetEnforceServiceLimitSchema.Defaults.displayName,
    });
    await this.cwESLService.uploadDefaultDoc(obj);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Refresh Data
  ///////////////////////////////////////////////////////////////////////////////////////////

  refreshData = () => this._refreshData();

  async _refreshData() {}

  resetUI() {
    const formValue = {} as any;
    formValue[CommunicationWidgetEnforceServiceLimitSchema.serviceLimitExceededAudioId] =
      this.dbCommunicationWidget?.getServiceLimitExceededAudioId();
    this.form.patchValue(formValue);
    this.auditEntry = makeAuditEntry(this.dbCommunicationWidget);
    this.changeDetectorRef.detectChanges();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Reset Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  resetForm = () => this._resetForm();

  _resetForm() {
    this.resetUI();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Controls
  /////////////////////////////////////////////////////////////////////////////////////////////

  get serviceLimitExceededAudioId() {
    return this.form.get(
      CommunicationWidgetEnforceServiceLimitSchema.serviceLimitExceededAudioId,
    ) as UntypedFormControl;
  }
}
