import Joi from 'joi';

import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { CommunicationsResponseCreateAsyncServiceRequestSchema } from '../../responses/communications-response-create-async-service-request/communications-response-create-async-service-request-schema';
import { CommunicationsResponseCreateOutboundCallSessionSchema } from '../../responses/communications-response-create-outbound-call-session/communications-response-create-outbound-call-session-schema';
import { CommunicationsResponseDialAsyncServiceRequestSchema } from '../../responses/communications-response-dial-async-service-request/communications-response-dial-async-service-request-schema';
import { CommunicationsResponseDialConferenceSchema } from '../../responses/communications-response-dial-conference/communications-response-dial-conference-schema';
import { CommunicationsResponseDialPhoneSchema } from '../../responses/communications-response-dial-phone/communications-response-dial-phone-schema';
import { CommunicationsResponseGetCallLogPIISchema } from '../../responses/communications-response-get-call-log-pii/communications-response-get-call-log-pii-schema';
import { CommunicationsResponseGetConversationLogPIISchema } from '../../responses/communications-response-get-conversation-log-pii/communications-response-get-conversation-log-pii-schema';
import { CommunicationsResponseGetVoiceDeviceTokenSchema } from '../../responses/communications-response-get-voice-device-token/communications-response-get-voice-device-token-schema';
import { CommunicationsResponseHandleAsyncServiceRequestSchema } from '../../responses/communications-response-handle-async-service-request/communications-response-handle-async-service-request-schema';
import { CommunicationsResponseOnTaskRouterConfigurationUpdatedSchema } from '../../responses/communications-response-on-task-router-configuration-updated/communications-response-on-task-router-configuration-updated-schema';

export class AnyCommunicationsResponseSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The type of the entity
  public static readonly type = 'type';

  private static readonly SCHEMA_ID = 'anyCommunicationsResponse';

  private static computedSchema = Joi.alternatives()
    .try(
      ...[
        new CommunicationsResponseCreateAsyncServiceRequestSchema().getJoiSchema(),
        new CommunicationsResponseCreateOutboundCallSessionSchema().getJoiSchema(),
        new CommunicationsResponseDialAsyncServiceRequestSchema().getJoiSchema(),
        new CommunicationsResponseDialConferenceSchema().getJoiSchema(),
        new CommunicationsResponseDialPhoneSchema().getJoiSchema(),
        new CommunicationsResponseGetCallLogPIISchema().getJoiSchema(),
        new CommunicationsResponseGetConversationLogPIISchema().getJoiSchema(),
        new CommunicationsResponseGetVoiceDeviceTokenSchema().getJoiSchema(),
        new CommunicationsResponseHandleAsyncServiceRequestSchema().getJoiSchema(),
        new CommunicationsResponseOnTaskRouterConfigurationUpdatedSchema().getJoiSchema(),
      ],
    )
    .id(AnyCommunicationsResponseSchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static GenericDefaults = class {
    // The name of the command
    public static readonly type = undefined;
  };

  /**
   * Always serialize the command name
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  public static getSchemaId(): string {
    return `#${AnyCommunicationsResponseSchema.SCHEMA_ID}`;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } | import('joi').AnySchema {
    return AnyCommunicationsResponseSchema.computedSchema;
  }
}
