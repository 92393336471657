import { cloneDeep, orderBy } from 'lodash';
import moment from 'moment-timezone';

import { CallLog } from '../../call/call-log/call-log';
import { AssignedUserType } from '../../event/event-data/enums';
import { EventData } from '../../event/event-data/event-data';
import { DBDocObject } from '../../generic/db-doc/db-doc-object';
import { DBDocSchema } from '../../generic/db-doc/db-doc-schema';
import { AllDataUser } from '../../user/all-data-user/all-data-user';
import { EntityStatsChunk } from '../entity-stats-chunk/entity-stats-chunk';

import { EntityStatsConstructor } from './entity-stats-constructor';
import { EntityStatsSchema } from './entity-stats-schema';
import { EntityStatsType } from './enums';

/**
 * This class represents statistics about a collection of events of the same type.
 */
export class EntityStats extends DBDocObject {
  /////////////////////////////////////////////////////////////////////////////
  // Serialized Variables
  /////////////////////////////////////////////////////////////////////////////

  protected byMonth!: Map<string, EntityStatsChunk>;

  protected bySlidingWindow!: Map<string, EntityStatsChunk>;

  protected total!: EntityStatsChunk;

  protected type!: EntityStatsType;

  protected numUsersAnalyzed!: number;

  /////////////////////////////////////////////////////////////////////////////
  // Private Vars used for calculation
  /////////////////////////////////////////////////////////////////////////////

  private usersThatAnsweredCalls!: Set<string>;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: EntityStatsConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): EntityStats {
    return new EntityStats(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(EntityStats.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new EntityStatsSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Gettters
  /////////////////////////////////////////////////////////////////////////////

  public getByMonth(): Map<string, EntityStatsChunk> {
    return cloneDeep(this.byMonth);
  }

  public getBySlidingWindow(): Map<string, EntityStatsChunk> {
    return cloneDeep(this.bySlidingWindow);
  }

  public getTotal(): EntityStatsChunk {
    return cloneDeep(this.total);
  }

  public getType(): EntityStatsType {
    return cloneDeep(this.type);
  }

  public getNumUsersAnalyzed(): number {
    return cloneDeep(this.numUsersAnalyzed);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Analytics
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Return all months for which we have stats, in ascending order.
   */
  public getSortedMonths(): string[] {
    // Get months in order
    return orderBy(
      Array.from(this.getByMonth().keys()),
      (z) => moment(z, EntityStatsSchema.Constants.monthFormat).valueOf(),
      ['asc'],
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Update Num Users Analyzed
  ////////////////////////////////////////////////////////////////////////////////////////////////

  private updateNumUsersAnalyzed(userId: string | undefined) {
    if (userId === undefined) {
      return;
    }

    if (this.usersThatAnsweredCalls === undefined) {
      this.usersThatAnsweredCalls = new Set();
    }

    this.usersThatAnsweredCalls.add(userId);
    this.numUsersAnalyzed = this.usersThatAnsweredCalls.size;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Update Call Stats
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Update this object to include the given call
   * @param callLog callLog to update the object with
   */
  public updateWithCall(callLog: CallLog, allDataUserMap: Map<string, AllDataUser>, timezone: string): EntityStats {
    if (!this.shouldProcessCall(callLog, allDataUserMap)) {
      return this;
    }

    // Update numUsersAnalyzed
    this.updateNumUsersAnalyzed(callLog.getAnsweredBy());

    this.total.updateWithCall(callLog);

    // Update byMonth
    const monthKey = callLog.getIncomingCallReceivedTime().tz(timezone).format(EntityStatsSchema.Constants.monthFormat);
    if (this.byMonth.get(monthKey) === undefined) {
      this.byMonth.set(monthKey, EntityStatsChunk.deserialize({}));
    }
    this.byMonth.get(monthKey)!.updateWithCall(callLog); // modify in place

    // Update the sliding windows.
    const numDaysInPast = moment.tz(timezone).diff(callLog.getIncomingCallReceivedTime(), 'days');
    for (const numDaysInWindow of EntityStatsSchema.Constants.slidingWindowDurationDays) {
      if (numDaysInPast > numDaysInWindow) {
        continue;
      }
      const windowKey = EntityStatsSchema.Constants.slidingWindowKeyName(numDaysInWindow);
      if (this.bySlidingWindow.get(windowKey) === undefined) {
        this.bySlidingWindow.set(windowKey, EntityStatsChunk.deserialize({}));
      }
      this.bySlidingWindow.get(windowKey)!.updateWithCall(callLog);
    }
    return this;
  }

  /**
   * Return true if the call should be included in this entity stats object, and
   * false if it is out of scope.
   *
   * For example, if this.type = EntityStatsType.oneUser, then only calls answered by this user
   * should be included. In comparison, all calls should be included for EntityStatsType.org
   *
   * @param call
   * @param allDataUserMap
   */
  public shouldProcessCall(call: CallLog, allDataUserMap: Map<string, AllDataUser>): boolean {
    const id = this.getId();
    if (id === DBDocSchema.GenericDefaults.id || id === undefined) {
      throw new Error(
        `EntityStats.shouldProcessCall: Invalid initialization. Cannot proceed because id is not specified. id=${id}`,
      );
    }

    const answeredByRoles = allDataUserMap.get(call.getAnsweredBy() || 'missing-answeredBy')?.roles;

    switch (this.type) {
      case EntityStatsType.org: {
        return true;
      }
      case EntityStatsType.orgAdmin: {
        if (answeredByRoles === undefined) {
          return false;
        }
        return answeredByRoles.isOrgAdmin();
      }
      case EntityStatsType.notOrgAdmin: {
        if (answeredByRoles === undefined) {
          return false;
        }
        return !answeredByRoles.isOrgAdmin();
      }
      case EntityStatsType.oneUser: {
        if (answeredByRoles === undefined) {
          return false;
        }
        return answeredByRoles.getUserId() === this.id;
      }
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Update Event Stats
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Update this object to include the given event
   * @param callLog callLog to update the object with
   */
  public updateWithEvent(
    event: EventData,
    allDataUserMap: Map<string, AllDataUser>,
    assignedUserType: AssignedUserType,
    timezone: string,
  ): EntityStats {
    if (!this.shouldProcessEvent(event, assignedUserType, allDataUserMap)) {
      return this;
    }

    // Update numUsersAnalyzed
    this.updateNumUsersAnalyzed(event.getUser(assignedUserType));
    this.total.updateWithEvent(event, assignedUserType);

    // Update byMonth
    const monthKey = event.getStart().tz(timezone).format(EntityStatsSchema.Constants.monthFormat);
    if (this.byMonth.get(monthKey) === undefined) {
      this.byMonth.set(monthKey, EntityStatsChunk.deserialize({}));
    }

    this.byMonth.get(monthKey)!.updateWithEvent(event, assignedUserType);

    //  Update the sliding windows.
    const numDaysInPast = moment.tz(timezone).diff(event.getStart(), 'days');
    for (const numDaysInWindow of EntityStatsSchema.Constants.slidingWindowDurationDays) {
      if (numDaysInPast > numDaysInWindow) {
        continue;
      }
      const windowKey = EntityStatsSchema.Constants.slidingWindowKeyName(numDaysInWindow);
      if (this.bySlidingWindow.get(windowKey) === undefined) {
        this.bySlidingWindow.set(windowKey, EntityStatsChunk.deserialize({}));
      }
      this.bySlidingWindow.get(windowKey)!.updateWithEvent(event, assignedUserType);
    }

    return this;
  }

  /**
   * Return true if the assignedUserType should be included in this entity stats object, and
   * false if it is out of scope.
   *
   * For example, if this.type = EntityStatsType.oneUser, then return true only if this user
   * is the assigned user.
   *
   * @param eventData
   * @param assignedUserType
   * @param allDataUserMap
   */
  public shouldProcessEvent(
    eventData: EventData,
    assignedUserType: AssignedUserType,
    allDataUserMap: Map<string, AllDataUser>,
  ): boolean {
    const id = this.getId();
    if (id === DBDocSchema.GenericDefaults.id || id === undefined) {
      throw new Error(
        `EntityStats.shouldProcessEvent: Invalid initialization. Cannot proceed because id is not specified. id=${id}`,
      );
    }

    const userId = eventData.getUser(assignedUserType);
    if (EventData.isNilOrDefaultUser(userId)) {
      return false;
    }
    const roles = allDataUserMap.get(userId || 'missing-answeredBy')?.roles;

    switch (this.type) {
      case EntityStatsType.org: {
        return true;
      }
      case EntityStatsType.orgAdmin: {
        if (roles === undefined) {
          return false;
        }
        return roles.isOrgAdmin();
      }
      case EntityStatsType.notOrgAdmin: {
        if (roles === undefined) {
          return false;
        }
        return !roles.isOrgAdmin();
      }
      case EntityStatsType.oneUser: {
        return userId === this.id;
      }
    }
  }

  public getChunkByPeriod(period: string): EntityStatsChunk | undefined {
    if (period === 'total') {
      return this.getTotal();
    }

    return this.getByMonth().get(period);
  }
}
