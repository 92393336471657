import type { ValidationResult } from 'joi';

import { AnySerializableObject } from '../../../../../objects/generic/serialization/any-serializable-object/any-serializable-object';
import { ObjectBuilder } from '../../../../../objects/generic/serialization/interfaces';
import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { CommunicationsRequestName } from '../../../communications-request-name';
import { CommunicationsResponseCreateAsyncServiceRequest } from '../../responses/communications-response-create-async-service-request/communications-response-create-async-service-request';
import { CommunicationsResponseCreateOutboundCallSession } from '../../responses/communications-response-create-outbound-call-session/communications-response-create-outbound-call-session';
import { CommunicationsResponseDialAsyncServiceRequest } from '../../responses/communications-response-dial-async-service-request/communications-response-dial-async-service-request';
import { CommunicationsResponseDialConference } from '../../responses/communications-response-dial-conference/communications-response-dial-conference';
import { CommunicationsResponseDialPhone } from '../../responses/communications-response-dial-phone/communications-response-dial-phone';
import { CommunicationsResponseGetCallLogPII } from '../../responses/communications-response-get-call-log-pii/communications-response-get-call-log-pii';
import { CommunicationsResponseGetConversationLogPII } from '../../responses/communications-response-get-conversation-log-pii/communications-response-get-conversation-log-pii';
import { CommunicationsResponseGetVoiceDeviceToken } from '../../responses/communications-response-get-voice-device-token/communications-response-get-voice-device-token';
import { CommunicationsResponseHandleAsyncServiceRequest } from '../../responses/communications-response-handle-async-service-request/communications-response-handle-async-service-request';
import { CommunicationsResponseOnTaskRouterConfigurationUpdated } from '../../responses/communications-response-on-task-router-configuration-updated/communications-response-on-task-router-configuration-updated';

import { AnyCommunicationsResponseSchema } from './any-communications-response-schema';

export abstract class AnyCommunicationsResponse extends AnySerializableObject {
  protected static getObjectBuilder(validationResult: ValidationResult): ObjectBuilder {
    const typeString = validationResult.value[AnyCommunicationsResponseSchema.type];
    const type = CommunicationsRequestName[typeString as keyof typeof CommunicationsRequestName];

    if (type === undefined) {
      throw new Error(
        `AnyCommunicationsResponseUser._deserialize: User Error: Cannot deserialize obj with type=${typeString}`,
      );
    }

    switch (type) {
      case CommunicationsRequestName.createAsyncServiceRequest:
        return CommunicationsResponseCreateAsyncServiceRequest;

      case CommunicationsRequestName.createOutboundCallSession:
        return CommunicationsResponseCreateOutboundCallSession;

      case CommunicationsRequestName.dialAsyncServiceRequest:
        return CommunicationsResponseDialAsyncServiceRequest;

      case CommunicationsRequestName.dialConference:
        return CommunicationsResponseDialConference;

      case CommunicationsRequestName.dialPhone:
        return CommunicationsResponseDialPhone;

      case CommunicationsRequestName.getCallLogPII:
        return CommunicationsResponseGetCallLogPII;

      case CommunicationsRequestName.getConversationLogPII:
        return CommunicationsResponseGetConversationLogPII;

      case CommunicationsRequestName.getVoiceDeviceToken:
        return CommunicationsResponseGetVoiceDeviceToken;

      case CommunicationsRequestName.handleAsyncServiceRequest:
        return CommunicationsResponseHandleAsyncServiceRequest;

      case CommunicationsRequestName.onTaskRouterConfigurationUpdated:
        return CommunicationsResponseOnTaskRouterConfigurationUpdated;

      default:
        throw new Error(`AnyCommunicationsResponse._deserialize Unknown type: ${type}`);
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new AnyCommunicationsResponseSchema();
  }
}
