import type { ValidationResult } from 'joi';

import { AutomationRequestName } from '../../../automation-request-name';
import { AutomationResponse } from '../../generic/automation-response/automation-response';
import { AutomationResponseSchema } from '../../generic/automation-response/automation-response-schema';

import { AutomationResponseCreateAutomationConstructor } from './automation-response-create-automation-constructor';
import { AutomationResponseCreateAutomationError } from './automation-response-create-automation-error';
import { AutomationResponseCreateAutomationSchema } from './automation-response-create-automation-schema';

export class AutomationResponseCreateAutomation extends AutomationResponse<AutomationResponseCreateAutomationError> {
  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: AutomationResponseCreateAutomationConstructor) {
    super({
      ...parameters,
      [AutomationResponseSchema.type]: AutomationRequestName.createAutomation,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  protected static _deserialize(validationResult: ValidationResult): AutomationResponseCreateAutomation {
    return new AutomationResponseCreateAutomation(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): AutomationResponseCreateAutomationSchema {
    return new AutomationResponseCreateAutomationSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(AutomationResponseCreateAutomation.getSchema());
  }
}
