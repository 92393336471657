import { values } from 'lodash';

import { GenericDisplayableSchema } from '../../../../generic/displayable/generic-displayable-schema';
import { SchemaField } from '../../../../generic/serialization/schema-field';
import { CommunicationWidgetName } from '../communication-widget-name';

export class CommunicationWidgetSchema extends GenericDisplayableSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly type = 'type';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'communicationWidgets';
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;

    public static readonly description = 'missing-description';

    public static readonly displayName = 'missing-displayName';
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationWidgetSchema.type] = SchemaField.string(
      CommunicationWidgetSchema.type,
      CommunicationWidgetSchema.Defaults.type,
    )
      .required()
      .valid(...values(CommunicationWidgetName));
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${CommunicationWidgetSchema.Constants.collection}`;
  }
}
