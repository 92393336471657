import { isNil } from 'lodash';

import { SupportedLanguages } from '../../../../voice-response-command/vrc-audio-metadata/supported-languages';
import { normalizeSpeechResult } from '../../../ivr/helper/normalize-speech-result';

const AUTOMATED_SYSTEM_KEYWORDS = [
  'message',
  'messages',
  'messaging',
  'press',
  'voicemail',
  'phone',
  'mailbox',
  'recording',
  'available',
  'unavailable',
  'forwarded',
  'forward',
];

/**
 * Guess if a call was answered live or by a machine based on the given speech. If no
 * reasonable guess can be made then return undefined.
 * @param receivedSpeech
 * @param receivedDigits
 */
export const speechDigitsProducedByMachine = (
  receivedSpeech: string | undefined,
  receivedDigits: string | undefined,
): boolean | undefined => {
  if (!isNil(receivedDigits) && receivedDigits.length > 0) {
    // Assume that if digits were received, then the call was answered live
    return false;
  }

  if (isNil(receivedSpeech) || receivedSpeech.length === 0) {
    // Cannot make a guess, since there is no data.
    return undefined;
  }

  const normalizedSpeech = normalizeSpeechResult(receivedSpeech, SupportedLanguages.en);
  if (!normalizedSpeech) {
    return undefined;
  }

  const bagOfWords = new Set(normalizedSpeech.split(' '));

  for (const keyword of AUTOMATED_SYSTEM_KEYWORDS) {
    if (bagOfWords.has(keyword)) {
      return true;
    }
  }

  return false;
};
