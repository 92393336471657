import { values } from 'lodash';

import { DBDocSchema } from '../../../../generic/db-doc/db-doc-schema';
import { SchemaField } from '../../../../generic/serialization/schema-field';
import { ServiceableAddressName } from '../serviceable-address-name';

export class ServiceableAddressSchema extends DBDocSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The name of the command
  public static readonly type = 'type';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'serviceableAddresses';
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////
  static Defaults = class {
    public static readonly type = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[ServiceableAddressSchema.type] = SchemaField.string(
      ServiceableAddressSchema.type,
      ServiceableAddressSchema.Defaults.type,
    ).valid(...values(ServiceableAddressName));
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////
  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${ServiceableAddressSchema.Constants.collection}`;
  }
}
