import type { ValidationResult } from 'joi';
import { cloneDeep } from 'lodash';

import { TimeSelection } from '../../core/time-selection/time-selection/time-selection';
import { VoiceResponseCommandName } from '../any-voice-response-command/voice-response-command-name';
import { VoiceResponseCommand } from '../generic/voice-response-command';

import { VRCIfTimeConstructor } from './voice-response-command-if-time-constructor';
import { VRCIfTimeSchema } from './voice-response-command-if-time-schema';

export class VRCIfTime extends VoiceResponseCommand {
  private timeSelection!: TimeSelection;

  private commands!: VoiceResponseCommand[];

  constructor(parameters: VRCIfTimeConstructor) {
    super({
      ...parameters,
      commandName: VoiceResponseCommandName.ifTime,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  protected static _deserialize(validationResult: ValidationResult): VRCIfTime {
    return new VRCIfTime(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(VRCIfTime.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): VRCIfTimeSchema {
    return new VRCIfTimeSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getTimeSelection(): TimeSelection {
    return cloneDeep(this.timeSelection);
  }

  public getCommands(): VoiceResponseCommand[] {
    return cloneDeep(this.commands);
  }
}
