import { ObserversModule } from '@angular/cdk/observers';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';

import { AccordionWizardStepComponent } from './accordion-wizard-step/accordion-wizard-step.component';
import { AccordionWizardStepContentFooterComponent } from './accordion-wizard-step-content-footer/accordion-wizard-step-content-footer.component';
import { AccordionWizardStepHeaderComponent } from './accordion-wizard-step-header/accordion-wizard-step-header.component';
import { AccordionWizardStepsComponent } from './accordion-wizard-steps/accordion-wizard-steps.component';

@NgModule({
  declarations: [
    AccordionWizardStepComponent,
    AccordionWizardStepContentFooterComponent,
    AccordionWizardStepHeaderComponent,
    AccordionWizardStepsComponent,
  ],
  exports: [AccordionWizardStepComponent, AccordionWizardStepsComponent],
  imports: [AccordionModule, ButtonModule, CommonModule, MatIconModule, ObserversModule, TranslocoModule],
})
export class AccordionWizardModule {}
