import { DBDocSchema } from '../../generic/db-doc/db-doc-schema';
import { SchemaField } from '../../generic/serialization/schema-field';
import { EntityStatsChunk } from '../entity-stats-chunk/entity-stats-chunk';

export class EntityStatsSchema extends DBDocSchema {
  public static readonly bySlidingWindow = 'bySlidingWindow';

  public static readonly byMonth = 'byMonth';

  public static readonly total = 'total';

  public static readonly type = 'type';

  public static readonly numUsersAnalyzed = 'numUsersAnalyzed';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly bySlidingWindow = new Map<string, EntityStatsChunk>();

    public static readonly byMonth = new Map<string, EntityStatsChunk>();

    public static readonly total = EntityStatsChunk.deserialize({});

    public static readonly type = 'missing-type';

    public static readonly numUsersAnalyzed = 0;
  };

  static Constants = class {
    public static readonly collection = 'entityStats';

    // Other constants
    public static readonly monthFormat = 'MM-YYYY';

    // Sliding Windows
    // https://beam.apache.org/documentation/programming-guide/#sliding-time-windows
    public static readonly slidingWindowDurationDays = [1, 7, 14, 30, 90];

    public static slidingWindowKeyName(days: number): string {
      return `${days}-days`;
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[EntityStatsSchema.bySlidingWindow] = SchemaField.mapOfObj(
      EntityStatsChunk,
      EntityStatsSchema.Defaults.bySlidingWindow,
    );
    parameters[EntityStatsSchema.byMonth] = SchemaField.mapOfObj(EntityStatsChunk, EntityStatsSchema.Defaults.byMonth);
    parameters[EntityStatsSchema.total] = SchemaField.obj(EntityStatsChunk, EntityStatsSchema.Defaults.total);
    parameters[EntityStatsSchema.type] = SchemaField.string(EntityStatsSchema.Defaults.type);
    parameters[EntityStatsSchema.numUsersAnalyzed] = SchemaField.number(EntityStatsSchema.Defaults.numUsersAnalyzed);
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${EntityStatsSchema.Constants.collection}`;
  }
}
