import { cloneDeep } from 'lodash';
import Mustache from 'mustache';

import { SerializableObject } from '../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../generic/serialization/serializable-object-schema';
import { LanguageDefaults } from '../../voice-response-command/vrc-audio-metadata/language-defaults';
import { SupportedLanguages } from '../../voice-response-command/vrc-audio-metadata/supported-languages';

import { TemplateTextConstructor } from './template-text-constructor';
import { TemplateTextSchema } from './template-text-schema';

export class TemplateText extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  private text!: Map<string, string>;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: TemplateTextConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): TemplateText {
    return new TemplateText(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(TemplateText.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new TemplateTextSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getText(): Map<string, string> {
    return cloneDeep(this.text);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Sanity Check
  /////////////////////////////////////////////////////////////////////////////

  public sanityCheck(): void {
    for (const langShortCode of this.text.keys()) {
      // This will throw if the language short code isn't supported.
      SupportedLanguages.getDefaults(langShortCode);
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////

  public getUsedVars(language: LanguageDefaults): Set<string> {
    const usedVars = new Set<string>();
    // Check the vars used, and validate they are allowed
    const templateTextInLang = this.text.get(language.getShortCode());
    if (templateTextInLang === undefined) {
      return new Set<string>();
    }

    const templateScans = Mustache.parse(templateTextInLang);
    for (const scan of templateScans) {
      if (scan[0] !== 'name' || scan.length < 2) {
        continue;
      }
      usedVars.add(scan[1]);
    }
    return usedVars;
  }
}
