<ng-container *transloco="let t; read: 'communication-log-detail-conversation'">
  <p-toast position="bottom-center"></p-toast>
  <p-card>
    <ng-template pTemplate="header">
      <legend>{{ t('title') }}</legend>
    </ng-template>

    <ng-template pTemplate="content">
      <app-communication-logs-session-hunt
        [tableRows$]="tableRows$"
        [tableTitle]="t('tableTitle')"
      ></app-communication-logs-session-hunt>
    </ng-template>

    <ng-template pTemplate="footer">
      <div class="communication-log-detail-conversation__actions">
        <button
          class="p-button-secondary"
          pButton
          type="button"
          [disabled]="!this.dataExists"
          label="{{
            dataExists ? t('getConversationPIIButtonEnabledLabel') : t('getConversationPIIButtonDisabledLabel')
          }}"
          (click)="showConversationPIIDialog()"
        ></button>
        <button app-submit-form-button pButton type="button" [sessionId]="communicationSessionId$ | async"></button>
      </div>
    </ng-template>
  </p-card>
</ng-container>
