import type { AnySchema } from 'joi';

import { SchemaField } from '../../../../../objects/generic/serialization/schema-field';
import { FormResponseSchema } from '../../generic/form-response/form-response-schema';

import { FormResponseCreateSubmissionError } from './form-response-create-submission-error';

export class FormResponseCreateSubmissionSchema extends FormResponseSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly Defaults = class {
    public static readonly type = undefined;

    public static readonly error = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: AnySchema } {
    const parameters = super.getSchemaDefinition();

    parameters[FormResponseCreateSubmissionSchema.error] = SchemaField.string(FormResponseSchema.Defaults.error)
      .optional()
      .valid(...Object.values(FormResponseCreateSubmissionError));

    return parameters;
  }
}
