import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { isNil } from 'lodash';
import { from, map, switchMap } from 'rxjs';
import { SurveyModule } from 'survey-angular-ui';
import { CompleteEvent, Model, settings } from 'survey-core';

import { CommunicationSessionId, configureSurveyJS, FormResponseCreateSubmission } from '@pwp-common';

import { StylesService } from '../../../ui/styles/styles.service';
import { FormAutocompleteComponent } from '../form-autocomplete/form-autocomplete.component';
import { FormSubmissionService } from '../services/form-submission/form-submission.service';
import { FormVersionService } from '../services/form-version.service';
import { createSurveyModel } from '../utils/create-survey-model/create-survey-model';

import { SaveSurveyResultInput } from './interfaces';

configureSurveyJS(settings);

@Component({
  selector: 'app-form-submission',
  standalone: true,
  imports: [CommonModule, FormAutocompleteComponent, FormsModule, ReactiveFormsModule, SurveyModule, TranslocoModule],
  templateUrl: './form-submission.component.html',
  styleUrls: ['./form-submission.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSubmissionComponent {
  private readonly formSubmissionService = inject(FormSubmissionService);

  private readonly formVersionService = inject(FormVersionService);

  private readonly stylesService = inject(StylesService);

  private readonly translocoService = inject(TranslocoService);

  public readonly selectedForm = new FormControl<string | null>(null);

  public readonly selectedFormModel$ = this.selectedForm.valueChanges.pipe(
    switchMap((formId) => this.createSurveyModel(formId)),
  );

  public readonly stylesLoaded$ = from(this.stylesService.lazyLoadGlobalStyle('survey-creator')).pipe(map(() => true));

  @Output() public readonly submission = new EventEmitter<FormResponseCreateSubmission>();

  @Input() public sessionId: CommunicationSessionId;

  private displaySubmissionError(options: CompleteEvent): void {
    options.showSaveError(this.translocoService.translate('form-submission.formSubmissionError'));
  }

  private handleFormSubmissionResponse(response: FormResponseCreateSubmission, options: CompleteEvent): void {
    if (isNil(response.getError())) {
      options.clearSaveMessages();
      this.submission.emit(response);
    } else {
      this.displaySubmissionError(options);
    }
  }

  private saveSurveyResult = async ({ survey, options, formVersion }: SaveSurveyResultInput): Promise<void> => {
    options.showSaveInProgress(this.translocoService.translate('form-submission.formSubmissionInProgress'));

    const response = await this.formSubmissionService.submitForm({
      formVersionId: formVersion.getId(),
      data: survey.data,
      sessionId: this.sessionId,
    });

    this.handleFormSubmissionResponse(response, options);
  };

  private async createSurveyModel(formId: string | null): Promise<Model | null> {
    if (isNil(formId)) {
      return null;
    }

    const formVersion = await this.formVersionService.getActiveFormVersion(formId);

    const model = createSurveyModel(formVersion.getFormSchema());
    model.onComplete.add(async (survey, options) => this.saveSurveyResult({ formVersion, survey, options }));

    return model;
  }
}
