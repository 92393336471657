<ng-container *transloco="let t; read: 'communication-workflow-table-editor'">
  <p-messages *ngIf="true" severity="success">
    <ng-template pTemplate>
      <div class="ml-2">
        You can create some workflows below. Feel free, this won't affect live calls, chats, texts etc until you've
        enabled it. Once you've gotten a feel for what they do please reach out and share your use case, and we'll
        invite you to the private beta if it's something we currently support!
      </div>
    </ng-template>
  </p-messages>

  <app-data-table
    [actionsTemplate]="actionsTemplate"
    [cols]="cols"
    [expansionRow]="expansionRow"
    [loading]="loading"
    [refreshClicked]="getDataWrapper"
    [selectedCols]="cols"
    [tableRows]="tableRows"
    [title]="t('title')"
    [translationScope]="'communication-workflow-table-editor'"
  >
    <ng-template #actionsTemplate>
      <p-button
        *appIfRole="'pwpAdmin'"
        icon="pi pi-plus"
        styleClass="p-button-outlined"
        [ariaLabel]="t('createCommunicationWorkflowButtonLabel')"
        [label]="t('createCommunicationWorkflowButtonLabel')"
        pTooltip="{{ t('createCommunicationWorkflowButtonTooltip') }}"
        (onClick)="createCommunicationWorkflow()"
      >
      </p-button>
    </ng-template>
    <ng-template #expansionRow let-rowData>
      <app-call-list-table-editor-expansion [row]="rowData"></app-call-list-table-editor-expansion>
    </ng-template>
  </app-data-table>
</ng-container>
