import { cloneDeep } from 'lodash';

import { CalleeEntity } from '../../call/callee-entity/callee-entity';
import { SerializableObjectSchema } from '../../generic/serialization/serializable-object-schema';
import { VoiceResponseCommandName } from '../any-voice-response-command/voice-response-command-name';
import { VoiceResponseCommand } from '../generic/voice-response-command';
import { VoiceResponseCommandSchema } from '../generic/voice-response-command-schema';

import { SendMessageTemplateConstructor } from './send-message-template-constructor';
import { SendMessageTemplateSchema } from './send-message-template-schema';

export class SendMessageTemplate extends VoiceResponseCommand {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected messageTemplateId!: string;

  protected to!: CalleeEntity[];

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: SendMessageTemplateConstructor) {
    // This hack ensures that deserializing a VoiceCommand is equal to instanting it directly.
    // The difference is trivial, and only required for intuitive unit testing.
    (parameters as any)[VoiceResponseCommandSchema.commandName] = VoiceResponseCommandName.sendMessageTemplate;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): SendMessageTemplate {
    return new SendMessageTemplate(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(SendMessageTemplate.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new SendMessageTemplateSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getMessageTemplateId(): string {
    return cloneDeep(this.messageTemplateId);
  }

  public getTo(): CalleeEntity[] {
    return cloneDeep(this.to);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////
}
