import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css'],
})
export class LoadingComponent implements OnInit, OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Inputs
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  @Input() loader: Record<
    any,
    {
      inProgress$: Observable<boolean>;
    }
  >;

  @Input() doneTemplate: TemplateRef<any>;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  inProgress: Observable<boolean>;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.initObservables();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Loading
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  initObservables(): void {
    if (this.loader === undefined) {
      this.inProgress = of(false);
    }

    this.inProgress = combineLatest(Object.values(this.loader).map((z) => z.inProgress$)).pipe(
      map((z) => z.every((_) => _ === true)),
    );
  }
}
