import { SerializableObject } from '../../../../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../../../../generic/serialization/serializable-object-schema';
import { ReservationSkipOffersIfRuleAvailability } from '../../reservation-skip-offers-if-rules/reservation-skip-offers-if-rule-availability/reservation-skip-offers-if-rule-availability';
import { ReservationSkipOffersIfRuleChannelMax } from '../../reservation-skip-offers-if-rules/reservation-skip-offers-if-rule-channel-max/reservation-skip-offers-if-rule-channel-max';
import { ReservationSkipOffersIfRule } from '../reservation-skip-offers-if-rule/reservation-skip-offers-if-rule';
import { ReservationSkipOffersIfRuleName } from '../reservation-skip-offers-if-rule-name';

import { AnyReservationSkipOffersIfRuleSchema } from './any-reservation-skip-offers-if-rule-schema';

export abstract class AnyReservationSkipOffersIfRule extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ReservationSkipOffersIfRule {
    const typeString = validationResult.value[AnyReservationSkipOffersIfRuleSchema.type];
    const type = ReservationSkipOffersIfRuleName[typeString as keyof typeof ReservationSkipOffersIfRuleName];
    if (type === undefined) {
      throw new Error(
        `AnyReservationSkipOffersIfRule._deserialize: User Error: Cannot deserialize obj with type=${typeString}`,
      );
    }

    switch (type) {
      case ReservationSkipOffersIfRuleName.availability: {
        return new ReservationSkipOffersIfRuleAvailability(validationResult.value);
      }
      case ReservationSkipOffersIfRuleName.channelMax: {
        return new ReservationSkipOffersIfRuleChannelMax(validationResult.value);
      }
      default: {
        throw new Error(`AnyReservationSkipOffersIfRule._deserialize Unknown type: ${type}`);
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new AnyReservationSkipOffersIfRuleSchema();
  }
}
