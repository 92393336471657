<ng-container *transloco="let t; read: 'communication-log-detail-call'">
  <p-card *ngrxLet="userRoles$ as roles">
    <ng-template pTemplate="header">
      <legend>{{ t('title') }}</legend>
    </ng-template>

    <ng-template pTemplate="content">
      <app-communication-logs-session-hunt
        *ngIf="roles?.orgAdmin; else noDetails"
        [tableRows$]="tableRows$"
        [tableTitle]="t('tableTitle')"
      ></app-communication-logs-session-hunt>
      <ng-template #noDetails> {{ t('noDetails') }} </ng-template>
    </ng-template>

    <ng-template pTemplate="footer">
      <div class="flex gap-2">
        <ng-container *ngIf="roles.orgAdmin">
          <button
            pButton
            type="button"
            [disabled]="!dataExists"
            label="{{ t('blockUnblock') }}"
            (click)="openBlockedCallerDialog()"
          ></button>
          <button
            *ngIf="showGetCallerPIIButton"
            class="p-button-secondary"
            pButton
            type="button"
            label="{{ t('showCallerPIIButtonLabel') }}"
            (click)="showCallerPIIDialog()"
          ></button>
        </ng-container>
        <app-create-callback-request-button
          *ngIf="roles.voicemail"
          [communicationSessionId]="communicationSessionId"
        ></app-create-callback-request-button>
        <button *ngIf="isActive$ | async" pButton type="button" (click)="openDialOngoingCallDialog()">
          {{ t('dialButtonLabel') }}
        </button>
        <button app-submit-form-button pButton type="button" [sessionId]="communicationSessionId"></button>
      </div>
    </ng-template>
  </p-card>
</ng-container>
