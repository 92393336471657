import { cloneDeep } from 'lodash';

import { DBDocObject } from '../../../../../generic/db-doc/db-doc-object';
import { DBDocSchema } from '../../../../../generic/db-doc/db-doc-schema';
import { CommunicationSessionEventLogName } from '../communication-session-event-log-name';

export abstract class CommunicationSessionEventLog extends DBDocObject {
  protected type!: CommunicationSessionEventLogName;

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    throw new Error('Error: Cannot get schema for abstract CommunicationSessionEventLog');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getType(): CommunicationSessionEventLogName {
    return cloneDeep(this.type);
  }
}
