import { isArray, isNil } from 'lodash';
import moment from 'moment-timezone';
import { ByWeekday, Frequency, Weekday } from 'rrule';

import { reinterpretUTCTimeAsTimezone } from './time-parsing';
import { validateAndGetRRuleFromStr } from './validation';

//////////////////////////////////////////////////////////////////////////////////////////
// Frequency
//////////////////////////////////////////////////////////////////////////////////////////

export const rruleIsWeekly = (rruleStr: string, throwOnError = true): boolean =>
  rruleIsFrequency(rruleStr, Frequency.WEEKLY, throwOnError);

export const rruleIsDaily = (rruleStr: string, throwOnError = true): boolean =>
  rruleIsFrequency(rruleStr, Frequency.DAILY, throwOnError);

export const rruleIsHourly = (rruleStr: string, throwOnError = true): boolean =>
  rruleIsFrequency(rruleStr, Frequency.HOURLY, throwOnError);

export const rruleIsMinutely = (rruleStr: string, throwOnError = true): boolean =>
  rruleIsFrequency(rruleStr, Frequency.MINUTELY, throwOnError);

export const rruleIsSecondly = (rruleStr: string, throwOnError = true): boolean =>
  rruleIsFrequency(rruleStr, Frequency.SECONDLY, throwOnError);

const rruleIsFrequency = (rruleStr: string, frequency: Frequency, throwOnError: boolean): boolean => {
  const rrule = validateAndGetRRuleFromStr(rruleStr, throwOnError);
  if (rrule === undefined) {
    return false;
  }
  return rrule.options.freq === frequency;
};

//////////////////////////////////////////////////////////////////////////////////////////
// DTStart
//////////////////////////////////////////////////////////////////////////////////////////

/**
 * Extract the DTStart out of an RRule string and interpret it according in
 * the specified timezone following the RFC:
 *
 * https://www.kanzaki.com/docs/ical/dateTime.html. For an example see
 * "FORM #3: DATE WITH LOCAL TIME AND TIME ZONE REFERENCE"
 * @param rruleStr
 * @returns
 */
export const getRRuleDtstart = (rruleStr: string): moment.Moment | undefined => {
  const rrule = validateAndGetRRuleFromStr(rruleStr, true);
  const value = rrule?.origOptions?.dtstart;
  if (isNil(value)) {
    return undefined;
  }
  const tzid = getRRuleTzid(rruleStr, true);
  const converted = reinterpretUTCTimeAsTimezone(value, tzid);
  return converted;
};

export const rruleHasDtstart = (rruleStr: string, throwOnError = true): boolean => {
  const rrule = validateAndGetRRuleFromStr(rruleStr, throwOnError);
  if (rrule === undefined) {
    return false;
  }
  return !isNil(getRRuleDtstart(rruleStr));
};

//////////////////////////////////////////////////////////////////////////////////////////
// TZID
//////////////////////////////////////////////////////////////////////////////////////////

export const getRRuleTzid = (rruleStr: string, throwOnError = true): string => {
  const rrule = validateAndGetRRuleFromStr(rruleStr, throwOnError);
  const tzid = rrule?.options.tzid || 'UTC';
  return tzid;
};

export const rruleHasTzid = (rruleStr: string, throwOnError = true): boolean => {
  const rrule = validateAndGetRRuleFromStr(rruleStr, throwOnError);
  const tzid = rrule?.options.tzid;
  return !isNil(tzid);
};

//////////////////////////////////////////////////////////////////////////////////////////
// COUNT
//////////////////////////////////////////////////////////////////////////////////////////

export const rruleHasCount = (rruleStr: string, throwOnError = true): boolean => {
  const rrule = validateAndGetRRuleFromStr(rruleStr, throwOnError);
  if (rrule === undefined) {
    return false;
  }
  return !isNil(rrule.options.count);
};

//////////////////////////////////////////////////////////////////////////////////////////
// By Day
//////////////////////////////////////////////////////////////////////////////////////////

const rruleConvertByWeekdayToInteger = (weekday: ByWeekday): number | undefined => {
  if (isNil(weekday)) {
    return undefined;
  }
  if (typeof weekday === 'number') {
    return weekday;
  }

  if (weekday instanceof Weekday) {
    return weekday.weekday;
  }

  switch (weekday) {
    case 'MO': {
      return 0;
    }
    case 'TU': {
      return 1;
    }
    case 'WE': {
      return 2;
    }
    case 'TH': {
      return 3;
    }
    case 'FR': {
      return 4;
    }
    case 'SA': {
      return 5;
    }
    case 'SU': {
      return 6;
    }
    default: {
      console.error('rruleConvertByWeekdayToInteger: Unknown weekday', weekday);
    }
  }
  return undefined;
};
export const getRRuleByWeekday = (rruleStr: string, throwOnError = true): number[] => {
  const rrule = validateAndGetRRuleFromStr(rruleStr, throwOnError);
  /**
   * Use origOptions, due to a documented bug in RRule (as of 2022-10-09)
   * https://github.com/jakubroztocil/rrule#instance-properties
   */
  let byWeekdayArray: ByWeekday[] = [];
  if (!isArray(rrule?.origOptions.byweekday)) {
    byWeekdayArray = [rrule?.origOptions.byweekday].filter((z) => !isNil(z)) as ByWeekday[];
  } else {
    byWeekdayArray = rrule?.origOptions.byweekday.filter((z) => !isNil(z)) as ByWeekday[];
  }
  return byWeekdayArray.map((z) => rruleConvertByWeekdayToInteger(z)).filter((z) => !isNil(z)) as number[];
};

export const getRRuleByIsoWeekday = (rruleStr: string, throwOnError = true): number[] => {
  const weekdays = getRRuleByWeekday(rruleStr, throwOnError);

  // Convert weekday into Iso Weekday
  return weekdays.map((z) => z + 1);
};
