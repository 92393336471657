import { isNil } from 'lodash';
import { configure } from 'safe-stable-stringify';

import { SerializableObject } from '../serializable-object';

const stringify = configure({ deterministic: true });
const defaultValue = '{}';
export const serializeToJsonString = (obj: SerializableObject | Object): string => {
  if (isNil(obj)) {
    return defaultValue;
  }

  let valueToStringify = obj;
  if (obj instanceof SerializableObject) {
    valueToStringify = (obj as any).serialize();
  }

  return stringify(valueToStringify)!;
};
