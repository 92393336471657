import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { CallerIdAutocompleteComponent } from './caller-id-autocomplete/caller-id-autocomplete.component';
import { CommunicationWorkflowSelectComponent } from './communication-workflow-select/communication-workflow-select.component';
import { ObjAutocompleteFormControlComponent } from './obj-autocomplete-form-control/obj-autocomplete-form-control.component';

@NgModule({
  declarations: [
    CallerIdAutocompleteComponent,
    CommunicationWorkflowSelectComponent,
    ObjAutocompleteFormControlComponent,
  ],
  exports: [CallerIdAutocompleteComponent, CommunicationWorkflowSelectComponent, ObjAutocompleteFormControlComponent],
  imports: [AutoCompleteModule, CommonModule, MatIconModule, ReactiveFormsModule, TranslocoModule],
})
export class ObjAutocompleteV2Module {}
