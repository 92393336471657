import { createReportRow } from '../create-report-row/create-report-row';
import { createValuesForEntityStats } from '../create-values-for-entity-stats/create-values-for-entity-stats';
import { AdminReportCallStatsRowInput, ReportRow } from '../interfaces';

export const createNobodyAvailableRow = ({ entityStats, callList }: AdminReportCallStatsRowInput): ReportRow =>
  createReportRow(
    'nobodyAvailable',
    createValuesForEntityStats(entityStats, (statsChunk) =>
      statsChunk.getCalls(callList)?.getINumUserIdsToCallWasEmpty(),
    ),
    { callList },
  );
