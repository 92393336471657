import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { VRCAudioMetadata } from '@pwp-common';

import { setControlEnabled } from '../../../../common/form/set-control-enabled';
import {
  getObjAutocompleteFormControlBaseProviders,
  ObjAutocompleteFormControlBase,
} from '../../../generic/abstract-classes/obj-autocomplete-form-control-base';
import { ServiceOperationEditorService } from '../services/service-operation-editor/service-operation-editor.service';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-vrc-audio-select',
  templateUrl: './vrc-audio-select.component.html',
  styleUrls: ['./vrc-audio-select.component.css'],
  providers: [...getObjAutocompleteFormControlBaseProviders(VrcAudioSelectComponent)],
})
export class VrcAudioSelectComponent extends ObjAutocompleteFormControlBase<VRCAudioMetadata> implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public serviceOperationEditorService: ServiceOperationEditorService,
  ) {
    super(changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Set All Options
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineAllOptions() {
    return this.serviceOperationEditorService.getVRCAudio();
  }

  // @Note: when running control.enable, the value is removed from the control. Reason is still unclear.
  // Component will likely get rewritten
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled !== this.form.disabled) {
      setControlEnabled(this.form, !isDisabled);
    }

    this.changeDetectorRef.detectChanges();
  }
}
