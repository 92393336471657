import { isNil, orderBy } from 'lodash';

import { displayTime } from '../../../helper/time/display-time/display-time';
import { formatDuration } from '../../../helper/time/format-duration/format-duration';
import { CommunicationDisplayAlertType } from '../../core/communication/communication-display-alert-type';
import { DisplayableCommunication } from '../../core/communication/displayable-communication';
import { CommunicationType } from '../../core/communication/types';
import { UserData } from '../../user/user-data/user-data';
import { getDisplayName } from '../../user/user-data/user-data-display';
import { ConversationConfig } from '../conversation-config/conversation-config';
import { ConversationParticipantSummaryChannel } from '../conversation-participant-summary/conversation-participant-channel';
import { ConversationUserIdentityType } from '../conversation-participant-summary/conversation-participant-id-type';
import { ConversationSummary } from '../conversation-summary/conversation-summary';

import { ConversationLog } from './conversation-log';
import { ConversationState } from './conversation-log-state';

/**
 * This class is used by call-table.component.ts. It transforms CallData(), which simply
 * holds all the properties of a call, to a format suitable for displaying in a table.
 */
export class ConversationLogDisplay implements DisplayableCommunication {
  ////////////////////////////////////////////////////
  // Variables
  ////////////////////////////////////////////////////

  conversationLog: ConversationLog;

  ////////////////////////////////////////////////////
  // Displayable Communication Interface
  ////////////////////////////////////////////////////

  id: string;

  communicationType: CommunicationType;

  communicationTypeDetail: string;

  day: string;

  receiveTime: string;

  end: string;

  holdDuration: string;

  talkDuration: string;

  answeredBy: string;

  alertType: CommunicationDisplayAlertType;

  timestamp: Date;

  conversationConfig: ConversationConfig | undefined;

  numUserMessages: string;

  numExternalMessages: string;

  /**
   * Initialize all properties based on the Call Data and UserDataMap
   * @param conversationLog Data associated to the call
   * @param allUserDataMap Map specifying UserData for each userId string.
   */
  constructor(
    conversationLog: ConversationLog,
    allUserDataMap: Map<string, UserData>,
    timezone: string,
    conversationConfigMap: Map<string, ConversationConfig>,
  ) {
    this.conversationLog = conversationLog;

    this.id = conversationLog.getId();
    this.conversationConfig = conversationConfigMap.get(conversationLog.getConversationConfigId() || '');
    this.communicationType = this.getCommunicationType(conversationLog.getSummary());
    this.communicationTypeDetail = this.conversationConfig?.getDisplayName() ?? '';

    this.day = conversationLog.getCreateTime()!.tz(timezone).format('l');
    this.receiveTime = displayTime(conversationLog.getCreateTime(), timezone, { timeOnly: true });
    this.end = this.getEndTime(conversationLog, timezone);

    this.holdDuration = formatDuration(conversationLog.getHoldDurationMS());
    this.talkDuration = this.getTalkDurationString(conversationLog);

    this.answeredBy = this.getAnsweredBy(allUserDataMap, conversationLog);
    this.alertType = this.getAlertType(conversationLog);
    this.timestamp = conversationLog.getCreateTime()!.toDate();
    this.numUserMessages =
      this.countMessagesByParticipantType(conversationLog, ConversationUserIdentityType.userId) || '';
    this.numExternalMessages =
      this.countMessagesByParticipantType(conversationLog, ConversationUserIdentityType.anonymousExternalId) || '';
  }

  /////////////////////////////////////////////////////////////////////////////
  // Answered By
  /////////////////////////////////////////////////////////////////////////////

  private getAnsweredBy(allUserDataMap: Map<string, UserData>, conversationLog: ConversationLog): string {
    if (!conversationLog.wasAnswered()) {
      return 'unanswered';
    }

    const summary = conversationLog.getSummary();
    const participantIds =
      summary?.getAllUserIdsThatAnswered() ?? ([conversationLog.getLastModifiedByUserId()] as string[]);

    return orderBy(participantIds, (userId) => summary?.getByParticipant().get(userId)?.getNumSent(), ['desc'])
      .map((userId) => getDisplayName(allUserDataMap, userId))
      .join(',');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Pretty Event Type
  /////////////////////////////////////////////////////////////////////////////

  private getCommunicationType(conversationSummary?: ConversationSummary) {
    if (isNil(conversationSummary)) {
      return CommunicationType.chat;
    }
    for (const participantSummary of conversationSummary.getByParticipant().values()) {
      if (participantSummary.getParticipantIdType() === ConversationUserIdentityType.anonymousExternalId) {
        switch (participantSummary.getChannel()) {
          case ConversationParticipantSummaryChannel.chat: {
            return CommunicationType.chat;
          }
          case ConversationParticipantSummaryChannel.sms: {
            return CommunicationType.text;
          }
          default: {
            console.error(`setCommunicationType: Not Implemented: ${participantSummary.getChannel()}`);
            return CommunicationType.chat;
          }
        }
      }
    }

    return CommunicationType.chat;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Pretty Print: End Time
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Return 'in progress' if the call is in progress. Else, return a string giving
   * the duration of the call in hours / minutes / seconds.
   */
  public getEndTime = (conversationLog: ConversationLog, timezone: string): string => {
    if (conversationLog.getState() === ConversationState.serviceRequested) {
      return '';
    }

    if (conversationLog.getState() === ConversationState.serviceDeliveryInProgress) {
      return '';
    }

    return displayTime(conversationLog.getCloseTime(), timezone, { timeOnly: true });
  };

  /////////////////////////////////////////////////////////////////////////////
  // Pretty Print: Talk / Hold duration
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Return 'in progress' if the call is in progress. Else, return a string giving
   * the duration of the call in hours / minutes / seconds.
   */
  public getTalkDurationString = (conversationLog: ConversationLog): string => {
    if (conversationLog.getState() === ConversationState.serviceRequested) {
      return '';
    }

    if (conversationLog.wasAnswered()) {
      return formatDuration(conversationLog.getTalkDurationMS());
    }

    return 'unanswered';
  };

  /////////////////////////////////////////////////////////////////////////////
  // CSS
  /////////////////////////////////////////////////////////////////////////////

  private getAlertType(conversationLog: ConversationLog): CommunicationDisplayAlertType {
    if (conversationLog.getState() === ConversationState.serviceRequested) {
      return CommunicationDisplayAlertType.inProgress;
    }

    if (conversationLog.getState() === ConversationState.serviceDeliveryInProgress) {
      return CommunicationDisplayAlertType.inProgress;
    }

    if (!conversationLog.wasAnswered()) {
      return CommunicationDisplayAlertType.unanswered;
    }

    // No special alert type
    return CommunicationDisplayAlertType.null;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Num External Messages & Num User Messages
  /////////////////////////////////////////////////////////////////////////////

  private countMessagesByParticipantType(
    conversationLog: ConversationLog | undefined,
    participantType: ConversationUserIdentityType,
  ): string {
    if (isNil(conversationLog)) {
      return '';
    }

    const summary = conversationLog?.getSummary();
    if (isNil(summary)) {
      return '';
    }

    // Return the count of messages as per participant type
    const messageCount = Array.from(summary.getByParticipant().values())
      .filter((z) => z.getParticipantIdType() === participantType)
      .map((z) => z.getNumSent())
      .reduce((acc, z) => acc + z, 0);

    // Convert the message count to a string before returning
    return messageCount.toString();
  }
}
