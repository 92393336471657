import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import moment from 'moment-timezone';
import { Frequency, Options, RRule, Weekday } from 'rrule';

import { getRRuleByWeekday, OrgData, rruleHasDtstart, validateAndGetRRuleFromStr } from '@pwp-common';

import {
  DATETIME_LOCAL_CONTROL_STR_FORMAT,
  getFieldControlValueOrDefault,
  getTimestampFromDateTimeLocalFieldControl,
} from '../../../common/objects/form-helper';
import { DatetimeValidator } from '../../../common/validators/datetime-validator/datetime-validator';
import { OrgDataService } from '../../../services/orgs/org-data/org-data.service';
import { FormGroupControlValueAccessor } from '../../generic/abstract-classes/form-group-control-value-accessor';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SelectButtonModule } from 'primeng/selectbutton';

const VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RruleEditorComponent),
  multi: true,
};

const VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => RruleEditorComponent),
  multi: true,
};

@UntilDestroy()
@Component({
  selector: 'app-rrule-editor',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    InputNumberModule,
    InputTextModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    SelectButtonModule,
    TranslocoModule,
  ],
  templateUrl: './rrule-editor.component.html',
  styleUrls: ['./rrule-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VALUE_ACCESSOR, VALIDATOR],
})
export class RruleEditorComponent extends FormGroupControlValueAccessor<any, string> implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////

  weekly = Frequency.WEEKLY;

  possibleFrequencies: { label: string; value: Frequency }[] = [
    { label: 'hourly', value: Frequency.HOURLY },
    { label: 'daily', value: Frequency.DAILY },
    { label: 'weekly', value: Frequency.WEEKLY },
    { label: 'monthly', value: Frequency.MONTHLY },
    { label: 'yearly', value: Frequency.YEARLY },
  ];

  possibleValuesByWeekday: { label: string; value: Weekday }[] = [
    { label: 'byWeekdayMonday', value: RRule.MO },
    { label: 'byWeekdayTuesday', value: RRule.TU },
    { label: 'byWeekdayWednesday', value: RRule.WE },
    { label: 'byWeekdayThursday', value: RRule.TH },
    { label: 'byWeekdayFriday', value: RRule.FR },
    { label: 'byWeekdaySaturday', value: RRule.SA },
    { label: 'byWeekdaySunday', value: RRule.SU },
  ];

  possibleValuesWeekStart: { label: string; value: Weekday }[] = [
    { label: 'weekStartMonday', value: RRule.MO },
    { label: 'weekStartTuesday', value: RRule.TU },
    { label: 'weekStartWednesday', value: RRule.WE },
    { label: 'weekStartThursday', value: RRule.TH },
    { label: 'weekStartFriday', value: RRule.FR },
    { label: 'weekStartSaturday', value: RRule.SA },
    { label: 'weekStartSunday', value: RRule.SU },
  ];

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  orgData: OrgData;

  rruleDidSpecifyTzId = false;

  rruleTimezone: string;

  initializedWithRRuleStr = '';

  @Input() public disableStartEditing = false;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private orgDataService: OrgDataService,
  ) {
    super(changeDetectorRef);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.orgDataService
      .getOrgData()
      .pipe(untilDestroyed(this))
      .subscribe((orgData) => {
        this.orgData = orgData;
        this.setRRuleTimezone();
        this.form.setValue(this.form.value);
        this.changeDetectorRef.detectChanges();
      });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineForm() {
    const formConfig = {} as any;
    formConfig.byWeekday = new UntypedFormControl(undefined, []);
    formConfig.frequency = new UntypedFormControl(Frequency.YEARLY, [Validators.required]);
    formConfig.weekStart = new UntypedFormControl(undefined, []);
    formConfig.interval = new UntypedFormControl(1, [Validators.required, Validators.min(1)]);

    const initializedWIthTime = moment().subtract(1, 'hour').format(DATETIME_LOCAL_CONTROL_STR_FORMAT);
    formConfig.dtStart = new UntypedFormControl(initializedWIthTime, [
      Validators.required,
      DatetimeValidator.isBefore(moment(), undefined),
      DatetimeValidator.isValid(),
    ]);
    this.form = new UntypedFormGroup(formConfig);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Write Value
  /////////////////////////////////////////////////////////////////////////////////////////////

  writeValue(rruleStr: string) {
    this.initializedWithRRuleStr = rruleStr;
    const rrule = validateAndGetRRuleFromStr(rruleStr ?? '', false);
    if (!rruleHasDtstart(rruleStr, false)) {
      this.rruleDidSpecifyTzId = false;
      /**
       * Emit if the RRule is initialized with an invalid str. Alternatively,
       * we could do nothing and leave the form as invalid
       * (which is what our custom form controls do by default)
       */
      this.form.patchValue(
        {
          dtStart: moment().subtract(1, 'hour').format(DATETIME_LOCAL_CONTROL_STR_FORMAT),
          interval: rrule.options.interval,
          freq: rrule.options.freq,
        },
        { emitEvent: true },
      );
      this.changeDetectorRef.detectChanges();
      return;
    }

    this.setRRuleTimezone();
    const dtStart = rrule.options.dtstart.toISOString().split(':').slice(0, 2).join(':');
    const byWeekday = isNil(rruleStr) ? [] : getRRuleByWeekday(rruleStr, true);
    const weekStart = new Weekday(rrule.options.wkst);
    const frequency = rrule.options.freq ?? Frequency.YEARLY;
    const { interval } = rrule.options;
    return super.writeValue({
      dtStart,
      byWeekday,
      weekStart,
      frequency,
      interval,
    });
  }

  private setRRuleTimezone() {
    const parsedRRule = validateAndGetRRuleFromStr(this.initializedWithRRuleStr, false);
    if (!rruleHasDtstart(this.initializedWithRRuleStr, false)) {
      this.rruleTimezone = this.orgData?.getTimezone();
      return;
    }
    this.rruleTimezone = parsedRRule.options.tzid;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  parseValueChange(rruleFormValue: any): string {
    const rruleInputTime = getTimestampFromDateTimeLocalFieldControl(this.dtStart, 'UTC', moment.unix(0));

    const interval = getFieldControlValueOrDefault(this.interval, undefined);
    const freq = getFieldControlValueOrDefault(this.frequency, undefined);
    const wkst = getFieldControlValueOrDefault(this.weekStart, undefined);
    const byweekday = freq === Frequency.WEEKLY ? getFieldControlValueOrDefault(this.byWeekday, undefined) : undefined;
    const dtstart = rruleInputTime.toDate();
    const tzid = this.rruleTimezone;

    const rruleConstructor: Partial<Options> = {
      interval,
      freq,
      dtstart,
      tzid,
      byweekday,
      wkst,
    };

    const rrule = new RRule(rruleConstructor).toString();
    const rruleStr = rrule.toString();
    return rruleStr;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Controls
  /////////////////////////////////////////////////////////////////////////////////////////////

  get dtStart() {
    return this.form.get('dtStart') as UntypedFormControl;
  }

  get byWeekday() {
    return this.form.get('byWeekday') as UntypedFormControl;
  }

  get frequency() {
    return this.form.get('frequency') as UntypedFormControl;
  }

  get weekStart() {
    return this.form.get('weekStart') as UntypedFormControl;
  }

  get interval() {
    return this.form.get('interval') as UntypedFormControl;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  public makeValidationErrors(): ValidationErrors {
    return {
      'rrule-editor': this.form.value,
    };
  }
}
