import { cloneDeep } from 'lodash';

import { TemplateText } from '../../../objects/text/template-text/template-text';
import { ResponseObj } from '../../generic/response/response-obj';

import { ExternalChatResponseConstructor } from './external-chat-response-constructor';
import { ExternalChatResponseError } from './external-chat-response-error';
import { ExternalChatResponseSchema } from './external-chat-response-schema';

export class ExternalChatResponse extends ResponseObj {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected jwt?: string;

  protected conversationSid?: string;

  protected message?: TemplateText;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ExternalChatResponseConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ExternalChatResponse {
    return new ExternalChatResponse(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(ExternalChatResponse.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): ExternalChatResponseSchema {
    return new ExternalChatResponseSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getJwt(): string | undefined {
    return cloneDeep(this.jwt);
  }

  public getConversationSid(): string | undefined {
    return cloneDeep(this.conversationSid);
  }

  public getMessage(): TemplateText | undefined {
    return cloneDeep(this.message);
  }

  public getError(): ExternalChatResponseError {
    return cloneDeep(this.error) as ExternalChatResponseError;
  }
}
