import { cloneDeep } from 'lodash';

import { DBDocSchema } from '../../../generic/db-doc/db-doc-schema';
import { GenericDisplayable } from '../../../generic/displayable/generic-displayable';
import { CommunicationWorkflowDefaultFilter } from '../communication-workflow-default-filter/communication-workflow-default-filter';
import { CommunicationWorkflowFilter } from '../communication-workflow-filter/communication-workflow-filter';

import { CommunicationWorkflowConstructor } from './communication-workflow-constructor';
import { CommunicationWorkflowSchema } from './communication-workflow-schema';

export class CommunicationWorkflow extends GenericDisplayable {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected defaultFilter!: CommunicationWorkflowDefaultFilter;

  protected filters!: CommunicationWorkflowFilter[];

  protected sid!: string | undefined;

  protected taskReservationTimeoutMS!: number;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationWorkflowConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationWorkflow {
    return new CommunicationWorkflow(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationWorkflow.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new CommunicationWorkflowSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
  public getDefaultFilter(): CommunicationWorkflowDefaultFilter | undefined {
    return cloneDeep(this.defaultFilter);
  }

  public getFilters(): CommunicationWorkflowFilter[] {
    return cloneDeep(this.filters);
  }

  public getSid(): string | undefined {
    return cloneDeep(this.sid);
  }

  public getTaskReservationTimeoutMS(): number {
    return cloneDeep(this.taskReservationTimeoutMS);
  }
}
