import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { loadingFor } from '@ngneat/loadoff';
import { cloneDeep, isNil } from 'lodash';
import moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { EntityStatsDisplayWindow, getIntervalForWindow, GLOBAL_TIMEZONE, Usage } from '@pwp-common';

import { UsageService } from '../../../../services/analytics/usage/usage.service';
import { ComponentWithForm } from '../../../generic/abstract-classes/component-with-form';

@Component({
  selector: 'app-admin-usage-dashboard',
  templateUrl: './admin-usage-dashboard.component.html',
  styleUrls: ['./admin-usage-dashboard.component.css'],
})
export class AdminUsageDashboardComponent extends ComponentWithForm implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////

  possibleWindows = Object.values(EntityStatsDisplayWindow).filter((z) => z !== EntityStatsDisplayWindow.custom);

  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  loader = loadingFor('usage', 'lastRefreshTime');

  usage: Observable<Usage[]>;

  lastRefreshTime: Observable<moment.Moment>;

  windowStart: moment.Moment = moment();

  windowEnd: moment.Moment = moment();

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private formBuilder: UntypedFormBuilder,
    private usageService: UsageService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getData();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Display Start / End interval
  /////////////////////////////////////////////////////////////////////////////////////////////

  public onFormChanges(value: any): void {
    if (isNil(this.window?.value)) {
    }

    const interval = getIntervalForWindow(this.window.value);
    this.windowStart = moment.tz(interval.getStart(), GLOBAL_TIMEZONE);
    this.windowEnd = moment.tz(interval.getEnd(), GLOBAL_TIMEZONE);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Form Field Getters
  /////////////////////////////////////////////////////////////////////////////////////////////

  get window(): AbstractControl | null {
    return this.form.get('window');
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected defineForm() {
    // Init Form
    const formConfig = {} as any;
    formConfig.window = [EntityStatsDisplayWindow.thisYear, [Validators.required]];
    this.form = this.formBuilder.group(formConfig);
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Data
  ///////////////////////////////////////////////////////////////////////

  private getData() {
    // Get all data. Note that observable doesn't depend on the window
    this.usage = this.usageService
      .getUsageWithWindowStartInRange(cloneDeep(this.windowStart).subtract(2, 'years'), moment())
      .pipe(this.loader.usage.track(), shareReplay(1));
    this.lastRefreshTime = this.usageService
      .getUsageWithWindowStartInRange(moment().startOf('month'), moment().endOf('month'))
      .pipe(
        map((usageArr) => moment.max(usageArr.map((z) => z.getLastUploadTime()))),
        this.loader.lastRefreshTime.track(),
        shareReplay(1),
      );
  }
}
