import { cloneDeep } from 'lodash';

import { CommunicationSessionId } from '../../../core/communication-session-id/communication-session-id';
import { DBDocObject } from '../../../generic/db-doc/db-doc-object';
import { DBDocSchema } from '../../../generic/db-doc/db-doc-schema';
import { AsyncServiceRequestNameType } from '../async-service-request-name';
import { AsyncServiceRequestStatus } from '../async-service-request-status';

export abstract class AsyncServiceRequest extends DBDocObject {
  protected type!: AsyncServiceRequestNameType;

  protected assignedTo: string | undefined;

  protected communicationWidgetId?: string;

  protected status!: AsyncServiceRequestStatus;

  protected serviceableAddressId!: string;

  protected sessionId!: CommunicationSessionId;

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    throw new Error('Error: Cannot get schema for abstract AsyncServiceRequest');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getType() {
    return cloneDeep(this.type);
  }

  public getAssignedTo() {
    return cloneDeep(this.assignedTo);
  }

  public getCommunicationWidgetId() {
    return cloneDeep(this.communicationWidgetId);
  }

  public getStatus() {
    return cloneDeep(this.status);
  }

  public getServiceableAddressId() {
    return cloneDeep(this.serviceableAddressId);
  }

  public getSessionId() {
    return cloneDeep(this.sessionId);
  }
}
