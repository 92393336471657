import { EventConfigOverlapsWithEventInput } from './interfaces';

export const eventConfigOverlapsWithEvent = ({
  eventConfig,
  eventData,
  timezone,
}: EventConfigOverlapsWithEventInput): boolean => {
  if (!eventConfig.getGenerate() || eventConfig.getType() !== eventData.getType()) {
    return false;
  }

  const events = eventConfig.generateEventsWithStartBetween(
    eventData.getStart().subtract(1, 'day'),
    eventData.getEnd().add(1, 'day'),
    timezone,
  );

  return events.some((event) => event.overlaps(eventData));
};
