import { values } from 'lodash';

import { DBDocSchema } from '../../../../../generic/db-doc/db-doc-schema';
import { SchemaField } from '../../../../../generic/serialization/schema-field';
import { CommunicationSessionEventLogName } from '../communication-session-event-log-name';

export class CommunicationSessionEventLogSchema extends DBDocSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly type = 'type';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationSessionEventLogSchema.type] = SchemaField.string(
      CommunicationSessionEventLogSchema.type,
      CommunicationSessionEventLogSchema.Defaults.type,
    )
      .required()
      .valid(...values(CommunicationSessionEventLogName));
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(): string {
    throw new Error(
      'CommunicationSessionEventLogSchema.getCollection: User error. Cannot call CommunicationSessionEventLogSchema.getCollection because no collection is specified',
    );
  }
}
