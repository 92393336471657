import { values } from 'lodash';

import { OperatorReasonConferenceEnded } from '../../../../../../../conference/operator-reason-conference-ended';
import { SchemaField } from '../../../../../../../generic/serialization/schema-field';
import { CommunicationSessionEventLogConferenceSchema } from '../../generic/communication-session-event-log-conference/communication-session-event-log-conference-schema';

export class CommunicationSessionEventLogConferenceEndSchema extends CommunicationSessionEventLogConferenceSchema {
  public static readonly callSidEndingConference = 'callSidEndingConference';

  public static readonly reasonConferenceEnded = 'reasonConferenceEnded';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    ///////////////////////////////////////////////////////
    // Generic InboundCallEvent Properties, Copy/Pasted Here
    ///////////////////////////////////////////////////////

    public static readonly type = undefined;

    public static readonly actor = undefined;

    public static readonly timestamp = undefined;

    ///////////////////////////////////////////////////////
    // CommunicationSessionEventLogConference Properties
    ///////////////////////////////////////////////////////

    public static readonly conferenceEventType = undefined;

    public static readonly conferenceSid = undefined;

    public static readonly eventTimestamp = undefined;

    public static readonly friendlyName = undefined;

    public static readonly sequenceNumber = undefined;

    ///////////////////////////////////////////////////////
    // Participant Join Properties
    ///////////////////////////////////////////////////////

    public static readonly callSidEndingConference = undefined;

    public static readonly reasonConferenceEnded = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationSessionEventLogConferenceEndSchema.callSidEndingConference] = SchemaField.string(
      CommunicationSessionEventLogConferenceEndSchema.Defaults.callSidEndingConference,
    );
    parameters[CommunicationSessionEventLogConferenceEndSchema.reasonConferenceEnded] = SchemaField.string(
      CommunicationSessionEventLogConferenceEndSchema.Defaults.reasonConferenceEnded,
    )
      .required()
      .valid(...values(OperatorReasonConferenceEnded));
    /* eslint-enable */

    return parameters;
  }
}
