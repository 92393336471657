import type { AnySchema } from 'joi';

import { SchemaField } from '../../../../../objects/generic/serialization/schema-field';
import { UserResponseSchema } from '../../generic/user-response/user-response-schema';

import { UserResponseCreateUserError } from './user-response-create-user-error';

export class UserResponseCreateUserSchema extends UserResponseSchema {
  protected static readonly userId = 'userId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////
  public static readonly Defaults = class extends UserResponseSchema.Defaults {
    public static readonly userId = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: AnySchema } {
    const parameters = super.getSchemaDefinition();

    parameters[UserResponseCreateUserSchema.error] = SchemaField.string(UserResponseCreateUserSchema.Defaults.error)
      .optional()
      .valid(...Object.values(UserResponseCreateUserError));
    parameters[UserResponseCreateUserSchema.userId] = SchemaField.string(UserResponseCreateUserSchema.Defaults.userId);

    return parameters;
  }
}
