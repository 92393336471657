import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Plotly } from 'angular-plotly.js/lib/plotly.interface';

import { AssignedUserStatTypes } from '@pwp-common';

import { EntityStatsService } from '../../../services/analytics/entity-stats/entity-stats.service';

@Component({
  selector: 'app-hours-and-calls',
  templateUrl: './hours-and-calls.component.html',
  styleUrls: ['./hours-and-calls.component.css'],
})
export class HoursAndCallsComponent implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Plot Configuration
  /////////////////////////////////////////////////////////////////////////////////////////////

  public graph = {
    data: [],
    layout: { title: 'Hours and Calls' },
  };

  public config: Partial<Plotly.Config> = {
    displaylogo: false,
    showLink: false,
    displayModeBar: false,
  };
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private entityStatsService: EntityStatsService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    await this.getData();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Get Data
  /////////////////////////////////////////////////////////////////////////////////////////////

  private async getData() {
    // Get data
    const entityStats = await this.entityStatsService.getLoggedInUserEntityData().toPromise();

    // Get months in order
    const months = entityStats.getSortedMonths();

    // Parse event data into format suitable for display
    const shiftHours: number[] = [];
    for (const month of months) {
      const hours =
        entityStats
          .getByMonth()
          .get(month)
          .getEvents()
          .getAssignedUserTypeStats()
          .get(AssignedUserStatTypes.any)
          ?.getDurationHours() || 0;
      shiftHours.push(hours);
    }

    // Parse call data into format suitable for display
    const numCalls: number[] = [];
    const byMonthStats = entityStats.getByMonth();
    for (const month of months) {
      numCalls.push(byMonthStats.get(month).getCalls().getINumAnswered());
    }

    // Display graph
    this.graph.data.push({ x: months, y: shiftHours, type: 'bar', name: 'shift hours', marker: { color: 'darkcyan' } });
    this.graph.data.push({
      x: months,
      y: numCalls,
      type: 'line',
      name: 'answered calls',
      marker: { color: 'darkred' },
    });

    this.changeDetectorRef.detectChanges();
  }
}
