import { SchemaField } from '../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';

export class TimeSelectionItemSchema extends SerializableObjectSchema {
  public static readonly include = 'include';

  public static readonly rrule = 'rrule';

  public static readonly duration = 'duration';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {};

  static Defaults = class {
    public static readonly include = undefined;

    public static readonly rrule = undefined;

    public static readonly duration = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Default Values
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[TimeSelectionItemSchema.include] = SchemaField.boolean(
      TimeSelectionItemSchema.Defaults.include,
    ).required();
    parameters[TimeSelectionItemSchema.rrule] = SchemaField.string(TimeSelectionItemSchema.Defaults.rrule).required();
    parameters[TimeSelectionItemSchema.duration] = SchemaField.isoDuration(
      TimeSelectionItemSchema.Defaults.duration,
    ).required();
    /* eslint-enable */

    return parameters;
  }
}
