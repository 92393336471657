import {
  AutomationActionSendEmail,
  AutomationOnCommunicationComplete,
  DBMatchRuleDocPath,
  DBMatchRuleName,
} from '@pwp-common';

import { AutomationEditorOutput } from '../automation-editor-output';

export const createAutomationFromEditorOutput = (
  id: string,
  output: AutomationEditorOutput,
): AutomationOnCommunicationComplete =>
  new AutomationOnCommunicationComplete({
    id,
    displayName: output.display.name,
    description: output.display.description,
    actions: [
      new AutomationActionSendEmail({
        subject: output.action.subject,
        body: output.action.message,
      }),
    ],
    triggerOn: new DBMatchRuleDocPath({
      orgScopedPaths: output.trigger.paths,
      type: DBMatchRuleName.docPath,
    }),
  });
