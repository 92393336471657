<ng-container *transloco="let t; read: 'event-request-config-table-editor'">
  <app-data-table
    [cols]="cols"
    [expansionRow]="expansionRow"
    [loading]="loading"
    [refreshClicked]="getDataWrapper"
    [selectedCols]="cols"
    [tableRows]="tableRows"
    [title]="t('title')"
    [translationScope]="'event-request-config-table-editor'"
  >
    <ng-template #expansionRow let-rowData>
      <app-event-request-config-table-editor-expansion
        [row]="rowData"
      ></app-event-request-config-table-editor-expansion>
    </ng-template>
  </app-data-table>
  <app-progress-spinner *ngIf="loading"></app-progress-spinner>
</ng-container>
