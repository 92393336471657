import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';

import { GenericDisplayable } from '@pwp-common';

import {
  getObjAutocompleteFormControlBaseProviders,
  ObjAutocompleteFormControlBase,
} from '../../abstract-classes/obj-autocomplete-form-control-base';

@UntilDestroy()
@Component({
  selector: 'app-obj-autocomplete-form-control',
  templateUrl: './obj-autocomplete-form-control.component.html',
  styleUrls: ['./obj-autocomplete-form-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...getObjAutocompleteFormControlBaseProviders(ObjAutocompleteFormControlComponent)],
})
export class ObjAutocompleteFormControlComponent extends ObjAutocompleteFormControlBase<any> implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Inputs / Outputs
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  @Input() options$: Observable<GenericDisplayable[]>;

  @Input() itemTemplate: TemplateRef<any>;

  @Input() selectedItemTemplate: TemplateRef<any>;

  @Input() dropdownAriaLabel: string;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Abstract Methods
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineAllOptions(): Observable<GenericDisplayable[]> {
    return this.options$ ?? of([]);
  }
}
