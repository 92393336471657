import type { AnySchema } from 'joi';

import { CommunicationsRequestSchema } from '../../generic/communications-request/communications-request-schema';

export class CommunicationsRequestGetVoiceDeviceTokenSchema extends CommunicationsRequestSchema {
  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: AnySchema } {
    const parameters = super.getSchemaDefinition();

    return parameters;
  }
}
