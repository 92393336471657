<ng-container *transloco="let t; read: 'target-editor'">
  <form [formGroup]="form">
    <div class="grid-nogutter">
      <div class="col-12">
        <div class="grid-nogutter p-fluid">
          <app-accordion-wizard-steps class="w-full">
            <app-accordion-wizard-step [hasError]="mode.invalid" [label]="t('stepModeLabel')">
              <app-target-mode-editor class="w-full" [formControl]="mode"></app-target-mode-editor>
            </app-accordion-wizard-step>

            <!-- Workers -->
            <app-accordion-wizard-step [hasError]="workers.invalid" [label]="t('stepWorkersLabel')">
              <div class="field mb-4 col-12">
                <span class="p-float-label">
                  <app-workers-autocomplete
                    class="w-full mt-3"
                    [formControl]="workers"
                    [label]="t('workersLabel')"
                    [ngClass]="{ 'ng-invalid': workers?.invalid }"
                    [unique]="true"
                  >
                  </app-workers-autocomplete>
                  <p *ngIf="workers.hasError('required')" class="p-error">
                    {{ t('workersErrorRequired', { numWorkers: workers.value?.length }) }}
                  </p>
                  <p *ngIf="workers.hasError('minlength')" class="p-error">
                    {{ t('workersErrorMinLength', { numWorkers: workers.value?.length }) }}
                  </p>
                  <p *ngIf="workers.hasError('maxlength')" class="p-error">
                    {{ t('workersErrorMaxLength', { numWorkers: workers.value?.length }) }}
                  </p>
                </span>
              </div>
            </app-accordion-wizard-step>

            <!-- Offers -->
            <app-accordion-wizard-step [hasError]="offers.invalid" [label]="t('stepOffersLabel')">
              <app-target-offers-editor
                attr.aria-label=""
                class="w-full"
                [formControl]="offers"
              ></app-target-offers-editor>
            </app-accordion-wizard-step>

            <!-- Context -->
            <app-accordion-wizard-step [hasError]="channelConfig.invalid" [label]="t('stepContextLabel')">
              <div class="field mb-4 col-12">
                <app-reservation-offer-channel-config
                  id="channelConfig"
                  [formControl]="channelConfig"
                ></app-reservation-offer-channel-config>
              </div>
            </app-accordion-wizard-step>

            <!-- Skip If -->
            <app-accordion-wizard-step [hasError]="skipOffersIf.invalid" [label]="t('stepSkipOffersIfLabel')">
              <div class="field mb-4 col-12">
                <app-target-offers-skip-if-editor
                  id="skipOffersIf"
                  [formControl]="skipOffersIf"
                ></app-target-offers-skip-if-editor>
              </div>
            </app-accordion-wizard-step>

            <!-- Workflow Target Timeout (Seconds) -->
            <app-accordion-wizard-step [hasError]="form.invalid" [label]="t('stepTimeoutLabel')">
              <div class="field col-12 mt-3">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon"><i class="pi pi-arrow-down"></i></span>
                  <span class="p-float-label">
                    <p-inputNumber
                      id="workflowTargetTimeoutSeconds"
                      mode="decimal"
                      ariaLabel="{{ t('workflowTargetTimeoutSecondsLabel') }}"
                      [formControl]="workflowTargetTimeoutSeconds"
                      [ngClass]="{ 'ng-invalid': workflowTargetTimeoutSeconds?.invalid }"
                      [showButtons]="true"
                      suffix=" {{ t('workflowTargetTimeoutSecondsSuffix') }}"
                    >
                    </p-inputNumber>
                    <label for="workflowTargetTimeoutSeconds">{{ t('workflowTargetTimeoutSecondsLabel') }}</label>
                  </span>
                </div>

                <p *ngIf="workflowTargetTimeoutSeconds.errors?.required" class="p-error">
                  {{ t('workflowTargetTimeoutSecondsErrorRequired') }}
                </p>
                <p *ngIf="workflowTargetTimeoutSeconds.errors?.min as minErrors" class="p-error">
                  {{ t('workflowTargetTimeoutSecondsErrorMin', minErrors) }}
                </p>
                <p *ngIf="workflowTargetTimeoutSeconds.errors?.max" class="p-error">
                  {{
                    t('workflowTargetTimeoutSecondsErrorMax', {
                      workflowTargetTimeoutSeconds: workflowTargetTimeoutSeconds.value,
                    })
                  }}
                </p>
                <p class="wrap" id="workflowTargetTimeoutSeconds-help">
                  {{
                    t('workflowTargetTimeoutSecondsHelp', {
                      workflowTargetTimeoutSeconds: workflowTargetTimeoutSeconds.value,
                    })
                  }}
                </p>
              </div>
            </app-accordion-wizard-step>
          </app-accordion-wizard-steps>
        </div>
      </div>
    </div>
  </form>
</ng-container>
