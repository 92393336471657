import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { CommunicationsRequestName } from '../../../communications-request-name';
import { CommunicationsResponse } from '../../generic/communications-response/communications-response';
import { CommunicationsResponseSchema } from '../../generic/communications-response/communications-response-schema';

import { CommunicationsResponseHandleAsyncServiceRequestConstructor } from './communications-response-handle-async-service-request-constructor';
import { CommunicationsResponseHandleAsyncServiceRequestError } from './communications-response-handle-async-service-request-error';
import { CommunicationsResponseHandleAsyncServiceRequestSchema } from './communications-response-handle-async-service-request-schema';

export class CommunicationsResponseHandleAsyncServiceRequest extends CommunicationsResponse<CommunicationsResponseHandleAsyncServiceRequestError> {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationsResponseHandleAsyncServiceRequestConstructor) {
    (parameters as any)[CommunicationsResponseSchema.type] = CommunicationsRequestName.handleAsyncServiceRequest;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(
    validationResult: import('joi').ValidationResult,
  ): CommunicationsResponseHandleAsyncServiceRequest {
    return new CommunicationsResponseHandleAsyncServiceRequest(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationsResponseHandleAsyncServiceRequest.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationsResponseHandleAsyncServiceRequestSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
}
