import { isNil } from 'lodash';

import { IVRResponse } from '../../../ivr/ivr-response/ivr-response';
import { IVRResponseDirection } from '../../../ivr/ivr-response/ivr-response-direction';
import { speechDigitsProducedByMachine } from '../speech-digits-produced-by-machine/speech-digits-produced-by-machine';

export const wasAnsweredByMachine = (responses: IVRResponse[]): boolean | undefined => {
  let machineAnswered;
  for (const response of responses) {
    if (response.getDirection() !== IVRResponseDirection.received) {
      continue;
    }

    const speech = response.getSpeech();
    const digits = response.getDigits();
    if (!isNil(digits) && digits.length > 0) {
      return false;
    }
    machineAnswered = machineAnswered || speechDigitsProducedByMachine(speech, digits);
    if (machineAnswered) {
      /**
       * We say it's not machine answered if none of the speech was produced
       * by machine, or if some digits were received.
       */
      break;
    }
  }
  return machineAnswered;
};
