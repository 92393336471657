import { SchemaField } from '../../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../../generic/serialization/serializable-object-schema';
import { ServiceLimitExecution } from '../service-limit-execution/service-limit-execution';

export class ServiceLimitExecutionsSchema extends SerializableObjectSchema {
  public static readonly executions = 'executions';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly executions = new Map();
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as { [key: string]: import('joi').AnySchema };

    /* eslint-disable */
    parameters[ServiceLimitExecutionsSchema.executions] = SchemaField.mapOfObj(
      ServiceLimitExecution,
      ServiceLimitExecutionsSchema.Defaults.executions,
    );
    /* eslint-enable */

    return parameters;
  }
}
