import { cloneDeep } from 'lodash';

import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { PossibleAdminRole } from '../../../../../objects/user/admin-roles/types';
import { PossibleRole } from '../../../../../objects/user/roles/types';
import { ConversationRequestName } from '../../../conversation-request-name';
import { ConversationRequest } from '../../generic/conversation-request/conversation-request';
import { ConversationRequestSchema } from '../../generic/conversation-request/conversation-request-schema';

import { ConversationRequestCloseAndDeleteConstructor } from './conversation-request-close-and-delete-constructor';
import { ConversationRequestCloseAndDeleteSchema } from './conversation-request-close-and-delete-schema';

export class ConversationRequestCloseAndDelete extends ConversationRequest {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected conversationLogId!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ConversationRequestCloseAndDeleteConstructor) {
    (parameters as any)[ConversationRequestSchema.type] = ConversationRequestName.closeAndDelete;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ConversationRequestCloseAndDelete {
    return new ConversationRequestCloseAndDelete(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ConversationRequestCloseAndDelete.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ConversationRequestCloseAndDeleteSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Roles
  /////////////////////////////////////////////////////////////////////////////

  public getRequiredRoles(): PossibleRole[] {
    return ['conversation'];
  }

  public getRequiredAdminRoles(): PossibleAdminRole[] | undefined {
    return undefined;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getConversationLogId(): string {
    return cloneDeep(this.conversationLogId);
  }
}
