import Joi from 'joi';

import { SerializableObjectSchema } from '../../../../../../../generic/serialization/serializable-object-schema';
import { CommunicationSessionEventLogConferenceEndSchema } from '../../type/communication-session-event-log-conference-end/communication-session-event-log-conference-end-schema';
import { CommunicationSessionEventLogConferenceParticipantJoinSchema } from '../../type/communication-session-event-log-conference-participant-join/communication-session-event-log-conference-participant-join-schema';
import { CommunicationSessionEventLogConferenceStartSchema } from '../../type/communication-session-event-log-conference-start/communication-session-event-log-conference-start-schema';

export class AnyCommunicationSessionEventLogConferenceSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The type of the entity
  public static readonly conferenceEventType = 'conferenceEventType';

  private static readonly SCHEMA_ID = 'anyCommunicationSessionEventLogConference';

  private static computedSchema = Joi.alternatives()
    .try(
      ...[
        new CommunicationSessionEventLogConferenceEndSchema().getJoiSchema(),
        new CommunicationSessionEventLogConferenceStartSchema().getJoiSchema(),
        new CommunicationSessionEventLogConferenceParticipantJoinSchema().getJoiSchema(),
      ],
    )
    .id(AnyCommunicationSessionEventLogConferenceSchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static GenericDefaults = class {
    // The name of the command
    public static readonly type = undefined;
  };

  /**
   * Always serialize the command name
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  public static getSchemaId(): string {
    return `#${AnyCommunicationSessionEventLogConferenceSchema.SCHEMA_ID}`;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } | import('joi').AnySchema {
    return AnyCommunicationSessionEventLogConferenceSchema.computedSchema;
  }
}
