import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';

import { ProgressSpinnerModule } from '../core/progress-spinner/progress-spinner.module';
import { LoadingModule } from '../generic/loading.module';

import { BlockedCallerComponent } from './blocked-caller/blocked-caller.component';
import { CallerInfoDialogComponent } from './caller-info-dialog/caller-info-dialog.component';

@NgModule({
  imports: [
    BlockedCallerComponent,
    ButtonModule,
    CallerInfoDialogComponent,
    CommonModule,
    FormsModule,
    LoadingModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    ToastModule,
    TranslocoModule,
  ],
  exports: [],
})
export class CallModule {}
