import { values } from 'lodash';

import { SchemaField } from '../../../../../objects/generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { CommunicationsRequestName } from '../../../communications-request-name';

export abstract class CommunicationsRequestSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The name of the command
  public static readonly type = 'type';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[CommunicationsRequestSchema.type] = SchemaField.string(CommunicationsRequestSchema.Defaults.type)
      .required()
      .valid(...values(CommunicationsRequestName));
    /* eslint-enable */

    return parameters;
  }
}
