import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';

import { Interval } from '../../../../helper/interval';
import { getRRuleDtstart, rruleHasDtstart, rruleHasTzid } from '../../../../helper/rrule/fields';
import { getOccuranceBefore } from '../../../../helper/rrule/recurrences';
import { validateAndGetRRuleFromStr } from '../../../../helper/rrule/validation';
import { SerializableObject } from '../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';

import { TimeSelectionItemConstructor } from './time-selection-item-constructor';
import { TimeSelectionItemSchema } from './time-selection-item-schema';

export class TimeSelectionItem extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected include!: boolean;

  protected rrule!: string;

  protected duration!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: TimeSelectionItemConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): TimeSelectionItem {
    return new TimeSelectionItem(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(TimeSelectionItem.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new TimeSelectionItemSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getInclude(): boolean {
    return cloneDeep(this.include);
  }

  public getDuration(): string {
    return cloneDeep(this.duration);
  }

  public getRRule(): string {
    return cloneDeep(this.rrule);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Contains
  /////////////////////////////////////////////////////////////////////////////

  public doesInclude(timestamp: moment.Moment, defaultValue: boolean): boolean {
    const dtStart = getRRuleDtstart(this.getRRule());
    if (timestamp.isBefore(dtStart)) {
      return defaultValue;
    }

    const rrule = validateAndGetRRuleFromStr(this.getRRule(), true)!;
    const before = getOccuranceBefore(timestamp, rrule, true);
    const after = cloneDeep(before).add(this.getDuration());

    const interval = new Interval(before, after);
    if (interval.containsOrIsOnBoundary(timestamp)) {
      return this.getInclude();
    }
    return defaultValue;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Sanity Check
  /////////////////////////////////////////////////////////////////////////////

  public sanityCheck(): void {
    const rrule = validateAndGetRRuleFromStr(this.getRRule(), true)!;

    if (!rruleHasDtstart(this.getRRule(), true)) {
      console.error('TimeSelectionItem.sanityCheck: RRule is missing dtStart', { rrule: this.getRRule() });
      throw new Error('TimeSelectionItem.sanityCheck: RRule is missing dtStart');
    }

    if (!rruleHasTzid(this.getRRule(), true)) {
      console.error('TimeSelectionItem.sanityCheck: RRule is missing tzid', { rrule: this.getRRule() });
      throw new Error('TimeSelectionItem.sanityCheck: RRule is missing tzid');
    }

    const dtStart = getRRuleDtstart(this.getRRule())!;
    const before = getOccuranceBefore(dtStart, rrule, true);
    if (!before.isSame(dtStart)) {
      console.error('TimeSelectionItem.sanityCheck: The dtstart is not a recurrance of the RRule', {
        rrule: this.getRRule(),
      });
      throw new Error('TimeSelectionItem.sanityCheck: The dtstart is not a recurrance of the RRule');
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Complement
  /////////////////////////////////////////////////////////////////////////////

  public getComplement(): TimeSelectionItem {
    return new TimeSelectionItem({
      include: !this.include,
      rrule: this.rrule,
      duration: this.duration,
    })
  }

}
