import { cloneDeep } from 'lodash';

import { UserStatus } from '../../../../../../../call/dialed-call-log/user-status';
import { CommunicationSessionEventLogSendMessage } from '../../generic/communication-session-event-log-send-message/communication-session-event-log-send-message';
import { CommunicationSessionEventLogSendMessageSchema } from '../../generic/communication-session-event-log-send-message/communication-session-event-log-send-message-schema';
import { CommunicationSessionEventLogSendMessageType } from '../../generic/communication-session-event-log-send-message-type';

import { CommunicationSessionEventLogSendMessageNotificationConstructor } from './communication-session-event-log-send-message-notification-constructor';
import { CommunicationSessionEventLogSendMessageNotificationSchema } from './communication-session-event-log-send-message-notification-schema';

export class CommunicationSessionEventLogSendMessageNotification extends CommunicationSessionEventLogSendMessage {
  protected userStatus?: UserStatus;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationSessionEventLogSendMessageNotificationConstructor) {
    super(
      Object.assign(parameters, {
        [CommunicationSessionEventLogSendMessageSchema.messageType]:
          CommunicationSessionEventLogSendMessageType.notification,
      }),
    );
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(
    validationResult: import('joi').ValidationResult,
  ): CommunicationSessionEventLogSendMessageNotification {
    return new CommunicationSessionEventLogSendMessageNotification(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationSessionEventLogSendMessageNotification.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): CommunicationSessionEventLogSendMessageNotificationSchema {
    return new CommunicationSessionEventLogSendMessageNotificationSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getUserStatus() {
    return cloneDeep(this.userStatus);
  }
}
