import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';

import { Switch, SwitchCase, SwitchOnField, SwitchSchema } from '@pwp-common';

import { getFieldValuesFromFormInComponent } from '../../../../../common/objects/form-helper';
import { KVPair } from '../../../../../common/objects/kvpair';
import { VRCEditor } from '../../../../generic/abstract-classes/vrc-editor';

@Component({
  selector: 'app-vrc-editor-switch',
  templateUrl: './vrc-editor-switch.component.html',
  styleUrls: ['./vrc-editor-switch.component.css'],
})
export class VrcEditorSwitchComponent extends VRCEditor<Switch> implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  cases: SwitchCase[];

  selectedCaseKVPair: KVPair<SwitchCase>;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  switchOnFieldList: SwitchOnField[] = Object.keys(SwitchOnField).map((val, _) => val as SwitchOnField);

  objBuilder = SwitchCase;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(_changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parameters = getFieldValuesFromFormInComponent(
      undefined,
      [SwitchSchema.switchOnField],
      SwitchSchema.Defaults,
      this,
    );

    parameters[SwitchSchema.cases] = this.cases || SwitchSchema.Defaults.cases;

    const value = new Switch(parameters);
    return new KVPair({ id: this.obj.id, value });
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: KVPair<Switch>) {
    // Init Form
    const formConfig = {} as any;
    formConfig[SwitchSchema.switchOnField] = [obj.value!.getSwitchOnField(), [Validators.required]];

    this.form = this.formBuilder.group(formConfig);

    // Init other state
    this.cases = obj.value!.getCases();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  get switchOnField(): AbstractControl | null {
    return this.form.get(SwitchSchema.switchOnField);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObjScoper = (): boolean => this.isValidObj();

  isValidObj() {
    if (!super.isValidObj()) {
      return false;
    }

    return true;
  }
}
