import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

import { AudioPlayerModule } from '../core/audio-player/audio-player.module';
import { DateRangeSelectModule } from '../core/date-range-select/date-range-select.module';
import { PhoneEditorModule } from '../core/phone-editor/phone-editor.module';
import { ProgressSpinnerModule } from '../core/progress-spinner/progress-spinner.module';
import { DataTableModule } from '../generic/data-table/data-table.module';
import { ObjAutocompleteV2Module } from '../generic/obj-autocomplete-v2/obj-autocomplete-v2.module';

import { InboxTableComponent } from './table/inbox-table/inbox-table.component';
import { InboxCallbackDetailComponent } from './table/row-expansion/callback/inbox-callback-detail/inbox-callback-detail.component';
import { InboxCallbackDetailCallerDetailsComponent } from './table/row-expansion/callback/inbox-callback-detail-caller-details/inbox-callback-detail-caller-details.component';
import { InboxCallbackDetailHandledByButtonComponent } from './table/row-expansion/callback/inbox-callback-detail-handled-by-button/inbox-callback-detail-handled-by-button.component';
import { InboxCallbackDialPhoneButtonComponent } from './table/row-expansion/callback/inbox-callback-dial-phone-button/inbox-callback-dial-phone-button.component';
import { InboxCreateCallbackNoteComponent } from './table/row-expansion/callback/inbox-create-callback-note/inbox-create-callback-note.component';
import { InboxDialPhoneButtonComponent } from './table/row-expansion/generic/inbox-dial-phone-button/inbox-dial-phone-button.component';
import { InboxDialPhoneConfirmComponent } from './table/row-expansion/generic/inbox-dial-phone-confirm/inbox-dial-phone-confirm.component';
import { InboxHandledByButtonComponent } from './table/row-expansion/generic/inbox-handled-by-button/inbox-handled-by-button.component';
import { InboxVoicemailDetailComponent } from './table/row-expansion/voicemail/inbox-voicemail-detail/inbox-voicemail-detail.component';
import { InboxVoicemailDetailDialPhoneButtonComponent } from './table/row-expansion/voicemail/inbox-voicemail-detail-dial-phone-button/inbox-voicemail-detail-dial-phone-button.component';
import { InboxVoicemailDetailHandledByButtonComponent } from './table/row-expansion/voicemail/inbox-voicemail-detail-handled-by-button/inbox-voicemail-detail-handled-by-button.component';
import { InboxVoicemailPlayComponent } from './table/row-expansion/voicemail/inbox-voicemail-play/inbox-voicemail-play.component';

@NgModule({
  declarations: [
    InboxCallbackDetailCallerDetailsComponent,
    InboxCallbackDetailComponent,
    InboxCallbackDetailHandledByButtonComponent,
    InboxCallbackDialPhoneButtonComponent,
    InboxCreateCallbackNoteComponent,
    InboxDialPhoneButtonComponent,
    InboxDialPhoneConfirmComponent,
    InboxHandledByButtonComponent,
    InboxTableComponent,
    InboxVoicemailDetailComponent,
    InboxVoicemailDetailDialPhoneButtonComponent,
    InboxVoicemailDetailHandledByButtonComponent,
    InboxVoicemailPlayComponent,
  ],
  exports: [InboxTableComponent],
  imports: [
    AudioPlayerModule,
    ButtonModule,
    CardModule,
    CheckboxModule,
    CommonModule,
    DataTableModule,
    DateRangeSelectModule,
    FormsModule,
    InputTextareaModule,
    InputTextModule,
    ObjAutocompleteV2Module,
    PhoneEditorModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    ToastModule,
    TooltipModule,
    TranslocoModule,
  ],
})
export class InboxModule {}
