import { values } from 'lodash';

import { SchemaField } from '../../../../../../generic/serialization/schema-field';
import { ReservationSkipOffersIfRuleSchema } from '../../generic/reservation-skip-offers-if-rule/reservation-skip-offers-if-rule-schema';

import { ReservationSkipOffersIfRuleChannelMaxChannelName } from './reservation-skip-offers-if-rule-channel-max-channel-name';

export class ReservationSkipOffersIfRuleChannelMaxSchema extends ReservationSkipOffersIfRuleSchema {
  public static readonly channel = 'channel';

  public static readonly skipIfInProgressGE = 'skipIfInProgressGE';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;

    public static readonly channel = undefined;

    public static readonly skipIfInProgressGE = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[ReservationSkipOffersIfRuleChannelMaxSchema.channel] = SchemaField.string(
      ReservationSkipOffersIfRuleChannelMaxSchema.Defaults.channel,
    )
      .valid(...values(ReservationSkipOffersIfRuleChannelMaxChannelName))
      .required();
    parameters[ReservationSkipOffersIfRuleChannelMaxSchema.skipIfInProgressGE] = SchemaField.number(
      ReservationSkipOffersIfRuleChannelMaxSchema.Defaults.skipIfInProgressGE,
    ).required();
    /* eslint-enable */

    return parameters;
  }
}
