import Joi from 'joi';

import { SerializableObjectSchema } from '../../../../../../generic/serialization/serializable-object-schema';
import { ReservationSkipOffersIfRuleAvailabilitySchema } from '../../reservation-skip-offers-if-rules/reservation-skip-offers-if-rule-availability/reservation-skip-offers-if-rule-availability-schema';
import { ReservationSkipOffersIfRuleChannelMaxSchema } from '../../reservation-skip-offers-if-rules/reservation-skip-offers-if-rule-channel-max/reservation-skip-offers-if-rule-channel-max-schema';

export class AnyReservationSkipOffersIfRuleSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The type of the entity
  public static readonly type = 'type';

  private static readonly SCHEMA_ID = 'anyDBMatchRule';

  private static computedSchema = Joi.alternatives()
    .try(
      ...[
        new ReservationSkipOffersIfRuleChannelMaxSchema().getJoiSchema(),
        new ReservationSkipOffersIfRuleAvailabilitySchema().getJoiSchema(),
      ],
    )
    .id(AnyReservationSkipOffersIfRuleSchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static GenericDefaults = class {
    // The name of the command
    public static readonly type = undefined;
  };

  /**
   * Always serialize the command name
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  public static getSchemaId(): string {
    return `#${AnyReservationSkipOffersIfRuleSchema.SCHEMA_ID}`;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } | import('joi').AnySchema {
    return AnyReservationSkipOffersIfRuleSchema.computedSchema;
  }
}
