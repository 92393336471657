<ng-container *transloco="let t; read: 'event-requests-query-editor'">
  <p-toast position="bottom-center"></p-toast>

  <div class="event-requests-query-editor__header">
    <form [formGroup]="form">
      <div class="form-row">
        <div class="col">
          <p-selectButton
            formControlName="eventRequestType"
            ariaLabel="{{ t('eventRequestTypeLabel') }}"
            [options]="[
              { label: t('eventRequestOpenLabel'), value: 'open' },
              { label: t('eventRequestDecidedLabel'), value: 'decided' },
            ]"
          ></p-selectButton>
        </div>
      </div>
      <div class="form-row">
        <div *ngIf="eventRequestType?.value === 'decided'" class="col">
          <mat-form-field appearance="fill">
            <mat-label>{{ t('dateRangeLabel') }}</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input formControlName="start" matStartDate placeholder="{{ t('start') }}" />
              <input formControlName="end" matEndDate placeholder="{{ t('end') }}" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error *ngIf="start?.hasError('matStartDateInvalid')">{{ t('invalidStartDate') }}</mat-error>
            <mat-error *ngIf="end?.hasError('matEndDateInvalid')">{{ t('invalidEndDate') }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
    <button
      class="p-button-text"
      pButton
      [disabled]="loading || !queryIsValid()"
      label="{{ t('queryButton') }}"
      [loading]="loading"
      (click)="refreshData()"
    ></button>
  </div>
  <mat-spinner *ngIf="loading"></mat-spinner>
  <app-event-requests-table-all
    *ngIf="!loading"
    [allDataEvent]="allDataEvent"
    [orgData]="orgDataObservable | async"
    (uploadComplete)="refreshData()"
  ></app-event-requests-table-all>
</ng-container>
