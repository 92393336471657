import { values } from 'lodash';

import { SchemaField } from '../../../generic/serialization/schema-field';
import { IdentityConfigSchema } from '../identity-config/identity-config-schema';

import { IdentityConfigConversationCaptureTarget } from './identity-config-conversation-capture-target';

export class IdentityConfigConversationSchema extends IdentityConfigSchema {
  public static readonly captureTarget = 'captureTarget';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly captureTarget = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[IdentityConfigConversationSchema.captureTarget] = SchemaField.string(
      IdentityConfigConversationSchema.Defaults.captureTarget,
    )
      .optional()
      .valid(...values(IdentityConfigConversationCaptureTarget));
    /* eslint-enable */

    return parameters;
  }
}
