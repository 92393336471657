import { BlobLocation } from '../../generic/blob-storage/blob-location';

export class VoicemailBlobLocation extends BlobLocation {
  // Firebase Blob storage associated with schema
  static StorageDirectories = class {
    public static readonly collection = 'voicemails';
  };

  public static Constants = class {
    public static readonly EXTENSION_WAV = 'wav';
  };

  public getAllowedFileExtensions(): string[] {
    return [VoicemailBlobLocation.Constants.EXTENSION_WAV];
  }

  public getStorageDir(orgId: string): string {
    return `${this.orgDir(orgId)}/${VoicemailBlobLocation.StorageDirectories.collection}`;
  }

  public getFileFullPath(orgId: string, sessionId: string): string {
    return `${this.getStorageDir(orgId)}/${sessionId}.${VoicemailBlobLocation.Constants.EXTENSION_WAV}`;
  }
}
