import { Component, Input, OnChanges, OnInit, TemplateRef } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isNil } from 'lodash';

import { NgChanges } from '../../../../common/objects/ng-changes';
import { PTableCol, subscribePTableTranslatedCols } from '../../../../common/p-table/p-table-col';

@UntilDestroy()
@Component({
  selector: 'app-analytics-table',
  templateUrl: './analytics-table.component.html',
  styleUrls: ['./analytics-table.component.css'],
})
export class AnalyticsTableComponent implements OnInit, OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Input / Output
  /////////////////////////////////////////////////////////////////////////////////////////////

  @Input() initialSelectedColumns: string[];

  @Input() cols: string[];

  @Input() data: any[];

  @Input() dataKey: string;

  @Input() translationScope: string;

  @Input() headerTemplate: TemplateRef<any>;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  selectedCols: PTableCol[] = [];

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    // subscribePTableTranslatedCols(this, this.translocoService, this.translationScope, this.cols, this.initialSelectedColumns);
  }

  ngOnChanges(changes: NgChanges<AnalyticsTableComponent>) {
    if (!isNil(changes.cols)) {
      subscribePTableTranslatedCols(
        this,
        this.translocoService,
        this.translationScope,
        this.cols,
        this.initialSelectedColumns,
      );
    }
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Table
  /////////////////////////////////////////////////////////////////////////////////////////////

  // resetSelectedCols() {
  //   subscribePTableTranslatedCols(this, this.translocoService, this.translationScope, this.cols, this.selectedCols);
  // }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Translation
  /////////////////////////////////////////////////////////////////////////////////////////////

  getTranslationKey(key: string) {
    return `${this.translationScope}.${key}`;
  }
}
