import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AllDataEventDisplay, EventDataSchema } from '@pwp-common';

import { AllDataEventService } from '../../../services/event/all-data-event/all-data-event.service';
import { DBQuery } from '../../../services/generic/interfaces';
import { OrgDataService } from '../../../services/orgs/org-data/org-data.service';
import { AuthService } from '../../../services/user/auth/auth.service';

@Component({
  selector: 'app-confirmed-events-table',
  templateUrl: './confirmed-events-table.component.html',
  styleUrls: ['./confirmed-events-table.component.css'],
})
export class ConfirmedEventsTableComponent implements OnInit, OnDestroy {
  ////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////

  // Constants specifying what data to downlad as backing data for the view
  allDataEventDisplay: AllDataEventDisplay[] = [];

  private ngUnsubscribe = new Subject<void>();

  ///////////////////////////////////////////////////////////////////////
  // Mat-Table Configuration
  ///////////////////////////////////////////////////////////////////////

  displayedColumns = ['startTime', 'endTime', 'type', 'assignedUser', 'assignedBackup', 'trainLevel'];

  dataSource = new MatTableDataSource<AllDataEventDisplay>(this.allDataEventDisplay);

  refreshMatTableDataSource() {
    this.dataSource.data = this.allDataEventDisplay;
    this.changeDetectorRef.detectChanges();
  }

  ////////////////////////////////////////////////////////////////////////
  // Constructor
  ////////////////////////////////////////////////////////////////////////

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private orgDataService: OrgDataService,
    private allDataEventService: AllDataEventService,
    private authService: AuthService,
  ) {}

  ////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.changeDetectorRef.detach();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ////////////////////////////////////////////////////////////////////////
  // Get Event Data
  ////////////////////////////////////////////////////////////////////////

  async getData() {
    this.allDataEventDisplay = [];

    // Get Org Data
    const orgData = await this.orgDataService.getOrgData().toPromise();

    const userId = await this.authService.getUserId().toPromise();

    // Form queries
    const queries: DBQuery[][] = [
      // Assigned User Query
      [
        { fieldPath: EventDataSchema.assignedUserId, opStr: '==', value: userId },
        { fieldPath: EventDataSchema.start, opStr: '>=', value: new Date() },
      ],

      // Assigned Backup User Query
      [
        { fieldPath: EventDataSchema.assignedBackupUserId, opStr: '==', value: userId },
        { fieldPath: EventDataSchema.start, opStr: '>=', value: new Date() },
      ],
    ];

    for (const query of queries) {
      await this.allDataEventService
        .getAllDocs(query, false, true)
        .pipe(
          map((allDataEventMap, _) => {
            const displayData = Array.from(allDataEventMap.values()).map((z) => new AllDataEventDisplay(z, orgData));
            this.allDataEventDisplay.push(...displayData);
          }),
        )
        .toPromise();
    }

    this.refreshMatTableDataSource();
  }
}
