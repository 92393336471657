import { isNil } from 'lodash';

import { DBDocSchema } from '../db-doc/db-doc-schema';

export const isNilOrDefaultDocId = (docId: string | null | undefined): boolean => {
  if (isNil(docId) || docId === DBDocSchema.GenericDefaults.id) {
    return true;
  }
  return false;
};
