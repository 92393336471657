import { SchemaField } from '../../../../../objects/generic/serialization/schema-field';
import { CommunicationsRequestSchema } from '../../generic/communications-request/communications-request-schema';

export class CommunicationsRequestCreateOutboundCallSessionSchema extends CommunicationsRequestSchema {
  public static readonly callerIdObjId = 'callerIdObjId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;

    public static readonly callerIdObjId = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationsRequestCreateOutboundCallSessionSchema.callerIdObjId] = SchemaField.autoGeneratedDocId(
      CommunicationsRequestCreateOutboundCallSessionSchema.Defaults.callerIdObjId,
    ).required();
    /* eslint-enable */

    return parameters;
  }
}
