import Joi from 'joi';

import { SerializableObjectSchema } from '../../../../../generic/serialization/serializable-object-schema';
import { CommunicationTaskQueueWorkerListEventSchema } from '../../communication-task-queue-worker-lists/communication-task-queue-worker-list-event/communication-task-queue-worker-list-event-schema';
import { CommunicationTaskQueueWorkerListUserSchema } from '../../communication-task-queue-worker-lists/communication-task-queue-worker-list-user/communication-task-queue-worker-list-user-schema';

export class AnyCommunicationTaskQueueWorkerListSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The type of the entity
  public static readonly type = 'type';

  private static readonly SCHEMA_ID = 'anyCommunicationTaskQueueWorkerList';

  private static computedSchema = Joi.alternatives()
    .try(
      ...[
        new CommunicationTaskQueueWorkerListEventSchema().getJoiSchema(),
        new CommunicationTaskQueueWorkerListUserSchema().getJoiSchema(),
      ],
    )
    .id(AnyCommunicationTaskQueueWorkerListSchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static GenericDefaults = class {
    // The name of the command
    public static readonly type = undefined;
  };

  /**
   * Always serialize the command name
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  public static getSchemaId(): string {
    return `#${AnyCommunicationTaskQueueWorkerListSchema.SCHEMA_ID}`;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } | import('joi').AnySchema {
    return AnyCommunicationTaskQueueWorkerListSchema.computedSchema;
  }
}
