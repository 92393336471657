import { cloneDeep } from 'lodash';

import { DBDocObject } from '../../generic/db-doc/db-doc-object';
import { DBDocSchema } from '../../generic/db-doc/db-doc-schema';
import { Displayable } from '../../generic/db-doc/displayable';

import { UserDataConstructor } from './user-data-constructor';
import { UserDataSchema } from './user-data-schema';

export class UserData extends DBDocObject implements Displayable {
  protected firstName!: string;

  protected lastInitial!: string;

  protected notificationEmail!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new UserDataSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): UserData {
    return new UserData(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: UserDataConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getFirstName(): string {
    return cloneDeep(this.firstName);
  }

  /**
   * @deprecated use getDisplayNameWithEmail instead
   */
  public getDisplayName(): string {
    return cloneDeep(`${this.lastInitial}. ${this.firstName}`);
  }

  public getLastInitial(): string {
    return cloneDeep(this.lastInitial);
  }

  public getNotificationEmail(): string {
    return cloneDeep(this.notificationEmail);
  }

  public getDisplayNameWithEmail(): string {
    return `${this.getDisplayName()} <${this.getNotificationEmail()}>`;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  public setFirstName(firstName: string): UserData {
    this.firstName = firstName;
    return this;
  }

  public setLastInitial(lastInitial: string): UserData {
    this.lastInitial = lastInitial;
    return this;
  }

  public setNotificationEmail(notificationEmail: string): UserData {
    this.notificationEmail = notificationEmail;
    return this;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////

  public hasIncompleteProfile(): boolean {
    if (
      this.getFirstName() === UserDataSchema.Defaults.firstName ||
      this.getLastInitial() === UserDataSchema.Defaults.lastInitial ||
      this.getNotificationEmail() === UserDataSchema.Defaults.notificationEmail
    ) {
      return true;
    }

    return false;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(UserData.getSchema());
  }
}
