<ng-container *transloco="let t; read: 'my-user-profile-editor'">
  <div *ngIf="!loading">
    <ng-template #objEditor>
      <div class="flex justify-content-center">
        <p-avatar icon="pi pi-user" pBadge size="xlarge" [severity]="userBadgeSeverity"></p-avatar>
      </div>
      <!-- Durations -->
      <form [formGroup]="form">
        <!-- First Name -->
        <div class="field">
          <label for="firstName">{{ t('firstNameLabel') }}</label>
          <div class="inputfield w-full">
            <div class="p-inputgroup">
              <input
                id="firstName"
                pInputText
                type="text"
                ariaLabel="{{ t('firstNameLabel') }}"
                [formControl]="firstName"
              />
            </div>
            <div id="firstName-help">
              <p *ngIf="firstName.errors?.required" class="p-error">{{ t('requiredError') }}</p>
              <p *ngIf="firstName.errors?.minlength" class="p-error">
                {{ t('minLengthError', firstName.errors?.minlength) }}
              </p>
              <p *ngIf="firstName.errors?.maxlength" class="p-error">
                {{ t('maxLengthError', firstName.errors?.maxlength) }}
              </p>
            </div>
          </div>
        </div>

        <!-- Last Initial -->
        <div class="field">
          <label for="lastInitial">{{ t('lastInitialLabel') }}</label>
          <div class="inputfield w-full">
            <div class="p-inputgroup">
              <input
                id="lastInitial"
                pInputText
                type="text"
                ariaLabel="{{ t('lastInitialLabel') }}"
                [formControl]="lastInitial"
              />
            </div>
            <div id="lastInitial-help">
              <p *ngIf="lastInitial.errors?.required" class="p-error">{{ t('requiredError') }}</p>
              <p *ngIf="lastInitial.errors?.minlength" class="p-error">
                {{ t('minLengthError', lastInitial.errors?.minlength) }}
              </p>
              <p *ngIf="lastInitial.errors?.maxlength" class="p-error">
                {{ t('maxLengthError', lastInitial.errors?.maxlength) }}
              </p>
            </div>
          </div>
        </div>

        <!-- Notification Email -->
        <div class="field">
          <label for="notificationEmail">{{ t('notificationEmailLabel') }}</label>
          <div class="inputfield w-full">
            <div class="p-inputgroup">
              <input
                id="notificationEmail"
                pInputText
                type="text"
                ariaLabel="{{ t('notificationEmailLabel') }}"
                [formControl]="notificationEmail"
              />
              <p-button
                icon="pi pi-pencil"
                styleClass="p-button-secondary"
                ariaLabel="{{ t('editNotificationEmailButtonLabel') }}"
                (onClick)="openChangeEmailDialog()"
              >
              </p-button>
            </div>
            <div id="notificationEmail-help">
              <p *ngIf="notificationEmail.errors?.required" class="p-error">{{ t('requiredError') }}</p>
              <p>{{ t('notificationEmailHelp', { value: notificationEmail.value }) }}</p>
            </div>
          </div>
        </div>

        <!-- Phone -->
        <div class="field">
          <label for="e164Phone">{{ t('e164PhoneLabel') }}</label>
          <p-inputMask
            id="e164Phone"
            mask="(999) 999-9999"
            styleClass="inputfield w-full"
            ariaLabel="{{ t('e164PhoneLabel') }}"
            [formControl]="e164Phone"
          >
          </p-inputMask>
          <div id="e164Phone-help">
            <p *ngIf="e164Phone.errors?.required" class="p-error">{{ t('requiredError') }}</p>
            <p *ngIf="e164Phone.errors?.isValidPhone" class="p-error">{{ t('e164PhoneInvalid') }}</p>
            <p>{{ t('e164PhoneHelp', { value: e164Phone.value }) }}</p>
          </div>
        </div>

        <!-- Availability  -->
        <div class="field">
          <label for="availability">{{ t('availabilityLabel') }}</label>
          <p-dropdown
            autoDisplayFirst="false"
            id="availability"
            styleClass="inputfield w-full"
            ariaLabel="{{ t('availabilityLabel') }}"
            [formControl]="availability"
            [options]="possibleValuesAvailability"
          >
            <ng-template pTemplate="item" let-availabilityValue>
              <div>{{ t(availabilityValue.label) }}</div>
            </ng-template>
            <ng-template pTemplate="selectedItem" data-testid="availabilitySelectedValue" let-availabilityValue>
              <div>{{ t(availabilityValue.label) }}</div>
            </ng-template>
          </p-dropdown>
          <div id="availability-help">
            <p *ngIf="availability?.value === true">{{ t('availabilityHelpMarkAvailable') }}</p>
            <p *ngIf="availability?.value === false">{{ t('availabilityHelpMarkUnAvailable') }}</p>
          </div>
        </div>

        <!-- Unavailable Until  -->
        <div *ngIf="availability?.value === false" class="field">
          <span class="p-float-label">
            <input
              class="w-full"
              id="unavailableUntil"
              pInputText
              type="datetime-local"
              ariaLabel="{{ t('unavailableUntilLabel', { timezone }) }}"
              [formControl]="unavailableUntil"
              [ngClass]="{ 'ng-invalid': unavailableUntil?.invalid }"
              placeholder="{{ t('unavailableUntilLabel', { timezone }) }}"
            />
            <label for="unavailableUntil">{{ t('unavailableUntilLabel', { timezone }) }}</label>
            <p *ngIf="unavailableUntil.hasError('isAfter')" class="p-error">{{ t('unavailableUntilErrorIsAfter') }}</p>
            <p *ngIf="unavailableUntil.hasError('isBefore')" class="p-error">
              {{ t('unavailableUntilErrorIsBefore') }}
            </p>
            <div id="unavailableUntil-help">
              <p>{{ t('unavailableUntilHelp') }}</p>
            </div>
          </span>
        </div>
      </form>
    </ng-template>

    <app-settings
      [auditEntry]="auditEntry"
      [doUpload]="doUpload"
      [editorTemplate]="objEditor"
      [isValid]="getIsValid"
      [refreshData]="refreshData"
      [resetForm]="doUpdateUI"
    >
    </app-settings>
  </div>
  <p-progressSpinner *ngIf="loading"></p-progressSpinner>
</ng-container>
