import { values } from 'lodash';

import { SchemaField } from '../../../../../objects/generic/serialization/schema-field';
import { ConversationResponseSchema } from '../../generic/conversation-response/conversation-response-schema';

import { ConversationResponseConfigureError } from './conversation-response-configure-error';

export class ConversationResponseConfigureSchema extends ConversationResponseSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;

    public static readonly error = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[ConversationResponseConfigureSchema.error] = SchemaField.string(
      ConversationResponseSchema.Defaults.error,
    )
      .optional()
      .valid(...values(ConversationResponseConfigureError));
    /* eslint-enable */

    return parameters;
  }
}
