import { cloneDeep, isNil } from 'lodash';
import moment from 'moment-timezone';

import { displayTimeShort } from '../display-time-short/display-time-short';
import { isMultiDayTimeRange } from '../is-multi-day-time-range/is-multi-day-time-range';

const dayFormat = 'ddd MMM DD';

export const displayTimeRangeShort = (
  start: moment.Moment | undefined,
  end: moment.Moment | undefined,
  timezone: string,
): string => {
  if (isNil(start) || isNil(end)) {
    return '';
  }

  const startInTz = cloneDeep(start).tz(timezone);
  const endInTz = cloneDeep(end).tz(timezone);

  if (!isMultiDayTimeRange(startInTz, endInTz, timezone)) {
    return `${startInTz.format(dayFormat)}, ${displayTimeShort(startInTz, timezone)}-${displayTimeShort(
      endInTz,
      timezone,
    )}`;
  }

  // June 12, 2022, 12:15pm – June 15, 2022, 11:45am
  return `${startInTz.format(dayFormat)}, ${displayTimeShort(startInTz, timezone)} - ${endInTz.format(
    dayFormat,
  )}, ${displayTimeShort(endInTz, timezone)}`;
};
