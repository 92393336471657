<ng-container *transloco="let t; read: 'phone-editor'">
  <p-inputMask
    mask="(999) 999-9999"
    placeholder="(000) 000-0000"
    ariaLabel="{{ t('phoneLabel') }}"
    [formControl]="e164Phone"
    [inputId]="inputId || defaultId"
  >
  </p-inputMask>
  <p *ngIf="e164Phone.hasError('isValidPhone')" class="p-error">
    {{ t('isValidPhoneError', { phoneStr: this.e164Phone?.value }) }}
  </p>
</ng-container>
