import { SchemaField } from '../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';
import { AnyVoiceResponseCommand } from '../../any-voice-response-command/any-voice-response-command';

export class ServiceOperationSchema extends SerializableObjectSchema {
  public static readonly commands = 'commands';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly commands = [];
  };

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([ServiceOperationSchema.commands]);
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as { [key: string]: import('joi').AnySchema };

    /* eslint-disable */
    parameters[ServiceOperationSchema.commands] = SchemaField.shortObjArray(
      AnyVoiceResponseCommand,
      ServiceOperationSchema.Defaults.commands,
    );
    /* eslint-enable */

    return parameters;
  }
}
