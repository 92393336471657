import Joi from 'joi';

import { SchemaField } from '../../../generic/serialization/schema-field';
import { VoiceResponseCommandName } from '../../any-voice-response-command/voice-response-command-name';
import { VoiceResponseCommandSchema } from '../../generic/voice-response-command-schema';
import { SwitchCase } from '../switch-case/switch-case';
import { SwitchCaseSchema } from '../switch-case/switch-case-schema';

export class SwitchSchema extends VoiceResponseCommandSchema {
  // The field on which the switch statement is made. The value is avaliable as a URL parameter
  public static readonly switchOnField = 'switchOnField';

  // Cases of the switch statement
  public static readonly cases = 'cases';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly commandName = VoiceResponseCommandName.switch;

    public static readonly switchOnField = 'missing-switchOnField';

    public static readonly cases: SwitchCase[] = [];
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Return the schema keys. This needs to be manually specified whenever the SchemaDefinition is a Joi.AnySchema.
   */
  public getSchemaKeys(includeDocId = false): string[] {
    return Object.keys(this.generateSchemaDefinition(false));
  }

  private generateSchemaDefinition(includeShared: boolean): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[VoiceResponseCommandSchema.commandName] = SchemaField.string(SwitchSchema.Defaults.commandName);
    parameters[SwitchSchema.switchOnField] = SchemaField.string(SwitchSchema.Defaults.switchOnField);
    /* eslint-enable */

    if (includeShared) {
      parameters[SwitchSchema.cases] = SchemaField.shortObjArray(SwitchCase, SwitchSchema.Defaults.cases);
    } else {
      parameters[SwitchSchema.cases] = SchemaField.shortObjArray(
        SwitchCase,
        SwitchSchema.Defaults.cases,
        undefined,
        new SwitchCaseSchema().getJoiSchemaWithoutShared(),
      );
    }

    return parameters;
  }

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } | import('joi').AnySchema {
    return this.generateSchemaDefinition(true);
  }

  public getJoiSchemaWithoutShared(): import('joi').AnySchema {
    return Joi.object(this.generateSchemaDefinition(false));
  }
}
