import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { UserData } from '@pwp-common';

import { DbDocumentService } from '../../generic/db-document-service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserDataService extends DbDocumentService<UserData> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(db: AngularFirestore, authService: AuthService) {
    super(db, authService, UserData);
  }

  ///////////////////////////////////////////////////////////////////////
  // Get One User Data
  ///////////////////////////////////////////////////////////////////////

  public getUserData(takeOne = true): Observable<UserData | undefined> {
    const observable = this.getInjectedAuthService()
      .getUserId()
      .pipe(switchMap((userId: string, _: number) => this.getDoc(userId, undefined, takeOne)));

    return observable;
  }

  ///////////////////////////////////////////////////////////////////////
  // New User
  ///////////////////////////////////////////////////////////////////////

  /**
   * Firebase automatically creates an account for each user if one didn't exist already.
   * Such accounts are not able to do anything.
   *
   * This function is so that we can detect that this happened, so the UI can this case differently
   * from login of a typical user.
   */
  public isAuthorizedAccount(): Observable<boolean> {
    return this.getUserData().pipe(
      take(1),
      map((userData, _): boolean => userData !== undefined),
    );
  }
}
