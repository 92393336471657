import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { CallbackRow } from '../../../../row/callback-row/callback-row';
import { InboxCreateCallbackNoteComponent } from '../inbox-create-callback-note/inbox-create-callback-note.component';
import { InboxCreateCallbackNoteData } from '../inbox-create-callback-note/interfaces';

@UntilDestroy()
@Component({
  selector: 'app-inbox-callback-detail',
  templateUrl: './inbox-callback-detail.component.html',
  styleUrls: ['./inbox-callback-detail.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService, MessageService],
})
export class InboxCallbackDetailComponent {
  private readonly translocoScope = 'inbox-callback-detail';
  ///////////////////////////////////////////////////////////////////////
  // Input
  ///////////////////////////////////////////////////////////////////////

  @Input() public row: CallbackRow;

  constructor(
    private dialogService: DialogService,
    private messageService: MessageService,
    private translocoService: TranslocoService,
  ) {}

  public openCreateNoteDialog(): void {
    const data: InboxCreateCallbackNoteData = {
      asyncServiceRequest: this.row.callbackRowParams.asyncServiceRequest,
      callLog: this.row.callbackRowParams.callLog,
    };

    const dialog = this.dialogService.open(InboxCreateCallbackNoteComponent, {
      header: this.translocoService.translate(`${this.translocoScope}.addNoteDialogHeader`),
      width: 'clamp(32rem, 50%, 64rem)',
      styleClass: 'm-4 p-dialog-no-containing-block',
      data,
    });

    dialog.onClose.pipe(untilDestroyed(this)).subscribe((result) => {
      if (result?.success) {
        this.messageService.add({
          severity: 'success',
          summary: this.translocoService.translate(`${this.translocoScope}.addNoteSuccess`),
        });
      }
    });
  }
}
