import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isNil } from 'lodash';

import { FormGroupControlValueAccessor } from '../../../../../generic/abstract-classes/form-group-control-value-accessor';
import { formToReservationSkipOffersIfEditorOutput } from '../../editor-output/reservation-offer-config/objects/reservation-skip-offers-if/form/form-to-reservation-skip-offers-if-editor-output/form-to-reservation-skip-offers-if-editor-output';
import {
  ReservationOfferSkipIfForm,
  ReservationOfferSkipIfNumCallsInProgressOptions,
} from '../../editor-output/reservation-offer-config/objects/reservation-skip-offers-if/form/reservation-offer-skip-if-form';
import { reservationSkipOffersIfEditorOutputToForm } from '../../editor-output/reservation-offer-config/objects/reservation-skip-offers-if/form/reservation-skip-offers-if-editor-output-to-form/reservation-skip-offers-if-editor-output-to-form';
import { ReservationSkipOffersIfEditorOutput } from '../../editor-output/reservation-offer-config/objects/reservation-skip-offers-if/reservation-skip-offers-if-editor-output';

const VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TargetOffersSkipIfEditorComponent),
  multi: true,
};

const VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => TargetOffersSkipIfEditorComponent),
  multi: true,
};
@UntilDestroy()
@Component({
  selector: 'app-target-offers-skip-if-editor',
  templateUrl: './target-offers-skip-if-editor.component.html',
  styleUrls: ['./target-offers-skip-if-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VALUE_ACCESSOR, VALIDATOR],
})
export class TargetOffersSkipIfEditorComponent extends FormGroupControlValueAccessor<
  ReservationOfferSkipIfForm,
  ReservationSkipOffersIfEditorOutput
> {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  skipIfNumCallsInProgressGEOptions: {
    value: ReservationOfferSkipIfNumCallsInProgressOptions | number;
    label: string;
  }[] = [
    { value: ReservationOfferSkipIfNumCallsInProgressOptions.neverSkip, label: 'skipNumInProgressCallsGENeverOption' },
    { value: 1, label: 'skipNumInProgressCallsGEOption' },
    { value: 2, label: 'skipNumInProgressCallsGEOption' },
    { value: 3, label: 'skipNumInProgressCallsGEOption' },
    { value: 4, label: 'skipNumInProgressCallsGEOption' },
    { value: 5, label: 'skipNumInProgressCallsGEOption' },
    { value: 6, label: 'skipNumInProgressCallsGEOption' },
    { value: 7, label: 'skipNumInProgressCallsGEOption' },
    { value: 8, label: 'skipNumInProgressCallsGEOption' },
    { value: 9, label: 'skipNumInProgressCallsGEOption' },
    { value: 10, label: 'skipNumInProgressCallsGEOption' },
  ];

  skipIfUnavailableOptions: { value: boolean; label: string }[] = [
    { value: true, label: 'skipIfUnavailableOptionTrue' },
    { value: false, label: 'skipIfUnavailableOptionFalse' },
  ];

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////
  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////
  defineForm() {
    this.form = new UntypedFormGroup({
      skipIfUnavailable: new UntypedFormControl(undefined, [Validators.required]),
      skipIfNumCallsInProgressGE: new UntypedFormControl(undefined, [Validators.required]),
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Write Value
  /////////////////////////////////////////////////////////////////////////////////////////////

  writeValue(value: ReservationSkipOffersIfEditorOutput) {
    if (isNil(value)) {
      return;
    }

    const formType = reservationSkipOffersIfEditorOutputToForm(value);
    super.writeValue(formType);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  parseValueChange(value: ReservationOfferSkipIfForm): ReservationSkipOffersIfEditorOutput {
    const result = formToReservationSkipOffersIfEditorOutput(value);
    return result;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected makeValidationErrors() {
    return {
      'target-offers-skip-if-editor': this.form.value,
    };
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form Controls
  ///////////////////////////////////////////////////////////////////////////////////////////
  get skipIfUnavailable() {
    return this.form.get('skipIfUnavailable') as UntypedFormControl;
  }

  get skipIfNumCallsInProgressGE() {
    return this.form.get('skipIfNumCallsInProgressGE') as UntypedFormControl;
  }
}
