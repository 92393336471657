import { SchemaField } from '../../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../../generic/serialization/serializable-object-schema';
import { ServiceExceptionExecution } from '../service-exception-execution/service-exception-execution';

export class ServiceExceptionExecutionsSchema extends SerializableObjectSchema {
  public static readonly executions = 'executions';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly executions = new Map();
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as { [key: string]: import('joi').AnySchema };

    /* eslint-disable */
    parameters[ServiceExceptionExecutionsSchema.executions] = SchemaField.mapOfObj(
      ServiceExceptionExecution,
      ServiceExceptionExecutionsSchema.Defaults.executions,
    );
    /* eslint-enable */

    return parameters;
  }
}
