<ng-template #objEditor>
  <form *ngIf="!loading" [formGroup]="form">
    <div class="form-row">
      <div class="col">
        <!-- Display Name -->
        <mat-form-field class="full-width">
          <textarea class="full-width" formControlName="displayName" matInput placeholder="Display Name"></textarea>
          <span *ngIf="displayName?.invalid && displayName?.touched"> Display Name Invalid </span>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Description -->
        <mat-form-field class="full-width">
          <textarea class="full-width" formControlName="description" matInput placeholder="Description"></textarea>
          <span *ngIf="description?.invalid && description?.touched"> Description Invalid </span>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Color -->
        <mat-form-field class="full-width">
          <input class="full-width" formControlName="color" matInput placeholder="Color" />
          <span *ngIf="color?.invalid && color?.touched"> Color Invalid </span>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        <!-- Event Type -->
        <app-obj-select
          [label]="'Event Type'"
          [orderedItems]="eventTypes"
          [(kvPair)]="selectedEventTypeKVPair"
        ></app-obj-select>
      </div>
      <div class="col">
        <!-- Assigned User -->
        <app-obj-autocomplete
          [displayFormat]="userDataDisplayFormat"
          [label]="'Assigned User'"
          [orderedItems]="userDataArray | async"
          [(kvPair)]="selectedAssignedUser"
        ></app-obj-autocomplete>
      </div>
      <div class="col">
        <!-- Assigned Backup User -->
        <app-obj-autocomplete
          [displayFormat]="userDataDisplayFormat"
          [label]="'Assigned Backup User'"
          [orderedItems]="userDataArray | async"
          [(kvPair)]="selectedAssignedBackupUser"
        ></app-obj-autocomplete>
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        <!-- Generate -->
        <mat-checkbox formControlName="generate">Generate</mat-checkbox>
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        Docs:
        <ul>
          <li><a href="http://jakubroztocil.github.io/rrule/">RRULE Generator</a></li>
          <li>Daily: RRULE:FREQ=DAILY;INTERVAL=1;WKST=MO;BYDAY=MO,TU,WE,TH,FR,SA,SU</li>
          <li>Every 3 Hours: RRULE:FREQ=HOURLY;INTERVAL=3;WKST=MO</li>
        </ul>
        <!-- RRule -->
        <mat-form-field class="full-width">
          <textarea class="full-width" formControlName="rrule" matInput placeholder="RRule"></textarea>
          <span *ngIf="rrule?.invalid && rrule?.touched"> RRule Invalid </span>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        <mat-form-field class="full-width">
          <mat-label>Start (Org Timezone)</mat-label>
          <input formControlName="start" matInput placeholder="Start" type="datetime-local" />
          <mat-error *ngIf="start?.invalid"> Invalid Start Time </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="full-width">
          <mat-label>End (Org Timezone)</mat-label>
          <input formControlName="end" matInput placeholder="End" type="datetime-local" />
          <mat-error *ngIf="end?.invalid"> Invalid End Time </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row">
      <div class="col">
        <label class="flex flex-column gap-1 p-fluid">
          Extend Schedule Num Days
          <p-inputNumber
            formControlName="extendScheduleNumDays"
            id="extendScheduleNumDays"
            mode="decimal"
            [showButtons]="true"
          >
          </p-inputNumber>
        </label>
      </div>
    </div>
  </form>

  <button color="primary" mat-button (click)="getEventsInSevenDays()">Recalculate Events</button>
  Events:
  <ul>
    <li *ngFor="let event of sevenDayEvents">
      {{ event.getStart().format() }} ----- to ----- {{ event.getEnd().format() }}
    </li>
  </ul>
</ng-template>

<app-obj-select-and-edit
  [doDelete]="doDelete"
  [doRefresh]="doRefresh"
  [doUpload]="doUpload"
  [editorTemplate]="objEditor"
  [label]="'Event Config'"
  [modifiedSelectedItem]="getUpdatedObj()"
  [objIsValid]="isValidObjScoper"
  [orderedItems]="itemsArray | async"
  [selectedKVPair]="selectedKVPair"
  (duplicateClick)="duplicateClick()"
  (newClick)="newClick()"
  (selectedKVPairChange)="onSelectedKVPairChange($event)"
>
</app-obj-select-and-edit>
