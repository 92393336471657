import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import {
  AnyConversationResponse,
  ConversationRequest,
  ConversationRequestCloseAndDelete,
  ConversationRequestConfigure,
  ConversationRequestGetJWT,
  ConversationRequestName,
  ConversationResponse,
  ConversationResponseCloseAndDelete,
  ConversationResponseConfigure,
  ConversationResponseGetJwt,
} from '@pwp-common';

import { ConversationRequestTake } from '../../../../../../../common/src/callable-functions/conversation/request/requests/conversation-request-take/conversation-request-take';
import { CallableFunctionService } from '../../generic/callable-function-service/callable-function-service';

@Injectable({
  providedIn: 'root',
})
export class ConversationEndpointService extends CallableFunctionService<
  ConversationRequest,
  ConversationResponse<any>
> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  // @ts-ignore
  constructor(private _fns: AngularFireFunctions) {
    super(AnyConversationResponse, _fns);
  }
  ///////////////////////////////////////////////////////////////////////
  // Function Name
  ///////////////////////////////////////////////////////////////////////

  public getFunctionName() {
    return 'conversation';
  }

  ///////////////////////////////////////////////////////////////////////
  // Jwt
  ///////////////////////////////////////////////////////////////////////

  public async getJWT(conversationConfigId: string): Promise<ConversationResponseGetJwt> {
    const request = new ConversationRequestGetJWT({ type: ConversationRequestName.getJWT, conversationConfigId });
    const response = await this.makeRequest(request);
    return response as ConversationResponseGetJwt;
  }

  ///////////////////////////////////////////////////////////////////////
  // Configure
  ///////////////////////////////////////////////////////////////////////

  public async configure(
    conversationRequestConfigure: ConversationRequestConfigure,
  ): Promise<ConversationResponseConfigure> {
    const response = await this.makeRequest(conversationRequestConfigure);
    return response as ConversationResponseConfigure;
  }

  ///////////////////////////////////////////////////////////////////////
  // Configure
  ///////////////////////////////////////////////////////////////////////

  public async closeAndDelete(obj: ConversationRequestCloseAndDelete): Promise<ConversationResponseCloseAndDelete> {
    const response = await this.makeRequest(obj);
    return response as ConversationResponseCloseAndDelete;
  }

  ///////////////////////////////////////////////////////////////////////
  // Take
  ///////////////////////////////////////////////////////////////////////

  public async take(obj: ConversationRequestTake): Promise<ConversationResponseCloseAndDelete> {
    return this.makeRequest(obj) as Promise<ConversationResponseCloseAndDelete>;
  }
}
