import moment from 'moment-timezone';

import { GLOBAL_TIMEZONE } from '../../../helper/constants';
import { DBDocSchema } from '../../generic/db-doc/db-doc-schema';
import { SchemaField } from '../../generic/serialization/schema-field';

export class EventDataSchema extends DBDocSchema {
  public static readonly start = 'start';

  public static readonly end = 'end';

  public static readonly type = 'type';

  public static readonly color = 'color';

  public static readonly assignedUserId = 'assignedUserId';

  public static readonly assignedBackupUserId = 'assignedBackupUserId';

  public static readonly eventConfigId = 'eventConfigId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'events';
  };

  static Defaults = class {
    public static readonly start = moment.tz(GLOBAL_TIMEZONE);

    public static readonly end = moment.tz(GLOBAL_TIMEZONE).add(1, 'minute');

    public static readonly type = 'missing-TYPE';

    public static readonly color = '';

    public static readonly assignedUserId = '';

    public static readonly assignedBackupUserId = '';

    public static readonly eventConfigId = 'missing-eventConfigId';
  };

  static Colors = class {
    public static readonly primary = '#c2c4c9';

    public static readonly reserved = '#BDBDBD';

    public static readonly open = '#FAFAFA';

    public static readonly taken = '#D1C4E9';

    public static readonly default = '';
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Default Values
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([EventDataSchema.color, EventDataSchema.assignedUserId, EventDataSchema.assignedBackupUserId]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    parameters[EventDataSchema.start] = SchemaField.timestamp(EventDataSchema.Defaults.start);
    parameters[EventDataSchema.end] = SchemaField.timestamp(EventDataSchema.Defaults.end);
    parameters[EventDataSchema.type] = SchemaField.string(EventDataSchema.Defaults.type);
    parameters[EventDataSchema.color] = SchemaField.string(EventDataSchema.Defaults.color);
    parameters[EventDataSchema.assignedUserId] = SchemaField.userId(EventDataSchema.Defaults.assignedUserId);
    parameters[EventDataSchema.assignedBackupUserId] = SchemaField.userId(
      EventDataSchema.Defaults.assignedBackupUserId,
    );
    parameters[EventDataSchema.eventConfigId] = SchemaField.string(EventDataSchema.Defaults.eventConfigId);

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${EventDataSchema.Constants.collection}`;
  }
}
