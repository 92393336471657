import { Roles } from '../roles/roles';
import { UserData } from '../user-data/user-data';
import { UserPrivateData } from '../user-private-data/user-private-data';

export class AllDataUser {
  public userData!: UserData;

  public userPrivateData?: UserPrivateData;

  public roles!: Roles;

  constructor(userData: UserData, roles: Roles, userPrivateData?: UserPrivateData) {
    if (userData === undefined && roles === undefined && userPrivateData === undefined) {
      console.error('AllDataUser: Cannot initialize class with all empty values');
      throw new Error('AllDataUser: Cannot initialize class with all empty values');
    }

    if (userData === undefined) {
      console.error(`AllDataUser: UserData is undefined. userId=${roles?.getId()}=${userPrivateData?.getId()}`);
      throw new Error('AllDataUser: UserData is undefined.');
    }

    if (roles === undefined) {
      console.error(`AllDataUser: UserData is undefined. userId=${userData.getId()}`);
      throw new Error('AllDataUser: roles is undefined.');
    }

    this.userData = userData;
    this.roles = roles;
    this.userPrivateData = userPrivateData;
  }

  public getUserId() {
    return this.userData.getId();
  }
}
