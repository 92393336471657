import { SerializableObject } from '../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../generic/serialization/serializable-object-schema';
import { AddBlockedCaller } from '../add-blocked-caller/add-blocked-caller';
import { AutoAcceptCall } from '../auto-accept-call/auto-accept-call';
import { CreateAsyncServiceRequest } from '../create-async-service-request/create-async-service-request';
import { DialCallList } from '../dial-call-list/dial-call-list';
import { EnforceBlockedCaller } from '../enforce-blocked-caller/enforce-blocked-caller';
import { EnforceServiceExceptions } from '../enforce-service-exceptions/enforce-service-exceptions';
import { EnforceServiceLimit } from '../enforce-service-limit/enforce-service-limit';
import { ExecEntrypoint } from '../exec-entrypoint/exec-entrypoint';
import { ExecIVR } from '../exec-ivr/exec-ivr';
import { FlagServiceRequest } from '../flag-service-request/flag-service-request';
import { Forward } from '../forward/forward';
import { VoiceResponseCommand } from '../generic/voice-response-command';
import { Hangup } from '../hangup/hangup';
import { JoinConference } from '../join-conference/join-conference';
import { Play } from '../play/play';
import { PlayOrSay } from '../play-or-say/play-or-say';
import { Say } from '../say/say';
import { SendMessageTemplate } from '../send-message-template/send-message-template';
import { SetContext } from '../set-context/set-context';
import { Switch } from '../switch/switch/switch';
import { TakeVoicemail } from '../take-voicemail/take-voicemail';
import { VRCIfTime } from '../voice-response-command-if-time/voice-response-command-if-time';

import { AnyVoiceResponseCommandSchema } from './any-voice-response-command-schema';
import { VoiceResponseCommandName } from './voice-response-command-name';

/**
 * Subclasses of this class parse a command from firebase and produce TwiML which
 * direct Twilio's servers to play audio / etc. (via raw TwiML), or direct our
 * servers to do something (like dial all users in a list). In the former case the
 * Twilio receives all parameters inside the TwiML. In the latter case, our HTTPS
 * endpoints receive their required parameters inside the URL parameters.
 */
export abstract class AnyVoiceResponseCommand extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): VoiceResponseCommand {
    const commandNameString = validationResult.value[AnyVoiceResponseCommandSchema.commandName];
    const commandName = VoiceResponseCommandName[commandNameString as keyof typeof VoiceResponseCommandName];
    if (commandName === undefined) {
      throw new Error(
        `AnyVoiceResponseCommandUser._deserialize: User Error: Cannot deserialize a command with commandName=${commandNameString}`,
      );
    }

    switch (commandName) {
      case VoiceResponseCommandName.addBlockedCaller: {
        return new AddBlockedCaller(validationResult.value);
      }
      case VoiceResponseCommandName.autoAcceptCall: {
        return new AutoAcceptCall(validationResult.value);
      }
      case VoiceResponseCommandName.createAsyncServiceRequest: {
        return new CreateAsyncServiceRequest(validationResult.value);
      }
      case VoiceResponseCommandName.dialCallList: {
        return new DialCallList(validationResult.value);
      }
      case VoiceResponseCommandName.enforceBlockedCaller: {
        return new EnforceBlockedCaller(validationResult.value);
      }
      case VoiceResponseCommandName.enforceServiceExceptions: {
        return new EnforceServiceExceptions(validationResult.value);
      }
      case VoiceResponseCommandName.enforceServiceLimit: {
        return new EnforceServiceLimit(validationResult.value);
      }
      case VoiceResponseCommandName.execEntrypoint: {
        return new ExecEntrypoint(validationResult.value);
      }
      case VoiceResponseCommandName.execIVR: {
        return new ExecIVR(validationResult.value);
      }
      case VoiceResponseCommandName.flagServiceRequest: {
        return new FlagServiceRequest(validationResult.value);
      }
      case VoiceResponseCommandName.forward: {
        return new Forward(validationResult.value);
      }
      case VoiceResponseCommandName.hangup: {
        return new Hangup(validationResult.value);
      }
      case VoiceResponseCommandName.ifTime: {
        return new VRCIfTime(validationResult.value);
      }
      case VoiceResponseCommandName.joinConference: {
        return new JoinConference(validationResult.value);
      }
      case VoiceResponseCommandName.play: {
        return new Play(validationResult.value);
      }
      case VoiceResponseCommandName.playOrSay: {
        return new PlayOrSay(validationResult.value);
      }
      case VoiceResponseCommandName.say: {
        return new Say(validationResult.value);
      }
      case VoiceResponseCommandName.sendMessageTemplate: {
        return new SendMessageTemplate(validationResult.value);
      }
      case VoiceResponseCommandName.setContext: {
        return new SetContext(validationResult.value);
      }
      case VoiceResponseCommandName.switch: {
        return new Switch(validationResult.value);
      }
      case VoiceResponseCommandName.takeVoicemail: {
        return new TakeVoicemail(validationResult.value);
      }
      default: {
        throw new Error(`getVoiceResponseCommandFromFirebase: Unknown commandName=${commandName}`);
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new AnyVoiceResponseCommandSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Abstract Methods
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Specify name of the command
   */
  public abstract getCommandName(): VoiceResponseCommandName;
}
