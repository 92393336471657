<form *ngIf="!loading" [formGroup]="form">
  <!-- Basic Form Fields -->
  <div class="form-row">
    <div class="col">
      <!-- Enabled -->
      <mat-checkbox formControlName="enabled">Enabled</mat-checkbox>
    </div>
  </div>
</form>

<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Waiting Room Message </mat-panel-title>
      <mat-panel-description> </mat-panel-description>
    </mat-expansion-panel-header>

    <app-template-text-editor
      [(objList)]="waitingRoomMessageArr"
      (objListChange)="updateObjAndEmit()"
    ></app-template-text-editor>
  </mat-expansion-panel>
</mat-accordion>
