import { SchemaField } from '../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';
import { TimeSelectionItem } from '../time-selection-item/time-selection-item';

export class TimeSelectionSchema extends SerializableObjectSchema {
  public static readonly items = 'items';

  public static readonly includeByDefault = 'includeByDefault';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {};

  static Defaults = class {
    public static readonly items = [];

    public static readonly includeByDefault = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Default Values
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([TimeSelectionSchema.items]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[TimeSelectionSchema.items] = SchemaField.shortObjArray(
      TimeSelectionItem,
      TimeSelectionSchema.Defaults.items,
    );
    parameters[TimeSelectionSchema.includeByDefault] = SchemaField.boolean(
      TimeSelectionSchema.Defaults.includeByDefault,
    ).required();
    /* eslint-enable */

    return parameters;
  }
}
