// Email Template Config
export { EmailTemplateConfig } from './email-template-config';
export { EmailTemplateConfigConstructor } from './email-template-config-constructor';
export { EmailTemplateConfigSchema } from './email-template-config-schema';
export { EmailTemplateType } from './email-template-type';
export { SgTemplateId } from './sg-template-id';

////////////////////////////////////////////////////////////////////////////////////////////////
// Warning On Import
////////////////////////////////////////////////////////////////////////////////////////////////

console.warn('--------- pwp-common:objects/email-template-config/index.ts: Imported ---------');
