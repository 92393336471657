import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LetModule } from '@ngrx/component';
import { cloneDeep, isNil } from 'lodash';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ToastModule } from 'primeng/toast';

import { provideValidator } from '../../../../../common/form/provide-validator';
import { provideValueAccessor } from '../../../../../common/form/provide-value-accessor';
import { emailArrayValidator } from '../../../../../common/form/validators/email-array';
import { FormFieldHelpComponent } from '../../../../form/form-field-help/form-field-help.component';
import { FormGroupControlValueAccessor } from '../../../../generic/abstract-classes/form-group-control-value-accessor';
import { defaultEventRequestConfigEditorOutput } from '../editor-output/default-event-request-config-output';
import { EventRequestConfigEditorOutput } from '../interfaces';

@UntilDestroy()
@Component({
  selector: 'app-event-request-config-editor',
  standalone: true,
  imports: [
    CheckboxModule,
    ChipsModule,
    CommonModule,
    FormFieldHelpComponent,
    FormsModule,
    LetModule,
    ReactiveFormsModule,
    ToastModule,
    TranslocoModule,
  ],
  templateUrl: './event-request-config-editor.component.html',
  styleUrls: ['./event-request-config-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    provideValueAccessor(EventRequestConfigEditorComponent),
    provideValidator(EventRequestConfigEditorComponent),
  ],
})
export class EventRequestConfigEditorComponent
  extends FormGroupControlValueAccessor<EventRequestConfigEditorOutput, EventRequestConfigEditorOutput>
  implements OnInit
{
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  public baseObj?: EventRequestConfigEditorOutput;

  public readonly form = new FormGroup({
    requestableSlotsPrimary: new FormControl(true),
    requestableSlotsBackup: new FormControl(false),
    autoApproveSlotsPrimary: new FormControl({ value: false, disabled: true }),
    autoApproveSlotsBackup: new FormControl({ value: false, disabled: true }),
    emailOnNewRequestCreated: new FormControl<string[] | undefined>([], { validators: [emailArrayValidator] }),
  });

  private getRequestSlotsPrimaryEnabled(): FormControl<boolean> {
    return this.form.controls.requestableSlotsPrimary;
  }

  private getRequestSlotsBackupEnabled(): FormControl<boolean> {
    return this.form.controls.requestableSlotsBackup;
  }

  private getAutoApproveSlotsPrimaryEnabled(): FormControl<boolean> {
    return this.form.controls.autoApproveSlotsPrimary;
  }

  private getAutoApproveSlotsBackupEnabled(): FormControl<boolean> {
    return this.form.controls.autoApproveSlotsBackup;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Form Logic
  /////////////////////////////////////////////////////////////////////////////////////////////

  private autoApplyFormLogicTo(requestableControl: UntypedFormControl, autoApprovalControl: UntypedFormControl) {
    if (!requestableControl || !autoApprovalControl) {
      return;
    }

    requestableControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value) {
        autoApprovalControl.enable();
      } else {
        autoApprovalControl.disable();
        autoApprovalControl.reset();
      }
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected parseValueChange(): EventRequestConfigEditorOutput {
    return {
      ...cloneDeep(this.baseObj ?? defaultEventRequestConfigEditorOutput),
      allowRequestPrimary: this.getRequestSlotsPrimaryEnabled().value,
      allowRequestBackup: this.getRequestSlotsBackupEnabled().value,
      autoApprovePrimary: this.getAutoApproveSlotsPrimaryEnabled().value,
      autoApproveBackup: this.getAutoApproveSlotsBackupEnabled().value,
      emailOnNewRequestCreated: this.form.controls.emailOnNewRequestCreated.value,
    };
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  public ngOnInit(): void {
    this.autoApplyFormLogicTo(this.getRequestSlotsPrimaryEnabled(), this.getAutoApproveSlotsPrimaryEnabled());
    this.autoApplyFormLogicTo(this.getRequestSlotsBackupEnabled(), this.getAutoApproveSlotsBackupEnabled());
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Write Value
  /////////////////////////////////////////////////////////////////////////////////////////////

  public async writeValue(value: EventRequestConfigEditorOutput): Promise<void> {
    /**
     * Preserve any properties of the input, if it is specified (such as the id)
     */
    if (isNil(value)) {
      return;
    }

    this.baseObj = cloneDeep(value ?? defaultEventRequestConfigEditorOutput);
    const formValue = {
      requestableSlotsPrimary: this.baseObj?.allowRequestPrimary,
      requestableSlotsBackup: this.baseObj?.allowRequestBackup,
      autoApproveSlotsPrimary: this.baseObj?.autoApprovePrimary,
      autoApproveSlotsBackup: this.baseObj?.autoApproveBackup,
      emailOnNewRequestCreated: this.baseObj?.emailOnNewRequestCreated,
    };
    this.form.patchValue(formValue, { emitEvent: true });
    this.changeDetectorRef.detectChanges();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  public makeValidationErrors(): ValidationErrors {
    return {
      'event-request-config-editor': this.form.value,
    };
  }
}
