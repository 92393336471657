import { Component, OnInit } from '@angular/core';

import { OrgData } from '@pwp-common';

import { OrgDataService } from '../../services/orgs/org-data/org-data.service';

@Component({
  selector: 'app-admin-analytics',
  templateUrl: './admin-analytics.component.html',
  styleUrls: ['./admin-analytics.component.css'],
})
export class AdminAnalyticsComponent implements OnInit {
  orgData: OrgData;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////
  constructor(private orgDataService: OrgDataService) {}

  ngOnInit(): void {
    this.orgDataService
      .getOrgData()
      .toPromise()
      .then((orgData) => (this.orgData = orgData));
  }
}
