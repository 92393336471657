<ng-container *transloco="let t; read: 'profile'">
  <!-- Toast notifications configuration -->
  <p-toast position="bottom-center"></p-toast>

  <div class="container-fluid">
    <div class="row flex flex-wrap">
      <div class="col-lg-4">
        <app-my-user-profile-editor></app-my-user-profile-editor>
      </div>
      <div class="col-lg-8">
        <p-card>
          <app-hours-and-calls></app-hours-and-calls>
        </p-card>
      </div>
    </div>
    <div class="row flex flex-wrap py-2">
      <div class="col-lg-12">
        <app-referral></app-referral>
      </div>
    </div>
    <div class="row flex flex-wrap py-1">
      <div class="col-lg-12">
        <app-confirmed-events-table></app-confirmed-events-table>
      </div>
    </div>
    <div class="row flex flex-wrap py-1">
      <div class="col-lg-12">
        <p-card>
          <ng-template pTemplate="header">
            <legend class="flex flex-wrap p-3">{{ t('reminders') }}</legend>
          </ng-template>
          <app-message-template-subscribe></app-message-template-subscribe>
        </p-card>
      </div>
    </div>
  </div>
</ng-container>
