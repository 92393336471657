import { SchemaField } from '../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';
import { CommunicationWorkflowTarget } from '../communication-workflow-target/communication-workflow-target/communication-workflow-target';

export class CommunicationWorkflowFilterSchema extends SerializableObjectSchema {
  public static readonly targets = 'targets';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly targets = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[CommunicationWorkflowFilterSchema.targets] = SchemaField.shortObjArray(
      CommunicationWorkflowTarget,
      CommunicationWorkflowFilterSchema.Defaults.targets,
    ).required();
    /* eslint-enable */

    return parameters;
  }
}
