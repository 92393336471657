import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { isNil } from 'lodash';
import { Observable, switchMap } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { ConversationLog, ConversationLogSchema, ConversationState } from '@pwp-common';

import { ConversationRequestTake } from '../../../../../../../common/src/callable-functions/conversation/request/requests/conversation-request-take/conversation-request-take';
import { DbDocumentService } from '../../generic/db-document-service';
import { DBOrderBy, DBQuery } from '../../generic/interfaces';
import { AuthService } from '../../user/auth/auth.service';
import { ConversationEndpointService } from '../conversation-endpoint/conversation-endpoint.service';

@Injectable({
  providedIn: 'root',
})
export class ConversationLogService extends DbDocumentService<ConversationLog> {
  ///////////////////////////////////////////////////////////////////////
  // State
  ///////////////////////////////////////////////////////////////////////

  private conversationState: Map<string, ConversationState> = new Map();
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(
    db: AngularFirestore,
    authService: AuthService,
    private conversationEndpointService: ConversationEndpointService,
  ) {
    super(db, authService, ConversationLog);
  }

  /////////////////////////////////////////////////////////////////////
  // Change State
  /////////////////////////////////////////////////////////////////////

  public async changeState(conversationLogId: string, newState: ConversationState): Promise<void> {
    const logMetadata: Record<string, unknown> = { conversationLogId, newState };
    console.log(`ConversationLogService.changeState:${this.getLoggingInfo()}: Starting`, logMetadata);

    const localState = this.conversationState.get(conversationLogId);
    logMetadata.localState = localState;
    if (localState === newState) {
      console.log(
        `ConversationLogService.changeState:${this.getLoggingInfo()}: Nothing to do because local state is already correct`,
        logMetadata,
      );
      return;
    }

    await this.db.firestore
      .runTransaction(async (transaction: firebase.firestore.Transaction) =>
        this.changeConversationState(transaction, conversationLogId, newState),
      )
      .catch((err) => {
        console.error(`ConversationLogService.changeState:${this.getLoggingInfo()}: Error`, logMetadata);
        console.error('err');
        console.error(err);
        throw err;
      })
      .finally(() => {
        console.log(`ConversationLogService.changeState:${this.getLoggingInfo()}: Complete`, logMetadata);
      });

    await this.conversationEndpointService.take(new ConversationRequestTake({ conversationLogId }));

    this.conversationState.set(conversationLogId, newState);
  }

  private async changeConversationState(
    transaction: firebase.firestore.Transaction,
    conversationLogId: string,
    newState: ConversationState,
  ) {
    const logMetadata: Record<string, unknown> = {};

    let update = {} as any;
    update[ConversationLogSchema.state] = newState;
    if (newState === ConversationState.serviceDeliveryInProgress) {
      update[ConversationLogSchema.firstUserMessageTime] = firebase.firestore.FieldValue.serverTimestamp();
    }
    update = await this.addGenericFields(update, 'update').toPromise();

    console.log(`ConversationLogService._changeConversationState:${this.getLoggingInfo()}: Starting`, logMetadata);

    const docRef = await this.docRef(conversationLogId).toPromise();
    const obj = await docRef.ref.get();
    const currLog = this.getObjectFromFirebase(obj);

    const remoteState = currLog?.getState();
    logMetadata.remoteState = remoteState;

    if (remoteState === newState) {
      console.log(`ConversationLogService._changeConversationState:${this.getLoggingInfo()}: Completed`, logMetadata);
    } else {
      console.log(
        `ConversationLogService._changeConversationState:${this.getLoggingInfo()}:
        Remote state will now be updated.`,
        logMetadata,
      );
      transaction.update(docRef.ref, update);
      console.log(`ConversationLogService._changeConversationState:${this.getLoggingInfo()}: Completed`, logMetadata);
    }

    console.log(`ConversationLogService._changeConversationState:${this.getLoggingInfo()}: Completed`, logMetadata);
  }

  /////////////////////////////////////////////////////////////////////
  // Conversations With State & Range
  /////////////////////////////////////////////////////////////////////

  public getConversationsWith(
    params: { state?: ConversationState; createTimeStart?: Date; createTimeEnd?: Date },
    takeOne = true,
  ): Observable<ConversationLog[]> {
    // Log inputs
    console.log(`ConversationLogService.getConversationsWith: Starting`, { ...params, takeOne });

    // Make the query
    const query: DBQuery[] = [];

    if (!isNil(params.createTimeStart)) {
      query.push({ fieldPath: ConversationLogSchema.createTime, opStr: '>=', value: params.createTimeStart });
    }

    if (!isNil(params.createTimeEnd)) {
      query.push({ fieldPath: ConversationLogSchema.createTime, opStr: '<=', value: params.createTimeEnd });
    }

    if (!isNil(params.state)) {
      query.push({ fieldPath: ConversationLogSchema.state, opStr: '==', value: params.state });
    }

    const dbOrder: DBOrderBy = { fieldPath: ConversationLogSchema.createTime, directionStr: 'desc' };

    // Return
    return this.getDocsArray(query, dbOrder, undefined, takeOne).pipe(startWith([]));
  }

  /////////////////////////////////////////////////////////////////////
  // In Progress
  /////////////////////////////////////////////////////////////////////

  public getInProgressConversations(
    params: { onlyForLoggedInUser: boolean },
    takeOne = true,
  ): Observable<ConversationLog[]> {
    // Log inputs
    console.log(`ConversationLogService.getInProgressConversations: Starting`, { ...params, takeOne });

    if (params.onlyForLoggedInUser === true) {
      return this.authService
        .getUserId(true)
        .pipe(
          switchMap((loggedInUserId) => {
            console.log(`ConversationLogService.getInProgressConversations: Starting`, {
              ...params,
              loggedInUserId,
              takeOne,
            });
            return this.getDocsArray(
              [
                { fieldPath: ConversationLogSchema.lastModifiedByUserId, opStr: '==', value: loggedInUserId },
                {
                  fieldPath: ConversationLogSchema.state,
                  opStr: '==',
                  value: ConversationState.serviceDeliveryInProgress,
                },
              ],
              undefined,
              undefined,
              takeOne,
            );
          }),
        )
        .pipe(startWith([]));
    }

    return this.getConversationsWith({ state: ConversationState.serviceDeliveryInProgress }, takeOne);
  }
}
