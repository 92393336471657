<ng-container *transloco="let t; read: 'conversation-logs-query-editor'">
  <p-toast position="bottom-center"></p-toast>

  <form [formGroup]="form">
    <div class="form-row">
      <div class="col">
        <label class="conversation-logs-query-editor__form-field">
          {{ t('showWaitingLabel') }}
          <p-inputSwitch formControlName="showWaiting" ariaLabel="{{ t('showWaitingLabel') }}"></p-inputSwitch>
        </label>
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        <label>
          {{ t('inProgressLabel') }}
          <p-selectButton
            formControlName="inProgress"
            ariaLabel="{{ t('inProgressLabel') }}"
            [options]="[
              { label: t('myInProgressLabel'), value: 'my' },
              { label: t('allInProgressLabel'), value: 'all' },
            ]"
          ></p-selectButton>
        </label>
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        <label class="conversation-logs-query-editor__form-field">
          {{ t('showEndedLabel') }}
          <p-inputSwitch formControlName="showEnded" ariaLabel="{{ t('showEndedLabel') }}"></p-inputSwitch>
        </label>
      </div>
      <div *ngIf="form.value.showEnded === true" class="col">
        <ng-container *ngrxLet="form.controls.range as range">
          <mat-form-field appearance="fill">
            <mat-label>{{ t('dateRangeLabel') }}</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input formControlName="start" matStartDate placeholder="{{ t('start') }}" />
              <input formControlName="end" matEndDate placeholder="{{ t('end') }}" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{
              t('invalidStartDate')
            }}</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{ t('invalidEndDate') }}</mat-error>
          </mat-form-field>
        </ng-container>
      </div>
    </div>
  </form>
  <button
    *ngrxLet="queryFailed$ as queryFailed"
    class="p-button-text"
    pButton
    [disabled]="!queryFailed || form.invalid"
    [label]="t('queryButton', { queryFailed })"
    [loading]="!queryFailed"
    (click)="retryQuery()"
  ></button>

  <app-conversation-select-and-edit *ngIf="client$ | async as client" [client]="client">
  </app-conversation-select-and-edit>
  <mat-spinner *ngIf="loading$ | async"></mat-spinner>
</ng-container>
