import { SerializableObject } from '../../../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../../../generic/serialization/serializable-object-schema';
import { DBMatchRuleAnonymousPhone } from '../../db-match-rules/db-match-rule-anonymous-phone/db-match-rule-anonymous-phone';
import { DBMatchRuleE164Phone } from '../../db-match-rules/db-match-rule-e164-phone/db-match-rule-e164-phone';
import { DBMatchRule } from '../db-match-rule/db-match-rule';
import { DBMatchRuleName } from '../db-match-rule-name';

import { AnyDBMatchRuleSchema } from './any-db-match-rule-schema';

export abstract class AnyDBMatchRule extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): DBMatchRule {
    const typeString = validationResult.value[AnyDBMatchRuleSchema.type];
    const type = DBMatchRuleName[typeString as keyof typeof DBMatchRuleName];
    if (type === undefined) {
      throw new Error(`AnyDBMatchRuleUser._deserialize: User Error: Cannot deserialize obj with type=${typeString}`);
    }

    switch (type) {
      case DBMatchRuleName.e164Phone: {
        return new DBMatchRuleE164Phone(validationResult.value);
      }
      case DBMatchRuleName.anonymousPhone: {
        return new DBMatchRuleAnonymousPhone(validationResult.value);
      }
      default: {
        throw new Error(`AnyDBMatchRule._deserialize Unknown type: ${type}`);
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new AnyDBMatchRuleSchema();
  }
}
