import { cloneDeep, isNil } from 'lodash';

import { SerializableObject } from '../../../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../../../generic/serialization/serializable-object-schema';
import { ServiceLimitServiceUsage } from '../../../service-limit-service-usage/service-limit-service-usage';
import { ServiceLimitServiceUsageThreshold } from '../../../service-limit-service-usage-threshold/service-limit-service-usage-threshold';
import { ServiceLimitEnforcementStrategyNameType } from '../service-limit-enforcement-strategy-name';

export abstract class ServiceLimitEnforcementStrategy extends SerializableObject {
  protected enforce!: boolean;

  protected type!: ServiceLimitEnforcementStrategyNameType;

  protected threshold!: ServiceLimitServiceUsageThreshold;

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    throw new Error('Error: Cannot get schema for abstract ServiceLimitEnforcementStrategy');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getEnforce(): boolean {
    return cloneDeep(this.enforce);
  }

  public getType(): ServiceLimitEnforcementStrategyNameType {
    return cloneDeep(this.type);
  }

  public getThreshold(): ServiceLimitServiceUsageThreshold {
    return cloneDeep(this.threshold);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Does Permit Service
  /////////////////////////////////////////////////////////////////////////////

  protected doesPermitServiceByGenericProps(usage: ServiceLimitServiceUsage): boolean | undefined {
    if (!this.enforce) {
      return true;
    }

    return undefined;
  }

  public abstract doesPermitService(usage: ServiceLimitServiceUsage): boolean;

  /////////////////////////////////////////////////////////////////////////////
  // Reset Usage Window
  /////////////////////////////////////////////////////////////////////////////

  public onBeforeLogSessionComplete(usage: ServiceLimitServiceUsage): void {
    if (isNil(usage.getWindowStartTime())) {
      usage.reset();
    }
  }
}
