import { cloneDeep } from 'lodash';

import { SerializableObjectSchema } from '../../generic/serialization/serializable-object-schema';
import { VoiceResponseCommandName } from '../any-voice-response-command/voice-response-command-name';
import { VoiceResponseCommand } from '../generic/voice-response-command';
import { VoiceResponseCommandSchema } from '../generic/voice-response-command-schema';

import { JoinConferenceConstructor } from './join-conference-constructor';
import { JoinConferenceSchema } from './join-conference-schema';

export class JoinConference extends VoiceResponseCommand {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  private skipCheckConferenceActive: boolean;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: JoinConferenceConstructor) {
    // This hack ensures that deserializing a VoiceCommand is equal to instanting it directly.
    // The difference is trivial, and only required for intuitive unit testing.
    (parameters as any)[VoiceResponseCommandSchema.commandName] = VoiceResponseCommandName.joinConference;
    super(parameters);
    this.skipCheckConferenceActive = false;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): JoinConference {
    return new JoinConference(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(JoinConference.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new JoinConferenceSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getSkipCheckConferenceActive(): boolean {
    return cloneDeep(this.skipCheckConferenceActive);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  public setSkipCheckConferenceActive(value: true): JoinConference {
    this.skipCheckConferenceActive = value;
    return this;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////
}
