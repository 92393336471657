<ng-container *transloco="let t; read: 'accordion-wizard-step-header'">
  <div class="col flex-grow-1 flex justify-content-begin">{{ label }}</div>
  <div class="col flex-grow-1 flex justify-content-end">
    <mat-icon
      *ngIf="!hasError"
      aria-hidden="false"
      class="text-green-500"
      attr.aria-label="{{ t('stepValidIndicatorLabel') }}"
      >check_circle</mat-icon
    >
    <mat-icon
      *ngIf="hasError"
      aria-hidden="false"
      class="text-yellow-500"
      attr.aria-label="{{ t('stepErrorIndicatorLabel') }}"
      >error</mat-icon
    >
  </div>
</ng-container>
