import Joi from 'joi';

import { DBDocSchemaFields } from '../db-doc/db-doc-schema-fields';

/**
 * Every serializable object must have an associated schema, which extends this class.
 */
export abstract class SerializableObjectSchema {
  public static getDefaultValue(schema: SerializableObjectSchema, fieldName: string): any {
    let defaults!: any;
    if (!Object.prototype.hasOwnProperty.call(schema.constructor, 'Defaults')) {
      console.error('error');
      console.error(schema);
      throw new Error('Error: object does not define defaults in a class named defaults');
    } else {
      defaults = (schema.constructor as any).Defaults;
    }

    const keys = new Set(Object.keys(defaults));
    if (!keys.has(fieldName)) {
      throw new Error(`SerializableObjectSchema.getDefaultValue: No default value specified for ${fieldName}`);
    }
    return defaults[fieldName];
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Abstract Methods
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * This is the main method of the class, and where the actual schema is encoded.
   */
  public abstract getSchemaDefinition(): { [key: string]: import('joi').AnySchema } | import('joi').AnySchema;

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Deserialize data according to
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////]

  /**
   *
   * Convert the schema definition to a JOI schema, which can be used for
   * validation.
   *
   */
  public getJoiSchema(): import('joi').AnySchema {
    const schemaDefinition = this.getSchemaDefinition();

    // If schemaDefinition is already a Joi object, then simply return.
    if (isJoiAnySchema(schemaDefinition)) {
      return schemaDefinition as Joi.AnySchema;
    }

    return Joi.object(schemaDefinition as { [key: string]: import('joi').AnySchema });
  }

  /**
   * The schema is comrpised of key value pairs. Return the list of keys.
   */
  public getSchemaKeys(includeDocId = false): string[] {
    const result = Object.keys(this.getSchemaDefinition());

    if (includeDocId) {
      return result;
    }
    return result.filter((z) => z !== DBDocSchemaFields.id);
  }

  /**
   * Default values will not be serialized, unless they are specified here.
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set();
  }
}

/**
 * This hack returns true if the input object is a Joi.AnySchema object, and false otherwise.
 * @param obj
 */
const isJoiAnySchema = (obj: any): boolean => {
  if (obj === undefined) {
    return false;
  }

  // Simply check if the following fields exist
  if (
    Object.prototype.hasOwnProperty.call(obj, '_rules') &&
    Object.prototype.hasOwnProperty.call(obj, '_singleRules')
  ) {
    return true;
  }
  return false;
};
