import { isNil } from 'lodash';
import moment from 'moment-timezone';

export const displayTimeShort = (time: moment.Moment | undefined, timezone: string): string => {
  if (isNil(time)) {
    return '';
  }

  const hours = time.tz(timezone).format('h');
  const minutes = time.tz(timezone).format('mm');
  const meridian = time.tz(timezone).format('a')[0];

  if (minutes === '00') {
    return `${hours}${meridian}`;
  }

  return `${hours}:${minutes}${meridian}`;
};
