import { values } from 'lodash';

import { SchemaField } from '../../../../../../../generic/serialization/schema-field';
import { CommunicationSessionEventLogSchema } from '../../../../generic/communication-session-event-log/communication-session-event-log-schema';
import { CommunicationSessionEventLogConferenceType } from '../communication-session-event-log-conference-type';

export class CommunicationSessionEventLogConferenceSchema extends CommunicationSessionEventLogSchema {
  public static readonly conferenceEventType = 'conferenceEventType';

  public static readonly conferenceSid = 'conferenceSid';

  public static readonly eventTimestamp = 'eventTimestamp';

  public static readonly friendlyName = 'friendlyName';

  public static readonly sequenceNumber = 'sequenceNumber';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    ///////////////////////////////////////////////////////
    // Generic InboundCallEvent Properties, Copy/Pasted Here
    ///////////////////////////////////////////////////////

    public static readonly type = undefined;

    public static readonly actor = undefined;

    public static readonly timestamp = undefined;

    ///////////////////////////////////////////////////////
    // CommunicationSessionEventLogSentMessage Properties
    ///////////////////////////////////////////////////////

    /**
     * Tracking Info
     */
    public static readonly conferenceEventType = undefined;

    public static readonly conferenceSid = undefined;

    public static readonly eventTimestamp = undefined;

    public static readonly friendlyName = undefined;

    public static readonly sequenceNumber = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    /**
     * Tracking Info
     */
    parameters[CommunicationSessionEventLogConferenceSchema.conferenceEventType] = SchemaField.string(
      CommunicationSessionEventLogConferenceSchema.conferenceEventType,
      CommunicationSessionEventLogConferenceSchema.Defaults.conferenceEventType,
    )
      .required()
      .valid(...values(CommunicationSessionEventLogConferenceType));
    parameters[CommunicationSessionEventLogConferenceSchema.conferenceSid] = SchemaField.string(
      CommunicationSessionEventLogConferenceSchema.Defaults.conferenceSid,
    ).required();
    parameters[CommunicationSessionEventLogConferenceSchema.eventTimestamp] = SchemaField.timestamp(
      CommunicationSessionEventLogConferenceSchema.Defaults.eventTimestamp,
    ).required();
    parameters[CommunicationSessionEventLogConferenceSchema.friendlyName] = SchemaField.string(
      CommunicationSessionEventLogConferenceSchema.Defaults.friendlyName,
    ).required();
    parameters[CommunicationSessionEventLogConferenceSchema.sequenceNumber] = SchemaField.number(
      CommunicationSessionEventLogConferenceSchema.Defaults.sequenceNumber,
    ).required();
    /* eslint-enable */

    return parameters;
  }
}
