<ng-container *transloco="let t; read: 'voicemail-autocomplete'">
  <p-autoComplete
    appendTo="body"
    field="displayName"
    [dropdown]="true"
    [dropdownAriaLabel]="t('dropdownLabel')"
    [emptyMessage]="t('noOptionsFound')"
    [formControl]="control"
    [inputId]="inputId"
    [ngClass]="{ 'ng-invalid': control?.invalid }"
    [placeholder]="placeholder"
    [showEmptyMessage]="true"
    [suggestions]="suggestedOptions$ | async"
    (completeMethod)="search($event)"
  >
    <ng-template #itemDisplay let-item>
      <div class="voicemail-autocomplete__item-name">
        <span>{{ item.displayName }}</span>
      </div>
    </ng-template>

    <ng-template pTemplate="item" let-item>
      <div class="voicemail-autocomplete__item">
        <div class="voicemail-autocomplete__item-label">
          <mat-icon> voicemail </mat-icon>
          <ng-container *ngTemplateOutlet="itemDisplay; context: { $implicit: item }"></ng-container>
        </div>
        <span class="voicemail-autocomplete__item-description">{{ item.description }}</span>
      </div>
    </ng-template>

    <ng-template pTemplate="selectedItem" let-value>
      <ng-container *ngTemplateOutlet="itemDisplay; context: { $implicit: value }"></ng-container>
    </ng-template>
  </p-autoComplete>

  <div *ngIf="control.errors as errors" class="p-error">
    <span *ngIf="errors.objIdNotInList">{{ t('controlErrorObjIdNotInList') }}</span>
  </div>
</ng-container>
