import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';

import { DBDocObject } from '../../generic/db-doc/db-doc-object';
import { DBDocSchema } from '../../generic/db-doc/db-doc-schema';
import { AssignedUserType } from '../event-data/enums';

import {
  EventRequestDecidedConstructor,
  EventRequestDecision,
  EventRequestOpenConstructor,
} from './event-request-constructor';
import { EventRequestSchema } from './event-request-schema';

export class EventRequest extends DBDocObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected comment: string | undefined;

  protected decisionTime: moment.Moment | undefined;

  protected decidedByUserId: string | undefined;

  protected eventId!: string;

  protected isApproved: boolean | undefined;

  protected isOpen!: boolean;

  protected requestedAssignedUserId!: string;

  protected requestedSlot?: AssignedUserType;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: EventRequestOpenConstructor | EventRequestDecidedConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): EventRequest {
    return new EventRequest(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(EventRequest.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new EventRequestSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getComment(): string | undefined {
    return cloneDeep(this.comment);
  }

  public getDecisionTime(): moment.Moment | undefined {
    return cloneDeep(this.decisionTime);
  }

  public getDecidedByUserId(): string | undefined {
    return cloneDeep(this.decidedByUserId);
  }

  public getEventId(): string {
    return cloneDeep(this.eventId);
  }

  public getIsApproved(): boolean | undefined {
    return cloneDeep(this.isApproved);
  }

  public getIsOpen(): boolean | undefined {
    return cloneDeep(this.isOpen);
  }

  public getRequestedAssignedUserId(): string {
    return cloneDeep(this.requestedAssignedUserId);
  }

  public getRequestedSlot(): AssignedUserType | undefined {
    return cloneDeep(this.requestedSlot);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////

  public decide(decisionData: EventRequestDecision): EventRequest {
    this.comment = decisionData.comment;
    this.decisionTime = decisionData.decisionTime;
    this.decidedByUserId = decisionData.decidedByUserId;
    this.isApproved = decisionData.isApproved;
    this.isOpen = false;
    return this;
  }
}
