<ng-container *transloco="let t; read: 'service-command-editor-container'">
  <div [formGroup]="form">
    <div class="grid-nogutter">
      <div class="col-12">
        <div class="grid-nogutter p-fluid">
          <app-accordion-wizard-steps class="w-full">
            <app-accordion-wizard-step [hasError]="commandName.invalid" [label]="t('chooseCommandLabel')">
              <div class="field mb-4 col-12">
                <app-service-command-name-select [formControl]="commandName"></app-service-command-name-select>
              </div>
            </app-accordion-wizard-step>

            <app-accordion-wizard-step [hasError]="command.invalid" [label]="t('commandTitle')">
              <div class="field mb-4 col-12" [ngSwitch]="commandName.value">
                <app-service-command-editor-dial-call-list
                  *ngSwitchCase="'dialCallList'"
                  [formControl]="command"
                ></app-service-command-editor-dial-call-list>
                <app-service-command-editor-enforce-service-limit
                  *ngSwitchCase="'enforceServiceLimit'"
                  [formControl]="command"
                ></app-service-command-editor-enforce-service-limit>
                <app-service-command-editor-flag-service-request
                  *ngSwitchCase="'flagServiceRequest'"
                  [formControl]="command"
                ></app-service-command-editor-flag-service-request>
                <app-service-command-editor-forward
                  *ngSwitchCase="'forward'"
                  [formControl]="command"
                ></app-service-command-editor-forward>
                <app-service-command-editor-play-or-say *ngSwitchCase="'playOrSay'" [formControl]="command">
                </app-service-command-editor-play-or-say>
                <app-service-command-editor-take-voicemail *ngSwitchCase="'takeVoicemail'" [formControl]="command">
                </app-service-command-editor-take-voicemail>
              </div>
            </app-accordion-wizard-step>
          </app-accordion-wizard-steps>
        </div>
      </div>
    </div>
  </div>
</ng-container>
