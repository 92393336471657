import { cloneDeep } from 'lodash';

import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { Identity } from '../../../../../objects/identity/identity/identity';
import { CommunicationsRequestName } from '../../../communications-request-name';
import { CommunicationsResponse } from '../../generic/communications-response/communications-response';
import { CommunicationsResponseSchema } from '../../generic/communications-response/communications-response-schema';

import { CommunicationsResponseGetConversationLogPIIConstructor } from './communications-response-get-conversation-log-pii-constructor';
import { CommunicationsResponseGetConversationLogPIIError } from './communications-response-get-conversation-log-pii-error';
import { CommunicationsResponseGetConversationLogPIISchema } from './communications-response-get-conversation-log-pii-schema';

export class CommunicationsResponseGetConversationLogPII extends CommunicationsResponse<CommunicationsResponseGetConversationLogPIIError> {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected identity!: Identity | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationsResponseGetConversationLogPIIConstructor) {
    (parameters as any)[CommunicationsResponseSchema.type] = CommunicationsRequestName.getConversationLogPII;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(
    validationResult: import('joi').ValidationResult,
  ): CommunicationsResponseGetConversationLogPII {
    return new CommunicationsResponseGetConversationLogPII(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationsResponseGetConversationLogPII.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationsResponseGetConversationLogPIISchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getIdentity(): Identity | undefined {
    return cloneDeep(this.identity);
  }
}
