import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { translate, TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LetModule } from '@ngrx/component';
import { parsePhoneNumber } from 'libphonenumber-js';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToastModule } from 'primeng/toast';
import { map } from 'rxjs';

import { PossibleRole, RolesSchema } from '@pwp-common';

import { NotInListValidator } from '../../../../common/validators/not-in-list-validator';
import { USLocalPhoneValidator } from '../../../../common/validators/us-local-phone-validator';
import { OrgDataService } from '../../../../services/orgs/org-data/org-data.service';
import { AdminRolesService } from '../../../../services/user/admin-roles/admin-roles.service';
import { UserEndpointService } from '../../../../services/user/user-endpoint/user-endpoint.service';
import { FormFieldHelpComponent } from '../../../form/form-field-help/form-field-help.component';

@UntilDestroy()
@Component({
  selector: 'app-add-user-dialog',
  standalone: true,
  imports: [
    ButtonModule,
    CheckboxModule,
    CommonModule,
    FormFieldHelpComponent,
    FormsModule,
    InputMaskModule,
    InputTextareaModule,
    InputTextModule,
    LetModule,
    MatIconModule,
    MultiSelectModule,
    ReactiveFormsModule,
    ToastModule,
    TranslocoModule,
  ],
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss'],
  providers: [DialogService, MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddUserDialogComponent {
  private readonly fb = inject(NonNullableFormBuilder);

  private readonly takenEmailAddresses = Array.from(this.dialogConfig.data.allDataUserMap.values()).map(
    ({ userData }) => userData.getNotificationEmail(),
  );

  public uploadInProgress = false;

  public readonly profileForm = this.fb.group({
    firstName: ['', [Validators.required]],
    lastInitial: ['', [Validators.required]],
    email: [
      '',
      [Validators.email, Validators.required, NotInListValidator.notInListValidator(this.takenEmailAddresses)],
    ],
    e164Phone: ['', [Validators.required, USLocalPhoneValidator.usLocalPhoneValidator()]],
    roles: [[] as PossibleRole[]],
    orgNote: [''],
    agreeWithConsentTerms: [false, [Validators.requiredTrue]],
  });

  public readonly rolesOptions$ = this.adminRolesService.getAdminRoles().pipe(
    map((userAdminRoles) =>
      [
        RolesSchema.Roles.support,
        RolesSchema.Roles.orgAdmin,
        RolesSchema.Roles.voicemail,
        RolesSchema.Roles.conversation,
      ].map((role) => ({
        label: this.translocoService.translate(`add-user-dialog.roles.${role}.label`),
        description: this.translocoService.translate(`add-user-dialog.roles.${role}.description`),
        disabled: role === RolesSchema.Roles.support && !userAdminRoles.isPwpAdmin(),
        value: role,
      })),
    ),
  );

  public readonly orgData$ = this.orgDataService.getOrgData().pipe(
    map((orgData) => ({
      orgName: orgData.getDisplayName(),
      orgPhoneNumbers: orgData
        .getE164Phones()
        .map((phoneNumber) => parsePhoneNumber(phoneNumber, 'US').formatInternational())
        .join(', '),
    })),
  );

  constructor(
    private adminRolesService: AdminRolesService,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private translocoService: TranslocoService,
    private orgDataService: OrgDataService,
    private userEndpointService: UserEndpointService,
  ) {}

  ////////////////////////////////////////////////////////////////////////
  // Messages
  ////////////////////////////////////////////////////////////////////////

  private showAddUserSuccess() {
    this.messageService.add({
      severity: 'info',
      summary: translate('add-user-dialog.successDialog.title'),
      detail: translate('add-user-dialog.successDialog.body'),
    });
  }

  private showAPIError(error: string) {
    console.error('AddUserDialogComponent.showAPIError', JSON.stringify(error));
    const summary = translate(`pwp-api.errorTimeoutTitle`);
    const detail = translate(`pwp-api.errorTimeoutBody`);

    this.messageService.add({
      severity: 'warn',
      summary,
      detail,
      sticky: true,
    });
  }

  ///////////////////////////////////////////////////////////////////////
  // Actions
  ///////////////////////////////////////////////////////////////////////

  public cancel() {
    this.dialogRef.close();
  }

  public async addUser(): Promise<void> {
    this.uploadInProgress = true;

    try {
      const { email, firstName, lastInitial, e164Phone, roles, orgNote } = this.profileForm.value;
      const requestResult = await this.userEndpointService.createUser({
        email,
        firstName,
        lastInitial,
        e164Phone,
        roles,
        orgNote,
      });
      const resultError = requestResult.getError();

      if (typeof resultError === 'string') {
        this.showAPIError(resultError);
      } else {
        this.showAddUserSuccess();
        this.profileForm.reset();
      }

      this.uploadInProgress = false;
    } catch (error) {
      this.showAPIError(error);
      this.uploadInProgress = false;
    }
  }
}
