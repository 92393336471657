import { millisecondsToSeconds } from 'date-fns';
import { cloneDeep, isNil } from 'lodash';

import {
  CommunicationTaskQueue,
  CommunicationWorkflow,
  CommunicationWorkflowSchema,
  isNilOrDefault,
  OrgData,
  UserData,
} from '@pwp-common';

import { dbToTargetEditorOutput } from '../../target/db/db-to-target-editor-output/db-to-target-editor-output';
import { TargetEditorOutput } from '../../target/target-editor-output';
import { CommunicationWorkflowEditorOutput } from '../communication-workflow-editor-output';

import { defaultEditorOutput } from './default-editor-output';

const workflowSchema = new CommunicationWorkflowSchema();
export const dbToEditorOutput = (params: {
  workflow: CommunicationWorkflow;
  taskQueues: Map<string, CommunicationTaskQueue>;
  users: Map<string, UserData>;
  orgData: OrgData;
}): CommunicationWorkflowEditorOutput => {
  /**
   * Handle missing data
   */
  if (
    isNil(params) ||
    isNil(params.workflow) ||
    isNil(params.taskQueues) ||
    isNil(params.users) ||
    isNil(params.orgData)
  ) {
    return cloneDeep(defaultEditorOutput);
  }

  const { workflow, taskQueues, users, orgData } = params;

  /**
   * Determine the targets
   */
  let targets: TargetEditorOutput[] = [];
  const filters = workflow.getFilters();
  if (filters.length !== 1) {
    console.error(params);
    throw new Error('workflowDbToEditorResult: Filters has the wrong length. Not implemented');
  }
  for (const target of filters[0].getTargets()) {
    targets.push(dbToTargetEditorOutput({ target, orgData, users, taskQueue: taskQueues.get(target.getQueueId()) }));
  }
  if (targets.length === 0) {
    targets = cloneDeep(defaultEditorOutput.targets);
  }

  let displayName = workflow.getDisplayName();
  let description = workflow.getDescription();

  if (isNilOrDefault(displayName, CommunicationWorkflowSchema.displayName, workflowSchema)) {
    displayName = '';
  }
  if (isNilOrDefault(description, CommunicationWorkflowSchema.description, workflowSchema)) {
    description = '';
  }

  const taskReservationTimeoutMs = workflow.getTaskReservationTimeoutMS();

  /**
   * Return
   */
  const result: CommunicationWorkflowEditorOutput = {
    id: workflow.getId(),
    displayName,
    description,
    targets,
    taskReservationTimeoutSeconds: taskReservationTimeoutMs
      ? millisecondsToSeconds(taskReservationTimeoutMs)
      : defaultEditorOutput.taskReservationTimeoutSeconds,
  };

  return result;
};
