import { ChangeDetectionStrategy, Component, OnChanges, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';

import { EventRequestConfigService } from '../../../../../services/event/event-requests/event-request-config.service';
import { SettingsRowEditorBase } from '../../../../generic/settings/abstract-classes/settings-row-editor-base';
import { makeAuditEntry } from '../../../../generic/settings/common/audit-entry/helpers/make-audit-entry';
import { editorOutputToDb } from '../../editor/editor-output/edit-output-to-db/editor-output-to-db';
import { EventRequestConfigEditorOutput } from '../../editor/interfaces';
import { EventRequestConfigTableRow } from '../event-request-config-table-row/event-request-config-table-row';

@Component({
  selector: 'app-event-request-config-table-editor-expansion',
  templateUrl: './event-request-config-table-editor-expansion.component.html',
  styleUrls: ['./event-request-config-table-editor-expansion.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class EventRequestConfigTableEditorExpansionComponent
  extends SettingsRowEditorBase<any, EventRequestConfigTableRow>
  implements OnChanges, OnInit
{
  ///////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    messageService: MessageService,
    private eventRequestConfigService: EventRequestConfigService,
  ) {
    super(messageService);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Audit Entry
  ///////////////////////////////////////////////////////////////////////////////////////////
  updateAuditEntry() {
    this.auditEntry = makeAuditEntry(this.row.data.eventRequestConfigData);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Upload
  ///////////////////////////////////////////////////////////////////////////////////////////
  protected async uploadEditorOutput(
    editorOutput: EventRequestConfigEditorOutput,
  ): Promise<{ updateSucceeded: boolean; objId: string }> {
    const requestedUpdate = editorOutputToDb({
      eventRequestConfig: this.row.data.eventRequestConfigData,
      editorOutput,
      eventRequestConfigId: this.row.data.eventType.getInternalName(),
    });
    if (this.row.data.eventRequestConfigData) {
      const updateSucceeded = await this.eventRequestConfigService.uploadIfNotEditingStaleObject(requestedUpdate);
      return { objId: requestedUpdate.getId(), updateSucceeded };
    }
    await lastValueFrom(
      this.eventRequestConfigService.upload({ obj: requestedUpdate, mode: 'create', generateId: false }),
    );
    return { objId: requestedUpdate.getId(), updateSucceeded: true };
  }
}
