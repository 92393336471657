import { cloneDeep } from 'lodash';

import { DBDocObject } from '../../generic/db-doc/db-doc-object';
import { DBDocSchema } from '../../generic/db-doc/db-doc-schema';

import { AdminRolesConstructor } from './admin-roles-constructor';
import { AdminRolesSchema } from './admin-roles-schema';
import { PossibleAdminRole } from './types';

export class AdminRoles extends DBDocObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected roles!: PossibleAdminRole[];

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: AdminRolesConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): AdminRoles {
    return new AdminRoles(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new AdminRolesSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getUserId(): string {
    return cloneDeep(this.getId());
  }

  public getRoles(): PossibleAdminRole[] {
    return cloneDeep(this.roles);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  public setRoles(roles: PossibleAdminRole[]): AdminRoles {
    this.roles = roles;
    return this;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////

  public isPwpAdmin(): boolean {
    if (this.getRoles().includes(AdminRolesSchema.Roles.pwpAdmin)) {
      return true;
    }
    return false;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Has Role
  /////////////////////////////////////////////////////////////////////////////

  public hasRoles(roles: PossibleAdminRole[]): boolean {
    for (const role of roles) {
      switch (role) {
        case 'pwpAdmin': {
          if (!this.isPwpAdmin()) {
            return false;
          }
          break;
        }
        default: {
          return false;
        }
      }
    }
    return true;
  }
  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(AdminRoles.getSchema());
  }
}
