import { translate } from '@ngneat/transloco';

import { StatusBadgeColor } from '../../components/shared/status-badge/status-badge-color';

export class RowCell<T> {
  public translationScope: string;

  public translationKey: string;

  public translationObj: Record<string, any>;

  public sortValue: T;

  public styleClass: string | undefined;

  public icon: string | undefined;

  public statusBadgeColor?: StatusBadgeColor;

  constructor(
    private params: {
      translationScope: string;
      translationKey: string;
      translationObj?: Record<string, any>;
      sortValue: T;
      styleClass?: string;
      icon?: string;
      statusBadgeColor?: StatusBadgeColor;
    },
  ) {
    this.translationScope = params.translationScope;
    this.translationKey = params.translationKey;
    this.translationObj = params.translationObj;
    this.sortValue = params.sortValue;
    this.styleClass = params.styleClass;
    this.icon = params.icon;
    this.statusBadgeColor = params.statusBadgeColor;
  }

  toString() {
    return translate(`${this.params.translationScope}.${this.translationKey}`, this.translationObj ?? {});
  }
}
