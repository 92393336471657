import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { isNil, translate, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep } from 'lodash';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { AllDataUser, AllDataUserDisplay, RolesSchema } from '@pwp-common';

import { AllDataUserService } from '../../../../services/user/all-data-user/all-data-user.service';
import { AuthService } from '../../../../services/user/auth/auth.service';
import { AddUserDialogComponent } from '../add-user-dialog/add-user-dialog.component';
import { AddUserDialogData } from '../common/add-user-dialog-data';

@UntilDestroy()
@Component({
  selector: 'app-modify-user-options-table',
  templateUrl: './modify-user-options-table.component.html',
  styleUrls: ['./modify-user-options-table.component.css'],
  providers: [DialogService, MessageService],
})
export class ModifyUserOptionsTableComponent implements OnInit {
  ////////////////////////////////////////////////////////////////////////
  // State
  ////////////////////////////////////////////////////////////////////////

  loading = false;

  loggedInUserId: string;

  ////////////////////////////////////////////////////////////////////////
  // Selected Columns
  ////////////////////////////////////////////////////////////////////////

  cols = [
    { field: 'displayName', header: 'Display Name' },
    { field: 'email', header: 'Email' },
    { field: 'tenureMonths', header: 'Tenure Months' },
    { field: 'userId', header: 'User Id' },
    { field: 'roles', header: 'Roles' },
    { field: 'orgNote', header: 'Note' },
    { field: 'createTime', header: 'Create Time' },
    { field: 'createdByDisplayName', header: 'Created By' },
    { field: 'createdByUserId', header: 'Created By (userId)' },
    { field: 'lastRoleUploadTime', header: 'Role / Note Last Modified Time' },
    { field: 'lastRoleModifiedByDisplayName', header: 'Role / Note Last Modified By' },
    { field: 'lastRoleModifiedByUserId', header: 'Role / Note Last Modified By (userId)' },
  ];

  initialSelectedCols = [
    { field: 'displayName', header: 'Display Name' },
    { field: 'email', header: 'Email' },
    { field: 'tenureMonths', header: 'Tenure Months' },
    { field: 'roles', header: 'Roles' },
    { field: 'orgNote', header: 'Note' },
    { field: 'lastRoleUploadTime', header: 'Role / Note Last Modified Time' },
    { field: 'lastRoleModifiedByDisplayName', header: 'Role / Note Last Modified By' },
  ];

  selectedColumns = this.initialSelectedCols;

  ////////////////////////////////////////////////////////////////////////
  // Variables
  ////////////////////////////////////////////////////////////////////////

  allDataUserMap: Map<string, AllDataUser> = new Map();

  rows: AllDataUserDisplay[] = [];

  ////////////////////////////////////////////////////////////////////////
  // Constructor
  ////////////////////////////////////////////////////////////////////////

  constructor(
    private allDataUserService: AllDataUserService,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private messageService: MessageService,
    private translocoService: TranslocoService,
  ) {}

  ////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////

  ngOnInit() {
    this.getData();
  }

  private getData() {
    this.loading = true;
    void Promise.all([this.allDataUserService.getDocs().toPromise(), this.authService.getUserId().toPromise()]).then(
      (value) => {
        this.allDataUserMap = value[0];
        this.loggedInUserId = value[1];
        this.refreshUI();
      },
    );
  }

  ////////////////////////////////////////////////////////////////////////
  // Refresh UI
  ////////////////////////////////////////////////////////////////////////

  private refreshUI() {
    if (isNil(this.loggedInUserId)) {
      return;
    }
    this.loading = true;
    const rows = [];

    for (const userId of Array.from(this.allDataUserMap.keys())) {
      if (userId === this.loggedInUserId) {
        // Allowing a user to edit their own permissions can cause account lock out.
        continue;
      }

      const allDataUser = this.allDataUserMap.get(userId);
      const allDataUserDisplay = new AllDataUserDisplay(allDataUser, this.allDataUserMap);

      allDataUserDisplay.roles = allDataUser.roles
        .getRoles()
        // temporarily don't show support role.
        .filter((z) => z !== RolesSchema.Roles.support)
        .map((z) => this.translocoService.translate(`modify-user-options-table.${z}`))
        .join(',');

      rows.push(allDataUserDisplay);
    }

    this.rows = rows.sort((a, b) => b.tenureMonths - a.tenureMonths);
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  ////////////////////////////////////////////////////////////////////////
  // Callback for when row changes
  ////////////////////////////////////////////////////////////////////////

  protected async userUpdated(userId: string) {
    console.log('userUpdated: Starting', { userId });

    this.loading = true;

    this.allDataUserMap.delete(userId);
    const newAllDataUser = await this.allDataUserService.getDoc(userId).toPromise();
    if (!isNil(newAllDataUser)) {
      this.allDataUserMap.set(newAllDataUser.getUserId(), newAllDataUser);
    } else {
      this.showDeleteSuccessful();
    }

    this.refreshUI();
    console.log('userUpdated: Completed', { userId });
  }

  private showDeleteSuccessful() {
    // Show Toast
    this.messageService.add({
      severity: 'success',
      summary: translate('modify-user-options-table.deleteSuccessTitle'),
      detail: '',
    });
    this.changeDetectorRef.detectChanges();
  }

  ////////////////////////////////////////////////////////////////////////
  // Add User Dialog
  ////////////////////////////////////////////////////////////////////////

  openAddUserDialog(): void {
    const data: AddUserDialogData = { allDataUserMap: cloneDeep(this.allDataUserMap) };

    const dialogRef = this.dialogService.open(AddUserDialogComponent, {
      data,
      showHeader: false,
      width: 'clamp(32rem, 50%, 64rem)',
    });

    dialogRef.onClose.pipe(untilDestroyed(this)).subscribe(() => {
      this.getData();
    });
  }
}
