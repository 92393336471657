import { isNil, upperFirst } from 'lodash';

import {
  DialedCallStatus,
  displayTime,
  formatDuration,
  GenericDialedCallLog,
  IVRResponseDirection,
  UserData,
  UserStatus,
} from '@pwp-common';

import { RowCell } from '../../../../common/p-table/row-cell';
import { StatusBadgeColor } from '../../../shared/status-badge/status-badge-color';

import { CommunicationSessionEventBase } from './communication-session-event-base';

export class CommunicationSessionDialedCallLogBase<
  T extends GenericDialedCallLog,
> extends CommunicationSessionEventBase<T> {
  //////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  //////////////////////////////////////////////////////////////////////////////////////////

  initiatedTimestamp: RowCell<Date>;

  ringingTimestamp: RowCell<Date>;

  answeredTimestamp: RowCell<Date>;

  completedTimestamp: RowCell<Date>;

  ringDuration: RowCell<number>;

  talkDuration: RowCell<number>;

  //////////////////////////////////////////////////////////////////////////////////////////
  // Constructor
  //////////////////////////////////////////////////////////////////////////////////////////

  constructor({
    dialedCall,
    allDataUser,
    timezone,
    userId,
    userStatus,
  }: {
    dialedCall: T;
    allDataUser: Map<string, UserData>;
    timezone: string;
    userId: string | undefined;
    userStatus: UserStatus | undefined;
  }) {
    super({
      channel: 'phone',
      sid: dialedCall.getCallSid() ?? dialedCall.getId(),
      doc: dialedCall,
      allDataUser,
      timezone,
      userId,
      userStatus,
    });
    this.setInitiatedTimestamp(dialedCall, timezone);
    this.setRingingTimestamp(dialedCall, timezone);
    this.setAnsweredTimestamp(dialedCall, timezone);
    this.setCompletedTimestamp(dialedCall, timezone);
    this.setRingDuration(dialedCall);
    this.setTalkDuration(dialedCall);
    this.setOriginationReason();
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Origination Reason
  //////////////////////////////////////////////////////////////////////////////////////////
  protected setOriginationReason() {
    this.originationReason = new RowCell<string>({
      translationScope: 'communication-session-event-row',
      translationKey: 'cellOriginationReasonDialed',
      translationObj: { ...this.userDisplayNameWithEmail?.translationObj },
      sortValue: 'cellOriginationReasonDialed',
      styleClass: undefined,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Initiated / Ringing / Answered / Completed Timestamps
  //////////////////////////////////////////////////////////////////////////////////////////

  private setInitiatedTimestamp(dialedCall: T, timezone: string) {
    const initiatedTimestamp = dialedCall.getInitiatedTimestamp();
    if (isNil(initiatedTimestamp)) {
      this.initiatedTimestamp = new RowCell<Date>({
        translationScope: 'communication-session-event-row',
        translationKey: 'cellInitiatedTimestampMissing',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    this.initiatedTimestamp = new RowCell<Date>({
      translationScope: 'communication-session-event-row',
      translationKey: 'cellInitiatedTimestamp',
      translationObj: {
        timestamp: displayTime(initiatedTimestamp, timezone, { timeOnly: true, showSeconds: true }),
      },
      sortValue: initiatedTimestamp.toDate(),
    });
  }

  private setRingingTimestamp(dialedCall: T, timezone: string) {
    const ringingTimestamp = dialedCall.getRingingTimestamp();
    if (isNil(ringingTimestamp)) {
      this.ringingTimestamp = new RowCell<Date>({
        translationScope: 'communication-session-event-row',
        translationKey: 'cellRingingTimestampMissing',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    this.ringingTimestamp = new RowCell<Date>({
      translationScope: 'communication-session-event-row',
      translationKey: 'cellRingingTimestamp',
      translationObj: {
        timestamp: displayTime(ringingTimestamp, timezone, { timeOnly: true, showSeconds: true }),
      },
      sortValue: ringingTimestamp.toDate(),
    });
  }

  private setAnsweredTimestamp(dialedCall: T, timezone: string) {
    const answeredTimestamp = dialedCall.getAnsweredTimestamp();
    if (isNil(answeredTimestamp)) {
      this.answeredTimestamp = new RowCell<Date>({
        translationScope: 'communication-session-event-row',
        translationKey: 'cellAnsweredTimestampMissing',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    this.answeredTimestamp = new RowCell<Date>({
      translationScope: 'communication-session-event-row',
      translationKey: 'cellAnsweredTimestamp',
      translationObj: {
        timestamp: displayTime(answeredTimestamp, timezone, { timeOnly: true, showSeconds: true }),
      },
      sortValue: answeredTimestamp.toDate(),
    });
  }

  private setCompletedTimestamp(dialedCall: T, timezone: string) {
    const completedTimestamp = dialedCall.getCompletedTimestamp();
    if (isNil(completedTimestamp)) {
      this.completedTimestamp = new RowCell<Date>({
        translationScope: 'communication-session-event-row',
        translationKey: 'cellCompletedTimestampMissing',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    this.completedTimestamp = new RowCell<Date>({
      translationScope: 'communication-session-event-row',
      translationKey: 'cellCompletedTimestamp',
      translationObj: {
        timestamp: displayTime(completedTimestamp, timezone, { timeOnly: true, showSeconds: true }),
      },
      sortValue: completedTimestamp.toDate(),
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Ring & Talk Durations
  //////////////////////////////////////////////////////////////////////////////////////////

  private setRingDuration(dialedCall: T) {
    const durationMS = dialedCall.getRingDurationMS();
    this.ringDuration = new RowCell<number>({
      translationScope: 'communication-session-event-row',
      translationKey: `cellRingDuration`,
      translationObj: {
        duration: formatDuration(durationMS),
      },
      sortValue: durationMS,
    });
  }

  private setTalkDuration(dialedCall: T) {
    const durationMS = dialedCall.getTalkDurationMS();
    this.talkDuration = new RowCell<number>({
      translationScope: 'communication-session-event-row',
      translationKey: `cellTalkDuration`,
      translationObj: {
        duration: formatDuration(durationMS),
      },
      sortValue: durationMS,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Summary
  //////////////////////////////////////////////////////////////////////////////////////////

  protected getSummaryTranslationObj(dialedCall: T) {
    /**
     * Make the translation object
     */
    const responses = dialedCall.getResponses();
    const digits = [];
    const speech = [];
    for (const response of responses) {
      if (response.getDirection() !== IVRResponseDirection.received) {
        continue;
      }
      if (!isNil(response.getDigits()) && response.getDigits().length > 0) {
        digits.push(response.getDigits());
      }
      if (!isNil(response.getSpeech()) && response.getSpeech().length > 0) {
        speech.push(response.getSpeech());
      }
    }

    return {
      ringDuration: formatDuration(dialedCall.getRingDurationMS()),
      talkDuration: formatDuration(dialedCall.getTalkDurationMS()),
      receivedDigits: digits.join(';'),
      receivedSpeech: speech.join(';'),
    };
  }

  protected setSummary(dialedCall: T, userStatus: UserStatus) {
    const translationObj = this.getSummaryTranslationObj(dialedCall);

    //////////////////////////////////////////////////////////////////////
    // Case: Did Not Dial
    //////////////////////////////////////////////////////////////////////

    /**
     * Case: Did Not Dial - Known SIP Error
     */
    if (dialedCall.getSIPResponseCode() === 608) {
      const translationKey = 'cellSummaryDidNotDialSIP608';
      this.summary = new RowCell<string>({
        translationScope: 'communication-session-event-row',
        translationKey,
        translationObj,
        sortValue: translationKey,
      });
      return;
    }

    /**
     * Case: Did Not Dial - Twilio Error
     */
    if (!isNil(dialedCall.getErrorCode())) {
      let translationKey = `cellSummaryDidNotDialUnknownTwilioError`;

      switch (dialedCall.getErrorCode()) {
        case 21211: {
          translationKey = `cellSummaryDidNotDialTwilioError21211`;
          break;
        }
        case 13224: {
          translationKey = `cellSummaryDidNotDialTwilioError13224`;
        }
      }
      this.summary = new RowCell<string>({
        translationScope: 'communication-session-event-row',
        translationKey,
        translationObj,
        sortValue: translationKey,
        statusBadgeColor: StatusBadgeColor.yellow,
      });
      return;
    }

    /**
     * Case: Did Not Dial - User Status
     */
    if ([UserStatus.onActiveCall, UserStatus.awayFromPhone, UserStatus.missingOrInvalidPhone].includes(userStatus)) {
      const translationKey = `cellSummaryUserStatus${upperFirst(userStatus)}`;
      this.summary = new RowCell<string>({
        translationScope: 'communication-session-event-row',
        translationKey,
        translationObj,
        sortValue: translationKey,
      });
      return;
    }

    //////////////////////////////////////////////////////////////////////
    // Case: In Progress & Unsuccessful Calls
    //////////////////////////////////////////////////////////////////////

    const dialedCallStatus = dialedCall.getDialedCallStatus();

    const translationKey = `cellSummaryCallStatus${upperFirst(dialedCallStatus)}`;
    this.summary = new RowCell<string>({
      translationScope: 'communication-session-event-row',
      translationKey,
      translationObj,
      sortValue: translationKey,
    });

    if (
      [
        DialedCallStatus.busy,
        DialedCallStatus.failed,
        DialedCallStatus.noAnswer,
        DialedCallStatus.completedReceptionIssueAnsweredByMachine,
        DialedCallStatus.completedAnsweredByMachine,
      ].includes(dialedCallStatus)
    ) {
      this.summary.statusBadgeColor = StatusBadgeColor.yellow;
    }
  }
}
