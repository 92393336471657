<ng-container *transloco="let t; read: 'admin-hours-dashboard'">
  <ng-template #dashboardConfigTemplate>
    <form *ngIf="!loading" class="full-width" [formGroup]="form">
      <div class="form-row">
        <div class="col">
          <mat-form-field>
            <mat-label>Window</mat-label>
            <mat-select formControlName="window">
              <mat-option *ngFor="let w of possibleWindows" [value]="w">{{ w }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="window?.value !== 'custom'" class="col">
          Showing plots for:
          <br />
          Start: {{ windowObj?.windowStart | momentPipe }} <br />
          End: {{ windowObj?.windowEnd | momentPipe }}
        </div>
      </div>
      <div class="form-row" class="full-width">
        <div *ngIf="window?.value === 'custom'" class="col" class="full-width">
          <mat-form-field class="full-width">
            <mat-label>Start ({{ orgData?.getTimezone() }})</mat-label>
            <input formControlName="customStart" matInput placeholder="Start" type="datetime-local" />
            <mat-error *ngIf="customStart?.invalid"> Invalid Start Time </mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="window?.value === 'custom'" class="col" class="full-width">
          <mat-form-field class="full-width">
            <mat-label>End ({{ orgData?.getTimezone() }})</mat-label>
            <input formControlName="customEnd" matInput placeholder="End" type="datetime-local" />
            <mat-error *ngIf="customEnd?.invalid"> Invalid End Time </mat-error>
          </mat-form-field>
        </div>
      </div>
      <button *ngIf="window?.value === 'custom'" color="primary" mat-raised-button (click)="emitChanges()">
        Apply
      </button>
    </form>
  </ng-template>

  <ng-template #dashboardTemplate>
    <div class="row">
      <div class="col">
        <app-event-hours-table
          *ngIf="!loading"
          [allDataUserMap]="allDataUserMap"
          [entityStatsMap]="entityStatsMap"
          [eventTypes]="eventTypes"
          [orgData]="orgData"
          [windowObj]="windowObj"
        >
        </app-event-hours-table>
      </div>
    </div>
  </ng-template>

  <app-loading [doneTemplate]="doneTemplate" [loader]="loader">
    <ng-template #doneTemplate>
      <app-analytics-dashboard
        [dashboardConfigTemplate]="dashboardConfigTemplate"
        [dashboardTemplate]="dashboardTemplate"
        [lastRefreshTime]="lastRefreshTime"
      >
      </app-analytics-dashboard>
    </ng-template>
  </app-loading>
</ng-container>
