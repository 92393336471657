import { SchemaField } from '../../../objects/generic/serialization/schema-field';
import { RequestSchema } from '../../generic/request/request-schema';

import { ExternalChatRequestAction } from './external-chat-action';

export class ExternalChatRequestSchema extends RequestSchema {
  public static readonly action = 'action';

  public static readonly orgId = 'orgId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class extends RequestSchema.GenericDefaults {
    public static readonly action = undefined;

    public static readonly orgId = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[ExternalChatRequestSchema.action] = SchemaField.string(ExternalChatRequestSchema.Defaults.action)
      .valid(ExternalChatRequestAction.newAnonymousChat)
      .required();
    parameters[ExternalChatRequestSchema.orgId] = SchemaField.autoGeneratedDocId(
      ExternalChatRequestSchema.Defaults.orgId,
    );
    /* eslint-enable */

    return parameters;
  }
}
