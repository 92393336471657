import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isNil } from 'lodash';

import { FormGroupControlValueAccessor } from '../../../../../generic/abstract-classes/form-group-control-value-accessor';
import { ReservationOfferChannelConfigEditorOutput } from '../../editor-output/reservation-offer-config/objects/channel/reservation-offer-channel-config-editor-output';

const VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ReservationOfferChannelConfigComponent),
  multi: true,
};

const VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ReservationOfferChannelConfigComponent),
  multi: true,
};

@UntilDestroy()
@Component({
  selector: 'app-reservation-offer-channel-config',
  templateUrl: './reservation-offer-channel-config.component.html',
  styleUrls: ['./reservation-offer-channel-config.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VALUE_ACCESSOR, VALIDATOR],
})
export class ReservationOfferChannelConfigComponent extends FormGroupControlValueAccessor<
  ReservationOfferChannelConfigEditorOutput,
  ReservationOfferChannelConfigEditorOutput
> {
  ////////////////////////////////////////////////////////////////////////
  // Variables
  ////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineForm() {
    this.form = new UntypedFormGroup({
      phoneCall: new UntypedFormGroup({
        vrcAudioMetadataId: new UntypedFormControl(undefined, [Validators.required]),
        dialTimeoutSeconds: new UntypedFormControl(600, [Validators.required, Validators.min(25), Validators.max(600)]),
      }),
      text: new UntypedFormGroup({
        body: new UntypedFormControl(undefined, [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(500),
        ]),
      }),
      callerIdObjId: new UntypedFormControl(undefined, [Validators.required]),
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Write Value
  /////////////////////////////////////////////////////////////////////////////////////////////
  writeValue(value: ReservationOfferChannelConfigEditorOutput) {
    if (isNil(value)) {
      return;
    }

    /**
     * Update the form
     */
    const formValue: ReservationOfferChannelConfigEditorOutput = {
      phoneCall: {
        vrcAudioMetadataId: value.phoneCall.vrcAudioMetadataId,
        dialTimeoutSeconds: value.phoneCall.dialTimeoutSeconds,
      },
      text: {
        body: value.text.body,
      },
      callerIdObjId: value.callerIdObjId,
    };

    this.form.patchValue(formValue, { emitEvent: true });
    this.changeDetectorRef.detectChanges();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  parseValueChange(value: ReservationOfferChannelConfigEditorOutput) {
    return value;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected makeValidationErrors() {
    return {
      'reservation-offer-channel-config': this.form.value,
    };
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form Controls
  ///////////////////////////////////////////////////////////////////////////////////////////

  get vrcAudioMetadataId() {
    return this.form.get('phoneCall.vrcAudioMetadataId') as UntypedFormControl;
  }

  get dialTimeoutSeconds() {
    return this.form.get('phoneCall.dialTimeoutSeconds') as UntypedFormControl;
  }

  get body() {
    return this.form.get('text.body') as UntypedFormControl;
  }

  get callerIdObjId() {
    return this.form.get('callerIdObjId') as UntypedFormControl;
  }
}
