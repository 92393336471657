import Joi from 'joi';

import { SchemaField } from '../../schema-field';
import { serializeToJsonString } from '../../serialize-to-json-string/serialize-to-json-string';

export const jsonStringSchema = (defaultValue?: string): Joi.StringSchema =>
  SchemaField.string(defaultValue, undefined, 10_000_000)
    .custom((value, helpers) => {
      try {
        const parsedValue = JSON.parse(value);
        const deterministicValue = serializeToJsonString(parsedValue);

        if (deterministicValue !== value) {
          return helpers.error('string.notDeterministic');
        }

        return value;
      } catch (e) {
        return helpers.error('string.invalidJson');
      }
    })
    .rule({
      message: {
        'string.notDeterministic': 'Value is not deterministic: "{#value}"',
        'string.invalidJson': 'Unable to parse JSON value: "{#value}"',
      },
    });
