import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, ViewChild } from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Calendar } from 'primeng/calendar';

import { FormGroupControlValueAccessor } from '../../../generic/abstract-classes/form-group-control-value-accessor';
import { DateRangeSelectOutput } from '../common/date-range-select-output';
import { makeDateArray } from '../common/make-date-array/make-date-array';
import { parseDateArray } from '../common/parse-date-array/parse-date-array';
import { DateRangeValidator } from '../validators/date-range-validator/date-range-validator';

const VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateRangeSelectComponent),
  multi: true,
};

const VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => DateRangeSelectComponent),
  multi: true,
};

@UntilDestroy()
@Component({
  selector: 'app-date-range-select',
  templateUrl: './date-range-select.component.html',
  styleUrls: ['./date-range-select.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VALUE_ACCESSOR, VALIDATOR],
})
export class DateRangeSelectComponent extends FormGroupControlValueAccessor<
  { dateRangeControl: Date[] | undefined } | undefined,
  DateRangeSelectOutput
> {
  @ViewChild(Calendar) private readonly calendarComponent!: Calendar;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Input
  /////////////////////////////////////////////////////////////////////////////////////////////

  @Input() label: string;

  @Input() showTime = false;

  @Input() required = false;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////
  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  public closeCalendarIfRangeIsValid(): void {
    if (this.dateRangeControl.valid) {
      this.calendarComponent.hideOverlay();
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////
  defineForm() {
    const formConfig = {} as any;
    formConfig.dateRangeControl = new UntypedFormControl(undefined, [DateRangeValidator.isComplete()]);
    this.form = new UntypedFormGroup(formConfig);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Write Value
  /////////////////////////////////////////////////////////////////////////////////////////////
  writeValue(input: DateRangeSelectOutput) {
    const dateRangeControl = makeDateArray(input);
    return super.writeValue({ dateRangeControl });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////
  parseValueChange(value: { dateRangeControl: Date[] | undefined }): DateRangeSelectOutput {
    const result = parseDateArray(this.dateRangeControl.value);
    return result;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Controls
  /////////////////////////////////////////////////////////////////////////////////////////////
  get dateRangeControl() {
    return this.form.get('dateRangeControl') as UntypedFormControl;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////
  public makeValidationErrors(): ValidationErrors {
    return {
      'date-range-select': this.form.value,
    };
  }
}
