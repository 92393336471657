import { cloneDeep, orderBy } from 'lodash';
import moment from 'moment-timezone';

/**
 * This class defines a half-open [start, end) interval, as well as
 * basic operations on it.
 */
export class Interval {
  private start!: number;

  private end!: number;

  ////////////////////////////////////////////////////////////////////////////////////
  // Constructor
  ////////////////////////////////////////////////////////////////////////////////////

  constructor(start: number | moment.Moment, end: number | moment.Moment) {
    this.start = valueOrMillis(start);
    this.end = valueOrMillis(end);

    if (typeof this.start !== 'number' || typeof this.end !== 'number' || this.start > this.end) {
      throw new Error(`Interval.constructor: User error: start=${start} end=${end}`);
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////
  // Disjoint
  ////////////////////////////////////////////////////////////////////////////////////

  /**
   * Return true if these intervals are pairwise disjoint, and false otherwise.
   * @param other
   * @returns
   */
  public static isPairwiseDisjoint(invervals: Interval[]) {
    const sortedByStart = orderBy(cloneDeep(invervals), [(z) => z.getStart().valueOf()], ['asc']);

    let lastInterval: Interval | undefined;
    for (const interval of sortedByStart) {
      if (lastInterval !== undefined && lastInterval.overlaps(interval)) {
        return false;
      }

      lastInterval = interval;
    }

    return true;
  }

  ////////////////////////////////////////////////////////////////////////////////////
  // Getters
  ////////////////////////////////////////////////////////////////////////////////////

  public getStart(): number {
    return this.start;
  }

  public getEnd(): number {
    return this.end;
  }

  ////////////////////////////////////////////////////////////////////////////////////
  // Length
  ////////////////////////////////////////////////////////////////////////////////////

  /**
   * Return the length of the interval
   * @returns
   */
  public getLength(): number {
    return this.getEnd() - this.getStart();
  }

  ////////////////////////////////////////////////////////////////////////////////////
  // Overlaps
  ////////////////////////////////////////////////////////////////////////////////////

  /**
   * Does this interval overlap another interval
   * @param other
   * @returns
   */
  public overlaps(other: Interval) {
    if (
      // Other ends before this starts
      other.getEnd() < this.getStart() ||
      // Other starts after this ends
      other.getStart() > this.getEnd()
    ) {
      return false;
    }
    if (this.isDirectlyAfterOrBefore(other)) {
      return false;
    }
    return true;
  }

  /**
   * Return true if the other interval is either directly before
   * or after this one, but not equal to this one.
   * @param other
   */
  public isDirectlyAfterOrBefore(other: Interval) {
    if (this.equals(other)) {
      return false;
    }
    if (other.getStart() === this.getEnd() || other.getEnd() === this.getStart()) {
      // Exclude the second endpoint because interval is half-open
      return true;
    }
    return false;
  }

  ////////////////////////////////////////////////////////////////////////////////////
  // Intersection
  ////////////////////////////////////////////////////////////////////////////////////

  /**
   * Return the inverval which represents the intersection
   * @param other
   * @returns
   */
  public intersection(other: Interval): Interval | undefined {
    const newStart = Math.max(this.getStart(), other.getStart());
    const newEnd = Math.min(this.getEnd(), other.getEnd());

    if (newStart > newEnd) {
      return undefined;
    }

    return new Interval(cloneDeep(newStart), cloneDeep(newEnd));
  }

  ////////////////////////////////////////////////////////////////////////////////////
  // Convex Hull
  ////////////////////////////////////////////////////////////////////////////////////

  /**
   * Return the minimal interval containing these two
   * @param other
   * @returns
   */
  public convexHull(other: Interval) {
    const start = Math.min(this.getStart(), other.getStart());
    const end = Math.max(this.getEnd(), other.getEnd());
    return new Interval(start, end);
  }

  ////////////////////////////////////////////////////////////////////////////////////
  // Contains
  ////////////////////////////////////////////////////////////////////////////////////

  /**
   * Return true if this interval contains the given value, and false otherwise
   * @param value
   * @returns
   */
  public contains(value: number | moment.Moment) {
    const _value = valueOrMillis(value);
    if (_value < this.start || _value > this.end) {
      return false;
    }

    // Interval is half-open [start, end)
    if (_value === this.end) {
      return false;
    }
    return true;
  }

  /**
   * Return true if this interval contains the given value
   * of if the value is the endpoint
   * @param value
   * @returns
   */
  public containsOrIsOnBoundary(value: number | moment.Moment) {
    const _value = valueOrMillis(value);
    if (_value < this.start || _value > this.end) {
      return false;
    }
    return true;
  }

  ////////////////////////////////////////////////////////////////////////////////////
  // Equals
  ////////////////////////////////////////////////////////////////////////////////////

  /**
   * Does this interval equal another interval
   * @param other
   * @returns
   */
  public equals(other: any) {
    if (!(other instanceof Interval)) {
      return false;
    }

    return this.getStart() === other.getStart() && this.getEnd() === other.getEnd();
  }
}

/**
 * If the given item is a moment, return it's value in milliseconds. Else, return the input
 * @param value moment or number.
 * @returns
 */
const valueOrMillis = (value: number | moment.Moment): number => {
  if (moment.isMoment(value)) {
    return value.valueOf();
  }
  return value;
};
