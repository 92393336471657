import { isNil } from 'lodash';

import { AssignedUserType, EventType, OrgData } from '@pwp-common';

import { WorkerEditorOutputItem } from '../interface';

export const makeEventOption = (
  assignedUserType: AssignedUserType,
  eventTypeId: string,
  orgData: OrgData,
): WorkerEditorOutputItem => {
  if (isNil(assignedUserType) || isNil(eventTypeId) || isNil(orgData)) {
    console.error(eventTypeId, eventTypeId, orgData);
    throw new Error('makeEventOption: User Error. The assignedUserType, eventTypeId, or orgData is missing.');
  }

  let eventType: EventType;
  try {
    eventType = orgData.getEventTypeWithInternalName(eventTypeId);
  } catch (error) {}

  if (isNil(eventType)) {
    return {
      type: 'deletedEvent',
      displayName: 'deletedEvent',
      eventTypeId,
      assignedUserType,
      searchString: `deletedEvent ${eventTypeId} ${assignedUserType}`,
    };
  }

  return {
    type: 'event',
    eventTypeId: eventType.getInternalName(),
    displayName: `${eventType.getDisplayName()}: ${assignedUserType}`,
    assignedUserType,
    searchString: `event ${eventType.getDisplayName()}: ${assignedUserType}`.toLowerCase(),
  };
};
