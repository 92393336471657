import { cloneDeep, isEmpty, isNil } from 'lodash';

import { ReservationOfferConfig, SupportedLanguages } from '@pwp-common';

import { defaultReservationOfferConfigEditorOutput } from '../../default-reservation-offer-config-editor-output';
import { ReservationOfferChannelConfigEditorOutput } from '../../objects/channel/reservation-offer-channel-config-editor-output';
import { dbToReservationOfferEditorOutput } from '../../objects/offers/reservation-offer/db-to-reservation-offer-editor-output/db-to-reservation-offer-editor-output';
import { dbToReservationSkipOffersIfEditorOutput } from '../../objects/reservation-skip-offers-if/db/db-to-reservation-skip-offers-if-editor-output/db-to-reservation-skip-offers-if-editor-output';
import { ReservationOfferConfigEditorOutput } from '../../reservation-offer-config-editor-output';

export const dbToReservationOfferConfigEditorOutput = (
  reservationOfferConfig: ReservationOfferConfig,
): ReservationOfferConfigEditorOutput => {
  /**
   * Handle empty input
   */
  if (isNil(reservationOfferConfig)) {
    return cloneDeep(defaultReservationOfferConfigEditorOutput);
  }

  let callerIdObjId = reservationOfferConfig.getOfferChannelConfigPhoneCall().getCallerIdObjId();
  if (isEmpty(callerIdObjId)) {
    callerIdObjId = reservationOfferConfig.getOfferChannelConfigText().getCallerIdObjId();
  }
  /**
   * Valid input
   */
  const channelConfig: ReservationOfferChannelConfigEditorOutput = {
    phoneCall: {
      dialTimeoutSeconds: reservationOfferConfig.getOfferChannelConfigPhoneCall().getDialTimeoutMS() / 1000,
      vrcAudioMetadataId: reservationOfferConfig.getOfferChannelConfigPhoneCall().getVrcAudioMetadataId(),
    },
    text: {
      body:
        reservationOfferConfig
          .getOfferChannelConfigText()
          .getBody()
          .getText()
          .get(SupportedLanguages.en.getShortCode()) ?? '',
    },
    callerIdObjId,
  };

  const result: ReservationOfferConfigEditorOutput = {
    channelConfig,
    offers: dbToReservationOfferEditorOutput(reservationOfferConfig),
    skipOffersIf: dbToReservationSkipOffersIfEditorOutput(reservationOfferConfig.getSkipOffersIf()),
  };
  return result;
};
