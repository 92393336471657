import { cloneDeep } from 'lodash';

import { DBDocSchema } from '../../../../generic/db-doc/db-doc-schema';
import { ServiceOperation } from '../../../../voice-response-command/service-operation/service-operation/service-operation';
import { CommunicationWidget } from '../../generic/communication-widget/communication-widget';
import { CommunicationWidgetSchema } from '../../generic/communication-widget/communication-widget-schema';
import { CommunicationWidgetName } from '../../generic/communication-widget-name';

import { CommunicationWidgetCreateAsyncServiceRequestConstructor } from './communication-widget-create-async-service-request-constructor';
import { CommunicationWidgetCreateAsyncServiceRequestSchema } from './communication-widget-create-async-service-request-schema';

export class CommunicationWidgetCreateAsyncServiceRequest extends CommunicationWidget {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////
  protected onComplete!: ServiceOperation;

  protected communicationWorkflowId!: string | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationWidgetCreateAsyncServiceRequestConstructor) {
    (parameters as any)[CommunicationWidgetSchema.type] = CommunicationWidgetName.createAsyncServiceRequest;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(
    validationResult: import('joi').ValidationResult,
  ): CommunicationWidgetCreateAsyncServiceRequest {
    return new CommunicationWidgetCreateAsyncServiceRequest(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationWidgetCreateAsyncServiceRequest.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new CommunicationWidgetCreateAsyncServiceRequestSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getCommunicationWorkflowId(): string | undefined {
    return cloneDeep(this.communicationWorkflowId);
  }

  public getOnComplete(): ServiceOperation {
    return cloneDeep(this.onComplete);
  }
}
