/* eslint-disable max-classes-per-file */
import type { AnySchema } from 'joi';

import { GenericDisplayableSchema } from '../../generic/displayable/generic-displayable-schema';
import { SchemaField } from '../../generic/serialization/schema-field';

export class VoicemailConfigSchema extends GenericDisplayableSchema {
  // Display Name
  public static readonly displayName = 'displayName';

  public static readonly description = 'description';

  // Notify the users specified in this call list that there is a new voicemail
  public static readonly notifyViaSMSOfVoicemailCallListId = 'notifyViaSMSOfVoicemailCallListId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly Constants = class {
    public static readonly collection = 'voicemailConfig';
  };

  public static readonly Defaults = class extends GenericDisplayableSchema.Defaults {
    public static readonly notifyViaSMSOfVoicemailCallListId = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: AnySchema } {
    const parameters = super.getSchemaDefinition();

    parameters[VoicemailConfigSchema.notifyViaSMSOfVoicemailCallListId] = SchemaField.string(
      VoicemailConfigSchema.Defaults.notifyViaSMSOfVoicemailCallListId,
    );

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${VoicemailConfigSchema.Constants.collection}`;
  }
}
