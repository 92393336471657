import { ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { isNil } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';

import {
  CalleeEntity,
  CallList,
  EventType,
  MessageDeliveryChannel,
  MessageTemplate,
  MessageTemplateContextType,
  MessageTemplateSchema,
  SchemaFieldConstants,
  SupportedLanguages,
  TemplateText,
} from '@pwp-common';

import { getCommaSeparatedValue, getFieldValuesFromFormInComponent } from '../../../../common/objects/form-helper';
import { KVPair } from '../../../../common/objects/kvpair';
import { CommaSepListValidator } from '../../../../common/validators/comma-sep-list-validator';
import { CallListService } from '../../../../services/call/call-list/call-list.service';
import { MessageTemplateService } from '../../../../services/text/message-template/message-template.service';
import { ConfigDocSelectAndEdit } from '../../../generic/abstract-classes/config-doc-select-and-edit';
import { TemplateTextLangPair } from '../generic/template-text-lang-pair';

@UntilDestroy()
@Component({
  selector: 'app-message-template-select-and-edit',
  templateUrl: './message-template-select-and-edit.component.html',
  styleUrls: ['./message-template-select-and-edit.component.css'],
})
export class MessageTemplateSelectAndEditComponent
  extends ConfigDocSelectAndEdit<MessageTemplate>
  implements OnInit, OnChanges, OnDestroy
{
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  form: UntypedFormGroup = new UntypedFormGroup({});

  eventTypes: EventType[];

  selectedEventTypes: KVPair<EventType>[];

  subjectArray: TemplateTextLangPair[] = [];

  bodyArray: TemplateTextLangPair[] = [];

  sendRRule: KVPair<string>;

  assignedUsers: CalleeEntity[];

  selectedCallListKVPair: KVPair<CallList>[];

  callListArray: CallList[];

  callListSubscription: Subscription;
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  possibleContexts = Object.keys(MessageTemplateContextType);

  possibleMessageDeliveryChannels = Object.keys(MessageDeliveryChannel);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private formBuilder: UntypedFormBuilder,
    // @ts-ignore
    private _dataService: MessageTemplateService,
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
    private callListService: CallListService,
  ) {
    super(MessageTemplate, _dataService, _changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Get Data
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getData() {
    super.getData();
    this.loading = true;
    this.eventTypes = this.orgData.getEventTypes();
    this.callListSubscription = this.callListService.getDocsArray().subscribe((callLists: CallList[]) => {
      this.callListArray = callLists;
    });
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parameters = getFieldValuesFromFormInComponent(
      this.selectedKVPair.value.getId(),
      [
        MessageTemplateSchema.displayName,
        MessageTemplateSchema.description,
        MessageTemplateSchema.context,
        MessageTemplateSchema.subject,
        MessageTemplateSchema.body,
        MessageTemplateSchema.replyTo,
        MessageTemplateSchema.messageDeliveryChannel,
        MessageTemplateSchema.sendEnabled,
        MessageTemplateSchema.callLists,
      ],
      MessageTemplateSchema.Defaults,
      this,
    );

    parameters[MessageTemplateSchema.subscribedUserIds] =
      this.selectedKVPair.value?.getSubscribedUserIds() || MessageTemplateSchema.Defaults.subscribedUserIds;

    parameters[MessageTemplateSchema.cc] = getCommaSeparatedValue(this.commaSepCC, MessageTemplateSchema.Defaults.cc);
    parameters[MessageTemplateSchema.bcc] = getCommaSeparatedValue(
      this.commaSepBCC,
      MessageTemplateSchema.Defaults.bcc,
    );
    parameters[MessageTemplateSchema.subscribedUserIds] = getCommaSeparatedValue(
      this.commaSepSubscribedUserIds,
      MessageTemplateSchema.Defaults.subscribedUserIds,
    );

    parameters[MessageTemplateSchema.eventTypes] =
      this.selectedEventTypes.map((z) => z.value?.getId()).filter((z) => !isNil(z)) ||
      MessageTemplateSchema.Defaults.eventTypes;

    parameters[MessageTemplateSchema.callLists] =
      this.selectedCallListKVPair?.map((z) => z.value?.getId()).filter((z) => !isNil(z)) ||
      MessageTemplateSchema.Defaults.callLists;

    // Subject
    const subject = new Map<string, string>();
    for (const item of this.subjectArray.filter((z) => !isNil(z))) {
      subject.set(item.languageDefaults.getShortCode(), item.templateText);
    }
    parameters[MessageTemplateSchema.subject] = new TemplateText({ text: subject });
    parameters[MessageTemplateSchema.sendRRule] = this.sendRRule?.value || MessageTemplateSchema.Defaults.sendRRule;

    // Body
    const body = new Map<string, string>();
    for (const item of this.bodyArray.filter((z) => !isNil(z))) {
      body.set(item.languageDefaults.getShortCode(), item.templateText);
    }
    parameters[MessageTemplateSchema.body] = new TemplateText({ text: body });

    // Assigned Users
    parameters[MessageTemplateSchema.assignedUsers] =
      this.assignedUsers || MessageTemplateSchema.Defaults.assignedUsers;

    const obj = new MessageTemplate(parameters);
    return obj;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: MessageTemplate) {
    // Init Form
    const formConfig = {} as any;
    formConfig[MessageTemplateSchema.displayName] = [
      obj.getDisplayName(),
      [Validators.required, Validators.maxLength(500)],
    ];
    formConfig[MessageTemplateSchema.description] = [
      obj.getDescription(),
      [Validators.required, Validators.maxLength(500)],
    ];
    formConfig[MessageTemplateSchema.context] = [obj.getContext(), [Validators.required, Validators.maxLength(500)]];
    formConfig[MessageTemplateSchema.sendEnabled] = [obj.getSendEnabled()];

    formConfig.commaSepCC = [obj!.getCC().join(','), [Validators.maxLength(500), CommaSepListValidator.email()]];
    formConfig.commaSepBCC = [obj!.getBCC().join(','), [Validators.maxLength(500), CommaSepListValidator.email()]];
    formConfig.commaSepSubscribedUserIds = [
      obj!.getSubscribedUserIds().join(','),
      [CommaSepListValidator.regex(SchemaFieldConstants.userIdRegex), CommaSepListValidator.noDuplicates()],
    ];

    formConfig[MessageTemplateSchema.replyTo] = [obj.getReplyTo(), [Validators.email, Validators.maxLength(500)]];
    formConfig[MessageTemplateSchema.sendRRule] = [obj.getSendRRule(), [Validators.maxLength(500)]];
    formConfig[MessageTemplateSchema.messageDeliveryChannel] = [
      obj.getMessageDeliveryChannel(),
      [Validators.required, Validators.maxLength(500)],
    ];

    formConfig[MessageTemplateSchema.callLists] = [obj!.getCallLists()?.join()];
    this.form = this.formBuilder.group(formConfig);

    // Init other state
    this.selectedEventTypes = obj
      .getEventTypes()
      .map((eventTypeId) => new KVPair({ id: eventTypeId, value: undefined }));

    this.sendRRule = new KVPair({ id: obj.getId(), value: obj.getSendRRule() });

    // Subject
    this.subjectArray = [];
    for (const [key, value] of obj.getSubject().getText().entries()) {
      const item = new TemplateTextLangPair(SupportedLanguages.getDefaults(key), value);
      this.subjectArray.push(item);
    }

    // Body
    this.bodyArray = [];
    for (const [key, value] of obj.getBody().getText().entries()) {
      const item = new TemplateTextLangPair(SupportedLanguages.getDefaults(key), value);
      this.bodyArray.push(item);
    }

    // Assigned Users
    this.assignedUsers = obj.getAssignedUsers();
    this.selectedCallListKVPair = obj
      ?.getCallLists()
      ?.map((callList) => new KVPair({ id: callList, value: undefined }));
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  get displayName(): AbstractControl | null {
    return this.form.get(MessageTemplateSchema.displayName);
  }

  get description(): AbstractControl | null {
    return this.form.get(MessageTemplateSchema.description);
  }

  get context() {
    return this.form.get(MessageTemplateSchema.context);
  }

  get commaSepCC() {
    return this.form.get('commaSepCC');
  }

  get commaSepBCC() {
    return this.form.get('commaSepBCC');
  }

  get commaSepSubscribedUserIds() {
    return this.form.get('commaSepSubscribedUserIds');
  }

  get replyTo() {
    return this.form.get(MessageTemplateSchema.replyTo);
  }

  get messageDeliveryChannel() {
    return this.form.get(MessageTemplateSchema.messageDeliveryChannel);
  }

  get sendEnabled() {
    return this.form.get(MessageTemplateSchema.sendEnabled);
  }

  get callLists() {
    return this.form.get(MessageTemplateSchema.callLists);
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObjScoper = (): boolean => this.isValidObj();

  isValidObj(): boolean {
    // This checks that the form is valid.
    if (!super.isValidObj()) {
      return false;
    }

    return true;
  }
}
