import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil, values } from 'lodash';

import { CommunicationTaskQueueSchema } from '@pwp-common';

import { FormGroupControlValueAccessor } from '../../../../../generic/abstract-classes/form-group-control-value-accessor';
import { formToTargetModeEditorOutput } from '../../editor-output/target-mode/form-to-target-mode-editor-output/form-to-target-mode-editor-output';
import { TargetModeEditorOutput } from '../../editor-output/target-mode/target-mode-editor-output';
import { TargetModeFormMode, TargetModeFormType } from '../../editor-output/target-mode/target-mode-form-type';
import { targetModeToFormType } from '../../editor-output/target-mode/target-mode-to-form-type/target-mode-to-form-type';

const VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TargetModeEditorComponent),
  multi: true,
};

const VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => TargetModeEditorComponent),
  multi: true,
};
@UntilDestroy()
@Component({
  selector: 'app-target-mode-editor',
  templateUrl: './target-mode-editor.component.html',
  styleUrls: ['./target-mode-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VALUE_ACCESSOR, VALIDATOR],
})
export class TargetModeEditorComponent extends FormGroupControlValueAccessor<
  TargetModeFormType,
  TargetModeEditorOutput
> {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  modes: { value: TargetModeFormMode }[] = values(TargetModeFormMode).map((z) => ({ value: z }));

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////
  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////
  defineForm() {
    this.form = new UntypedFormGroup({
      mode: new UntypedFormControl(undefined, [Validators.required]),
      maxReservedWorkers: new UntypedFormControl(1, [
        Validators.required,
        Validators.min(CommunicationTaskQueueSchema.Constants.maxReservedWorkersMin),
        Validators.max(CommunicationTaskQueueSchema.Constants.maxReservedWorkersMax),
      ]),
    });
    this.mode.valueChanges.pipe(untilDestroyed(this)).subscribe((nextValue: TargetModeFormMode) => {
      const currentValue: TargetModeFormMode = this.form.value.mode;

      // Do nothing if this mode was already selected
      if (isNil(nextValue) || currentValue === nextValue) {
        return;
      }

      switch (nextValue) {
        case TargetModeFormMode.oneAtATime: {
          this.maxReservedWorkers.setValue(1);
          break;
        }
        case TargetModeFormMode.severalAtATime: {
          this.maxReservedWorkers.setValue(Math.max(this.maxReservedWorkers?.value ?? 2, 2));
          break;
        }
      }
      this.updateUI();
    });
    this.updateUI();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Update UI
  /////////////////////////////////////////////////////////////////////////////////////////////

  private updateUI() {
    if (this.form.disabled) {
      return;
    }
    if (this.mode.value === TargetModeFormMode.severalAtATime) {
      this.maxReservedWorkers.enable();
    } else {
      this.maxReservedWorkers.disable();
    }
    this.changeDetectorRef.markForCheck();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Write Value
  /////////////////////////////////////////////////////////////////////////////////////////////

  writeValue(value: TargetModeEditorOutput) {
    if (isNil(value)) {
      return;
    }

    const formType = targetModeToFormType(value);
    super.writeValue(formType);
    this.updateUI();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  parseValueChange(value: TargetModeFormType): TargetModeEditorOutput {
    return formToTargetModeEditorOutput(value);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected makeValidationErrors() {
    return {
      'target-mode-editor': this.form.value,
    };
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form Controls
  ///////////////////////////////////////////////////////////////////////////////////////////
  get mode() {
    return this.form.get('mode') as UntypedFormControl;
  }

  get maxReservedWorkers() {
    return this.form.get('maxReservedWorkers') as UntypedFormControl;
  }
}
