import { upperFirst } from 'lodash';

import { ConfirmJoinIVRResponseStatus, DialedCallLog, DialedCallStatus, UserData } from '@pwp-common';

import { RowCell } from '../../../../common/p-table/row-cell';
import { StatusBadgeColor } from '../../../shared/status-badge/status-badge-color';
import { CommunicationSessionDialedCallLogBase } from '../base/communication-session-dialed-call-log-base';

export class DialedCallLogRow extends CommunicationSessionDialedCallLogBase<DialedCallLog> {
  //////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  //////////////////////////////////////////////////////////////////////////////////////////

  dialStatus: RowCell<string>;

  connectedToClient: RowCell<boolean>;

  //////////////////////////////////////////////////////////////////////////////////////////
  // Constructor
  //////////////////////////////////////////////////////////////////////////////////////////

  constructor({
    dialedCall,
    allDataUser,
    timezone,
  }: {
    dialedCall: DialedCallLog;
    allDataUser: Map<string, UserData>;
    timezone: string;
  }) {
    super({
      dialedCall,
      allDataUser,
      timezone,
      userId: dialedCall.getUserId(),
      userStatus: dialedCall.getUserStatus(),
    });
    this.setDialStatus(dialedCall);
    this.setConnectedToClient(dialedCall);
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Dial Status
  //////////////////////////////////////////////////////////////////////////////////////////
  private setDialStatus(dialedCall: DialedCallLog) {
    const status = dialedCall.getDialStatus() ?? '';

    this.dialStatus = new RowCell<string>({
      translationScope: 'communication-session-event-row',
      translationKey: `cellDialStatus${upperFirst(status)}`,
      translationObj: {},
      sortValue: status,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Connected To Client
  //////////////////////////////////////////////////////////////////////////////////////////

  private setConnectedToClient(dialedCall: DialedCallLog) {
    const value = dialedCall.getConnectedToClient();

    if (dialedCall.getConnectedToClient()) {
      this.connectedToClient = new RowCell<boolean>({
        translationScope: 'communication-session-event-row',
        translationKey: `cellConnectedToClientTrue`,
        translationObj: {},
        sortValue: value,
        statusBadgeColor: StatusBadgeColor.green,
      });
      return;
    }
    this.connectedToClient = new RowCell<boolean>({
      translationScope: 'communication-session-event-row',
      translationKey: `cellConnectedToClientFalse`,
      translationObj: {},
      sortValue: value,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Summary
  //////////////////////////////////////////////////////////////////////////////////////////

  protected setSummary(dialedCall: DialedCallLog) {
    super.setSummary(dialedCall, dialedCall.getUserStatus());

    const translationObj = super.getSummaryTranslationObj(dialedCall);

    //////////////////////////////////////////////////////////////////////
    // Case: Connected Calls
    //////////////////////////////////////////////////////////////////////

    if (dialedCall.getConnectedToClient()) {
      const translationKey = `cellSummaryConnectedToClient`;
      this.summary = new RowCell<string>({
        translationScope: 'communication-session-event-row',
        translationKey,
        translationObj,
        sortValue: translationKey,
        statusBadgeColor: StatusBadgeColor.green,
      });
      return;
    }

    //////////////////////////////////////////////////////////////////////
    // Case: Calls Answered By Human, But Not Connected
    //////////////////////////////////////////////////////////////////////

    const dialedCallStatus = dialedCall.getDialedCallStatus();
    const ivrResponseStatus = dialedCall.confirmJoinStatus().status;
    if (
      dialedCall.didDial() &&
      ivrResponseStatus !== ConfirmJoinIVRResponseStatus.noResponse &&
      [DialedCallStatus.completed, DialedCallStatus.completedAnsweredLive].includes(dialedCallStatus)
    ) {
      /**
       * If an IVR response was received and the call is complete but not connected
       * to the client, then display the IVR response for debugging purposes.
       */
      const _translationKey = `cellSummaryConfirmJoinStatus${upperFirst(ivrResponseStatus)}`;
      this.summary = new RowCell<string>({
        translationScope: 'communication-session-event-row',
        translationKey: _translationKey,
        translationObj,
        sortValue: _translationKey,
        statusBadgeColor: StatusBadgeColor.yellow,
      });
    }
  }
}
