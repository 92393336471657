import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogService } from '@ngneat/dialog';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import moment from 'moment-timezone';
import { MenuItem, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { BehaviorSubject, combineLatest, filter, map, Observable, shareReplay, switchMap } from 'rxjs';

import {
  AllDataEventDisplay,
  AllDataUser,
  DBUploadExistingDoc,
  EventData,
  EventDataTransactionDisplay,
  EventPropertiesBulkUpdate,
  makeSimpleUploadEventDataTransactionDisplay,
  OrgData,
  selectEventsWeeklyInFuture,
} from '@pwp-common';

import { editEventTransactionsInPlace } from '../../../../common/event/edit-event-transactions-in-place';
import { splitEventTransactionInPlace } from '../../../../common/event/split-event-transaction';
import { KeyType, KVPair } from '../../../../common/objects/kvpair';
import { EventsService } from '../../../../services/event/events/events.service';
import { RolesService } from '../../../../services/user/roles/roles.service';
import { ConfirmWithInputComponent } from '../../../generic/confirm-with-input/confirm-with-input.component';
import { EditEventPropertiesDialogComponent } from '../edit-event-properties-dialog/edit-event-properties-dialog.component';
import { EventCreateDialogComponent } from '../event-create-dialog/event-create-dialog.component';
import { EventSplitDialogComponent } from '../event-split-dialog/event-split-dialog.component';
import { RepeatScheduleDialogComponent } from '../repeat-schedule-dialog/repeat-schedule-dialog.component';

@UntilDestroy()
@Component({
  selector: 'app-event-surgery-table',
  standalone: true,
  imports: [
    ButtonModule,
    CardModule,
    CommonModule,
    FormsModule,
    MenuModule,
    MultiSelectModule,
    TableModule,
    ToastModule,
    TranslocoModule,
  ],
  templateUrl: './event-surgery-table.component.html',
  styleUrls: ['./event-surgery-table.component.scss'],
  providers: [MessageService],
})
export class EventSurgeryTableComponent {
  private readonly isAdmin$ = this.rolesService.getRoles().pipe(
    untilDestroyed(this),
    shareReplay({ bufferSize: 1, refCount: true }),
    map((z) => z.isOrgAdmin()),
  );

  /////////////////////////////////////////////////////////////////////////////////
  // Inputs
  /////////////////////////////////////////////////////////////////////////////////

  @Input() public allDataUserMap: Map<string, AllDataUser>;

  @Input() public orgData: OrgData;

  /////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////

  public dbTransactions: KVPair<EventDataTransactionDisplay>[] = [];

  public uploadInProgress = false;

  public tableLoading = false;

  public readonly cols = [
    'id',
    'startTime',
    'endTime',
    'type',
    'dbTransactionType',
    'color',
    'primaryUserDisplayName',
    'primaryUserEmail',
    'primaryUserPhone',
    'backupUserDisplayName',
    'backupUserEmail',
    'backupUserPhone',
  ].map((colName) => ({
    label: this.translocoService.translate(`event-surgery-table.${colName}`),
    value: colName,
  }));

  public readonly selectedCols = [
    'startTime',
    'endTime',
    'type',
    'dbTransactionType',
    'color',
    'primaryUserDisplayName',
    'backupUserDisplayName',
  ];

  public readonly selection$ = new BehaviorSubject<KVPair<EventDataTransactionDisplay>[]>([]);

  public readonly eventSelectionActions$: Observable<MenuItem[]> = combineLatest({
    isAdmin: this.isAdmin$,
    selection: this.selection$,
  }).pipe(
    map(({ isAdmin, selection }) => [
      {
        label: this.translocoService.translate('event-surgery-table.editEventPropertiesAction'),
        command: () => this.editEventPropertiesAction(),
      },
      {
        label: this.translocoService.translate('event-surgery-table.removeAction'),
        command: () => this.removeSelectedEventsAction(),
      },
      {
        label: this.translocoService.translate('event-surgery-table.splitAction'),
        command: () => this.splitSelectedEventsAction(),
        disabled: selection.length !== 1,
      },
      {
        label: this.translocoService.translate('event-surgery-table.repeatWeeklyAction'),
        command: () => this.openRepeatWeeklyDialog(),
      },
      {
        label: this.translocoService.translate('event-surgery-table.deleteAction'),
        command: () => this.confirmAndDelete(),
        disabled: this.cantBeDeleted() || !isAdmin,
      },
    ]),
  );

  /////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////

  constructor(
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private messageService: MessageService,
    private dialogService: DialogService,
    private eventsService: EventsService,
    private translocoService: TranslocoService,
    private rolesService: RolesService,
  ) {}

  /////////////////////////////////////////////////////////////////////////////////
  // IDs
  /////////////////////////////////////////////////////////////////////////////////

  private removeIds(ids: Set<KeyType>) {
    this.tableLoading = true;
    this.dbTransactions = this.dbTransactions.filter((value) => !ids.has(value.id));
    this.selection$.next(this.selection$.value.filter((value) => !ids.has(value.id)));
    this.tableLoading = false;
  }

  private getSelectedIds(): Set<KeyType> {
    return new Set(this.selection$.value.map((z) => z.getId()));
  }

  /////////////////////////////////////////////////////////////////////////////////
  // Events
  /////////////////////////////////////////////////////////////////////////////////

  private getSelectedEvents(): EventData[] {
    return this.getSelectedTransactions().map((transaction) => {
      if (!transaction.isSimpleUpdate()) {
        throw new Error('getSelectedEvents: User Error the update isnt simple.');
      }

      const [action] = transaction.actions as DBUploadExistingDoc<EventData>[];
      return action.obj;
    });
  }

  /**
   * This method differs from addEvent on because it doesn't call
   * the change detector. Calling the change detector many times results in 10+ seconds of
   * delay if editing 24+ events on a Macbook Air.
   *
   * @param eventDisplayObj
   * @param overwriteExisting
   */
  private addEventFromAllDataEventDisplay(params: {
    eventDisplayObj: AllDataEventDisplay;
    overwriteExisting?: boolean;
  }): void {
    const eventData = params.eventDisplayObj.allDataEvent.getEventData();
    this.addEventFromEventData({ eventData, overwriteExisting: params.overwriteExisting ?? true });
  }

  /**
   * Warning, do not call change detector in this method! See comments in
   * addEventFromAllDataEventDisplay
   *
   * @param eventData
   * @param overwriteExisting
   * @returns
   */
  private addEventFromEventData(params: {
    eventData: EventData;
    overwriteExisting?: boolean;
    skipNotifications?: boolean;
  }): void {
    const id = params.eventData.getId();
    const index = this.indexOfTransactionWithId(id);
    const indexInSelection = this.indexOfTransactionWithId(id, this.selection$.value);

    console.log('E2E debug: addEventFromEventData', { id, index, indexInSelection });

    const overwriteExisting = params.overwriteExisting ?? true;
    if (!params.skipNotifications && !overwriteExisting && index >= 0) {
      this.showWarnAlreadyAdded();
      return;
    }

    const transaction = makeSimpleUploadEventDataTransactionDisplay(
      this.allDataUserMap,
      this.orgData,
      params.eventData,
    );

    const kvPair = new KVPair<EventDataTransactionDisplay>({ id, value: transaction });

    if (!(index >= 0)) {
      this.dbTransactions.push(kvPair);
      this.selection$.next([...this.selection$.value, kvPair]);
      if (!params.skipNotifications) {
        this.showEventAddedMessage();
      }
      return;
    }

    if (!(indexInSelection >= 0)) {
      this.selection$.value[indexInSelection] = kvPair;
    }

    if (!params.skipNotifications) {
      this.showEventUpdatedMessage();
    }
    this.dbTransactions[index] = kvPair;
  }

  public createEventsAction(start: moment.Moment, end: moment.Moment, type: string) {
    const dialogRef = this.dialog.open(EventCreateDialogComponent, {
      hasBackdrop: true,
      minWidth: '50%',
      data: {
        start,
        end,
        type,
        orgData: this.orgData,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.changeDetectorRef.detectChanges());
  }

  private getFutureEventsForRepeatWeekly(maxStart: moment.Moment): Observable<EventData[] | undefined> {
    if (this.getSelectedIds().size === 0) {
      return undefined;
    }

    // Determine the start date of the given list of events
    const events = this.getSelectedEvents();
    const minStart = moment.min(events.map((z) => z.getStart()));

    const types = Array.from(new Set(events.map((z) => z.getType())));

    // Double check that there is exactly one event type
    if (types.length !== 1) {
      throw new Error('This component assumes that only one type is specified.');
    }
    const type = types[0];

    return this.eventsService.getEventsWithStartInRange(minStart, maxStart, type);
  }

  private async repeatWeekly(endDate: moment.Moment) {
    if (isNil(endDate) || !moment.isMoment(endDate)) {
      return;
    }

    // Step 1: Get Future events
    const futureEvents = await this.getFutureEventsForRepeatWeekly(endDate).toPromise();

    // Make the db updates
    const updates = selectEventsWeeklyInFuture(this.getSelectedTransactions(), futureEvents);
    updates.forEach((eventData) =>
      this.addEventFromEventData({ eventData, overwriteExisting: false, skipNotifications: true }),
    );
    this.showSelectionRepeatedWeeklyAddedMessage(updates?.length ?? 0);
    this.changeDetectorRef.detectChanges();
  }

  /////////////////////////////////////////////////////////////////////////////////
  // Upload
  /////////////////////////////////////////////////////////////////////////////////

  private async upload() {
    this.uploadInProgress = true;
    const promises: Promise<KeyType>[] = [];

    this.dbTransactions.forEach(async (dbTransaction) => {
      if (isNil(dbTransaction.value)) {
        this.removeIds(new Set([dbTransaction.id]));
        return;
      }

      promises.push(
        this.eventsService.runDBTransaction(dbTransaction.value!).then(() => {
          console.log(`Finished running transaction with id: ${dbTransaction.id}`);
          return dbTransaction.id;
        }),
      );
    });

    const successfulKeys = await Promise.all(promises);
    this.removeIds(new Set(successfulKeys));

    this.uploadInProgress = false;
  }

  /////////////////////////////////////////////////////////////////////////////////
  // Transactions
  /////////////////////////////////////////////////////////////////////////////////

  private indexOfTransactionWithId(eventId: string, arr = this.dbTransactions): number {
    for (let i = 0; i < arr.length; i += 1) {
      const kvPair = arr[i];
      if (kvPair.getId() === eventId) {
        return i;
      }
    }

    return -1;
  }

  private getSelectedTransactions(): EventDataTransactionDisplay[] {
    const selectedIds = this.getSelectedIds();

    return this.dbTransactions.reduce<EventDataTransactionDisplay[]>((acc, transaction) => {
      if (!selectedIds.has(transaction.id)) {
        return acc;
      }

      if (!transaction.value?.isSimpleUpdate()) {
        throw new Error('getSelectedTransactions: Selected transaction is not simple.');
      }

      return [...acc, transaction.value];
    }, []);
  }

  private cantBeDeleted() {
    try {
      return this.getSelectedTransactions().some((z) => !z.isSimpleUpdate());
    } catch (error) {
      return true;
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Notifications
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  private successMessage() {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: '',
    });
  }

  private failureMessage(error: unknown) {
    console.error(error);
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: JSON.stringify(error),
    });
  }

  private showEventAddedMessage() {
    const eventAddedToTable = this.translocoService.translate('event-surgery-table.eventAddedToTable');
    const howToUseTable = this.translocoService.translate('event-surgery-table.howToUseTable');

    this.messageService.add({
      severity: 'info',
      summary: eventAddedToTable,
      detail: howToUseTable,
    });
  }

  private showSelectionRepeatedWeeklyAddedMessage(num: number) {
    const title = this.translocoService.translate('event-surgery-table.selectionRepeatedWeeklyAddedToTableTitle', {
      num,
    });
    const message = this.translocoService.translate('event-surgery-table.selectionRepeatedWeeklyAddedToTableMessage');

    this.messageService.add({
      severity: 'info',
      summary: title,
      detail: message,
    });
  }

  private showEventUpdatedMessage() {
    const eventUpdatedInTable = this.translocoService.translate('event-surgery-table.eventUpdatedInTable');
    const howToUseTable = this.translocoService.translate('event-surgery-table.howToUseTable');

    this.messageService.add({
      severity: 'info',
      summary: eventUpdatedInTable,
      detail: howToUseTable,
    });
  }

  private showWarnAlreadyAdded() {
    const alreadyAddedToTable = this.translocoService.translate('event-surgery-table.alreadyAddedToTable');
    const howToUseTable = this.translocoService.translate('event-surgery-table.howToUseTable');

    this.messageService.add({
      severity: 'warn',
      summary: alreadyAddedToTable,
      detail: howToUseTable,
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // UI
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  private async deleteSelection() {
    this.tableLoading = true;
    const eventIdsToDelete = this.getSelectedEvents().map((z) => z.getId());
    console.log(eventIdsToDelete);
    await this.eventsService.deleteDocs(eventIdsToDelete);
    this.removeSelectedEventsAction();
    this.successMessage();
    this.changeDetectorRef.detectChanges();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Public methods
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public addEvent(eventDisplayObj: AllDataEventDisplay, overwriteExisting = true): void {
    console.log('E2E debug: addEvent', { eventDisplayObj, overwriteExisting });
    this.addEventFromAllDataEventDisplay({ eventDisplayObj, overwriteExisting });
    this.changeDetectorRef.detectChanges();
  }

  public addEvents({
    start,
    end,
    events,
    type,
  }: {
    start: moment.Moment;
    end: moment.Moment;
    events: AllDataEventDisplay[];
    type: string;
  }): void {
    console.log(`Selected range: ${start.format()} to ${end.format()}`);
    if (events.length === 0) {
      this.createEventsAction(start, end, type);
      return;
    }

    events.forEach((eventDisplayObj) =>
      this.addEventFromAllDataEventDisplay({ eventDisplayObj, overwriteExisting: false }),
    );
    this.changeDetectorRef.detectChanges();
  }

  public editEventPropertiesAction() {
    const userDataArray = Array.from(this.allDataUserMap?.values() || []).map((z) => z.userData);
    this.dialog
      .open(EditEventPropertiesDialogComponent, {
        hasBackdrop: true,
        minWidth: '50%',
        data: {
          userDataArray,
        },
      })
      .afterClosed()
      .pipe(
        filter((update) => !isNil(update)),
        untilDestroyed(this),
      )
      .subscribe((update: EventPropertiesBulkUpdate) => {
        editEventTransactionsInPlace(
          this.dbTransactions,
          this.orgData,
          this.getSelectedIds(),
          this.allDataUserMap,
          update,
        );
        this.changeDetectorRef.detectChanges();
      });
  }

  public removeSelectedEventsAction() {
    const selectedIds = this.getSelectedIds();
    this.removeIds(selectedIds);
    this.selection$.next([]);
  }

  public clearTable() {
    this.selection$.next(this.dbTransactions);
    this.removeSelectedEventsAction();
  }

  public splitSelectedEventsAction() {
    const eventData = this.getSelectedEvents()[0];

    this.dialog
      .open(EventSplitDialogComponent, {
        hasBackdrop: true,
        minWidth: '50%',
        data: {
          start: eventData.getStart(),
          end: eventData.getEnd(),
          orgData: this.orgData,
        },
      })
      .afterClosed()
      .pipe(
        filter((splitTime) => !isNil(splitTime)),
        untilDestroyed(this),
      )
      .subscribe((splitTime: moment.Moment) => {
        console.log('Split Time', splitTime);
        splitEventTransactionInPlace(
          this.dbTransactions,
          this.orgData,
          this.getSelectedIds(),
          this.allDataUserMap,
          splitTime,
        );
        this.changeDetectorRef.detectChanges();
      });
  }

  public openRepeatWeeklyDialog() {
    this.dialog
      .open(RepeatScheduleDialogComponent, {
        hasBackdrop: true,
        minWidth: '50%',
      })
      .afterClosed()
      .pipe(
        switchMap((endDate?: moment.Moment) => this.repeatWeekly(endDate)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  public confirmAndDelete() {
    const title = this.translocoService.translate('event-surgery-table.confirmDeleteTitle', {
      num: this.selection$.value.length,
    });
    const body = this.translocoService.translate('event-surgery-table.confirmDeleteBody', {
      num: this.selection$.value.length,
    });

    this.dialog
      .open(ConfirmWithInputComponent, {
        hasBackdrop: true,
        minWidth: '30%',
        restoreFocus: false,
        data: {
          title,
          body,
          requiredConfirmationString: '1', // this.selection.length.toString()
        },
      })
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap(() => this.deleteSelection()),
        untilDestroyed(this),
      )
      .subscribe();
  }

  public confirmAndUpload() {
    const title = this.translocoService.translate('event-surgery-table.confirmUploadTitle');
    const body = this.translocoService.translate('event-surgery-table.confirmUploadBody');

    this.dialogService
      .confirm({ title, body })
      .afterClosed$.pipe(
        filter(Boolean),
        switchMap(() => this.upload()),
        untilDestroyed(this),
      )
      .subscribe({
        next: () => this.successMessage(),
        error: (error) => this.failureMessage(error),
      });
  }
}
