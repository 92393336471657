import Joi from 'joi';

import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { EventRequestTransactionSchema } from '../../requests/event-request-transaction/event-request-transaction-schema';

export class AnyEventRequestSchema extends SerializableObjectSchema {
  // The type of the entity
  public static readonly type = 'type';

  private static readonly SCHEMA_ID = 'anyEventRequest';

  private static computedSchema = Joi.alternatives()
    .try(...[new EventRequestTransactionSchema().getJoiSchema()])
    .id(AnyEventRequestSchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////
  static GenericDefaults = class {
    // The name of the command
    public static readonly type = undefined;
  };

  /**
   * Always serialize the command name
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  public static getSchemaId(): string {
    return `#${AnyEventRequestSchema.SCHEMA_ID}`;
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } | import('joi').AnySchema {
    return AnyEventRequestSchema.computedSchema;
  }
}
