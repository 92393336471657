<ng-container *transloco="let t; read: 'make-event-request-table'">
  <p-toast position="bottom-center"></p-toast>
  <p-card *ngrxLet="eventRequestConfigs$ as eventRequestConfigs" class="selector-card">
    <ng-template pTemplate="content">
      <app-data-table
        [cols]="cols"
        [loading]="loading"
        [refreshClicked]="getDataWrapper"
        [selectedCols]="cols"
        [tableRows]="tableRows$"
        [title]="t('title')"
        [translationScope]="'make-event-request-table'"
      >
        <ng-container
          *transloco="let tDecision; read: 'make-event-request-table.decision'"
          appDataTableColumnDef="decision"
        >
          <th *appDataTableHeader>{{ t('colDecision') }}</th>
          <td *appDataTableCell="let rowData">
            <ng-container *ngrxLet="requestedSlotForm.controls[rowData.id]?.value as slot">
              {{
                tDecision(
                  {
                    slot,
                    eventRequestConfig: eventRequestConfigs[rowData.eventType],
                    allDataEvent: rowData.allDataEvent,
                  } | eventRequestDecision
                )
              }}
            </ng-container>
          </td>
        </ng-container>
        <ng-container appDataTableColumnDef="requestedSlot">
          <th *appDataTableHeader>{{ t('colRequestedSlot') }}</th>
          <td *appDataTableCell="let rowData">
            <app-requested-slot-select
              *ngIf="requestedSlotForm.controls[rowData.id] as slotControl"
              [eventRequestConfig]="eventRequestConfigs[rowData.eventType]"
              [formControl]="slotControl"
              [rowData]="rowData"
            >
            </app-requested-slot-select>
          </td>
        </ng-container>
        <ng-container appDataTableColumnDef="remove">
          <th *appDataTableHeader>{{ t('colRemove') }}</th>
          <td *appDataTableCell="let rowData">
            <div class="make-event-request-table__cell--centered">
              <button
                class="p-button-rounded p-button-danger p-button-text"
                pButton
                type="button"
                (click)="onRowRemoved(rowData)"
              >
                <i class="pi pi-trash"></i>
              </button>
            </div>
          </td>
        </ng-container>
      </app-data-table>
    </ng-template>
    <ng-template pTemplate="footer">
      <button
        class="p-button-raised"
        pButton
        [disabled]="selectedAllDataEventDisplay.size === 0 || uploadingRequest"
        label="{{ t('actionButtonTitle', { num: selectedAllDataEventDisplay.size }) }}"
        [loading]="uploadingRequest"
        (click)="submit()"
      ></button>
    </ng-template>
  </p-card>
</ng-container>
