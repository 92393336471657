import { SchemaField } from '../../../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../../../generic/serialization/serializable-object-schema';
import { ServiceLimitServiceUsageThreshold } from '../../../service-limit-service-usage-threshold/service-limit-service-usage-threshold';

export abstract class ServiceLimitEnforcementStrategySchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly enforce = 'enforce';

  public static readonly type = 'type';

  public static readonly threshold = 'threshold';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly enforce = undefined;

    public static readonly type = undefined;

    public static readonly threshold = ServiceLimitServiceUsageThreshold.deserialize({});
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([ServiceLimitEnforcementStrategySchema.type, ServiceLimitEnforcementStrategySchema.threshold]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[ServiceLimitEnforcementStrategySchema.enforce] = SchemaField.boolean(
      ServiceLimitEnforcementStrategySchema.Defaults.enforce,
    ).required();
    parameters[ServiceLimitEnforcementStrategySchema.type] = SchemaField.string(
      ServiceLimitEnforcementStrategySchema.type,
      ServiceLimitEnforcementStrategySchema.Defaults.type,
    );
    parameters[ServiceLimitEnforcementStrategySchema.threshold] = SchemaField.obj(
      ServiceLimitServiceUsageThreshold,
      ServiceLimitEnforcementStrategySchema.Defaults.threshold,
    );
    /* eslint-enable */

    return parameters;
  }
}
