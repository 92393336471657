import { SchemaField } from '../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';
import { AnyDBMatchRule } from '../db-match-rule/generic/any-db-match-rule/any-db-match-rule';

export class DBMatcherSchema extends SerializableObjectSchema {
  public static readonly queryIds = 'queryIds';

  public static readonly rules = 'rules';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly queryIds = undefined;

    public static readonly rules = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[DBMatcherSchema.queryIds] = SchemaField.shortStringArray(DBMatcherSchema.Defaults.queryIds).required();
    parameters[DBMatcherSchema.rules] = SchemaField.shortObjArray(
      AnyDBMatchRule,
      DBMatcherSchema.Defaults.rules,
    ).required();
    /* eslint-enable */

    return parameters;
  }
}
