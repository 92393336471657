<ng-container *transloco="let t; read: 'conversation-getting-started'">
  <ng-template #loading>
    <p-progressSpinner></p-progressSpinner>
  </ng-template>

  <ng-container *ngrxLet="data$ as data; suspenseTpl: loading">
    <legend>{{ t('title') }}</legend>
    <span>{{ t('howTo') }}</span>

    <div class="conversation-getting-started__fieldset">
      {{ t('chatURLDescription') }}
      <div class="p-inputgroup">
        <input pInputText type="text" [disabled]="true" [size]="data.chatUrl.length" [value]="data.chatUrl" />
        <button icon="pi pi-copy" pButton pRipple type="button" [cdkCopyToClipboard]="data.chatUrl"></button>
      </div>
    </div>

    <div class="conversation-getting-started__fieldset">
      <span>
        {{ t('webchatSnippet') }}
      </span>
      <code #code class="conversation-getting-started__webchat-snippet">
        &lt;script&gt;
        <br />
        &nbsp;&nbsp;window.helplineSoftware = &#123; orgId: '{{ data.orgId }}' &#125;;
        <br />
        &lt;/script&gt;
        <br />
        &lt;script src="{{ webchatScriptURL }}" type="module"&gt;&lt;/script&gt;
        <button
          class="conversation-getting-started__webchat-snippet-copy-button p-button-rounded p-button-text p-button-icon-only"
          icon="pi pi-copy"
          pButton
          type="button"
          [cdkCopyToClipboard]="code.textContent"
        ></button>
      </code>
    </div>
  </ng-container>
</ng-container>
