import moment from 'moment-timezone';

import { GLOBAL_TIMEZONE } from '../../../../helper/constants';
import { Interval } from '../../../../helper/interval';

import { EntityStatsDisplayWindow } from './enum';

export const getIntervalForWindow = (window: EntityStatsDisplayWindow): Interval => {
  let start: moment.Moment;
  let end: moment.Moment;

  switch (window!) {
    case EntityStatsDisplayWindow.lastMonth: {
      start = moment.tz(GLOBAL_TIMEZONE).subtract(1, 'month').startOf('month');
      end = moment.tz(GLOBAL_TIMEZONE).subtract(1, 'month').endOf('month');
      break;
    }
    case EntityStatsDisplayWindow.lastQuarter: {
      start = moment.tz(GLOBAL_TIMEZONE).subtract(1, 'quarter').startOf('quarter');
      end = moment.tz(GLOBAL_TIMEZONE).subtract(1, 'quarter').endOf('quarter');
      break;
    }
    case EntityStatsDisplayWindow.lastYear: {
      start = moment.tz(GLOBAL_TIMEZONE).subtract(1, 'year').startOf('year');
      end = moment.tz(GLOBAL_TIMEZONE).subtract(1, 'year').endOf('year');
      break;
    }
    case EntityStatsDisplayWindow.thisMonth: {
      start = moment.tz(GLOBAL_TIMEZONE).startOf('month');
      end = moment.tz(GLOBAL_TIMEZONE).endOf('month');
      break;
    }
    case EntityStatsDisplayWindow.thisQuarter: {
      start = moment.tz(GLOBAL_TIMEZONE).startOf('quarter');
      end = moment.tz(GLOBAL_TIMEZONE).endOf('quarter');
      break;
    }
    case EntityStatsDisplayWindow.thisYear: {
      start = moment.tz(GLOBAL_TIMEZONE).startOf('year');
      end = moment.tz(GLOBAL_TIMEZONE).endOf('year');
      break;
    }
    case EntityStatsDisplayWindow.past: {
      start = moment.tz(0, GLOBAL_TIMEZONE);
      end = moment.tz(GLOBAL_TIMEZONE);
      break;
    }
    case EntityStatsDisplayWindow.custom: {
      start = moment.tz(0, GLOBAL_TIMEZONE);
      end = moment.tz().add(1, 'years').endOf('year');
    }
  }
  return new Interval(start, end);
};
