import { Conversation } from '@twilio/conversations';

export const joinConversation = async (twilioConversation: Conversation): Promise<void> => {
  const { status } = twilioConversation;
  const logMetadata = { sid: twilioConversation.sid, status, state: twilioConversation.state };
  console.log('joinConversation: Starting', logMetadata);
  if (status === 'notParticipating') {
    if (twilioConversation.state.current === 'closed') {
      console.error('joinConversation: User Error. Attempted to join a closed conversation', logMetadata);
      return;
    }
    await twilioConversation.join();
    return;
  }
  console.log('joinConversation: Nothing to do. Already joined conversation.', logMetadata);
};
