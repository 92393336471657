import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import {
  AnyVoiceResponseCommand,
  DBAction,
  DBCreateDoc,
  DBTransaction,
  DBTransactionType,
  DBUploadExistingDoc,
  EnforceServiceLimit,
  EnforceServiceLimitSchema,
  isNilOrDefaultDocId,
  ServiceLimit,
  ServiceOperation,
  VoiceResponseCommand,
  VoiceResponseCommandName,
} from '@pwp-common';

import { EnforceServiceLimitEditorOutput } from '../../../components/core/service-operation-editor/editor-output/enforce-service-limit-editor-output';
import { ServiceOperationEditorOutput } from '../../../components/core/service-operation-editor/editor-output/service-operation-editor-output';

////////////////////////////////////////////////////////////////////////////////////
// Service
////////////////////////////////////////////////////////////////////////////////////

@Injectable({
  providedIn: 'root',
})
export class ServiceOperationService {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(private db: AngularFirestore) {}

  ///////////////////////////////////////////////////////////////////////
  // Get Service Operation
  ///////////////////////////////////////////////////////////////////////

  public getServiceOperation(editorResult: ServiceOperationEditorOutput): {
    serviceOperation: ServiceOperation;
    serviceLimitTransaction: DBTransaction<ServiceLimit>;
  } {
    const commands: VoiceResponseCommand[] = [];
    const serviceLimitDBActions: DBAction<ServiceLimit>[] = [];

    for (const editedCommand of editorResult?.commands ?? []) {
      switch (editedCommand.commandName) {
        case VoiceResponseCommandName.enforceServiceLimit: {
          const serializedCommand = {} as any;
          const { serviceLimit } = editedCommand as EnforceServiceLimitEditorOutput;
          if (isNilOrDefaultDocId(serviceLimit.getId())) {
            serviceLimit.setId(this.db.createId());
            serviceLimitDBActions.push(new DBCreateDoc(serviceLimit));
          } else {
            serviceLimitDBActions.push(new DBUploadExistingDoc(serviceLimit));
          }
          serializedCommand[EnforceServiceLimitSchema.commandName] = VoiceResponseCommandName.enforceServiceLimit;
          serializedCommand[EnforceServiceLimitSchema.serviceLimitId] = serviceLimit.getId();
          const parsed = EnforceServiceLimit.deserialize(serializedCommand);
          commands.push(parsed);
          break;
        }
        default: {
          const parsed = AnyVoiceResponseCommand.deserialize(editedCommand);
          commands.push(parsed);
        }
      }
    }
    return {
      serviceOperation: new ServiceOperation({ commands }),
      serviceLimitTransaction: new DBTransaction<ServiceLimit>(DBTransactionType.update, serviceLimitDBActions),
    };
  }
}
