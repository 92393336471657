import { SchemaField } from '../../generic/serialization/schema-field';
import { VoiceResponseCommandName } from '../any-voice-response-command/voice-response-command-name';
import { VoiceResponseCommandSchema } from '../generic/voice-response-command-schema';

export class FlagServiceRequestSchema extends VoiceResponseCommandSchema {
  public static readonly deny = 'deny';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly commandName = VoiceResponseCommandName.flagServiceRequest;

    public static readonly deny = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } | import('joi').AnySchema {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[VoiceResponseCommandSchema.commandName] = SchemaField.string(
      FlagServiceRequestSchema.Defaults.commandName,
    );
    parameters[FlagServiceRequestSchema.deny] = SchemaField.boolean(FlagServiceRequestSchema.Defaults.deny).required();

    /* eslint-enable */

    return parameters;
  }
}
