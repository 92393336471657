<ng-container *transloco="let t; read: 'vrc-editor-forward'">
  <form *ngIf="!loading" [formGroup]="form">
    <label class="flex flex-column w-fit gap-2 mb-2">
      <span>{{ t('toLabel') }}</span>

      <input formControlName="to" pInputText [placeholder]="t('toPlaceholder')" />

      <div *ngIf="to.touched && to.errors as errors">
        <span *ngIf="errors.required" class="p-error">
          {{ t('toErrorRequired') }}
        </span>
        <span *ngIf="errors.pattern" class="p-error">
          {{ t('toErrorPattern') }}
        </span>
      </div>
    </label>
  </form>

  <app-obj-is-valid *ngIf="!loading" [isValidObj]="isValidObj"></app-obj-is-valid>
</ng-container>
