import { DBDocSchema } from '../generic/db-doc/db-doc-schema';
import { SchemaField } from '../generic/serialization/schema-field';

export class ReleaseVersionSchema extends DBDocSchema {
  public static readonly latestGitCommitHash = 'latestGitCommitHash';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////
  static Constants = class {
    public static readonly collection = 'config';

    public static readonly docId = 'version';

    public static readonly refreshTimeMilliseconds = 1000 * 60 * 60 * 3;
  };

  static Defaults = class {
    public static readonly latestGitCommitHash = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[ReleaseVersionSchema.latestGitCommitHash] = SchemaField.string(
      ReleaseVersionSchema.Defaults.latestGitCommitHash,
    );
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(): string {
    return `${ReleaseVersionSchema.Constants.collection}`;
  }

  public getDoc(): string {
    return `${this.getCollection()}/${ReleaseVersionSchema.Constants.docId}`;
  }
}
