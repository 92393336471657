import { TimeSelection } from '../../../../core/time-selection/time-selection/time-selection';
import { VRCIfTime } from '../../../voice-response-command-if-time/voice-response-command-if-time';
import { CallEntrypoint } from '../../call-entrypoint';

export const getTimeSelectionFromCallEntrypoint = (callEntrypoint: CallEntrypoint): TimeSelection | null => {
  const ifTimeCommand = callEntrypoint.getCommands().find((command) => command instanceof VRCIfTime) as
    | VRCIfTime
    | undefined;

  return ifTimeCommand?.getTimeSelection() ?? null;
};
