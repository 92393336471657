import { translate } from '@ngneat/transloco';

import { EntityStats } from '@pwp-common';

import { createReportRow } from '../create-report-row/create-report-row';
import { createValuesForEntityStats } from '../create-values-for-entity-stats/create-values-for-entity-stats';
import { formatUsersWithCount } from '../formatting/format-users-with-count';
import { getUsersWithHighestCount } from '../get-users-with-highest-count/get-users-with-highest-count';
import { AdminReportEventStatsReportRowInput, ReportRow } from '../interfaces';

export const createUserWhoTookTheMostEventsRow = ({
  entityStats,
  usersStatsData,
  eventType,
}: AdminReportEventStatsReportRowInput): ReportRow =>
  createReportRow(
    'userWhoTookTheMostEvents',
    createValuesForEntityStats(entityStats, (_, period) => {
      const getUserEventsCount = (userStats: EntityStats) =>
        userStats.getChunkByPeriod(period)?.getNumEvents(eventType) ?? 0;

      const usersWithMostCalls = getUsersWithHighestCount(usersStatsData, getUserEventsCount);
      const suffix = translate('admin-report-row.userWhoTookTheMostEvents.cellSuffix');

      return formatUsersWithCount(usersWithMostCalls, suffix);
    }),
    { eventType },
  );
