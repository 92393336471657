import { SchemaField } from '../../../../generic/serialization/schema-field';
import { ServiceOperation } from '../../../../voice-response-command/service-operation/service-operation/service-operation';
import { CommunicationWidgetSchema } from '../../generic/communication-widget/communication-widget-schema';

export class CommunicationWidgetCreateAsyncServiceRequestSchema extends CommunicationWidgetSchema {
  public static readonly onComplete = 'onComplete';

  public static readonly communicationWorkflowId = 'communicationWorkflowId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;

    public static readonly description = 'missing-description';

    public static readonly displayName = 'missing-displayName';

    public static readonly onComplete = undefined;

    public static readonly communicationWorkflowId = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationWidgetCreateAsyncServiceRequestSchema.onComplete] = SchemaField.obj(
      ServiceOperation,
      CommunicationWidgetCreateAsyncServiceRequestSchema.Defaults.onComplete,
    ).required();
    parameters[CommunicationWidgetCreateAsyncServiceRequestSchema.communicationWorkflowId] =
      SchemaField.autoGeneratedDocId(
        CommunicationWidgetCreateAsyncServiceRequestSchema.Defaults.communicationWorkflowId,
      ).optional();
    /* eslint-enable */

    return parameters;
  }
}
