import { cloneDeep } from 'lodash';

import { RequestObj } from '../../generic/request/request-obj';

import { ExternalChatRequestActionType } from './external-chat-action';
import { ExternalChatRequestConstructor } from './external-chat-request-constructor';
import { ExternalChatRequestSchema } from './external-chat-request-schema';

export class ExternalChatRequest extends RequestObj {
  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ExternalChatRequestConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected action!: ExternalChatRequestActionType;

  protected orgId!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ExternalChatRequest {
    return new ExternalChatRequest(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(ExternalChatRequest.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): ExternalChatRequestSchema {
    return new ExternalChatRequestSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getAction(): ExternalChatRequestActionType {
    return cloneDeep(this.action);
  }

  public getOrgId(): string {
    return cloneDeep(this.orgId);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  sanityCheck() {}
}
