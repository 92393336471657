import { values } from 'lodash';

import { SchemaField } from '../../../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../../../generic/serialization/serializable-object-schema';
import { CommunicationTaskQueueWorkerListName } from '../communication-task-queue-worker-list-name';

export abstract class CommunicationTaskQueueWorkerListSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The name of the command
  public static readonly type = 'type';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[CommunicationTaskQueueWorkerListSchema.type] = SchemaField.string(
      CommunicationTaskQueueWorkerListSchema.type,
      CommunicationTaskQueueWorkerListSchema.Defaults.type,
    )
      .required()
      .valid(...values(CommunicationTaskQueueWorkerListName));
    /* eslint-enable */

    return parameters;
  }
}
