export class LabeledData {
  private labels: string[];

  private data: number[];

  constructor(labels: string[], data: number[]) {
    this.labels = labels;
    this.data = data;
  }

  public getLabels(): string[] {
    return this.labels;
  }

  public getData(): number[] {
    return this.data;
  }
}
