import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';

import { CommunicationLogParticipant } from '../../communication/communication-log-participant/communication-log-participant';
import { DBDocObject } from '../../generic/db-doc/db-doc-object';
import { DBDocSchema } from '../../generic/db-doc/db-doc-schema';
import { OperatorMessageStatus, ORDERED_OPERATOR_MESSAGE_SEND_STATUSES } from '../operator-message-status';

import { SentMessageConstructor } from './sent-message-constructor';

export abstract class SentMessage extends DBDocObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected errorCode!: number | undefined;

  protected from!: string;

  protected messageSid!: string;

  protected numMedia!: number;

  protected numSegments!: number;

  protected operatorTimestamps!: Map<OperatorMessageStatus, moment.Moment>;

  protected participant!: CommunicationLogParticipant;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: SentMessageConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  static getSchema(): DBDocSchema {
    throw new Error('SentMessage.getSchema: UserError: This function should never be called.');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getErrorCode() {
    return cloneDeep(this.errorCode);
  }

  public getFrom() {
    return cloneDeep(this.from);
  }

  public getMessageSid() {
    return cloneDeep(this.messageSid);
  }

  public getNumMedia() {
    return cloneDeep(this.numMedia);
  }

  public getNumSegments() {
    return cloneDeep(this.numSegments);
  }

  public getOperatorTimestamps() {
    return cloneDeep(this.operatorTimestamps);
  }

  public getParticipant() {
    return cloneDeep(this.participant);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Complex Getters
  /////////////////////////////////////////////////////////////////////////////

  public getOperatorMessageStatus() {
    for (let i = ORDERED_OPERATOR_MESSAGE_SEND_STATUSES.length - 1; i >= 0; i--) {
      const status = ORDERED_OPERATOR_MESSAGE_SEND_STATUSES[i];
      if (this.operatorTimestamps.has(status)) {
        return cloneDeep(status);
      }
    }
    return undefined;
  }
}
