<!-- Dial Call List Command -->
<form *ngIf="!loading" [formGroup]="form">
  <!-- Language -->
  <mat-form-field class="full-width">
    <mat-label>Language</mat-label>
    <mat-select formControlName="language">
      <mat-option *ngFor="let language of possibleLanguages" [value]="language.getShortCode()">{{
        language.getDisplayName()
      }}</mat-option>
    </mat-select>
    <mat-error *ngIf="language.errors?.required"> Language is required </mat-error>
  </mat-form-field>
</form>

<!-- Is Valid Indicator -->
<app-obj-is-valid *ngIf="!loading" [isValidObj]="isValidObjScoper"></app-obj-is-valid>
