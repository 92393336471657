import { values } from 'lodash';

import { DBDocSchema } from '../../generic/db-doc/db-doc-schema';
import { SchemaField } from '../../generic/serialization/schema-field';
import { ConversationSummary } from '../conversation-summary/conversation-summary';

import { ConversationState } from './conversation-log-state';

export class ConversationLogSchema extends DBDocSchema {
  public static readonly chatServiceSid = 'chatServiceSid';

  public static readonly conversationConfigId = 'conversationConfigId';

  public static readonly conversationSid = 'conversationSid';

  public static readonly messagingServiceSid = 'messagingServiceSid';

  public static readonly summary = 'summary';

  public static readonly closeTime = 'closeTime';

  public static readonly firstUserMessageTime = 'firstUserMessageTime';

  /**
   * This is the current state of conversation. Used to answer questions like:
   * - Is service request still unfulfilled?
   * - Is conversation closed?
   * - Does data need to be deleted?
   */
  public static readonly state = 'state';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'conversationLogs';
  };

  static Defaults = class {
    public static readonly chatServiceSid = undefined;

    public static readonly conversationConfigId = undefined;

    public static readonly conversationSid = undefined;

    public static readonly messagingServiceSid = undefined;

    public static readonly summary = undefined;

    public static readonly state = undefined;

    public static readonly closeTime = undefined;

    public static readonly firstUserMessageTime = undefined;
  };

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[ConversationLogSchema.chatServiceSid] = SchemaField.string(
      ConversationLogSchema.Defaults.chatServiceSid,
    );
    parameters[ConversationLogSchema.conversationConfigId] = SchemaField.string(
      ConversationLogSchema.Defaults.conversationConfigId,
    ).required();
    parameters[ConversationLogSchema.conversationSid] = SchemaField.string(
      ConversationLogSchema.Defaults.conversationSid,
    ).required();
    parameters[ConversationLogSchema.messagingServiceSid] = SchemaField.string(
      ConversationLogSchema.Defaults.messagingServiceSid,
    );
    parameters[ConversationLogSchema.state] = SchemaField.string(ConversationLogSchema.Defaults.state)
      .allow(...values(ConversationState))
      .required();

    parameters[ConversationLogSchema.summary] = SchemaField.obj(
      ConversationSummary,
      ConversationLogSchema.Defaults.summary,
    );
    parameters[ConversationLogSchema.closeTime] = SchemaField.timestamp(ConversationLogSchema.Defaults.closeTime);
    parameters[ConversationLogSchema.firstUserMessageTime] = SchemaField.timestamp(
      ConversationLogSchema.Defaults.firstUserMessageTime,
    );
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${ConversationLogSchema.Constants.collection}`;
  }
}
