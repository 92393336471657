import { AngularFireFunctions } from '@angular/fire/compat/functions';
import * as Sentry from '@sentry/angular';
import pRetry from 'p-retry';
import { firstValueFrom, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SerializableObject, SerializableObjectBuilder } from '@pwp-common';

export abstract class CallableFunctionService<
  RequestObject extends SerializableObject,
  ResponseObject extends SerializableObject,
> {
  ///////////////////////////////////////////////////////////////////////
  // Protected Methods
  ///////////////////////////////////////////////////////////////////////

  protected callable: (data: any) => Observable<any>;

  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(
    private responseRef: SerializableObjectBuilder<SerializableObject>,
    private fns: AngularFireFunctions,
  ) {}

  ///////////////////////////////////////////////////////////////////////
  // Make Request OLD
  ///////////////////////////////////////////////////////////////////////

  public makeRequestOLD(requestObject: RequestObject): Observable<ResponseObject> {
    this.callable = this.fns.httpsCallable(this.getFunctionName());
    const serializedRequest = (requestObject as any).serialize();
    const observable = this.callable(serializedRequest).pipe(
      map((serializedResponse, _) => {
        const deserializedResponse = this.responseRef.deserialize(serializedResponse);
        return deserializedResponse;
      }),
      catchError((err, caught) => {
        console.error(`CallableFunctionService.makeRequest: ${this.getLoggingInfo()}: Error`);
        console.error(requestObject);
        console.error('err');
        console.error(err);
        console.error('caught');
        console.error(caught);
        throw err;
      }),
    );

    return observable;
  }

  ///////////////////////////////////////////////////////////////////////
  // Make Request
  ///////////////////////////////////////////////////////////////////////

  public async makeRequest<T extends ResponseObject>(
    requestObject: RequestObject,
    retries?: { retryTimeoutMS?: number; numRetries?: number; log?: boolean },
  ): Promise<T> {
    const loggingObj = { ...this.getLoggingObj(), requestObject };
    this.callable = this.fns.httpsCallable(this.getFunctionName(), {
      // We've initially set the timeout high defensively, to handle cold starts.
      timeout: retries?.retryTimeoutMS ?? 30 * 1000,
    });
    const serializedRequest = (requestObject as any).serialize();
    const promiseGenerator = () => firstValueFrom(this.callable(serializedRequest));

    const result = await pRetry(
      async (attemptNum: number): Promise<T> => {
        console.log('CallableFunctionService.makeRequest: Attempt', { ...loggingObj, attemptNum });
        if (retries?.log) {
          Sentry.addBreadcrumb({
            message: `Attempt ${attemptNum}`,
            category: this.getFunctionName(),
            level: 'info',
            data: { ...loggingObj, attemptNum },
          });
        }
        try {
          const serializedResponse = await promiseGenerator();
          const deserializedResponse = this.responseRef.deserialize(serializedResponse);

          return deserializedResponse;
        } catch (attemptError) {
          if (retries?.log) {
            Sentry.addBreadcrumb({
              message: `Attempt ${attemptNum} / Error`,
              category: this.getFunctionName(),
              level: 'error',
              data: { ...loggingObj, attemptNum, attemptError },
            });
          }
          Sentry.captureException(attemptError);
          console.warn(`CallableFunctionService.makeRequest: Error`, { ...loggingObj, attemptNum, attemptError });
          throw attemptError;
        }
      },
      {
        retries:
          // We've initially set the number of retries to 5 defensively, to handle cold starts which may happen due to congestion
          retries?.numRetries ?? 5,
      },
    );

    return result;
  }

  ///////////////////////////////////////////////////////////////////////
  // Logging
  ///////////////////////////////////////////////////////////////////////

  /**
   * Return parameters appending to beginning of some logging statements for
   * debugging purposes
   */
  protected getLoggingInfo(): string {
    return `function=${this.getFunctionName()}`;
  }

  protected getLoggingObj(): Record<string, any> {
    return { functionName: this.getFunctionName() };
  }

  ///////////////////////////////////////////////////////////////////////
  // Abstract Functions
  ///////////////////////////////////////////////////////////////////////

  /**
   * Get the function name;
   */
  public abstract getFunctionName(): string;
}
