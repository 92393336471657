import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { minutesToMilliseconds, secondsToMilliseconds } from 'date-fns';
import { isNil, upperFirst } from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeWhile,
  tap,
  timer,
} from 'rxjs';

import { ReportsService } from '../../../../services/reports/reports.service';
import { DateRangeSelectOutput } from '../../../core/date-range-select/common/date-range-select-output';
import { MakeTableDataOutput } from '../table-rows/make-table-data/interfaces';
import { makeTableData } from '../table-rows/make-table-data/make-table-data';

@UntilDestroy()
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportComponent {
  protected readonly loading$ = new BehaviorSubject(false);

  public readonly dateRange = new FormControl<Partial<DateRangeSelectOutput>>({});

  public readonly displayLoadingDialog$ = combineLatest([this.loading$, this.dateRange.valueChanges]).pipe(
    map(([loading, range]) => loading && !isNil(range?.start ?? range?.end)),
    startWith(false),
  );

  public readonly remainingLoadingTime$ = this.displayLoadingDialog$.pipe(
    switchMap((displayLoadingDialog) => (displayLoadingDialog ? timer(0, secondsToMilliseconds(1)) : EMPTY)),
    map((secondsPassed) => minutesToMilliseconds(5) - secondsToMilliseconds(secondsPassed)),
    takeWhile((time) => time >= 0),
  );

  public readonly tableData$ = this.dateRange.valueChanges.pipe(
    startWith(this.dateRange.value),
    filter((dateRange) => !isNil(dateRange)),
    tap(() => this.loading$.next(true)),
    switchMap((range) => this.reportsService.getDataForReport(range)),
    map((input) => makeTableData(input)),
    tap((tableData) => ['en', 'es'].map((lang) => this.addColumnTranslations(tableData, lang))),
    tap(() => this.loading$.next(false)),
    /*
      Note: in practice (when running the app), this can be just a `share` instead of `shareReplay`, because `getData` will take some time to complete
      However, in testing, we mock the services to return immediately, so using `share` makes `tableRows$` a late subscriber which does not get the first emission from `tableData$`
      Both `shareReplay` and `share` can be removed if we refactor `DataTableComponent` accept `tableRows` as an array instead of an observable, then using the `async` pipe here (on the consumer) instead
      This would be possible because we could then use a single `tableData$` observable and read its properties (rows, cols) in the template with a single subscription
    */
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public readonly tableCols$ = this.tableData$.pipe(
    map(({ cols }) => cols),
    startWith([]),
  );

  public readonly tableRows$ = this.tableData$.pipe(map(({ rows }) => rows));

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private reportsService: ReportsService,
    private translocoService: TranslocoService,
  ) {}

  private addColumnTranslations(tableData: MakeTableDataOutput, lang: string): void {
    tableData.cols.forEach((col) => {
      this.translocoService.setTranslationKey(`admin-reports.${col.header}`, upperFirst(col.field), lang, {
        emitChange: false,
      });
    });
  }
}
