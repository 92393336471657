<ng-container *transloco="let t; read: 'obj-autocomplete-form-control'">
  <p-autoComplete
    appendTo="body"
    field="displayName"
    [dropdown]="true"
    dropdownAriaLabel="{{ dropdownAriaLabel ?? t('autocompleteDropdownLabel') }}"
    [formControl]="control"
    [ngClass]="{ 'ng-invalid': control?.invalid }"
    [placeholder]="placeholder"
    [suggestions]="suggestedOptions$ | async"
    (completeMethod)="search($event)"
  >
    <ng-template pTemplate="item" let-value>
      <div *ngIf="itemTemplate">
        <ng-container
          [ngTemplateOutlet]="selectedItemTemplate"
          [ngTemplateOutletContext]="{ $implicit: value, item: value?.value, id: value?.id }"
        >
        </ng-container>
      </div>
      <div *ngIf="itemTemplate === undefined">
        <b>{{ value.getDisplayName() }}</b>
        <br />
        <small>{{ value.getDescription() }}</small>
      </div>
    </ng-template>
    <ng-template pTemplate="selectedItem" let-value>
      <div *ngIf="selectedItemTemplate">
        <ng-container
          [ngTemplateOutlet]="selectedItemTemplate"
          [ngTemplateOutletContext]="{ $implicit: value, item: value?.value, id: value?.id }"
        >
        </ng-container>
      </div>
      <div *ngIf="selectedItemTemplate === undefined">
        <b>{{ value.getDisplayName() }}</b>
      </div>
    </ng-template>
  </p-autoComplete>
  <p *ngIf="control.hasError('objIdNotInList')" class="p-error">{{ t('controlErrorObjIdNotInList') }}</p>
</ng-container>
