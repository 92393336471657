<ng-container *transloco="let t; read: 'wizard-steps'">
  <p-card>
    <p-toast position="bottom-center"></p-toast>
    <p-steps [model]="items" [readonly]="stepsReadonly" [(activeIndex)]="activeIndex"></p-steps>
  </p-card>
  <p-card>
    <ng-template pTemplate="title">
      {{ items[activeIndex].label }}
    </ng-template>
    <ng-template pTemplate="content">
      <ng-content></ng-content>
    </ng-template>
    <ng-template pTemplate="footer">
      <div>
        <div class="row">
          <div class="col flex justify-content-start align-items-end flex-wrap">
            <p-button
              *ngIf="previousButtonVisible"
              icon="pi pi-angle-left"
              styleClass="p-button-secondary"
              label="{{ t('previousButtonLabel') }}"
              (onClick)="previousClick()"
            ></p-button>
          </div>
          <div *ngIf="nextButtonVisible" class="col flex justify-content-end flex-wrap">
            <p-button
              icon="pi pi-angle-right"
              iconPos="right"
              styleClass="p-button-primary"
              [disabled]="nextButtonDisabled"
              label="{{ t('nextButtonLabel') }}"
              (onClick)="nextClick()"
            ></p-button>
          </div>
          <div *ngIf="completeButtonVisible" class="col flex justify-content-end flex-wrap">
            <p-button
              icon="pi pi-check"
              iconPos="left"
              styleClass="p-button-primary"
              type="button"
              [disabled]="nextButtonDisabled"
              label="{{ completeLabel ?? t('completeButtonLabel') }}"
              [loading]="completeLoadingIndicator"
              (onClick)="completeClick()"
            ></p-button>
          </div>
        </div>
      </div>
    </ng-template>
  </p-card>
</ng-container>
