import { cloneDeep } from 'lodash';

import { DBDocSchema } from '../../../generic/db-doc/db-doc-schema';
import { GenericDisplayable } from '../../../generic/displayable/generic-displayable';
import { isNilOrDefaultDocId } from '../../../generic/serialization/is-nil-or-default-doc-id';
import { CommunicationWorkerSchema } from '../../communication-worker/communication-worker/generic/communication-worker/communication-worker-schema';
import { CommunicationWorkerAttributesTaskQueueSchema } from '../../communication-worker/communication-worker-attributes/communication-worker-attributes-task-queue/communication-worker-attributes-task-queue-schema';
import { ReservationOfferConfig } from '../../reservation-offer/reservation-offer-config/reservation-offer-config';
import { CommunicationTaskQueueWorkerList } from '../communication-task-queue-worker-list/generic/communication-task-queue-worker-list/communication-task-queue-worker-list';

import { CommunicationTaskQueueConstructor } from './communication-task-queue-constructor';
import { CommunicationTaskQueueSchema } from './communication-task-queue-schema';

export class CommunicationTaskQueue extends GenericDisplayable {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////
  protected maxReservedWorkers!: number;

  protected reservationOfferConfig!: ReservationOfferConfig;

  protected sid!: string | undefined;

  protected workers!: CommunicationTaskQueueWorkerList[];

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationTaskQueueConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationTaskQueue {
    return new CommunicationTaskQueue(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationTaskQueue.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new CommunicationTaskQueueSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
  public getMaxReservedWorkers(): number {
    return cloneDeep(this.maxReservedWorkers);
  }

  public getReservationOfferConfig(): ReservationOfferConfig {
    return cloneDeep(this.reservationOfferConfig);
  }

  public getSid(): string | undefined {
    return cloneDeep(this.sid);
  }

  public getWorkers(): CommunicationTaskQueueWorkerList[] {
    return cloneDeep(this.workers);
  }

  public getNumWorkerSlots(): number {
    const numSlots = (this.getWorkers() ?? []).map((z) => z.getNumWorkerSlots()).reduce((x, y) => x + y);
    return numSlots;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Target Worker Expression
  /////////////////////////////////////////////////////////////////////////////

  public getTwilioTargetWorkerExpression(): string | undefined {
    if (isNilOrDefaultDocId(this.getId())) {
      throw new Error(
        'getTwilioTargetWorkerExpression: User Error: This object has no id. Cannot generate a target worker expression',
      );
    }

    // Escaping is required if the id starts with a number.
    return `${CommunicationWorkerSchema.taskQueueAttributes}.\\${this.getId()}.${CommunicationWorkerAttributesTaskQueueSchema.index} >= 0`;
  }
}
