import { isNil } from 'lodash';

import { CallEntrypoint, getTimeSelectionFromCallEntrypoint } from '@pwp-common';

import { RowCell } from '../../../../../common/p-table/row-cell';
import { StatusBadgeColor } from '../../../../../components/shared/status-badge/status-badge-color';

import { CallEntrypointTableRow, CreateCallEntrypointTableRowInput } from './interface';

export const createCallEntrypointTableRow = ({
  callEntrypoint,
}: CreateCallEntrypointTableRowInput): CallEntrypointTableRow => ({
  id: callEntrypoint.getId(),
  displayName: callEntrypoint.getDisplayName(),
  description: callEntrypoint.getDescription(),
  availableHours: createAvailableHoursCell(callEntrypoint),
  phones: callEntrypoint.getE164Phones().join(', '),
  callEntrypoint,
});

const createAvailableHoursCell = (callEntrypoint: CallEntrypoint): RowCell<unknown> => {
  const hasSpecificAvailableHours = !isNil(getTimeSelectionFromCallEntrypoint(callEntrypoint));

  return new RowCell({
    statusBadgeColor: hasSpecificAvailableHours ? StatusBadgeColor.yellow : StatusBadgeColor.green,
    translationKey: 'availableHours',
    sortValue: hasSpecificAvailableHours,
    translationScope: 'phone-line-table-editor',
    translationObj: { hasSpecificAvailableHours },
  });
};
