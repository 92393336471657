<p-toast position="bottom-center"></p-toast>

<mat-card>
  <!-- Header -->
  <mat-card-header>
    <mat-card-title>
      <h2>Submit A Ticket</h2>
      <p>
        Have a question? Experiencing any technical difficulties? Please provide all details below, including examples
        for us to troubleshoot, and we’ll be in touch shortly.
      </p>
    </mat-card-title>
  </mat-card-header>

  <!-- Content -->
  <mat-card-content>
    <form *ngIf="!loading" [formGroup]="form">
      <!-- Subject -->
      <mat-form-field class="full-width">
        <textarea class="full-width" formControlName="subject" matInput placeholder="Subject"></textarea>
        <span *ngIf="subject.invalid && subject.touched"> Required Field </span>
      </mat-form-field>

      <!-- Comment -->
      <mat-form-field class="full-width">
        <textarea class="full-width" formControlName="comment" matInput placeholder="Comment"></textarea>
        <span *ngIf="comment.invalid && comment.touched"> Required Field </span>
      </mat-form-field>

      <!-- Org Impact -->
      <mat-form-field class="full-width">
        <mat-label>Org Impact</mat-label>
        <mat-select formControlName="orgImpact">
          <mat-option [value]="'low'"
            >P3: ANYTHING ELSE - You have a question about Helpline Software or how to use it's products</mat-option
          >
          <mat-option [value]="'high'"
            >P2: DEGRADED SERVICE- Helpline Software or your configuration is not functioning properly or
            intermittently.</mat-option
          >
          <mat-option [value]="'urgent'"
            >P1: ORGANIZATION LEVEL CRITICAL OUTAGE - Helpline Software or your configuration is experiencing a complete
            organizational level outage
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <p *ngIf="form?.valid && orgImpact?.value !== 'urgent'">
      We strive to provide excellent service, and will respond to your request as soon as possible, based on perceived
      priority. For more detailed information please see
      <a
        href="https://helplinesoftware.zendesk.com/hc/en-us/articles/4416346354843-What-are-the-Support-Response-Times-"
        rel="noopener noreferrer"
        target="_blank"
        >our help center article on support priority and response times.</a
      >
    </p>
  </mat-card-content>

  <!-- Actions -->
  <mat-card-actions>
    <div *ngIf="orgImpact?.value === 'urgent'">
      <b
        >If your organization is experiencing a critical outages, please do not submit a ticket. Instead, please call
        415-763-0745.</b
      >
    </div>
    <button
      color="primary"
      mat-button
      [disabled]="!form?.valid || orgImpact?.value === 'urgent'"
      (click)="submitRequest()"
    >
      Submit
    </button>
  </mat-card-actions>
</mat-card>
