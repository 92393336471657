import { cloneDeep } from 'lodash';

import { SerializableObject } from '../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../generic/serialization/serializable-object-schema';

import { CallerIdStrategyConstructor } from './caller-id-strategy-constructor';
import { CallerIdStrategySchema } from './caller-id-strategy-schema';
import { CallerIdStrategyType } from './caller-id-strategy-type';

export class CallerIdStrategy extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected type!: CallerIdStrategyType;

  protected callerIdObjId!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CallerIdStrategyConstructor) {
    super(parameters);
  }
  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is protected, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CallerIdStrategy {
    return new CallerIdStrategy(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(CallerIdStrategy.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CallerIdStrategySchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getType() {
    return cloneDeep(this.type);
  }

  public getCallerIdObjId() {
    return cloneDeep(this.callerIdObjId);
  }
}
