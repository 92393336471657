import { cloneDeep, isNil } from 'lodash';

import { defaultReservationOfferEditorOutput } from '../default-reservation-offer-editor-output';
import { ReservationOfferEditorOutput } from '../reservation-offer-editor-output';

import { ReservationOfferFormType } from './reservation-offer-form-type';

/**
 * For a given formType return a sensible value of AllDataCommunicationWorkflowEditorTargetMode.
 */
export const formToReservationOfferEditorOutput = (
  formType: ReservationOfferFormType,
): ReservationOfferEditorOutput[] => {
  if (isNil(formType?.offers)) {
    return [cloneDeep(defaultReservationOfferEditorOutput)];
  }

  return formType.offers;
};
