import Joi from 'joi';

import { GenericDisplayableSchema } from '../../../generic/displayable/generic-displayable-schema';
import { SchemaField } from '../../../generic/serialization/schema-field';
import { ReservationOfferConfig } from '../../reservation-offer/reservation-offer-config/reservation-offer-config';
import { AnyCommunicationTaskQueueWorkerList } from '../communication-task-queue-worker-list/generic/any-communication-task-queue-worker-list/any-communication-task-queue-worker-list';

export class CommunicationTaskQueueSchema extends GenericDisplayableSchema {
  public static readonly maxReservedWorkers = 'maxReservedWorkers';

  public static readonly reservationOfferConfig = 'reservationOfferConfig';

  public static readonly sid = 'sid';

  public static readonly workers = 'workers';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'communicationTaskQueues';

    public static readonly maxReservedWorkersMin = 1;

    public static readonly maxReservedWorkersMax = 50;
  };

  static Defaults = class {
    public static readonly description = 'missing-description';

    public static readonly displayName = 'missing-displayName';

    public static readonly maxReservedWorkers = undefined;

    public static readonly reservationOfferConfig = undefined;

    public static readonly sid = undefined;

    public static readonly workers = undefined;
  };

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationTaskQueueSchema.maxReservedWorkers] = (
      SchemaField.number(CommunicationTaskQueueSchema.Defaults.maxReservedWorkers).required() as Joi.NumberSchema
    )
      .min(CommunicationTaskQueueSchema.Constants.maxReservedWorkersMin)
      .max(CommunicationTaskQueueSchema.Constants.maxReservedWorkersMax);
    parameters[CommunicationTaskQueueSchema.reservationOfferConfig] = SchemaField.obj(
      ReservationOfferConfig,
      CommunicationTaskQueueSchema.Defaults.reservationOfferConfig,
    ).required();
    parameters[CommunicationTaskQueueSchema.sid] = SchemaField.string(
      CommunicationTaskQueueSchema.Defaults.sid,
    ).optional();
    parameters[CommunicationTaskQueueSchema.workers] = SchemaField.shortObjArray(
      AnyCommunicationTaskQueueWorkerList,
      CommunicationTaskQueueSchema.Defaults.workers,
    );
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${CommunicationTaskQueueSchema.Constants.collection}`;
  }
}
