<ng-template #objEditor>
  <form *ngIf="!loading" [formGroup]="form">
    <div class="form-row">
      <div class="col">
        <!-- Display Name -->
        <mat-form-field class="full-width">
          <textarea class="full-width" formControlName="displayName" matInput placeholder="Display Name"></textarea>
          <span *ngIf="displayName.invalid && displayName.touched"> Display Name Invalid </span>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Description -->
        <mat-form-field class="full-width">
          <textarea class="full-width" formControlName="description" matInput placeholder="Description"></textarea>
          <span *ngIf="description.invalid && description.touched"> Description Invalid </span>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Context -->
        <mat-form-field class="full-width">
          <mat-label>Context</mat-label>
          <mat-select formControlName="context">
            <mat-option *ngFor="let v of possibleContexts" [value]="v">{{ v }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Call List Select -->
        <app-obj-multi-select
          [label]="'Call List Select'"
          [orderedItems]="callListArray"
          [(kvPairs)]="selectedCallListKVPair"
          (kvPairsChange)="updateObjAndEmit()"
        >
        </app-obj-multi-select>
      </div>
    </div>

    <div class="form-row">
      <div class="col">
        <!-- Event Types -->
        <app-obj-multi-select
          [label]="'Event Types'"
          [orderedItems]="eventTypes"
          [(kvPairs)]="selectedEventTypes"
          (kvPairsChange)="updateObjAndEmit()"
        ></app-obj-multi-select>
      </div>
      <div class="col">
        <!-- CC -->
        <mat-form-field class="full-width">
          <textarea
            class="full-width"
            formControlName="commaSepCC"
            matInput
            placeholder="Comma Separated CC"
          ></textarea>
          <mat-error *ngIf="commaSepCC?.invalid && commaSepCC?.touched">
            Invalid Emails: {{ commaSepCC.errors.invalidEmails }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- BCC -->
        <mat-form-field class="full-width">
          <textarea
            class="full-width"
            formControlName="commaSepBCC"
            matInput
            placeholder="Comma Separated BCC"
          ></textarea>
          <mat-error *ngIf="commaSepBCC?.invalid && commaSepBCC?.touched">
            Invalid Emails: {{ commaSepBCC.errors.invalidEmails }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col">
        <!-- Send Enabled -->
        <mat-checkbox class="full-width" formControlName="sendEnabled">Send Enabled</mat-checkbox>
      </div>
    </div>

    <div class="form-row">
      <div class="col">
        <!-- Message Delivery Channel -->
        <mat-form-field class="full-width">
          <mat-label>Message Delivery Channel</mat-label>
          <mat-select formControlName="messageDeliveryChannel">
            <mat-option *ngFor="let v of possibleMessageDeliveryChannels" [value]="v">{{ v }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- Send RRule -->
    <app-message-template-rrule-editor
      [orgData]="orgData"
      [(obj)]="sendRRule"
      (objChange)="updateObjAndEmit()"
    ></app-message-template-rrule-editor>

    <mat-accordion>
      <!-- Subject -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Subject </mat-panel-title>
        </mat-expansion-panel-header>
        <app-template-text-editor [(objList)]="subjectArray"></app-template-text-editor>
      </mat-expansion-panel>

      <!-- Body -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Body </mat-panel-title>
        </mat-expansion-panel-header>

        <p>TODO: Add checklist of compliance items if necessary: https://www.twilio.com/legal/messaging-policy</p>
        <app-template-text-editor [(objList)]="bodyArray"></app-template-text-editor>
      </mat-expansion-panel>

      <!-- Assigned Users -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Assigned Users </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>

        <app-callee-entity-select-and-edit
          [eventTypes]="eventTypes"
          [userDataArray]="userDataArray"
          [(objList)]="assignedUsers"
        >
        </app-callee-entity-select-and-edit>
      </mat-expansion-panel>

      <!-- Subscribed UserIds -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title> Subscribed UserIds </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="col">
          <!-- BCC -->
          <mat-form-field class="full-width">
            <textarea
              class="full-width"
              formControlName="commaSepSubscribedUserIds"
              matInput
              placeholder="Comma Sep List of Subscribed UserIds"
            ></textarea>
            <mat-error *ngIf="commaSepSubscribedUserIds?.invalid && commaSepSubscribedUserIds?.touched">
              Invalid UserIds: {{ commaSepSubscribedUserIds.errors.invalidComponents }}
            </mat-error>
          </mat-form-field>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </form>
</ng-template>

<app-obj-select-and-edit
  [doDelete]="doDelete"
  [doRefresh]="doRefresh"
  [doUpload]="doUpload"
  [editorTemplate]="objEditor"
  [label]="'Message Template'"
  [modifiedSelectedItem]="getUpdatedObj()"
  [objIsValid]="isValidObjScoper"
  [orderedItems]="itemsArray | async"
  [selectedKVPair]="selectedKVPair"
  (duplicateClick)="duplicateClick()"
  (newClick)="newClick()"
  (selectedKVPairChange)="onSelectedKVPairChange($event)"
>
</app-obj-select-and-edit>
