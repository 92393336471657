import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { isNil } from 'lodash';

import {
  ConversationWaitingRoomConfig,
  ConversationWaitingRoomConfigSchema,
  SupportedLanguages,
  TemplateText,
} from '@pwp-common';

import { getFieldValuesFromFormInComponent } from '../../../../common/objects/form-helper';
import { KVPair } from '../../../../common/objects/kvpair';
import { ObjEditor } from '../../../generic/abstract-classes/obj-editor';
import { TemplateTextLangPair } from '../../message-template/generic/template-text-lang-pair';

@Component({
  selector: 'app-conversation-waiting-room-config',
  templateUrl: './conversation-waiting-room-config.component.html',
  styleUrls: ['./conversation-waiting-room-config.component.css'],
})
export class ConversationWaitingRoomConfigComponent
  extends ObjEditor<KVPair<ConversationWaitingRoomConfig>>
  implements OnInit
{
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  waitingRoomMessageArr: TemplateTextLangPair[] = [];

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(_changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parameters = getFieldValuesFromFormInComponent(
      undefined,
      [ConversationWaitingRoomConfigSchema.enabled],
      ConversationWaitingRoomConfigSchema.Defaults,
      this,
    );

    // Waiting Room Message
    const waitingRoomMessageText = new Map<string, string>();
    for (const item of this.waitingRoomMessageArr.filter((z) => !isNil(z))) {
      waitingRoomMessageText.set(item.languageDefaults.getShortCode(), item.templateText);
    }
    parameters[ConversationWaitingRoomConfigSchema.waitingRoomMessage] = new TemplateText({
      text: waitingRoomMessageText,
    });

    const value = new ConversationWaitingRoomConfig(parameters);
    return new KVPair({ id: this.obj?.id, value });
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: KVPair<ConversationWaitingRoomConfig>) {
    // Init Form
    const formConfig = {} as any;
    formConfig[ConversationWaitingRoomConfigSchema.enabled] = [obj.value!.getEnabled(), []];

    this.form = this.formBuilder.group(formConfig);

    // Waiting Room Message
    this.waitingRoomMessageArr = [];
    for (const [key, value] of obj.value.getWaitingRoomMessage().getText().entries()) {
      const item = new TemplateTextLangPair(SupportedLanguages.getDefaults(key), value);
      this.waitingRoomMessageArr.push(item);
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  get enabled(): AbstractControl | null {
    return this.form.get(ConversationWaitingRoomConfigSchema.enabled);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObj(): boolean {
    // This checks that the form is valid.
    if (!super.isValidObj()) {
      return false;
    }

    return true;
  }
}
