import { SerializableObject } from '../../../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../../../generic/serialization/serializable-object-schema';
import { CommunicationTaskQueueWorkerListEvent } from '../../communication-task-queue-worker-lists/communication-task-queue-worker-list-event/communication-task-queue-worker-list-event';
import { CommunicationTaskQueueWorkerListUser } from '../../communication-task-queue-worker-lists/communication-task-queue-worker-list-user/communication-task-queue-worker-list-user';
import { CommunicationTaskQueueWorkerList } from '../communication-task-queue-worker-list/communication-task-queue-worker-list';
import { CommunicationTaskQueueWorkerListName } from '../communication-task-queue-worker-list-name';

import { AnyCommunicationTaskQueueWorkerListSchema } from './any-communication-task-queue-worker-list-schema';

export abstract class AnyCommunicationTaskQueueWorkerList extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationTaskQueueWorkerList {
    const typeString = validationResult.value[AnyCommunicationTaskQueueWorkerListSchema.type];
    const type = CommunicationTaskQueueWorkerListName[typeString as keyof typeof CommunicationTaskQueueWorkerListName];
    if (type === undefined) {
      throw new Error(
        `AnyCommunicationTaskQueueWorkerListUser._deserialize: User Error: Cannot deserialize obj with type=${typeString}`,
      );
    }

    switch (type) {
      case CommunicationTaskQueueWorkerListName.event: {
        return new CommunicationTaskQueueWorkerListEvent(validationResult.value);
      }
      case CommunicationTaskQueueWorkerListName.userList: {
        return new CommunicationTaskQueueWorkerListUser(validationResult.value);
      }
      default: {
        throw new Error(`AnyCommunicationTaskQueueWorkerList._deserialize Unknown type: ${type}`);
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new AnyCommunicationTaskQueueWorkerListSchema();
  }
}
