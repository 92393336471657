import { SerializableObject } from '../../../../../../../generic/serialization/serializable-object';
import { CommunicationSessionEventLogConferenceEnd } from '../../type/communication-session-event-log-conference-end/communication-session-event-log-conference-end';
import { CommunicationSessionEventLogConferenceParticipantJoin } from '../../type/communication-session-event-log-conference-participant-join/communication-session-event-log-conference-participant-join';
import { CommunicationSessionEventLogConferenceStart } from '../../type/communication-session-event-log-conference-start/communication-session-event-log-conference-start';
import { CommunicationSessionEventLogConference } from '../communication-session-event-log-conference/communication-session-event-log-event-conference';
import { CommunicationSessionEventLogConferenceType } from '../communication-session-event-log-conference-type';

import { AnyCommunicationSessionEventLogConferenceSchema } from './any-communication-session-event-log-conference-schema';

export abstract class AnyCommunicationSessionEventLogConference extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  public static _deserialize(validationResult: import('joi').ValidationResult): CommunicationSessionEventLogConference {
    const typeString = validationResult.value[AnyCommunicationSessionEventLogConferenceSchema.conferenceEventType];
    const conferenceEventType =
      CommunicationSessionEventLogConferenceType[typeString as keyof typeof CommunicationSessionEventLogConferenceType];
    if (conferenceEventType === undefined) {
      throw new Error(
        `AnyCommunicationSessionEventLog._deserialize: User Error: Cannot deserialize obj with type=${typeString}`,
      );
    }

    switch (conferenceEventType) {
      case CommunicationSessionEventLogConferenceType.conferenceEnd: {
        return new CommunicationSessionEventLogConferenceEnd(validationResult.value);
      }
      case CommunicationSessionEventLogConferenceType.conferenceStart: {
        return new CommunicationSessionEventLogConferenceStart(validationResult.value);
      }
      case CommunicationSessionEventLogConferenceType.participantJoin: {
        return new CommunicationSessionEventLogConferenceParticipantJoin(validationResult.value);
      }
      default: {
        throw new Error(`AnyCommunicationSessionEventLog._deserialize Unknown type: ${conferenceEventType}`);
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema() {
    return new AnyCommunicationSessionEventLogConferenceSchema();
  }
}
