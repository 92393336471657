import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { secondsToMilliseconds } from 'date-fns';
import { cloneDeep, isNil } from 'lodash';
import { firstValueFrom } from 'rxjs';

import {
  CommunicationTaskQueue,
  CommunicationWorkflow,
  CommunicationWorkflowFilterSchema,
  CommunicationWorkflowSchema,
  CommunicationWorkflowTarget,
  CommunicationWorkflowTargetTimeoutFixed,
  CommunicationWorkflowTargetTimeoutName,
  DBAction,
  DBCreateDoc,
  DBDeleteDoc,
  DBTransaction,
  DBTransactionType,
  DBUploadExistingDoc,
  getTaskQueueIds,
  isNilOrDefaultDocId,
  ServiceException,
} from '@pwp-common';

import { targetEditorOutputToDb } from '../../../../components/core/communication-workflow/editor/editor-output/target/db/target-editor-output-to-db/target-editor-output-to-db';
import { CommunicationsService } from '../../../call/communications/communications.service';
import { CommunicationTaskQueueService } from '../../communication-task-queue/communication-task-queue.service';
import { CommunicationWorkflowService } from '../communication-workflow.service';

import { defaultCommunicationWorkflowTemplate } from './workflow-editor-result-to-db-transaction/default-obj-templates';
import { sanityCheck } from './workflow-editor-result-to-db-transaction/sanity-check';
import {
  CommunicationWorkflowEditTransaction,
  Original,
  Update,
  WorkflowEditorToDbTransactionInput,
} from './workflow-editor-result-to-db-transaction/types';

///////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Service
///////////////////////////////////////////////////////////////////////////////////////////////////////////////

@Injectable({
  providedIn: 'root',
})
export class AllDataCommunicationWorkflowService {
  constructor(
    private db: AngularFirestore,
    private communicationWorkflowService: CommunicationWorkflowService,
    private communicationTaskQueueService: CommunicationTaskQueueService,

    private communicationEndpointService: CommunicationsService,
  ) {}

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Get Task Queues For Workflow
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

  private async getTaskQueuesForWorkflow(workflowId: string): Promise<Original | undefined> {
    if (isNilOrDefaultDocId(workflowId)) {
      return undefined;
    }
    const workflow = await firstValueFrom(this.communicationWorkflowService.getDoc(workflowId));
    const taskQueueIds = getTaskQueueIds(workflow);
    const taskQueues = await firstValueFrom(
      this.communicationTaskQueueService.getDocsWithIds(Array.from(taskQueueIds)),
    );

    return {
      workflow,
      taskQueues,
    };
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Upload transaction
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

  public async update(params: {
    workflowId: string | undefined;
    update: Update;
    deleteWorkflowRequested: boolean;
  }): Promise<void> {
    const original = await this.getTaskQueuesForWorkflow(params.workflowId);
    const transaction = this.workflowEditorResultToDbTransaction({
      original,
      update: params.update,
      deleteWorkflowRequested: params.deleteWorkflowRequested,
    });
    await this.communicationTaskQueueService.runDBTransaction(transaction.communicationTaskQueueTransaction);
    await this.communicationWorkflowService.runDBTransaction(transaction.communicationWorkflowTransaction);
    await this.communicationEndpointService.onTaskRouterConfigurationUpdated();
  }

  // eslint-disable-next-line max-len
  private workflowEditorResultToDbTransaction(
    input: WorkflowEditorToDbTransactionInput,
  ): CommunicationWorkflowEditTransaction {
    sanityCheck(input);

    /**
     * Don't edit input directly, since that might cause tests to fail
     * depending on the order they are run in.
     */
    const { original, update, deleteWorkflowRequested } = cloneDeep(input);
    /**
     * Make base data
     */
    const communicationWorkflow: CommunicationWorkflow =
      cloneDeep(original?.workflow) ?? cloneDeep(defaultCommunicationWorkflowTemplate);
    const taskQueuesToUpdate: CommunicationTaskQueue[] = [];
    const taskQueuesToCreate: CommunicationTaskQueue[] = [];

    /**
     * Return empty transaction if no changes were made.
     */
    if (isNil(update?.editorResult) && deleteWorkflowRequested !== true) {
      return {
        communicationWorkflowTransaction: new DBTransaction<ServiceException>(DBTransactionType.update, []),
        communicationTaskQueueTransaction: new DBTransaction<ServiceException>(DBTransactionType.update, []),
      };
    }

    /**
     * Delete everything if that was requested
     */
    if (isNil(update.editorResult) && deleteWorkflowRequested === true) {
      const actions: DBAction<CommunicationTaskQueue>[] = [];
      for (const key of Array.from(original.taskQueues.keys()).sort()) {
        const value = original.taskQueues.get(key);
        actions.push(new DBDeleteDoc(value));
      }
      return {
        communicationWorkflowTransaction: new DBTransaction(DBTransactionType.update, [
          new DBDeleteDoc(communicationWorkflow),
        ]),
        communicationTaskQueueTransaction: new DBTransaction(DBTransactionType.update, actions),
      };
    }

    /**
     * Parse each target and update the transactions accordingly
     */
    const targets: CommunicationWorkflowTarget[] = [];
    for (const unparsedTarget of update.editorResult.targets) {
      let queueId = unparsedTarget.communicationTaskQueueId;
      let isNewTaskQueue = false;

      if (isNilOrDefaultDocId(queueId)) {
        // This is a new queue, specify the ID
        queueId = this.db.createId();
        isNewTaskQueue = true;
      }
      if (taskQueuesToUpdate.map((z) => z.getId()).includes(queueId)) {
        throw new Error(
          'workflowEditorResultToDbTransaction: Not implemented error. Each target must refer to a new task queue',
        );
      }

      /**
       * Generate the target
       */
      const parsedTarget = new CommunicationWorkflowTarget({
        queueId,
        timeout: new CommunicationWorkflowTargetTimeoutFixed({
          type: CommunicationWorkflowTargetTimeoutName.fixed,
          timeoutMS: unparsedTarget.workflowTargetTimeoutSeconds * 1000,
        }),
      });
      targets.push(parsedTarget);

      /**
       * Generate the task queue
       */
      const taskQueue = targetEditorOutputToDb({
        originalTaskQueue: original?.taskQueues?.get(queueId),
        targetEditorOutput: unparsedTarget,
      });
      taskQueue.setId(queueId);

      if (isNewTaskQueue) {
        taskQueuesToCreate.push(taskQueue);
      } else {
        taskQueuesToUpdate.push(taskQueue);
      }
    }

    /**
     * Update workflow
     */
    const filter = communicationWorkflow.getFilters()[0];
    const taskReservationTimeoutMS = secondsToMilliseconds(update.editorResult.taskReservationTimeoutSeconds);

    filter.setField(CommunicationWorkflowFilterSchema.targets, targets);
    communicationWorkflow.setField(CommunicationWorkflowSchema.id, update.editorResult.id);
    communicationWorkflow.setField(CommunicationWorkflowSchema.displayName, update.editorResult.displayName ?? '');
    communicationWorkflow.setField(CommunicationWorkflowSchema.description, update.editorResult.description ?? '');
    communicationWorkflow.setField(CommunicationWorkflowSchema.taskReservationTimeoutMS, taskReservationTimeoutMS);
    communicationWorkflow.setField(CommunicationWorkflowSchema.filters, [filter]);

    /********************************************************************************
     * Make transaction to update workflow
     ********************************************************************************/

    /**
     * Upsert
     */

    // Workflow
    let communicationWorkflowTransaction: DBTransaction<CommunicationWorkflow>;
    if (isNilOrDefaultDocId(update.editorResult.id)) {
      communicationWorkflowTransaction = new DBTransaction(DBTransactionType.update, [
        new DBCreateDoc(communicationWorkflow),
      ]);
    } else {
      communicationWorkflowTransaction = new DBTransaction(DBTransactionType.update, [
        new DBUploadExistingDoc(communicationWorkflow),
      ]);
    }

    // Task Queues
    const taskQueueActions: DBAction<CommunicationTaskQueue>[] = [];
    for (const value of taskQueuesToCreate) {
      taskQueueActions.push(new DBCreateDoc(value));
    }

    for (const value of taskQueuesToUpdate) {
      taskQueueActions.push(new DBUploadExistingDoc(value));
    }

    for (const key of Array.from(original?.taskQueues.keys() ?? []).sort()) {
      const value = original.taskQueues.get(key);
      if (!isNilOrDefaultDocId(value.getId()) && !taskQueuesToUpdate.map((z) => z.getId()).includes(value.getId())) {
        taskQueueActions.push(new DBDeleteDoc(value));
      }
    }

    const communicationTaskQueueTransaction = new DBTransaction(DBTransactionType.update, taskQueueActions);
    return { communicationWorkflowTransaction, communicationTaskQueueTransaction };
  }
}
