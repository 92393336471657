import { SchemaField } from '../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';
import { ShakenPayloadDest } from '../shaken-payload-dest/shaken-payload-dest';
import { ShakenPayloadOrig } from '../shaken-payload-orig/shaken-payload-orig';

export class ShakenPayloadSchema extends SerializableObjectSchema {
  public static readonly attest = 'attest';

  public static readonly dest = 'dest';

  public static readonly iat = 'iat';

  public static readonly orig = 'orig';

  public static readonly origid = 'origid';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly attest = undefined;

    public static readonly dest = undefined;

    public static readonly iat = undefined;

    public static readonly orig = undefined;

    public static readonly origid = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;
    /* eslint-disable */
    parameters[ShakenPayloadSchema.attest] = SchemaField.string(ShakenPayloadSchema.Defaults.attest)
      .allow('A', 'B', 'C')
      .required();
    parameters[ShakenPayloadSchema.dest] = SchemaField.obj(
      ShakenPayloadDest,
      ShakenPayloadSchema.Defaults.dest,
    ).required();
    parameters[ShakenPayloadSchema.iat] = SchemaField.number(ShakenPayloadSchema.Defaults.iat).required();
    parameters[ShakenPayloadSchema.orig] = SchemaField.obj(
      ShakenPayloadOrig,
      ShakenPayloadSchema.Defaults.orig,
    ).required();
    parameters[ShakenPayloadSchema.origid] = SchemaField.string(ShakenPayloadSchema.Defaults.origid).required();
    /* eslint-enable */

    return parameters;
  }
}
