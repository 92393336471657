import { EventRequestRole } from './enums';
import { EventRequestConfig } from './event-request-config';
import { EventRequestConfigSchema } from './event-request-config-schema';

export const createDefaultEventRequestConfig = (id = EventRequestConfigSchema.GenericDefaults.id): EventRequestConfig =>
  new EventRequestConfig({
    id,
    primary: [EventRequestRole.allowRequest],
    backup: [],
  });
