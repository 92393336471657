import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { SelectButtonModule } from 'primeng/selectbutton';
import { BehaviorSubject, map, switchMap } from 'rxjs';

import { makePTableCols } from '../../../../common/p-table/make-p-table-cols';
import { DataTableModule } from '../../../../components/generic/data-table/data-table.module';
import { CallEntrypointsService } from '../../../../services/call/call-entrypoints/call-entrypoints.service';
import { createCallEntrypointTableRow } from '../helpers/create-call-entrypoint-table-row/create-call-entrypoint-table-row';

import { PhoneLineEditorComponent } from './components/phone-line-editor/phone-line-editor.component';

@Component({
  selector: 'app-phone-line-table-editor',
  standalone: true,
  imports: [CommonModule, DataTableModule, PhoneLineEditorComponent, SelectButtonModule, TranslocoModule],
  templateUrl: './phone-line-table-editor.component.html',
  styleUrls: ['./phone-line-table-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneLineTableEditorComponent {
  private readonly callEntrypointsService = inject(CallEntrypointsService);

  private readonly refresh$ = new BehaviorSubject<void>(null);

  public readonly cols = makePTableCols({
    columns: ['displayName', 'description', 'availableHours', 'phones'],
    translationScope: 'phone-line-table-editor',
  });

  public readonly tableRows$ = this.refresh$.pipe(
    switchMap(() => this.callEntrypointsService.getDocsArray()),
    map((callEntrypoints) => callEntrypoints.map((callEntrypoint) => createCallEntrypointTableRow({ callEntrypoint }))),
  );

  public updateWithSuccess(): void {
    this.refresh$.next();
  }
}
