import { cloneDeep } from 'lodash';

import { SerializableObject } from '../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';

import { IVRResponseConstructor } from './ivr-response-constructor';
import { IVRResponseDirection } from './ivr-response-direction';
import { IVRResponseSchema } from './ivr-response-schema';

export class IVRResponse extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected speech!: string | undefined;

  protected digits!: string | undefined;

  protected ivrId!: string;

  protected timestamp!: moment.Moment;

  protected direction!: IVRResponseDirection;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: IVRResponseConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): IVRResponse {
    return new IVRResponse(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(IVRResponse.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new IVRResponseSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getSpeech(): string | undefined {
    return cloneDeep(this.speech);
  }

  public getDigits(): string | undefined {
    return cloneDeep(this.digits);
  }

  public getIvrId(): string {
    return cloneDeep(this.ivrId);
  }

  public getTimestamp(): moment.Moment {
    return cloneDeep(this.timestamp);
  }

  public getDirection(): string {
    return cloneDeep(this.direction);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  public setSpeech(speech: string | undefined): IVRResponse {
    this.speech = speech;
    return this;
  }

  public setDigits(digits: string | undefined): IVRResponse {
    this.digits = digits;
    return this;
  }

  public setTimestamp(timestamp: moment.Moment): IVRResponse {
    this.timestamp = timestamp;
    return this;
  }

  public setDirection(direction: IVRResponseDirection): IVRResponse {
    this.direction = direction;
    return this;
  }
}
