import RRule from 'rrule';

import { rruleHasDtstart, rruleHasTzid } from './fields';
import { reInterpretMomentToUTC } from './reinterpret-to-utc';

export const addDtStartAndTimezoneToRRule = (baseRRuleStr: string, date: moment.Moment, timezone: string): RRule => {
  if (rruleHasDtstart(baseRRuleStr, true) || rruleHasTzid(baseRRuleStr)) {
    console.error(baseRRuleStr);
    throw new Error('This RRule already has a DTStart or TZID. Cant add.');
  }

  /**
   * Use the RRule library to generate the rrule string. There are corner cases to handle
   * if we choose to generate the string manually. For example, it appears that
   * the TZID property shouldn't be specified if TZID = UTC (https://datatracker.ietf.org/doc/html/rfc5545)
   *
   * For all other cases however this appears to be the same as:
   * `DTSTART;TZID=${timezone}:${date.tz(timezone).format(rruleTimeFormat)};\n${baseRRuleStr}`
   */

  const rrule = RRule.fromString(baseRRuleStr);
  const options = rrule.origOptions;
  options.tzid = timezone;
  options.dtstart = reInterpretMomentToUTC(date.tz(timezone)).toDate();
  const parsedRRule = new RRule(options);
  return parsedRRule;
};
