import { SchemaField } from '../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';

export class ServiceLimitServiceUsageSchema extends SerializableObjectSchema {
  public static readonly inboundCallServiceDeliveredNum = 'inboundCallServiceDeliveredNum';

  public static readonly inboundCallServiceRequestedNum = 'inboundCallServiceRequestedNum';

  public static readonly outboundCallServiceDeliveredNum = 'outboundCallServiceDeliveredNum';

  public static readonly outboundCallServiceRequestedNum = 'outboundCallServiceRequestedNum';

  public static readonly windowStartTime = 'windowStartTime';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly inboundCallServiceDeliveredNum = 0;

    public static readonly inboundCallServiceRequestedNum = 0;

    public static readonly outboundCallServiceDeliveredNum = 0;

    public static readonly outboundCallServiceRequestedNum = 0;

    public static readonly windowStartTime = undefined;
  };

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([
      ServiceLimitServiceUsageSchema.inboundCallServiceDeliveredNum,
      ServiceLimitServiceUsageSchema.inboundCallServiceRequestedNum,
      ServiceLimitServiceUsageSchema.outboundCallServiceDeliveredNum,
      ServiceLimitServiceUsageSchema.outboundCallServiceRequestedNum,
    ]);
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as { [key: string]: import('joi').AnySchema };

    /* eslint-disable */
    parameters[ServiceLimitServiceUsageSchema.inboundCallServiceDeliveredNum] = SchemaField.number(
      ServiceLimitServiceUsageSchema.Defaults.inboundCallServiceDeliveredNum,
    );
    parameters[ServiceLimitServiceUsageSchema.inboundCallServiceRequestedNum] = SchemaField.number(
      ServiceLimitServiceUsageSchema.Defaults.inboundCallServiceRequestedNum,
    );
    parameters[ServiceLimitServiceUsageSchema.outboundCallServiceDeliveredNum] = SchemaField.number(
      ServiceLimitServiceUsageSchema.Defaults.outboundCallServiceDeliveredNum,
    );
    parameters[ServiceLimitServiceUsageSchema.outboundCallServiceRequestedNum] = SchemaField.number(
      ServiceLimitServiceUsageSchema.Defaults.outboundCallServiceRequestedNum,
    );
    parameters[ServiceLimitServiceUsageSchema.windowStartTime] = SchemaField.timestamp(
      ServiceLimitServiceUsageSchema.Defaults.windowStartTime,
    );
    /* eslint-enable */

    return parameters;
  }
}
