import { ChangeDetectorRef, Directive, EventEmitter, inject, Input, Output } from '@angular/core';
import { cloneDeep, isEqual, isNil } from 'lodash';

import { ComponentWithForm } from './component-with-form';

@Directive()
export abstract class ComponentWithEmittingForm<T> extends ComponentWithForm {
  private readonly cdr = inject(ChangeDetectorRef);
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  @Input() obj: T;

  @Output() objChange = new EventEmitter<T>();

  lastEmittedValue: any = '__ComponentWithEmittingForm__unset';

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Updated Object
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  abstract getObjFromForm(): T;

  public getUpdatedObj(): T | undefined {
    if (!this.isValidObj()) {
      return undefined;
    }
    return this.getObjFromForm();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Emit Command Change
  ///////////////////////////////////////////////////////////////////////////////////////////

  protected isValidObj(): boolean {
    if (isNil(this.form)) {
      return false;
    }

    if (!this.form.valid && !isNil(this.form.errors)) {
      console.log(`ComponentWithEmittingForm:Form errors.`, this.form.errors);
    }
    return this.form.valid;
  }

  updateObjAndEmit() {
    // Don't update the local object if the form specifies an invalid item
    if (!this.isValidObj()) {
      return;
    }

    this.obj = this.getUpdatedObj();

    if (isEqual(this.obj, this.lastEmittedValue)) {
      return;
    }

    this.objChange.emit(this.obj);
    this.lastEmittedValue = cloneDeep(this.obj);
  }

  updateFormUI() {
    if (isEqual(this.obj, this.lastEmittedValue)) {
      this.loading = false;
      this.cdr.detectChanges();
      return;
    }
    super.updateFormUI();
    this.updateObjAndEmit();
  }

  onFormChanges(value: any): void {
    super.onFormChanges(value);
    this.updateObjAndEmit();
  }
}
