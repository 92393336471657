<ng-container *transloco="let t; read: 'edit-available-hours-by-dow'">
  <form *ngIf="!loading" [formGroup]="form">
    <legend>{{ t('title') }}</legend>
    <span class="edit-available-hours-by-dow__description">{{ description }}</span>
    <div *ngFor="let isoWeekday of isoWeekdays" class="field grid">
      <!-- Select Available/Not Available/Custom -->
      <div class="col">
        <label class="col-fixed" style="width: 100px" [for]="'timeRangeType' + isoWeekday">{{
          t(isoWeekday.toString())
        }}</label>
        <p-dropdown
          appendTo="body"
          class="inputfield"
          [ariaLabel]="t(isoWeekday.toString())"
          [formControlName]="'timeRangeType' + isoWeekday"
          [id]="'timeRangeType' + isoWeekday"
          [options]="[
            { label: t('alwaysAvailable'), value: 'alwaysAvailable' },
            { label: t('neverAvailable'), value: 'neverAvailable' },
            { label: t('sometimesAvailable'), value: 'sometimesAvailable' },
          ]"
        >
        </p-dropdown>
      </div>

      <!-- Specify Custom Available Hours -->
      <label
        *ngIf="isSometimesAvailable(isoWeekday)"
        class="col-fixed"
        style="width: 200px"
        [attr.for]="'timeRange' + isoWeekday"
        >{{ t('availableTimes') }}</label
      >
      <div *ngIf="isSometimesAvailable(isoWeekday)" class="col">
        <!-- Time Range in 24h Format -->
        <p-inputMask
          class="inputfield"
          mask="99:99 - 99:99"
          placeholder="09:00 - 17:00"
          [ariaLabel]="t('timeRange' + isoWeekday)"
          [characterPattern]="timeRangeRegex"
          [formControlName]="'timeRange' + isoWeekday"
          [id]="'timeRange' + isoWeekday"
        >
        </p-inputMask>

        <!-- Error Message -->
        <p-messages *ngIf="isInvalidTimeRange(isoWeekday)" severity="error">
          <ng-template pTemplate>
            {{ t('invalidTimeRange') }}
          </ng-template>
        </p-messages>
      </div>
    </div>
  </form>
</ng-container>
