import { cloneDeep } from 'lodash';

import { APP_URL } from '../../../constants';
import { CalleeEntity } from '../../call/callee-entity/callee-entity';
import { TimeSelection } from '../../core/time-selection/time-selection/time-selection';
import { DBDocSchema } from '../../generic/db-doc/db-doc-schema';
import { GenericDisplayable } from '../../generic/displayable/generic-displayable';
import { IdentityConfigConversation } from '../../identity/identity-config/identity-config-conversation/identity-config-conversation';
import { VoiceResponseCommand } from '../../voice-response-command/generic/voice-response-command';
import { ConversationWaitingRoomConfig } from '../conversation-waiting-room-config/conversation-waiting-room-config';

import { ConversationConfigConstructor } from './conversation-config-constructor';
import { ConversationConfigSchema } from './conversation-config-schema';

export class ConversationConfig extends GenericDisplayable {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected chatServiceSid!: string;

  protected onCannotProvideService!: VoiceResponseCommand[];

  protected serviceProviders!: CalleeEntity[];

  protected roleSidAnonymousParticipant!: string;

  protected roleSidInternalParticipant!: string;

  protected communicationWorkflowId?: string;

  protected inactiveAfterDuration?: string;

  protected closedAfterDuration?: string;

  protected availableTimes?: TimeSelection;

  protected pIIRetentionDuration!: string;

  protected redirectURL: string | undefined;

  protected waitingRoomConfig!: ConversationWaitingRoomConfig;

  protected identityConfig!: IdentityConfigConversation;

  protected phones?: string[];

  protected messagingServiceSid?: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ConversationConfigConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ConversationConfig {
    return new ConversationConfig(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ConversationConfig.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new ConversationConfigSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getChatServiceSid(): string {
    return cloneDeep(this.chatServiceSid);
  }

  public getOnCannotProvideService(): VoiceResponseCommand[] {
    return cloneDeep(this.onCannotProvideService);
  }

  public getServiceProviders(): CalleeEntity[] {
    return cloneDeep(this.serviceProviders);
  }

  public getRoleSidAnonymousParticipant(): string {
    return cloneDeep(this.roleSidAnonymousParticipant);
  }

  public getRoleSidInternalParticipant(): string {
    return cloneDeep(this.roleSidInternalParticipant);
  }

  public getCommunicationWorkflowId() {
    return cloneDeep(this.communicationWorkflowId);
  }

  public getInactiveAfterDuration(): string | undefined {
    return cloneDeep(this.inactiveAfterDuration);
  }

  public getClosedAfterDuration(): string | undefined {
    return cloneDeep(this.closedAfterDuration);
  }

  public getAvailableTimes(): TimeSelection {
    return cloneDeep(this.availableTimes || ConversationConfigSchema.Defaults.availableTimes);
  }

  public getPIIRetentionDuration(): string {
    return cloneDeep(this.pIIRetentionDuration);
  }

  public getRedirectURL(): string | undefined {
    return cloneDeep(this.redirectURL);
  }

  public getWaitingRoomConfig(): ConversationWaitingRoomConfig {
    return cloneDeep(this.waitingRoomConfig);
  }

  public getIdentityConfig() {
    return cloneDeep(this.identityConfig);
  }

  public getPhones(): string[] | undefined {
    return cloneDeep(this.phones);
  }

  public getMessagingServiceSid(): string | undefined {
    return cloneDeep(this.messagingServiceSid);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Chat URL
  /////////////////////////////////////////////////////////////////////////////

  public getChatURL(orgId: string): string {
    return `${APP_URL}/incomingChat?orgId=${orgId}`;
  }
}
