import Joi from 'joi';

import { SerializableObjectSchema } from '../../../../../../../generic/serialization/serializable-object-schema';
import { CommunicationSessionEventLogDialAsyncServiceRequestDeliverySchema } from '../../type/communication-session-event-log-dial-async-service-request-delivery/communication-session-event-log-dial-async-service-request-delivery-schema';
import { CommunicationSessionEventLogDialConferenceSchema } from '../../type/communication-session-event-log-dial-conference/communication-session-event-log-dial-conference-schema';
import { CommunicationSessionEventLogDialReservationOfferSchema } from '../../type/communication-session-event-log-dial-reservation-offer/communication-session-event-log-dial-reservation-offer-schema';

export class AnyCommunicationSessionEventLogDialPhoneSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The type of the entity
  public static readonly callType = 'callType';

  private static readonly SCHEMA_ID = 'anyCommunicationSessionEventLogDialPhone';

  private static computedSchema = Joi.alternatives()
    .try(
      ...[
        new CommunicationSessionEventLogDialAsyncServiceRequestDeliverySchema().getJoiSchema(),
        new CommunicationSessionEventLogDialConferenceSchema().getJoiSchema(),
        new CommunicationSessionEventLogDialReservationOfferSchema().getJoiSchema(),
      ],
    )
    .id(AnyCommunicationSessionEventLogDialPhoneSchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static GenericDefaults = class {
    // The name of the command
    public static readonly type = undefined;
  };

  /**
   * Always serialize the command name
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  public static getSchemaId(): string {
    return `#${AnyCommunicationSessionEventLogDialPhoneSchema.SCHEMA_ID}`;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } | import('joi').AnySchema {
    return AnyCommunicationSessionEventLogDialPhoneSchema.computedSchema;
  }
}
