import type { ValidationResult } from 'joi';
import { cloneDeep, isEqual, isNil } from 'lodash';

import { DBDocSchemaFields } from '../db-doc/db-doc-schema-fields';

import { FieldChangeEnum, FieldChangeTypes } from './field-change';
import { SerializableObjectSchema } from './serializable-object-schema';
import { serializeValue } from './serialize-value';

/**
 * All objects that that are transmitted from/to the server extend this class.
 *
 */
export abstract class SerializableObject {
  protected constructorParameters!: any;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(constructorParameters: any) {
    this.constructorParameters = constructorParameters;
    this.parseConstructorParameters(constructorParameters);
  }

  protected static createObject(validationResult: ValidationResult): SerializableObject {
    return this._deserialize(validationResult);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Static Deserialize method
  // Static abstract methods are not yet available, so we use the
  // hack suggested here:
  // https://github.com/microsoft/TypeScript/issues/34516#issuecomment-642087219
  // to implement this interface
  // https://github.com/microsoft/TypeScript/issues/34516#issuecomment-622967774
  /////////////////////////////////////////////////////////////////////////////

  public static deserialize(obj: any): any {
    let validationResult!: ValidationResult;
    const schemaObject = this.getSchema();
    const schema = schemaObject.getJoiSchema();

    try {
      // Force every deserialization attempt to verify that the
      // object meets the schema
      validationResult = schema.validate(obj, { errors: { escapeHtml: true } });

      if (validationResult.error || validationResult.errors) {
        // Log the object and schema errors if the object cannot be deserialized.
        console.error('deserialize: Object does not match schema. Returning default');
        console.error(validationResult.error);
        console.error(validationResult.errors);
        console.log(obj);
        return this._deserialize(schema.validate({}));
      }
    } catch (error) {
      console.error(this.getSchema());
      // TODO: DELETE THIS LINE! CANNOT LOG OBJECTS
      // console.log(obj);
      console.error(error);
      throw new Error('deserialize: Could not deserialze object.');
    }

    // The input has been sanitized, and check for XSS. Ok to instantiate object.
    return this.createObject(validationResult);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Get Schema
  /////////////////////////////////////////////////////////////////////////////

  static getSchema(): SerializableObjectSchema {
    throw new Error('SerializableObjectSchema.getSchema: UserError: This function should never be called.');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Get Schema
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is meant to be called only by SerializableObject
   * and the _deserialize method of subclasses.
   *
   * It extracts an audit log maintained in every document.
   *
   * It is meant to only be called internally
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: ValidationResult): any {
    if (validationResult.error !== undefined || validationResult.errors !== undefined) {
      throw new Error('SerializableObject._deserialize: User Error. Function called with error in validation result.');
    }

    const result = {} as any;
    for (const field of this.getSchema().getSchemaKeys(true)) {
      result[field] = validationResult.value[field];
    }

    return result;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Default Object
  /////////////////////////////////////////////////////////////////////////////

  public hasOnlyDefaultProperties() {
    const serializedObj = (this as any).serialize();
    const defaultSerializedObject = (this as any).constructor.deserialize({}).serialize();

    return isEqual(serializedObj, defaultSerializedObject);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public sanityCheck(): void {
    // Throw error if object should not be serialized.
  }

  /**
   * Serialize this object.
   */
  public serialize(schema: SerializableObjectSchema, target: 'api' | 'firebase' = 'firebase'): any {
    this.sanityCheck();
    const serializedObj: Record<string, unknown> = {};
    const schemaConstructor = schema.constructor as Function & { Defaults: Record<string, unknown> };

    if ('Defaults' in schemaConstructor === false) {
      console.error('error');
      console.error(schema);
      throw new Error('Error: object does not define defaults in a class named defaults');
    }

    const defaults = schemaConstructor.Defaults as Record<string, unknown>;
    const genericFields: Set<string> = new Set([
      DBDocSchemaFields.createTime,
      DBDocSchemaFields.createdByUserId,
      DBDocSchemaFields.lastUploadTime,
      DBDocSchemaFields.lastModifiedByUserId,
    ]);

    const objFields = new Set(Object.keys(this));

    for (const field of schema.getSchemaKeys()) {
      // Don't serialize generic fields because reading / writing them is handled by the generic db service
      if (genericFields.has(field)) {
        continue;
      }

      // Do not serialize field if it doesn't exist
      if (!objFields.has(field)) {
        /**
         * This case will be triggered normally in the following circumstance:
         * if const x = new Foo({a: 1}), and the FooConstructor has an optional parameter named
         * b, then Object.keys(x) will not contain the string 'b'.
         */
        continue;
      }

      if (field in defaults === false) {
        console.error(this);
        throw new Error(`Will not serialize because object does not define a default value for ${field}`);
      }

      const defaultValue = defaults[field];
      const actualValue = this[field as keyof this];
      const serializedDefaultValue = serializeValue(defaultValue, target);
      const serializedActualValue = serializeValue(actualValue, target);

      // Do not serialize a field if the actual value is undefined
      if (actualValue === undefined) {
        continue;
      }

      // If the actual value is just the default, we only serialize it if explicitly requested
      if (!schema.defaultValuesToSerialize().has(field) && isEqual(serializedActualValue, serializedDefaultValue)) {
        continue;
      }

      // Serialize the field
      serializedObj[field] = serializeValue(actualValue, target);
    }

    return serializedObj;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Parse Parameters
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Parse parameters as required
   */
  protected parseConstructorParameters(parameters: any): void {
    for (const key of Object.keys(parameters)) {
      (this as any)[key] = (parameters as any)[key];
    }
  }

  /**
   * The ParsedParameters is an interface whose keys are the elements required to construct
   * the associated typescript object. Each element of this dictionary has already been parsed
   * to its final type (eg, moment.Moment, string, number, ...).
   */
  public getConstructorParameters(): any {
    return this.constructorParameters;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Equals
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Objects are considered equal if they are equal once serialized.
   * @param obj
   */
  public equals(obj: any, ignoreFields: string[] = []): boolean {
    // Check that the objects are instances of the same class
    if (isNil(obj) || Object.getPrototypeOf(this) !== Object.getPrototypeOf(obj)) {
      console.debug('SerializableObject.equals: Prototypes not equal');
      return false;
    }

    for (const key of Object.keys(this.getConstructorParameters())) {
      if (ignoreFields.includes(key)) {
        continue;
      }
      if (!Object.prototype.hasOwnProperty.call(obj, key)) {
        console.debug('SerializableObject.equals: object is missing key', { key });
        return false;
      }

      // Must check equality using lodash for the serialized value, because
      // moments and dates cannot be checked for equality directly.
      const thisSerVal = serializeValue((this as any)[key], 'api');
      const objSerVal = serializeValue(obj[key], 'api');
      if (!isEqual(objSerVal, thisSerVal)) {
        console.debug('SerializableObject.equals: serialized values differ at key', { key });
        return false;
      }
    }

    return true;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Generic Getter / Setter
  /////////////////////////////////////////////////////////////////////////////

  public getField(fieldName: string): any {
    return cloneDeep((this as any)[fieldName]);
  }

  private getDefaultValue(fieldName: string): any {
    const schema = (this.constructor as any).getSchema();
    return SerializableObjectSchema.getDefaultValue(schema, fieldName);
  }

  public setField(fieldName: string, value: any | FieldChangeTypes): SerializableObject {
    switch (value) {
      case FieldChangeEnum.resetDefault: {
        const defaultValue = this.getDefaultValue(fieldName);
        (this as any)[fieldName] = defaultValue;
        break;
      }
      case FieldChangeEnum.noChange: {
        break;
      }
      default: {
        (this as any)[fieldName] = value;
      }
    }

    return this;
  }
}
