import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isEqual, isNil } from 'lodash';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { Note, OrgData, ServiceableAddressE164Phone } from '@pwp-common';

import { NgChanges } from '../../../../../../common/objects/ng-changes';
import { getExpirationDisplayTime } from '../../../../../../common/pii-retention/get-expiration-display-time/get-expiration-display-time';
import { AsyncServiceRequestNotesService } from '../../../../../../services/call/note/async-service-request-notes.service';
import { ServiceableAddressE164PhoneService } from '../../../../../../services/core/serviceable-address/serviceable-address-e164-phone.service';
import { UserDataService } from '../../../../../../services/user/user-data/user-data.service';
import { CallbackRow } from '../../../../row/callback-row/callback-row';
import { InboxService } from '../../../../services/inbox-service/inbox.service';

import { createNotesDisplay } from './helper/create-notes-display';
import { CallerDetailsData } from './interfaces';

@UntilDestroy()
@Component({
  selector: 'app-inbox-callback-detail-caller-details',
  templateUrl: './inbox-callback-detail-caller-details.component.html',
  styleUrls: ['./inbox-callback-detail-caller-details.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboxCallbackDetailCallerDetailsComponent implements OnChanges {
  private changes = new Subject<void>();

  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////
  public readonly data$ = this.changes.pipe(
    startWith(null),
    switchMap(() => this.getData()),
  );

  ///////////////////////////////////////////////////////////////////////
  // Input
  ///////////////////////////////////////////////////////////////////////
  @Input() public row: CallbackRow;

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////
  constructor(
    private asyncServiceRequestNotesService: AsyncServiceRequestNotesService,
    private inboxService: InboxService,
    private serviceableAddressService: ServiceableAddressE164PhoneService,
    private userDataService: UserDataService,
  ) {}

  private createFinalData(
    serviceableAddress: ServiceableAddressE164Phone | undefined,
    orgData: OrgData,
    notes: Note[],
  ): Observable<CallerDetailsData> {
    return this.userDataService.getDocsWithIds(notes.map((note) => note.getCreatedByUserId())).pipe(
      map((users) => ({
        e164Phone: serviceableAddress?.getE164Phone(),
        dataRetentionEndFormatted: getExpirationDisplayTime(
          [serviceableAddress?.getCreateTime().toDate(), ...notes.map((note) => note.getCreateTime().toDate())],
          { piiRetentionTime: 'P30D', timezone: orgData?.getTimezone() },
        ),
        notes: createNotesDisplay(notes, users),
      })),
    );
  }

  private getData(): Observable<CallerDetailsData> {
    return this.inboxService.$isAdmin.pipe(
      switchMap((isAdmin) => {
        const serviceableAddress$ = isAdmin
          ? this.serviceableAddressService.getDoc(
              this.row.callbackRowParams.asyncServiceRequest.getServiceableAddressId(),
            )
          : of(undefined);

        return combineLatest([
          serviceableAddress$,
          this.inboxService.$orgData,
          this.asyncServiceRequestNotesService.getNotesByAsyncRequestId(this.row.id, { takeOne: false }),
        ]);
      }),
      switchMap(([serviceableAddress, orgData, notes]) => this.createFinalData(serviceableAddress, orgData, notes)),
    );
  }

  public ngOnChanges(changes: NgChanges<InboxCallbackDetailCallerDetailsComponent>) {
    if (!isEqual(changes.row.currentValue, changes.row.previousValue) && !isNil(this.row)) {
      this.changes.next();
    }
  }
}
