import type { ValidationResult } from 'joi';

import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { FormRequestName } from '../../../form-request-name';
import { FormResponse } from '../../generic/form-response/form-response';
import { FormResponseSchema } from '../../generic/form-response/form-response-schema';

import { FormResponseCreateSubmissionConstructor } from './form-response-create-submission-constructor';
import { FormResponseCreateSubmissionError } from './form-response-create-submission-error';
import { FormResponseCreateSubmissionSchema } from './form-response-create-submission-schema';

export class FormResponseCreateSubmission extends FormResponse<FormResponseCreateSubmissionError> {
  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: FormResponseCreateSubmissionConstructor) {
    super({
      ...parameters,
      [FormResponseSchema.type]: FormRequestName.createSubmission,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  protected static _deserialize(validationResult: ValidationResult): FormResponseCreateSubmission {
    return new FormResponseCreateSubmission(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new FormResponseCreateSubmissionSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(FormResponseCreateSubmission.getSchema());
  }
}
