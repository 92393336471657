import { cloneDeep } from 'lodash';

import { SerializableObject } from '../../../../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../../../../generic/serialization/serializable-object-schema';
import { CommunicationWorkflowTargetTimeoutNameType } from '../communication-workflow-target-timeout-name';

export abstract class CommunicationWorkflowTargetTimeout extends SerializableObject {
  protected type!: CommunicationWorkflowTargetTimeoutNameType;

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    throw new Error('Error: Cannot get schema for abstract CommunicationWorkflowTargetTimeout');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getType(): CommunicationWorkflowTargetTimeoutNameType {
    return cloneDeep(this.type);
  }
}
