import { SchemaField } from '../../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../../generic/serialization/serializable-object-schema';

export class CallListExtensionConfigSchema extends SerializableObjectSchema {
  public static readonly extension = 'extension';

  static Defaults = class {
    public static readonly extension = undefined;
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;
    parameters[CallListExtensionConfigSchema.extension] = SchemaField.string(
      CallListExtensionConfigSchema.Defaults.extension,
    );
    return parameters;
  }
}
