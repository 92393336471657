import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';

import { DBDocObject } from '../../generic/db-doc/db-doc-object';
import { DBDocSchema } from '../../generic/db-doc/db-doc-schema';

import { UsageCategory } from './usage-category';
import { UsageConstructor } from './usage-constructor';
import { UsageSchema } from './usage-schema';

export class Usage extends DBDocObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected windowStart!: moment.Moment;

  protected windowEnd!: moment.Moment;

  protected asOf!: moment.Moment;

  protected category!: UsageCategory;

  protected usage!: number;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: UsageConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): Usage {
    return new Usage(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(Usage.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new UsageSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getWindowStart(): moment.Moment {
    return cloneDeep(this.windowStart);
  }

  public getWindowEnd(): moment.Moment {
    return cloneDeep(this.windowEnd);
  }

  public getAsOf(): moment.Moment {
    return cloneDeep(this.asOf);
  }

  public getCategory(): UsageCategory {
    return cloneDeep(this.category);
  }

  public getUsage(): number {
    return cloneDeep(this.usage);
  }
}
