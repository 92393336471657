<ng-template #objEditor>
  <form *ngIf="!loading" [formGroup]="form">
    <div class="form-row">
      <div class="col">
        <!-- Display Name -->
        <mat-form-field class="full-width">
          <textarea class="full-width" formControlName="displayName" matInput placeholder="Display Name"></textarea>
          <mat-error *ngIf="displayName.errors && displayName.dirty"> Display Name Invalid </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Description -->
        <mat-form-field class="full-width">
          <textarea class="full-width" formControlName="description" matInput placeholder="Description"></textarea>
          <mat-error *ngIf="description.errors && description.dirty"> Description Invalid </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row">
      <div class="col">
        <!-- Chat Service SID -->
        <mat-form-field class="full-width">
          <input class="full-width" formControlName="chatServiceSid" matInput placeholder="Chat Service SID" />
          <mat-error *ngIf="chatServiceSid.errors && chatServiceSid.dirty"> Invalid Chat Service SID </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Anonymous Participant Role SID -->
        <mat-form-field class="full-width">
          <input
            class="full-width"
            formControlName="roleSidAnonymousParticipant"
            matInput
            placeholder="Anonymous Participant Role SID"
          />
          <mat-error *ngIf="roleSidAnonymousParticipant.errors && roleSidAnonymousParticipant.dirty">
            Invalid Anonymous Participant Role SID
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Internal Participant Role SID -->
        <mat-form-field class="full-width">
          <input
            class="full-width"
            formControlName="roleSidInternalParticipant"
            matInput
            placeholder="Internal Participant Role SID"
          />
          <mat-error *ngIf="roleSidInternalParticipant.errors && roleSidInternalParticipant.dirty">
            Invalid Internal Participant Role SID
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row">
      <div class="col">
        <!-- Closed After Duration -->
        <mat-form-field class="full-width">
          <input
            class="full-width"
            formControlName="closedAfterDuration"
            matInput
            placeholder="Closed After Duration"
          />
          <mat-error *ngIf="closedAfterDuration.errors && closedAfterDuration.dirty">
            Invalid Closed After Duration
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Inactive After Duration -->
        <mat-form-field class="full-width">
          <input
            class="full-width"
            formControlName="inactiveAfterDuration"
            matInput
            placeholder="Inactive After Duration"
          />
          <mat-error *ngIf="inactiveAfterDuration.errors && inactiveAfterDuration.dirty">
            Invalid Inactive After Duration
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- PII Retention Duration -->
        <mat-form-field class="full-width">
          <input
            class="full-width"
            formControlName="pIIRetentionDuration"
            matInput
            placeholder="PII Retention Duration"
          />
          <mat-error *ngIf="pIIRetentionDuration.errors && pIIRetentionDuration.dirty">
            Invalid PII Retention Duration
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        <!-- Redirect URL -->
        <mat-form-field class="full-width">
          <input class="full-width" formControlName="redirectURL" matInput placeholder="Redirect URL" />
          <mat-error *ngIf="redirectURL.errors && redirectURL.dirty"> Invalid Redirect URL </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        <label class="flex flex-column gap-1 p-fluid">
          Phones
          <p-chips
            class="full-width"
            formControlName="phones"
            [addOnBlur]="true"
            [addOnTab]="true"
            [allowDuplicate]="false"
            [placeholder]="'E164 Phone'"
          ></p-chips>
        </label>
      </div>

      <div class="col">
        <label class="flex flex-column gap-1 p-fluid">
          Messaging Service SID
          <input formControlName="messagingServiceSid" pInputText placeholder="Messaging service SID" />
        </label>
      </div>
    </div>
  </form>

  <mat-accordion *ngIf="!loading">
    <!-- This container holds all Service Providers -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Service Providers </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <app-callee-entity-select-and-edit
        [eventTypes]="eventTypes"
        [userDataArray]="userDataArray"
        [(objList)]="serviceProviders"
      >
      </app-callee-entity-select-and-edit>
    </mat-expansion-panel>

    <!-- This container holds onCannotProvideService -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> On Cannot Provide Service </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <app-vrc-list-editor [(objList)]="onCannotProvideService"></app-vrc-list-editor>
    </mat-expansion-panel>

    <!-- Waiting Room Config-->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Waiting Room Config </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <app-conversation-waiting-room-config [(obj)]="waitingRoomConfig"></app-conversation-waiting-room-config>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>

<app-obj-select-and-edit
  [doDelete]="doDelete"
  [doRefresh]="doRefresh"
  [doUpload]="doUpload"
  [editorTemplate]="objEditor"
  [label]="'Conversation Config'"
  [modifiedSelectedItem]="getUpdatedObj()"
  [objIsValid]="isValidObjScoper"
  [orderedItems]="itemsArray | async"
  [selectedKVPair]="selectedKVPair"
  (duplicateClick)="duplicateClick()"
  (newClick)="newClick()"
  (selectedKVPairChange)="onSelectedKVPairChange($event)"
>
</app-obj-select-and-edit>
