import { DBDocSchema } from '../../generic/db-doc/db-doc-schema';
import { SchemaField } from '../../generic/serialization/schema-field';

export class RolesSchema extends DBDocSchema {
  public static readonly roles = 'roles';

  public static readonly orgNote = 'orgNote';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'roles';
  };

  static Defaults = class {
    public static readonly roles: string[] = [];

    public static readonly orgNote = '';
  };

  static Roles = class {
    public static readonly conversation = 'conversation';

    public static readonly orgAdmin = 'orgAdmin';

    public static readonly support = 'support';

    public static readonly voicemail = 'voicemail';
  };

  static CustomClaims = class {
    public static readonly orgId = 'orgId';
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Default Values
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([RolesSchema.orgNote, RolesSchema.roles]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    parameters[RolesSchema.orgNote] = SchemaField.string(RolesSchema.Defaults.orgNote);
    parameters[RolesSchema.roles] = SchemaField.shortStringArray(RolesSchema.Defaults.roles).items(
      RolesSchema.Roles.orgAdmin,
    );

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${RolesSchema.Constants.collection}`;
  }
}
