import type { ValidationResult } from 'joi';

import { AnySerializableObject } from '../../../../generic/serialization/any-serializable-object/any-serializable-object';
import { ObjectBuilder } from '../../../../generic/serialization/interfaces';
import { SerializableObjectSchema } from '../../../../generic/serialization/serializable-object-schema';
import { AutomationActionName } from '../../automation-action-name';
import { AutomationActionSendEmail } from '../../type/automation-action-send-email/automation-action-send-email';

import { AnyAutomationActionSchema } from './any-automation-action-schema';

export abstract class AnyAutomationAction extends AnySerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  protected static getObjectBuilder(validationResult: ValidationResult): ObjectBuilder {
    const typeString = validationResult.value[AnyAutomationActionSchema.type];
    const type = AutomationActionName[typeString as keyof typeof AutomationActionName];

    if (type === undefined) {
      throw new Error(`AnyAutomationAction._deserialize: User Error: Cannot deserialize obj with type=${typeString}`);
    }

    switch (type) {
      case AutomationActionName.sendEmail:
        return AutomationActionSendEmail;
      default:
        throw new Error(`AnyAutomationAction._deserialize Unknown type: ${type}`);
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new AnyAutomationActionSchema();
  }
}
