import { ChangeDetectorRef, Component, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { isNil } from 'lodash';

import {
  DBDocSchema,
  SSMLAudio,
  SSMLAudioSchema,
  SupportedLanguages,
  VRCAudioBlobLocation,
  VRCAudioMetadata,
  VRCAudioMetadataSchema,
} from '@pwp-common';

import { getFieldValuesFromFormInComponent } from '../../../../common/objects/form-helper';
import { KVPair } from '../../../../common/objects/kvpair';
import { DBQuery } from '../../../../services/generic/interfaces';
import { AuthService } from '../../../../services/user/auth/auth.service';
import { VRCAudioBlobService } from '../../../../services/vrc-audio/vrc-audio-blob/vrc-audio-blob.service';
import { VRCAudioMetadataService } from '../../../../services/vrc-audio/vrc-audio-metadata/vrc-audio-metadata.service';
import { ConfigDocSelectAndEdit } from '../../../generic/abstract-classes/config-doc-select-and-edit';
import { FileSSMLAudioPair } from '../generic/file-ssml-audio-pair';

@Component({
  selector: 'app-vrc-audio-metadata-select-and-edit',
  templateUrl: './vrc-audio-metadata-select-and-edit.component.html',
  styleUrls: ['./vrc-audio-metadata-select-and-edit.component.css'],
})
export class VrcAudioMetadataSelectAndEditComponent
  extends ConfigDocSelectAndEdit<VRCAudioMetadata>
  implements OnInit, OnChanges
{
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  audioArray: FileSSMLAudioPair[];

  selectedSSMLAudioKVPair: KVPair<FileSSMLAudioPair>;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  possibleLanguages = SupportedLanguages.all;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private vrcAudioBlobService: VRCAudioBlobService,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    // @ts-ignore
    private _dataService: VRCAudioMetadataService,
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    super(VRCAudioMetadata, _dataService, _changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Items Query
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * To prevent difficult to debug race conditions, only allow editing of documents that have already been
   * processed by the server.
   */
  itemsQuery(): DBQuery[] {
    return [
      {
        fieldPath: VRCAudioMetadataSchema.wasProcessedByServer,
        opStr: '==',
        value: true,
      },
    ];
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parameters = getFieldValuesFromFormInComponent(
      this.selectedKVPair?.value?.getId(),
      [VRCAudioMetadataSchema.displayName, VRCAudioMetadataSchema.description],
      VRCAudioMetadataSchema.Defaults,
      this,
    );

    const audio = new Map<string, SSMLAudio>();
    for (const item of this.audioArray) {
      audio.set(item.languageDefaults.getShortCode(), item.ssmlAudio);
    }
    parameters[VRCAudioMetadataSchema.audio] = audio;
    const obj = new VRCAudioMetadata(parameters);
    obj.setWasProcessedByServer(false);
    return obj;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: VRCAudioMetadata) {
    // Init Form
    const formConfig = {} as any;
    formConfig[VRCAudioMetadataSchema.displayName] = [
      obj.getDisplayName(),
      [Validators.required, Validators.maxLength(500)],
    ];
    formConfig[VRCAudioMetadataSchema.description] = [
      obj.getDescription(),
      [Validators.required, Validators.maxLength(500)],
    ];

    const audioArray = [];
    for (const [key, value] of obj.getAudio().entries()) {
      const item: FileSSMLAudioPair = new FileSSMLAudioPair(SupportedLanguages.getDefaults(key), value, undefined);
      audioArray.push(item);
    }
    this.audioArray = audioArray;
    this.form = this.formBuilder.group(formConfig);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  get displayName(): AbstractControl | null {
    return this.form.get(VRCAudioMetadataSchema.displayName);
  }

  get description(): AbstractControl | null {
    return this.form.get(VRCAudioMetadataSchema.description);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Callbacks
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  async uploadClick() {
    // Must first upload audio, this method modifes the updatedObj
    await this.uploadAudio();
    await super.uploadClick();
  }

  async uploadAudio() {
    const orgId = await this.authService.getOrgId().toPromise();

    // eslint-disable-next-line
    for (let i = 0; i < this.audioArray.length; i++) {
      const item = this.audioArray[i];
      if (isNil(item.file)) {
        continue;
      }

      // For new documents, the id is not set.
      let docId = this.selectedKVPair.value.getId();
      if (docId === DBDocSchema.GenericDefaults.id) {
        docId = this._dataService.createId();
      }
      const fileFullPath = new VRCAudioBlobLocation().getFileFullPath(orgId, docId, item.languageDefaults);
      console.log(`Upload started: ${fileFullPath}`);
      await this.vrcAudioBlobService
        .upload(fileFullPath, item.file, {
          cacheControl: 'immutable',
          contentType: 'audio/wav',
        })
        .percentageChanges()
        .toPromise();
      console.log(`Upload Complete: ${fileFullPath}`);
      const downloadURL = await this.vrcAudioBlobService.getDownloadLink(fileFullPath).toPromise();

      const { ssmlAudio } = item;
      ssmlAudio.setSSML(SSMLAudioSchema.Defaults.ssml);
      ssmlAudio.setAudioURL(downloadURL);
      this.audioArray[i].ssmlAudio = ssmlAudio;
    }

    console.log('All file uploads complete');
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  //// Is Valid Obj
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObjScoper = (): boolean => this.isValidObj();

  isValidObj(): boolean {
    // This checks that the form is valid.
    if (!super.isValidObj()) {
      return false;
    }
    return true;
  }
}
