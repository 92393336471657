import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { Frequency, RRule, Weekday } from 'rrule';

import { convertRRuleDateToMoment, getRRuleInputFromMoment, OrgData, validateAndGetRRuleFromStr } from '@pwp-common';

import {
  DATETIME_LOCAL_CONTROL_STR_FORMAT,
  getFieldControlValueOrDefault,
  getTimestampFromDateTimeLocalFieldControl,
} from '../../../../common/objects/form-helper';
import { KVPair } from '../../../../common/objects/kvpair';
import { DatetimeValidator } from '../../../../common/validators/datetime-validator/datetime-validator';
import { ObjEditor } from '../../../generic/abstract-classes/obj-editor';

@Component({
  selector: 'app-message-template-rrule-editor',
  templateUrl: './message-template-rrule-editor.component.html',
  styleUrls: ['./message-template-rrule-editor.component.css'],
})
export class MessageTemplateRruleEditorComponent extends ObjEditor<KVPair<string>> implements OnInit {
  @Input() orgData: OrgData;
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  hourly = Frequency.HOURLY;

  daily = Frequency.DAILY;

  weekly = Frequency.WEEKLY;

  monthly = Frequency.MONTHLY;

  yearly = Frequency.YEARLY;

  monday = RRule.MO;

  tuesday = RRule.TU;

  wednesday = RRule.WE;

  thursday = RRule.TH;

  friday = RRule.FR;

  saturday = RRule.SA;

  sunday = RRule.SU;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private formBuilder: UntypedFormBuilder,
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    super(_changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Close Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  public getObjFromForm(): KVPair<string> {
    const rruleInputTime = getTimestampFromDateTimeLocalFieldControl(this.dtstart, undefined, undefined);

    const rrule = new RRule({
      freq: getFieldControlValueOrDefault(this.freq, undefined),
      wkst: getFieldControlValueOrDefault(this.wkst, undefined),
      byweekday: getFieldControlValueOrDefault(this.byweekday, undefined),
      dtstart: getRRuleInputFromMoment(rruleInputTime, new RRule({ tzid: this.orgData.getTimezone() })),
    }).toString();

    return new KVPair({ id: this.obj.getId(), value: rrule });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  public setFormFromObj(obj: KVPair<string>) {
    // Init Form
    const formConfig = {} as any;

    const _rrule = validateAndGetRRuleFromStr(obj.value || '', true);

    const localTimezone = moment.tz.guess();
    const momentDtStart = convertRRuleDateToMoment(_rrule.options.dtstart, localTimezone);
    const dtStartString = cloneDeep(momentDtStart).format(DATETIME_LOCAL_CONTROL_STR_FORMAT);

    formConfig.byweekday = [_rrule.options.byweekday?.map((z) => this.getDay(z))];
    formConfig.freq = [_rrule.options.freq];
    formConfig.wkst = [this.getDay(_rrule.options.wkst)];
    formConfig.dtstart = [dtStartString, [Validators.required, DatetimeValidator.stepSizeSeconds(900)]];

    this.form = this.formBuilder.group(formConfig);
  }

  private getDay(dayNum: number): Weekday {
    const genDay = new Weekday(dayNum);
    for (const day of [
      this.sunday,
      this.monday,
      this.tuesday,
      this.wednesday,
      this.thursday,
      this.friday,
      this.saturday,
    ]) {
      if (genDay.weekday === day.weekday) {
        return day;
      }
    }
    console.error(dayNum);
    throw new Error('getDay: User Error, invalid day:');
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Form Changes
  /////////////////////////////////////////////////////////////////////////////////////////////

  onFormChanges() {
    this.updateObjAndEmit();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Form Field Getters
  /////////////////////////////////////////////////////////////////////////////////////////////

  get dtstart(): AbstractControl | null {
    return this.form.get('dtstart');
  }

  get byweekday(): AbstractControl | null {
    return this.form.get('byweekday');
  }

  get freq(): AbstractControl | null {
    return this.form.get('freq');
  }

  get wkst(): AbstractControl | null {
    return this.form.get('wkst');
  }
}
