import Joi from 'joi';

import { SerializableObjectSchema } from '../../../../../generic/serialization/serializable-object-schema';
import { AnyCommunicationSessionEventLogConferenceSchema } from '../../events/communication-session-event-log-conference/generic/any-communication-session-event-log-conference/any-communication-session-event-log-conference-schema';
import { AnyCommunicationSessionEventLogDialPhoneSchema } from '../../events/communication-session-event-log-dial-phone/generic/any-communication-session-event-log-event-dial-phone/any-communication-session-event-log-dial-phone-schema';
import { AnyCommunicationSessionEventLogSendMessageSchema } from '../../events/communication-session-event-log-send-message/generic/any-communication-session-event-log-send-message/any-communication-session-event-log-send-message-schema';

export class AnyCommunicationSessionEventLogSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The type of the entity
  public static readonly type = 'type';

  private static readonly SCHEMA_ID = 'anyCommunicationSessionEventLog';

  private static computedSchema = Joi.alternatives()
    .try(
      ...[
        new AnyCommunicationSessionEventLogConferenceSchema().getJoiSchema(),
        new AnyCommunicationSessionEventLogDialPhoneSchema().getJoiSchema(),
        new AnyCommunicationSessionEventLogSendMessageSchema().getJoiSchema(),
      ],
    )
    .id(AnyCommunicationSessionEventLogSchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static GenericDefaults = class {
    // The name of the command
    public static readonly type = undefined;
  };

  /**
   * Always serialize the command name
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  public static getSchemaId(): string {
    return `#${AnyCommunicationSessionEventLogSchema.SCHEMA_ID}`;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } | import('joi').AnySchema {
    return AnyCommunicationSessionEventLogSchema.computedSchema;
  }

  public getCollection(): never {
    throw new Error(
      'AnyCommunicationSessionEventLogSchema.getCollection: User error. Cannot call AnyCommunicationSessionEventLogSchema.getCollection because no collection is specified',
    );
  }

  public getCollectionName(): never {
    throw new Error(
      'AnyCommunicationSessionEventLogSchema.getCollectionName: User error. Cannot call AnyCommunicationSessionEventLogSchema.getCollectionName because no collection is specified',
    );
  }
}
