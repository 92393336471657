import { cloneDeep } from 'lodash';

import { SerializableObjectSchema } from '../../../../../../generic/serialization/serializable-object-schema';
import { ReservationSkipOffersIfRule } from '../../generic/reservation-skip-offers-if-rule/reservation-skip-offers-if-rule';
import { ReservationSkipOffersIfRuleSchema } from '../../generic/reservation-skip-offers-if-rule/reservation-skip-offers-if-rule-schema';
import { ReservationSkipOffersIfRuleName } from '../../generic/reservation-skip-offers-if-rule-name';

import { ReservationSkipOffersIfRuleChannelMaxChannelName } from './reservation-skip-offers-if-rule-channel-max-channel-name';
import { ReservationSkipOffersIfRuleChannelMaxConstructor } from './reservation-skip-offers-if-rule-channel-max-constructor';
import { ReservationSkipOffersIfRuleChannelMaxSchema } from './reservation-skip-offers-if-rule-channel-max-schema';

export class ReservationSkipOffersIfRuleChannelMax extends ReservationSkipOffersIfRule {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected channel!: ReservationSkipOffersIfRuleChannelMaxChannelName;

  protected skipIfInProgressGE!: number;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ReservationSkipOffersIfRuleChannelMaxConstructor) {
    (parameters as any)[ReservationSkipOffersIfRuleSchema.type] = ReservationSkipOffersIfRuleName.channelMax;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(
    validationResult: import('joi').ValidationResult,
  ): ReservationSkipOffersIfRuleChannelMax {
    return new ReservationSkipOffersIfRuleChannelMax(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ReservationSkipOffersIfRuleChannelMax.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ReservationSkipOffersIfRuleChannelMaxSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getChannel() {
    return cloneDeep(this.channel);
  }

  public getSkipIfInProgressGE() {
    return cloneDeep(this.skipIfInProgressGE);
  }
}
