import { AllDataEvent, AssignedUserType, EventRequest, OrgData } from '@pwp-common';

import { AllRequestsForOneEventSlotSummary } from '../../../common/all-requests-for-one-event-slot-summary';

export const getEventRequestRows = (
  allDataEventList: AllDataEvent[],
  orgData: OrgData,
): AllRequestsForOneEventSlotSummary[] =>
  allDataEventList.reduce((acc, allDataEvent) => {
    const primarySlotRequests = new Map<string, EventRequest>();
    const backupSlotRequests = new Map<string, EventRequest>();

    const getSlotRequests = (slot: AssignedUserType) =>
      slot === AssignedUserType.backup ? backupSlotRequests : primarySlotRequests;

    allDataEvent.getRequests().forEach((request) => {
      getSlotRequests(request.getRequestedSlot()).set(request.getId(), request);
    });

    const createRowsForSlot = (requestedSlot: AssignedUserType) => {
      const requests = getSlotRequests(requestedSlot);

      if (requests.size === 0) {
        return [];
      }

      const allDataEventForSlot = new AllDataEvent(
        allDataEvent.getEventData(),
        allDataEvent.getAllDataUserMap(),
        requests,
      );

      return [new AllRequestsForOneEventSlotSummary(allDataEventForSlot, orgData, requestedSlot)];
    };

    return [...acc, ...createRowsForSlot(AssignedUserType.primary), ...createRowsForSlot(AssignedUserType.backup)];
  }, []);
