import { CommunicationSessionId } from '../../../../../objects/core/communication-session-id/communication-session-id';
import { SchemaField } from '../../../../../objects/generic/serialization/schema-field';
import { CommunicationsRequestSchema } from '../../generic/communications-request/communications-request-schema';

export class CommunicationsRequestCreateAsyncServiceRequestSchema extends CommunicationsRequestSchema {
  public static readonly asyncServiceRequestId = 'asyncServiceRequestId';

  public static readonly createAsyncServiceRequestWidgetId = 'createAsyncServiceRequestWidgetId';

  public static readonly e164Phone = 'e164Phone';

  public static readonly sessionId = 'sessionId';

  public static readonly note = 'note';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;

    public static readonly asyncServiceRequestId = undefined;

    public static readonly createAsyncServiceRequestWidgetId = undefined;

    public static readonly e164Phone = undefined;

    public static readonly sessionId = undefined;

    public static readonly note = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationsRequestCreateAsyncServiceRequestSchema.asyncServiceRequestId] =
      SchemaField.autoGeneratedDocId(
        CommunicationsRequestCreateAsyncServiceRequestSchema.Defaults.asyncServiceRequestId,
      ).required();
    parameters[CommunicationsRequestCreateAsyncServiceRequestSchema.createAsyncServiceRequestWidgetId] =
      SchemaField.autoGeneratedDocId(
        CommunicationsRequestCreateAsyncServiceRequestSchema.Defaults.createAsyncServiceRequestWidgetId,
      ).required();
    parameters[CommunicationsRequestCreateAsyncServiceRequestSchema.e164Phone] = SchemaField.e164Phone(
      CommunicationsRequestCreateAsyncServiceRequestSchema.Defaults.e164Phone,
    ).required();
    parameters[CommunicationsRequestCreateAsyncServiceRequestSchema.sessionId] = SchemaField.obj(
      CommunicationSessionId,
      CommunicationsRequestCreateAsyncServiceRequestSchema.Defaults.sessionId,
    ).required();
    parameters[CommunicationsRequestCreateAsyncServiceRequestSchema.note] = SchemaField.string(
      CommunicationsRequestCreateAsyncServiceRequestSchema.Defaults.note,
    ).optional();
    /* eslint-enable */

    return parameters;
  }
}
