import { values } from 'lodash';

import { UserStatus } from '../../../../../call/dialed-call-log/user-status';
import { DBDocSchema } from '../../../../../generic/db-doc/db-doc-schema';
import { SchemaField } from '../../../../../generic/serialization/schema-field';
import { CommunicationTaskName } from '../../../../communication-task/generic/communication-task-name';

export class CommunicationSessionEventLogReservationOfferSchema extends DBDocSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly communicationTaskType = 'communicationTaskType';

  public static readonly userStatus = 'userStatus';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly communicationTaskType = undefined;

    public static readonly userStatus = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[CommunicationSessionEventLogReservationOfferSchema.communicationTaskType] = SchemaField.string(
      CommunicationSessionEventLogReservationOfferSchema.communicationTaskType,
    )
      .allow(...values(CommunicationTaskName))
      .required();
    parameters[CommunicationSessionEventLogReservationOfferSchema.userStatus] = SchemaField.string(
      CommunicationSessionEventLogReservationOfferSchema.userStatus,
      CommunicationSessionEventLogReservationOfferSchema.Defaults.userStatus,
    )
      .optional()
      .valid(...values(UserStatus));
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(): string {
    throw new Error(
      'CommunicationSessionEventLogReservationOfferSchema.getCollection: User error. Cannot call CommunicationSessionEventLogReservationOfferSchema.getCollection because no collection is specified',
    );
  }
}
