import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { TooltipModule } from 'primeng/tooltip';

import { DataTableModule } from '../../generic/data-table/data-table.module';
import { SettingsModule } from '../../generic/settings/settings.module';
import { ArrayOfPhonesEditorModule } from '../array-of-phones-editor/array-of-phones-editor.module';
import { ServiceOperationEditorModule } from '../service-operation-editor/service-operation-editor.module';

import { ServiceExceptionEditorComponent } from './editor/service-exception-editor/service-exception-editor.component';
import { ServiceExceptionsTableComponent } from './editor-table/service-exceptions-table/service-exceptions-table.component';
import { ServiceExceptionEditorDialogComponent } from './service-exception-editor-dialog/service-exception-editor-dialog.component';
import { ServiceExceptionEditorWithSaveComponent } from './service-exception-editor-with-save/service-exception-editor-with-save.component';

@NgModule({
  declarations: [
    ServiceExceptionEditorComponent,
    ServiceExceptionEditorDialogComponent,
    ServiceExceptionEditorWithSaveComponent,
    ServiceExceptionsTableComponent,
  ],
  imports: [
    ArrayOfPhonesEditorModule,
    ButtonModule,
    CommonModule,
    DataTableModule,
    InputTextareaModule,
    InputTextModule,
    MessagesModule,
    ReactiveFormsModule,
    ServiceOperationEditorModule,
    SettingsModule,
    SharedModule,
    TooltipModule,
    TranslocoModule,
  ],
  exports: [
    ServiceExceptionEditorComponent,
    ServiceExceptionEditorDialogComponent,
    ServiceExceptionEditorWithSaveComponent,
    ServiceExceptionsTableComponent,
  ],
})
export class ServiceExceptionModule {}
