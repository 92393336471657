<ng-template #objEditor>
  <form *ngIf="!loading" [formGroup]="form">
    <div class="form-row">
      <div class="col">
        <!-- Display Name -->
        <mat-form-field class="full-width">
          <textarea class="full-width" formControlName="displayName" matInput placeholder="Display Name"></textarea>
          <span *ngIf="displayName.invalid && displayName.touched"> Display Name Invalid </span>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Description -->
        <mat-form-field class="full-width">
          <textarea class="full-width" formControlName="description" matInput placeholder="Description"></textarea>
          <span *ngIf="description.invalid && description.touched"> Description Invalid </span>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row">
      <div class="col">
        <!-- Reply To -->
        <mat-form-field class="full-width">
          <span matPrefix>&nbsp;</span>
          <input class="full-width" formControlName="e164Phone" matInput placeholder="E164Phone" />
          <span *ngIf="e164Phone.invalid && e164Phone.touched"> Invalid Phone </span>
        </mat-form-field>
      </div>
    </div>
  </form>
</ng-template>

<app-obj-select-and-edit
  [doDelete]="doDelete"
  [doRefresh]="doRefresh"
  [doUpload]="doUpload"
  [editorTemplate]="objEditor"
  [label]="'Caller Id'"
  [modifiedSelectedItem]="getUpdatedObj()"
  [objIsValid]="isValidObjScoper"
  [orderedItems]="itemsArray | async"
  [selectedKVPair]="selectedKVPair"
  (duplicateClick)="duplicateClick()"
  (newClick)="newClick()"
  (selectedKVPairChange)="onSelectedKVPairChange($event)"
>
</app-obj-select-and-edit>
