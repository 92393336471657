import Joi from 'joi';

import { SerializableObjectSchema } from '../../../../../generic/serialization/serializable-object-schema';
import { DBMatchRuleAnonymousPhoneSchema } from '../../db-match-rules/db-match-rule-anonymous-phone/db-match-rule-anonymous-phone-schema';
import { DBMatchRuleE164PhoneSchema } from '../../db-match-rules/db-match-rule-e164-phone/db-match-rule-e164-phone-schema';

export class AnyDBMatchRuleSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The type of the entity
  public static readonly type = 'type';

  private static readonly SCHEMA_ID = 'anyDBMatchRule';

  private static computedSchema = Joi.alternatives()
    .try(...[new DBMatchRuleE164PhoneSchema().getJoiSchema(), new DBMatchRuleAnonymousPhoneSchema().getJoiSchema()])
    .id(AnyDBMatchRuleSchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static GenericDefaults = class {
    // The name of the command
    public static readonly type = undefined;
  };

  /**
   * Always serialize the command name
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  public static getSchemaId(): string {
    return `#${AnyDBMatchRuleSchema.SCHEMA_ID}`;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } | import('joi').AnySchema {
    return AnyDBMatchRuleSchema.computedSchema;
  }
}
