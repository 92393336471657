import { cloneDeep } from 'lodash';

import { SerializableObject } from '../../../../objects/generic/serialization/serializable-object';

export class Response<ResponseType = string, ErrorType = string> extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected error!: ErrorType;

  protected type!: ResponseType;

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getType(): ResponseType {
    return cloneDeep(this.type);
  }

  public getError(): ErrorType {
    return cloneDeep(this.error);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  public setError(error: any): Response<ResponseType, ErrorType> {
    this.error = error;
    return this;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
}
