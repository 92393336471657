import { SchemaField } from '../../../../generic/serialization/schema-field';
import { CommunicationWidgetSchema } from '../../generic/communication-widget/communication-widget-schema';

export class CommunicationWidgetEnforceServiceLimitSchema extends CommunicationWidgetSchema {
  public static readonly serviceLimitExceededAudioId = 'serviceLimitExceededAudioId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;

    public static readonly description = 'missing-description';

    public static readonly displayName = 'missing-displayName';

    public static readonly serviceLimitExceededAudioId = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationWidgetEnforceServiceLimitSchema.serviceLimitExceededAudioId] =
      SchemaField.autoGeneratedDocId(CommunicationWidgetEnforceServiceLimitSchema.Defaults.serviceLimitExceededAudioId);
    /* eslint-enable */

    return parameters;
  }
}
