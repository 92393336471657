import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';

import { VoicemailBlobLocation } from '@pwp-common';

import { VoicemailBlobService } from '../../../../../../services/voicemail/voicemail-blob/voicemail-blob.service';
import { VoicemailMetadataService } from '../../../../../../services/voicemail/voicemail-metadata/voicemail-metadata.service';
import { VoicemailRow } from '../../../../row/voicemail-row/voicemail-row';
import { InboxService } from '../../../../services/inbox-service/inbox.service';

@Component({
  selector: 'app-inbox-voicemail-play',
  templateUrl: './inbox-voicemail-play.component.html',
  styleUrls: ['./inbox-voicemail-play.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class InboxVoicemailPlayComponent implements OnInit {
  ///////////////////////////////////////////////////////////////////////
  // Input
  ///////////////////////////////////////////////////////////////////////
  @Input() row: VoicemailRow;

  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  playURL: string;

  errorGettingPlayURL = false;

  loading = true;
  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private voicemailBlobService: VoicemailBlobService,
    private inboxService: InboxService,
    private voicemailMetadataService: VoicemailMetadataService,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    void this.play();
  }

  ///////////////////////////////////////////////////////////////////////
  // Play
  ///////////////////////////////////////////////////////////////////////

  private async play() {
    this.loading = true;
    this.changeDetectorRef.detectChanges();

    try {
      const { voicemailMetadata } = this.row.params;
      const orgData = await firstValueFrom(this.inboxService.$orgData);
      const fileFullPath = new VoicemailBlobLocation().getFileFullPath(
        orgData.getId(),
        voicemailMetadata.getRecordingSid(),
      );
      this.playURL = await firstValueFrom(this.voicemailBlobService.getDownloadLink(fileFullPath, false));
      await this.voicemailMetadataService.markAsListenedToByLoggedInUser(voicemailMetadata);
    } catch (error) {
      switch (error.code) {
        case 'storage/object-not-found': {
          this.errorGettingPlayURL = true;
          this.messageService.add({
            severity: 'warn',
            summary: translate('inbox-voicemail-play.objNotFoundTitle'),
            detail: translate('inbox-voicemail-play.objNotFoundBody'),
          });
          return;
        }
        default: {
          console.warn(error);
        }
      }
    } finally {
      this.loading = false;
      this.changeDetectorRef.detectChanges();
    }
  }
}
