import { SchemaField } from '../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';
import { TemplateText } from '../../../text/template-text/template-text';

export class ReservationOfferChannelConfigTextSchema extends SerializableObjectSchema {
  public static readonly callerIdObjId = 'callerIdObjId';

  public static readonly body = 'body';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly callerIdObjId = undefined;

    public static readonly body = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // prettier-ignore
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[ReservationOfferChannelConfigTextSchema.callerIdObjId] = SchemaField.autoGeneratedDocId(ReservationOfferChannelConfigTextSchema.Defaults.callerIdObjId, ReservationOfferChannelConfigTextSchema.Defaults.callerIdObjId).optional()
    parameters[ReservationOfferChannelConfigTextSchema.body] = SchemaField.obj(TemplateText, ReservationOfferChannelConfigTextSchema.Defaults.body).required()
    /* eslint-enable */

    return parameters;
  }
}
