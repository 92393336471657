import { cloneDeep, isNil } from 'lodash';

import { defaultTargetEditorOutput } from '../../default-target-editor-output';
import { TargetEditorOutput } from '../../target-editor-output';

import { TargetEditorFormType } from './target-editor-form-type';

/**
 * For a given formType return a sensible value of AllDataCommunicationWorkflowEditorTargetMode.
 */
export const formToTargetEditorOutput = (
  base: TargetEditorOutput,
  formType: TargetEditorFormType,
): TargetEditorOutput => {
  if (isNil(formType)) {
    return cloneDeep(defaultTargetEditorOutput);
  }

  const result: TargetEditorOutput = {
    ...base,
    ...formType,
  };

  return result;
};
