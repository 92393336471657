import { cloneDeep } from 'lodash';
import Mustache from 'mustache';

import { SerializableObject } from '../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../generic/serialization/serializable-object-schema';
import { TemplateText } from '../../text/template-text/template-text';
import { LanguageDefaults } from '../../voice-response-command/vrc-audio-metadata/language-defaults';

import { ConversationWaitingRoomConstructor } from './conversation-waiting-room-constructor';
import { ConversationWaitingRoomSchema } from './conversation-waiting-room-schema';

export class ConversationWaitingRoom extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected queuePosition!: number;

  protected waitingRoomMessage!: TemplateText;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ConversationWaitingRoomConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ConversationWaitingRoom {
    return new ConversationWaitingRoom(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ConversationWaitingRoom.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ConversationWaitingRoomSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getQueuePosition(): number {
    return cloneDeep(this.queuePosition);
  }

  public getWaitingRoomMessage(language: LanguageDefaults): string {
    const localizedTemplate =
      this.waitingRoomMessage.getText().get(language.getShortCode()) ||
      `Missing Template for language ${language.getDisplayName()}`;

    return Mustache.render(localizedTemplate, { queuePosition: this.queuePosition });
  }
}
