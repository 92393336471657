import { CallerIdStrategy } from '../../../../communication/caller-id-strategy/caller-id-strategy';
import { OfferChannelConfigPhoneCallMachineDetection } from '../../../../communication/reservation-offer/offer-channel-config-phone-call-machine-detection/offer-channel-config-phone-call-machine-detection';
import { SchemaField } from '../../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../../generic/serialization/serializable-object-schema';

export class CallListOfferChannelConfigPhoneCallSchema extends SerializableObjectSchema {
  public static readonly callerIdStrategy = 'callerIdStrategy';

  public static readonly machineDetection = 'machineDetection';

  static Defaults = class {
    public static readonly callerIdStrategy = undefined;

    public static readonly machineDetection = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[CallListOfferChannelConfigPhoneCallSchema.callerIdStrategy] = SchemaField.obj(
      CallerIdStrategy,
      CallListOfferChannelConfigPhoneCallSchema.Defaults.callerIdStrategy,
    ).optional();
    parameters[CallListOfferChannelConfigPhoneCallSchema.machineDetection] = SchemaField.obj(
      OfferChannelConfigPhoneCallMachineDetection,
      CallListOfferChannelConfigPhoneCallSchema.Defaults.machineDetection,
    ).optional();
    /* eslint-enable */

    return parameters;
  }
}
