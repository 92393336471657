import { SchemaField } from '../../generic/serialization/schema-field';
import { VoiceResponseCommandName } from '../any-voice-response-command/voice-response-command-name';
import { VoiceResponseCommandSchema } from '../generic/voice-response-command-schema';

export class TakeVoicemailSchema extends VoiceResponseCommandSchema {
  // The language to say it in
  public static readonly voicemailConfigId = 'voicemailConfigId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly commandName = VoiceResponseCommandName.takeVoicemail;

    public static readonly voicemailConfigId = 'voicemailConfigId';
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } | import('joi').AnySchema {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[VoiceResponseCommandSchema.commandName] = SchemaField.string(TakeVoicemailSchema.Defaults.commandName);
    parameters[TakeVoicemailSchema.voicemailConfigId] = SchemaField.string(
      TakeVoicemailSchema.Defaults.voicemailConfigId,
    );
    /* eslint-enable */

    return parameters;
  }
}
