import { values } from 'lodash';

import { UserStatus } from '../../../../../../../call/dialed-call-log/user-status';
import { SchemaField } from '../../../../../../../generic/serialization/schema-field';
import { CommunicationSessionEventLogDialPhoneSchema } from '../../generic/communication-session-event-log-dial-phone/communication-session-event-log-dial-phone-schema';

export class CommunicationSessionEventLogDialConferenceSchema extends CommunicationSessionEventLogDialPhoneSchema {
  public static readonly requestedByUserId = 'requestedByUserId';

  public static readonly userStatus = 'userStatus';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////
  static Defaults = class {
    ///////////////////////////////////////////////////////
    // Generic InboundCallEvent Properties, Copy/Pasted Here
    ///////////////////////////////////////////////////////

    public static readonly type = undefined;

    public static readonly actor = undefined;

    public static readonly timestamp = undefined;

    ///////////////////////////////////////////////////////
    // Generic Properties, Copy/Pasted Here
    ///////////////////////////////////////////////////////
    /**
     * Timestamps
     */
    public static readonly initiatedTimestamp = undefined;

    public static readonly ringingTimestamp = undefined;

    public static readonly answeredTimestamp = undefined;

    public static readonly completedTimestamp = undefined;

    /**
     * Operator Tracking
     */
    public static readonly errorCode = undefined;

    public static readonly operatorDurationMS = undefined;

    public static readonly operatorPriceUnit = undefined;

    public static readonly operatorBasePricePM = undefined;

    public static readonly operatorCountryCode = undefined;

    /**
     * IVR
     */
    public static readonly responses = [];

    /**
     * Other
     */
    public static readonly callerIdObjId = undefined;

    public static readonly sipResponseCode = undefined;

    public static readonly operatorCallStatus = undefined;

    public static readonly callSid = undefined;

    ///////////////////////////////////////////////////////
    // CommunicationSessionEventLogDialPhone Properties
    ///////////////////////////////////////////////////////

    /**
     * Tracking Info
     */
    public static readonly callType = undefined;

    public static readonly participant = undefined;

    ///////////////////////////////////////////////////////
    // DialConference Properties
    ///////////////////////////////////////////////////////
    public static readonly requestedByUserId = undefined;

    public static readonly userStatus = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationSessionEventLogDialConferenceSchema.requestedByUserId] = SchemaField.userId(
      CommunicationSessionEventLogDialConferenceSchema.requestedByUserId,
    );
    parameters[CommunicationSessionEventLogDialConferenceSchema.userStatus] = SchemaField.string(
      CommunicationSessionEventLogDialConferenceSchema.userStatus,
      CommunicationSessionEventLogDialConferenceSchema.Defaults.userStatus,
    )
      .optional()
      .valid(...values(UserStatus));
    /* eslint-enable */

    return parameters;
  }
}
