import { isNil } from 'lodash';

import { eventConfigOverlapsWithEvent } from '../../../event-config/helpers/event-config-overlaps-with-event/event-config-overlaps-with-event';

import { EventOverlapType } from './event-overlap-type';
import { EventOverlapData, GetOverlappingCurrentOrFutureEventInput } from './interfaces';

export const getOverlappingCurrentOrFutureEvent = (
  params: GetOverlappingCurrentOrFutureEventInput,
): EventOverlapData | null => {
  const { eventData, orgData, eventConfigs, existingEvents } = params;

  const [overlappingExistingEvent] = existingEvents;

  if (!isNil(overlappingExistingEvent)) {
    return {
      start: overlappingExistingEvent.getStart().toDate(),
      end: overlappingExistingEvent.getEnd().toDate(),
      type: EventOverlapType.existingEvent,
    };
  }

  if (
    eventConfigs.some((eventConfig) =>
      eventConfigOverlapsWithEvent({
        eventConfig,
        eventData,
        timezone: orgData.getTimezone(),
      }),
    )
  ) {
    return {
      start: eventData.getStart().toDate(),
      end: eventData.getEnd().toDate(),
      type: EventOverlapType.futureEvent,
    };
  }

  return null;
};
