import { Request } from '../../../../generic/v2/request/request';
import { FormRequestNameType } from '../../../form-request-name';

export abstract class FormRequest extends Request<FormRequestNameType> {
  public getLogMetadata(): Record<string, unknown> {
    return {
      type: this.getType(),
    };
  }
}
