import { SerializableObjectSchema } from '../../../../../generic/serialization/serializable-object-schema';
import { ServiceExceptionAction } from '../service-exception-action/service-exception-action';

import { ServiceExceptionActionInboundCallSessionConstructor } from './service-exception-action-inbound-call-session-constructor';
import { ServiceExceptionActionInboundCallSessionSchema } from './service-exception-action-inbound-call-session-schema';

export class ServiceExceptionActionInboundCallSession extends ServiceExceptionAction {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ServiceExceptionActionInboundCallSessionConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(
    validationResult: import('joi').ValidationResult,
  ): ServiceExceptionActionInboundCallSession {
    return new ServiceExceptionActionInboundCallSession(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ServiceExceptionActionInboundCallSession.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ServiceExceptionActionInboundCallSessionSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
}
