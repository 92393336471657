import { cloneDeep } from 'lodash';

import { SerializableObject } from '../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';

import { ShakenPayloadOrigConstructor } from './shaken-payload-orig-constructor';
import { ShakenPayloadOrigSchema } from './shaken-payload-orig-schema';

export class ShakenPayloadOrig extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected uri!: string | undefined;

  protected tn!: string | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ShakenPayloadOrigConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ShakenPayloadOrig {
    return new ShakenPayloadOrig(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ShakenPayloadOrig.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ShakenPayloadOrigSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getURI(): string | undefined {
    return cloneDeep(this.uri);
  }

  public getTN(): string | undefined {
    return cloneDeep(this.tn);
  }
}
