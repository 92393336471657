<ng-container *transloco="let t; read: 'events-calendar'">
  <ng-container [ngSwitch]="eventTypeSelectMode">
    <p-multiSelect
      *ngSwitchCase="'multiple'"
      appEventTypeSearch
      [defaultLabel]="t('eventTypeSelectLabel')"
      [formControl]="selectedEventTypes"
      [maxSelectedLabels]="2"
      [selectedItemsLabel]="'{0} ' + t('eventTypesSelected') | lowercase"
      (menuOptionSearchCompleted)="selectedEventTypes.setValue([$event.at(0)?.value])"
    >
    </p-multiSelect>

    <p-dropdown
      *ngSwitchCase="'single'"
      appEventTypeSearch
      [formControl]="selectedEventTypes"
      [placeholder]="t('eventTypeSelectLabel')"
      (menuOptionSearchCompleted)="selectedEventTypes.setValue($event.at(0)?.value)"
    >
    </p-dropdown>
  </ng-container>

  <div [appLoading]="loading$ | async">
    <full-calendar class="calendar-container" [options]="options$ | async"> </full-calendar>
  </div>
</ng-container>
