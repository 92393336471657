import { cloneDeep, isNil } from 'lodash';

import { ReservationOfferConfig } from '@pwp-common';

import { defaultReservationOfferEditorOutput } from '../default-reservation-offer-editor-output';
import { ReservationOfferEditorOutput } from '../reservation-offer-editor-output';

export const dbToReservationOfferEditorOutput = (
  reservationConfig: ReservationOfferConfig,
): ReservationOfferEditorOutput[] => {
  if (isNil(reservationConfig)) {
    return cloneDeep([defaultReservationOfferEditorOutput]);
  }

  const result: ReservationOfferEditorOutput[] = [];
  for (const offer of reservationConfig.getOffers()) {
    for (const channel of offer.getOfferChannels()) {
      result.push({
        channel,
        delay: offer.getDelay(),
      });
    }
  }

  return result;
};
