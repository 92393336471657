import { cloneDeep } from 'lodash';

import { CommunicationSessionType } from '../../../call/communication-session-type';
import { DBDocSchema } from '../../../generic/db-doc/db-doc-schema';
import { GenericDisplayable } from '../../../generic/displayable/generic-displayable';
import { ServiceLimitEnforcementStrategy } from '../service-limit-enforcement-strategy/generic/service-limit-enforcement-strategy/service-limit-enforcement-strategy';
import { ServiceLimitServiceUsage } from '../service-limit-service-usage/service-limit-service-usage';

import { ServiceLimitConstructor } from './service-limit-constructor';
import { ServiceLimitLogSessionCompleteData } from './service-limit-log-session-complete-data';
import { ServiceLimitSchema } from './service-limit-schema';

export class ServiceLimit extends GenericDisplayable {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected enforcementStrategy!: ServiceLimitEnforcementStrategy;

  protected usage!: ServiceLimitServiceUsage;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ServiceLimitConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ServiceLimit {
    return new ServiceLimit(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(ServiceLimit.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new ServiceLimitSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getEnforcementStrategy(): ServiceLimitEnforcementStrategy {
    return cloneDeep(this.enforcementStrategy);
  }

  public getUsage(): ServiceLimitServiceUsage {
    return cloneDeep(this.usage);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Does Permit Service
  /////////////////////////////////////////////////////////////////////////////

  public doesPermitService(): boolean {
    return this.enforcementStrategy.doesPermitService(this.usage);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Log Session Complete
  /////////////////////////////////////////////////////////////////////////////

  public logSessionComplete(
    communicationSessionType: CommunicationSessionType,
    data: ServiceLimitLogSessionCompleteData,
  ): ServiceLimit {
    this.enforcementStrategy.onBeforeLogSessionComplete(this.usage);
    switch (communicationSessionType) {
      case CommunicationSessionType.inboundCall: {
        this.usage.logInboundCallSessionComplete(data);
        return this;
      }
      default: {
        throw new Error(
          `ServiceLimit.logSessionComplete: User error: This case has not been implemented: ${communicationSessionType}`,
        );
      }
    }
  }
}
