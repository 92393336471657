<!-- Switch Case Editor -->
<form *ngIf="!loading" [formGroup]="form">
  <mat-form-field class="full-width">
    <input class="full-width" formControlName="value" matInput placeholder="Switch Case Value" />
    <mat-error *ngIf="value.errors?.required"> Value is required </mat-error>
  </mat-form-field>

  <!-- Call List Select -->
  <app-vrc-list-editor [(objList)]="commands" (objListChange)="updateObjAndEmit()"></app-vrc-list-editor>
</form>

<!-- Is Valid Indicator -->
<app-obj-is-valid *ngIf="!loading" [isValidObj]="isValidObjScoper"></app-obj-is-valid>
