import { SchemaFieldConstants } from '../../generic/serialization/constants';
import { SchemaField } from '../../generic/serialization/schema-field';
import { VoiceResponseCommandSchema } from '../generic/voice-response-command-schema';

export class SaySchema extends VoiceResponseCommandSchema {
  // The language to say it in
  public static readonly language = 'language';

  // Number of times to loop
  public static readonly loop = 'loop';

  // What to say
  public static readonly message = 'message';

  // Number of times to loop
  public static readonly voice = 'voice';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly commandName = 'say';

    public static readonly voice = 'woman';

    public static readonly message = '';

    public static readonly language = 'en';

    public static readonly loop = 1;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } | import('joi').AnySchema {
    const parameters = {} as any;
    parameters[VoiceResponseCommandSchema.commandName] = SchemaField.string(SaySchema.Defaults.commandName);
    parameters[SaySchema.message] = SchemaField.string(
      SaySchema.Defaults.message,
      undefined,
      SchemaFieldConstants.longStringMaxLength,
    );
    parameters[SaySchema.language] = SchemaField.string(SaySchema.Defaults.language);
    parameters[SaySchema.loop] = SchemaField.number(SaySchema.Defaults.loop);
    parameters[SaySchema.voice] = SchemaField.string(SaySchema.Defaults.voice);

    return parameters;
  }
}
