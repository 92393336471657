import { cloneDeep } from 'lodash';

import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { PossibleAdminRole } from '../../../../../objects/user/admin-roles/types';
import { PossibleRole } from '../../../../../objects/user/roles/types';
import { CommunicationsRequestName } from '../../../communications-request-name';
import { CommunicationsRequest } from '../../generic/communications-request/communications-request';
import { CommunicationsRequestSchema } from '../../generic/communications-request/communications-request-schema';

import { CommunicationsRequestCreateOutboundCallSessionConstructor } from './communications-request-create-outbound-call-session-constructor';
import { CommunicationsRequestCreateOutboundCallSessionSchema } from './communications-request-create-outbound-call-session-schema';

export class CommunicationsRequestCreateOutboundCallSession extends CommunicationsRequest {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected callerIdObjId!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationsRequestCreateOutboundCallSessionConstructor) {
    (parameters as any)[CommunicationsRequestSchema.type] = CommunicationsRequestName.createOutboundCallSession;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(
    validationResult: import('joi').ValidationResult,
  ): CommunicationsRequestCreateOutboundCallSession {
    return new CommunicationsRequestCreateOutboundCallSession(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationsRequestCreateOutboundCallSession.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationsRequestCreateOutboundCallSessionSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Roles
  /////////////////////////////////////////////////////////////////////////////

  public getRequiredRoles(): PossibleRole[] {
    return ['voicemail'];
  }

  public getRequiredAdminRoles(): PossibleAdminRole[] | undefined {
    return undefined;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getCallerIdObjId(): string {
    return cloneDeep(this.callerIdObjId);
  }
}
