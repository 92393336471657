import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { loadingFor } from '@ngneat/loadoff';
import moment from 'moment-timezone';

import {
  AllDataUser,
  EntityStats,
  EntityStatsDisplayWindow,
  EventType,
  getIntervalForWindow,
  GLOBAL_TIMEZONE,
  OrgData,
} from '@pwp-common';

import {
  DATETIME_LOCAL_CONTROL_STR_FORMAT,
  getTimestampFromDateTimeLocalFieldControl,
} from '../../../../common/objects/form-helper';
import { DatetimeValidator } from '../../../../common/validators/datetime-validator/datetime-validator';
import { EntityStatsService } from '../../../../services/analytics/entity-stats/entity-stats.service';
import { AllDataUserService } from '../../../../services/user/all-data-user/all-data-user.service';
import { ComponentWithForm } from '../../../generic/abstract-classes/component-with-form';
import { EntityStatsDisplayWindowChange } from '../../generic/entity-stats-display-window-change';

@Component({
  selector: 'app-admin-hours-dashboard',
  templateUrl: './admin-hours-dashboard.component.html',
  styleUrls: ['./admin-hours-dashboard.component.css'],
})
export class AdminHoursDashboardComponent extends ComponentWithForm implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Input
  /////////////////////////////////////////////////////////////////////////////////////////////

  @Input() orgData: OrgData;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////

  possibleWindows = Object.values(EntityStatsDisplayWindow);

  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  loader = loadingFor('entityStats', 'allDataUserMap', 'lastRefreshTime');

  entityStatsMap: Map<string, EntityStats>;

  allDataUserMap: Map<string, AllDataUser>;

  lastRefreshTime: moment.Moment;

  eventTypes: EventType[];

  windowObj: EntityStatsDisplayWindowChange;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private formBuilder: UntypedFormBuilder,
    private allDataUserService: AllDataUserService,
    private entityStatsService: EntityStatsService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Display Start / End interval
  /////////////////////////////////////////////////////////////////////////////////////////////

  public onFormChanges(value: any): void {
    if (this.window.value !== EntityStatsDisplayWindow.custom) {
      // Don't auto-refresh for custom window. Changing dates causes too many db reads
      this.emitChanges();
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected defineForm() {
    // Init Form
    const formConfig = {} as any;
    formConfig.window = [EntityStatsDisplayWindow.thisMonth, [Validators.required]];
    formConfig.customStart = [
      moment.tz(this.orgData.getTimezone()).startOf('day').format(DATETIME_LOCAL_CONTROL_STR_FORMAT),
      [],
    ];
    formConfig.customEnd = [
      moment.tz(this.orgData.getTimezone()).endOf('day').format(DATETIME_LOCAL_CONTROL_STR_FORMAT),
      [],
    ];

    this.form = this.formBuilder.group(formConfig, {
      validator: DatetimeValidator.isAfterControl('customStart', 'customEnd', this.orgData.getTimezone()),
    });
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Data
  ///////////////////////////////////////////////////////////////////////

  protected getDataPromise() {
    this.eventTypes = this.orgData.getEventTypes();
    return Promise.all([
      this.entityStatsService
        .getDocs()
        .toPromise()
        .then((_) => (this.entityStatsMap = _)),
      this.allDataUserService
        .getDocs()
        .toPromise()
        .then((_) => (this.allDataUserMap = _)),
    ]).then(() => {
      this.lastRefreshTime = moment.max(Array.from(this.entityStatsMap.values()).map((z) => z.getLastUploadTime()));
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Form Field Getters
  /////////////////////////////////////////////////////////////////////////////////////////////

  get window(): AbstractControl | null {
    return this.form.get('window');
  }

  get customStart(): AbstractControl | null {
    return this.form.get('customStart');
  }

  get customEnd(): AbstractControl | null {
    return this.form.get('customEnd');
  }

  ///////////////////////////////////////////////////////////////////////
  // Custom Window
  ///////////////////////////////////////////////////////////////////////

  emitChanges() {
    this.windowObj = this.getWindowChange();
    this.changeDetectorRef.detectChanges();
  }

  getWindowChange(): EntityStatsDisplayWindowChange {
    if (!this.form.valid) {
      return;
    }

    let windowStart: moment.Moment;
    let windowEnd: moment.Moment;
    if (this.window.value !== EntityStatsDisplayWindow.custom) {
      const interval = getIntervalForWindow(this.window.value);
      windowStart = moment.tz(interval.getStart(), GLOBAL_TIMEZONE);
      windowEnd = moment.tz(interval.getEnd(), GLOBAL_TIMEZONE);
    } else {
      windowStart = getTimestampFromDateTimeLocalFieldControl(this.customStart, this.orgData?.getTimezone(), undefined);
      windowEnd = getTimestampFromDateTimeLocalFieldControl(this.customEnd, this.orgData?.getTimezone(), undefined);
    }

    if (!windowStart.isValid() || !windowEnd.isValid() || windowEnd.isSameOrBefore(windowStart)) {
      console.log('getWindowChange: Incomplete form, not returning.');
      return;
    }

    const result = { type: this.window.value, windowStart, windowEnd };
    console.log(result);
    return result;
  }
}
