import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import moment from 'moment-timezone';
import { map, tap } from 'rxjs/operators';

import { EventType, EventTypeSchema, OrgData, OrgDataSchema, SchemaFieldConstants } from '@pwp-common';

import { getFieldControlValueOrDefault } from '../../../common/objects/form-helper';
import { KVPair } from '../../../common/objects/kvpair';
import { ArrayValidator } from '../../../common/validators/array-validator/array-validator';
import { OrgDataService } from '../../../services/orgs/org-data/org-data.service';
import { ConfigDocSelectAndEdit } from '../../generic/abstract-classes/config-doc-select-and-edit';

@Component({
  selector: 'app-org-data-select-and-edit',
  templateUrl: './org-data-select-and-edit.component.html',
  styleUrls: ['./org-data-select-and-edit.component.css'],
})
export class OrgDataSelectAndEditComponent extends ConfigDocSelectAndEdit<OrgData> implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  form: UntypedFormGroup = new UntypedFormGroup({});

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  possibleTimezones = moment.tz.names();

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private formBuilder: UntypedFormBuilder,
    // @ts-ignore
    private _dataService: OrgDataService,
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    super(OrgData, _dataService, _changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Override Get Data to Only Allow Editing One Org
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getData() {
    this.itemsArray = this._dataService.getOrgData().pipe(
      map((item: OrgData) => [item]),
      tap(() => {
        this.onSelectedKVPairChange(undefined);
      }),
    );
    // Don't call super, because it will overwrite itemsArray
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const eventTypes: EventType[] = [];
    for (const event of this.eventTypes.controls) {
      const internalName = getFieldControlValueOrDefault(
        event.get(EventTypeSchema.internalName),
        EventTypeSchema.Defaults.internalName,
      );
      const displayName = getFieldControlValueOrDefault(
        event.get(EventTypeSchema.displayName),
        EventTypeSchema.Defaults.displayName,
      );
      const eventType = new EventType({
        displayName,
        internalName:
          internalName === EventTypeSchema.Defaults.internalName ? this._dataService.createId() : internalName,
      });
      eventTypes.push(eventType);
    }

    const timezone: string = getFieldControlValueOrDefault(this.timezone, OrgDataSchema.Defaults.timezone);
    const e164Phones: string[] = getFieldControlValueOrDefault(this.e164Phones, OrgDataSchema.Defaults.e164Phones);

    const orgData = new OrgData({
      id: this.selectedKVPair.value.getId(),
      displayName: this.displayName.value,
      timezone,
      e164Phones,
      eventTypes,
    });

    return orgData;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: OrgData) {
    const formConfig = {} as any;
    formConfig[OrgDataSchema.displayName] = new UntypedFormControl(obj.getDisplayName(), [
      Validators.required,
      Validators.maxLength(500),
    ]);
    formConfig[OrgDataSchema.eventTypes] = new UntypedFormArray([], [Validators.required]);
    formConfig[OrgDataSchema.timezone] = new UntypedFormControl(obj.getTimezone(), [
      Validators.required,
      Validators.maxLength(500),
    ]);
    formConfig[OrgDataSchema.e164Phones] = [obj.getE164Phones(), [Validators.required, ArrayValidator.e164Phone()]];

    // Set Event Types
    for (const eventType of obj.getEventTypes()) {
      formConfig[OrgDataSchema.eventTypes].push(this.getEventTypesControl(eventType));
    }

    this.form = this.formBuilder.group(formConfig);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Event Types
  ///////////////////////////////////////////////////////////////////////////////////////////

  private getEventTypesControl(eventType?: EventType): UntypedFormGroup {
    const group = new UntypedFormGroup({
      displayName: new UntypedFormControl(eventType?.getDisplayName() || EventTypeSchema.Defaults.displayName, [
        Validators.required,
        Validators.maxLength(SchemaFieldConstants.shortStringMaxLength),
      ]),
      internalName: new UntypedFormControl({
        value: eventType?.getInternalName() || EventTypeSchema.Defaults.internalName,
        disabled: true,
      }),
    });

    return group;
  }

  addEventType(index: number) {
    this.eventTypes.insert(index, this.getEventTypesControl());
  }

  removeEventType(index: number) {
    this.eventTypes.removeAt(index);
  }

  moveEventTypeUp(index: number) {
    const tmp = this.eventTypes.at(index);
    this.removeEventType(index);
    this.eventTypes.insert(index - 1, tmp);
  }

  moveEventTypeDown(index: number) {
    const tmp = this.eventTypes.at(index - 1);
    this.removeEventType(index - 1);
    this.eventTypes.insert(index, tmp);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  get displayName(): AbstractControl | null {
    return this.form.get(OrgDataSchema.displayName);
  }

  get timezone(): AbstractControl | null {
    return this.form.get(OrgDataSchema.timezone);
  }

  get eventTypes(): UntypedFormArray {
    return this.form.get(OrgDataSchema.eventTypes) as UntypedFormArray;
  }

  get e164Phones(): AbstractControl | null {
    return this.form.get(OrgDataSchema.e164Phones);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Callbacks
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  onSelectedKVPairChange(kvPair: KVPair<OrgData> | undefined) {
    super.onSelectedKVPairChange(kvPair);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObjScoper = (): boolean => this.isValidObj();

  isValidObj(): boolean {
    // This checks that the form is valid.
    if (!super.isValidObj()) {
      return false;
    }
    return true;
  }
}
