<plotly-plot
  [config]="{
    displaylogo: false,
    modeBarButtonsToRemove: [
      'zoom2d',
      'pan2d',
      'select2d',
      'lasso2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
      'resetScale2d',
      'toggleSpikelines',
      'hoverClosestCartesian',
      'hoverCompareCartesian',
      'toggleHover',
    ],
  }"
  [data]="[
    {
      type: 'sunburst',
      labels: labels,
      parents: parents,
      values: values,
      text: text,
      textinfo: 'label+value',
      hoverinfo: 'text',
      hoverlabel: {
        namelength: -1,
        align: 'left',
      },
      leaf: { opacity: 0.4 },
      marker: { line: { width: 2 } },
      branchvalues: 'total',
    },
  ]"
  [layout]="{
    xaxis: {
      automargin: true,
      autorange: 'reversed',
    },
    yaxis: {
      automargin: true,
      autorange: 'reversed',
    },
  }"
  [style]="{ position: 'relative', width: '100%', height: '100vh' }"
  [useResizeHandler]="true"
>
</plotly-plot>
