import { isEqual } from 'lodash';

import { SerializableObjectSchema } from './serializable-object-schema';
import { serializeValue } from './serialize-value';

export const isNilOrDefault = (value: any, fieldName: string, schema: SerializableObjectSchema): boolean => {
  if (value === null || value === undefined) {
    return true;
  }

  const serializedValue = serializeValue(value, 'firebase');
  const serializedDefaultValue = serializeValue(
    SerializableObjectSchema.getDefaultValue(schema, fieldName),
    'firebase',
  );

  return isEqual(serializedValue, serializedDefaultValue);
};
