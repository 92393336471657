<ng-container *transloco="let t; read: 'service-command-editor-enforce-service-limit'">
  <p-messages severity="info">
    <ng-template pTemplate>
      <p class="wrap">{{ t('description') }}</p>
    </ng-template>
  </p-messages>
  <form *ngIf="(loader.serviceLimit.inProgress$ | async) === false" [formGroup]="form">
    <app-service-limit-editor
      [formControl]="serviceLimit"
      [ngClass]="{ 'ng-invalid': serviceLimit?.invalid }"
    ></app-service-limit-editor>
    <p *ngIf="serviceLimit.hasError('required')" class="p-error">{{ t('serviceLimitErrorRequired') }}</p>
    <p *ngIf="serviceLimit.hasError('objValidatorIsSerializable')" class="p-error">
      {{ t('serviceLimitErrorObjValidatorIsValid') }}
    </p>
  </form>
  <app-progress-spinner *ngIf="loader.serviceLimit.inProgress$ | async"></app-progress-spinner>
</ng-container>
