import { SchemaField } from '../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../generic/serialization/serializable-object-schema';

/**
 * This object encodes the parameters used to make an API call to Google
 * Cloud Text-To-Speeech.
 *
 * Voices w/ Sample Audio: https://cloud.google.com/text-to-speech/docs/voices
 * Demo for Testing SSML: https://cloud.google.com/text-to-speech
 */
export class TemplateTextSchema extends SerializableObjectSchema {
  // The SSML text to say:
  public static readonly text = 'text';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly maxTextLength = 100000;
  };

  static Defaults = class {
    public static readonly text = new Map<string, string>();
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Default values to serialize
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public defaultValuesToSerialize(): Set<string> {
    return new Set([TemplateTextSchema.text]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[TemplateTextSchema.text] = SchemaField.mapOfType(
      SchemaField.sanitizedString(TemplateTextSchema.Constants.maxTextLength),
      TemplateTextSchema.Defaults.text,
    );
    /* eslint-enable */

    return parameters;
  }
}
