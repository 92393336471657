import { SchemaField } from '../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../generic/serialization/serializable-object-schema';

export class AssignedUserTypeStatsSchema extends SerializableObjectSchema {
  public static readonly num = 'num';

  public static readonly durationMs = 'durationMs';

  public static readonly numUsers = 'numUsers';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly num = 0;

    public static readonly durationMs = 0;

    public static readonly numUsers = 0;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[AssignedUserTypeStatsSchema.num] = SchemaField.number(AssignedUserTypeStatsSchema.Defaults.num);
    parameters[AssignedUserTypeStatsSchema.durationMs] = SchemaField.number(
      AssignedUserTypeStatsSchema.Defaults.durationMs,
    );
    parameters[AssignedUserTypeStatsSchema.numUsers] = SchemaField.number(
      AssignedUserTypeStatsSchema.Defaults.numUsers,
    );
    /* eslint-enable */

    return parameters;
  }
}
