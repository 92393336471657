import { cloneDeep, flatten, isNil } from 'lodash';

import { SerializableObject } from '../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';
import { ReservationOffer } from '../reservation-offer/reservation-offer';
import { ReservationOfferChannel } from '../reservation-offer-channel';
import { ReservationOfferChannelConfigPhoneCall } from '../reservation-offer-channel-config-phone-call/reservation-offer-channel-config-phone-call';
import { ReservationOfferChannelConfigText } from '../reservation-offer-channel-config-text/reservation-offer-channel-config-text';
import { ReservationSkipOffersIf } from '../reservation-skip-offers-if/reservation-skip-offers-if/reservation-skip-offers-if';

import { ReservationOfferConfigConstructor } from './reservation-offer-config-constructor';
import { ReservationOfferConfigSchema } from './reservation-offer-config-schema';

export class ReservationOfferConfig extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected offers!: ReservationOffer[];

  protected skipOffersIf!: ReservationSkipOffersIf;

  protected offerChannelConfigPhoneCall!: ReservationOfferChannelConfigPhoneCall;

  protected offerChannelConfigText!: ReservationOfferChannelConfigText;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ReservationOfferConfigConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ReservationOfferConfig {
    return new ReservationOfferConfig(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ReservationOfferConfig.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ReservationOfferConfigSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getOffers() {
    return cloneDeep(this.offers);
  }

  public getSkipOffersIf() {
    return cloneDeep(this.skipOffersIf);
  }

  public getOfferChannelConfigPhoneCall() {
    return cloneDeep(this.offerChannelConfigPhoneCall);
  }

  public getOfferChannelConfigText() {
    return cloneDeep(this.offerChannelConfigText);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Sanity Check
  /////////////////////////////////////////////////////////////////////////////

  public sanityCheck() {
    const offerChannels = new Set(flatten(this.offers.map((z) => z.getOfferChannels())));

    if (offerChannels.has(ReservationOfferChannel.phoneCall) && isNil(this.offerChannelConfigPhoneCall)) {
      throw new Error(
        'ReservationOfferConfig.sanityCheck: User error. A phoneCall offer is specified but the required configuration is not.',
      );
    }

    if (offerChannels.has(ReservationOfferChannel.text) && isNil(this.offerChannelConfigText)) {
      throw new Error(
        'ReservationOfferConfig.sanityCheck: User error. A text offer is specified but the required configuration is not.',
      );
    }

    super.sanityCheck();
  }
}
