import { isNil } from 'lodash';
import moment from 'moment-timezone';
import RRule from 'rrule';

/////////////////////////////////////////////////////////////////////////////
// RRule Parsing: Reinterpret Times in UTC / Global Timezone
/////////////////////////////////////////////////////////////////////////////
/**
 * The RRule library returns dates that declare they are in UTC format,
 * whereas their date.getUTCFullYear(), ... date.getUTCMinutes()
 * are actually the the correct date for the recurrance when
 * interpreted in the local timezone.
 *
 * This function does the conversion:
 *
 * RRule Date --[reinterpret]--> Date in local timezone -[cast]-> Date in specified timezone
 *
 * following the instructions here:
 * https://github.com/jakubroztocil/rrule#important-use-utc-dates
 * @param rruleDate
 */
export const reinterpretUTCTimeAsTimezone = (rruleDate: Date, timezone: string): moment.Moment => {
  const array = [
    rruleDate.getUTCFullYear(),
    rruleDate.getUTCMonth(),
    rruleDate.getUTCDate(),
    rruleDate.getUTCHours(),
    rruleDate.getUTCMinutes(),
  ];
  const reinterpretedToGivenTimezone = moment.tz(array, timezone);
  return reinterpretedToGivenTimezone;
};

/**
 * The RRule library returns dates that declare they are in UTC format,
 * whereas their date.getUTCFullYear(), ... date.getUTCMinutes()
 * are actually the the correct date for the recurrance when
 * interpreted in the local timezone.
 *
 * This function does the conversion:
 *
 * RRule Date --[reinterpret]--> Date in local timezone -[cast]-> Date in specified timezone
 *
 * following the instructions here:
 * https://github.com/jakubroztocil/rrule#important-use-utc-dates
 * @param rruleDate
 */
export const convertRRuleDateToMoment = (rruleDate: Date, timezone: string): moment.Moment => {
  if (timezone === 'UTC') {
    return reinterpretUTCTimeAsTimezone(rruleDate, timezone);
  }
  const array = [
    rruleDate.getUTCFullYear(),
    rruleDate.getUTCMonth(),
    rruleDate.getUTCDate(),
    rruleDate.getUTCHours(),
    rruleDate.getUTCMinutes(),
  ];
  const reinterpretedToLocalTime = moment(array);
  return reinterpretedToLocalTime.tz(timezone);
};

/**
 * The RRule library needs inputs in a certain format depening on
 * if the RRule timezone is set or not. This function handles such
 * conversions.
 *
 * @param dateWithTimezone
 * @param rruleTzidIsUtc
 * @returns
 */
export const getRRuleInputFromMoment = (dateWithTimezone: moment.Moment, rrule: RRule): Date => {
  if (isNil(rrule.options.tzid)) {
    return dateWithTimezone.toDate();
  }

  const momentInLocalTimezone = dateWithTimezone.local(false);

  // Return the date value whose components in UTC are the same as the components of this date in the timezone of the rrule
  return new Date(
    Date.UTC(
      momentInLocalTimezone.year(),
      momentInLocalTimezone.month(),
      momentInLocalTimezone.date(),
      momentInLocalTimezone.hour(),
      momentInLocalTimezone.minute(),
      momentInLocalTimezone.second(),
    ),
  );
};
