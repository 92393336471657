import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, inject, Input } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { filter } from 'rxjs';

import { CommunicationSessionId } from '@pwp-common';

import { FormSubmissionDialogComponent } from '../form-submission-dialog/form-submission-dialog.component';

@UntilDestroy()
@Component({
  selector: 'button[app-submit-form-button]',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './submit-form-button.component.html',
  styleUrls: ['./submit-form-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmitFormButtonComponent {
  private readonly dialogService = inject(DialogService);

  private readonly messageService = inject(MessageService);

  private readonly translocoService = inject(TranslocoService);

  @Input() public sessionId!: CommunicationSessionId;

  @HostListener('click')
  public openFormDialog(): void {
    this.dialogService
      .open(FormSubmissionDialogComponent, {
        height: '100%',
        width: 'clamp(32rem, 75%, 64rem)',
        closeOnEscape: false,
        header: this.translocoService.translate('form-submission.title'),
        data: {
          sessionId: this.sessionId,
        },
      })
      .onClose.pipe(
        filter((result) => !isNil(result)),
        untilDestroyed(this),
      )
      .subscribe((result) => this.messageService.add(result.message));
  }
}
