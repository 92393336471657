import Joi from 'joi';

import { serializeToJsonString } from '../../serialize-to-json-string/serialize-to-json-string';
import { jsonStringSchema } from '../json-string-schema/json-string-schema';

import { getSurveyJSJsonSchemaErrors } from './helper/get-survey-js-json-schema-errors';
import { getSurveyJSModelErrors } from './helper/get-survey-js-model-errors';

export const surveyJSJsonSchema = (defaultValue?: string): Joi.StringSchema =>
  jsonStringSchema(defaultValue)
    .custom((value, helpers) => {
      const modelErrors = getSurveyJSModelErrors(value);

      if (modelErrors.length > 0) {
        return helpers.error('string.surveyJSJsonSchema', {
          value: serializeToJsonString(modelErrors),
        });
      }

      const schemaErrors = getSurveyJSJsonSchemaErrors(value);

      if (schemaErrors.length > 0) {
        return helpers.error('string.surveyJSJsonSchema', {
          value: serializeToJsonString(schemaErrors),
        });
      }

      return value;
    })
    .rule({
      message: {
        'string.surveyJSJsonSchema': 'Value cannot be parsed to a valid SurveyJS form object: "{#value}"',
      },
    });
