import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AuthService } from '../../services/user/auth/auth.service';
import { RolesService } from '../../services/user/roles/roles.service';

@Injectable({
  providedIn: 'root',
})
export class VoicemailGuard implements CanActivate {
  constructor(
    private rolesService: RolesService,
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const observable = this.authService.getUserId().pipe(
      switchMap((userId) => this.rolesService.getDoc(userId)),
      switchMap((roles) => {
        if (roles?.canAccessVoicemail() === true) {
          console.log('VoicemailGuard: User authorized to access voicemail');
          return of(true);
        }
        console.log('VoicemailGuard: User is not permitted to access this route');
        return from(this.router.navigate(['/'])).pipe(map((_) => false));
      }),
    );
    return observable;
  }
}
