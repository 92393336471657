import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { isNil } from 'lodash';

import { EntityStats, UserData, UserLoadSlidingWindow } from '@pwp-common';

@Component({
  selector: 'app-user-load-table',
  templateUrl: './user-load-table.component.html',
  styleUrls: ['./user-load-table.component.css'],
})
export class UserLoadTableComponent implements OnInit, OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Input / Output
  /////////////////////////////////////////////////////////////////////////////////////////////

  @Input() entityStatsMap: Map<string, EntityStats>;

  @Input() allUserDataMap: Map<string, UserData>;

  @Input() slidingWindowDurationDays = 0;

  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  userLoads: UserLoadSlidingWindow[] = [];

  cols = ['displayName', 'email', 'iNum', 'iTalkDurationHours', 'numEvents', 'totalEventHours'];

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if ('entityStatsMap' in changes || 'allUserDataMap' in changes || 'slidingWindowDurationDays' in changes) {
      this.refreshData();
    }
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Data
  ///////////////////////////////////////////////////////////////////////

  private refreshData() {
    this.userLoads = [];
    if (isNil(this.entityStatsMap) || isNil(this.allUserDataMap) || isNil(this.slidingWindowDurationDays)) {
      return;
    }

    for (const entityStats of this.entityStatsMap.values()) {
      const hoursForOneEntity = new UserLoadSlidingWindow(
        entityStats as EntityStats,
        this.allUserDataMap,
        this.slidingWindowDurationDays,
      );
      this.userLoads.push(hoursForOneEntity);
    }

    this.userLoads = UserLoadSlidingWindow.getTopUsersByCallLoad(this.userLoads, undefined);
  }
}
