import type { AnySchema } from 'joi';
import Joi from 'joi';

import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { AutomationRequestCreateAutomationSchema } from '../../requests/automation-request-create-automation/automation-request-create-automation-schema';

export class AnyAutomationRequestSchema extends SerializableObjectSchema {
  private static readonly SCHEMA_ID = 'anyAutomationRequest';

  private static computedSchema = Joi.alternatives()
    .try(new AutomationRequestCreateAutomationSchema().getJoiSchema())
    .id(this.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly type = 'type';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly GenericDefaults = class {
    public static readonly type = undefined;
  };

  public static getSchemaId(): string {
    return `#${AnyAutomationRequestSchema.SCHEMA_ID}`;
  }

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: AnySchema } | AnySchema {
    return AnyAutomationRequestSchema.computedSchema;
  }
}
