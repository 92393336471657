import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import moment from 'moment-timezone';

import { OrgData } from '@pwp-common';

import {
  DATETIME_LOCAL_CONTROL_STR_FORMAT,
  getTimestampFromDateTimeLocalFieldControl,
} from '../../../../common/objects/form-helper';
import { DatetimeValidator } from '../../../../common/validators/datetime-validator/datetime-validator';
import { ComponentWithForm } from '../../../generic/abstract-classes/component-with-form';

@Component({
  selector: 'app-event-split-dialog',
  templateUrl: './event-split-dialog.component.html',
  styleUrls: ['./event-split-dialog.component.css'],
})
export class EventSplitDialogComponent extends ComponentWithForm implements OnInit {
  minTime: string;

  maxTime: string;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    public dialogRef: MatDialogRef<EventSplitDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      start: moment.Moment;
      end: moment.Moment;
      orgData: OrgData;
    },
  ) {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Close Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  public getSplitTime() {
    const momentValue = getTimestampFromDateTimeLocalFieldControl(
      this.splitTime,
      this.data.orgData.getTimezone(),
      undefined,
    );
    return momentValue;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected defineForm() {
    const timezone = this.data.orgData.getTimezone();
    this.minTime = this.data.start.tz(timezone).format(DATETIME_LOCAL_CONTROL_STR_FORMAT);
    this.maxTime = this.data.end.tz(timezone).format(DATETIME_LOCAL_CONTROL_STR_FORMAT);

    // Init Form
    const formConfig = {} as any;
    formConfig.splitTime = [
      this.minTime,
      [
        Validators.required,
        DatetimeValidator.isAfter(this.data.start, timezone),
        DatetimeValidator.isBefore(this.data.end, timezone),
        DatetimeValidator.stepSizeSeconds(900),
      ],
    ];
    this.form = this.formBuilder.group(formConfig);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Form Field Getters
  /////////////////////////////////////////////////////////////////////////////////////////////

  get splitTime(): AbstractControl | null {
    return this.form.get('splitTime');
  }
}
