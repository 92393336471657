import { UserStatus } from '../../../../../../../call/dialed-call-log/user-status';
import { SchemaField } from '../../../../../../../generic/serialization/schema-field';
import { CommunicationSessionEventLogSendMessageSchema } from '../../generic/communication-session-event-log-send-message/communication-session-event-log-send-message-schema';

export class CommunicationSessionEventLogSendMessageNotificationSchema extends CommunicationSessionEventLogSendMessageSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly userStatus = 'userStatus';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    ///////////////////////////////////////////////////////
    // Generic InboundCallEvent Properties, Copy/Pasted Here
    ///////////////////////////////////////////////////////

    public static readonly type = undefined;

    public static readonly actor = undefined;

    public static readonly timestamp = undefined;

    ///////////////////////////////////////////////////////
    // Sent Message Properties, Copy/Pasted Here
    ///////////////////////////////////////////////////////
    public static readonly errorCode = undefined;

    public static readonly from = undefined;

    public static readonly messageSid = undefined;

    public static readonly numMedia = undefined;

    public static readonly numSegments = undefined;

    public static readonly operatorTimestamps = new Map();

    ///////////////////////////////////////////////////////
    // CommunicationSessionEventLogSentMessage Properties
    ///////////////////////////////////////////////////////

    public static readonly messageType = undefined;

    public static readonly participant = undefined;

    ///////////////////////////////////////////////////////
    // Send Message Notification Properties
    ///////////////////////////////////////////////////////
    public static readonly userStatus = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    parameters[CommunicationSessionEventLogSendMessageNotificationSchema.userStatus] = SchemaField.string(
      CommunicationSessionEventLogSendMessageNotificationSchema.userStatus,
      CommunicationSessionEventLogSendMessageNotificationSchema.Defaults.userStatus,
    )
      .optional()
      .valid(...Object.values(UserStatus));

    return parameters;
  }
}
