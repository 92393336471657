import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';

import { SerializableObject } from '../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../generic/serialization/serializable-object-schema';
import { ConversationMessageDigest } from '../conversation-message-digest/conversation-message-digest';

import { ConversationParticipantSummaryChannelType } from './conversation-participant-channel';
import { ConversationUserIdentityTypeType } from './conversation-participant-id-type';
import { ConversationParticipantSummaryConstructor } from './conversation-participant-summary-constructor';
import { ConversationParticipantSummarySchema } from './conversation-participant-summary-schema';

export class ConversationParticipantSummary extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected participantIdType!: ConversationUserIdentityTypeType;

  protected participantId!: string | undefined;

  protected channel!: ConversationParticipantSummaryChannelType;

  protected participantCreateTime!: moment.Moment;

  protected participantUpdateTime!: moment.Moment;

  protected firstSend: ConversationMessageDigest | undefined;

  protected lastSend: ConversationMessageDigest | undefined;

  protected lastRead: ConversationMessageDigest | undefined;

  protected numSent: number | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ConversationParticipantSummaryConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ConversationParticipantSummary {
    return new ConversationParticipantSummary(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ConversationParticipantSummary.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ConversationParticipantSummarySchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getParticipantIdType(): ConversationUserIdentityTypeType {
    return cloneDeep(this.participantIdType);
  }

  public getParticipantId(): string | undefined {
    return cloneDeep(this.participantId);
  }

  public getChannel(): ConversationParticipantSummaryChannelType {
    return cloneDeep(this.channel);
  }

  public getParticipantCreateTime(): moment.Moment {
    return cloneDeep(this.participantCreateTime);
  }

  public getParticipantUpdateTime(): moment.Moment {
    return cloneDeep(this.participantUpdateTime);
  }

  public getFirstSend(): ConversationMessageDigest | undefined {
    return cloneDeep(this.firstSend);
  }

  public getLastSend(): ConversationMessageDigest | undefined {
    return cloneDeep(this.lastSend);
  }

  public getLastRead(): ConversationMessageDigest | undefined {
    return cloneDeep(this.lastRead);
  }

  public getNumSent(): number {
    return cloneDeep(this.numSent) ?? ConversationParticipantSummarySchema.Defaults.numSent;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Update this obj
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Update this object to include the given message
   * @param params info about the message to update the object with
   */
  public updateWithSentMessage(params: {
    index: number;
    sendTimestamp: moment.Moment;
  }): ConversationParticipantSummary {
    const messageDigest = new ConversationMessageDigest({ index: params.index, timestamp: params.sendTimestamp });
    if (this.firstSend === undefined || messageDigest < this.firstSend) {
      this.firstSend = messageDigest;
    }
    if (this.lastSend === undefined || messageDigest > this.lastSend) {
      this.lastSend = messageDigest;
    }
    this.numSent = (this.numSent ?? 0) + 1;
    return this;
  }
}
