import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { firstValueFrom, Observable } from 'rxjs';

import { isNilOrDefault, VoicemailMetadata, VoicemailMetadataSchema } from '@pwp-common';

import { DbDocumentService } from '../../generic/db-document-service';
import { DBOrderBy, DBQuery } from '../../generic/interfaces';
import { AuthService } from '../../user/auth/auth.service';

const schema = new VoicemailMetadataSchema();
@Injectable({
  providedIn: 'root',
})
export class VoicemailMetadataService extends DbDocumentService<VoicemailMetadata> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(
    // @ts-ignore
    private _db: AngularFirestore,
    // @ts-ignore
    private _authService: AuthService,
  ) {
    super(_db, _authService, VoicemailMetadata);
  }

  /////////////////////////////////////////////////////////////////////
  // Events with start in range
  /////////////////////////////////////////////////////////////////////

  public getVoicemailsWithRecordingStartInRange(
    start: moment.Moment,
    end: moment.Moment,
    takeOne = true,
  ): Observable<VoicemailMetadata[]> {
    // Log inputs
    console.log(
      `getVoicemailsWithRecordingStartInRange:` +
        `\n\tStart:\t${start}` +
        `\n\tTo:\t\t${end}` +
        `\n\ttakeOne:${takeOne}`,
    );

    // Make the query
    const query: DBQuery[] = [
      {
        fieldPath: VoicemailMetadataSchema.recordingStartTime,
        opStr: '>=',
        value: start.toDate(),
      },
      {
        fieldPath: VoicemailMetadataSchema.recordingStartTime,
        opStr: '<=',
        value: end.toDate(),
      },
    ];

    const orderBy: DBOrderBy = {
      fieldPath: VoicemailMetadataSchema.recordingStartTime,
      directionStr: 'desc',
    };

    // Return
    return this.getDocsArray(query, orderBy, undefined, takeOne);
  }

  ///////////////////////////////////////////////////////////////////////
  // Toggle Handled By Self
  ///////////////////////////////////////////////////////////////////////

  public async toggleHandledBySelf(docId: string): Promise<VoicemailMetadata> {
    const loggedInUserId = await firstValueFrom(this.getInjectedAuthService().getUserId(true));

    const promise = this.db.firestore.runTransaction(
      async (transaction: firebase.firestore.Transaction): Promise<VoicemailMetadata> => {
        const docRef = await firstValueFrom(this.docRef(docId));
        const oldData = this.getObjectFromFirebase(await transaction.get(docRef.ref));

        /**
         * Don't do anything if the item is handled by someone else.
         */
        const assignedTo = oldData.getAssignedTo();
        if (isNilOrDefault(assignedTo, VoicemailMetadataSchema.assignedTo, schema)) {
          oldData.setAssignedTo(loggedInUserId);
          const uploadDictionary = await firstValueFrom(this.getSerializedObjWithGenericFields(oldData, 'update'));
          transaction.update(docRef.ref, uploadDictionary);
        }
        if (assignedTo === loggedInUserId) {
          const uploadDictionary = await firstValueFrom(this.getSerializedObjWithGenericFields(oldData, 'update'));
          uploadDictionary[VoicemailMetadataSchema.assignedTo] = firebase.firestore.FieldValue.delete();
          transaction.update(docRef.ref, uploadDictionary);
        }

        const newData = await firstValueFrom(this.getDoc(docId));
        return newData;
      },
    );
    const result = await promise;
    return result;
  }

  ///////////////////////////////////////////////////////////////////////
  // Assign/Unassign to self
  ///////////////////////////////////////////////////////////////////////

  /**
   * Assign this voicemail to the logged in user. Succeed only if
   * this voicemail is already assigned to this user, or unassigned.
   *
   * @param voicemailMetadata Voicemail Metadata
   */
  public async assignToSelf(voicemailMetadata: VoicemailMetadata): Promise<void> {
    const loggedInUserId = await this.getInjectedAuthService().getUserId(true).toPromise();
    const docRef = await this.docRef(voicemailMetadata.getId()).toPromise();

    const promise = this.db.firestore.runTransaction(
      async (transaction: firebase.firestore.Transaction): Promise<void> => {
        const result = await transaction.get(docRef.ref);
        let newVoicemailMetadata = this.getObjectFromFirebase(result);

        // Succeed only if it is already assigned to this user, or unassigned.
        if ([loggedInUserId, undefined].includes(newVoicemailMetadata.getAssignedTo())) {
          newVoicemailMetadata = newVoicemailMetadata.setAssignedTo(loggedInUserId);
          const uploadDictionary = await this.getSerializedObjWithGenericFields(
            newVoicemailMetadata,
            'update',
          ).toPromise();
          transaction.update(docRef.ref, uploadDictionary);
          return undefined;
        }

        return Promise.reject(newVoicemailMetadata);
      },
    );

    return promise;
  }

  /**
   * Unassign this voicemail from the logged in user. Succeed only if
   * this voicemail is already assigned to this user, or unassigned.
   *
   * @param voicemailMetadata Voicemail Metadata
   */
  public async unAssignFromSelf(voicemailMetadata: VoicemailMetadata): Promise<void> {
    const loggedInUserId = await this.getInjectedAuthService().getUserId(true).toPromise();
    const docRef = await this.docRef(voicemailMetadata.getId()).toPromise();

    const promise = this.db.firestore.runTransaction(
      async (transaction: firebase.firestore.Transaction): Promise<void> => {
        const result = await transaction.get(docRef.ref);
        const newVoicemailMetadata = this.getObjectFromFirebase(result);

        // Succeed only if it is already assigned to this user, or unassigned.
        if ([loggedInUserId, undefined].includes(newVoicemailMetadata.getAssignedTo())) {
          const uploadDictionary = await this.getSerializedObjWithGenericFields(
            newVoicemailMetadata,
            'update',
          ).toPromise();
          uploadDictionary[VoicemailMetadataSchema.assignedTo] = firebase.firestore.FieldValue.delete();
          transaction.update(docRef.ref, uploadDictionary);
          return undefined;
        }

        return Promise.reject(newVoicemailMetadata);
      },
    );

    return promise;
  }

  /**
   * Mark this voicemail as listened to by the currently logged in user.
   *
   * @param voicemailMetadata Voicemail Metadata
   */
  public async markAsListenedToByLoggedInUser(voicemailMetadata: VoicemailMetadata): Promise<void> {
    const loggedInUserId = await this.getInjectedAuthService().getUserId(true).toPromise();
    const docRef = await this.docRef(voicemailMetadata.getId()).toPromise();

    const promise = this.db.firestore.runTransaction(
      async (transaction: firebase.firestore.Transaction): Promise<void> => {
        const result = await transaction.get(docRef.ref);
        const newVoicemailMetadata = this.getObjectFromFirebase(result);

        const uploadDictionary = await this.getSerializedObjWithGenericFields(
          newVoicemailMetadata,
          'update',
        ).toPromise();
        uploadDictionary[VoicemailMetadataSchema.listenedToBy] =
          firebase.firestore.FieldValue.arrayUnion(loggedInUserId);
        transaction.update(docRef.ref, uploadDictionary);
        return undefined;
      },
    );

    return promise;
  }
}
