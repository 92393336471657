import type { ValidationResult } from 'joi';
import { cloneDeep } from 'lodash';

import { CommunicationSessionId } from '../../../../../objects/core/communication-session-id/communication-session-id';
import { FormBuilderVendor } from '../../../../../objects/form/form-builder-vendor';
import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { PossibleAdminRole } from '../../../../../objects/user/admin-roles/types';
import { PossibleRole } from '../../../../../objects/user/roles/types';
import { FormRequestName } from '../../../form-request-name';
import { FormRequest } from '../../generic/form-request/form-request';
import { FormRequestSchema } from '../../generic/form-request/form-request-schema';

import { FormRequestCreateSubmissionConstructor } from './form-request-create-submission-constructor';
import { FormRequestCreateSubmissionSchema } from './form-request-create-submission-schema';

export class FormRequestCreateSubmission extends FormRequest {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected formVersionId!: string;

  protected sessionId!: CommunicationSessionId;

  protected submission!: string;

  protected submissionId!: string;

  protected vendor!: FormBuilderVendor;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: FormRequestCreateSubmissionConstructor) {
    super({
      ...parameters,
      [FormRequestSchema.type]: FormRequestName.createSubmission,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  protected static _deserialize(validationResult: ValidationResult): FormRequestCreateSubmission {
    return new FormRequestCreateSubmission(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new FormRequestCreateSubmissionSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(FormRequestCreateSubmission.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Roles
  /////////////////////////////////////////////////////////////////////////////

  public getRequiredRoles(): PossibleRole[] {
    return [];
  }

  public getRequiredAdminRoles(): PossibleAdminRole[] | undefined {
    return undefined;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getFormVersionId(): string {
    return cloneDeep(this.formVersionId);
  }

  public getSessionId(): CommunicationSessionId {
    return cloneDeep(this.sessionId);
  }

  public getSubmission(): string {
    return cloneDeep(this.submission);
  }

  public getSubmissionId(): string {
    return cloneDeep(this.submissionId);
  }

  public getVendor(): FormBuilderVendor {
    return cloneDeep(this.vendor);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Log metadata
  /////////////////////////////////////////////////////////////////////////////

  public getLogMetadata(): Record<string, unknown> {
    return {
      ...super.getLogMetadata(),
      formVersionId: this.getFormVersionId(),
      sessionId: this.getSessionId(),
      submission: this.getSubmission(),
      submissionId: this.getSubmissionId(),
      vendor: this.getVendor(),
    };
  }
}
