import { AssignedUserType } from '../../enums';
import { canRequestSlot } from '../can-request-slot/can-request-slot';
import { CanRequestSlotInput } from '../can-request-slot/interfaces';

export const getFirstRequestableSlot = (params: Omit<CanRequestSlotInput, 'slot'>): AssignedUserType | null => {
  if (canRequestSlot({ ...params, slot: AssignedUserType.primary })) {
    return AssignedUserType.primary;
  }

  if (canRequestSlot({ ...params, slot: AssignedUserType.backup })) {
    return AssignedUserType.backup;
  }

  return null;
};
