import { SchemaField } from '../../../objects/generic/serialization/schema-field';
import { RequestSchema } from '../../generic/request/request-schema';

export class BlockedCallerRequestSchema extends RequestSchema {
  public static readonly requestedAction = 'requestedAction';

  public static readonly sessionId = 'sessionId';

  public static readonly expireTime = 'expireTime';

  public static readonly reason = 'reason';

  public static readonly blockedCallerId = 'blockedCallerId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class extends RequestSchema.GenericDefaults {
    public static readonly sessionId = undefined;

    public static readonly expireTime = undefined;

    public static readonly reason = '';

    public static readonly blockedCallerId = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[BlockedCallerRequestSchema.blockedCallerId] = SchemaField.string(
      BlockedCallerRequestSchema.Defaults.blockedCallerId,
    );
    parameters[BlockedCallerRequestSchema.sessionId] = SchemaField.string(
      BlockedCallerRequestSchema.Defaults.sessionId,
    );
    parameters[BlockedCallerRequestSchema.expireTime] = SchemaField.timestamp(
      BlockedCallerRequestSchema.Defaults.expireTime,
    );
    parameters[BlockedCallerRequestSchema.reason] = SchemaField.string(BlockedCallerRequestSchema.Defaults.reason);
    /* eslint-enable */

    return parameters;
  }
}
