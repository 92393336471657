<ng-container *transloco="let t; read: 'event-hours-table'">
  <ng-template #headerTemplate>
    Window: {{ windowObj?.type }}
    <app-obj-multi-select
      label="{{ t('eventType') }}"
      [orderedItems]="eventTypes"
      [(kvPairs)]="selectedEventTypes"
      (kvPairsChange)="this.getData()"
    ></app-obj-multi-select>
  </ng-template>

  <div [appLoading]="loading">
    <app-analytics-table
      [cols]="cols"
      [data]="hoursOverTime"
      [headerTemplate]="headerTemplate"
      [translationScope]="'event-hours-table'"
    >
    </app-analytics-table>
  </div>
</ng-container>
