import { isNilOrDefaultGenericField } from '../../serialization/is-nil-or-default-generic-field/is-nil-or-default-generic-field';
import { DBDocSchemaFields } from '../db-doc-schema-fields';

import { IsEditingStaleObjectInput } from './interface';

export const isEditingStaleObject = ({
  original,
  updated,
  userEditingTheObject,
}: IsEditingStaleObjectInput): boolean => {
  /**
   * Check if required parameters are nil or default
   */
  if (
    // Id
    isNilOrDefaultGenericField(DBDocSchemaFields.id, original?.getId()) ||
    isNilOrDefaultGenericField(DBDocSchemaFields.id, updated?.getId()) ||
    // Last Upload Time
    isNilOrDefaultGenericField(DBDocSchemaFields.lastUploadTime, original?.getLastUploadTime()) ||
    isNilOrDefaultGenericField(DBDocSchemaFields.lastUploadTime, updated?.getLastUploadTime()) ||
    // Last modified by userId
    isNilOrDefaultGenericField(DBDocSchemaFields.lastModifiedByUserId, original?.getLastModifiedByUserId()) ||
    isNilOrDefaultGenericField(DBDocSchemaFields.lastModifiedByUserId, updated?.getLastModifiedByUserId())
  ) {
    throw new Error('isEditingStaleObject: Required field is nil or default');
  }

  if (original.getId() !== updated.getId()) {
    throw new Error('isEditingStaleObject: The docIds do not match');
  }

  const modifiedTimesAreDifferent = original.getLastUploadTime()!.unix() !== updated.getLastUploadTime()!.unix();
  const isBeingEditedByAnotherUser = original.getLastModifiedByUserId() !== userEditingTheObject;

  return modifiedTimesAreDifferent && isBeingEditedByAnotherUser;
};
