import type { ValidationResult } from 'joi';

import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { UserRequestName } from '../../../user-request-name';
import { UserResponse } from '../../generic/user-response/user-response';
import { UserResponseSchema } from '../../generic/user-response/user-response-schema';

import { UserResponseUpdateUserConstructor } from './user-response-update-user-constructor';
import { UserResponseUpdateUserError } from './user-response-update-user-error';
import { UserResponseUpdateUserSchema } from './user-response-update-user-schema';

export class UserResponseUpdateUser extends UserResponse<UserResponseUpdateUserError> {
  protected userId?: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: UserResponseUpdateUserConstructor) {
    super({
      ...parameters,
      [UserResponseSchema.type]: UserRequestName.updateUser,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  protected static _deserialize(validationResult: ValidationResult): UserResponseUpdateUser {
    return new UserResponseUpdateUser(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new UserResponseUpdateUserSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(UserResponseUpdateUser.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getUserId() {
    return this.userId;
  }
}
