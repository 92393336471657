import { SchemaField } from '../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';

export class ParentCallInfoTokenSchema extends SerializableObjectSchema {
  public static readonly callSid = 'callSid';

  public static readonly from = 'from';

  public static readonly to = 'to';

  public static readonly iat = 'iat';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly callSid = undefined;

    public static readonly from = undefined;

    public static readonly to = undefined;

    public static readonly iat = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[ParentCallInfoTokenSchema.callSid] = SchemaField.string(
      ParentCallInfoTokenSchema.Defaults.callSid,
    ).required();
    parameters[ParentCallInfoTokenSchema.from] = SchemaField.string(ParentCallInfoTokenSchema.Defaults.from).required();
    parameters[ParentCallInfoTokenSchema.to] = SchemaField.string(ParentCallInfoTokenSchema.Defaults.to).required();
    parameters[ParentCallInfoTokenSchema.iat] = SchemaField.number(ParentCallInfoTokenSchema.Defaults.iat).required();
    return parameters;
  }
}
