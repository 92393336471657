import type { AnySchema } from 'joi';
import Joi from 'joi';

import { SerializableObjectSchema } from '../../../../generic/serialization/serializable-object-schema';
import { AutomationOnCommunicationCompleteSchema } from '../../type/automation-on-communication-complete/automation-on-communication-complete-schema';
import { AutomationSchema } from '../automation/automation-schema';

export class AnyAutomationSchema extends SerializableObjectSchema {
  private static readonly SCHEMA_ID = 'anyAutomation';

  private static readonly computedSchema = Joi.alternatives()
    .try(new AutomationOnCommunicationCompleteSchema().getJoiSchema())
    .id(this.SCHEMA_ID);

  public static readonly type = 'type';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: AnySchema } | AnySchema {
    return AnyAutomationSchema.computedSchema;
  }

  public getCollection(orgId: string) {
    return new AutomationSchema().getCollection(orgId);
  }

  public getCollectionName() {
    return new AutomationSchema().getCollectionName();
  }
}
