import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { isNil } from 'lodash';
import { EMPTY, Observable, of } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';

import { ReleaseVersion, ReleaseVersionSchema } from '@pwp-common';

import { latestGitCommitHash } from '../../../environments/config';
import { DbDocumentService } from '../generic/db-document-service';
import { observableTakeOne } from '../generic/take-one';
import { AuthService } from '../user/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ReleaseVersionService extends DbDocumentService<ReleaseVersion> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(db: AngularFirestore, authService: AuthService) {
    super(db, authService, ReleaseVersion);
  }

  ///////////////////////////////////////////////////////////////////////
  // Base Methods
  ///////////////////////////////////////////////////////////////////////

  protected getSchema(): ReleaseVersionSchema {
    return new ReleaseVersionSchema();
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Version
  ///////////////////////////////////////////////////////////////////////

  public getVersion(): Observable<ReleaseVersion | undefined> {
    return this.getDoc(ReleaseVersionSchema.Constants.docId);
  }

  ///////////////////////////////////////////////////////////////////////
  // Periodically Refresh
  ///////////////////////////////////////////////////////////////////////

  /**
   * Periodically query the database to determine the latest version of the
   * app.
   */
  public loadLatestVersionPeriodically() {
    console.log('Starting Service: ReleaseVersionService');
    console.log(`Local Version: ${latestGitCommitHash}`);

    return setInterval(async () => {
      await this.refreshVersion().toPromise();
    }, ReleaseVersionSchema.Constants.refreshTimeMilliseconds);
  }

  ///////////////////////////////////////////////////////////////////////
  // Refresh on interval
  ///////////////////////////////////////////////////////////////////////

  /**
   * This function will grab the latestCommitHash from firebase. If it is out of
   * date then it will reload the app. Else, do nothing.
   */
  public refreshVersion(): Observable<void> {
    console.log('refreshVersion: Starting refresh');
    const observable = this.getVersion().pipe(
      switchMap((version) => {
        console.log('refreshVersion: Remote version', { remoteVersion: version?.getLatestGitCommitHash() });
        if (!isNil(version) && version.getLatestGitCommitHash() !== latestGitCommitHash) {
          console.log(
            `App is out of date. Local Version: ${latestGitCommitHash}, Latest Version=${version?.getLatestGitCommitHash()}`,
          );
          return this.appOutOfDateAction();
        }
        return EMPTY;
      }),
    );

    return observableTakeOne(observable, true);
  }

  /**
   * If the app is out of date then refresh. This function imposes
   * a delay to insure it does not refresh too often.
   */
  private appOutOfDateAction(): Observable<void> {
    const refreshHours = (ReleaseVersionSchema.Constants.refreshTimeMilliseconds / (1000.0 * 60 * 60)).toFixed(2);
    console.log(`App is out of date: Loading new app in ${refreshHours} hours.`);

    const observable = of('').pipe(
      delay(ReleaseVersionSchema.Constants.refreshTimeMilliseconds),
      map((_) => location.reload()),
    );

    return observable;
  }
}
