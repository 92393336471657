<ng-container *transloco="let t; read: 'data-table-caption'">
  <!-- Required to configure toast notifications -->
  <p-toast position="bottom-center"></p-toast>

  <div class="flex justify-content-between flex-wrap">
    <!-- Left Aligned Buttons -->
    <div class="flex align-items-center justify-content-between flex-wrap mt-3 gap-2">
      <p-multiSelect
        *transloco="let tableScopedT; read: translationScope"
        [dataKey]="'header'"
        [options]="cols"
        [placeholder]="t('selectColsPlaceholder')"
        [showHeader]="false"
        [style]="{ minWidth: '200px' }"
        [(ngModel)]="selectedCols"
        (onChange)="emitUpdatedCols()"
      >
        <ng-template pTemplate="item" let-col>
          {{ tableScopedT(col.header) }}
        </ng-template>
        <ng-template pTemplate="selectedItems">
          <div *ngIf="selectedCols?.length > 0">
            {{ t('numColsSelected', { num: selectedCols?.length ?? 0, total: cols?.length ?? 0 }) }}
          </div>
          <div *ngIf="!selectedCols || selectedCols.length === 0">
            {{ t('selectColsPlaceholder') }}
          </div>
        </ng-template>
      </p-multiSelect>
      <ng-container *ngIf="actionsTemplate" [ngTemplateOutlet]="actionsTemplate"> </ng-container>
      <p-button
        *ngIf="hasFilter()"
        icon="pi pi-filter-slash"
        styleClass="p-button-outlined"
        [ariaLabel]="t('clearButtonLabel')"
        [label]="t('clearButtonLabel')"
        (onClick)="clearTable(table)"
      ></p-button>
      <p-button
        *ngIf="refreshClicked"
        icon="pi pi-refresh"
        iconPos="left"
        styleClass="p-button-text"
        [ariaLabel]="t('refreshButtonLabel')"
        (onClick)="refreshClicked()"
      ></p-button>
      <p-button
        icon="pi pi-download"
        iconPos="left"
        styleClass="p-button-text"
        [ariaLabel]="t('downloadButtonLabel')"
        (onClick)="download ? download(table) : table.exportCSV()"
      ></p-button>
    </div>

    <!-- Right Aligned Buttons -->
    <div class="flex align-items-center justify-content-center mt-3">
      <span *ngIf="!rightActionsTemplate" class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          placeholder="{{ t('searchPlaceholder') }}"
          [(ngModel)]="globalFilter"
          (ngModelChange)="table.filterGlobal($event, 'contains')"
        />
      </span>
      <ng-container *ngIf="rightActionsTemplate" [ngTemplateOutlet]="rightActionsTemplate"> </ng-container>
    </div>
  </div>
</ng-container>
