import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { cloneDeep, isNil } from 'lodash';

import { IVROption, IVROptionSchema, SupportedLanguages, VoiceResponseCommand } from '@pwp-common';

import { getCommaSeparatedValue, getFieldValuesFromFormInComponent } from '../../../../common/objects/form-helper';
import { KVPair } from '../../../../common/objects/kvpair';
import { ObjEditor } from '../../../generic/abstract-classes/obj-editor';
import { PhrasesLangPair } from '../generic/phrases-lang-pair';

@Component({
  selector: 'app-ivr-option-editor',
  templateUrl: './ivr-option-editor.component.html',
  styleUrls: ['./ivr-option-editor.component.css'],
})
export class IvrOptionEditorComponent extends ObjEditor<KVPair<IVROption>> implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  phrases: PhrasesLangPair[];

  commands: VoiceResponseCommand[] = [];

  selectedPhraseLangKVPair: KVPair<PhrasesLangPair>;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(_changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parameters = getFieldValuesFromFormInComponent(
      undefined,
      [IVROptionSchema.displayName, IVROptionSchema.description],
      IVROptionSchema.Defaults,
      this,
    );

    parameters[IVROptionSchema.keyPresses] = getCommaSeparatedValue(
      this.commaSepKeyPresses,
      IVROptionSchema.Defaults.keyPresses,
    );

    if (!isNil(this.phrases)) {
      const phrases = new Map<string, string[]>();
      for (const phrase of this.phrases) {
        phrases.set(phrase.languageDefaults.getShortCode(), phrase.phrases);
      }
      parameters[IVROptionSchema.phrases] = phrases;
    }
    parameters[IVROptionSchema.commands] = cloneDeep(this.commands);

    const value = new IVROption(parameters);
    return new KVPair({ id: this.obj?.id, value });
  }

  getSelectedPhrasesObj(): PhrasesLangPair | undefined {
    if (isNil(this.phrases) || isNil(this.selectedPhraseLangKVPair?.id)) {
      return undefined;
    }
    return this.phrases[this.selectedPhraseLangKVPair!.id as number];
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: KVPair<IVROption>) {
    // Init Form
    const formConfig = {} as any;
    formConfig[IVROptionSchema.displayName] = [
      obj.value!.getDisplayName(),
      [Validators.required, Validators.maxLength(500)],
    ];
    formConfig[IVROptionSchema.description] = [
      obj.value!.getDescription(),
      [Validators.required, Validators.maxLength(500)],
    ];
    formConfig.commaSepKeyPresses = [obj.value!.getKeyPresses().join(','), [Validators.maxLength(500)]];

    this.form = this.formBuilder.group(formConfig);

    // Init other state
    this.phrases = [];
    for (const [shortCode, strArray] of obj.value!.getPhrases().entries()) {
      this.phrases.push(new PhrasesLangPair(SupportedLanguages.getDefaults(shortCode), strArray));
    }
    this.commands = cloneDeep(obj.value!.getCommands());
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  get displayName(): AbstractControl | null {
    return this.form.get(IVROptionSchema.displayName);
  }

  get description(): AbstractControl | null {
    return this.form.get(IVROptionSchema.description);
  }

  get commaSepKeyPresses(): AbstractControl | null {
    return this.form.get('commaSepKeyPresses');
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObj(): boolean {
    // This checks that the form is valid.
    if (!super.isValidObj()) {
      return false;
    }

    return true;
  }
}
