import { ITheme } from 'survey-core';

export const surveyJSTheme: ITheme = {
  themeName: 'default',
  colorPalette: 'light',
  isPanelless: false,
  backgroundImage: '',
  backgroundOpacity: 1,
  backgroundImageAttachment: 'scroll',
  backgroundImageFit: 'cover',
  cssVariables: {
    '--sjs-article-font-medium-lineHeight': '32px',
    '--sjs-article-font-medium-paragraphIndent': '0px',
    '--sjs-article-font-medium-textCase': 'none',
    '--sjs-article-font-default-textDecoration': 'none',
    '--sjs-article-font-default-fontWeight': '400',
    '--sjs-article-font-default-fontStyle': 'normal',
    '--sjs-article-font-default-fontStretch': 'normal',
    '--sjs-article-font-default-letterSpacing': '0',
    '--sjs-article-font-default-lineHeight': '28px',
    '--sjs-article-font-default-paragraphIndent': '0px',
    '--sjs-article-font-default-textCase': 'none',
    '--sjs-general-backcolor-dim': 'rgba(243, 243, 243, 1)',
    '--sjs-primary-backcolor': 'var(--primary-500)',
    '--sjs-primary-backcolor-dark': 'var(--primary-700)',
    '--sjs-primary-backcolor-light': 'var(--primary-100)',
    '--sjs-primary-forecolor': 'var(--pwp-color-white)',
    '--sjs-primary-forecolor-light': 'var(--pwp-color-black)',
  },
  headerView: 'basic',
};
