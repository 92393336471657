import { Mixin } from 'ts-mixer';

import { CommunicationSessionEventLogReservationOfferSchema } from '../../../../generic/communication-session-event-log-reservation-offer/communication-session-event-log-reservation-offer-schema';
import { CommunicationSessionEventLogSendMessageSchema } from '../../generic/communication-session-event-log-send-message/communication-session-event-log-send-message-schema';

export class CommunicationSessionEventLogSendReservationOfferSchema extends Mixin(
  CommunicationSessionEventLogSendMessageSchema,
  CommunicationSessionEventLogReservationOfferSchema,
) {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    ///////////////////////////////////////////////////////
    // Generic InboundCallEvent Properties, Copy/Pasted Here
    ///////////////////////////////////////////////////////

    public static readonly type = undefined;

    public static readonly actor = undefined;

    public static readonly timestamp = undefined;

    ///////////////////////////////////////////////////////
    // Sent Message Properties, Copy/Pasted Here
    ///////////////////////////////////////////////////////
    public static readonly errorCode = undefined;

    public static readonly from = undefined;

    public static readonly messageSid = undefined;

    public static readonly numMedia = undefined;

    public static readonly numSegments = undefined;

    public static readonly operatorTimestamps = new Map();

    ///////////////////////////////////////////////////////
    // CommunicationSessionEventLogSentMessage Properties
    ///////////////////////////////////////////////////////

    /**
     * Tracking Info
     */
    public static readonly messageType = undefined;

    public static readonly participant = undefined;

    ///////////////////////////////////////////////////////
    // ReservationOffer Properties
    ///////////////////////////////////////////////////////
    public static readonly communicationTaskType = undefined;

    public static readonly userStatus = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {
      ...new CommunicationSessionEventLogSendMessageSchema().getSchemaDefinition(),
      ...new CommunicationSessionEventLogReservationOfferSchema().getSchemaDefinition(),
    };

    /* eslint-disable */
    /* eslint-enable */

    return parameters;
  }
}
