import type { ValidationResult } from 'joi';
import { cloneDeep } from 'lodash';

import { DBMatchRuleDocPath } from '../../core/db-matcher/db-match-rule/db-match-rules/db-match-rule-doc-path/db-match-rule-doc-path';
import { DBDocObject } from '../../generic/db-doc/db-doc-object';
import { DBDocSchema } from '../../generic/db-doc/db-doc-schema';
import { NoteBody } from '../note-body/generic/note-body/note-body';

import { NoteConstructor } from './note-constructor';
import { NoteSchema } from './note-schema';

export class Note extends DBDocObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected body?: NoteBody;

  protected tags!: DBMatchRuleDocPath;

  protected redacted?: boolean;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: NoteConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new NoteSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  protected static _deserialize(validationResult: ValidationResult): Note {
    return new Note(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(Note.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getBody(): NoteBody | undefined {
    return cloneDeep(this.body);
  }

  public getTags(): DBMatchRuleDocPath {
    return cloneDeep(this.tags);
  }

  public isRedacted(): boolean {
    return this.redacted ?? false;
  }
}
