import { cloneDeep } from 'lodash';

import { VoiceResponse } from '../../../../helper/twilio-types';
import { DBDocObject } from '../../../generic/db-doc/db-doc-object';
import { DBDocSchema } from '../../../generic/db-doc/db-doc-schema';
import { Displayable } from '../../../generic/db-doc/displayable';
import { VoiceResponseCommand } from '../../../voice-response-command/generic/voice-response-command';
import { LanguageDefaults } from '../../../voice-response-command/vrc-audio-metadata/language-defaults';
import { IVROption } from '../ivr-option/ivr-option';

import { IVRConstructor } from './ivr-constructor';
import { IVRSchema } from './ivr-schema';

export class IVR extends DBDocObject implements Displayable {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected displayName!: string;

  protected description!: string;

  protected menuOptionsCommands!: VoiceResponseCommand[];

  protected invalidKeyPressedCommands!: VoiceResponseCommand[];

  protected options!: IVROption[];

  protected numRetriesRemaining!: number;

  protected onFailure!: VoiceResponseCommand[];

  protected maxDigits!: number;

  protected speechTimeout!: string;

  protected speechModel!: VoiceResponse.GatherSpeechModel;

  protected timeout!: number;

  protected defaultLanguage!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: IVRConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): IVR {
    return new IVR(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(IVR.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new IVRSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getDisplayName(): string {
    return cloneDeep(this.displayName);
  }

  public getDescription(): string {
    return cloneDeep(this.description);
  }

  public getMenuOptionsCommands(): VoiceResponseCommand[] {
    return cloneDeep(this.menuOptionsCommands);
  }

  public getInvalidKeyPressedCommands(): VoiceResponseCommand[] {
    return cloneDeep(this.invalidKeyPressedCommands);
  }

  public getOptions(): IVROption[] {
    return cloneDeep(this.options);
  }

  public getNumRetriesRemaining(): number {
    return cloneDeep(this.numRetriesRemaining);
  }

  public getOnFailure(): VoiceResponseCommand[] {
    return cloneDeep(this.onFailure);
  }

  public getMaxDigits(): number {
    return cloneDeep(this.maxDigits);
  }

  public getSpeechTimeout(): string {
    return cloneDeep(this.speechTimeout);
  }

  public getSpeechModel(): VoiceResponse.GatherSpeechModel {
    return cloneDeep(this.speechModel);
  }

  public getTimeout(): number {
    return cloneDeep(this.timeout);
  }

  public getDefaultLanguage(): string {
    return cloneDeep(this.defaultLanguage);
  }

  /**
   * Determine if the IVR should parse digits, or
   * both digits and speech.
   */
  public getInput(): VoiceResponse.GatherInput[] {
    const gatherInput: Set<VoiceResponse.GatherInput> = new Set([]);

    for (const option of this.options) {
      if (option.getKeyPresses().length !== 0) {
        gatherInput.add('dtmf');
      }
      if (Array.from(option.getPhrases().keys()).length !== 0) {
        gatherInput.add('speech');
      }
      if (gatherInput.size === 2) {
        break;
      }
    }
    if (gatherInput.size === 0) {
      throw new Error(`User Error: IVR with id=${this.getId()} does not take dtmf or speech input.`);
    }

    // Sort lexiographically so this function has deterministic output.
    return Array.from(gatherInput).sort();
  }
  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  public setId(id: string): IVR {
    this.id = id;
    return this;
  }

  /////////////////////////////////////////////////////////////////////////////
  // IVR Options
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Return the list of all hints for this IVR in expected format
   * https://www.twilio.com/docs/voice/twiml/gather#hints
   *
   * @param language
   */
  public getHints(language: LanguageDefaults): string {
    const hints = [];
    for (const option of this.options) {
      hints.push(...option.getPhrasesForLanguage(language));
    }
    return hints.sort().join(',');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Get Next Action
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This function is given the processed input taken from the user during their
   * interaction with an IVR. It determines what next action to take based on
   * user input.
   *
   * If there isn't a valid action to take based on this input we return undefined.
   *
   * @param detectedSpeech transctibed version of what the user said in the language of the IVR
   * @param keysPressed the keys pressed by the user if any
   * @param language: The selected language
   *
   */
  public getCommandsForInput(
    detectedSpeech: string | undefined,
    keysPressed: string | undefined,
    language: LanguageDefaults,
  ): VoiceResponseCommand[] | undefined {
    for (const option of this.options) {
      const commands = option.getCommandsForInput(detectedSpeech, keysPressed?.trim(), language);
      if (commands !== undefined) {
        return commands;
      }
    }

    return undefined;
  }
}
