import { AnyVoiceResponseCommand } from '../../../any-voice-response-command/any-voice-response-command';
import { VoiceResponseCommand } from '../../../generic/voice-response-command';
import { Hangup } from '../../../hangup/hangup';
import { SetContext } from '../../../set-context/set-context';
import { VRCIfTime } from '../../../voice-response-command-if-time/voice-response-command-if-time';

import { GetCommandsWithUpdatedIfTimeInput } from './interface';

export const getCommandsWithUpdatedIfTime = ({
  callEntrypoint,
  closedHoursCommands,
  timeSelection,
}: GetCommandsWithUpdatedIfTimeInput): VoiceResponseCommand[] => {
  const vrcCommand = new VRCIfTime({
    commands: [...closedHoursCommands.map((command) => AnyVoiceResponseCommand.deserialize(command)), new Hangup({})],
    timeSelection,
  });

  const callEntrypointCommands = callEntrypoint.getCommands();

  const currentIfTimeIndex = callEntrypointCommands.findIndex((command) => command instanceof VRCIfTime);
  const setContextIndex = callEntrypointCommands.findIndex((command) => command instanceof SetContext);

  const hasIfTime = currentIfTimeIndex > -1;

  const startIndex = hasIfTime ? currentIfTimeIndex : setContextIndex + 1;
  const endIndex = hasIfTime ? currentIfTimeIndex + 1 : setContextIndex + 1;

  return [...callEntrypointCommands.slice(0, startIndex), vrcCommand, ...callEntrypointCommands.slice(endIndex)];
};
