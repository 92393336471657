import { cloneDeep } from 'lodash';
import * as moment from 'moment-timezone';

import { SerializableObject } from '../serialization/serializable-object';

import { DBDocSchema } from './db-doc-schema';

export abstract class DBDocObject extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected id!: string;

  protected createTime: moment.Moment | undefined;

  protected createdByUserId: string | undefined;

  protected lastUploadTime: moment.Moment | undefined;

  protected lastModifiedByUserId: string | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Get Schema
  /////////////////////////////////////////////////////////////////////////////

  static getSchema(): DBDocSchema {
    throw new Error('DBDocObject.getSchema: UserError: This function should never be called.');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This object is stored under its collection with this key name.
   */
  public getId(): string {
    return cloneDeep(this.id);
  }

  public getLastUploadTime(): moment.Moment | undefined {
    return cloneDeep(this.lastUploadTime);
  }

  public getCreateTime(): moment.Moment | undefined {
    return cloneDeep(this.createTime);
  }

  public getLastModifiedByUserId(): string | undefined {
    return cloneDeep(this.lastModifiedByUserId);
  }

  public getCreatedByUserId(): string | undefined {
    return cloneDeep(this.createdByUserId);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  public setId(id: string): DBDocObject {
    this.id = id;
    return this;
  }

  public setLastModifiedByUserId(lastModifiedByUserId: string): DBDocObject {
    this.lastModifiedByUserId = lastModifiedByUserId;
    return this;
  }

  public setCreatedByUserId(createdByUserId: string): DBDocObject {
    this.createdByUserId = createdByUserId;
    return this;
  }
}
