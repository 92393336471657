import { ValidationResult } from 'joi';
import { cloneDeep } from 'lodash';

import { AutomationActionName } from '../../automation-action-name';
import { AutomationAction } from '../../generic/automation-action/automation-action';
import { AutomationActionSchema } from '../../generic/automation-action/automation-action-schema';

import { AutomationActionSendEmailConstructor } from './automation-action-send-email-constructor';
import { AutomationActionSendEmailSchema } from './automation-action-send-email-schema';

export class AutomationActionSendEmail extends AutomationAction {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected body!: string;

  protected subject!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: AutomationActionSendEmailConstructor) {
    super({
      ...parameters,
      [AutomationActionSchema.type]: AutomationActionName.sendEmail,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  protected static _deserialize(validationResult: ValidationResult): AutomationActionSendEmail {
    return new AutomationActionSendEmail(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): AutomationActionSendEmailSchema {
    return new AutomationActionSendEmailSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(AutomationActionSendEmail.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getBody(): string {
    return cloneDeep(this.body);
  }

  public getSubject(): string {
    return cloneDeep(this.subject);
  }
}
