import { cloneDeep, isNil } from 'lodash';

import { SerializableObjectSchema } from '../../../../../generic/serialization/serializable-object-schema';
import { DBMatchRule } from '../../generic/db-match-rule/db-match-rule';
import { DBMatchRuleSchema } from '../../generic/db-match-rule/db-match-rule-schema';
import { DBMatchRuleName } from '../../generic/db-match-rule-name';

import { DBMatchRuleDocPathConstructor } from './db-match-rule-doc-path-constructor';
import { DBMatchRuleDocPathSchema } from './db-match-rule-doc-path-schema';

export class DBMatchRuleDocPath extends DBMatchRule {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected orgScopedPaths!: string[];

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: DBMatchRuleDocPathConstructor) {
    (parameters as any)[DBMatchRuleSchema.type] = DBMatchRuleName.docPath;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): DBMatchRuleDocPath {
    return new DBMatchRuleDocPath(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    this.sanityCheck();
    return super.serialize(DBMatchRuleDocPath.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new DBMatchRuleDocPathSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Make QueryId
  /////////////////////////////////////////////////////////////////////////////

  public static makeQueryId(orgScopedPaths: string): string {
    if (isNil(orgScopedPaths)) {
      throw new Error('DBMatchRuleDocPath.makeQueryId: User Error, cannot match nil');
    }
    return `${DBMatchRuleName.docPath}_${orgScopedPaths}`;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Query IDs
  /////////////////////////////////////////////////////////////////////////////

  public getQueryIds(): string[] {
    return [...this.getOrgScopedPaths().map((z) => DBMatchRuleDocPath.makeQueryId(z))];
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getOrgScopedPaths() {
    return cloneDeep(this.orgScopedPaths);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Static method to validate orgScopedPaths
  /////////////////////////////////////////////////////////////////////////////

  static validateOrgScopedPaths(orgScopedPaths: string[]): boolean {
    /* Regex to ensure no "/" at the start or end
     *and no "orgs/" at the start
     */
    const regex = /^(?!\/|orgs\/)(?!.*\/$).*/;
    return orgScopedPaths.every((path) => regex.test(path));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Override sanityCheck
  /////////////////////////////////////////////////////////////////////////////

  sanityCheck() {
    super.sanityCheck();
    if (!DBMatchRuleDocPath.validateOrgScopedPaths(this.orgScopedPaths)) {
      throw new Error('DBMatchRuleDocPath.sanityCheck: Invalid orgScopedPaths');
    }
  }
}
