import { SchemaField } from '../../generic/serialization/schema-field';
import { VoiceResponseCommandName } from '../any-voice-response-command/voice-response-command-name';
import { VoiceResponseCommandSchema } from '../generic/voice-response-command-schema';

export class CreateAsyncServiceRequestSchema extends VoiceResponseCommandSchema {
  public static readonly createAsyncServiceRequestWidgetId = 'createAsyncServiceRequestWidgetId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly commandName = VoiceResponseCommandName.createAsyncServiceRequest;

    public static readonly createAsyncServiceRequestWidgetId = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } | import('joi').AnySchema {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[VoiceResponseCommandSchema.commandName] = SchemaField.string(
      CreateAsyncServiceRequestSchema.Defaults.commandName,
    );
    parameters[CreateAsyncServiceRequestSchema.createAsyncServiceRequestWidgetId] = SchemaField.autoGeneratedDocId(
      CreateAsyncServiceRequestSchema.Defaults.createAsyncServiceRequestWidgetId,
    ).required();
    /* eslint-enable */

    return parameters;
  }
}
