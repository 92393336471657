import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageService } from 'primeng/api';
import { combineLatestWith, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { OrgData, UserData } from '@pwp-common';

import { PTableCol } from '../../../../../common/p-table/p-table-col';
import { CommunicationTaskQueueService } from '../../../../../services/communication/communication-task-queue/communication-task-queue.service';
import { CommunicationWorkflowService } from '../../../../../services/communication/communication-workflow/communication-workflow.service';
import { OrgDataService } from '../../../../../services/orgs/org-data/org-data.service';
import { UserDataService } from '../../../../../services/user/user-data/user-data.service';
import { SettingsEditorTableBase } from '../../../../generic/settings/abstract-classes/settings-editor-table-base';
import { CommunicationWorkflowEditorComponent } from '../../editor/communication-workflow-editor/communication-workflow-editor.component';
import { CommunicationWorkflowTableRow } from '../communication-workflow-table-row/communication-workflow-table-row';

@UntilDestroy()
@Component({
  selector: 'app-communication-workflow-table-editor',
  templateUrl: './communication-workflow-table-editor.component.html',
  styleUrls: ['./communication-workflow-table-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class CommunicationWorkflowTableEditorComponent
  extends SettingsEditorTableBase<CommunicationWorkflowTableRow>
  implements OnInit
{
  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  private userData$: Observable<Map<string, UserData>>;

  private orgData$: Observable<OrgData>;

  ///////////////////////////////////////////////////////////////////////
  // Constants
  ///////////////////////////////////////////////////////////////////////

  cols: PTableCol[] = [
    { field: 'displayName', header: 'colDisplayName' },
    { field: 'description', header: 'colDescription' },
  ];

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////
  constructor(
    private dialog: MatDialog,
    private orgDataService: OrgDataService,
    private communicationWorkflowService: CommunicationWorkflowService,
    private communicationTaskQueueService: CommunicationTaskQueueService,
    private userDataService: UserDataService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    this.getOneTimeData();
    super.ngOnInit();
  }

  ///////////////////////////////////////////////////////////////////////
  // One Time Data
  ///////////////////////////////////////////////////////////////////////

  private getOneTimeData() {
    /**
     * Store one copy of data that doesn't change often
     */
    this.userData$ = this.userDataService.getDocs().pipe(shareReplay(1), untilDestroyed(this));
    this.orgData$ = this.orgDataService.getOrgData().pipe(shareReplay(1), untilDestroyed(this));
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Data
  ///////////////////////////////////////////////////////////////////////
  getDataWrapper = () => this.getData();

  getData() {
    this.loading = true;
    this.tableRows = this.communicationWorkflowService.getDocsArray().pipe(
      combineLatestWith(this.communicationTaskQueueService.getDocs()),
      combineLatestWith(this.orgData$),
      combineLatestWith(this.userData$),
      map(([[[communicationWorkflows, allTaskQueues], orgData], users]) => {
        const tableRows: CommunicationWorkflowTableRow[] = [];
        for (const communicationWorkflow of communicationWorkflows) {
          const row = new CommunicationWorkflowTableRow(communicationWorkflow, allTaskQueues, users, orgData);
          tableRows.push(row);
        }
        this.loading = false;
        this.changeDetectorRef.detectChanges();
        return tableRows;
      }),
    );
  }

  ///////////////////////////////////////////////////////////////////////
  // Create A Service Exception
  ///////////////////////////////////////////////////////////////////////
  createCommunicationWorkflow() {
    const dialogRef = this.dialog.open(CommunicationWorkflowEditorComponent, {
      hasBackdrop: true,
      minWidth: '80%',
      height: 'auto',
      maxHeight: '90vh',
    });
    dialogRef
      .afterClosed()
      .pipe()
      .subscribe(() => {
        this.getData();
        this.changeDetectorRef.detectChanges();
      });
  }
}
