import { SchemaField } from '../../generic/serialization/schema-field';
import { VoiceResponseCommandName } from '../any-voice-response-command/voice-response-command-name';
import { VoiceResponseCommandSchema } from '../generic/voice-response-command-schema';

export class DialCallListSchema extends VoiceResponseCommandSchema {
  // Id of the call list to dial
  public static readonly callListId = 'callListId';

  // Number of times to call each user in the call list.
  public static readonly numTimesDialEachUser = 'numTimesDialEachUser';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly commandName = VoiceResponseCommandName.dialCallList;

    public static readonly callListId = 'missing-callListId';

    public static readonly numTimesDialEachUser = 2;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } | import('joi').AnySchema {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[VoiceResponseCommandSchema.commandName] = SchemaField.string(DialCallListSchema.Defaults.commandName);
    parameters[DialCallListSchema.callListId] = SchemaField.string(DialCallListSchema.Defaults.callListId);
    parameters[DialCallListSchema.numTimesDialEachUser] = SchemaField.number(
      DialCallListSchema.Defaults.numTimesDialEachUser,
    );
    /* eslint-enable */

    return parameters;
  }
}
