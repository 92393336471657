import { cloneDeep } from 'lodash';

import { SerializableObject } from '../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';

import { OfferChannelConfigPhoneCallMachineDetectionConstructor } from './offer-channel-config-phone-call-machine-detection-constructor';
import { OfferChannelConfigPhoneCallMachineDetectionSchema } from './offer-channel-config-phone-call-machine-detection-schema';

export class OfferChannelConfigPhoneCallMachineDetection extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////
  protected hangupOnMachine!: boolean;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: OfferChannelConfigPhoneCallMachineDetectionConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only hangupOnMachine
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(
    validationResult: import('joi').ValidationResult,
  ): OfferChannelConfigPhoneCallMachineDetection {
    return new OfferChannelConfigPhoneCallMachineDetection(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(OfferChannelConfigPhoneCallMachineDetection.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new OfferChannelConfigPhoneCallMachineDetectionSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getHangupOnMachine() {
    return cloneDeep(this.hangupOnMachine);
  }
}
