import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProgressSpinnerModule as PrimeProgressSpinnerModule } from 'primeng/progressspinner';

import { ProgressSpinnerComponent } from './progress-spinner.component';

@NgModule({
  declarations: [ProgressSpinnerComponent],
  imports: [CommonModule, PrimeProgressSpinnerModule],
  exports: [ProgressSpinnerComponent],
})
export class ProgressSpinnerModule {}
