import { Directive, ElementRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, fromEvent, tap } from 'rxjs';

@UntilDestroy()
@Directive({
  selector: '[appShadowRootListener]',
  standalone: true,
  host: {
    '[id]': 'id',
  },
})
export class ShadowRootListenerDirective implements OnInit {
  private static id = 0;

  private readonly elementRef = inject(ElementRef);

  public readonly id =
    this.elementRef.nativeElement.id || `shadow-root-listener-${(ShadowRootListenerDirective.id += 1)}`;

  @Input('appShadowRootListener') public shadowHost: HTMLElement;

  @Output() public readonly shadowElementClick = new EventEmitter<MouseEvent>();

  public ngOnInit(): void {
    fromEvent<MouseEvent>(this.shadowHost, 'click')
      .pipe(
        filter((event) =>
          event.composedPath().some((element: HTMLElement) => element.id === this.elementRef.nativeElement.id),
        ),
        tap(() => console.log(this.shadowHost.shadowRoot.innerHTML)),
        untilDestroyed(this),
      )
      .subscribe((event) => this.shadowElementClick.emit(event));
  }
}
