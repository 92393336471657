import { cloneDeep, orderBy } from 'lodash';
import moment from 'moment-timezone';

import { LabeledData } from '../display/labeled-data';
import { getIntervalForWindow } from '../entity-stats-display/entity-stats-display-window/entity-stats-display-window';
import { EntityStatsDisplayWindow } from '../entity-stats-display/entity-stats-display-window/enum';

import { Usage } from './usage';
import { UsageCategory } from './usage-category';

/**
 * usageArr is an array of usage of possibly multiple categories. Further,
 * some months may have no usage of a given type, and as a result they will be missing.
 *
 * This method shards the data by category, fills in missing months with zero usage,
 * and sorts then. The result is an array of labeled data for each type.
 *
 * We do not assume that usageArr is sorted in any way.
 *
 * @param usageArr
 */
export const getUsageDisplayData = (
  usageArr: Usage[],
  window: EntityStatsDisplayWindow,
): Map<UsageCategory, LabeledData> => {
  // Shard usage by category
  const byCategory = new Map<UsageCategory, Usage[]>();
  for (const usage of usageArr) {
    if (byCategory.get(usage.getCategory()) === undefined) {
      byCategory.set(usage.getCategory(), []);
    }
    byCategory.get(usage.getCategory())?.push(usage);
  }

  const result = new Map<UsageCategory, LabeledData>();

  // Fill missing data with zero usage, sort, and convert to LabeledData
  for (const key of byCategory.keys()) {
    // const missingValues: Usage[] = [];

    // Presort the list of values
    let values = orderBy(byCategory.get(key), (z) => z.getWindowStart().valueOf(), ['asc']);

    // Filter to include only values in window
    const interval = getIntervalForWindow(window);
    values = values.filter((z) => interval.contains(z.getWindowStart()));

    // Fill in missing usage with 0's
    const naFilledValues: Usage[] = [];
    const start = moment(Math.max(interval.getStart(), moment().startOf('month').subtract('2', 'years').valueOf()));
    const end = moment(Math.min(interval.getEnd(), moment().startOf('month').valueOf()));
    for (const month = start; month.isSameOrBefore(end); month.add(1, 'month')) {
      let determinedValue = new Usage({
        category: key,
        windowStart: cloneDeep(month),
        windowEnd: cloneDeep(month).endOf('month'),
        asOf: moment(),
        usage: 0,
        id: '',
      });

      for (const value of values) {
        if (value.getWindowStart().unix() === month.unix()) {
          determinedValue = value;
          break;
        }
      }
      naFilledValues.push(determinedValue);
    }

    // Sort na filled values
    const sortedValues = orderBy(naFilledValues, (z) => z.getWindowStart().valueOf(), ['asc']);

    // Make result
    const sortedLabels = sortedValues.map((z) => z.getWindowStart().format('YYYY-MM'));
    const sortedUsage = sortedValues.map((z) => z.getUsage());
    result.set(key, new LabeledData(sortedLabels, sortedUsage));
  }

  return result;
};
