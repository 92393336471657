import { values } from 'lodash';

import { SchemaField } from '../../../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../../../generic/serialization/serializable-object-schema';
import { CommunicationWorkerAttributesTaskQueue } from '../../../communication-worker-attributes/communication-worker-attributes-task-queue/communication-worker-attributes-task-queue';
import { CommunicationWorkerName } from '../communication-worker-name';

export abstract class CommunicationWorkerSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly taskQueueAttributes = 'taskQueueAttributes';

  public static readonly type = 'type';

  public static readonly orgId = 'orgId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly taskQueueAttributes = new Map();

    public static readonly type = undefined;

    public static readonly orgId = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([CommunicationWorkerSchema.taskQueueAttributes]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // prettier-ignore
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[CommunicationWorkerSchema.taskQueueAttributes] = SchemaField.mapOfObj(CommunicationWorkerAttributesTaskQueue, CommunicationWorkerSchema.Defaults.taskQueueAttributes).optional();
    parameters[CommunicationWorkerSchema.type] = SchemaField.string(CommunicationWorkerSchema.type, CommunicationWorkerSchema.Defaults.type).valid(...values(CommunicationWorkerName));
    parameters[CommunicationWorkerSchema.orgId] = SchemaField.autoGeneratedDocId(CommunicationWorkerSchema.Defaults.orgId).required();
    /* eslint-enable */

    return parameters;
  }
}
