<ng-container *transloco="let t; read: 'event-create-dialog'">
  <!-- Required to configure toast notifications -->
  <p-toast position="bottom-center"></p-toast>

  <form *ngIf="!loading" [formGroup]="form">
    {{ t('type') }}: {{ data.orgData.getEventTypeWithInternalName(data.type).getDisplayName() }} <br />
    {{ t('start') }}: {{ data.start | momentPipe: data.orgData.getTimezone() }} <br />
    {{ t('end') }}: {{ data.end | momentPipe: data.orgData.getTimezone() }} <br /><br />

    <!-- Does Repeat -->
    <mat-checkbox formControlName="doesRepeat">{{ t('doesRepeat') }}</mat-checkbox>
    <br />

    <app-event-repeat
      *ngIf="doesRepeat?.value === true"
      [(obj)]="eventRepeatInfo"
      (objChange)="updateEvents()"
    ></app-event-repeat>

    <button mat-button [disabled]="form.invalid" (click)="addEvents()">
      {{ t('addEvents', { num: this.events?.length ?? 0 }) }}
    </button>
  </form>
</ng-container>
