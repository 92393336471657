import { JSONValue, Message, Participant, Conversation as TwilioConversation } from '@twilio/conversations';
import { isNil } from 'lodash';
import moment from 'moment-timezone';
import { BehaviorSubject } from 'rxjs';

import {
  ConversationAttributes,
  ConversationUserAttributes,
  ConversationUserIdentityType,
  TwilioConversationState,
  TwilioConversationStateType,
} from '@pwp-common';

import { MessageState } from '../message-state/message-state/message-state';

import { ConversationStateRealtime } from './conversation-state-realtime';
import { joinConversation } from './join/join-conversation';
import { getRoom, RoomWithMetadata } from './room/get-room';

export class Conversation {
  ///////////////////////////////////////////////////////////////////////////
  // Private Variables
  ///////////////////////////////////////////////////////////////////////////

  private hasMessageFromInternalParticipant = false;

  private participantIsInternal: Map<string, Promise<void>> = new Map();

  private initializationPromise: Promise<void>;

  private participants: Participant[] = [];

  ///////////////////////////////////////////////////////////////////////////
  // Public Variables
  ///////////////////////////////////////////////////////////////////////////

  public $state: BehaviorSubject<ConversationStateRealtime> = new BehaviorSubject(undefined);

  public $participants: BehaviorSubject<Participant[]> = new BehaviorSubject([]);

  public messageState: MessageState;

  public $room: BehaviorSubject<RoomWithMetadata> = new BehaviorSubject(undefined);

  ///////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////////

  constructor(private readonly params: { conversation: TwilioConversation; identity: string }) {
    this.initializationPromise = this.initialize();
  }

  ///////////////////////////////////////////////////////////////////////////
  // Initialize
  ///////////////////////////////////////////////////////////////////////////

  public waitForInitialization(): Promise<void> {
    return this.initializationPromise;
  }

  private async initialize() {
    try {
      this.messageState = new MessageState(this.params.conversation);
      this.participants = await this.params.conversation.getParticipants();
      this.setThisParticipant();
      await this.maintainParticipants();
      this.maintainActiveInactiveClosedState();
      this.maintainInProgressState();
      this.maintainRoom();
    } catch (error) {
      console.error('Conversation.initialize: Error initializing conversation', error);
    }
  }

  ///////////////////////////////////////////////////////////////////////////
  // Send Message
  ///////////////////////////////////////////////////////////////////////////

  public async send(body: string): Promise<void> {
    if (this.params.conversation.status === 'notParticipating') {
      await joinConversation(this.params.conversation);
      this.setThisParticipant();
    }
    await this.messageState.sendAssumingJoined(body);
  }

  ///////////////////////////////////////////////////////////////////////////
  // Room
  ///////////////////////////////////////////////////////////////////////////

  private maintainRoom() {
    this.emitRoom(this.params.conversation.attributes);
    this.params.conversation.on('updated', ({ conversation, updateReasons }) => {
      if (updateReasons.includes('attributes')) {
        this.emitRoom(conversation.attributes);
      }
    });
  }

  private emitRoom(attributesJSON: JSONValue | undefined): void {
    let attributes: ConversationAttributes | undefined;
    if (attributesJSON !== undefined) {
      attributes = ConversationAttributes.deserialize(attributesJSON);
    }

    const room = getRoom({
      conversation: this.params.conversation,
      timezone: moment.tz.guess(true),
      attributes,
    });

    this.$room.next(room);
  }

  ///////////////////////////////////////////////////////////////////////////
  // This Participant
  ///////////////////////////////////////////////////////////////////////////

  private getActiveSessionUserParticipant(): Participant | undefined {
    return this.participants.find((participant) => participant.identity === this.params.identity);
  }

  private setThisParticipant() {
    try {
      const thisParticipant = this.getActiveSessionUserParticipant();

      this.messageState.setThisParticipantSid(thisParticipant?.sid);
    } catch (error) {
      this.messageState.setThisParticipantSid(undefined);
      if (!error.message.endsWith('not found')) {
        console.log('Conversation.setThisParticipant: Unknown error getting this participant', error);
        console.error(error);
      }
    }
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Participants
  ////////////////////////////////////////////////////////////////////////////////

  private async maintainParticipants() {
    // Initial Data
    this.emitParticipants();

    // participantJoined
    this.params.conversation.on('participantJoined', (participant) => {
      // Replace existing participant
      for (let i = 0; i < this.participants.length; i++) {
        if (this.participants[i].sid === participant.sid) {
          this.participants[i] = participant;
          this.emitParticipants();
          return;
        }
      }

      // Add new
      this.participants.push(participant);
      this.emitParticipants();
    });

    // participantLeft
    this.params.conversation.on('participantLeft', (participant) => {
      for (let i = 0; i < this.participants.length; i++) {
        if (this.participants[i].sid === participant.sid) {
          this.participants.splice(i, 1);
          this.emitParticipants();
          return;
        }
      }
    });

    // participantUpdated
    this.params.conversation.on('participantUpdated', ({ participant }) => {
      for (let i = 0; i < this.participants.length; i++) {
        if (this.participants[i].sid === participant.sid) {
          this.participants[i] = participant;
          this.emitParticipants();
          return;
        }
      }

      // Add new
      this.participants.push(participant);
      this.emitParticipants();
    });
  }

  private emitParticipants() {
    this.$participants.next(this.participants);
  }

  ///////////////////////////////////////////////////////////////////////////
  // State: Active / Inactive / Closed / User Messaged
  ///////////////////////////////////////////////////////////////////////////

  private maintainActiveInactiveClosedState() {
    this.emitState(this.params.conversation.state.current);
    this.params.conversation.on('updated', ({ conversation: updatedConversation, updateReasons }) => {
      if (updateReasons.includes('state')) {
        this.emitState(updatedConversation.state.current);
      }
    });
  }

  private maintainInProgressState() {
    const messageAddedListener = (message: Message): void => {
      const { participantSid } = message;
      if (!isNil(participantSid) && !this.participantIsInternal.has(participantSid)) {
        const promise = this.params.conversation
          .getParticipantBySid(participantSid)
          .then((_) => _.getUser())
          .then((user) => {
            const attributes: ConversationUserAttributes = ConversationUserAttributes.deserialize(user.attributes);
            if (attributes.getIdentityType() === ConversationUserIdentityType.userId) {
              this.hasMessageFromInternalParticipant = true;
              this.emitState();
              this.params.conversation.removeListener('messageAdded', messageAddedListener);
              this.participantIsInternal = new Map();
            }
          })
          // Catch works like try-catch, only need one catch at the end.
          .catch((error) => {
            console.warn('There was an error in messageAddedListener. Will retry on the next receive', error);
            this.participantIsInternal.delete(participantSid);
          });
        this.participantIsInternal.set(participantSid, promise);
      }
    };
    this.params.conversation.onWithReplay('messageAdded', messageAddedListener);
  }

  private emitState(state?: TwilioConversationStateType): void {
    const enumState =
      TwilioConversationState[state ?? (this.$state.value.state as keyof typeof TwilioConversationState)];

    const value = {
      state: enumState,
      hasMessageFromInternalParticipant: this.hasMessageFromInternalParticipant,
    };

    this.$state.next(value);
  }

  ///////////////////////////////////////////////////////////////////////////
  // Shutdown
  ///////////////////////////////////////////////////////////////////////////

  public shutdown(): void {
    this.params.conversation.removeAllListeners();

    this.hasMessageFromInternalParticipant = false;
    this.participantIsInternal = new Map();
    this.initializationPromise = undefined;
    this.participants = [];
    this.messageState.shutdown();

    this.$state.next(undefined);
    this.emitParticipants();
    this.emitRoom(undefined);

    this.$state.complete();
    this.$participants.complete();
    this.$room.complete();
  }
}
