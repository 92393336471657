import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SharedModule } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';

import { TakeVoicemailSchema } from '@pwp-common';

import { provideValidator } from '../../../../common/form/provide-validator';
import { provideValueAccessor } from '../../../../common/form/provide-value-accessor';
import { FormGroupControlValueAccessor } from '../../../generic/abstract-classes/form-group-control-value-accessor';
import { VoicemailAutocompleteComponent } from '../../voicemail-autocomplete/voicemail-autocomplete.component';

@UntilDestroy()
@Component({
  selector: 'app-service-command-editor-take-voicemail',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MessagesModule,
    ReactiveFormsModule,
    SharedModule,
    TranslocoModule,
    VoicemailAutocompleteComponent,
  ],
  providers: [
    provideValueAccessor(ServiceCommandEditorTakeVoicemailComponent),
    provideValidator(ServiceCommandEditorTakeVoicemailComponent),
  ],
  templateUrl: './service-command-editor-take-voicemail.component.html',
  styleUrls: ['./service-command-editor-take-voicemail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceCommandEditorTakeVoicemailComponent extends FormGroupControlValueAccessor<unknown, unknown> {
  private readonly fb = inject(NonNullableFormBuilder);

  public readonly form = this.fb.group({
    commandName: [TakeVoicemailSchema.Defaults.commandName, [Validators.required]],
    voicemailConfigId: [null, [Validators.required]],
  });

  public parseValueChange(value: unknown): unknown {
    return value;
  }

  public makeValidationErrors(): ValidationErrors {
    return {
      'service-command-editor-take-voicemail': this.form.value,
    };
  }
}
