import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  TemplateRef,
} from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { cloneDeep, isEqual, isNil } from 'lodash';
import { ConfirmationService } from 'primeng/api';

import { NgChanges } from '../../../../common/objects/ng-changes';
import { FormGroupControlValueAccessor } from '../../abstract-classes/form-group-control-value-accessor';
import { FormType } from '../common/form-type';
import { SequenceStep } from '../common/sequence-step';
import { makeSequenceSteps } from '../make-sequence-steps/make-sequence-steps';

const VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SequenceStepsComponent),
  multi: true,
};

const VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => SequenceStepsComponent),
  multi: true,
};

@UntilDestroy()
@Component({
  selector: 'app-sequence-steps',
  templateUrl: './sequence-steps.component.html',
  styleUrls: ['./sequence-steps.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ConfirmationService, VALUE_ACCESSOR, VALIDATOR],
})
export class SequenceStepsComponent<OutputType>
  extends FormGroupControlValueAccessor<FormType<OutputType>, OutputType[]>
  implements OnChanges
{
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Input
  /////////////////////////////////////////////////////////////////////////////////////////////

  @Input() maxSteps: number;

  @Input() minSteps = 0;

  @Input() required = false;

  @Input() editorTemplate: TemplateRef<any>;

  @Input() stepHeaderTemplate: TemplateRef<any>;

  @Input() startHeader = '';

  @Input() endHeader = '';

  @Input() defaultStep: any;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////
  sequenceSteps: SequenceStep[] = [];
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
  ) {
    super(changeDetectorRef);
  }

  ngOnChanges(changes: NgChanges<SequenceStepsComponent<OutputType>>) {
    if (!isEqual(changes.maxSteps?.currentValue, changes.maxSteps?.previousValue)) {
      this.updateValidators();
      this.changeDetectorRef.detectChanges();
      return;
    }
    if (!isEqual(changes.minSteps?.currentValue, changes.minSteps?.previousValue)) {
      this.updateValidators();
      this.changeDetectorRef.detectChanges();
      return;
    }
    if (!isEqual(changes.required?.currentValue, changes.required?.previousValue)) {
      this.updateValidators();
      this.changeDetectorRef.detectChanges();
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineForm() {
    this.form = new UntypedFormGroup({
      steps: new UntypedFormArray([]),
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  parseValueChange(value: FormType<OutputType>): OutputType[] {
    const output: OutputType[] = value?.steps ?? [];
    return output;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Write Value
  /////////////////////////////////////////////////////////////////////////////////////////////

  writeValue(value: OutputType[]) {
    this.steps.clear({ emitEvent: false });
    for (const step of value ?? []) {
      const newControl = new UntypedFormControl(step);
      this.steps.insert(this.steps.length, newControl, { emitEvent: false });
    }
    this.updateUI();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected makeValidationErrors() {
    return {
      'sequence-steps': this.form.value,
    };
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Update UI
  /////////////////////////////////////////////////////////////////////////////////////////////

  private updateUI(): void {
    this.sequenceSteps = makeSequenceSteps(this.steps?.controls ?? []);
    this.updateValidators();
    this.changeDetectorRef.detectChanges();
    /**
     * We are intentionally triggering change detection here twice.
     * This is to update validity of form after adding/removing steps.
     * If we don't do this then we will incorrectly mark a sequence
     * where an invalid step was added as "Valid".
     *
     * Note that we can't write a unit test to verify this functionality
     * until we upgrade to testing-library v13
     * https://github.com/testing-library/angular-testing-library/issues/322
     *
     */
    this.steps.updateValueAndValidity();
    this.changeDetectorRef.detectChanges();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Remove Step
  /////////////////////////////////////////////////////////////////////////////////////////////

  removeStepAtIndex(index: number) {
    this.confirmationService.confirm({
      accept: () => {
        this.steps.removeAt(index);
        this.updateUI();
      },
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Add Step At Index
  /////////////////////////////////////////////////////////////////////////////////////////////
  addStepAtIndex(formControlIndex: number | undefined) {
    const insertionIndex = formControlIndex + 1 ?? 0;
    const newControl = new UntypedFormControl(cloneDeep(this.defaultStep), Validators.required);
    this.steps.insert(insertionIndex, newControl);
    this.updateUI();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Array
  /////////////////////////////////////////////////////////////////////////////////////////////

  get steps() {
    return this.form.get('steps') as UntypedFormArray;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Update validators
  /////////////////////////////////////////////////////////////////////////////////////////////

  updateValidators() {
    const validators: ValidatorFn[] = [];

    if (this.required) {
      validators.push(Validators.required);
    }

    if (!isNil(this.minSteps)) {
      validators.push(Validators.minLength(this.minSteps ?? 0));
    }

    if (!isNil(this.maxSteps)) {
      validators.push(Validators.maxLength(this.maxSteps));
    }

    this.steps.setValidators(validators);
    this.steps.updateValueAndValidity();
  }
}
