<ng-container *transloco="let t; read: 'org-settings-event-request-config'">
  <p-messages severity="info">
    <ng-template pTemplate>
      <div class="ml-2">{{ t('description') }}</div>
    </ng-template>
  </p-messages>
  <p-messages severity="warn">
    <ng-template pTemplate>
      <div class="ml-2">
        Thanks for checking out this feature! We're currently building this feature out, so please check back here in a
        couple of weeks! Any questions or comments? Please email spal&#64;helplinesoftware.com
      </div>
    </ng-template>
  </p-messages>
</ng-container>
<app-event-request-config-table></app-event-request-config-table>
