import type { AnySchema } from 'joi';
import { values } from 'lodash';

import { DBDocSchema } from '../../generic/db-doc/db-doc-schema';
import { SchemaField } from '../../generic/serialization/schema-field';

import { EventRequestRole } from './enums';

export class EventRequestConfigSchema extends DBDocSchema {
  public static readonly primary = 'primary';

  public static readonly backup = 'backup';

  public static readonly emailOnNewRequestCreated = 'emailOnNewRequestCreated';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'eventRequestConfig';
  };

  static Defaults = class {
    public static readonly primary = undefined;

    public static readonly backup = undefined;

    public static readonly emailOnNewRequestCreated = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: AnySchema } {
    const parameters = super.getSchemaDefinition();

    parameters[EventRequestConfigSchema.primary] = SchemaField.shortStringArray(
      EventRequestConfigSchema.Defaults.primary,
    ).items(...values(EventRequestRole));
    parameters[EventRequestConfigSchema.backup] = SchemaField.shortStringArray(
      EventRequestConfigSchema.Defaults.backup,
    ).items(...values(EventRequestRole));
    parameters[EventRequestConfigSchema.emailOnNewRequestCreated] = SchemaField.arrayOfType(
      SchemaField.email(undefined),
      EventRequestConfigSchema.Defaults.emailOnNewRequestCreated,
    ).optional();

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${EventRequestConfigSchema.Constants.collection}`;
  }
}
