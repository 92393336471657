<ng-template #objEditor>
  <form *ngIf="!loading" [formGroup]="form">
    <div class="form-row">
      <div class="col">
        <!-- Display Name -->
        <mat-form-field class="full-width">
          <textarea class="full-width" formControlName="displayName" matInput placeholder="Display Name"></textarea>
          <span *ngIf="displayName.invalid && displayName.touched"> Display Name Invalid </span>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Description -->
        <mat-form-field class="full-width">
          <textarea class="full-width" formControlName="description" matInput placeholder="Description"></textarea>
          <span *ngIf="description.invalid && description.touched"> Description Invalid </span>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row">
      <div class="col">
        <!-- Max digits -->
        <mat-form-field class="full-width">
          <input class="full-width" formControlName="maxDigits" matInput placeholder="Max Digits" type="number" />
          <span *ngIf="maxDigits.errors?.min && maxDigits.touched">
            maxDigits too low: {{ maxDigits.errors.min.actual }}
          </span>
          <span *ngIf="maxDigits.errors?.max && maxDigits.touched">
            maxDigits too high: {{ maxDigits.errors.max.actual }}
          </span>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Num Retries Remaining -->
        <mat-form-field class="full-width">
          <input
            class="full-width"
            formControlName="numRetriesRemaining"
            matInput
            matInput
            placeholder="Num Retries Remaining"
            type="number"
          />
          <span *ngIf="numRetriesRemaining.errors?.min && numRetriesRemaining.touched">
            numRetriesRemaining too low: {{ numRetriesRemaining.errors.min.actual }}
          </span>
          <span *ngIf="numRetriesRemaining.errors?.max && numRetriesRemaining.touched">
            numRetriesRemaining too high: {{ maxDigits.errors.max.actual }}
          </span>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Timeout Seconds -->
        <mat-form-field class="full-width">
          <input
            class="full-width"
            formControlName="timeout"
            matInput
            matInput
            placeholder="Timeout Seconds"
            type="number"
          />
          <span *ngIf="timeout.errors?.min && timeout.touched"> timeout too low: {{ timeout.errors.min.actual }} </span>
          <span *ngIf="timeout.errors?.max && timeout.touched">
            timeout too high: {{ timeout.errors.max.actual }}
          </span>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row">
      <div class="col">
        <!-- Speech Timeout -->
        <mat-form-field class="full-width">
          <mat-label>Speech Timeout</mat-label>
          <input class="full-width" formControlName="speechTimeout" matInput />
          <span *ngIf="speechTimeout.invalid && speechTimeout.touched"> invalid </span>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Speech Model -->
        <mat-form-field class="full-width">
          <mat-label>Gather Speech Model</mat-label>
          <mat-select formControlName="speechModel">
            <mat-option *ngFor="let v of possibleSpeechModels" [value]="v">{{ v }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Language -->
        <mat-form-field class="full-width">
          <mat-label>Default Language</mat-label>
          <mat-select formControlName="defaultLanguage">
            <mat-option *ngFor="let language of possibleLanguages" [value]="language.getShortCode()">{{
              language.getDisplayName()
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>

  <mat-accordion>
    <!-- Options -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Options </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <app-ivr-option-select-and-edit [(objList)]="options"> </app-ivr-option-select-and-edit>
    </mat-expansion-panel>

    <!-- Invalid Key Press Commands -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Invalid Key Press </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <app-vrc-list-editor [(objList)]="invalidKeyPressedCommands"></app-vrc-list-editor>
    </mat-expansion-panel>

    <!-- Menu Options -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Menu Options </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      Docs:
      <ul>
        <li>
          PlayOrSay, SetContext, and Switch are the
          <a href="https://www.twilio.com/docs/voice/twiml/gather#nest-other-verbs">only</a> nestable verbs since we
          don't implement pause.
        </li>
      </ul>

      <app-vrc-list-editor [(objList)]="menuOptionsCommands"></app-vrc-list-editor>
    </mat-expansion-panel>

    <!-- On Failure -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> On Failure </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <app-vrc-list-editor [(objList)]="onFailureCommands"></app-vrc-list-editor>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>

<app-obj-select-and-edit
  [doDelete]="doDelete"
  [doRefresh]="doRefresh"
  [doUpload]="doUpload"
  [editorTemplate]="objEditor"
  [label]="'IVR'"
  [modifiedSelectedItem]="getUpdatedObj()"
  [objIsValid]="isValidObjScoper"
  [orderedItems]="itemsArray | async"
  [selectedKVPair]="selectedKVPair"
  (duplicateClick)="duplicateClick()"
  (newClick)="newClick()"
  (selectedKVPairChange)="onSelectedKVPairChange($event)"
>
</app-obj-select-and-edit>
