<plotly-plot
  [config]="{
    displaylogo: false,
    modeBarButtonsToRemove: [
      'zoom2d',
      'pan2d',
      'select2d',
      'lasso2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
      'resetScale2d',
      'toggleSpikelines',
      'hoverClosestCartesian',
      'hoverCompareCartesian',
    ],
  }"
  [data]="traces"
  [layout]="{
    title: 'Calls Over Time',
    showlegend: true,
    hovermode: 'x unified',
    hoverlabel: {
      namelength: -1,
    },
    legend: { orientation: 'h' },
    xaxis: {
      title: 'Month',
    },
    yaxis: {
      title: 'Number of Calls',
    },
  }"
  [style]="{ position: 'relative', width: '100%' }"
  [useResizeHandler]="true"
>
</plotly-plot>
