import { cloneDeep } from 'lodash';

import { CommunicationSessionEventLogConferenceSchema } from '../../generic/communication-session-event-log-conference/communication-session-event-log-conference-schema';
import { CommunicationSessionEventLogConference } from '../../generic/communication-session-event-log-conference/communication-session-event-log-event-conference';
import { CommunicationSessionEventLogConferenceType } from '../../generic/communication-session-event-log-conference-type';

import { CommunicationSessionEventLogConferenceParticipantJoinConstructor } from './communication-session-event-log-conference-participant-join-constructor';
import { CommunicationSessionEventLogConferenceParticipantJoinSchema } from './communication-session-event-log-conference-participant-join-schema';

export class CommunicationSessionEventLogConferenceParticipantJoin extends CommunicationSessionEventLogConference {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected callSid!: string;

  protected coaching!: boolean;

  protected endConferenceOnExit!: boolean;

  protected hold!: boolean;

  protected muted!: boolean;

  protected startConferenceOnEnter!: boolean;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationSessionEventLogConferenceParticipantJoinConstructor) {
    (parameters as any)[CommunicationSessionEventLogConferenceSchema.conferenceEventType] =
      CommunicationSessionEventLogConferenceType.participantJoin;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(
    validationResult: import('joi').ValidationResult,
  ): CommunicationSessionEventLogConferenceParticipantJoin {
    return new CommunicationSessionEventLogConferenceParticipantJoin(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationSessionEventLogConferenceParticipantJoin.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): CommunicationSessionEventLogConferenceParticipantJoinSchema {
    return new CommunicationSessionEventLogConferenceParticipantJoinSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
  public getCallSid() {
    return cloneDeep(this.callSid);
  }

  public getCoaching() {
    return cloneDeep(this.coaching);
  }

  public getEndConferenceOnExit() {
    return cloneDeep(this.endConferenceOnExit);
  }

  public getHold() {
    return cloneDeep(this.hold);
  }

  public getMuted() {
    return cloneDeep(this.muted);
  }

  public getStartConferenceOnEnter() {
    return cloneDeep(this.startConferenceOnEnter);
  }
}
