<ng-container *transloco="let t; read: 'service-limit-editor'">
  <form [formGroup]="form">
    <div class="grid formgrid p-fluid">
      <!-- Enforce -->
      <div class="field col-12">
        <p>{{ t('enforceLabel') }}</p>
        <div class="flex align-items-center">
          <p-inputSwitch id="enforce" ariaLabel="{{ t('enforceLabel') }}" [formControl]="enforce"></p-inputSwitch>
        </div>
        <p class="wrap" id="enforce-help">{{ t('enforceHelp', { enforce: enforce.value }) }}</p>
      </div>

      <!-- Window Reset RRule -->
      <div class="field col-12">
        <legend>{{ t('windowResetRRuleHeader') }}</legend>
        <br />
        <app-rrule-editor [formControl]="windowResetRRule"></app-rrule-editor>
      </div>

      <!-- Service Delivered Number -->
      <div class="field col-12">
        <p>{{ t('serviceDeliveredNumLabel') }}</p>
        <div class="flex align-items-center">
          <p-inputNumber
            id="serviceDeliveredNum"
            mode="decimal"
            ariaLabel="{{ t('serviceDeliveredNumLabel') }}"
            [formControl]="serviceDeliveredNum"
            [max]="50000"
            [min]="0"
            [showButtons]="true"
          >
          </p-inputNumber>
        </div>
        <p class="wrap" id="serviceDeliveredNum-help">
          {{ t('serviceDeliveredNumHelp', { serviceDeliveredNum: serviceDeliveredNum.value }) }}
        </p>
      </div>
    </div>
  </form>
</ng-container>
