import { cloneDeep } from 'lodash';

import { CallLog } from '../../call/call-log/call-log';
import { CallState } from '../../call/call-log/call-state';
import { SerializableObject } from '../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../generic/serialization/serializable-object-schema';

import { CallStatsConstructor } from './call-stats-constructor';
import { CallStatsSchema } from './call-stats-schema';

/**
 * This class represents statistics about a collection of calls.
 */
export class CallStats extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  public iNum!: number;

  public iNumServiceRequested!: number;

  public iNumServiceNotRequested!: number;

  public iNumAnswered!: number;

  public iNumUnexpectedlyMissed!: number;

  public iNumEnforceBlockedCaller!: number;

  public iNumNoCapacity!: number;

  public iMsOperatorDuration!: number;

  public iMsRingDuration!: number;

  public iMsHoldDuration!: number;

  public iMsTalkDuration!: number;

  public iMsHoldDurationForAnsweredCalls!: number;

  public iMsHoldDurationForCallersThatHungUpEarly!: number;

  public iNumUsersAnswered!: number;

  public iNumUserIdsToCallWasEmpty!: number;

  public iNumBillableMinutes!: number;

  public iNumClientDisconnectBeforeServiceDeliveryAttemptComplete!: number;

  public iNumClientConnectedToUser!: number;

  /////////////////////////////////////////////////////////////////////////////
  // Private Vars used for calculation
  /////////////////////////////////////////////////////////////////////////////

  private usersThatAnsweredCalls = new Set<string>();

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CallStatsConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  public static _deserialize(validationResult: import('joi').ValidationResult): CallStats {
    return new CallStats(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CallStats.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CallStatsSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getINum(): number {
    return cloneDeep(this.iNum);
  }

  public getINumServiceRequested(): number {
    return cloneDeep(this.iNumServiceRequested);
  }

  public getINumServiceNotRequested(): number {
    return cloneDeep(this.iNumServiceNotRequested);
  }

  public getINumAnswered(): number {
    return cloneDeep(this.iNumAnswered);
  }

  public getINumUnexpectedlyMissed(): number {
    return cloneDeep(this.iNumUnexpectedlyMissed);
  }

  public getINumEnforceBlockedCaller(): number {
    return cloneDeep(this.iNumEnforceBlockedCaller);
  }

  public getINumNoCapacity(): number {
    return cloneDeep(this.iNumNoCapacity);
  }

  public getIMsOperatorDuration(): number {
    return cloneDeep(this.iMsOperatorDuration);
  }

  public getIMsRingDuration(): number {
    return cloneDeep(this.iMsRingDuration);
  }

  public getIMsHoldDuration(): number {
    return cloneDeep(this.iMsHoldDuration);
  }

  public getIMsHoldDurationForAnsweredCalls(): number {
    return cloneDeep(this.iMsHoldDurationForAnsweredCalls);
  }

  public getIMsHoldDurationForCallersThatHungUpEarly(): number {
    return cloneDeep(this.iMsHoldDurationForCallersThatHungUpEarly);
  }

  public getIMsTalkDuration(): number {
    return cloneDeep(this.iMsTalkDuration);
  }

  public getINumUsersAnswered(): number {
    return cloneDeep(this.iNumUsersAnswered);
  }

  public getINumUserIdsToCallWasEmpty(): number {
    return cloneDeep(this.iNumUserIdsToCallWasEmpty);
  }

  public getINumBillableMinutes(): number {
    return cloneDeep(this.iNumBillableMinutes);
  }

  public getINumClientDisconnectBeforeServiceDeliveryAttemptComplete(): number {
    return cloneDeep(this.iNumClientDisconnectBeforeServiceDeliveryAttemptComplete);
  }

  public getINumClientConnectedToUser(): number {
    return cloneDeep(this.iNumClientConnectedToUser);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Stats
  /////////////////////////////////////////////////////////////////////////////

  public avgRingTimeMS(): number {
    if (this.getINum() === 0) {
      return 0;
    }

    return this.getIMsRingDuration() / this.getINum();
  }

  public avgHoldTimeMS(): number {
    if (this.getINumServiceRequested() === 0) {
      return 0;
    }

    return this.getIMsHoldDuration() / this.getINumServiceRequested();
  }

  public avgHoldTimeForAnsweredCallsMS(): number {
    if (this.getINumAnswered() === 0) {
      return 0;
    }

    return this.getIMsHoldDurationForAnsweredCalls() / this.getINumAnswered();
  }

  public avgHoldTimeForCallersThatHungUpEarlyMS(): number {
    if (this.getINumClientDisconnectBeforeServiceDeliveryAttemptComplete() === 0) {
      return 0;
    }

    return (
      this.getIMsHoldDurationForCallersThatHungUpEarly() /
      this.getINumClientDisconnectBeforeServiceDeliveryAttemptComplete()
    );
  }

  public avgTalkTimeMS(): number {
    if (this.getINumAnswered() === 0) {
      return 0;
    }

    return this.getIMsTalkDuration() / this.getINumAnswered();
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Update this obj
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Update this object to include the given call
   * @param callLog callLog to update the object with
   */
  public updateWith(callLog: CallLog): CallStats {
    if (callLog.getState() !== CallState.completed) {
      return this;
    }

    this.iNum += 1;
    this.iMsOperatorDuration += callLog.getOperatorDurationMS() || 0;

    if (callLog.callListSelected()) {
      this.iNumServiceRequested++;
    } else {
      this.iNumServiceNotRequested++;
    }

    if (callLog.getUserIdsToCall().length === 0 && callLog.callListSelected()) {
      this.iNumUserIdsToCallWasEmpty += 1;
    }

    if (callLog.wasAnswered()) {
      this.iNumAnswered++;
      this.usersThatAnsweredCalls.add(callLog.getAnsweredBy()!);
      this.iMsHoldDurationForAnsweredCalls += callLog.getHoldDurationMS();
    }

    this.iNumUsersAnswered = this.usersThatAnsweredCalls.size;

    if (callLog.wasUnexpectedlyMissed()) {
      this.iNumUnexpectedlyMissed += 1;
    }

    this.iNumBillableMinutes += callLog.getNumBillableMinutes();

    if (callLog.wasBlocked()) {
      this.iNumEnforceBlockedCaller++;
    }

    if (!callLog.hadCapacity()) {
      this.iNumNoCapacity += 1;
    }

    if (callLog.didClientDisconnectBeforeServiceDeliveryAttemptComplete()) {
      this.iNumClientDisconnectBeforeServiceDeliveryAttemptComplete += 1;
      this.iMsHoldDurationForCallersThatHungUpEarly += callLog.getHoldDurationMS();
    }

    if (callLog.wasClientConnectedToUser()) {
      this.iNumClientConnectedToUser += 1;
    }

    this.iMsRingDuration += callLog.getRingDurationMS();
    this.iMsHoldDuration += callLog.getHoldDurationMS();
    this.iMsTalkDuration += callLog.getTalkDurationMS();

    return this;
  }
}
