import { SchemaField } from '../../../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../../../generic/serialization/serializable-object-schema';
import { ServiceOperation } from '../../../../../voice-response-command/service-operation/service-operation/service-operation';

export abstract class ServiceExceptionActionSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The name of the command
  public static readonly onCreate = 'onCreate';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly onCreate = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[ServiceExceptionActionSchema.onCreate] = SchemaField.obj(
      ServiceOperation,
      ServiceExceptionActionSchema.Defaults.onCreate,
    );
    /* eslint-enable */

    return parameters;
  }
}
