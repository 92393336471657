import Joi from 'joi';

import { SchemaField } from '../../generic/serialization/schema-field';
import { AnyVoiceResponseCommand } from '../any-voice-response-command/any-voice-response-command';
import { AnyVoiceResponseCommandSchema } from '../any-voice-response-command/any-voice-response-command-schema';
import { VoiceResponseCommandName } from '../any-voice-response-command/voice-response-command-name';
import { VoiceResponseCommand } from '../generic/voice-response-command';
import { VoiceResponseCommandSchema } from '../generic/voice-response-command-schema';
import { Say } from '../say/say';
import { SaySchema } from '../say/say-schema';

export class EnforceBlockedCallerSchema extends VoiceResponseCommandSchema {
  // Commands to execute if the caller should be blocked.
  public static readonly commands = 'commands';
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly commandName = VoiceResponseCommandName.enforceBlockedCaller;

    public static readonly commands: VoiceResponseCommand[] = [
      new Say({
        message: 'This call is not allowed at this time.',
        loop: 1,
        language: 'en-US',
        voice: SaySchema.Defaults.voice,
      }),
    ];
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Return the schema keys. This needs to be manually specified whenever the SchemaDefinition is a Joi.AnySchema.
   */
  public getSchemaKeys(includeDocId = false): string[] {
    return [VoiceResponseCommandSchema.commandName, EnforceBlockedCallerSchema.commands];
  }

  private generateSchemaDefinition(includeShared: boolean): import('joi').AnySchema {
    const parameters = {} as any;

    parameters[VoiceResponseCommandSchema.commandName] = SchemaField.string(
      EnforceBlockedCallerSchema.Defaults.commandName,
    );
    parameters[EnforceBlockedCallerSchema.commands] = SchemaField.shortObjArray(
      AnyVoiceResponseCommand,
      EnforceBlockedCallerSchema.Defaults.commands,
      undefined,
      Joi.link(AnyVoiceResponseCommandSchema.getSchemaId()),
    );

    if (includeShared) {
      return Joi.object(parameters).shared(new AnyVoiceResponseCommandSchema().getJoiSchema());
    }
    return Joi.object(parameters);
  }

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } | import('joi').AnySchema {
    return this.generateSchemaDefinition(true);
  }

  public getJoiSchemaWithoutShared(): import('joi').AnySchema {
    return this.generateSchemaDefinition(false);
  }
}
