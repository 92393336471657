import { Pipe, PipeTransform } from '@angular/core';

import {
  AllDataEvent,
  AssignedUserType,
  canRequestSlot,
  createDefaultEventRequestConfig,
  EventRequestConfig,
  EventRequestRole,
} from '@pwp-common';

import { EventRequestDecisionKey } from './event-request-decision-key';

interface EventRequestDecisionPipeInput {
  allDataEvent: AllDataEvent;
  eventRequestConfig: EventRequestConfig | null;
  slot: AssignedUserType;
}

@Pipe({
  name: 'eventRequestDecision',
  standalone: true,
})
export class EventRequestDecisionPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  public transform({ allDataEvent, slot, ...params }: EventRequestDecisionPipeInput): EventRequestDecisionKey {
    const eventRequestConfig = params.eventRequestConfig ?? createDefaultEventRequestConfig();
    const eventData = allDataEvent.getEventData();

    if (!canRequestSlot({ eventData, slot, eventRequestConfig })) {
      return EventRequestDecisionKey.cannotRequest;
    }

    if (eventRequestConfig.hasRole(slot, EventRequestRole.approveRequest)) {
      return EventRequestDecisionKey.autoApprove;
    }

    if (
      allDataEvent.getRequests().filter((request) => request.getRequestedSlot() === slot && request.getIsOpen())
        .length > 0
    ) {
      return EventRequestDecisionKey.alreadyRequested;
    }

    return EventRequestDecisionKey.needsApproval;
  }
}
