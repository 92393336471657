/**
 * This file handles converting EventData to/from objects suitable for displaying in a calendar
 */

import { EventInput } from '@fullcalendar/core';

import {
  AllDataEvent,
  AllDataEventDisplay,
  displayTimeRangeShort,
  EventData,
  EventDataSchema,
  isMultiDayTimeRange,
  isNilOrDefault,
  OrgData,
} from '@pwp-common';

const eventDataSchema = new EventDataSchema();
/**
 * Convert EventData into object suitable for representing event in a calendar.
 *
 */
export const makeCalendarEvent = (allEventData: AllDataEvent, orgData: OrgData): EventInput => {
  const eventData = allEventData.getEventData();
  const allEventDataDisplay = new AllDataEventDisplay(allEventData, orgData);

  return {
    start: eventData.getStart().toDate(),
    end: eventData.getEnd().toDate(),
    id: eventData.getId(),
    title: eventTitle(allEventDataDisplay),
    backgroundColor: eventBackgroundColor(eventData),
    borderColor: eventBorderColor(eventData),
    textColor: eventTextColor(eventData),
    extendedProps: allEventDataDisplay,
    resourceId: eventData.getType(),
  };
};

//////////////////////////////////////////////////////////////////////////////////////////
// Calendar Event Properties: Color
//////////////////////////////////////////////////////////////////////////////////////////

/**
 * Parse color out of eventData. Used for displaying event in calendar.
 *
 * @param eventData Event to be displayed.
 */
const eventBackgroundColor = (eventData: EventData): string => {
  // If an event has a manually specified color, then use that.
  if (!isNilOrDefault(eventData.getColor(), EventDataSchema.color, eventDataSchema)) {
    return eventData.getColor();
  }

  // Primary user is unassigned
  if (isNilOrDefault(eventData.getAssignedUserId(), EventDataSchema.assignedUserId, eventDataSchema)) {
    return EventDataSchema.Colors.open;
  }

  return EventDataSchema.Colors.taken;
};

/**
 * Determine border color for box representing event in calendar. Used for displaying event.
 *
 * @param eventData Event to be displayed in calendar.
 */
const eventBorderColor = (eventData: EventData): string => 'silver';

/**
 * Determine text color text representing event in calendar. Used for displaying event.
 *
 * @param eventData Event to be displayed in calendar.
 */
const eventTextColor = (eventData: EventData): string => 'black';

//////////////////////////////////////////////////////////////////////////////////////////
// Calendar Event Properties: Title
//////////////////////////////////////////////////////////////////////////////////////////

/**
 * Determine text representing event in calendar. Used for displaying event.
 *
 * @param allDataEventDisplay Event to be displayed in calendar.
 */
export const eventTitle = (allDataEventDisplay: AllDataEventDisplay): string => {
  let multiDayString = '';

  const timezone = allDataEventDisplay.orgData.getTimezone();
  const eventData = allDataEventDisplay.allDataEvent.getEventData();
  if (isMultiDayTimeRange(eventData.getStart(), eventData.getEnd(), timezone)) {
    multiDayString = `(${displayTimeRangeShort(eventData.getStart(), eventData.getEnd(), timezone)})`;
  }

  let assignedUserDisplayName = '';
  if (!isNilOrDefault(eventData.getAssignedUserId(), EventDataSchema.assignedUserId, eventDataSchema)) {
    assignedUserDisplayName = allDataEventDisplay.primaryAllDataUserDisplay?.displayName || 'Deleted User';
  }

  let assignedBackupUserDisplayName = '';
  if (!isNilOrDefault(eventData.getAssignedBackupUserId(), EventDataSchema.assignedBackupUserId, eventDataSchema)) {
    assignedBackupUserDisplayName = allDataEventDisplay.backupAllDataUserDisplay?.displayName || 'Deleted User';
  }

  // Cleaner look: Don't return separator if nothing to separate.
  if (assignedUserDisplayName === '' && assignedBackupUserDisplayName === '') {
    return ` | ${multiDayString}`;
  }

  const namesWithoutMultiDayString = `${assignedUserDisplayName} | ${assignedBackupUserDisplayName}`;
  if (multiDayString.length === 0) {
    return namesWithoutMultiDayString;
  }
  return `${assignedUserDisplayName} | ${assignedBackupUserDisplayName} ${multiDayString}`;
};
