import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';

import { RequestObj } from '../../generic/request/request-obj';

import { BlockedCallerRequestConstructor } from './blocked-caller-request-constructor';
import { BlockedCallerRequestSchema } from './blocked-caller-request-schema';

export class BlockedCallerRequest extends RequestObj {
  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: BlockedCallerRequestConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected blockedCallerId!: string;

  protected sessionId!: string;

  protected expireTime!: moment.Moment;

  protected reason!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): BlockedCallerRequest {
    return new BlockedCallerRequest(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    const serialized = super.serialize(BlockedCallerRequest.getSchema(), 'api');
    return serialized;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): BlockedCallerRequestSchema {
    return new BlockedCallerRequestSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getBlockedCallerId(): string {
    return cloneDeep(this.blockedCallerId);
  }

  public getSessionId(): string {
    return cloneDeep(this.sessionId);
  }

  public getExpireTime(): moment.Moment {
    return cloneDeep(this.expireTime);
  }

  public getReason(): string {
    return cloneDeep(this.reason);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  public setExpireTime(expireTime: moment.Moment) {
    this.expireTime = expireTime;
    return this;
  }
}
