import { cloneDeep } from 'lodash';

import { SerializableObject } from '../../../../generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../../generic/serialization/serializable-object-schema';
import { CallListOfferChannelConfigPhoneCall } from '../call-list-offer-channel-config-phone-call/call-list-offer-channel-config-phone-call';

import { CallListOfferConfigConstructor } from './call-list-offer-config-constructor';
import { CallListOfferConfigSchema } from './call-list-offer-config-schema';

export class CallListOfferConfig extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected offerChannelConfigPhoneCall!: CallListOfferChannelConfigPhoneCall;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CallListOfferConfigConstructor) {
    super(parameters);
  }
  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is protected, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CallListOfferConfig {
    return new CallListOfferConfig(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(CallListOfferConfig.getSchema());
  }
  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CallListOfferConfigSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getOfferChannelConfigPhoneCall() {
    return cloneDeep(this.offerChannelConfigPhoneCall);
  }
}
