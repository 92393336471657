import { SchemaField } from '../../../generic/serialization/schema-field';
import { SerializableObjectSchema } from '../../../generic/serialization/serializable-object-schema';

export class ServiceLimitServiceUsageThresholdSchema extends SerializableObjectSchema {
  public static readonly serviceDeliveredNum = 'serviceDeliveredNum';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly serviceDeliveredNum = 100;
  };

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([ServiceLimitServiceUsageThresholdSchema.serviceDeliveredNum]);
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = {} as { [key: string]: import('joi').AnySchema };

    /* eslint-disable */
    parameters[ServiceLimitServiceUsageThresholdSchema.serviceDeliveredNum] = SchemaField.number(
      ServiceLimitServiceUsageThresholdSchema.Defaults.serviceDeliveredNum,
    );
    /* eslint-enable */

    return parameters;
  }
}
