import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

// @Todo: enable onPush and change getter to property once updated to Angular 18: https://github.com/angular/angular/pull/54579
// Currently not possible because touch does not update form status, so there is no way to reactively observe touch state changes

@UntilDestroy()
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'app-form-field-help',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './form-field-help.component.html',
  styleUrls: ['./form-field-help.component.scss'],
  host: {
    '[class.form-field-help--error]': 'isInErrorState',
    '[attr.role]': 'isInErrorState ? "alert" : null',
  },
})
export class FormFieldHelpComponent {
  @Input() public control: AbstractControl<unknown>;

  public get isInErrorState(): boolean {
    return this.control.invalid && this.control.touched;
  }
}
