import { BlobLocation } from '../../generic/blob-storage/blob-location';

import { LanguageDefaults } from './language-defaults';

export class VRCAudioBlobLocation extends BlobLocation {
  static StorageDirectories = class {
    public static readonly collection = 'voiceResponseCommandAudio';
  };

  public static Constants = class {
    public static readonly EXTENSION_WAV = 'wav';
  };

  public getAllowedFileExtensions(): string[] {
    return [VRCAudioBlobLocation.Constants.EXTENSION_WAV];
  }

  public getStorageDir(orgId: string, vrcAudioId: string): string {
    return `${this.orgDir(orgId)}/${VRCAudioBlobLocation.StorageDirectories.collection}/${vrcAudioId}`;
  }

  public getFileFullPath(orgId: string, vrcAudioId: string, language: LanguageDefaults): string {
    const filename = `${language.getShortCode()}.${VRCAudioBlobLocation.Constants.EXTENSION_WAV}`;
    return `${this.getStorageDir(orgId, vrcAudioId)}/${filename}`;
  }
}
