<ng-container *transloco="let t; read: 'communication-logs-table'">
  <!-- Required to configure toast notifications -->
  <p-toast position="bottom-center"></p-toast>
  <p-table
    #dt
    dataKey="id"
    responsiveLayout="scroll"
    sortMode="multiple"
    styleClass="p-datatable-gridlines"
    [columns]="selectedCols"
    [globalFilterFields]="[
      'communicationTypeDetail',
      'receivedAtE164Phone',
      'day',
      'receiveTime',
      'holdDuration',
      'end',
      'talkDuration',
      'answeredBy',
      'disconnectReason',
      'stoppedDialingReason',
      'disconnectedBy',
      'numCounselorsOnCall',
      'hasAnonymousCaller',
      'language',
      'id',
      'incomingCallSID',
      'numUserMessages',
      'numExternalMessages',
    ]"
    [loading]="loading"
    [paginator]="true"
    [rowExpandMode]="contactCenterMode ? 'single' : 'multiple'"
    [rows]="50"
    [rowsPerPageOptions]="[25, 50, 100, 500]"
    [value]="tableRows"
    (onRowCollapse)="onRowCollapse()"
    (onRowExpand)="onRowExpand($event.data)"
  >
    <!-- Select Columns to Display -->
    <ng-template pTemplate="caption">
      <div *ngIf="roles$ | async as roles" class="row">
        <!-- Left Aligned Buttons -->
        <div class="col-6" style="text-align: left">
          <mat-form-field *ngIf="roles?.orgAdmin" appearance="fill">
            <mat-label>{{ t('columns') }}</mat-label>
            <mat-select multiple [(value)]="selectedCols">
              <mat-option *ngFor="let col of cols" [value]="col">{{ t(col.field) }}</mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-icon-button [attr.aria-label]="t('refresh')" (click)="getData()">
            <mat-icon>refresh</mat-icon>
          </button>
          <button *ngIf="roles?.orgAdmin" mat-icon-button [attr.aria-label]="t('download')" (click)="dt.exportCSV()">
            <mat-icon>download</mat-icon>
          </button>
          <button *ngIf="hasFilter(dt)" mat-icon-button [attr.aria-label]="t('clear')" (click)="dt.clear()">
            <mat-icon>clear_all</mat-icon>
          </button>
        </div>
        <!-- Right Aligned Buttons -->
        <div class="col-6" style="text-align: right">
          <mat-form-field appearance="fill">
            <mat-label> {{ t('dateRange') }} </mat-label>
            <mat-date-range-input [formGroup]="form" [rangePicker]="picker">
              <input formControlName="start" matStartDate placeholder="{{ t('startDate') }}" />
              <input formControlName="end" matEndDate placeholder="{{ t('endDate') }}" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker>
              <mat-date-range-picker-actions>
                <button mat-button matDateRangePickerCancel>{{ t('cancel') }}</button>
                <button color="primary" mat-raised-button matDateRangePickerApply (click)="getData()">
                  {{ t('apply') }}
                </button>
              </mat-date-range-picker-actions>
            </mat-date-range-picker>

            <mat-error *ngIf="form.controls.start.hasError('matStartDateInvalid')">{{
              t('invalidStartDate')
            }}</mat-error>
            <mat-error *ngIf="form.controls.end.hasError('matEndDateInvalid')">{{ t('invalidEndDate') }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </ng-template>

    <!-- Header -->
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 3em"></th>
        <th *ngFor="let col of columns">
          {{ t(col.field) }}
          <p-columnFilter display="menu" type="text" field="{{ col.field }}"></p-columnFilter>
        </th>
      </tr>
    </ng-template>

    <!-- Display Rows -->
    <ng-template pTemplate="body" let-columns="columns" let-expanded="expanded" let-rowData>
      <tr [ngClass]="rowData.alertType" [pSelectableRow]="rowData">
        <td>
          <a href="#" [pRowToggler]="rowData">
            <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
          </a>
        </td>
        <td *ngFor="let col of columns" class="ui-resizable-column">
          <div [ngSwitch]="col.field">
            <!-- Incoming Phone Call -->
            <div *ngSwitchCase="'communicationType'">{{ t(rowData[col.field]) }}</div>
            <div *ngSwitchDefault>{{ rowData[col.field] }}</div>
          </div>
        </td>
      </tr>
    </ng-template>

    <!-- Expansion -->
    <ng-template pTemplate="rowexpansion" let-columns="columns" let-rowData>
      <tr>
        <td [attr.colspan]="columns.length + 1">
          <div [ngSwitch]="rowData?.communicationType">
            <!-- Incoming Phone Call -->
            <app-communication-log-detail-call
              *ngSwitchCase="'incomingPhoneCall'"
              [allUserDataMap]="allUserDataMap"
              [callLogDisplay]="rowData"
              [orgData]="orgData"
            >
            </app-communication-log-detail-call>

            <!-- Chat -->
            <app-communication-log-detail-conversation
              *ngSwitchCase="'chat'"
              [allUserDataMap]="allUserDataMap"
              [conversationLogDisplay]="rowData"
              [orgData]="orgData"
            >
            </app-communication-log-detail-conversation>

            <!-- Text -->
            <app-communication-log-detail-conversation
              *ngSwitchCase="'text'"
              [allUserDataMap]="allUserDataMap"
              [conversationLogDisplay]="rowData"
              [orgData]="orgData"
            >
            </app-communication-log-detail-conversation>
          </div>
        </td>
      </tr>
    </ng-template>

    <!-- Empty Message -->
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length + 1">
          {{ t('emptyMessage') }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>
