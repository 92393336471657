import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogService } from '@ngneat/dialog';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LetModule } from '@ngrx/component';
import { isEmpty } from 'lodash';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { BehaviorSubject, filter, firstValueFrom, ReplaySubject, scan, shareReplay, switchMap } from 'rxjs';

import {
  AllDataEventDisplay,
  AssignedUserType,
  EventData,
  EventRequest,
  EventRequestConfig,
  EventRequestOpenConstructor,
  EventRequestSchema,
  getFirstRequestableSlot,
  hasUnassignedRequestableSlot,
} from '@pwp-common';

import { makePTableCols } from '../../../common/p-table/make-p-table-cols';
import { equalTo } from '../../../services/core/queries/where/operations';
import { where } from '../../../services/core/queries/where/where';
import { EventRequestConfigService } from '../../../services/event/event-requests/event-request-config.service';
import { EventRequestsService } from '../../../services/event/event-requests/event-requests.service';
import { EventTransactionService } from '../../../services/event/event-requests/event-transaction.service';
import { AuthService } from '../../../services/user/auth/auth.service';
import { DataTableModule } from '../../generic/data-table/data-table.module';

import { EventRequestDecisionPipe } from './event-request-decision/event-request-decision.pipe';
import { EventRequestTableRow } from './event-request-table-row/event-request-table-row';
import { RequestedSlotSelectComponent } from './requested-slot-select/requested-slot-select.component';

@UntilDestroy()
@Component({
  selector: 'app-make-event-request-table',
  standalone: true,
  imports: [
    ButtonModule,
    CardModule,
    CommonModule,
    DataTableModule,
    EventRequestDecisionPipe,
    FormsModule,
    LetModule,
    RadioButtonModule,
    ReactiveFormsModule,
    RequestedSlotSelectComponent,
    ToastModule,
    TranslocoModule,
  ],
  templateUrl: './make-event-request-table.component.html',
  styleUrls: ['./make-event-request-table.component.scss'],
  providers: [MessageService],
})
export class MakeEventRequestTableComponent implements OnChanges {
  private readonly changes = new ReplaySubject<void>(1);

  @Input() public selectedEventType!: string;

  public uploadingRequest = false;

  public loading = false;

  public readonly assignedUserType = AssignedUserType;

  public readonly cols = makePTableCols({
    columns: ['details', 'type', 'currentPrimary', 'currentBackup', 'requestedSlot', 'decision', 'remove'],
    translationScope: 'make-event-request-table',
  });

  public readonly requestedSlotForm = new FormGroup<Record<string, FormControl<AssignedUserType>>>({});

  public readonly selectedAllDataEventDisplay = new Map<string, AllDataEventDisplay>();

  public readonly eventRequestConfigs$ = this.changes.pipe(
    filter(() => !isEmpty(this.selectedEventType)),
    switchMap(() => this.eventRequestConfigService.getDoc(this.selectedEventType)),
    scan((acc, config) => ({ ...acc, [this.selectedEventType]: config }), {} as Record<string, EventRequestConfig>),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  public readonly tableRows$ = new BehaviorSubject<EventRequestTableRow[]>([]);

  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private eventRequestConfigService: EventRequestConfigService,
    private eventRequestTransactionService: EventTransactionService,
    private eventsRequestsService: EventRequestsService,
    private messageService: MessageService,
    private translocoService: TranslocoService,
  ) {}

  ///////////////////////////////////////////////////////////////////////
  // Toast Messages
  ///////////////////////////////////////////////////////////////////////

  private showAdded(): void {
    const addedSuccessTitle = this.translocoService.translate('make-event-request-table.addedToTableSummary');
    const howToUseTable = this.translocoService.translate('make-event-request-table.howToUseTable');

    this.messageService.add({
      severity: 'success',
      summary: addedSuccessTitle,
      detail: howToUseTable,
    });
  }

  private showWarnNotRequestable(): void {
    const warnNotRequestableTitle = this.translocoService.translate('make-event-request-table.warnNotRequestableTitle');
    const warnNotRequestableBody = this.translocoService.translate('make-event-request-table.warnNotRequestableBody');

    this.messageService.add({
      severity: 'warn',
      summary: warnNotRequestableTitle,
      detail: warnNotRequestableBody,
    });
  }

  private showWarnAlreadyAdded(): void {
    const alreadyAddedToTable = this.translocoService.translate('make-event-request-table.alreadyAddedToTable');
    const howToUseTable = this.translocoService.translate('make-event-request-table.howToUseTable');

    this.messageService.add({
      severity: 'info',
      summary: alreadyAddedToTable,
      detail: howToUseTable,
    });
  }

  private showUploadSuccess(): void {
    const uploadSuccessTitle = this.translocoService.translate('make-event-request-table.uploadSuccessSummary');
    const uploadSuccessDetail = this.translocoService.translate('make-event-request-table.uploadSuccessDetail');

    this.messageService.add({
      severity: 'success',
      summary: uploadSuccessTitle,
      detail: uploadSuccessDetail,
    });
  }

  ///////////////////////////////////////////////////////////////////////
  // Confirm Requesting Already Requested Item
  ///////////////////////////////////////////////////////////////////////

  private confirmRequestOfAlreadyRequestedEvent(eventDisplayObj: AllDataEventDisplay): void {
    const title = this.translocoService.translate('make-event-request-table.confirmRequestAlreadyRequestedTitle');
    const body = this.translocoService.translate('make-event-request-table.confirmRequestAlreadyRequestedBody', {
      type: eventDisplayObj.type,
      start: eventDisplayObj.startTime,
      end: eventDisplayObj.endTime,
      numOpenRequests: eventDisplayObj.numRequests,
    });

    this.dialogService
      .confirm({
        title,
        body,
      })
      .afterClosed$.pipe(
        filter((confirmed) => confirmed),
        untilDestroyed(this),
      )
      .subscribe(() => this.addEventWithoutCheck(eventDisplayObj));
  }

  private async addEventWithoutCheck(eventDisplayObj: AllDataEventDisplay): Promise<void> {
    this.selectedAllDataEventDisplay.set(eventDisplayObj.id, eventDisplayObj);
    this.showAdded();
    await this.getData();
  }

  private getEventData(eventId: string): EventData {
    return this.selectedAllDataEventDisplay.get(eventId).allDataEvent.getEventData();
  }

  private async updateRequestedSlotForm(tableRows: EventRequestTableRow[]): Promise<void> {
    const eventRequestConfigs = await firstValueFrom(this.eventRequestConfigs$);

    Object.keys(this.requestedSlotForm.controls).forEach((controlName) => {
      if (!this.selectedAllDataEventDisplay.has(controlName)) {
        this.requestedSlotForm.removeControl(controlName);
      }
    });

    tableRows.forEach(({ id }) => {
      if (!this.requestedSlotForm.contains(id)) {
        const initialValue = getFirstRequestableSlot({
          eventData: this.getEventData(id),
          eventRequestConfig: eventRequestConfigs[this.selectedEventType],
        });
        this.requestedSlotForm.addControl(id, new FormControl(initialValue));
      }
    });
  }

  private async getData(): Promise<void> {
    this.loading = true;
    const tableRows = [...this.selectedAllDataEventDisplay.values()].map(
      (data) => new EventRequestTableRow({ eventData: data.allDataEvent, orgData: data.orgData }),
    );
    this.tableRows$.next(tableRows);
    this.loading = false;
    await this.updateRequestedSlotForm(tableRows);

    this.changeDetectorRef.detectChanges();
  }

  public ngOnChanges(): void {
    this.changes.next();
  }

  public getDataWrapper = () => this.getData();

  public async onRowRemoved(rowData: EventRequestTableRow): Promise<void> {
    this.selectedAllDataEventDisplay.delete(rowData.id);
    await this.getData();
  }

  ///////////////////////////////////////////////////////////////////////
  // Submit
  ///////////////////////////////////////////////////////////////////////

  public async submit(): Promise<void> {
    this.uploadingRequest = true;
    try {
      // Get UserId
      const userId = await firstValueFrom(this.authService.getUserId());

      const query = where<typeof EventRequestSchema>({
        requestedAssignedUserId: equalTo(userId),
        isOpen: equalTo(true),
      });
      const pendingRequests = await firstValueFrom(this.eventsRequestsService.getDocs(query));
      const eventIdsAlreadyRequested = new Set(Array.from(pendingRequests.values()).map((z) => z.getEventId()));

      const requests = [...this.selectedAllDataEventDisplay.values()].reduce((acc, event) => {
        const eventId = event.allDataEvent.getEventData().getId();

        if (eventId in eventIdsAlreadyRequested) {
          console.log(`You have already requested ${eventId}, and it is still pending. Not requesting this event`);
          return acc;
        }

        const request = new EventRequest({
          eventId,
          isOpen: true,
          requestedAssignedUserId: userId,
          requestedSlot: this.requestedSlotForm.get(event.id).value,
        } as EventRequestOpenConstructor);
        return [...acc, request];
      }, []);

      await this.eventRequestTransactionService.makeEventRequestTransaction(requests);
      this.selectedAllDataEventDisplay.clear();
      this.showUploadSuccess();
    } catch (error) {
      console.error('Error uploading request', error);
    } finally {
      this.uploadingRequest = false;
      await this.getData();
      this.changeDetectorRef.detectChanges();
    }
  }

  ///////////////////////////////////////////////////////////////////////
  // Input Data
  ///////////////////////////////////////////////////////////////////////

  public async addEvent(eventDisplayObj: AllDataEventDisplay): Promise<void> {
    const eventRequestConfigs = await firstValueFrom(this.eventRequestConfigs$);

    if (
      !hasUnassignedRequestableSlot({
        eventData: eventDisplayObj.allDataEvent.getEventData(),
        eventRequestConfig: eventRequestConfigs[this.selectedEventType],
      })
    ) {
      return this.showWarnNotRequestable();
    }

    if (this.selectedAllDataEventDisplay.get(eventDisplayObj.id)) {
      return this.showWarnAlreadyAdded();
    }

    if (eventDisplayObj.numRequests > 0) {
      return this.confirmRequestOfAlreadyRequestedEvent(eventDisplayObj);
    }

    return this.addEventWithoutCheck(eventDisplayObj);
  }
}
