import { untilDestroyed } from '@ngneat/until-destroy';
import { Participant } from '@twilio/conversations';
import { isEqual, isNil } from 'lodash';
import { BehaviorSubject, combineLatestAll, map, Observable, of, startWith, switchMap } from 'rxjs';

import { TwilioConversationState } from '@pwp-common';

import { RoomUser } from '../chat-component/interfaces';
import { ConversationStateRealtime } from '../conversation-client/conversation/conversation-state-realtime';
import { RoomWithMetadata } from '../conversation-client/conversation/room/get-room';
import { ConversationClient } from '../conversation-client/conversation-client/conversation-client';
import { getDisplayableParticipant } from '../get-displayable-participant';

import { createDisplayableMessage } from './displayable-message/create-displayable-message';
import { DisplayableMessage } from './displayable-message/displayable-message';
import { PerConversationAction, PerConversationActionMenuItem } from './per-conversation-action-type';

type UIState = 'conversationDoesNotExist';

export interface ConversationClientDisplayConstructor {
  client: ConversationClient;
  isAdmin?: boolean;
}

export class ConversationClientDisplay {
  ////////////////////////////////////////////////////////////////////////////////////////////
  // Private State
  ////////////////////////////////////////////////////////////////////////////////////////////

  private selectedConversationSids: Set<string>;

  private selectedConversationSid: string | undefined;

  private readonly client = this.parameters.client;

  private readonly selectedConversationSid$ = new BehaviorSubject<string | undefined>(undefined);

  ////////////////////////////////////////////////////////////////////////////////////////////
  // Public State
  ////////////////////////////////////////////////////////////////////////////////////////////

  public $authExpired = new BehaviorSubject<boolean>(false);

  public $messagesLoaded = new BehaviorSubject<boolean>(false);

  public $participants = new BehaviorSubject<RoomUser[]>([]);

  public $perConversationMenuItems = new BehaviorSubject<PerConversationActionMenuItem[]>([]);

  public $room = new BehaviorSubject<RoomWithMetadata>(undefined);

  public $rooms = new BehaviorSubject<RoomWithMetadata[]>([]);

  public $roomsLoaded = new BehaviorSubject<boolean>(true);

  public $state = new BehaviorSubject<ConversationStateRealtime | undefined>(undefined);

  public readonly displayableMessages$ = this.selectedConversationSid$.pipe(
    switchMap((conversationSid) => this.getDisplayableMessages(conversationSid)),
    startWith([]),
  );

  ////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////////////////////////

  constructor(private parameters: ConversationClientDisplayConstructor) {
    this.client.$authExpired.pipe(untilDestroyed(this, 'closeObservers')).subscribe((result) => {
      this.emitAuthExpired(result);
    });
    this.client.$subscribedConversations.pipe(untilDestroyed(this, 'closeObservers')).subscribe((result) => {
      this.subscribeRooms(result);
    });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////
  // Messages
  ////////////////////////////////////////////////////////////////////////////////////////////

  private getDisplayableMessages(conversationSid: string): Observable<DisplayableMessage[]> {
    const conversation = this.client.getConversation(conversationSid);

    if (!conversation) {
      return of([]);
    }

    return conversation.messageState.conversationMessagesStates$.pipe(
      switchMap((messagesStates) =>
        Promise.all(
          messagesStates.map((messageState) =>
            createDisplayableMessage({
              ...messageState,
              conversation,
            }),
          ),
        ),
      ),
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////
  // Shutdown
  ////////////////////////////////////////////////////////////////////////////////////////////

  public closeObservers() {}

  public triggerSwitchConversation() {}

  public triggerSwitchConversationList() {}

  public async shutdown() {
    this.closeObservers();
    await this.client.shutdown();
    this.$perConversationMenuItems.next([]);
    this.emitState(undefined);
    console.log('ConversationClientDisplay: Shutdown complete');
  }

  ////////////////////////////////////////////////////////////////////////////////////////////
  // Auth Expired
  ////////////////////////////////////////////////////////////////////////////////////////////

  private emitAuthExpired(expired: boolean) {
    this.$authExpired.next(expired);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////
  // Rooms
  ////////////////////////////////////////////////////////////////////////////////////////////

  private subscribeRooms(sids: Set<string>) {
    console.log('ConversationClientDisplay.subscribeRooms: List of subscribed rooms updated', { sids });
    this.triggerSwitchConversationList();
    const observables: Observable<RoomWithMetadata>[] = [];

    for (const sid of sids) {
      const observable = this.client
        .getConversation(sid)
        ?.$room.pipe(untilDestroyed(this, 'triggerSwitchConversationList'), untilDestroyed(this, 'closeObservers'));

      if (observable !== undefined) {
        observables.push(observable);
      }
    }

    of(...observables)
      .pipe(
        combineLatestAll(),
        map((rooms) => {
          const filteredSortedRooms = (rooms ?? []).filter((z) => z !== undefined);
          this.$rooms.next(filteredSortedRooms);
          this.$roomsLoaded.next(true);
        }),
      )
      .subscribe();

    if (observables.length === 0) {
      this.$rooms.next([]);
      this.$roomsLoaded.next(true);
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////
  // Room
  ////////////////////////////////////////////////////////////////////////////////////////////
  private subscribeRoom(sid: string) {
    this.client
      .getConversation(sid)
      ?.$room.pipe(untilDestroyed(this, 'triggerSwitchConversation'), untilDestroyed(this, 'closeObservers'))
      .subscribe((result) => {
        this.emitRoom(result);
      });
  }

  private emitRoom(room: RoomWithMetadata | undefined) {
    this.$room.next(room);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////
  // State
  ////////////////////////////////////////////////////////////////////////////////////////////
  private subscribeState(sid: string) {
    this.client
      .getConversation(sid)
      ?.$state.pipe(untilDestroyed(this, 'triggerSwitchConversation'), untilDestroyed(this, 'closeObservers'))
      .subscribe((result) => {
        this.emitState(result);
      });
  }

  private emitState(state: ConversationStateRealtime | undefined) {
    this.$state.next(state);
    this.emitPerConversationActions(state);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////
  // Messages Loaded
  ////////////////////////////////////////////////////////////////////////////////////////////

  private subscribeMessagesLoaded(sid: string) {
    console.log('ConversationClientDisplay.subscribeMessagesLoaded', { sid });

    this.client
      .getConversation(sid)
      ?.messageState?.$messagesLoaded.pipe(
        untilDestroyed(this, 'triggerSwitchConversation'),
        untilDestroyed(this, 'closeObservers'),
      )
      .subscribe((result) => {
        this.emitMessagesLoadedState(result);
      });
  }

  private emitMessagesLoadedState(state: boolean) {
    this.$messagesLoaded.next(state);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////
  // Participants
  ////////////////////////////////////////////////////////////////////////////////////////////

  private subscribeParticipants(sid: string) {
    this.client
      .getConversation(sid)
      ?.$participants.pipe(untilDestroyed(this, 'triggerSwitchConversation'), untilDestroyed(this, 'closeObservers'))
      .subscribe((result) => {
        this.emitParticipants(result);
      });
  }

  private emitParticipants(participants: Participant[]) {
    if (isNil(participants)) {
      return;
    }
    this.$participants.next(participants.map(getDisplayableParticipant));
  }

  ////////////////////////////////////////////////////////////////////////////////////////////
  // Per Conversation Actions
  ////////////////////////////////////////////////////////////////////////////////////////////

  private emitPerConversationActions(state: ConversationStateRealtime | undefined) {
    const menuActions: PerConversationActionMenuItem[] = [];
    if (isNil(state) || !this.parameters.isAdmin) {
      this.$perConversationMenuItems.next([]);
      return;
    }
    if (state.state !== TwilioConversationState.closed) {
      menuActions.push({
        name: PerConversationAction.closeAndDeleteConversation,
        title: PerConversationAction.closeAndDeleteConversation,
      });
    }

    this.$perConversationMenuItems.next(menuActions);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////
  // Public Setters
  ////////////////////////////////////////////////////////////////////////////////////////////

  setSelectedConversation = async (sid: string, force = false) => {
    console.log('ConversationClientDisplay.setSelectedConversation', { sid });
    if (isNil(sid) || (sid === this.selectedConversationSid && !force)) {
      return;
    }
    this.emitMessagesLoadedState(false);

    let uiState: UIState;
    try {
      await this.client.subscribeConversation(sid);
    } catch (error) {
      const errorCode = error.body?.code;
      switch (errorCode) {
        case 50350: {
          uiState = 'conversationDoesNotExist';
          break;
        }
        default: {
          console.error('ConversationClientDisplay.setSelectedConversation: Unhandled error', error);
        }
      }
    }

    // Unsubscribe from any previously subscribed conversations
    this.triggerSwitchConversation();

    this.updateUI(sid, uiState);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////
  // Update UI
  ////////////////////////////////////////////////////////////////////////////////////////////

  private updateUI(sid: string, uiState?: UIState) {
    this.selectedConversationSid$.next(sid);
    this.selectedConversationSid = sid;

    // Reset state to unknown
    this.emitState(undefined);

    this.subscribeRoom(sid);
    this.subscribeMessagesLoaded(sid);
    this.subscribeParticipants(sid);
    this.subscribeState(sid);

    switch (uiState) {
      case 'conversationDoesNotExist': {
        // Conversation not found (Eg, Deleted Conversation)
        this.emitPerConversationActions(undefined);
        this.emitParticipants([]);
        this.emitMessagesLoadedState(true);
      }
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////
  // Set Subscribed Conversations
  ////////////////////////////////////////////////////////////////////////////////////////////

  public async setSubscribedConversations(sids: Set<string>) {
    console.log('ConversationClientDisplay.setSubscribedConversations: Starting', { sids });

    // Always stay subscribed to the selected conversation
    const conversationsToSubscribe = new Set([...sids]);
    if (!isNil(this.selectedConversationSid)) {
      conversationsToSubscribe.add(this.selectedConversationSid);
    }

    if (isEqual(this.selectedConversationSids, conversationsToSubscribe)) {
      /**
       * Prevent a race condition: If this function is rapidly called multiple times with the same value then
       * $rooms will never emit because triggerSwitchConversationList is called before the first emit. Subsequent
       * calls to this function won't cause $rooms to emit.
       */
      return;
    }
    this.selectedConversationSids = conversationsToSubscribe;
    this.triggerSwitchConversationList();
    this.$roomsLoaded.next(false);
    for (const sid of this.client.$subscribedConversations.getValue()) {
      if (!conversationsToSubscribe.has(sid)) {
        this.client.removeSubscription(sid);
      }
    }

    try {
      // Subscribe
      await Promise.all([Array.from(conversationsToSubscribe).map((sid) => this.client.subscribeConversation(sid))]);

      // Select a conversation
      if (conversationsToSubscribe.size > 0) {
        if (!isNil(this.selectedConversationSid)) {
          await this.setSelectedConversation(this.selectedConversationSid, true);
        } else {
          await this.setSelectedConversation(Array.from(sids).sort()[0]);
        }
      }
      console.log('ConversationClientDisplay.setSubscribedConversations: Complete', { sids });
    } catch (error) {
      console.error('ConversationClientDisplay.setSubscribedConversations: Error', { sids });
      console.error(error);
      this.$roomsLoaded.next(false);
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////
  // Public Getters
  ////////////////////////////////////////////////////////////////////////////////////////////

  public getSelectedConversationSid(): string | undefined {
    return this.selectedConversationSid;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////
  // Send Message
  ////////////////////////////////////////////////////////////////////////////////////////////

  public sendMessage(message: string) {
    void this.client.getConversation(this.getSelectedConversationSid()).send(message);
  }
}
