import { cloneDeep } from 'lodash';
import Mustache from 'mustache';

import { DBDocSchema } from '../../generic/db-doc/db-doc-schema';
import { GenericDisplayable } from '../../generic/displayable/generic-displayable';
import { TemplateText } from '../../text/template-text/template-text';
import { LanguageDefaults } from '../../voice-response-command/vrc-audio-metadata/language-defaults';

import { ConversationMessageTemplateConstructor } from './conversation-message-template-constructor';
import { ConversationMessageTemplateSchema } from './conversation-message-template-schema';
import { ConversationMessageTemplateType } from './conversation-message-template-type';

export class ConversationMessageTemplate extends GenericDisplayable {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected type?: ConversationMessageTemplateType;

  protected body!: TemplateText;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ConversationMessageTemplateConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ConversationMessageTemplate {
    return new ConversationMessageTemplate(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ConversationMessageTemplate.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new ConversationMessageTemplateSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getType(): ConversationMessageTemplateType | undefined {
    return cloneDeep(this.type);
  }

  public getBody(): TemplateText {
    return cloneDeep(this.body);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Specialist Functiosn
  /////////////////////////////////////////////////////////////////////////////

  public getBodyInLang(language: LanguageDefaults): string | undefined {
    const localizedTemplate = this.body.getText().get(language.getShortCode()) || '';
    const renderedTemplate = Mustache.render(localizedTemplate, {});
    if (renderedTemplate.trim() === '') {
      return undefined;
    }
    return renderedTemplate;
  }
}
