import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { CommunicationsRequestName } from '../../../communications-request-name';
import { CommunicationsResponse } from '../../generic/communications-response/communications-response';
import { CommunicationsResponseSchema } from '../../generic/communications-response/communications-response-schema';

import { CommunicationsResponseCreateOutboundCallError } from './communications-response-create-outbound-call-error';
import { CommunicationsResponseOnTaskRouterConfigurationUpdatedConstructor } from './communications-response-on-task-router-configuration-updated-constructor';
import { CommunicationsResponseOnTaskRouterConfigurationUpdatedSchema } from './communications-response-on-task-router-configuration-updated-schema';

export class CommunicationsResponseOnTaskRouterConfigurationUpdated extends CommunicationsResponse<CommunicationsResponseCreateOutboundCallError> {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationsResponseOnTaskRouterConfigurationUpdatedConstructor) {
    (parameters as any)[CommunicationsResponseSchema.type] = CommunicationsRequestName.onTaskRouterConfigurationUpdated;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(
    validationResult: import('joi').ValidationResult,
  ): CommunicationsResponseOnTaskRouterConfigurationUpdated {
    return new CommunicationsResponseOnTaskRouterConfigurationUpdated(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationsResponseOnTaskRouterConfigurationUpdated.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationsResponseOnTaskRouterConfigurationUpdatedSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
}
