import { cloneDeep } from 'lodash';

import { SerializableObject } from '../../../../objects/generic/serialization/serializable-object';
import { SerializableObjectSchema } from '../../../../objects/generic/serialization/serializable-object-schema';
import { PossibleAdminRole } from '../../../../objects/user/admin-roles/types';
import { PossibleRole } from '../../../../objects/user/roles/types';

export abstract class Request<T> extends SerializableObject {
  protected type!: T;

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    throw new Error('Error: Cannot get schema for abstract ConversationRequest');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Roles
  /////////////////////////////////////////////////////////////////////////////

  public abstract getRequiredRoles(): PossibleRole[];
  public abstract getRequiredAdminRoles(): PossibleAdminRole[] | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getType(): T {
    return cloneDeep(this.type);
  }
}
