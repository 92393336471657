import { DBDocSchema } from '../../generic/db-doc/db-doc-schema';
import { SchemaField } from '../../generic/serialization/schema-field';

export class AdminRolesSchema extends DBDocSchema {
  public static readonly roles = 'roles';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'adminRoles';
  };

  static Defaults = class {
    public static readonly roles: string[] = [];
  };

  static Roles = class {
    public static readonly pwpAdmin = 'pwpAdmin';
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Default Values
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([AdminRolesSchema.roles]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): { [key: string]: import('joi').AnySchema } {
    const parameters = super.getSchemaDefinition();

    parameters[AdminRolesSchema.roles] = SchemaField.shortStringArray(AdminRolesSchema.Defaults.roles).items(
      AdminRolesSchema.Roles.pwpAdmin,
    );

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `/${AdminRolesSchema.Constants.collection}`;
  }
}
