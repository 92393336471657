import { isEmpty } from 'lodash';

import { createDefaultEventRequestConfig } from '../../../event-request-config/create-default-event-request-config';
import { EventRequestRole } from '../../../event-request-config/enums';
import { AssignedUserType } from '../../enums';

import { CanRequestSlotInput } from './interfaces';

export const canRequestSlot = ({ eventData, slot, ...params }: CanRequestSlotInput): boolean => {
  const eventRequestConfig = params.eventRequestConfig ?? createDefaultEventRequestConfig();

  const userId =
    slot === AssignedUserType.primary ? eventData.getAssignedUserId() : eventData.getAssignedBackupUserId();

  return eventRequestConfig.hasRole(slot, EventRequestRole.allowRequest) && isEmpty(userId);
};
