import { isNil, orderBy, uniqBy } from 'lodash';
import moment from 'moment-timezone';
import RRule from 'rrule';

import { getRRuleDtstart, getRRuleTzid } from './fields';
import { convertRRuleDateToMoment, getRRuleInputFromMoment } from './time-parsing';

/**
 * This function is a wrapper on the rrule library
 * which returns the recurrence specified by the given RRule
 * between the given start/end.
 *
 * Returned values are moments in the same timezone as the RRule.
 * @param rrule
 * @param start
 * @param end
 */
export const getRecurrences = (
  rrule: RRule,
  start: moment.Moment,
  end: moment.Moment,
  validateTimes = true,
): moment.Moment[] => {
  if (validateTimes) {
    validateTimestampAfterDtStart(start, rrule);
  }
  const timezone = getRRuleTzid(rrule.toString());

  /**
   * RRule returns objects that say they are in UTC, but are actually in the local timezone.
   * In order to get the correct timezone we have to reinterpret them as in the local timezone,
   * and then convert them to the required timezone. This function handles that process.
   *
   * https://github.com/jakubroztocil/rrule#important-use-utc-dates
   */
  let recurrences = rrule
    .between(getRRuleInputFromMoment(start, rrule), getRRuleInputFromMoment(end, rrule), true)
    .map((z) => convertRRuleDateToMoment(z, timezone));

  // Drop duplicates (rrule will return duplicate events during spring forward)
  recurrences = uniqBy(recurrences, (z) => z.valueOf());

  // Defensively sort in ascending order
  recurrences = orderBy(recurrences, (z) => z.valueOf(), ['asc']);

  return recurrences;
};

export const getOccuranceBefore = (start: moment.Moment, rrule: RRule, inclEndpoint: boolean): moment.Moment => {
  validateTimestampAfterDtStart(start, rrule);
  const rruleTimezone = getRRuleTzid(rrule.toString());
  const input = getRRuleInputFromMoment(start, rrule);
  const outputBeforeConversion = rrule.before(input, inclEndpoint);
  const output = convertRRuleDateToMoment(outputBeforeConversion, rruleTimezone);
  return output;
};

export const getOccuranceAfter = (start: moment.Moment, rrule: RRule, inclEndpoint: boolean): moment.Moment => {
  validateTimestampAfterDtStart(start, rrule);
  const rruleTimezone = getRRuleTzid(rrule.toString());
  const inputStart = getRRuleInputFromMoment(start, rrule);
  const endTime = rrule.after(inputStart, inclEndpoint);
  const reinterpretedEndTime = convertRRuleDateToMoment(endTime, rruleTimezone);
  return reinterpretedEndTime;
};

export const validateTimestampAfterDtStart = (timestamp: moment.Moment, rrule: RRule): void => {
  const dtStart = getRRuleDtstart(rrule.toString());
  if (!isNil(dtStart) && moment(dtStart).isAfter(timestamp)) {
    console.error(
      'validateTimestampAfterDtStart: User Error. The start time in the recurrance is after the given timeztamp start.',
      { dtStart, start: timestamp, rrule: rrule.toString() },
    );
    throw new Error(
      'validateTimestampAfterDtStart: User Error. The start time in the recurrance is after the given timeztamp start.',
    );
  }
};
